import { IAnalysis } from "@/@types/analysis.ts"
import { IInterviewQuestion } from "@/@types/interview-question.ts"
import { IProject } from "@/@types/project.ts"
import { IReport } from "@/@types/reports"
import { Style } from "@react-pdf/types"
import { IProjectMetrics } from "@services/projects/IProjectGateway.ts"
import React, { createContext, useContext, useReducer } from "react"
import { createTw } from "react-pdf-tailwind"
import tailwindConfig, { tailwindCamelCaseColors } from "../../../../../tailwind.config.ts"

// #region Type definitions
interface IReportDetailsProviderProps {
    children: React.ReactNode
    project: IProject
    interviewQuestions: IInterviewQuestion[]
    numberOfEntries: number
    report: IReport
    analyses: IAnalysis[]
    reportMetrics: IProjectMetrics
    onBack?: () => void
    renderAsPdf: boolean
    isReadOnly: boolean
}

interface IReportDetailsContextState {
    report: IReport
    project: IProject
    analyses: IAnalysis[]
    interviewQuestions: IInterviewQuestion[]
    numberOfEntries: number
    reportMetrics: IProjectMetrics
    scrollToBlockId?: string
    onBack?: () => void
    renderAsPdf: boolean
    isReadOnly: boolean
    /**
     * Method to receive TailwindCSS classes and convert to @react-pdf/renderer styles.
     */
    tw: (input: string) => Style
}

type IReportDetailsContextAction = {
    type: "mark-block-to-scroll"
    blockId: string
} | {
    type: "clear-block-to-scroll"
} | {
    type: "remove-block-from-report"
    reportId: string
    blockId: string
}
// #endregion

// #region Context definitions
const ReportDetailsContext = createContext(
    {} as IReportDetailsContextState
)
const ReportDetailsContextDispatch = createContext(
    {} as React.Dispatch<IReportDetailsContextAction>
)
// #endregion

// #region Hook definitions
export function useReportDetails() {
    return useContext(ReportDetailsContext)
}
export function useReportDetailsDispatch() {
    return useContext(ReportDetailsContextDispatch)
}
// #endregion

// #region Provider definition
export default function ReportDetailsProvider({
    children,
    project,
    interviewQuestions,
    numberOfEntries,
    report,
    analyses,
    reportMetrics,
    onBack,
    renderAsPdf,
    isReadOnly
}: Readonly<IReportDetailsProviderProps>) {
    const tw = createTw({
        theme: {
            ...tailwindConfig.theme,
            extend: {
                ...tailwindConfig.theme?.extend ?? {},
                colors: tailwindCamelCaseColors
            }
        }
    }, { ptPerRem: 16 })
    const initialState: IReportDetailsContextState = {
        report,
        project,
        analyses,
        interviewQuestions,
        numberOfEntries,
        reportMetrics,
        onBack,
        renderAsPdf,
        isReadOnly,
        tw
    }

    const [state, dispatch] = useReducer(ReportDetailsReducer, initialState)

    return (
        <ReportDetailsContext.Provider value={state}>
            <ReportDetailsContextDispatch.Provider value={dispatch}>
                {children}
            </ReportDetailsContextDispatch.Provider>
        </ReportDetailsContext.Provider>
    )
}
// #endregion

// #region Reducer definition
function ReportDetailsReducer(
    state: IReportDetailsContextState,
    action: IReportDetailsContextAction
): IReportDetailsContextState {
    switch (action.type) {
        case "remove-block-from-report": {
            return {
                ...state,
                report: {
                    ...state.report,
                    appendix: state.report.appendix.filter(i => i.id !== action.blockId)
                }
            }
        }
        case "mark-block-to-scroll": {
            return {
                ...state,
                scrollToBlockId: action.blockId
            }
        }
        case "clear-block-to-scroll": {
            return {
                ...state,
                scrollToBlockId: undefined
            }
        }
        default: {
            return state
        }
    }
}