import { DetailedHTMLProps, InputHTMLAttributes } from "react"

interface IGlautInputSingleLineProps
    extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {

}

export default function GlautTextAreaSingleLine(props: Readonly<IGlautInputSingleLineProps>) {
    return (
        <input
            className="p-[0.5em] text-[13.33px] font-medium border-1 rounded-[0.25em] h-max w-full
                bg-glaut-text-area-off-white border-glaut-cards text-glaut-text-midnight
                placeholder:text-glaut-grey
                focus:shadow-none focus:border-glaut-light-grey
                enabled:hover:border-glaut-light-grey
                disabled:bg-glaut-very-light-grey disabled:cursor-not-allowed"
            {...props}
        />
    )
}