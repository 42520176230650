import GlautButtonPrimary from "@components/Buttons/GlautButtonPrimary"
import GlautButtonSecondary from "@components/Buttons/GlautButtonSecondary"
import ModalLayout from "@components/layouts/ModalLayout"

interface IAnalysisTabQuestionsColDeleteQuestionModalProps {
    open: boolean
    onClose?: () => void
    onConfirm?: () => void
}

export default function AnalysisTabQuestionsColDeleteQuestionModal({
    open,
    onClose,
    onConfirm
}: Readonly<IAnalysisTabQuestionsColDeleteQuestionModalProps>) {
    return (
        <ModalLayout
            isOpen={open}
            className={`
                flex flex-col bg-white rounded-xl shadow px-4 py-6 gap-3 transition-all w-56 border-[1px]
                border-glaut-grey
                ${open ? "scale-100 opacity-100" : "scale-125 opacity-0"}
            `}
        >
            <div className="flex justify-center items-center text-center">
                <p>Are you sure you want to remove this analysis?</p>
            </div>
            <div className="flex flex-row justify-between">
                <GlautButtonSecondary onClick={onClose}>
                    Cancel
                </GlautButtonSecondary>
                <GlautButtonPrimary onClick={() => {
                    onConfirm?.()
                    onClose?.()
                }}>
                    Remove
                </GlautButtonPrimary>
            </div>
        </ModalLayout>
    )
}