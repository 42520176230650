import { useDebouncedInputChange } from "@hooks/useDebouncedInputChange"
import { copy, getCopy } from "@utils/Copy"
import { isUrlValid } from "@utils/urls"
import { ChangeEvent, ChangeEventHandler, useCallback } from "react"
import GlautInputText from "../../../../../components/inputs/GlautInputText"
import { useOutlineTab } from "../../contexts/OutlineTabProvider"
import { useLegacyProject } from "../../contexts/ProjectProvider"
import OutlineTabContentDefaultTitle from "../OutlineTabContentDefaultTitle"
import OutlineTabContentDefaultToggle from "../OutlineTabContentDefaultToggle"
import { IProjectInterviewSettings } from "@/@types/project"

export default function OutlineTabContentOverviewPrivacyPolicy() {
    // #region Contexts
    const { project, setProject } = useLegacyProject()
    const { canEdit } = useOutlineTab()
    // #endregion

    // #region Callbacks
    const handleToggle = useCallback((
        ev: ChangeEvent<HTMLInputElement>,
        attribute: keyof IProjectInterviewSettings
    ) => {
        setProject(prev => {
            if (!prev || prev.source === "CSV" || !prev.interview_settings) return prev

            return {
                ...prev,
                interview_settings: {
                    ...prev.interview_settings,
                    [attribute]: ev.target.checked
                }
            }
        })
    }, [setProject])

    const handleValidatePrivacyPolicyLink = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
        const privacyPolicyLink = ev.target.value
        return privacyPolicyLink.length === 0 || isUrlValid(privacyPolicyLink)
    }, [])

    const handleChangePrivacyPolicyLink: ChangeEventHandler<HTMLInputElement> = useCallback(ev => {
        const privacyPolicyLink = ev.target.value

        setProject(prev => {
            if (prev?.source !== "interview") return prev

            return {
                ...prev,
                interview_settings: {
                    ...prev.interview_settings,
                    privacy_policy_link: privacyPolicyLink.length > 0 ? privacyPolicyLink : null
                }
            }
        })
    }, [setProject])
    // #endregion

    // #region Debounced inputs
    const { debouncedOnChange } = useDebouncedInputChange({
        callback: handleChangePrivacyPolicyLink,
        validationCallback: handleValidatePrivacyPolicyLink
    })
    // #endregion

    if (project?.source !== "interview") return <></>

    return (
        <>
            <OutlineTabContentDefaultToggle
                onChange={ev => { handleToggle(ev, "privacy_notice") }}
                checked={!!project.interview_settings?.privacy_notice}
                disabled={!canEdit}
                label={getCopy(copy.outline.overview.privacyNoticeForRespondents) ?? ""}
            />
            {project.interview_settings?.privacy_notice && (
                <>

                    <div className="flex flex-col">
                        <OutlineTabContentDefaultTitle
                            title={getCopy(copy.outline.overview.privacyPolicyLink) ?? ""}
                            subtitle={getCopy(copy.outline.overview.ifNoLinkIsSharedGlautWillDisplay) ?? ""}
                        />
                        <GlautInputText
                            placeholder="https://www.mysite.com/redirect_link"
                            defaultValue={project?.interview_settings.privacy_policy_link ?? ""}
                            onChange={debouncedOnChange}
                            disabled={!canEdit}
                        />
                    </div>
                    <OutlineTabContentDefaultToggle
                        onChange={ev => { handleToggle(ev, "privacy_notice_explicit_consent") }}
                        checked={!!project.interview_settings?.privacy_notice_explicit_consent}
                        disabled={!canEdit}
                        label={getCopy(copy.outline.overview.requestExplicitConsent) ?? ""}
                        tooltipContent={getCopy(copy.outline.overview.ensureRespondentsAgreeToTheTerms) ?? ""}
                    />
                </>
            )}
        </>
    )
}