import { CSSProperties } from "react"

interface ILoadingProps {
    fullPage?: boolean
    powered?: boolean
    color?: CSSProperties["backgroundColor"]
}

export default function Loading({
    fullPage = false,
    powered = false,
    color = "black"
}: Readonly<ILoadingProps>) {
    return (
        <div className="loading column" style={{ height: fullPage ? "100%" : "", justifyContent: "center" }}>
            {powered && <h2>Powered by Glaut</h2>}
            <div className="bouncing-loader">
                {[1, 2, 3].map(index => (
                    <div key={`loading-${index}`} style={{ backgroundColor: color }}></div>
                ))}
            </div>
        </div>
    )
}