import { IVariableResults } from "@/@types/project"
import GlautInfoBottomIcon from "@assets/glaut-info-bottom-icon.svg"
import { monitorForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter"
import NpsContent from "@components/Analysis/AnalysisContent"
import NpsMetrics from "@components/Analysis/AnalysisMetrics"
import ShowMoreButton from "@components/Buttons/ShowMoreButton"
import EmptySpaceBox from "@components/EmptySpaceBox"
import Loading from "@components/loading/Loading"
import { useAnalysisService } from "@hooks/services/useAnalysisService"
import useDragScroll from "@hooks/useDragScroll"
import { isNpsStats, isScaleStats } from "@utils/analysis/scale-stats"
import {
    forceConvertISegmentToIAnalysisCategory,
    forceConvertIStatsToICategoryStatsAnalysisCategory,
    recursivelyConvertChildrenToAnalysisCategory
} from "@utils/analysis/segmentation"
import { copy, getCopy } from "@utils/Copy"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { IAnalysisCategory } from "src/@types/analysis"
import { IBaseScaleStats, ICategoryStats } from "src/@types/processing/statistics"
import { useAnalysisTab, useAnalysisTabDispatch } from "../../contexts/AnalysisTabProvider"
import { useLegacyProject, useProject } from "../../contexts/ProjectProvider"
import { useAnalysisTabAnalysisColDraggable } from "../../hooks/useAnalysisTabAnalysisColDraggable"
import AnalysisTabAnalysisColActionsBox from "../AnalysisTabAnalysisColActionsBox"
import DeleteThemeOrCodeModal from "../AnalysisTabAnalysisColDeleteThemeOrCodeModal"
import AnalysisTabAnalysisColHeader from "../AnalysisTabAnalysisColHeader"
import AnalysisTabAnalysisColMergingBox from "../AnalysisTabAnalysisColMergingBox"
import AnalysisTabAnalysisColThematicMiniGuide from "../AnalysisTabAnalysisColThematicMiniGuide"
import Theme from "../AnalysisTabAnalysisColTheme"
import { MAX_INITIAL_ITEMS } from "./constants/max-initial-items"

interface IAnalysisTabAnalysisColProps {
    isLoadingAnalyses: boolean
}

export default function AnalysisTabAnalysisCol({ isLoadingAnalyses }: Readonly<IAnalysisTabAnalysisColProps>) {
    // #region Contexts
    const { lang } = useLegacyProject()
    const { interviewQuestions } = useProject()
    const {
        selectedAnalysis,
        analysesStats,
        questionsStats,
        selectedBreakdown,
        breakdownStats,
        selectedInterviewQuestionId
    } = useAnalysisTab()
    const analysisTabDispatch = useAnalysisTabDispatch()
    // #endregion

    // #region Services
    const analysisService = useAnalysisService()
    // #endregion

    // #region States
    const [shouldShowAllItems, setShouldShowAllItems] = useState(false)
    // #endregion

    // #region Refs
    const scrollRef = useRef<HTMLDivElement>(null)
    // #endregion

    // #region Scroll hooks

    // Automatically scrolls the code menu
    useDragScroll(scrollRef)

    // #endregion

    // #region Analysis column hooks
    const { isAllowedToDrag } = useAnalysisTabAnalysisColDraggable()
    // #endregion

    // #region Memos
    const selectedInterviewQuestionStats = useMemo(
        () => selectedInterviewQuestionId
            ? questionsStats[selectedInterviewQuestionId]
            : undefined,
        [questionsStats, selectedInterviewQuestionId]
    )

    const isLoadingBreakdown = useMemo(
        () => selectedBreakdown !== undefined && breakdownStats === undefined,
        [selectedBreakdown, breakdownStats]
    )

    const shouldShowActionsBox = useMemo(() => {
        if (selectedInterviewQuestionId)
            return !!interviewQuestions?.find(iq => iq.id === selectedInterviewQuestionId)?.probing_instructions

        return true
    }, [interviewQuestions, selectedInterviewQuestionId])

    const whenQuestionShouldRenderAsThemes = useMemo(() => {
        if (!selectedInterviewQuestionStats) return false
        return Array.isArray(selectedInterviewQuestionStats) || Array.isArray(selectedInterviewQuestionStats.stats)
    }, [selectedInterviewQuestionStats])

    const selectedInterviewQuestion = useMemo(
        () => interviewQuestions?.find(iq => iq.id === selectedInterviewQuestionId),
        [interviewQuestions, selectedInterviewQuestionId]
    )

    const themesList: ICategoryStats<IAnalysisCategory>[] = useMemo(() => {
        if (!selectedAnalysis && !selectedInterviewQuestionStats) return []

        if (selectedAnalysis) {
            // Try breakdown
            if (breakdownStats) {
                // Force strong typing to avoid runtime errors
                const stats: ICategoryStats<IAnalysisCategory>[] = breakdownStats.map(stats => ({
                    ...stats.segment,
                    category: forceConvertISegmentToIAnalysisCategory(stats.segment.category, lang),
                    children: stats.project_results.analysis_results[selectedAnalysis.id]?.stats ?? []
                })).filter(c => c.occurrences > 0).sort((a, b) => b.occurrences - a.occurrences)

                return stats
            }

            // Try analysis
            const stats = analysesStats[selectedAnalysis.id]?.stats
            if (stats)
                return stats.filter(
                    c => !c.category.parent && c.occurrences > 0
                ).sort((a, b) => b.occurrences - a.occurrences)
        }

        // Try question
        if (!selectedInterviewQuestionStats || !selectedInterviewQuestionId) return []
        if (Array.isArray(selectedInterviewQuestionStats)) {
            const stats: ICategoryStats<IAnalysisCategory>[] = selectedInterviewQuestionStats.map(stats => ({
                ...stats.segment,
                category: forceConvertISegmentToIAnalysisCategory(stats.segment.category, lang),
                children: forceConvertIStatsToICategoryStatsAnalysisCategory(
                    stats.project_results.question_results[selectedInterviewQuestionId]?.stats ?? []
                )
            })).filter(c => c.occurrences > 0).sort((a, b) => b.occurrences - a.occurrences)

            return stats
        }

        if (!Array.isArray(selectedInterviewQuestionStats?.stats)) return []
        return selectedInterviewQuestionStats.stats
            .map(c => recursivelyConvertChildrenToAnalysisCategory(c, lang))
            .filter(c => c.occurrences > 0)
            .sort((a, b) => b.occurrences - a.occurrences)
    }, [
        selectedAnalysis,
        selectedInterviewQuestionStats,
        selectedInterviewQuestionId,
        breakdownStats,
        analysesStats,
        lang
    ])

    const scaleMetricsBlocks = useMemo(() => {
        if (
            !selectedInterviewQuestionStats
            || Array.isArray(selectedInterviewQuestionStats)
            || Array.isArray(selectedInterviewQuestionStats.stats)
        ) return []

        if (isNpsStats(selectedInterviewQuestionStats.stats))
            return [
                {
                    label: getCopy(copy.words.promoters) ?? "",
                    value: `${String(
                        selectedInterviewQuestionStats.stats.promoters.toFixed(2)
                    ).padStart(2, "0")}%`
                },
                {
                    label: getCopy(copy.words.detractors) ?? "",
                    value: `${String(
                        selectedInterviewQuestionStats.stats.detractors.toFixed(2)
                    ).padStart(2, "0")}%`
                },
                {
                    label: getCopy(copy.words.nps) ?? "",
                    value: `${String(
                        selectedInterviewQuestionStats.stats.nps.toFixed(2)
                    ).padStart(2, "0")}`
                }
            ]

        if (isScaleStats(selectedInterviewQuestionStats.stats))
            return [
                {
                    label: getCopy(copy.words.averageScore) ?? "",
                    value: `${String(
                        selectedInterviewQuestionStats.stats.average_score.toFixed(2)
                    ).padStart(2, "0")}`
                }
            ]

        return []
    }, [selectedInterviewQuestionStats])

    const shouldShowShowMoreButton = useMemo(() => themesList.length > MAX_INITIAL_ITEMS, [themesList])
    // #endregion

    // #region Callbacks
    const filterByMinMaxScale = useCallback((score: number) => {
        if (!selectedInterviewQuestion) return true
        return selectedInterviewQuestion.type === "scale"
            ? (selectedInterviewQuestion.min <= score && selectedInterviewQuestion.max >= score)
            : true
    }, [selectedInterviewQuestion])
    // #endregion

    // #region Effects

    // onDragAndDropThemesOrCodesPerformChangeParent
    useEffect(() => monitorForElements({
        canMonitor: () => isAllowedToDrag,
        onDrop: ({ source, location }) => {
            if (!selectedAnalysis?.project_id) return

            const destination = location.current.dropTargets[0]
            if (!source.data || !destination?.data) return

            const sourceCategoryStats = source.data.stats as ICategoryStats<IAnalysisCategory>
            const destinationCategoryStats = destination.data.stats as ICategoryStats<IAnalysisCategory> | undefined
            if (!destinationCategoryStats || sourceCategoryStats.category.id === destinationCategoryStats.category.id)
                return

            if (sourceCategoryStats.category.parent === destinationCategoryStats.category.id)
                return

            const projectId = selectedAnalysis.project_id
            const analysisId = selectedAnalysis.id
            const category = sourceCategoryStats.category
            const newParentCategoryId = destinationCategoryStats.category.id

            analysisService.setParentOfCategory({ projectId, analysisId, category, newParentCategoryId })
                .then(({ analysisStats }) => {
                    analysisTabDispatch({
                        type: "change-parent-of-category",
                        analysisId,
                        categoryId: category.id,
                        newParentCategoryId,
                        analysisStats
                    })
                })
        }
    }))

    // onChangeAnalysisResetShowMoreState
    useEffect(() => {
        setShouldShowAllItems(false)
    }, [selectedAnalysis?.id])

    // #endregion

    // #region Element memos

    const themesListElement = useMemo(() => {
        if (isLoadingAnalyses || isLoadingBreakdown)
            return <Loading fullPage />

        if (themesList.length === 0)
            return (
                <EmptySpaceBox
                    message={getCopy(copy.coding.emptyAnalysisColumnMessage) ?? ""}
                    bottomIcon={<img src={GlautInfoBottomIcon} alt="Info" />}
                />
            )

        const shouldShowCodes = ["thematic", "entity"].includes(selectedAnalysis?.type ?? "")
            || !!selectedInterviewQuestion?.probing_instructions

        return (
            <div className="codes">
                {themesList.filter((_, cIdx) => shouldShowAllItems || cIdx < MAX_INITIAL_ITEMS).map((c, cIdx) => (
                    <Theme
                        key={c.category.id}
                        index={cIdx}
                        themeStats={c}
                        shouldShowCodes={shouldShowCodes}
                        isLastElement={cIdx === themesList.length - 1}
                    />
                ))}
            </div>
        )
    }, [
        shouldShowAllItems,
        isLoadingBreakdown,
        themesList,
        selectedAnalysis,
        selectedInterviewQuestion?.probing_instructions,
        isLoadingAnalyses
    ])

    // #endregion

    if (!selectedAnalysis && !selectedInterviewQuestionId) return <></>

    return (
        <div className="analysis h-full flex flex-col">
            <AnalysisTabAnalysisColHeader />

            {shouldShowActionsBox && <AnalysisTabAnalysisColActionsBox />}

            {selectedInterviewQuestionStats && (
                <>
                    {whenQuestionShouldRenderAsThemes && (
                        <div className="overflow-auto mt-[0.5em] pr-2 mb-[0.5em] h-full" ref={scrollRef}>
                            {selectedInterviewQuestion?.probing_instructions !== null
                                && scaleMetricsBlocks.length > 0
                                && <NpsMetrics blocks={scaleMetricsBlocks} />
                            }
                            {themesListElement}
                        </div>
                    )}
                    {!whenQuestionShouldRenderAsThemes && (
                        <div className="flex flex-col overflow-auto pr-1">
                            {scaleMetricsBlocks.length > 0 && (<NpsMetrics blocks={scaleMetricsBlocks} />)}
                            <NpsContent
                                designMode={selectedInterviewQuestion?.type === "scale" ? "scale" : "nps"}
                                values={Object.fromEntries(Object.entries((
                                    selectedInterviewQuestionStats as IVariableResults<IBaseScaleStats>
                                ).stats.values_count ?? {}).filter(([score]) => filterByMinMaxScale(Number(score))))}
                            />
                        </div>
                    )}
                </>
            )}
            {!selectedInterviewQuestionStats && (
                <>
                    {selectedAnalysis?.type === "thematic" && (<AnalysisTabAnalysisColThematicMiniGuide />)}

                    <AnalysisTabAnalysisColMergingBox />

                    <div className="overflow-auto mt-[0.5em] pr-2 mb-[0.5em] h-full" ref={scrollRef}>
                        {themesListElement}
                        {!shouldShowAllItems && shouldShowShowMoreButton && (
                            <ShowMoreButton onClick={() => { setShouldShowAllItems(true) }} />
                        )}
                    </div>
                    <DeleteThemeOrCodeModal />
                </>
            )}

        </div>
    )
}

