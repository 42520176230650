import { IInterview } from "@/@types/entry"
import { createContext, useContext, useReducer } from "react"

// #region Type definitions
interface DataTabCsvContentProviderProps {
    children: React.ReactNode
    interviews: IInterview[]
}

interface DataTabCsvContentContextState {
    selectedColumnIndex: number | null
    interviews: IInterview[]
}

type DataTabCsvContentContextAction = {
    type: "set-selected-column-index"
    index: number | null
}
// #endregion

// #region Context definitions
const DataTabCsvContentContext = createContext(
    {} as DataTabCsvContentContextState
)
const DataTabCsvContentContextDispatch = createContext(
    {} as React.Dispatch<DataTabCsvContentContextAction>
)
// #endregion

// #region Hook definitions
export function useDataTabCsvContent() {
    return useContext(DataTabCsvContentContext)
}
export function useDataTabCsvContentDispatch() {
    return useContext(DataTabCsvContentContextDispatch)
}
// #endregion

// #region Provider definition
export default function DataTabCsvContentProvider({
    children,
    interviews
}: Readonly<DataTabCsvContentProviderProps>) {
    const initialState: DataTabCsvContentContextState = {
        selectedColumnIndex: null,
        interviews
    }

    const [state, dispatch] = useReducer(DataTabCsvContentReducer, initialState)

    return (
        <DataTabCsvContentContext.Provider value={state}>
            <DataTabCsvContentContextDispatch.Provider value={dispatch}>
                {children}
            </DataTabCsvContentContextDispatch.Provider>
        </DataTabCsvContentContext.Provider>
    )
}
// #endregion

// #region Reducer definition
function DataTabCsvContentReducer(
    state: DataTabCsvContentContextState,
    action: DataTabCsvContentContextAction
): DataTabCsvContentContextState {
    switch (action.type) {
        case "set-selected-column-index": {
            return {
                ...state,
                selectedColumnIndex: action.index
            }
        }
        default: {
            return state
        }
    }
}
// #endregion