import FloatingLayout from "@components/layouts/FloatingLayout"
import { copy, getCopy } from "@utils/Copy"
import { useCallback, useMemo, useRef, useState } from "react"
import { MdArrowDropDown } from "react-icons/md"
import { useOutlineTab } from "../../contexts/OutlineTabProvider"

interface IOutlineTabContentOverviewLanguageDropdownProps {
    onSelect?: (langCode: string) => void
    currLanguage?: string
    languages: {
        [langCode: string]: {
            flag: string
            name: string
        }
    }
    disabled?: boolean
}

/** @deprecated use `@components/inputs/LanguageDropdown` instead */
export default function OutlineTabContentOverviewLanguageDropdown({
    onSelect,
    currLanguage,
    languages,
    disabled = false
}: Readonly<IOutlineTabContentOverviewLanguageDropdownProps>) {
    // #region Contexts
    const { canEdit } = useOutlineTab()
    // #endregion

    // #region States
    const [menuOpen, setMenuOpen] = useState(false)
    // #endregion

    // #region Refs
    const buttonRef = useRef<HTMLButtonElement>(null)
    // #endregion

    // #region Memos
    const buttonLabel = useMemo(() => {
        const language = currLanguage ? languages[currLanguage] : undefined
        if (!language) return getCopy(copy.outline.overview.selectALanguage) ?? ""

        return `${language.flag} ${language.name}`
    }, [languages, currLanguage])

    const isEditable = useMemo(() => canEdit && !disabled, [canEdit, disabled])
    // #endregion

    // #region Callbacks
    const handleSelect = useCallback((langCode: string) => {
        onSelect?.(langCode)
        setMenuOpen(false)
    }, [onSelect])
    // #endregion

    // #region Const values
    const buttonBottomPosition = buttonRef?.current?.getBoundingClientRect().bottom ?? 0
    const windowBottomPosition = window.scrollY + window.innerHeight
    const maxDropdownHeight = windowBottomPosition - buttonBottomPosition - 100
    // #endregion

    return (
        <>
            <button
                ref={buttonRef}
                className={`flex flex-row justify-between rounded-[0.25em] py-[0.625em] px-[0.75em]
                    w-full border-1 group
                    shadow-none
                    bg-glaut-cards border-glaut-light-grey
                    disabled:border-0
                    ${!isEditable ? "cursor-not-allowed" : ""}
                `}
                onClick={() => canEdit && setMenuOpen(!menuOpen)}
                disabled={disabled}
            >
                {isEditable && <MdArrowDropDown className="invisible" />} {/** for alignment */}
                <div className="flex gap-[0.75em] justify-center flex-1">
                    <p className="text-[13.33px] font-medium text-glaut-text-midnight 
                        group-disabled:text-glaut-light-grey">
                        {buttonLabel}
                    </p>
                </div>
                {isEditable && <MdArrowDropDown />}
            </button>
            {canEdit && menuOpen && (
                <FloatingLayout
                    anchorRef={buttonRef}
                    onClose={() => setMenuOpen(false)}
                    className={`absolute flex flex-col py-[0.25em] px-[0.5em] border-1 rounded-[0.25em]
                        border-glaut-grey bg-glaut-off-white shadow-[0px_0px_8px_0px_rgb(0,0,0,0.15)]
                    `}
                    place="bottom"
                    style={{ width: buttonRef?.current?.getBoundingClientRect().width ?? undefined }}
                    boundingBoxOffset={50}
                >
                    <p className="text-[11.11px] font-medium text-[#666666] py-[0.625em]">
                        {getCopy(copy.outline.overview.selectOneLanguage)}
                    </p>
                    <div className="px-[0.25em] overflow-auto" style={{ maxHeight: maxDropdownHeight }}>
                        {Object.entries(languages).map(([langCode, langData]) => (
                            <button
                                key={langCode}
                                onClick={() => { handleSelect(langCode) }}
                                className={`flex gap-[0.5em] items-center py-[0.625em] rounded-none px-1 cursor-pointer
                                    w-full justify-start border-none shadow-none
                                    hover:bg-glaut-very-light-grey
                                `}
                            >
                                {langData.flag}  {langData.name}
                            </button>
                        ))}
                    </div>
                </FloatingLayout>
            )}
        </>
    )
}
