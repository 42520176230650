import { useMemo, useState, useEffect, useCallback } from "react"
import { IOrganizationUser, IWorkspaceUser, IProjectGuest } from "src/@types/user"
import { getCopy } from "@utils/Copy"
import { emailRegex } from "@utils/Variables"
import { useGlautAuthInfo } from "@hooks/useGlautAuthInfo"

import Button from "@components/layouts/Button"
import Options from "@components/menus/Options"
import ConfirmModal from "@components/Modals/ConfirmModal"
import Loading from "@components/loading/Loading"

import { LuSend } from "react-icons/lu"
import { MdClose } from "react-icons/md"
import defaultProfile from "@assets/default-profile.png"
import GlautInfoBottomIcon from "@assets/glaut-info-bottom-icon.svg"
import { ILang } from "@/@types/interview-question"
import GlautInputText from "@components/inputs/GlautInputText"

interface IMemberProps {
    canEdit: boolean
    defaultRole: string
    roles: { slug: string, title: string, description: string }[]
    users: IOrganizationUser[] | IWorkspaceUser[] | IProjectGuest[] | undefined
    inviteUser: (email: string, role: string) => Promise<void>
    changeUserRole: (email: string, role: string) => Promise<void>
    removeUser: (email: string) => Promise<void>
    loading: boolean
    copy: {
        inviteNewMemberTitle: ILang
        inviteNewMemberDescription: ILang
        invite: ILang
        cannotInvite: ILang
        members: ILang
        removeMember: ILang
        noMembersYet: ILang
        selectAPerson?: ILang
    },
    suggestedUsers?: IOrganizationUser[]
}

export default function Members({
    canEdit,
    defaultRole,
    roles,
    users,
    inviteUser,
    changeUserRole,
    removeUser,
    loading,
    copy,
    suggestedUsers = []
}: Readonly<IMemberProps>) {
    // #region Hooks
    const { userClass } = useGlautAuthInfo()
    // #endregion

    // #region State
    const [removeMember, setRemoveMember] = useState(null)
    const [inviteAddress, setInviteAddress] = useState("")
    const [inviteRole, setInviteRole] = useState(defaultRole)
    const [userIndex, setUserIndex] = useState(0)
    // #endregion

    // #region Memo
    const filteredSuggestedUsers = useMemo(
        () => inviteAddress.length ?
            suggestedUsers.filter(u => u.email.includes(inviteAddress)) :
            [],
        [inviteAddress, suggestedUsers]
    )
    const emailValid = useMemo(() => emailRegex.test(inviteAddress), [inviteAddress])
    // #endregion

    // #region Effects
    useEffect(() => {
        if (userIndex > filteredSuggestedUsers.length) setUserIndex(filteredSuggestedUsers.length - 1)
    }, [filteredSuggestedUsers, userIndex])
    // #endregion

    // #region Callbacks
    const innerInviteUser = useCallback((email, role) => {
        inviteUser(email, role).then(() => {
            setInviteAddress("")
        })
    }, [inviteUser])

    const handleKeyDown = useCallback(e => {
        if (e.key === "ArrowDown" || e.key === "ArrowUp" || e.key === "Enter") {
            e.preventDefault()
            e.stopPropagation()

            if (e.key === "ArrowDown" && userIndex < filteredSuggestedUsers.length - 1)
                setUserIndex(userIndex + 1)
            else if (e.key === "ArrowUp" && userIndex > 0)
                setUserIndex(userIndex - 1)
            else if (e.key === "Enter")
                if (emailValid)
                    innerInviteUser(inviteAddress, inviteRole)
                else if (filteredSuggestedUsers.length > 0 && userIndex < filteredSuggestedUsers.length)
                    innerInviteUser(filteredSuggestedUsers[userIndex].email, inviteRole)
        }
    }, [filteredSuggestedUsers, userIndex, emailValid, inviteAddress, inviteRole, innerInviteUser])
    // #endregion

    if (users === undefined) return <></>

    return (
        <>
            <div className="members flex flex-col gap-[0.75rem] relative mt-[0.75rem]">
                <p className="text-[13.33px] font-medium text-[#565656]">
                    {getCopy(copy.inviteNewMemberTitle)}
                </p>
                <p className="text-[12.7px] text-[#565656]">
                    {getCopy(copy.inviteNewMemberDescription)}
                </p>
                {canEdit ? (
                    <div className="flex flex-row gap-2 w-10/12 my-8 mx-auto">
                        <div className="w-[30vw]">
                            <div className="invite-user absolute flex flex-col gap-2 w-[30vw]">
                                <GlautInputText
                                    value={inviteAddress}
                                    onChange={e => setInviteAddress(e.target.value)}
                                    placeholder="mail@adress.com"
                                    style={{ flex: 1 }}
                                    onKeyDown={handleKeyDown}
                                    disabled={loading}
                                />
                                {!loading && filteredSuggestedUsers.length > 0 && (
                                    <div className="flex flex-col gap-2 relative w-full rounded-md top-1 max-h-64
                                        overflow-y-auto
                                        bg-glaut-off-white shadow-[#00000026_0px_0px_8px_0px]
                                    ">
                                        <div className="text-sm text-[#666666] p-2">
                                            {getCopy(copy.selectAPerson)}
                                        </div>
                                        {filteredSuggestedUsers.map((u, index) => (
                                            <div
                                                className={
                                                    "flex flex-row justify-between hover:bg-glaut-off-white " +
                                                    "py-2 px-4 rounded-none cursor-pointer" +
                                                    (index === userIndex ? " bg-glaut-cards" : "")
                                                }
                                                key={u._id}
                                                onClick={() => setInviteAddress(u.email)}
                                            >
                                                <div className="flex flex-row gap-2 items-center">
                                                    <img
                                                        src={u.picture_url || defaultProfile}
                                                        className="w-8 h-8 rounded-full"
                                                    />
                                                    <div className="flex flex-col">
                                                        <b>
                                                            {u.first_name} {u.last_name}
                                                        </b>
                                                        <span style={{ fontSize: "small" }}>{u.email}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                        <Options
                            options={roles}
                            chosen={inviteRole}
                            setChosen={setInviteRole}
                            width="16em"
                            disabled={loading}
                        />
                        <Button
                            label={getCopy(copy.invite)}
                            onClick={() => innerInviteUser(inviteAddress, inviteRole)}
                            icon={<LuSend className="small-icon" />}
                            disabled={!emailValid || loading}
                        />
                    </div>
                ) : (
                    <span>{getCopy(copy.cannotInvite)}</span>
                )}
                <p className="text-[13.33px] font-medium text-[#565656] my-[1.5rem]">
                    {getCopy(copy.members)}
                </p>
                {loading && <Loading />}
                {!users?.length && (
                    <div className="flex flex-col justify-center items-center text-glaut-grey p-2">
                        {getCopy(copy.noMembersYet)}
                        <img src={GlautInfoBottomIcon} className="h-3 m-2" />
                    </div>
                )}
                {!!users?.length && users.map(u => (
                    <div
                        className="member flex flex-row justify-between m-2 hover:bg-glaut-off-white"
                        key={u._id}
                    >
                        <div className="flex flex-row gap-2 items-center">
                            <img
                                src={u.picture_url || defaultProfile}
                                className="w-8 h-8 rounded-full"
                            />
                            <div className="flex flex-col">
                                <b className="member-name">
                                    {u.first_name} {u.last_name}
                                </b>
                                <span className="member-email" style={{ fontSize: "small" }}>{u.email}</span>
                            </div>
                        </div>
                        {canEdit && u.propelauth_id !== userClass?.userId ? (
                            <div className="flex flex-row items-center gap-2">
                                <Options
                                    width="16em"
                                    options={roles}
                                    chosen={u.role || u.role_in_org}
                                    setChosen={v => changeUserRole(u.email, v)}
                                    disabled={loading}
                                />
                                <MdClose
                                    className={loading ? "cursor-not-allowed" : "cursor-pointer"}
                                    onClick={() => !loading && setRemoveMember(u.email)}
                                />
                            </div>
                        ) : (
                            <div>{u.role || u.role_in_org}</div>
                        )}
                    </div>
                ))}
            </div>
            {removeMember && (
                <ConfirmModal
                    open={removeMember}
                    onClose={() => setRemoveMember(null)}
                    onConfirm={() => {
                        setRemoveMember(null)
                        removeUser(removeMember)
                    }}
                >
                    <span>{getCopy(copy.removeMember)}</span>
                </ConfirmModal>
            )}
        </>
    )
}
