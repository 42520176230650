import { normalizeToColorIndex } from "@utils/styling/colors-from-index"
import { FaTag } from "react-icons/fa"

interface IAnalysisTabAnalysisColFilteringModalQuestionValueSelectLabelProps {
    colorIndex: number
    label: string
}

export default function AnalysisTabAnalysisColFilteringModalQuestionValueSelectLabel({
    colorIndex,
    label
}: Readonly<IAnalysisTabAnalysisColFilteringModalQuestionValueSelectLabelProps>) {
    return (
        <div className="flex gap-[0.5em] items-center p-[0.5em]">
            <FaTag
                className="h-[1em] w-[1em] rounded-none"
                style={{ color: `var(--color${normalizeToColorIndex(colorIndex)})` }}
            />
            <p className={`text-[13.33px] font-medium text-glaut-text-midnight
                text-ellipsis whitespace-nowrap overflow-hidden
            `}>
                {label}
            </p>
        </div>
    )
}