import GlautLogoComplete from "@assets/GlautLogoComplete.svg"
import GlautInputLogo from "@components/inputs/GlautInputLogo"
import Button from "@components/layouts/Button"
import FloatingMenu from "@components/layouts/FloatingMenu"
import { useGlautAuthInfo } from "@hooks/useGlautAuthInfo"
import { useLogoutFunction } from "@propelauth/react"
import { copy, getCopy } from "@utils/Copy"
import { KeyboardEventHandler, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { MdAddCircle, MdLogout } from "react-icons/md"
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri"
import { useNavigate, useParams } from "react-router-dom"
import CreateOrganizationPopup from "./CreateOrganizationPopup"


interface ITopBarProps {
    currentTab: number
    setCurrentTab: (index: number) => void
    tabs: (string | { label: string; index: number })[]
    children?: React.ReactNode
}

export default function TopBar({ currentTab, setCurrentTab, tabs, children = undefined }: Readonly<ITopBarProps>) {
    // #region Navigation
    const { orgId } = useParams()
    const navigate = useNavigate()
    // #endregion

    // #region Hooks
    const logout = useLogoutFunction()
    const {
        isSuperuser, orgs, currentOrg, currentWs, currentOrgIsAtLeastRole, submitOrgLogo, removeOrgLogo
    } = useGlautAuthInfo()
    // #endregion

    // #region States
    const [menuOpen, setMenuOpen] = useState(false)
    const [searchString, setSearchString] = useState("")
    const [filteredOrgs, setFilteredOrgs] = useState(orgs || [])
    const [orgIndex, setOrgIndex] = useState(0)
    const [menuAutoClose, setMenuAutoClose] = useState(true)
    const [openCreateOrganization, setOpenCreateOrganization] = useState(false)
    // #endregion

    // #region Refs
    const orgNameRef = useRef<HTMLDivElement>(null)
    const orgListRef = useRef<HTMLDivElement>(null)
    // #endregion

    // #region Memos
    const canEdit = useMemo(() => currentOrgIsAtLeastRole("owner"), [currentOrgIsAtLeastRole])
    const normalizedTabs = useMemo(
        () => tabs.map((tab, index) => typeof tab === "string" ? { label: tab, index } : tab),
        [tabs]
    )
    // #endregion

    // #region Callbacks

    // Open the selected organization
    const openOrg = useCallback((index: number) => {
        const org = filteredOrgs[index]
        setMenuOpen(false)
        setSearchString("")
        navigate(`/o/${org._id}`)
    }, [filteredOrgs, navigate])

    // Handle the keydown event for the organization list
    const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(e => {
        if (e.key === "ArrowDown" || e.key === "ArrowUp" || e.key === "Enter") {
            e.preventDefault()
            e.stopPropagation()

            if (e.key === "ArrowDown" && orgIndex < filteredOrgs.length - 1)
                setOrgIndex(orgIndex + 1)
            else if (e.key === "ArrowUp" && orgIndex > 0)
                setOrgIndex(orgIndex - 1)
            else if (e.key === "Enter")
                openOrg(orgIndex)
        }
    }, [orgIndex, filteredOrgs.length, openOrg])

    // #endregion

    // #region Effects
    // Reset the current tab when the current organization changes
    useEffect(() => {
        if (orgId) setCurrentTab(0)
    }, [orgId, setCurrentTab])

    // Filter the organizations based on the search string
    useEffect(() => {
        if (orgs && searchString)
            setFilteredOrgs(orgs.filter(org => org.name.toLowerCase().includes(searchString.toLowerCase())))
        else setFilteredOrgs(orgs || [])
    }, [searchString, orgs])

    // Close the menu when the organization logo changes
    useEffect(() => {
        setMenuAutoClose(true)
    }, [currentOrg?.logo])

    // Update the org index when the filtered orgs change due to prevent the index from going out of bounds
    useEffect(() => {
        if (orgIndex > filteredOrgs.length) setOrgIndex(filteredOrgs.length - 1)
    }, [filteredOrgs, orgIndex])

    // Scroll the org list to the selected org
    useEffect(() => {
        // if (orgListRef.current) orgListRef.current.scrollTop = orgIndex * 40
        if (orgListRef.current) {
            const selectedChild = orgListRef?.current?.children[orgIndex]
            if (selectedChild instanceof HTMLElement)
                orgListRef.current.scrollTop = selectedChild.offsetTop - orgListRef.current.offsetTop
        }
    }, [orgIndex])
    // #endregion

    return (
        <div id="div--topbar" className="flex flex-row items-center justify-between bg-glaut-text-midnight pl-6 pr-20">
            <div className="flex flex-row items-center h-full">
                <img
                    src={GlautLogoComplete}
                    alt="Glaut logo"
                    className={currentOrg ? "h-7 cursor-pointer" : "h-7"}
                    onClick={() => currentOrg && navigate(`/o/${currentOrg._id}`)}
                />
                {currentOrg && <span
                    id="div--topbar-org-name"
                    className="flex flex-row text-glaut-grey underline gap-1 p-2 pr-0 cursor-pointer"
                    onClick={() => setMenuOpen(!menuOpen)}
                    ref={orgNameRef}
                >
                    {currentOrg?.name}
                    {menuOpen ?
                        <RiArrowDropUpLine className="arrow-up" /> :
                        <RiArrowDropDownLine className="arrow-down" />}
                </span>}
                {currentWs && <span
                    id="div--topbar-ws-name"
                    className="flex flex-row text-glaut-grey underline pl-2 pr-2 cursor-pointer"
                    onClick={() => navigate(`/w/${currentWs._id}`)}
                >
                    {currentWs.name}
                </span>}
                {children}
            </div>
            <div id="div--topbar-steps" className="flex flex-row">
                {normalizedTabs.map(step => (
                    <div
                        key={step.index}
                        className={currentTab === step.index
                            ? "pt-5 py-3 px-4 border-b-2 border-glaut-pink text-white"
                            : "pt-5 py-3 px-4 text-glaut-grey border-b-2 border-b-transparent"
                            + " hover:border-glaut-pink cursor-pointer"}
                        onClick={() => setCurrentTab((step.index))}
                    >
                        {step.label}
                    </div>
                ))}
            </div>
            {menuOpen &&
                <FloatingMenu
                    anchorRef={orgNameRef}
                    onClose={() => {
                        setMenuOpen(false)
                        setSearchString("")
                    }}
                    elements={[]}
                    autoClose={menuAutoClose}
                    position="bottom"
                    distance={0}
                >
                    <div
                        id="div--topbar-ws-name"
                        className="flex flex-col gap-4 px-4 w-80"
                    >
                        {orgs?.length && orgs?.length > 1 && <div>
                            <input
                                type="text"
                                placeholder={getCopy(copy.org.searchOrg) ?? ""}
                                className="w-full rounded-md px-2"
                                value={searchString}
                                onChange={e => setSearchString(e.target.value)}
                                onKeyDown={handleKeyDown}
                            />
                            <div
                                id="div--topbar-org-list"
                                ref={orgListRef}
                                className="max-h-40 overflow-y-auto border-l-2 border-r-2 border-b-2 border-dotted 
                                border-glaut-light-grey rounded-none text-black"
                            >
                                {filteredOrgs.map((org, index) => (
                                    <div
                                        key={index}
                                        className="flex flex-row items-center gap-2 cursor-pointer"
                                        onClick={() => openOrg(index)}
                                    >
                                        <span
                                            className={"border-b border-glaut-cards rounded-none w-full p-2" + (
                                                index === orgIndex ? " bg-glaut-cards" : "")}
                                        >{org.name}</span>
                                    </div>
                                ))}
                            </div>
                        </div>}
                        {isSuperuser && <Button
                            id="div--topbar-add-org"
                            className="tertiary"
                            label={getCopy(copy.org.addOrganization)}
                            onClick={() => setOpenCreateOrganization(true)}
                            icon={<MdAddCircle />}
                        />}
                        {currentOrg && canEdit && (
                            <GlautInputLogo
                                id="div--topbar-org-logo"
                                title={
                                    <div className="border-t px-2 py-1 rounded-none">
                                        {getCopy(copy.org.uploadLogo)}
                                    </div>
                                }
                                src={currentOrg?.logo ?? undefined}
                                alt="Org logo"
                                onRemove={removeOrgLogo}
                                onSelect={submitOrgLogo}
                                onAfterSelect={() => setMenuAutoClose(false)}
                            />
                        )}
                        <button
                            className="border-none shadow-none px-0 py-1 text-base font-normal hover:font-bold"
                            onClick={() => logout(false)}
                        >
                            <MdLogout /> {getCopy(copy.org.logout)}
                        </button>
                    </div>
                </FloatingMenu>}
            {openCreateOrganization && <CreateOrganizationPopup setOpen={setOpenCreateOrganization} />}
        </div>
    )
}
