import { ChangeEvent, ChangeEventHandler, useCallback, useRef } from "react"

interface IUseDebouncedInputChangeProps<T extends HTMLInputElement | HTMLTextAreaElement> {
    preCallback?: (ev: ChangeEvent<T>) => void
    callback: (ev: ChangeEvent<T>) => void
    validationCallback?: (ev: ChangeEvent<T>) => boolean
    timeout?: number
}

export function useDebouncedInputChange<T extends HTMLInputElement | HTMLTextAreaElement>({
    preCallback,
    callback,
    validationCallback,
    timeout = 1000
}: Readonly<IUseDebouncedInputChangeProps<T>>) {
    // #region Refs
    const callbackCallTimeoutRef = useRef<NodeJS.Timeout>()
    // #endregion

    // #region Callbacks
    const debouncedOnChange: ChangeEventHandler<T> = useCallback(ev => {
        if (preCallback) preCallback?.(ev)
        if (validationCallback && !validationCallback(ev)) return

        if (callbackCallTimeoutRef.current) clearTimeout(callbackCallTimeoutRef.current)
        callbackCallTimeoutRef.current = setTimeout(() => { callback(ev) }, timeout)
    }, [preCallback, callback, validationCallback, timeout])
    // #endregion

    return { debouncedOnChange }
}