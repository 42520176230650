import { DetailedHTMLProps, SVGAttributes } from "react"

interface IArrowKeysProps extends DetailedHTMLProps<SVGAttributes<SVGSVGElement>, SVGSVGElement> {

}

export default function ArrowKeys({
    ...props
}: Readonly<IArrowKeysProps>) {
    return (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M11.832 8.75004H14.6654V14.9167H8.4987V8.75004H11.582H11.832ZM7.9987 8.75004L7.9987 
                14.9167H1.83203L1.83203 8.75004H4.66536H4.91536H7.9987ZM5.16536 8.25004V2.08337L11.332 
                2.08337V8.25004H8.4987H8.2487H7.9987H5.16536Z" stroke="black" strokeWidth="0.5" />
            <path
                d="M8.2487 8.50004H1.58203L1.58203 15.1667H8.2487M8.2487 8.50004V15.1667M8.2487 
                8.50004H14.9154V15.1667H8.2487M8.2487 3.83337V6.50004M4.91536 1.83337L11.582 
                1.83337V8.50004H4.91536V1.83337Z" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M6.91536 5.16671L8.2487 3.83337L9.58203 5.16671M3.58203 11.8334H6.2487M3.58203 11.8334L4.91536 
                13.1667M3.58203 11.8334L4.91536 10.5M12.9154 11.8334H10.2487M12.9154 11.8334L11.582 10.5M12.9154 
                11.8334L11.582 13.1667" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}