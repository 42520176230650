import ProjectMetricsBoxGraphProportionEntry from "@components/Project/ProjectMetricsBoxGraphProportionEntry"
import ProjectMetricsBoxTextEntry from "@components/Project/ProjectMetricsBoxTextEntry"
import { useMetricsIndicators } from "@hooks/useMetricsIndicators"
import { View } from "@react-pdf/renderer"
import { useReportDetails } from "../../contexts/ReportDetailsContext"
import MetricWrapper from "../ReportDetailsContentKeyInterviewDataMetricWrapper"
import StandardTextSession from "../ReportDetailsContentStandardTextSession"

export default function ReportDetailsContentKeyInterviewData() {
    // #region Contexts
    const { renderAsPdf, tw, reportMetrics } = useReportDetails()
    // #endregion

    // #region Metrics hooks
    const {
        avgInterviewLengthMinutes,
        avgInterviewLengthSeconds,
        completionRate,
        desktopPercentage,
        mobilePercentage
    } = useMetricsIndicators(reportMetrics)
    // #endregion

    if (renderAsPdf)
        return (
            <View style={tw("flex flex-col gap-[8.91px]")} wrap={false}>
                <StandardTextSession title="Key interview data" />
                <View>
                    <View style={[
                        tw("flex flex-col border-1 rounded-[6.36px] py-[10.18px] px-[12.73px] gap-[5.09px]"),
                        tw("border-glautVeryLightGrey")
                    ]}>
                        <View style={tw("flex flex-row gap-[5.09px]")}>
                            <MetricWrapper>
                                <ProjectMetricsBoxTextEntry
                                    description="Started"
                                    value={String(reportMetrics.started)}
                                    asPdf
                                    twPdf={tw}
                                />
                            </MetricWrapper>
                            <MetricWrapper>
                                <ProjectMetricsBoxTextEntry
                                    description="Completed"
                                    value={String(reportMetrics.completed)}
                                    asPdf
                                    twPdf={tw}
                                />
                            </MetricWrapper>
                        </View>
                        <View style={tw("flex flex-row gap-[5.09px]")}>
                            <MetricWrapper>
                                <ProjectMetricsBoxTextEntry
                                    description="Completion rate"
                                    value={`${completionRate}%`}
                                    asPdf
                                    twPdf={tw}
                                />
                            </MetricWrapper>
                            <MetricWrapper>
                                <ProjectMetricsBoxTextEntry
                                    description="Avg. interview length"
                                    value={`${avgInterviewLengthMinutes}:${avgInterviewLengthSeconds} min`}
                                    asPdf
                                    twPdf={tw}
                                />
                            </MetricWrapper>
                        </View>
                        <View style={tw("flex flex-row")}>
                            <MetricWrapper>
                                <ProjectMetricsBoxGraphProportionEntry
                                    desktopPercentage={desktopPercentage}
                                    mobilePercentage={mobilePercentage}
                                    asPdf
                                    twPdf={tw}
                                />
                            </MetricWrapper>
                        </View>
                    </View>
                </View>
            </View>
        )

    return (
        <div className="flex flex-col py-[0.75em]">
            <StandardTextSession title="Key interview data" />
            <div className={`flex flex-col border-1 rounded-[0.625em] p-[1em] px-[1.25em] gap-[0.5em]
                border-glaut-very-light-grey
            `}>
                <div className="flex gap-[0.5em]">
                    <MetricWrapper>
                        <ProjectMetricsBoxTextEntry description="Started" value={String(reportMetrics.started)} />
                    </MetricWrapper>
                    <MetricWrapper>
                        <ProjectMetricsBoxTextEntry description="Completed" value={String(reportMetrics.completed)} />
                    </MetricWrapper>
                </div>
                <div className="flex gap-[0.5em]">
                    <MetricWrapper>
                        <ProjectMetricsBoxTextEntry description="Completion rate" value={`${completionRate}%`} />
                    </MetricWrapper>
                    <MetricWrapper>
                        <ProjectMetricsBoxTextEntry
                            description="Avg. interview length"
                            value={`${avgInterviewLengthMinutes}:${avgInterviewLengthSeconds} min`}
                        />
                    </MetricWrapper>
                </div>

                <MetricWrapper>
                    <ProjectMetricsBoxGraphProportionEntry
                        desktopPercentage={desktopPercentage}
                        mobilePercentage={mobilePercentage}
                    />
                </MetricWrapper>
            </div>
        </div>
    )
}