import { IProject } from "@/@types/project"
import { draggable } from "@atlaskit/pragmatic-drag-and-drop/element/adapter"
import CodeOccurrencesBox from "@components/Coding/CodeOccurrencesBox"
import { useAnalysisService } from "@hooks/services/useAnalysisService"
import { isEllipsisActive } from "@utils/html-elements/is-ellipsis-active"
import { normalizeToColorIndex } from "@utils/styling/colors-from-index"
import { MouseEvent as ReactMouseEvent, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { MdCheck, MdClose, MdMoreVert } from "react-icons/md"
import { IAnalysisCategory } from "src/@types/analysis"
import { ICategoryStats } from "src/@types/processing/statistics"
import { ProjectContext } from "../../../AutoSaveProject"
import {
    useAnalysisTabAnalysisCol,
    useAnalysisTabAnalysisColDispatch
} from "../../contexts/AnalysisTabAnalysisColProvider"
import { useAnalysisTab, useAnalysisTabDispatch } from "../../contexts/AnalysisTabProvider"
import { useAnalysisTabAnalysisColDraggable } from "../../hooks/useAnalysisTabAnalysisColDraggable"
import AnalysisTabAnalysisColThemeAndCodeActionsMenu from "../AnalysisTabAnalysisColThemeAndCodeActionsMenu"

interface ICodeProps {
    themeId: string
    codeStats: ICategoryStats<IAnalysisCategory>
    colorIndex: number
    withActionsMenu?: boolean
    forceShowColor?: boolean
    isFromLastTheme?: boolean
}

export default function AnalysisTabAnalysisColThemeCode({
    themeId,
    codeStats,
    colorIndex,
    withActionsMenu = false,
    forceShowColor = false,
    isFromLastTheme = false
}: Readonly<ICodeProps>) {
    // #region Contexts
    const { project } = useContext(ProjectContext) as { project: IProject | null }
    const {
        selectedCategory,
        selectedCategorySegmentId,
        breakdownStats,
        categoryColorIndexes,
        selectedBreakdown,
        selectedAnalysis
    } = useAnalysisTab()
    const analysisTabDispatch = useAnalysisTabDispatch()
    const {
        categoryBeingMerged,
        targetCategoryBeingMerged,
        categoryBeingUsedToCreateSubTheme,
        categoryBeingEdited
    } = useAnalysisTabAnalysisCol()
    const analysisTabAnalysisColDispatch = useAnalysisTabAnalysisColDispatch()
    // #endregion

    // #region Services
    const analysisService = useAnalysisService()
    // #endregion

    // #region States
    const [isShowingActionsMenu, setIsShowingActionsMenu] = useState(false)
    const [isBeingDragged, setIsBeingDragged] = useState(false)
    const [hasTooltip, setHasTooltip] = useState(false)
    const [isEditingName, setIsEditingName] = useState(false)
    // #endregion

    // #region Refs
    const containerDivRef = useRef<HTMLDivElement>(null)
    const codeParagraphRef = useRef<HTMLParagraphElement>(null)
    const editionInputRef = useRef<HTMLInputElement>()
    // #endregion

    // #region Analysis column hooks
    const { isAllowedToDrag } = useAnalysisTabAnalysisColDraggable()
    // #endregion

    // #region Memos
    const isAThemeBeingMerged = useMemo(
        () => !!categoryBeingMerged && (categoryBeingMerged.category.id === categoryBeingMerged.themeId),
        [categoryBeingMerged]
    )
    const codeStatus = useMemo(
        () => {
            if (isBeingDragged) return "dragging"

            if (themeId === categoryBeingMerged?.themeId || isAThemeBeingMerged) {
                const isCategoryBeingMerged = categoryBeingMerged?.category.id === codeStats.category.id
                const isTargetCategoryBeingMerged = targetCategoryBeingMerged?.category.id === codeStats.category.id

                if (isCategoryBeingMerged || isTargetCategoryBeingMerged) return "merging"
                return "available-to-merge"
            }

            if (themeId === categoryBeingUsedToCreateSubTheme?.themeId)
                return categoryBeingUsedToCreateSubTheme.codes.some(c => c.category.id === codeStats.category.id)
                    ? "clustering"
                    : "available-to-cluster"

            if (selectedCategory?.category.id === codeStats.category.id)
                if (!selectedBreakdown || themeId === selectedCategorySegmentId)
                    return "selected"

            return undefined
        },
        [
            isBeingDragged,
            isAThemeBeingMerged,
            categoryBeingMerged,
            targetCategoryBeingMerged,
            categoryBeingUsedToCreateSubTheme,
            codeStats,
            themeId,
            selectedCategory,
            selectedBreakdown,
            selectedCategorySegmentId
        ]
    )
    const isGrey = useMemo(() => {
        if (forceShowColor) return false
        if (isAThemeBeingMerged) return codeStats.children.length !== 0

        return codeStats.occurrences === 0 || ["merging", "clustering"].includes(codeStatus ?? "")
    }, [forceShowColor, codeStatus, isAThemeBeingMerged, codeStats.occurrences, codeStats.children.length])
    const shouldShowActionsMenuButton = useMemo(
        () => withActionsMenu
            && breakdownStats === undefined
            && !isEditingName
            && [undefined, "selected", "dragging"].includes(codeStatus),
        [codeStatus, withActionsMenu, breakdownStats, isEditingName]
    )
    const containerGrabClassName = useMemo(() => {
        if (isAThemeBeingMerged) return "cursor-pointer"
        if (codeStatus === undefined && isAllowedToDrag) return "cursor-grab"

        switch (codeStatus) {
            case "selected": { return "cursor-grab" }
            case "merging": { return "cursor-default" }
            case "available-to-merge": { return "cursor-pointer" }
            case "available-to-cluster": { return "cursor-pointer" }
            case "clustering": { return "cursor-pointer" }
            default: { return "" }
        }
    }, [codeStatus, isAThemeBeingMerged, isAllowedToDrag])
    const containerBackgroundColor = useMemo(() => {
        if (breakdownStats) return `var(--color${categoryColorIndexes[codeStats.category.id]}-light)`

        return isGrey
            ? "var(--color-glaut-dummie-color)"
            : `var(--color${normalizeToColorIndex(colorIndex)}-light)`
    }, [colorIndex, isGrey, breakdownStats, categoryColorIndexes, codeStats])
    // #endregion

    // #region Callbacks
    const handleContainerClick = useCallback((e: ReactMouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation()

        if (isEditingName) return

        if (breakdownStats) {
            if (codeStatus === "selected")
                analysisTabDispatch({ type: "clear-selected-category" })
            else
                analysisTabDispatch({
                    type: "set-selected-category",
                    category: codeStats,
                    selectedCategorySegmentId: themeId
                })

            return
        }

        switch (codeStatus) {
            case "available-to-merge": {
                analysisTabAnalysisColDispatch({
                    type: "set-target-category-being-merged",
                    category: { ...codeStats, themeId, index: colorIndex }
                })
                break
            }
            case "available-to-cluster": {
                analysisTabAnalysisColDispatch({
                    type: "add-code-to-sub-theme-cluster",
                    code: codeStats
                })
                break
            }
            case "clustering": {
                analysisTabAnalysisColDispatch({
                    type: "remove-code-from-sub-theme-cluster",
                    codeId: codeStats.category.id
                })
                break
            }
            case "selected": {
                analysisTabDispatch({ type: "clear-selected-category" })
                break
            }
            default: {
                analysisTabDispatch({
                    type: "set-selected-category",
                    category: codeStats
                })
            }
        }
    }, [
        analysisTabAnalysisColDispatch,
        analysisTabDispatch,
        codeStats,
        colorIndex,
        themeId,
        codeStatus,
        breakdownStats,
        isEditingName
    ])

    const handleDismissEditingInput = useCallback(() => {
        setIsEditingName(false)
        analysisTabAnalysisColDispatch({ type: "clear-category-being-edited" })
    }, [analysisTabAnalysisColDispatch])

    const handleApplyEdition = useCallback((e?: ReactMouseEvent) => {
        e?.stopPropagation()

        if (!project || !selectedAnalysis || !editionInputRef.current) return

        const label = editionInputRef.current.value
        if (label === "") return

        const projectId = project._id
        const analysisId = selectedAnalysis.id
        const categoryId = codeStats.category.id

        analysisService.updateAnalysisCategory({
            projectId,
            analysisId,
            categoryId,
            category: { ...codeStats.category, label }
        }).then(() => {
            analysisTabDispatch({ type: "update-theme-labels", analysisId, themesData: [{ id: categoryId, label }] })
            handleDismissEditingInput()
        })
    }, [analysisService, analysisTabDispatch, codeStats.category, handleDismissEditingInput, project, selectedAnalysis])
    // #endregion

    // #region Effects

    // onRenderTurnIntoDraggable
    useEffect(() => draggable({
        element: containerDivRef.current!,
        getInitialData: () => ({ stats: codeStats }),
        canDrag: () => isAllowedToDrag,
        onGenerateDragPreview: () => setIsBeingDragged(true),
        onDrop: () => setIsBeingDragged(false)
    }), [codeStats, isAllowedToDrag])

    // onTextEllipsisShowTooltip
    useEffect(() => {
        if (!codeParagraphRef.current) return
        setHasTooltip(isEllipsisActive(codeParagraphRef.current))
    }, [codeStats.category.label])

    // onEditingCategoryCheckIfIsEditingThisCode
    useEffect(() => {
        if (!(categoryBeingEdited?.id === codeStats.category.id && categoryBeingEdited?.type === "code")) {
            setIsEditingName(false)
            return
        }

        setIsEditingName(true)
    }, [categoryBeingEdited?.id, categoryBeingEdited?.type, codeStats.category.id])

    // onEditingAttachKeydownEvents
    useEffect(() => {
        if (!isEditingName || !editionInputRef.current) return

        const elem = editionInputRef.current

        function onKeyDown(e: KeyboardEvent) {
            e.stopPropagation()

            if (e.key === "Enter") handleApplyEdition()
            if (e.key === "Escape") handleDismissEditingInput()
        }

        elem.addEventListener("keydown", onKeyDown)

        return () => { elem.removeEventListener("keydown", onKeyDown) }
    }, [handleApplyEdition, handleDismissEditingInput, isEditingName])

    // #endregion

    return (
        <div
            className={`flex flex-row rounded-full pr-[0.3125em] pl-[0.0625em] gap-[0.25em] items-center
                border-1 max-w-[45%]
                ${containerGrabClassName}
                ${codeStatus === "selected" ? "border-glaut-text-midnight" : ""}
                ${codeStatus !== "selected" ? "border-transparent" : ""}
            `}
            style={{ backgroundColor: containerBackgroundColor }}
            ref={containerDivRef}
            onClick={handleContainerClick}
            data-tooltip-id="tooltip--glaut-app"
            data-tooltip-content={codeStats.category.label}
            data-tooltip-place="right"
            data-tooltip-hidden={!hasTooltip}
        >
            <div className="py-[0.0625em]">
                <CodeOccurrencesBox
                    number={codeStats.occurrences}
                    percentage={codeStats.frequency}
                />
            </div>
            {!isEditingName && (
                <p className="text-[13.33px] text-ellipsis whitespace-nowrap overflow-hidden rounded-none leading-[18px]
                    text-glaut-text-midnight" ref={codeParagraphRef}>
                    {codeStats.category.label}
                </p>
            )}
            {isEditingName && (
                <>
                    <input
                        ref={element => {
                            if (!element) {
                                editionInputRef.current = undefined
                                return
                            }

                            editionInputRef.current = element
                            element.focus()
                        }}
                        className="text-[13.33px] leading-[18px] mr-1
                        border-none shadow-none rounded-none bg-transparent m-0 p-0 focus:shadow-none"
                        placeholder={codeStats.category.label}
                    />
                    <MdClose className="h-[1em] w-[1em] cursor-pointer" onClick={handleDismissEditingInput} />
                    <MdCheck className="h-[1em] w-[1em] cursor-pointer" onClick={handleApplyEdition} />
                </>
            )}
            {shouldShowActionsMenuButton && (
                <>
                    <button
                        className="border-0 shadow-none bg-transparent p-0 m-0 text-base"
                        onClick={e => {
                            e.stopPropagation()
                            setIsShowingActionsMenu(true)
                        }}
                    >
                        <MdMoreVert className="h-[0.875em] w-[0.875em] text-glaut-text-midnight" />
                    </button>
                    <AnalysisTabAnalysisColThemeAndCodeActionsMenu
                        isOpen={isShowingActionsMenu}
                        onClose={() => { setIsShowingActionsMenu(false) }}
                        anchorRef={containerDivRef}
                        categoryStats={codeStats}
                        parentCategoryId={themeId}
                        categoryIndex={colorIndex}
                        openPlacement={isFromLastTheme ? "up" : "down"}
                    />
                </>
            )}
        </div>
    )
}
