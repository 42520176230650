import { DetailedHTMLProps, HTMLAttributes } from "react"

interface IModalLayoutProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    isOpen: boolean
    onClose?: () => void
    children: React.ReactNode
}

export default function ModalLayout({
    isOpen,
    onClose,
    children,
    ...props
}: Readonly<IModalLayoutProps>) {
    return (
        <div
            className={`
                fixed inset-0 flex justify-center items-center transition-colors overflow-hidden z-[1000]
                ${isOpen ? "visible bg-black/20" : "invisible"}
            `}
            onClick={onClose}
        >
            <div onClick={e => e.stopPropagation()} {...props}>
                {children}
            </div>
        </div>
    )
}