import GlautButtonSecondary from "@components/Buttons/GlautButtonSecondary"
import { useConversationOverlay } from "@components/ConversationOverlay/contexts/ConversationOverlayProvider"
import { copy, getCopy } from "@utils/Copy"
import { useCallback, useMemo, useState } from "react"
import { FaCheckCircle, FaRegTrashAlt, FaTimesCircle } from "react-icons/fa"
import { MdClose, MdOutlinePerson } from "react-icons/md"
import ConversationOverlayDeleteInterviewModal from "../ConversationOverlayDeleteInterviewModal"
import { useInterviewService } from "@hooks/services/useInterviewService"
import { conversationOverlayCopy } from "@components/ConversationOverlay/utils/copy"

interface IConversationOverlayHeaderProps {
    onClose: () => void
    onDeleteInterview: () => void
}

export default function ConversationOverlayHeader({
    onClose,
    onDeleteInterview
}: Readonly<IConversationOverlayHeaderProps>) {
    // #region Contexts
    const { interview, deletableEntry } = useConversationOverlay()
    // #endregion

    // #region Services
    const interviewService = useInterviewService()
    // #endregion

    // #region States
    const [isDeleteModalShown, setIsDeleteModalShown] = useState(false)
    // #endregion

    // #region Memos
    const statusData = useMemo(() => ({
        completed: {
            text: getCopy(conversationOverlayCopy.status.completed),
            icon: <FaCheckCircle className="text-glaut-codes-green h-[0.875rem] w-[0.875rem]" />
        },
        uncompleted: {
            text: getCopy(conversationOverlayCopy.status.uncompleted),
            icon: <FaTimesCircle className="text-glaut-codes-orange h-[0.875rem] w-[0.875rem]" />
        },
        uncooperative: {
            text: getCopy(copy.words.nonCooperative),
            icon: <FaTimesCircle className="text-glaut-codes-red h-[0.875rem] w-[0.875rem]" />
        },
        screenout: {
            text: getCopy(copy.words.screenedOut),
            icon: <FaTimesCircle className="text-glaut-codes-red h-[0.875rem] w-[0.875rem]" />
        },
        quotafull: {
            text: getCopy(copy.words.quotafull),
            icon: <FaTimesCircle className="text-glaut-codes-red h-[0.875rem] w-[0.875rem]" />
        }
    }[interview.termination_reason ?? "uncompleted"]), [interview])
    // #endregion

    // #region Callbacks
    const handleShowDeletionModal = useCallback(() => {
        setIsDeleteModalShown(true)
    }, [])

    const handleCloseDeletionModal = useCallback(() => {
        setIsDeleteModalShown(false)
    }, [])

    const handleDeleteInterview = useCallback(() => {
        interviewService.deleteInterview({ interviewId: interview._id, projectId: interview.project_id })
            .then(() => {
                onDeleteInterview()
                onClose()
            })
    }, [interview._id, interview.project_id, interviewService, onClose, onDeleteInterview])
    // #endregion

    return (
        <div className="flex gap-[1em] justify-between items-center pl-[0.25em] pr-[0.75em]">
            <div className="flex gap-[0.75em] items-center max-w-[90%]">
                <MdOutlinePerson className="h-[1em] w-[1em] text-glaut-grey" />
                <p className={`font-medium text-[16px] text-glaut-text-midnight rounded-none
                    overflow-hidden whitespace-nowrap text-ellipsis flex-1
                `}>
                    {interview.identifier}
                </p>
                {statusData.icon}
                {deletableEntry && (
                    <GlautButtonSecondary className="p-1" onClick={handleShowDeletionModal}>
                        <FaRegTrashAlt className="w-[1em] h-[1em]" />
                    </GlautButtonSecondary>
                )}
            </div>
            <MdClose className="text-glaut-grey h-[1.25em] w-[1.25em] cursor-pointer" onClick={onClose} />

            <ConversationOverlayDeleteInterviewModal
                isOpen={isDeleteModalShown}
                onClose={handleCloseDeletionModal}
                onConfirm={handleDeleteInterview}
            />
        </div>
    )
}