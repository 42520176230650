import { DetailedHTMLProps, SVGAttributes } from "react"

interface IGlautIconProps
    extends DetailedHTMLProps<SVGAttributes<SVGSVGElement>, SVGSVGElement> {

}

export default function GlautIcon({
    fill,
    height = "16",
    viewBox = "0 0 16 16",
    width = "16",
    ...props
}: Readonly<IGlautIconProps>) {
    return (
        <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M14.8099 9.07256L9.26024 7.05761C10.3227 6.28293 11.3048 5.69409 12.0291 5.51216C12.6047 5.36935 12.9702 4.714 12.8456 4.05083C12.7226 3.39548 12.1657 2.96119 11.5696 3.11573C10.3944 3.40917 8.87079 4.38339 7.36595 5.54541L6.69638 1.83438C6.57852 1.17511 6.03022 0.75256 5.43067 0.879717C4.85162 1.0147 4.47926 1.66614 4.59712 2.32931L5.183 5.57671L1.73775 4.32666C1.1792 4.12712 0.572823 4.47925 0.391764 5.12482C0.214121 5.77234 0.524996 6.4629 1.08867 6.66635L4.54758 7.92228C3.03078 9.29753 1.8539 10.4987 1.57719 10.7843C1.13821 11.2362 1.10234 12.0148 1.50032 12.5175C1.71042 12.7856 2.00251 12.9205 2.2963 12.9205C2.55081 12.9205 2.80873 12.8169 3.01541 12.6056C3.64912 11.9522 4.72352 10.8978 5.94652 9.79638L6.73395 14.1647C6.83815 14.7438 7.28567 15.1429 7.78444 15.1429C7.85447 15.1429 7.92792 15.135 8.00137 15.1194C8.58042 14.9844 8.95449 14.333 8.83492 13.6698L8.02699 9.18407L14.1659 11.4123C14.2735 11.4514 14.3828 11.469 14.4905 11.469C14.9431 11.469 15.365 11.1364 15.5119 10.6141C15.6895 9.96658 15.3787 9.27601 14.815 9.07256"
                fill={fill} />
        </svg>
    )
}