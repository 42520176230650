import GlautSwitch from "@components/inputs/GlautSwitch"
import { ChangeEventHandler } from "react"
import { MdInfo } from "react-icons/md"

interface IOutlineTabContentDefaultToggleProps {
    onChange?: ChangeEventHandler<HTMLInputElement>
    checked?: boolean
    disabled?: boolean
    label: string
    tooltipContent?: string
}

export default function OutlineTabContentDefaultToggle({
    checked = false,
    disabled = false,
    tooltipContent,
    label,
    onChange
}: Readonly<IOutlineTabContentDefaultToggleProps>) {
    return (
        <div className="flex gap-[0.5em] items-center">
            <GlautSwitch
                onChange={onChange}
                checked={checked}
                disabled={disabled}
            />
            <p className="text-[13.33px] font-medium text-glaut-text-midnight">
                {label}
            </p>
            {tooltipContent && (
                <MdInfo
                    className="text-glaut-grey h-[1em] w-[1em]"
                    data-tooltip-id="tooltip--glaut-app"
                    data-tooltip-content={tooltipContent}
                    data-tooltip-place="right"
                />
            )}
        </div>
    )
}