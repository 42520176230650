import EditableTitle from "@components/inputs/EditableTitle"
import Button from "@components/layouts/Button"
import DeletePopup from "@components/layouts/DeletePopup"
import Loading from "@components/loading/Loading"
import { useProjectService } from "@hooks/services/useProjectService"
import { copy, getCopy } from "@utils/Copy"
import { getProjectLang } from "@utils/language"
import { deepCopy } from "@utils/miscellaneous"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { FaExclamationCircle, FaFileCsv } from "react-icons/fa"
import { MdClose, MdOutlineCancel } from "react-icons/md"
import { useNavigate, useParams } from "react-router-dom"
import { IInterviewQuestion } from "src/@types/interview-question"
import { useLegacyProject, useProject, useProjectDispatch } from "../../contexts/ProjectProvider"
import { normalizeCSVData } from "../../utils/normalize-csv-data"
import CSVSettingsTabProjectProjectLanguage from "../CSVSettingsTabProjectProjectLanguage"
import CSVSettingsTabProjectTitle from "../CSVSettingsTabProjectTitle"
import Papa from "papaparse"

const settingsCopy = copy.coding.settings

interface ICsvSettingsTabProjectSettingsProps {
    file?: File
    fileContent?: ReturnType<typeof normalizeCSVData>
    setFileContent: React.Dispatch<React.SetStateAction<ReturnType<typeof normalizeCSVData> | undefined>>
    selectedColumn?: number
    setSelectedColumn: React.Dispatch<React.SetStateAction<number | undefined>>
}

export default function CSVSettingsTabProjectSettings({
    file,
    fileContent,
    setFileContent,
    setSelectedColumn
}: Readonly<ICsvSettingsTabProjectSettingsProps>) {
    // #region Params
    const { projectId } = useParams()
    // #endregion

    // #region Hooks
    const navigate = useNavigate()
    // #endregion

    // #region Contexts
    const { interviewQuestions } = useProject()
    const projectDispatch = useProjectDispatch()
    const { project } = useLegacyProject()
    // #endregion

    // #region Services
    const projectService = useProjectService()
    // #endregion

    // #region States
    const [openDelete, setOpenDelete] = useState(false)
    const [updateColumnTimeout, setUpdateColumnTimeout] = useState<NodeJS.Timeout>()
    const [currInterviewQuestions, setCurrInterviewQuestions] = useState<IInterviewQuestion[]>([])
    const [isImporting, setIsImporting] = useState(false)
    // #endregion

    // #region Memos

    const lang = useMemo(() => getProjectLang(project), [project])

    // Set questionIds from schema
    const questionIds = useMemo(
        () => interviewQuestions?.map(q => q.id) ?? [],
        [interviewQuestions]
    )
    // Set headers from file or from schema
    const headers = useMemo(
        () => fileContent
            ? fileContent.headers.slice(1)
            : currInterviewQuestions.map(q => q.content[lang] ?? q.header ?? ""),
        [fileContent, currInterviewQuestions, lang]
    )

    // #endregion

    // #region Callbacks

    const reloadInterviewQuestions = useCallback(() => {
        if (!projectId) return

        projectService.getInterviewQuestions({ projectId })
            .then(({ interviewQuestions }) => {
                projectDispatch({ type: "set-interview-questions", interviewQuestions })
            })
    }, [projectDispatch, projectId, projectService])

    const setHeaders = useCallback((index: number, value: string) => {
        if (!project || !interviewQuestions) return

        if (fileContent) {
            // Update column header during file import
            const newHeaders = [...fileContent.headers]
            newHeaders[index + 1] = value
            setFileContent({ ...fileContent, headers: newHeaders })

            return
        }

        const newIqs = deepCopy(currInterviewQuestions)
        const iq = newIqs[index]
        if (!iq) return

        // Change in pointer
        iq.content[lang] = value
        setCurrInterviewQuestions(newIqs)

        if (updateColumnTimeout) clearTimeout(updateColumnTimeout)
        setUpdateColumnTimeout(setTimeout(() => {
            // Update project question
            projectService.updateInterviewQuestion({
                projectId: project._id,
                questionId: questionIds[index],
                data: {
                    ...iq,
                    content: { ...iq.content, [lang]: value }
                }
            }).then(() => {
                reloadInterviewQuestions()
            })
        }, 1000))
    }, [
        currInterviewQuestions,
        fileContent,
        interviewQuestions,
        lang,
        project,
        projectService,
        questionIds,
        reloadInterviewQuestions,
        setFileContent,
        updateColumnTimeout
    ])

    const handleCsvImporting = useCallback(() => {
        if (!projectId || !fileContent) return

        setIsImporting(true)

        const rows = fileContent.rows.map(r => r.map(c => c?.toString() ?? ""))
        rows.unshift(fileContent.headers)

        const csv = Papa.unparse(rows, {
            header: true,
            delimiter: ",",
            newline: "\n",
            quotes: true
        })

        projectService.importCsv({ projectId, csv: new Blob([csv]), includedHeaders: fileContent.headers })
            .then(() => {
                window.location.reload()
            })
            .catch(e => {
                console.log(e)
                setIsImporting(false)
            })
    }, [fileContent, projectId, projectService])

    const handleDeleteColumn = useCallback((index: number) => {
        if (!project) return

        if (fileContent) {
            // Delete column during file import
            const types = [...fileContent.types]
            types.splice(index, 0)
            const headers = [...fileContent.headers]
            headers.splice(index, 1)
            const rows = fileContent.rows.map(row => {
                const newRow = [...row]
                newRow.splice(index, 1)
                return newRow
            })

            setFileContent({ types, headers, rows })

            return
        }

        // Delete column (question) from project
        projectService.deleteInterviewQuestion({ projectId: project._id, questionId: questionIds[index - 1] })
            .then(() => {
                reloadInterviewQuestions()
            })
    }, [fileContent, project, projectService, questionIds, setFileContent, reloadInterviewQuestions])

    // #endregion

    // #region Effects

    // onUpdateIqs
    useEffect(() => {
        if (!interviewQuestions) return
        setCurrInterviewQuestions(interviewQuestions)
    }, [interviewQuestions])

    // #endregion

    if (project?.source !== "CSV") return <></>

    return (
        <div className="project-settings h-full w-full max-w-[40%]">
            <h2 className="title">
                {getCopy(settingsCopy.projectSettings)}
            </h2>
            <div className="scroll">
                {file && (
                    <div className="file-name">
                        <FaFileCsv className="icon" />
                        <span>{file.name}</span>
                        <MdOutlineCancel className="delete" onClick={() => setFileContent(undefined)} />
                    </div>
                )}
                <div className="general-settings flex flex-col gap-[0.5rem]">
                    <CSVSettingsTabProjectTitle />
                    <CSVSettingsTabProjectProjectLanguage />
                </div>
                <div className="instructions">
                    <div className="instructions-title">
                        <FaExclamationCircle />
                        {getCopy(settingsCopy.csvSettings.writeYourQuestion)}
                    </div>
                    <div className="instructions-body">{getCopy(settingsCopy.csvSettings.writeYourQuestionText)}</div>
                </div>
                <div className="column-names">
                    {headers.map((s, i) => (
                        <div key={i} className="flex justify-between items-center gap-[0.5rem]">
                            <div className="flex-1 min-w-0">
                                <EditableTitle
                                    value={s || ""}
                                    onChange={value => setHeaders(i, value)}
                                    placeholder={getCopy(settingsCopy.csvSettings.questionPlaceholder)?.(i + 1) ?? ""}
                                    fontSize="13.33px"
                                    onInputFocus={() => setSelectedColumn(i)}
                                    onInputBlur={() => setSelectedColumn(undefined)}
                                />
                            </div>
                            <button
                                className="p-0 m-0 border-none shadow-none text-base"
                                onClick={() => handleDeleteColumn(i + 1)}
                                disabled={headers.length === 1}
                            >
                                <MdClose className="h-[1em] w-[1em] text-glaut-dark-grey" />
                            </button>
                        </div>
                    ))}
                </div>
                <div className="other-settings">
                    {!file && (
                        <div className="box danger-zone" style={{ border: "1px solid var(--danger-color)" }}>
                            <h2>Danger zone</h2>
                            <div className="long-bar">
                                <div className="column" style={{ gap: "4px", alignItems: "flex-start" }}>
                                    <span style={{ fontWeight: "bold" }}>Delete this project</span>
                                    <span>There is no going back. Please be certain.</span>
                                </div>
                            </div>

                            <div className="long-bar">
                                <button className="danger-button" onClick={() => setOpenDelete(true)}>
                                    Delete
                                </button>
                            </div>
                        </div>
                    )}
                    {openDelete && (
                        <DeletePopup
                            confirmString={project.name || "Untitled"}
                            entityName="project"
                            close={() => setOpenDelete(false)}
                            onConfirm={() => {
                                projectService.deleteProject({ projectId: project._id }).then(() => {
                                    setOpenDelete(false)
                                    navigate(`/w/${project.workspace_id}`)
                                })
                            }}
                        />
                    )}
                </div>
                {fileContent && (
                    <div>
                        <Button
                            className="primary centered import"
                            label={
                                !isImporting
                                    ? getCopy(settingsCopy.csvSettings.importData)
                                    : <Loading />
                            }
                            disabled={isImporting}
                            onClick={handleCsvImporting}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}
