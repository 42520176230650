import { useGlautAuthInfo } from "@hooks/useGlautAuthInfo"
import { useEffect, useRef } from "react"
import { settings } from "../utils/Variables"

const BASE_URL = settings.wsUrl

const useAuthSocket = () => {
    const authInfo = useGlautAuthInfo()
    const authInfoRef = useRef(authInfo)

    useEffect(() => {
        authInfoRef.current = authInfo
    }, [authInfo])

    const getSocket = path => {
        const sock = new WebSocket(BASE_URL.concat(path))
        sock.onopen = () => sock.send(JSON.stringify({token: authInfoRef.current.accessToken}))
        return sock
    }

    return getSocket
}

export { BASE_URL as baseWS }

export default useAuthSocket
