import PdfIcon from "@components/Pdf/PdfIcon"
import LoadingBar from "@components/loading/LoadingBar"
import { useToPng } from "@hugocxl/react-to-image"
import { Text, View } from "@react-pdf/renderer"
import { namedColors } from "@utils/Variables"
import {
    extractDescriptionFromSegment,
    extractIdFromSegment,
    extractLabelFromSegment,
    isStatsACategoryStats
} from "@utils/analysis/segmentation"
import { getProjectLang } from "@utils/language"
import {
    bgColorWithCssColorVarFromIndex,
    normalizeToColorIndex,
    textColorWithCssColorVarFromIndex
} from "@utils/styling/colors-from-index"
import { useMemo, useState } from "react"
import { FaHashtag, FaTag } from "react-icons/fa"
import { MdLayers, MdManageSearch } from "react-icons/md"
import slugify from "slugify"
import { IBaseInsight } from "src/@types/reports"
import { useReportDetails } from "../../contexts/ReportDetailsContext"
import ReportDetailsInsightBlockDownloadBar from "../ReportDetailsInsightBlockDownloadBar"
import ReportDetailsInsightBlockThemeSegmentedTheme from "../ReportDetailsInsightBlockThemeSegmentedTheme"

interface IReportDetailsInsightBlockThemesProps {
    block: IBaseInsight
    isDownloadable?: boolean
}

export default function ReportDetailsInsightBlockThemes({
    block,
    isDownloadable = false
}: Readonly<IReportDetailsInsightBlockThemesProps>) {
    // #region Contexts
    const { project, renderAsPdf, tw } = useReportDetails()
    // #endregion

    // #region States
    const [isExporting, setIsExporting] = useState(false)
    // #endregion

    // #region React to Image
    const [, convertToPng, ref] = useToPng<HTMLDivElement>({
        onStart: () => {
            setIsExporting(true)
        },
        onSuccess: data => {
            const link = document.createElement("a")
            link.download = `${slugify(block.title)}-themes.png`
            link.href = data
            link.click()

            setTimeout(() => {
                link.remove()
                setIsExporting(false)
            }, 500)
        },
        onError: () => {
            setIsExporting(false)
        }
    })
    // #endregion

    // #region Memos
    const lang = useMemo(() => getProjectLang(project), [project])
    const isSegmentingApplied = useMemo(() => !!block.segment_by, [block])
    const categoryStats = useMemo(() => block.stats ?? [], [block])
    const formattedThemes = useMemo(() => {
        if (!Array.isArray(categoryStats)) return []

        if (categoryStats.every(isStatsACategoryStats))
            return categoryStats
                .filter(stats => extractIdFromSegment(stats.category) !== "empty")
                .map((stats, idx) => ({
                    index: idx,
                    categoryId: extractIdFromSegment(stats.category),
                    label: extractLabelFromSegment(stats.category, lang),
                    description: extractDescriptionFromSegment(stats.category, lang),
                    percentage: stats.frequency,
                    occurrences: stats.occurrences,
                    children: []
                })).sort((a, b) => b.occurrences - a.occurrences)

        return categoryStats
            .filter(stats => extractIdFromSegment(stats.segment.category) !== "empty")
            .map((stats, idx) => ({
                index: idx,
                categoryId: extractIdFromSegment(stats.segment.category),
                label: extractLabelFromSegment(stats.segment.category, lang),
                description: extractDescriptionFromSegment(stats.segment.category, lang),
                percentage: stats.segment.frequency,
                occurrences: stats.segment.occurrences,
                children: Array.isArray(stats.segmented)
                    ? stats.segmented.map(segmentedStats => ({
                        id: extractIdFromSegment(segmentedStats.category),
                        label: extractLabelFromSegment(segmentedStats.category, lang),
                        frequency: segmentedStats.frequency,
                        occurrences: segmentedStats.occurrences
                    }))
                    : []
            })).sort((a, b) => b.occurrences - a.occurrences)
    }, [categoryStats, lang])
    // #endregion

    if (renderAsPdf)
        return (
            <View style={tw("flex flex-col bg-glautOffWhite w-full")}>
                {formattedThemes.map(theme => (
                    <View
                        key={theme.categoryId}
                        style={tw("flex flex-col px-[5.09px]")}
                    >
                        <View
                            style={[
                                tw("flex flex-row justify-between w-full border-b-1 py-[11.18px]"),
                                tw("border-b-glautStrokeGlaut")
                            ]}
                        >
                            <View style={tw("flex flex-row items-center gap-[5.09px] max-w-[50%]")}>
                                {isSegmentingApplied && (
                                    <PdfIcon
                                        icon={MdLayers}
                                        color={namedColors[`color${normalizeToColorIndex(theme.index)}`]}
                                        size={"10.18px"}
                                    />
                                )}
                                {!isSegmentingApplied && (
                                    <PdfIcon
                                        icon={FaTag}
                                        color={namedColors[`color${normalizeToColorIndex(theme.index)}`]}
                                        size={"10.18px"}
                                    />
                                )}
                                <Text style={tw("font-sans text-[8.48px] font-medium text-glautTextMidnight")}>
                                    {theme.label ?? ""}
                                </Text>
                            </View>
                            <View style={tw("flex flex-row items-center w-[45%] justify-end")}>
                                <Text style={tw("text-[12.22px] text-glautTextMidnight w-10")}>
                                    {Math.round(theme.percentage * 100)}%
                                </Text>
                                <View style={tw("bg-glautCards h-[2.14px] flex-1 rounded-full")}>
                                    <View style={[tw("h-full rounded-full"), {
                                        backgroundColor: namedColors[`color${normalizeToColorIndex(theme.index)}`],
                                        width: `${theme.percentage * 100}%`
                                    }]}></View>
                                </View>
                                <View style={tw("flex flex-row items-center justify-end gap-[1.91px] w-10")}>
                                    <PdfIcon
                                        icon={FaHashtag}
                                        color={namedColors.grey}
                                        style={{ marginBottom: "2px" }}
                                        size={"8.91px"}
                                    />
                                    <Text style={tw("text-[12.22px] text-glautTextMidnight")}>
                                        {theme.occurrences}
                                    </Text>
                                </View>
                            </View>
                        </View>
                        {isSegmentingApplied && (
                            <View
                                style={[
                                    tw("px-[8.48px] py-[5.09px] flex flex-row flex-wrap"),
                                    { rowGap: "10.18px", columnGap: "5.09px" }
                                ]}
                            >
                                {theme.children.map(segmentedTheme => (
                                    <ReportDetailsInsightBlockThemeSegmentedTheme
                                        key={segmentedTheme.id}
                                        colorIndex={theme.index}
                                        label={segmentedTheme.label}
                                        frequency={segmentedTheme.frequency}
                                        occurrences={segmentedTheme.occurrences}
                                    />
                                ))}
                            </View>
                        )}
                    </View>
                ))}
            </View>
        )

    return (
        <div className="flex flex-col gap-[1.5em] bg-glaut-off-white p-1" ref={ref}>
            <div className="flex flex-col w-full">
                {formattedThemes.map(theme => (
                    <div key={theme.categoryId}>
                        <div
                            className={`flex gap-[0.5em] px-[0.5em] w-full border-b-1 
                                border-b-glaut-stroke-glaut
                                lg:flex-row lg:justify-between 
                                ${block.type === "select" ? "flex-row justify-between" : "flex-col"}
                                ${block.type === "open" ? "py-[1em]" : "py-[0.75em]"}
                            `}
                        >
                            <div className={`flex items-center gap-[0.5em] w-full
                                lg:max-w-[65%] xl:max-w-[55%] 2xl:max-w-[65%]
                            `}>
                                {isSegmentingApplied && (
                                    <MdLayers className={`w-[1.125em] h-[1.125em]
                                        ${textColorWithCssColorVarFromIndex(theme.index)}
                                    `} />
                                )}
                                {!isSegmentingApplied && (
                                    <FaTag className={`w-[1em] h-[1em]
                                        ${textColorWithCssColorVarFromIndex(theme.index)}
                                    `} />
                                )}
                                <p className={`text-[13.33px] font-medium text-glaut-text-midnight
                                    overflow-hidden whitespace-nowrap text-ellipsis
                                `}>
                                    {theme.label}
                                </p>
                                {block.type === "open" && !isExporting && (
                                    <MdManageSearch
                                        data-tooltip-id="tooltip--glaut-app"
                                        data-tooltip-content={theme.description}
                                        data-tooltip-place="right"
                                        data-tooltip-class-name="max-w-[400px]"
                                        className="w-[1.125em] h-[1.125em] text-glaut-grey hover:text-glaut-dark-grey"
                                    />
                                )}
                            </div>
                            <div className="flex items-center min-w-[35%] justify-end pt-[0.5em] lg:pt-0">
                                <p className="text-[19.2px] text-glaut-text-midnight min-w-12">
                                    {Math.round(theme.percentage * 100)}%
                                </p>
                                {block.type === "open" && (
                                    <LoadingBar
                                        percentage={theme.percentage * 100}
                                        showPercentage={false}
                                        loadingBarFullClassName={`loading-bar-full
                                            ${bgColorWithCssColorVarFromIndex(theme.index)}
                                        `}
                                        loadingBarDotClassName={`loading-bar-dot
                                            ${bgColorWithCssColorVarFromIndex(theme.index)}
                                        `}
                                        style={{ flex: 1 }}
                                    />
                                )}
                                <div className="flex items-center justify-end gap-[0.25em] min-w-12">
                                    <FaHashtag className="w-[0.875em] h-[0.875em] text-glaut-grey mb-[0.20em]" />
                                    <p className="text-[19.2px] text-glaut-text-midnight">
                                        {theme.occurrences}
                                    </p>
                                </div>
                            </div>
                        </div>
                        {isSegmentingApplied && (
                            <div
                                className="px-[0.75em] py-[0.5em] flex flex-wrap"
                                style={{ rowGap: "1em", columnGap: "0.5em" }}
                            >
                                {theme.children.map(segmentedTheme => (
                                    <ReportDetailsInsightBlockThemeSegmentedTheme
                                        key={segmentedTheme.id}
                                        colorIndex={theme.index}
                                        label={segmentedTheme.label}
                                        frequency={segmentedTheme.frequency}
                                        occurrences={segmentedTheme.occurrences}
                                    />
                                ))}
                            </div>
                        )}
                    </div>
                ))}
            </div>


            {isDownloadable && (
                <ReportDetailsInsightBlockDownloadBar
                    onDownload={convertToPng}
                    showDownloadButton={!isExporting}
                />
            )}
        </div>
    )
}