import { copy, getCopy } from "@utils/Copy"
import { useCallback, useMemo, useState } from "react"
import { MdClose, MdFormatListBulleted } from "react-icons/md"
import { useReportDetails, useReportDetailsDispatch } from "../../contexts/ReportDetailsContext"
import { useReportDetailsHeaderHeight } from "../../hooks/useReportDetailsHeaderHeight"

export default function ReportDetailsIndex() {
    // #region Contexts
    const { report, renderAsPdf } = useReportDetails()
    const reportDetailsDispatch = useReportDetailsDispatch()
    // #endregion

    // #region Custom hooks
    const headerHeight = useReportDetailsHeaderHeight()
    // #endregion

    // #region States
    const [isExpanded, setIsExpanded] = useState(false)
    // #endregion

    // #region Callbacks
    const handleClickOnIndexOption = useCallback((blockId: string) => {
        reportDetailsDispatch({ type: "mark-block-to-scroll", blockId })
        setIsExpanded(false)
    }, [reportDetailsDispatch])
    // #endregion

    // #region Element memos
    const indexOptionsElement = useMemo(() => report.appendix.filter(i => i.type !== "image").map((i, idx) => (
        <button
            key={i.id}
            className={`rounded-none shadow-none border-none flex justify-start items-start gap-[0.25em]
                cursor-pointer text-left py-[0.25em] px-[0.5em] w-full 
                bg-glaut-off-white
                hover:bg-glaut-cards
            `}
            onClick={() => { handleClickOnIndexOption(i.id) }}
        >
            <p className="text-[11.11px] font-medium text-glaut-grey min-w-3 text-center">
                {idx + 1}
            </p>
            <p className="text-[11.11px] font-medium text-glaut-text-midnight">
                {i.title}
            </p>
        </button>
    )), [report, handleClickOnIndexOption])
    // #endregion

    // #region Dynamic const values
    const indexMarginTop = `${headerHeight - 1}px`
    // #endregion

    if (!report || renderAsPdf) return <></>

    return (
        <>
            <div className={`fixed inset-0 w-full h-full z-10 ${isExpanded ? "visible" : "invisible"}`}
                onClick={() => { setIsExpanded(false) }}>
            </div>
            <div className={`absolute bottom-[1em] flex flex-col ml-[1em] z-50
                sm:top-[20%] sm:ml-[0.25em]
            `}>
                <button
                    className={`py-[0.25em] px-[0.5em] border-1 rounded-[0.25em] shadow-none 
                    bg-glaut-cards border-glaut-grey
                `}
                    onClick={() => { setIsExpanded(prev => !prev) }}
                >
                    <MdFormatListBulleted className="h-[1.5em] w-[1.5em] text-glaut-pink" />
                </button>
                <div className={`flex flex-col gap-[0.25em] rounded-e-[0.5em] min-w-[200px] max-w-[350px] border-1
                    transition-all overflow-hidden
                    bg-glaut-off-white border-glaut-grey shadow-[0px_0px_8px_0px_rgb(0,0,0,0.15)]
                    opacity-0 h-0 mt-0 py-0
                    invisible sm:visible
                    ${isExpanded ? "sm:opacity-100 sm:h-max sm:mt-[0.5em] sm:py-[1em]" : ""}
                    ${!isExpanded ? "opacity-0 h-0 mt-0 py-0" : ""}
                `}>
                    {indexOptionsElement}
                </div>
            </div>
            <div style={{ top: indexMarginTop }} className={`absolute bottom-0 z-10 w-[85%] visible 
                transition-[left,border]
                bg-glaut-off-white border-glaut-grey shadow-[0px_0px_8px_0px_rgb(0,0,0,0.15)]
                sm:w-0 sm:invisible
                ${isExpanded ? "border-1 left-0" : ""}
                ${!isExpanded ? "border-0 -left-[85%]" : ""}
            `}>
                <div className="flex flex-col mx-[0.75em] gap-[0.5em] my-[1em]">
                    <div className="flex items-center justify-between">
                        <p className="text-[13.33px] font-medium text-glaut-grey">
                            {getCopy(copy.reports.index)}:
                        </p>
                        <MdClose
                            className="w-[1.25em] h-[1.25em] text-glaut-grey"
                            onClick={() => { setIsExpanded(false) }}
                        />
                    </div>
                    <div className="flex flex-col gap-[0.25em] pl-[0.625em] py-[0.75em]">
                        {indexOptionsElement}
                    </div>
                </div>
            </div>
        </>
    )
}