import { Text, View } from "@react-pdf/renderer"
import Markdown from "react-markdown"
import { useReportDetails } from "../../contexts/ReportDetailsContext"
import { markdownToPdfComponents } from "../../utils/markdown-components"

interface IReportDetailsContentStandardTextSessionProps {
    title: string
    content?: string
}

export default function ReportDetailsContentStandardTextSession({
    title,
    content
}: Readonly<IReportDetailsContentStandardTextSessionProps>) {
    // #region Contexts
    const { renderAsPdf, tw } = useReportDetails()
    // #endregion

    if (renderAsPdf)
        return (
            <View style={tw("flex flex-col gap-[8.91px]")}>
                <Text style={[
                    tw("text-[12.2px] px-[10.18px] pt-[10.18px] pb-[2.55px] border-b-1 font-serif"),
                    tw("text-glautDarkGrey border-b-glautVeryLightGrey")
                ]}>
                    {title}
                </Text>
                {content && (
                    <View style={tw("px-[10.18px]")}>
                        <Markdown components={markdownToPdfComponents}>
                            {content}
                        </Markdown>
                    </View>
                )}
            </View>
        )

    return (
        <div className="flex flex-col gap-[0.875em] py-[1em]">
            <p className={`text-[19.2px] text-glaut-dark-grey px-[16px] mb-[0.25em] border-b-1 font-serif
                border-b-glaut-very-light-grey
            `}>
                {title}
            </p>
            {content && (
                <Markdown className="markdown text-glaut-dark-grey px-[16px]">
                    {content}
                </Markdown>
            )}
        </div>
    )
}