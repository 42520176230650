import { copy, getCopy } from "@utils/Copy"
import OutlineTabContentDefaultTitle from "../OutlineTabContentDefaultTitle"
import OutlineTabContentBrandingLogoPicker from "../OutlineTabContentBrandingLogoPicker"
import OutlineTabContentBrandingColorPicker from "../OutlineTabContentBrandingColorPicker"

export default function OutlineTabContentBranding() {
    return (
        <div className="flex gap-[1.5em]">
            <div className="flex flex-col gap-[0.25em] flex-1">
                <OutlineTabContentDefaultTitle title={getCopy(copy.outline.branding.logo) ?? ""} />
                <div className="flex flex-col gap-[0.75em]">
                    <OutlineTabContentBrandingLogoPicker />
                    <div className="flex gap-[1.5em]">
                        <OutlineTabContentBrandingColorPicker resource="font" />
                        {/* <OutlineTabContentBrandingColorPicker resource="background" /> */}
                    </div>
                </div>
            </div>
            <div className="flex-1"></div> {/** for alignment */}
        </div>
    )
}