import GlautButtonPrimary from "@components/Buttons/GlautButtonPrimary"
import GlautButtonSecondary from "@components/Buttons/GlautButtonSecondary"
import ModalLayout from "@components/layouts/ModalLayout"
import { useReportService } from "@hooks/services/useReportService"
import { useCallback } from "react"
import { useReportDetails, useReportDetailsDispatch } from "../../contexts/ReportDetailsContext"
import { IInsight } from "@/@types/reports"

interface IReportDetailsDeleteBlockModalProps {
    isOpen: boolean
    onClose?: () => void
    block: IInsight
}

export default function ReportDetailsDeleteBlockModal({
    isOpen,
    onClose,
    block
}: Readonly<IReportDetailsDeleteBlockModalProps>) {
    // #region Contexts
    const { report } = useReportDetails()
    const reportsDetailsDispatch = useReportDetailsDispatch()
    // #endregion

    // #region Services
    const reportService = useReportService()
    // #endregion

    // #region Callbacks
    const handleDeleteModal = useCallback(() => {
        if (!report) return

        const projectId = report.project_id
        const reportId = report.id
        const blockId = block.id

        reportService.deleteReportBlock({ blockId, projectId, reportId }).then(() => {
            reportsDetailsDispatch({ type: "remove-block-from-report", blockId, reportId })
        })
    }, [block.id, report, reportService, reportsDetailsDispatch])
    // #endregion

    return (
        <ModalLayout
            isOpen={isOpen}
            onClose={onClose}
            className={`
                flex flex-col bg-white rounded-[0.25em] shadow px-4 py-6 gap-3 transition-all w-72 border-[1px]
                border-glaut-grey
                ${isOpen ? "scale-100 opacity-100" : "scale-125 opacity-0"}
            `}
        >
            <div className="flex justify-center items-center text-center">
                <p className="text-[13.33px] font-medium text-glaut-text-midnight">
                    Are you sure you want to delete this insight from the report?
                </p>
            </div>
            <div className="flex flex-row justify-evenly">
                <GlautButtonSecondary onClick={onClose}>
                    Cancel
                </GlautButtonSecondary>
                <GlautButtonPrimary onClick={() => {
                    handleDeleteModal()
                    onClose?.()
                }}>
                    Remove
                </GlautButtonPrimary>
            </div>
        </ModalLayout>
    )
}