import ProjectSetup from "../OutlineTabSidebarProjectSetup"
import ProjectStatus from "../OutlineTabSidebarProjectStatus"
import ProjectTitle from "../OutlineTabSidebarProjectTitle"
import ShareInterview from "../OutlineTabSidebarShareInterview"

export default function OutlineTabSidebar() {
    return (
        <div className={`flex flex-col gap-[1.5em] rounded-[1em] border-1 w-[20%] py-[2em] px-[1.125em]
            border-glaut-stroke-glaut bg-glaut-off-white
        `}>
            <ProjectTitle />
            <div className="flex flex-col gap-[1.5em] overflow-y-auto overflow-x-hidden rounded-none">
                <ShareInterview />
                <ProjectStatus />
                <ProjectSetup />
            </div>
        </div>
    )
}