import { useOutlineTabIqs } from "../../contexts/OutlineTabIqsProvider"
import Body from "../OutlineTabContentIqsQuestionFormBody"
import Header from "../OutlineTabContentIqsQuestionFormHeader"

export default function OutlineTabContentIqsQuestionForm() {
    // #region Contexts
    const { iqToBeBuilt } = useOutlineTabIqs()
    // #endregion

    if (!iqToBeBuilt)
        return <div className="flex-1"></div> // intentional empty div instead of empty fragment for alignment

    return (
        <div className="flex-1 flex flex-col gap-[1.5em] px-[0.75em]">
            <Header />
            <Body />
        </div>
    )
}