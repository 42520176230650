import { Text } from "@react-pdf/renderer"
import { useReportDetails } from "../../contexts/ReportDetailsContext"

interface IReportDetailsDefaultBlockTitleProps {
    content: string
}

export default function ReportDetailsDefaultBlockTitle({
    content
}: Readonly<IReportDetailsDefaultBlockTitleProps>) {
    // #region Contexts
    const { renderAsPdf, tw } = useReportDetails()
    // #endregion

    if (renderAsPdf)
        return (
            <Text style={tw("text-[14.67px] text-glautTextMidnight font-serif")}>
                {content}
            </Text>
        )

    return (
        <p className="text-[23.04px] text-glaut-text-midnight font-serif">
            {content}
        </p>
    )
}