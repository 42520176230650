import GlautInputLogo from "@components/inputs/GlautInputLogo"
import { useProjectService } from "@hooks/services/useProjectService"
import { ChangeEvent, useCallback } from "react"
import { useLegacyProject } from "../../contexts/ProjectProvider"
import { useOutlineTab } from "../../contexts/OutlineTabProvider"

export default function OutlineTabContentBrandingLogoPicker() {
    // #region Contexts
    const { project, setProject } = useLegacyProject()
    const { canEdit } = useOutlineTab()
    // #endregion

    // #region Services
    const projectService = useProjectService()
    // #endregion

    // #region Callbacks
    const handleSelectLogo = useCallback(async (ev: ChangeEvent<HTMLInputElement>) => {
        if (!project) return

        const logoFile = ev.target.files?.[0]
        if (!logoFile) return

        const { url } = await projectService.setLogo({ projectId: project._id, logo: logoFile })
        setProject(prev => {
            if (prev?.source !== "interview") return prev
            return { ...prev, brand_settings: { ...prev.brand_settings, logo: url } }
        })
    }, [project, projectService, setProject])

    const handleRemoveLogo = useCallback(() => {
        setProject(prev => {
            if (prev?.source !== "interview") return prev
            return { ...prev, brand_settings: { ...prev.brand_settings, logo: null } }
        })
    }, [setProject])
    // #endregion

    if (project?.source !== "interview") return <></>

    return (
        <GlautInputLogo
            src={project.brand_settings?.logo ?? undefined}
            alt="project-logo"
            onSelect={handleSelectLogo}
            onRemove={handleRemoveLogo}
            disabled={!canEdit}
        />
    )
}