import { IQuestionDomain } from "@/@types/interview-question"
import { useMemo } from "react"

interface IAnalysisTabQuestionsColQuestionItemRelatedQuestionsProps {
    questions: {
        index: number
        label: string
        domain?: IQuestionDomain
    }[]
    state: "not-selected" | "selected" | "disabled" | "processing"
}

export default function AnalysisTabQuestionsColQuestionItemRelatedQuestions({
    questions,
    state
}: Readonly<IAnalysisTabQuestionsColQuestionItemRelatedQuestionsProps>) {
    // #region Memos
    const shouldShowHoverStyling = useMemo(() => state === "not-selected", [state])
    const shouldShowTooltip = useMemo(() => ["selected", "not-selected"].includes(state), [state])
    // #endregion

    if (questions.length === 0) return <></>

    return (
        <div className="flex flex-row gap-[0.25em] max-w-full">
            {questions.map(question => (
                <div
                    key={question.index}
                    className={`rounded-[4px] border-1 p-[0.25em] flex flex-row gap-[0.25em] min-w-0 flex-1
                        bg-glaut-off-white border-[#F1F1F1]
                    `}
                    data-tooltip-id={shouldShowTooltip ? "tooltip--glaut-app" : ""}
                    data-tooltip-place="bottom"
                    data-tooltip-content={question.label}
                >
                    <p className="text-[11.11px] font-medium text-glaut-grey">
                        {`${question.domain === "screening" ? "S" : "Q"}${question.index + 1}`}
                    </p>
                    <p className={`text-[11.11px] font-medium text-ellipsis whitespace-nowrap overflow-hidden
                        ${shouldShowHoverStyling ? "group-hover/question:text-glaut-stroke-button" : ""}
                        ${state === "not-selected" ? "text-glaut-dark-grey" : ""}
                        ${state === "selected" ? "text-glaut-text-midnight" : ""}
                        ${state === "disabled" ? "text-glaut-stroke-glaut" : ""}
                        ${state === "processing" ? "text-glaut-stroke-glaut" : ""}
                    `}>
                        {question.label}
                    </p>
                </div>
            ))}
        </div>
    )
}