import { useMemo } from "react"
import { FaTag } from "react-icons/fa"
import { Tooltip } from "react-tooltip"
import slugify from "slugify"
import ReactDOMServer from "react-dom/server"
import { useBrowserUserAgent } from "@hooks/useBrowserUserAgent"

interface ICodeItemProps {
    /**
     * Index of the color class name to apply on the background of the code.
     */
    colorIndex?: number
    leadContent: string
    content: string,
    elementOnEnd?: React.ReactNode
    tooltipMode?: "content" | "parent"
    tooltipPlacement?: "top" | "bottom" | "left" | "right"
    parentCodeLabel?: string
}

/** @deprecated */
export default function CodeItem({
    colorIndex = 1,
    leadContent,
    content,
    elementOnEnd,
    tooltipMode = undefined,
    tooltipPlacement = "top",
    parentCodeLabel
}: Readonly<ICodeItemProps>) {
    // #region Memos
    const tooltipId = useMemo(() => `code-item-${slugify(content)}`, [content])
    const tooltipContent = useMemo(() => {
        switch (tooltipMode) {
            case "content": {
                return content
            }
            case "parent": {
                return ReactDOMServer.renderToStaticMarkup(
                    <div className="flex flex-row gap-2 items-center">
                        from: <FaTag className="text-white h-4 w-4" />{parentCodeLabel}
                    </div>
                )
            }
            default: {
                return undefined
            }
        }
    }, [content, tooltipMode, parentCodeLabel])
    // #endregion

    // #region Custom hooks
    const { browsers: { isSafari } } = useBrowserUserAgent()
    // #endregion

    return (
        <>
            <div
                className="flex flex-row gap-1 rounded-full pr-2 border-2 items-center overflow-hidden"
                // @TODO: create a better approach for assigning colors to themes
                style={{
                    backgroundColor: `var(--color${(colorIndex % 10) + 1})`,
                    borderColor: `var(--color${(colorIndex % 10) + 1})`
                }}
                data-tooltip-id={tooltipId}
                data-tooltip-html={tooltipContent}
                data-tooltip-place={tooltipPlacement}
            >
                <div className="flex-none flex justify-center items-center rounded-full bg-white h-6 w-6 text-xs">
                    <p className="align-middle text-center text-ellipsis">{leadContent}</p>
                </div>
                <div className="w-full overflow-hidden">
                    <p className="text-white text-sm whitespace-nowrap overflow-hidden text-ellipsis border-none">
                        {content}
                    </p>
                </div>
                {elementOnEnd}
            </div>
            {!isSafari && tooltipContent !== undefined && (
                <Tooltip
                    id={tooltipId}
                    className="z-50 max-w-96"
                    style={{
                        backgroundColor: `var(--color${(colorIndex % 10) + 1})`
                    }}
                />
            )}
        </>
    )
}