import BaseTopBar from "@components/layouts/TopBar"
import Loading from "@components/loading/Loading"
import { useProjectService } from "@hooks/services/useProjectService"
import { download } from "@utils/miscellaneous"
import { useCallback, useEffect, useMemo, useState } from "react"
import { MdDownload } from "react-icons/md"
import { useLegacyProject, useProject } from "../../contexts/ProjectProvider"

const tabs = ["Outline", "Data", "Analysis", "Reports", "Settings", "Settings"]
export const interviewTabs = [0, 1, 2, 3, 4]
export const csvTabs = [1, 2, 4]
export const csvSetupTabs = [5]

interface ITopBarProps {
    currentTab: number
    setCurrentTab: (tab: number) => void
}

export default function TopBar({
    currentTab,
    setCurrentTab
}: Readonly<ITopBarProps>) {
    // #region Contexts
    const { org, project } = useLegacyProject()
    const { isAnalysisTabExportDataButtonActive, interviewQuestions } = useProject()
    // #endregion

    // #region Services
    const projectService = useProjectService()
    // #endregion

    // #region States
    const [tabsToDisplay, setTabsToDisplay] = useState<number[]>([])
    const [isDownloading, setIsDownloading] = useState(false)
    // #endregion

    // #region Memos
    const isDownloadButtonDisabled = useMemo(
        () => !isAnalysisTabExportDataButtonActive || isDownloading,
        [isAnalysisTabExportDataButtonActive, isDownloading]
    )
    // #endregion

    // #region Callbacks
    const downloadExcel = useCallback(() => {
        if (!project) return

        setIsDownloading(true)
        projectService.downloadExcel({ projectId: project._id })
            .then(response => {
                download(response, (project.name || "Untitled") + ".xlsx", "application/vnd.vnd.ms-excel")
            })
            .catch(error => {
                console.error("Error downloading the file:", error)
            })
            .finally(() => {
                setIsDownloading(false)
            })
    }, [project, projectService])
    // #endregion

    // #region Effects

    // Update the tabs to display
    useEffect(() => {
        if (!interviewQuestions) return

        let tabsToDisplay: number[] = interviewTabs
        if (project?.source === "CSV")
            tabsToDisplay = interviewQuestions.length > 0 ? csvTabs : csvSetupTabs

        setTabsToDisplay(tabsToDisplay)
    }, [interviewQuestions, project?.source])

    // #endregion

    if (!project || !org) return <Loading />

    return (
        <BaseTopBar
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            tabs={tabs.map((t, index) => ({ label: t, index })).filter(t => tabsToDisplay.includes(t.index))}
        >
            <span
                id="span--project-area-topbar-project-name"
                className="text-white text-base pr-2 pl-2 border-l"
            >
                {project.name || "Untitled"}
            </span>
            <div
                className={`flex flex-row rounded-[4px] gap-1 px-2 py-1 items-center
                    ${!isDownloadButtonDisabled ? "bg-glaut-pink cursor-pointer" : "bg-glaut-off-white"}
                `}
                onClick={!isDownloadButtonDisabled ? downloadExcel : undefined}>
                <MdDownload className={`h-[18px] w-[18px]
                    ${!isDownloadButtonDisabled ? "text-glaut-off-white" : "text-glaut-grey"}
                `} />
                {isDownloading ? <Loading /> : (
                    <span className={`text-[11.11px] font-medium 
                        ${!isDownloadButtonDisabled ? "text-glaut-off-white" : "text-glaut-grey"}
                    `}>
                        Export data
                    </span>
                )}
            </div>
        </BaseTopBar>
    )
}
