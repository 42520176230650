import { languages } from "@utils/Variables"
import OutlineTabContentOverviewLanguageDropdown from "../OutlineTabContentOverviewLanguageDropdown"
import { useLegacyProject } from "../../contexts/ProjectProvider"
import { useCallback } from "react"
import OutlineTabContentDefaultTitle from "../OutlineTabContentDefaultTitle"
import { copy, getCopy } from "@utils/Copy"

export default function OutlineTabContentOverviewProjectLanguage() {
    // #region Contexts
    const { project, setProject } = useLegacyProject()
    // #endregion

    // #region Callbacks
    const handleSelectProjectLanguage = useCallback((langCode: string) => {
        setProject(prev => {
            if (!prev) return prev

            return {
                ...prev,
                general_settings: {
                    ...prev.general_settings,
                    language: langCode
                }
            }
        })
    }, [setProject])
    // #endregion

    return (
        <>
            <OutlineTabContentDefaultTitle
                title={getCopy(copy.outline.overview.projectLanguage) ?? ""}
                subtitle={getCopy(copy.outline.overview.thisIsTheLanguageTheProjectIsWrittenAndAnalyzedIn) ?? ""}
            />
            <div className="self-center w-[50%]">
                <OutlineTabContentOverviewLanguageDropdown
                    languages={languages}
                    onSelect={handleSelectProjectLanguage}
                    currLanguage={project?.general_settings.language}
                />
            </div>
        </>
    )
}