import React, { useEffect, useRef, useState } from "react"
import axios from "axios"
import { BASE_URL } from "@hooks/useAxiosAuth"
import { useParams } from "react-router-dom"
import { copy, getCopy } from "@utils/Copy"
import { isIos } from "@utils/device"
import { tagRegex } from "@utils/Variables"
import { useToast } from "@contexts/ToastProvider"

const punctuations = [".", "?", "!", ":"]

/**
 * @param {Object} props
 * @param {boolean} props.isVoiceEnabled
 */
const VoiceSynthesizer = ({ lastMessage, lang, setIsPlaying, isVoiceEnabled }) => {
    // #region Useful hooks
    const { addToast } = useToast()
    const { projectId } = useParams()
    // #endregion

    // #region States

    // Hold the text part of the displayed sentences
    const [sentences, setSentences] = useState([])

    // State to hold the audio objects for playing
    const [audioBuffer, setAudioBuffer] = useState([])

    // State to keep track of the currently playing audio
    /** @type {[HTMLAudioElement | null, React.Dispatch<React.SetStateAction<HTMLAudioElement>>]} */
    const [currentlyPlaying, setCurrentlyPlaying] = useState(null)

    // Within the current message, keeps track of the sentence index currently being read
    const [currentSentenceIndex, setCurrentSentenceIndex] = useState(-1)

    // #endregion

    // #region Refs

    // Flag to track component's mounted status
    const isMounted = useRef(true)

    const lastSentenceEndIndex = useRef(0)
    const lastMessageIndex = useRef(lastMessage.index)

    // #endregion

    // #region Callbacks

    const playbackError = () => {
        let title = getCopy(copy.errorPlayback, lang)
        let detail = getCopy(copy.errorPlaybackDetail, lang)
        if (isIos()) {
            title = getCopy(copy.errorIosPlayback, lang)
            detail = getCopy(copy.errorIosPlaybackDetail, lang)
        }
        addToast(title, detail)

        // Removes faulty promise and skips to the next sentence
        setCurrentSentenceIndex(i => i + 1)
        setAudioBuffer(prevBuffer => prevBuffer.slice(1))
    }

    const synthesizeSentence = sentence => {
        // To avoid bugs
        if (sentence.length < 4) return

        // Request sentence synthesis
        const audioPromise = axios
            .post(`${BASE_URL}/interviews/${projectId}/read`, { sentence, lang }, { responseType: "blob" })
            .then(res => URL.createObjectURL(res.data))
            .then(audioSrc => new Audio(audioSrc))

        setAudioBuffer(prevBuffer => [...prevBuffer, audioPromise])
        setSentences(sentences => [...sentences, sentence])
    }

    const completeSynthesis = () => {
        console.log("All audio files have been played.")
        setCurrentlyPlaying(null)
        setIsPlaying(false)
    }

    // #endregion

    // #region Effects

    // Makes sure to reset is mounted when the components remounts
    useEffect(() => {
        if (!isVoiceEnabled) return

        isMounted.current = true
    }, [isVoiceEnabled])

    useEffect(() => {
        if (!isVoiceEnabled) return
        if (lastMessage.index === lastMessageIndex) return

        setSentences([])
        setCurrentSentenceIndex(-1)
        lastSentenceEndIndex.current = 0
        lastMessageIndex.current = lastMessage.index
    }, [isVoiceEnabled, lastMessage.index])

    // Effect to detect and queue sentences from the lastMessage prop
    useEffect(() => {
        if (!isVoiceEnabled) return
        if (!lastMessage.content || lastMessage.role === "respondent") return

        // The body of the message the respondent sees
        const displayMessage = lastMessage.content.replace(tagRegex, "")

        // This is to 
        const localSentences = lastSentenceEndIndex.current ? sentences : []

        // Loop through the new part of the message to find punctuation followed by a space
        for (let i = lastSentenceEndIndex.current; i < displayMessage.length - 1; i++)
            if (punctuations.includes(displayMessage[i]) && displayMessage[i + 1] === " ") {
                // Extract and synthetize the sentence
                const sentence = displayMessage.substring(lastSentenceEndIndex.current, i + 1)

                // Adds this clause to avoid re-reading the same sentence on re-connect
                if (!localSentences.includes(sentence)) synthesizeSentence(sentence)

                // Move past the punctuation and space
                lastSentenceEndIndex.current = i + 1
            }

        if (lastMessage.completed) { // Handle the last sentence which may not end with a punctuation followed by space
            const lastSentence = displayMessage.substring(lastSentenceEndIndex.current)
            synthesizeSentence(lastSentence)
        }
    }, [isVoiceEnabled, lastMessage])


    // Effect hook to start playing the next audio file whenever the buffer is updated
    useEffect(() => {
        if (!isVoiceEnabled) return

        // Play the first audio file in the buffer if not already playing
        if (audioBuffer.length > 0) {
            const firstElement = audioBuffer[0]

            // Check if the first element is a promise, if already resolved wraps it for uniform treatment
            /** @type {Promise<HTMLAudioElement>} */
            const audioPromise =
                firstElement instanceof Promise ? firstElement : Promise.resolve(firstElement)

            audioPromise
                .then(audio => {
                    if (audio && audio.paused && isMounted.current) {
                        audio.play().catch(error => {
                            console.error("Error playing audio:", error)
                            playbackError()
                        })
                        setIsPlaying(true)
                        setCurrentlyPlaying(audio)
                        setCurrentSentenceIndex(i => i + 1)

                        // Remove the audio that just finished playing to play the next one
                        audio.onended = () => setAudioBuffer(prevBuffer => prevBuffer.slice(1))
                    }
                })
                .catch(error => {
                    console.error("Error resolving audio promise:", error)
                    playbackError()
                })
        } else if (lastMessage.completed && currentlyPlaying) { completeSynthesis() }
    }, [isVoiceEnabled, audioBuffer])

    // Cleanup function to stop currently playing audio when component unmounts
    useEffect(() => {
        if (!isVoiceEnabled) {
            setAudioBuffer([])
            setCurrentlyPlaying(null)
            setIsPlaying(false)
            return
        }

        // Just to make sure
        isMounted.current = true

        return () => {
            isMounted.current = false
            if (currentlyPlaying && !currentlyPlaying.paused)
                currentlyPlaying.pause()
        }
    }, [isVoiceEnabled, currentlyPlaying, setIsPlaying])

    // #endregion

    if (!isVoiceEnabled)
        return (
            <span className="fade-in text-[16px] md:text-[24px]">
                {lastMessage.content.replace(tagRegex, "")}
            </span>
        )

    return (
        <h2>
            {sentences.map((sentence, index) => (
                <span
                    key={`${index} - ${sentence}`}
                    style={{ display: index <= currentSentenceIndex ? "inline" : "none" }}
                >
                    {sentence.split(" ").filter(w => w).map((word, i) => (
                        <span
                            key={`${index}, ${i} - ${word}`}
                            className="fade-in text-[16px] md:text-[24px]"
                            style={{
                                opacity: 0,
                                whiteSpace: "pre-wrap",
                                display: "inline",
                                animationFillMode: "forwards",
                                animationDelay: `${i * 0.2}s`
                            }}
                        >
                            {word}{" "}
                        </span>
                    ))}
                </span>
            ))}
        </h2>
    )
}

export default VoiceSynthesizer