import { VerbatimHeader, VerbatimRow } from "@components/Coding/Verbatims"
import { getCopy } from "@utils/Copy"
import { getProjectLang } from "@utils/language"
import { useMemo } from "react"
import { useLegacyProject, useProject } from "../../../../contexts/ProjectProvider"
import { useDataTabCsvContent } from "../../contexts/DataTabCsvContentProvider"
import { dataTabCsvContentPreviewCopy } from "./utils/copy"

export default function DataTabCsvContentPreview() {
    // #region Contexts
    const { interviewQuestions } = useProject()
    const { project } = useLegacyProject()
    const { selectedColumnIndex, interviews: entries } = useDataTabCsvContent()
    // #endregion

    // #region Memos

    const lang = useMemo(() => getProjectLang(project), [project])

    // Set questionIds from schema
    const questionIds = useMemo(
        () => interviewQuestions?.map(q => q.id) ?? [],
        [interviewQuestions]
    )
    // Set headers from schema
    const headers = useMemo(
        () => ["ID"].concat(interviewQuestions?.map(q => q.content[lang] ?? q.header ?? "") ?? []),
        [interviewQuestions, lang]
    )
    // Set rows from entries
    const rows = useMemo(
        () => (entries ?? []).map(e => [e.identifier].concat(
            questionIds.map(
                id => e.messages.filter(m => m.role === "respondent" && m.question_id === id).map(m => m.content)
            ).flat()
        )),
        [entries, questionIds]
    )

    // #endregion

    return (
        <div className="flex flex-col flex-1 gap-[1em] max-w-[60%] overflow-hidden">
            <p className="text-[16px] text-glaut-bar">
                {getCopy(dataTabCsvContentPreviewCopy.preview)?.toUpperCase()}
            </p>
            <div className="scroll">
                <div className="verbatims-table">
                    <VerbatimHeader headers={headers} selectedColumn={selectedColumnIndex} />
                    {rows.map((r, idx) => (
                        <VerbatimRow
                            key={`${r[0]}-${idx}`}
                            row={r}
                            selectedColumn={selectedColumnIndex}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}