import PreviewButton from "@components/Buttons/PreviewButton"
import GlautTextAreaMultiLine from "@components/inputs/GlautTextAreaMultiLine"
import { ChangeEvent as ReactChangeEvent, useCallback, useMemo, useRef, useState } from "react"

interface IOutlineTabContentIqsQuestionFormBodyProbingTextAreaProps {
    placeholder: string
    disabled?: boolean
    probingInstructions: string
    setProbingInstructions: (probingInstructions: string) => void
    probingTemplates: [string, string][]
}

export default function OutlineTabContentIqsQuestionFormBodyProbingTextArea({
    placeholder,
    disabled = false,
    probingInstructions,
    setProbingInstructions,
    probingTemplates
}: Readonly<IOutlineTabContentIqsQuestionFormBodyProbingTextAreaProps>) {
    // #region Refs
    const textareaRef = useRef<HTMLTextAreaElement>(null)
    // #endregion

    // #region States
    const [templatePlaceholder, setTemplatePlaceholder] = useState<string | null>(null)
    // #endregion

    // #region Memo
    const probingTemplateHidden = useMemo(
        () => Boolean(probingInstructions.length),
        [probingInstructions]
    )
    // #endregion

    // #region Callbacks
    const handleChangeProbingInstructions = useCallback((e: ReactChangeEvent<HTMLTextAreaElement>) => {
        setProbingInstructions(e.target.value)
    }, [setProbingInstructions])

    const handleSelectTemplate = useCallback((template: string) => {
        if (!probingTemplateHidden) setProbingInstructions(template)
    }, [probingTemplateHidden, setProbingInstructions])
    // #endregion

    return (
        <div className="relative">
            <GlautTextAreaMultiLine
                ref={textareaRef}
                placeholder={templatePlaceholder ?? placeholder}
                value={probingInstructions}
                onChange={handleChangeProbingInstructions}
                disabled={disabled}
                designMode="ai"
            />
            <div className={`absolute bottom-0 left-0 right-0 flex items-center gap-1 px-[0.75rem] py-[0.5rem]
                pointer-events-none
                ${probingTemplateHidden ? "invisible" : "visible"}
            `}>
                <p className="text-[16px]">
                    🔍
                </p>
                {probingTemplates.map(([label, template]) => (
                    <PreviewButton
                        key={label}
                        disabled={disabled}
                        onPreview={show => setTemplatePlaceholder(show ? template : null)}
                        onClick={() => handleSelectTemplate(template)}
                        className={`pointer-events-auto
                            ${probingTemplateHidden ? "invisible transition-none" : "visible"}
                        `}
                    >
                        {label}
                    </PreviewButton>
                ))}
            </div>
        </div>
    )
}
