import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { FaTag } from "react-icons/fa"
import { MdClose, MdEditNote, MdInfoOutline } from "react-icons/md"
import {
    useAnalysisTabAnalysisCol,
    useAnalysisTabAnalysisColDispatch
} from "../../contexts/AnalysisTabAnalysisColProvider"
import DeleteModal from "../AnalysisTabAnalysisColReviewCodeBookModalThemeItemDeleteModal"
import { getCopy, copy } from "@utils/Copy"
import { useAnalysisTab } from "../../contexts/AnalysisTabProvider"
import { normalizeToColorIndex } from "@utils/styling/colors-from-index"

interface IAnalysisTabAnalysisColReviewCodeBookModalThemeItemProps {
    colorIndex: number
    label: string
    description?: string | null
    themeId: string
    removeCodeCallback: (themeId: string) => void
}

export default function AnalysisTabAnalysisColReviewCodeBookModalThemeItem({
    colorIndex,
    label,
    description,
    themeId,
    removeCodeCallback
}: Readonly<IAnalysisTabAnalysisColReviewCodeBookModalThemeItemProps>) {
    // #region Contexts
    const { selectedAnalysis, categoryColorIndexes } = useAnalysisTab()
    const { codeBook: { edits }, categoryBeingEdited } = useAnalysisTabAnalysisCol()
    const analysisTabAnalysisColDispatch = useAnalysisTabAnalysisColDispatch()
    // #endregion

    // #region State
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
    // #endregion

    // #region Refs
    const textAreaRef = useRef<HTMLTextAreaElement>()
    const colorIndexRef = useRef(colorIndex) // avoid changes after rerender from parent
    // #endregion

    // #region Memos
    const isThemeSelected = useMemo(() => themeId === categoryBeingEdited?.id, [themeId, categoryBeingEdited])
    const newThemeLabel = useMemo(() => edits[themeId]?.newLabel ?? "", [themeId, edits])
    const themeInstructions = useMemo(() => edits[themeId]?.instruction ?? "", [themeId, edits])

    const shouldShowEditIcon = useMemo(() => selectedAnalysis?.type !== "entity", [selectedAnalysis?.type])
    const shouldShowDescriptionTextArea = useMemo(
        () => isThemeSelected && selectedAnalysis?.type !== "entity",
        [isThemeSelected, selectedAnalysis?.type]
    )
    // #endregion

    // #region Callbacks
    const handleSetEditingCategoryName = useCallback(() => {
        analysisTabAnalysisColDispatch({
            type: "set-category-being-edited",
            category: { id: themeId, attribute: "name", type: "theme" }
        })
    }, [analysisTabAnalysisColDispatch, themeId])

    const handleChangeThemeLabel = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        analysisTabAnalysisColDispatch({
            type: "add-code-book-edit",
            themeId,
            instruction: themeInstructions,
            newLabel: event.target.value
        })
    }, [analysisTabAnalysisColDispatch, themeId, themeInstructions])

    const handleChangeThemeInstructions = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
        if (textAreaRef.current) {
            // Resize textarea based on content
            textAreaRef.current.style.height = "auto"
            // +2px to avoid scroll to be shown
            textAreaRef.current.style.height = textAreaRef.current.scrollHeight + 2 + "px"
        }

        analysisTabAnalysisColDispatch({
            type: "add-code-book-edit",
            themeId,
            instruction: event.target.value,
            newLabel: newThemeLabel
        })
    }, [analysisTabAnalysisColDispatch, themeId, newThemeLabel])

    const handleConfirmDeletion = useCallback(() => {
        setIsDeleteModalOpen(false)

        // Timeout for UX purposes
        setTimeout(() => {
            removeCodeCallback(themeId)
        }, 500)
    }, [removeCodeCallback, themeId])
    // #endregion

    // #region Effects
    useEffect(() => {
        if (isThemeSelected || newThemeLabel !== "" || label !== "") return

        removeCodeCallback(themeId)
    }, [analysisTabAnalysisColDispatch, isThemeSelected, label, newThemeLabel, removeCodeCallback, themeId])

    useEffect(() => {
        if (!selectedAnalysis?.categories.some(c => c.id === themeId)) return
        colorIndexRef.current = categoryColorIndexes[themeId] ?? colorIndexRef.current
    }, [categoryColorIndexes, selectedAnalysis?.categories, themeId])
    // #endregion

    return (
        <div className="border-t-1 border-t-glaut-stroke-glaut rounded-none">
            <div className={`flex flex-row justify-between rounded-none p-[0.5em] 
                ${isThemeSelected ? "bg-glaut-cards" : ""}
            `}>
                <div className="flex flex-row gap-[0.5em] flex-1 min-w-0">
                    <FaTag style={{ color: `var(--color${normalizeToColorIndex(colorIndexRef.current)}` }} />
                    {!isThemeSelected && (
                        <button
                            className={`flex-1 max-w-full overflow-hidden h-full
                                justify-start rounded-none border-none shadow-none p-0 m-0 text-left 
                            `}
                            onClick={handleSetEditingCategoryName}
                        >
                            <p className="text-[13.33px] font-medium whitespace-nowrap text-ellipsis overflow-hidden">
                                {newThemeLabel || label}
                            </p>
                        </button>
                    )}
                    {isThemeSelected && (
                        <input
                            className={`border-none shadow-none rounded-none focus:shadow-none bg-transparent m-0 p-0
                                text-[13.33px] font-medium
                                placeholder-shown:text-ellipsis
                                focus:placeholder:text-ellipsis
                            `}
                            placeholder={
                                (label ||
                                    getCopy(selectedAnalysis?.type === "thematic"
                                        ? copy.coding.writeANewThemeHere
                                        : copy.coding.writeANewCategoryHere)
                                ) ?? ""
                            }
                            value={newThemeLabel}
                            onChange={handleChangeThemeLabel}
                            ref={element => {
                                if (categoryBeingEdited?.id === themeId && categoryBeingEdited.attribute === "name")
                                    setTimeout(() => { // timeout to give time to render
                                        element?.focus()
                                        analysisTabAnalysisColDispatch({
                                            type: "set-category-being-edited",
                                            category: { id: themeId, attribute: undefined, type: "theme" }
                                        })
                                    }, 100)
                            }}
                        />
                    )}
                </div>
                <div className="flex flex-row gap-[0.5em]">
                    {shouldShowEditIcon && (
                        <MdEditNote
                            className={
                                isThemeSelected ? "text-glaut-midnight bg-glaut-stroke-glaut" : "text-glaut-grey"
                            }
                            onClick={handleSetEditingCategoryName}
                        />
                    )}
                    <MdClose
                        className="text-glaut-dark-grey"
                        onClick={() => {
                            setIsDeleteModalOpen(true)
                        }}
                    />
                </div>
            </div>
            {shouldShowDescriptionTextArea && (
                <div className="px-[8px] pb-[8px] ms-[32px] mt-[8px]">
                    <div className="flex gap-[0.25em] items-center mb-[0.3125em]">
                        <p className="text-[13.33px] text-glaut-dark-grey font-medium">
                            {getCopy(selectedAnalysis?.type === "thematic"
                                ? copy.coding.editInstructionsOptional
                                : copy.coding.editDescription)
                            }
                        </p>
                        {selectedAnalysis?.type === "thematic" && (
                            <MdInfoOutline
                                className="h-[1em] w-[1em] text-glaut-dark-grey"
                                data-tooltip-id="tooltip--glaut-app"
                                data-tooltip-content={getCopy(copy.coding.instructionsHelpGlautSelect)}
                                data-tooltip-place="right"
                                data-tooltip-class-name="max-w-[300px]"
                            />
                        )}
                    </div>
                    <textarea
                        className={`rounded-[4px] border-1 h-max py-[0.5em] px-[0.75em]
                            border-glaut-light-grey 
                            focus:border-glaut-text-midnight
                            hover:border-glaut-text-midnight
                        `}
                        value={themeInstructions}
                        onChange={handleChangeThemeInstructions}
                        placeholder={(description || getCopy(selectedAnalysis?.type === "thematic"
                            ? copy.coding.includeInThisThemeAllMentionsAboutABC
                            : copy.coding.describeWhatThisCategoryShouldContainToHelpGlaut)) ?? ""
                        }
                        ref={element => {
                            if (element) textAreaRef.current = element

                            if (
                                categoryBeingEdited?.id === themeId &&
                                categoryBeingEdited.attribute === "description"
                            )
                                setTimeout(() => { // timeout to give time to render
                                    element?.focus()
                                    analysisTabAnalysisColDispatch({
                                        type: "set-category-being-edited",
                                        category: { id: themeId, attribute: undefined, type: "theme" }
                                    })
                                }, 100)
                        }}
                    />
                </div>
            )}

            <DeleteModal
                isOpen={isDeleteModalOpen}
                onConfirm={handleConfirmDeletion}
                onClose={() => {
                    setIsDeleteModalOpen(false)
                }}
            />
        </div >
    )
}