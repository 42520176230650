import LoadingBar from "@components/loading/LoadingBar"
import PdfIcon from "@components/Pdf/PdfIcon"
import { Text, View } from "@react-pdf/renderer"
import { Style } from "@react-pdf/types"
import {
    bgColorWithCssColorVarFromIndex,
    normalizeToColorIndex,
    textColorWithCssColorVarFromIndex
} from "@utils/styling/colors-from-index"
import { namedColors } from "@utils/Variables"
import { useMemo, useRef } from "react"
import { FaHashtag, FaSlackHash, FaStarHalfAlt } from "react-icons/fa"

interface NpsContentItemProps {
    score: number
    occurrences: number
    /**
     * From 0 to 100.
     */
    percentage: number
    index?: number
    designMode?: "nps" | "scale"
    asPdf?: boolean
    twPdf?: (input: string) => Style
}

export default function NpsContentItem({
    occurrences,
    percentage,
    score,
    index = 0,
    designMode = "nps",
    asPdf,
    twPdf
}: Readonly<NpsContentItemProps>) {
    // #region Refs
    const containerRef = useRef<HTMLDivElement>(null)
    // #endregion

    // #region Memos
    const leftIconClassName = useMemo(() => {
        const baseClassName = "w-[16px] h-[16px]"

        if (designMode === "scale")
            return `${baseClassName} ${textColorWithCssColorVarFromIndex(index)}`

        if (score >= 9 && score <= 10)
            return `${baseClassName} text-glaut-codes-green`
        else if (score >= 7 && score <= 8)
            return `${baseClassName} text-glaut-codes-yellow`

        return `${baseClassName} text-glaut-codes-red`
    }, [designMode, index, score])

    const scoreRelatedHexColor = useMemo(() => {
        if (designMode === "scale")
            return namedColors[`color${normalizeToColorIndex(index)}`]

        if (score >= 9 && score <= 10)
            return namedColors["codes-green"]
        else if (score >= 7 && score <= 8)
            return namedColors["codes-yellow"]

        return namedColors["codes-red"]
    }, [designMode, index, score])

    const loadingBarBackgroundClassName = useMemo(() => {
        if (designMode === "scale")
            return bgColorWithCssColorVarFromIndex(index)

        if (score >= 9 && score <= 10)
            return "bg-glaut-codes-green"
        else if (score >= 7 && score <= 8)
            return "bg-glaut-codes-yellow"

        return "bg-glaut-codes-red"
    }, [designMode, index, score])
    // #endregion

    if (asPdf && twPdf)
        return (
            <View style={[
                twPdf("flex flex-row justify-between items-center p-[5.09px]"),
                index !== 0 ? twPdf("border-t-1 border-t-glautStrokeGlaut") : {}
            ]}>
                <View style={twPdf("flex flex-row items-center gap-[5.09px] w-[15%]")}>
                    <PdfIcon icon={FaSlackHash} size={"10.18px"} color={scoreRelatedHexColor} />
                    <Text style={twPdf("text-[12.22px] font-medium text-glautTextMidnight")}>
                        {score}
                    </Text>
                </View>
                <View style={twPdf("flex flex-row items-center flex-1")}>
                    <Text style={twPdf("text-[12.22px] text-glautTextMidnight w-12")}>
                        {percentage}%
                    </Text>
                    <View style={twPdf("bg-glautCards h-[2.14px] m-2 flex-1 rounded-full")}>
                        <View style={[twPdf("h-full rounded-full"), {
                            backgroundColor: scoreRelatedHexColor,
                            width: `${percentage}%`
                        }]}></View>
                    </View>
                </View>
                <View style={twPdf("flex flex-row gap-[5.09px] justify-end items-center w-[15%]")}>
                    <Text style={twPdf("text-[12.22px] text-glautTextMidnight")}>
                        {occurrences}
                    </Text>
                    <PdfIcon icon={FaStarHalfAlt} size={"10.18px"} color={namedColors.grey} />
                </View>
            </View>
        )

    return (
        <div
            className={`flex flex-row justify-between items-center p-[0.5em]
                ${index !== 0 ? "border-t-1 border-t-glaut-stroke-glaut" : ""}
            `}
            ref={containerRef}
        >
            <div className="flex flex-row items-center gap-[0.625em] w-[45%]">
                <FaStarHalfAlt className={leftIconClassName} />
                <p className="text-[23.04px] font-medium text-glaut-text-midnight">
                    {score}
                </p>
            </div>
            <div className="flex flex-row items-center flex-1">
                <p className="text-[19.2px] text-glaut-text-midnight w-12">
                    {percentage}%
                </p>
                <LoadingBar
                    percentage={percentage}
                    showPercentage={false}
                    precision={0}
                    style={{ flex: 1 }}
                    loadingBarFullClassName={`loading-bar-full ${loadingBarBackgroundClassName}`}
                    loadingBarDotClassName={`loading-bar-dot ${loadingBarBackgroundClassName}`}
                />
            </div>
            <div className="flex flex-row gap-[0.25em] justify-end items-center w-[10%]">
                <FaHashtag className="w-[0.875em] h-[0.875em] text-glaut-grey mb-[0.20em]" />
                <p className="text-[19.2px] text-glaut-text-midnight text-end">
                    {occurrences}
                </p>
            </div>
        </div>
    )
}