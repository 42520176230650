interface INpsInputProps {
    addClosedMessage: (content: string, questionId: string, value: number) => void
    message: {
        id: string
        min: number
        max: number
    }
    isPlaying?: boolean
}

export default function NpsInput({
    addClosedMessage,
    message,
    isPlaying
}: Readonly<INpsInputProps>) {
    return (
        <div id="nps-input" className="flex flex-wrap justify-center gap-[1em] mt-[1em]">
            {Array.from({ length: message.max - message.min + 1 }, (_, i) => (
                <button
                    key={i}
                    className="border-1 shadow-none rounded-[0.5rem] aspect-square p-[0.75rem] w-[4em]
                        bg-glaut-very-light-grey text-glaut-text-midnight border-transparent
                        hover:border-1 hover:border-glaut-stroke-glaut
                        active:bg-glaut-grey"
                    onClick={() => addClosedMessage((i + message.min).toString(), message.id, i + message.min)}
                    disabled={isPlaying || isPlaying === null}
                >
                    <p className="text-center text-[19.2px]">{i + message.min}</p>
                </button>
            ))}
        </div>
    )
}
