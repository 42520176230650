import { createContext, useContext, useCallback, useMemo, useRef } from "react"

// #region Type definitions
export type IInteractionType = "moderator-reply"

export declare type IInteractionInProgress = {
    type: IInteractionType
    startedAt: number
    inProgress: true
    latency: null
}

export declare type IInteractionEnded = {
    type: IInteractionType
    startedAt: number
    inProgress: false
    latency: number
}

export declare type IInteraction = IInteractionInProgress | IInteractionEnded

interface ITrackInteractionProps {
    children: React.ReactNode
}
interface ITrackInteractionState {
    startInteraction: (type: IInteractionType) => IInteractionInProgress
    stopInteraction: (type: IInteractionType) => IInteractionEnded | null
}
// #endregion

// #region Contexts definitions
const TrackInteractionContext = createContext({} as ITrackInteractionState)
// #endregion

// #region Hooks definitions
export function useTrackInteraction() {
    return useContext(TrackInteractionContext)
}
// #endregion

// #region Provider definition
export default function TrackInteractionProvider({
    children
}: Readonly<ITrackInteractionProps>) {
    // #region Refs
    const interactionsRef = useRef<{ [type in IInteractionType]?: IInteraction }>({})
    // #endregion

    // #region Callbacks
    const startInteraction = useCallback((type: IInteractionType): IInteractionInProgress => {
        const interaction: IInteractionInProgress = {
            type,
            startedAt: Date.now(),
            inProgress: true,
            latency: null
        }

        interactionsRef.current[type] = interaction

        return interaction
    }, [])
    const stopInteraction = useCallback((type: IInteractionType): IInteractionEnded | null => {
        if (!interactionsRef.current[type]) return null

        const interaction: IInteractionEnded = {
            ...(interactionsRef.current[type] as IInteractionInProgress),
            inProgress: false,
            latency: Date.now() - interactionsRef.current[type].startedAt
        }

        interactionsRef.current[type] = interaction

        return interaction
    }, [])
    // #endregion

    // #region Memo
    const context = useMemo(() => ({ startInteraction, stopInteraction }), [startInteraction, stopInteraction])
    // #endregion

    return (
        <TrackInteractionContext.Provider value={context}>
            {children}
        </TrackInteractionContext.Provider>
    )
}
// #endregion
