import { IInterview } from "src/@types/entry"
import DataTabTableRowItemDate from "../DataTabTableRowItemDate"
import DataTabTableRowItemId from "../DataTabTableRowItemId"
import DataTabTableRowItemLanguage from "../DataTabTableRowItemLanguage"
import DataTabTableRowItemPlatform from "../DataTabTableRowItemPlatform"
import DataTabTableRowItemStatus from "../DataTabTableRowItemStatus"

interface IDataTabTableRowItemProps {
    entry: IInterview
}

export default function DataTabTableRowItem({
    entry
}: Readonly<IDataTabTableRowItemProps>) {
    return <tr>
        <DataTabTableRowItemId entry={entry} />
        <DataTabTableRowItemStatus status={entry.termination_reason ?? "uncompleted"} />
        <DataTabTableRowItemDate date={entry.last_updated ?? entry.started} />
        <DataTabTableRowItemPlatform platform={entry.headers?.["user-agent"] ?? "Not available"} />
        <DataTabTableRowItemLanguage language={entry.lang} />
    </tr>
}