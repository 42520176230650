export const workspace = {
    workspace: {
        createProject: {
            en: "New project",
        },
        back: {
            en: "Back",
        },
        noProjectsYet: {
            en: "No projects yet",
        },
        inviteNewMemberTitle: {
            en: "Invite a new member to the workspace"
        },
        inviteNewMemberDescription: {
            en: "Workspace members have access to all the projects within it, with different roles. You can either add existing members of the organization by simply typing their name, or invite new members by typing their email address. New members will then be automatically added to the organization."
        },
        members: {
            en: "Workspace members"
        },
        noMembersYet: {
            en: "You have no members yet"
        },
        selectAPerson: {
            en: "Select a person from the organization:"
        },
        invite: {
            en: "Invite"
        },
        inviteSent: {
            en: inviteAddress => "Invitation sent to " + inviteAddress
        },
        inviteUserError: {
            en: "There was an error inviting the user"
        },
        changeUserRoleError: {
            en: "There was an error changing the user role"
        },
        removeUserError: {
            en: "There was an error changing the user role"
        },
        removeMember: {
            en: "This member will be removed from the current workspace, but not from the organization"
        },
        cannotInvite: {
            en: "You cannot invite members"
        },
        deleteThis: {
            en: "Delete this workspace"
        },
        deleteButton: {
            en: "Delete"
        },
    },
} as const
