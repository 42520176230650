import { questionLabelFromType } from "@utils/analysis/labels"
import { useMemo } from "react"
import { IAnalysisType } from "src/@types/analysis"
import { IInterviewQuestion } from "src/@types/interview-question"

interface IAnalysisTabQuestionsColQuestionItemTitleProps {
    questionType?: IAnalysisType | IInterviewQuestion["type"]
    index: number
    state: "not-selected" | "selected" | "disabled" | "processing"
}

export default function AnalysisTabQuestionsColQuestionItemTitle({
    index,
    questionType,
    state
}: Readonly<IAnalysisTabQuestionsColQuestionItemTitleProps>) {
    // #region Memos
    const shouldShowHoverStyling = useMemo(() => state === "not-selected", [state])
    const questionTypeLabel = useMemo(() => questionType ? questionLabelFromType(questionType) : "", [questionType])
    // #endregion

    return (
        <div className="project-area-question-title flex flex-row gap-[0.5em]">
            <span className={`project-area-question-title-label 
                text-[11.11px] font-medium px-[0.25em] text-glaut-cards
                ${state === "selected" ? "bg-glaut-midnight" : "bg-glaut-light-grey"}
            `}>
                A{index}
            </span>
            <span className={`project-area-question-type-label text-[11.11px]
                ${shouldShowHoverStyling ? "group-hover/question:text-glaut-text-midnight" : ""}
                ${state === "not-selected" ? "text-glaut-dark-grey" : ""}
                ${state === "selected" ? "text-glaut-midnight" : ""}
                ${state === "disabled" ? "text-glaut-stroke-glaut" : ""}
                ${state === "processing" ? "text-glaut-stroke-glaut" : ""}
            `}>
                {questionTypeLabel}
            </span>
        </div>
    )
}