import { FaRegClipboard } from "react-icons/fa"
import { getCopy } from "@utils/Copy"
import { useMemo } from "react"
import { useConversationOverlay } from "@components/ConversationOverlay/contexts/ConversationOverlayProvider"
import { conversationOverlayCopy } from "@components/ConversationOverlay/utils/copy"

export default function ConversationOverlayRelatedData() {
    // #region Contexts
    const { lang, screeningQuestions, interview } = useConversationOverlay()
    // #endregion

    // #region Memos
    const panelScreeningData = useMemo(() => screeningQuestions.reduce((prev, curr) => {
        const questionId = curr.id
        const relatedMessage = interview.messages.find(
            message => message.question_id === questionId && message.role === "respondent"
        )
        if (!relatedMessage) return prev

        const title = curr.content[lang]
        if (title === null) return prev

        prev[curr.id] = { title, value: relatedMessage.content }

        return prev
    }, {} as Record<string, { title: string; value: string }>), [interview.messages, lang, screeningQuestions])
    // #endregion

    if (Object.keys(panelScreeningData).length === 0) return <></>

    return (
        <div className="flex flex-col gap-[0.5em] w-full px-[1.125em]">
            <div className="flex gap-[0.75em] w-full h-[1.75em]">
                <FaRegClipboard className="h-[1em] w-[1em] text-glaut-text-midnight rounded-none" />
                <p className="text-[13.33px] font-medium text-glaut-text-midnight">
                    {getCopy(conversationOverlayCopy.panelScreening.relatedData)}
                </p>
            </div>
            <div className="flex flex-col w-full px-[1.25em]">
                {Object.entries(panelScreeningData).map(([key, item]) => (
                    <div key={key} className="flex h-[1.75em]">
                        <p className="text-[13.33px] font-medium text-glaut-dark-grey flex-1">
                            {item.title}
                        </p>
                        <p className="text-[13.33px] text-glaut-stroke-button flex-1">
                            {item.value}
                        </p>
                    </div>
                ))}
            </div>
        </div>
    )
}