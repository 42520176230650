import { copy, getCopy } from "@utils/Copy"
import { ChangeEventHandler, useCallback } from "react"
import { useLegacyProject } from "../../contexts/ProjectProvider"
import GlautTextAreaSingleLine from "@components/inputs/GlautTextAreaSingleLine"

export default function CSVSettingsTabProjectTitle() {
    // #region Contexts
    const { project, setProject } = useLegacyProject()
    // #endregion

    // #region Callbacks
    const handleChangeProjectName: ChangeEventHandler<HTMLInputElement> = useCallback(ev => {
        setProject(prev => prev ? ({ ...prev, name: ev.target.value }) : prev)
    }, [setProject])
    // #endregion

    if (project?.source !== "CSV") return <></>

    return (
        <div className="flex flex-col gap-[0.5em]">
            <p className="text-[13.33px] font-medium text-[#565656]">
                {getCopy(copy.coding.settings.csvSettings.projectTitle) ?? ""}
            </p>
            <GlautTextAreaSingleLine
                placeholder={getCopy(copy.words.untitled) ?? ""}
                defaultValue={project?.name ?? ""}
                onChange={handleChangeProjectName}
            />
        </div>
    )
}