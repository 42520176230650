import Sprinkles from "@components/Icons/Sprinkles"
import { DetailedHTMLProps, forwardRef, TextareaHTMLAttributes, useCallback } from "react"

interface IGlautTextAreaMultiLineProps
    extends DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
    withBiggerTextSize?: boolean
    designMode?: "ai" | "default"
}

const GlautTextAreaMultiLine = forwardRef<HTMLTextAreaElement, Readonly<IGlautTextAreaMultiLineProps>>(({
    className,
    withBiggerTextSize = false,
    designMode = "default",
    ...props
}, ref) => {
    // #region Element callbacks
    type ITextAreaElementProps = { withBorder?: boolean }
    const textAreaElement = useCallback((textAreaProps: ITextAreaElementProps = { withBorder: true }) => (
        <textarea
            {...props}
            ref={ref}
            className={`block p-[0.5em] font-medium rounded-[0.25em] border-0
                ${withBiggerTextSize ? "text-[16px]" : "text-[13.33px]"}
                bg-glaut-text-area-off-white text-glaut-text-midnight
                placeholder:text-glaut-grey
                focus:shadow-none
                disabled:bg-glaut-very-light-grey disabled:cursor-not-allowed
                ${textAreaProps?.withBorder ? "border-1" : ""}
                ${textAreaProps?.withBorder ? "border-glaut-cards" : ""}
                ${textAreaProps?.withBorder ? "focus:border-glaut-light-grey" : "focus:border-0"}
                ${textAreaProps?.withBorder ? "enabled:hover:border-glaut-light-grey" : ""}
                ${className}
            `}
        />
    ), [ref, withBiggerTextSize, className, props])
    // #endregion

    if (designMode === "ai")
        return (
            <div className={`
                relative
                p-[1px]
                bg-glaut-gradient-1
                hover:bg-glaut-light-grey hover:bg-none
                has-[:focus]:bg-glaut-light-grey has-[:focus]:bg-none
                rounded-[0.25em]
            `}>
                {textAreaElement({ withBorder: false })}
                <div className="absolute bottom-[0.75rem] right-[0.75rem]">
                    <Sprinkles designMode="ai" />
                </div>
            </div>
        )

    return textAreaElement()
})

GlautTextAreaMultiLine.displayName = "GlautTextAreaMultiLine"

export default GlautTextAreaMultiLine