import { IProjectStatus } from "@/@types/project"
import FloatingLayout from "@components/layouts/FloatingLayout"
import { useWebSocket } from "@hooks/useWebSocket"
import { copy, getCopy } from "@utils/Copy"
import {
    projectStatusColorMap,
    projectStatusIconMap,
    projectStatusLabelMap,
    projectStatusTargetMap
} from "@utils/project/status"
import { useMemo, useRef, useState } from "react"
import { MdArrowDropDown } from "react-icons/md"
import { useOutlineTab } from "../../contexts/OutlineTabProvider"
import { useLegacyProject } from "../../contexts/ProjectProvider"

export default function OutlineTabSidebarProjectStatusButton() {
    // #region Contexts
    const { project } = useLegacyProject()
    const { hasPermission } = useOutlineTab()
    // #endregion

    // #region WebSocket
    const { sendMessage } = useWebSocket()
    // #endregion

    // #region States
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    // #endregion

    // #region Refs
    const projectStatusRef = useRef<HTMLButtonElement>(null)
    // #endregion

    // #region Memos
    const currStatus = useMemo(
        () => project?.source === "interview" ? (project?.status ?? "not_set") : "not_set",
        [project]
    )
    const availableStatus = useMemo(() => projectStatusTargetMap[currStatus], [currStatus])
    const Icon = useMemo(() => projectStatusIconMap[currStatus], [currStatus])
    const elements = useMemo(() => (["set_up", "test", "live", "ended"] as IProjectStatus[])
        .filter(status => status !== currStatus)
        .map(status => {
            const Icon = projectStatusIconMap[status]

            return {
                icon: <Icon className={`w-[1.25em] h-[1.25em] ${projectStatusColorMap[status].className.text}`} />,
                text: (
                    <p className="text-[13.33px] font-medium">
                        {projectStatusLabelMap[status]}
                    </p>
                ),
                onClick: () => {
                    sendMessage({ operation: "update_project_status", status })
                    setIsMenuOpen(false)
                },
                status,
                disabled: !availableStatus.includes(status)
            }
        }), [availableStatus, currStatus, sendMessage])
    // #endregion

    return (
        <>
            <button
                ref={projectStatusRef}
                className={`flex flex-row justify-between w-full rounded-[0.25em] py-[0.625em] px-[0.75em]
                    border-0 shadow-none
                    bg-glaut-very-light-grey
                `}
                onClick={() => hasPermission && setIsMenuOpen(!isMenuOpen)}
            >
                <div className="flex gap-[0.75em]">
                    <Icon className="w-[1.25em] h-[1.25em]" color={projectStatusColorMap[currStatus].hex} />
                    <p className="text-[13.33px] font-medium text-glaut-text-midnight">
                        {projectStatusLabelMap[currStatus]}
                    </p>
                </div>
                {hasPermission && (
                    <MdArrowDropDown className="arrow-down" />
                )}
            </button>
            {hasPermission && isMenuOpen && (
                <FloatingLayout
                    anchorRef={projectStatusRef}
                    onClose={() => setIsMenuOpen(false)}
                    className={`absolute flex flex-col py-[0.25em] px-[0.75em] border-1 rounded-[0.25em]
                        border-glaut-grey bg-glaut-off-white shadow-[0px_0px_8px_0px_rgb(0,0,0,0.15)]
                        text-glaut-text-midnight
                        disabled:text-glaut-grey
                    `}
                    place="bottom"
                    style={{ width: projectStatusRef?.current?.getBoundingClientRect().width ?? undefined }}
                    boundingBoxOffset={50}
                >
                    <p className="text-[11.11px] font-medium text-[#666666] py-[0.625em]">
                        {getCopy(copy.outline.moveTo)}
                    </p>
                    {elements.map(element => (
                        <button
                            key={element.status}
                            onClick={element.onClick}
                            className={`flex gap-[0.5em] items-center py-[0.625em] rounded-none px-1 cursor-pointer
                                w-full justify-start border-none shadow-none bg-transparent
                                hover:bg-glaut-very-light-grey
                            `}
                            disabled={element.disabled}
                        >
                            {element.icon}
                            {element.text}
                        </button>
                    ))}
                </FloatingLayout>
            )}
        </>
    )
}
