import { IAnalysis } from "@/@types/analysis"
import { newAnalysisOptionsMap } from "@/constants/analyses/options"
import GlautAnalysisCreationForm, {
    IGlautAnalysisCreationFormOnChangeHandler,
    IGlautAnalysisCreationFormState
} from "@components/Forms/GlautAnalysisCreationForm"
import ConfirmModal from "@components/Modals/ConfirmModal"
import { copy, getCopy } from "@utils/Copy"
import { useCallback, useMemo, useState } from "react"
import { MdClose } from "react-icons/md"
import { useOutlineTabIqs, useOutlineTabIqsDispatch } from "../../contexts/OutlineTabIqsProvider"
import { useOutlineTab } from "../../contexts/OutlineTabProvider"
import { useAnalysisService } from "@hooks/services/useAnalysisService"
import { useLegacyProject } from "../../contexts/ProjectProvider"

interface IOutlineTabContentIqsQuestionFormBodyExAnteAnalysisListItemProps {
    analysis: Partial<IAnalysis> & { isEditing: boolean }
}

export default function OutlineTabContentIqsQuestionFormBodyExAnteAnalysisListItem({
    analysis
}: Readonly<IOutlineTabContentIqsQuestionFormBodyExAnteAnalysisListItemProps>) {
    // #region Contexts
    const { project } = useLegacyProject()
    const { canEdit } = useOutlineTab()
    const { iqToBeBuilt, selectedExAnteAnalysisId } = useOutlineTabIqs()
    const outlineTabIqsDispatch = useOutlineTabIqsDispatch()
    // #endregion

    // #region Services
    const analysisService = useAnalysisService()
    // #endregion

    // #region States
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
    // #endregion

    // #region Memos
    const analysisState: IGlautAnalysisCreationFormState = useMemo(() => ({
        title: analysis.title ?? "",
        goal: analysis.goal ?? "",
        selectedIqIds: analysis.sources ?? [],
        categories: analysis.categories ?? [],
        fixedCodebook: analysis.type === "thematic" ? (analysis.fixed_codebook ?? false) : false
    }), [analysis])
    // #endregion

    // #region Callbacks
    const handleRemoveExAnteAnalysis = useCallback(() => {
        if (!project) return

        const analysisId = analysis.id
        if (!analysisId) return

        if (!analysis.isEditing) {
            outlineTabIqsDispatch({ type: "remove-analysis-from-iq-to-be-built", analysisId })
            return
        }

        analysisService.deleteAnalysis({ projectId: project._id, analysisId }).then(() => {
            outlineTabIqsDispatch({ type: "remove-analysis-from-iq-to-be-built", analysisId })
        })
    }, [analysis.id, analysis.isEditing, analysisService, outlineTabIqsDispatch, project])

    const handleSelectExAnteAnalysis = useCallback(() => {
        if (!analysis.id) return

        if (analysis.id === selectedExAnteAnalysisId)
            outlineTabIqsDispatch({ type: "remove-selected-ex-ante-analysis" })
        else
            outlineTabIqsDispatch({ type: "set-selected-ex-ante-analysis-id", analysisId: analysis.id })
    }, [analysis.id, outlineTabIqsDispatch, selectedExAnteAnalysisId])

    const handleUpdateAnalysis: IGlautAnalysisCreationFormOnChangeHandler = useCallback(newState => {
        if (!analysis.id) return

        const { categories, fixedCodebook, goal, selectedIqIds, title } = newState

        outlineTabIqsDispatch({
            type: "set-analysis-to-iq-to-be-built",
            analysis: {
                ...analysis,
                ...{
                    goal,
                    title,
                    categories,
                    sources: selectedIqIds
                },
                ...(analysis.type === "thematic" ? { fixed_codebook: fixedCodebook } : {})
            },
            isEditing: analysis.isEditing
        })
    }, [outlineTabIqsDispatch, analysis])
    // #endregion

    if (!iqToBeBuilt?.analyses) return <></>

    return (
        <div className="flex flex-col gap-[1.5em]">
            <button className="flex justify-between p-[1em] rounded-[0.5em] bg-glaut-very-light-grey
                border-none shadow-none text-base m-0 w-full" onClick={handleSelectExAnteAnalysis}>
                <p className="text-[13.33px] font-medium text-glaut-dark-grey">
                    {analysis.type ? newAnalysisOptionsMap[analysis.type].title : ""}
                </p>

                <MdClose
                    className={`text-glaut-dark-grey w-[1rem] h-[1rem] rounded-none
                        ${canEdit ? "cursor-pointer" : "cursor-not-allowed"}
                    `}
                    onClick={e => {
                        e.stopPropagation()
                        setIsDeleteModalOpen(true)
                    }}
                />

            </button>
            <ConfirmModal
                open={isDeleteModalOpen}
                onClose={() => { setIsDeleteModalOpen(false) }}
                onConfirm={() => {
                    if (!analysis.id) return
                    handleRemoveExAnteAnalysis()
                    setIsDeleteModalOpen(false)
                }}
            >
                <p className="text-[13.33px] font-medium text-glaut-text-midnight">
                    {getCopy(copy.outline.interviewQuestions.areYouSureYouWantToDeleteThisAnalysis)}
                </p>
            </ConfirmModal>
            {analysis.id && selectedExAnteAnalysisId === analysis.id && (
                <div className="mx-[1.5em]">
                    <GlautAnalysisCreationForm
                        value={analysisState}
                        onChange={handleUpdateAnalysis}
                        defaultAnalysisValue={analysis}
                        designMode="compact"
                    />
                </div>
            )}
        </div>
    )
}