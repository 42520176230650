import FloatingLayout from "@components/layouts/FloatingLayout"
import { copy, getCopy } from "@utils/Copy"
import { DetailedHTMLProps, HTMLAttributes, useCallback, useRef, useState } from "react"
import { MdOutlineArrowDropDown } from "react-icons/md"

type IGlautDropdownOption = {
    label: string
    value: string
}

interface IGlautDropdownProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    defaultOption?: IGlautDropdownOption
    options: readonly IGlautDropdownOption[]
    onSelectOption?: (value: string) => void
    disabled?: boolean
}

export default function GlautDropdown({
    defaultOption,
    options,
    onSelectOption,
    disabled = false,
    ...props
}: Readonly<IGlautDropdownProps>) {
    // #region States
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    const [selectedOption, setSelectedOption] = useState<IGlautDropdownOption | undefined>(defaultOption)
    // #endregion

    // #region Refs
    const buttonDropdownRef = useRef<HTMLButtonElement>(null)
    // #endregion

    // #region Callbacks
    const handleSelectOption = useCallback((option: IGlautDropdownOption) => {
        setSelectedOption(option)
        onSelectOption?.(option.value)
        setIsDropdownOpen(false)
    }, [onSelectOption])
    // #endregion

    return (
        <div {...props}>
            <button
                className={`flex py-[0.5rem] px-[0.75rem] border-1 rounded-[0.25rem] w-full
                    shadow-none m-0 text-base
                    bg-glaut-cards
                    ${selectedOption === undefined ? "border-glaut-stroke-button" : "border-glaut-light-grey"}
                `}
                ref={buttonDropdownRef}
                onClick={() => setIsDropdownOpen(prev => !prev)}
                disabled={disabled}
            >
                <div className="flex-1">
                    {selectedOption === undefined && (
                        <p className="text-[13.33px] font-medium text-glaut-light-grey text-center">
                            {getCopy(copy.actions.setValue)}
                        </p>
                    )}
                    {selectedOption !== undefined && (
                        <p className="text-[13.33px] font-medium text-glaut-text-midnight text-center">
                            {selectedOption.label}
                        </p>
                    )}
                </div>
                <MdOutlineArrowDropDown className="h-[1.25em] w-[1.25em] text-glaut-text-midnight" />
            </button>
            {isDropdownOpen && (
                <FloatingLayout
                    anchorRef={buttonDropdownRef}
                    onClose={() => setIsDropdownOpen(false)}
                    place="bottom"
                    boundingBoxOffset={10}
                    verticalOffset={0}
                    className={`absolute flex flex-col rounded-[0.25rem] border-1 py-[0.5rem] px-[0.75rem]
                        z-[999999999999]
                        bg-glaut-off-white border-glaut-grey shadow-[0px_0px_8px_0px_rgb(0,0,0,0.15)]
                    `}
                    style={{ width: buttonDropdownRef.current?.offsetWidth }}
                >
                    {options.map(opt => (
                        <button
                            key={opt.value}
                            className="border-none bg-transparent shadow-none p-0 m-0 w-full hover:bg-[#F7F7F7]"
                            onClick={() => { handleSelectOption(opt) }}
                        >
                            <p className="text-[13.33px] font-medium text-glaut-text-midnight text-center py-[0.5rem]">
                                {opt.label}
                            </p>
                        </button>
                    ))}
                </FloatingLayout>
            )}
        </div>
    )
}