import { Font } from "@react-pdf/renderer"

export function useRegisterPdfFonts() {
    Font.register({
        family: "Poppins",
        fonts: [{
            src: "https://fonts.gstatic.com/s/poppins/v21/pxiByp8kv8JHgFVrLDz8V1s.ttf",
            fontWeight: 300,
            fontStyle: "normal"
        }, {
            src: "https://fonts.gstatic.com/s/poppins/v21/pxiEyp8kv8JHgFVrFJA.ttf",
            fontWeight: 400,
            fontStyle: "normal"
        }, {
            src: "https://fonts.gstatic.com/s/poppins/v21/pxiByp8kv8JHgFVrLGT9V1s.ttf",
            fontWeight: 500,
            fontStyle: "normal"
        }, {
            src: "https://fonts.gstatic.com/s/poppins/v21/pxiByp8kv8JHgFVrLEj6V1s.ttf",
            fontWeight: 600,
            fontStyle: "normal"
        }, {
            src: "https://fonts.gstatic.com/s/poppins/v21/pxiByp8kv8JHgFVrLCz7V1s.ttf",
            fontWeight: 700,
            fontStyle: "normal"
        }, {
            src: "https://fonts.gstatic.com/s/poppins/v21/pxiByp8kv8JHgFVrLDD4V1s.ttf",
            fontWeight: 800,
            fontStyle: "normal"
        }, {
            src: "https://fonts.gstatic.com/s/poppins/v21/pxiByp8kv8JHgFVrLBT5V1s.ttf",
            fontWeight: 900,
            fontStyle: "normal"
        }, {
            src: "https://fonts.gstatic.com/s/poppins/v21/pxiDyp8kv8JHgFVrJJLm21llEA.ttf",
            fontWeight: 300,
            fontStyle: "italic"
        }, {
            src: "https://fonts.gstatic.com/s/poppins/v21/pxiGyp8kv8JHgFVrJJLedw.ttf",
            fontWeight: 400,
            fontStyle: "italic"
        }, {
            src: "https://fonts.gstatic.com/s/poppins/v21/pxiDyp8kv8JHgFVrJJLmg1hlEA.ttf",
            fontWeight: 500,
            fontStyle: "italic"
        }, {
            src: "https://fonts.gstatic.com/s/poppins/v21/pxiDyp8kv8JHgFVrJJLmr19lEA.ttf",
            fontWeight: 600,
            fontStyle: "italic"
        }, {
            src: "https://fonts.gstatic.com/s/poppins/v21/pxiDyp8kv8JHgFVrJJLmy15lEA.ttf",
            fontWeight: 700,
            fontStyle: "italic"
        }, {
            src: "https://fonts.gstatic.com/s/poppins/v21/pxiDyp8kv8JHgFVrJJLm111lEA.ttf",
            fontWeight: 800,
            fontStyle: "italic"
        }, {
            src: "https://fonts.gstatic.com/s/poppins/v21/pxiDyp8kv8JHgFVrJJLm81xlEA.ttf",
            fontWeight: 900,
            fontStyle: "italic"
        }]
    })
    Font.register({
        family: "Playfair Display",
        fonts: [{
            src: "https://fonts.gstatic.com/s/playfairdisplay/v37/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKdFvUDQ.ttf",
            fontWeight: 400,
            fontStyle: "normal"
        }, {
            src: "https://fonts.gstatic.com/s/playfairdisplay/v37/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKd3vUDQ.ttf",
            fontWeight: 500,
            fontStyle: "normal"
        }]
    })
    Font.registerHyphenationCallback(word => [word])
}