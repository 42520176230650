import { DetailedHTMLProps, SVGAttributes } from "react"

interface IGlautStarProps extends DetailedHTMLProps<SVGAttributes<SVGSVGElement>, SVGSVGElement> {

}

export default function GlautStar({ ...props }: Readonly<IGlautStarProps>) {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#clip0_17913_343392)">
                <path d="M30.1419 16.9224C30.1419 16.9224 12.8086 17.2486 0.693359 6.78052"
                    stroke="#FF6392" strokeWidth="2.32727" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M19.6093 0.709229C19.6093 0.709229 19.0681 16.5248 8.6875 31.2908"
                    stroke="#FF6392" strokeWidth="2.32727" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M21.3709 26.5107C21.3709 26.5107 18.5676 13.5603 8.74219 5.0213"
                    stroke="#FF6392" strokeWidth="2.32727" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M25.3138 9.17694C25.3138 9.17694 17.2369 16.3117 2.8457 22.5528"
                    stroke="#FF6392" strokeWidth="2.32727" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_17913_343392">
                    <rect width="30.8364" height="32" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}