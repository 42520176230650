import React, { useCallback, useState } from "react"
import { IInterviewQuestion } from "src/@types/interview-question"
import ProbingInstructionsTextArea from "./ProbingInstructionsTextArea"

const probingTemplates: [string, string][] = [
    ["Ask why", "Use successive ‘Why?’ questions in follow-ups to probe deeper until " +
        "the root cause of the interviewee’s response is identified"],
    ["Ask what", "Use targeted probing questions during follow-ups to clarify the meaning " +
        "of the response and get additional details and follow-ups"],
    ["Ask example", "Use follow-up questions to ask participants for specific examples that " +
        "illustrate their responses"]
]

interface IEditInsightProps {
    question: Partial<IInterviewQuestion>
    setQuestion: React.Dispatch<React.SetStateAction<Partial<IInterviewQuestion>>>
    disabled?: boolean
}

/** @deprecated */
export default function EditInsight({
    question,
    setQuestion,
    disabled = false
}: Readonly<IEditInsightProps>) {
    // #region States
    const [hasProbing, setHasProbing] = useState(question.type === "open" && question?.probing_instructions != null)
    // #endregion

    // #region Callbacks
    const handleToggleHasProbing = useCallback(() => {
        setHasProbing(prev => {
            setQuestion(q => ({ ...q, probing_instructions: prev ? null : ""}))
            return !prev
        })
    }, [setQuestion])
    const handleSetProbingInstructions = useCallback((text: string) => {
        setQuestion(q => ({
            ...q,
            probing_instructions: text
        }))
    }, [setQuestion])
    // #endregion

    if (question.type !== "open") return <></>

    return (
        <div className="">
            <div className="flex items-center gap-[0.5em] mb-[0.5em]">
                <input
                    id="default-checkbox"
                    type="checkbox"
                    value=""
                    className={`w-4 h-4 rounded shadow-none
                        text-glaut-text-midnight border-glaut-text-midnight accent-glaut-text-midnight
                    `}
                    checked={hasProbing}
                    onChange={handleToggleHasProbing}
                    disabled={disabled}
                />
                <label
                    htmlFor="default-checkbox"
                    className="text-[13.33px] font-medium text-glaut-dark-grey">
                    Probe deeper with follow-ups (optional)
                </label>
            </div>
            <ProbingInstructionsTextArea
                placeholder={"Instruct the moderator which topics to follow-up on..."}
                probingInstructions={question.probing_instructions ?? ""}
                setProbingInstructions={handleSetProbingInstructions}
                disabled={disabled || !hasProbing}
                probingTemplates={probingTemplates}
            />
        </div>
    )
}
