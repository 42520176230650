import { getCopy } from "@utils/Copy"
import { outlineTabContentIqsQuestionSyntheticAnswersLoadingCopy } from "./utils/copy"
import LoadingBar from "@components/loading/LoadingBar"

interface IOutlineTabContentIqsQuestionSyntheticAnswersLoadingProps {
    percentage: number
}

export default function OutlineTabContentIqsQuestionSyntheticAnswersLoading({
    percentage
}: Readonly<IOutlineTabContentIqsQuestionSyntheticAnswersLoadingProps>) {
    return (
        <div className="flex-1 flex flex-col justify-center items-center gap-[0.75rem] py-[0.75rem] px-[3.75rem]
            rounded-[0.25rem] max-h-[400px]
            bg-glaut-cards">
            <p className="text-[13.33px] font-medium text-glaut-dark-grey text-center">
                {getCopy(outlineTabContentIqsQuestionSyntheticAnswersLoadingCopy.generatingSyntheticAnswers)}
            </p>
            <LoadingBar percentage={percentage} style={{ flex: 1, maxHeight: "10px" }} />
        </div>
    )
}