import ErrorLevel from "@utils/ErrorLevel"
import { createContext, useCallback, useContext, useEffect, useMemo, useReducer } from "react"
import { GoAlertFill } from "react-icons/go"
import { IoCloseSharp } from "react-icons/io5"
import { MdInfo } from "react-icons/md"
import { v4 as uuid } from "uuid"
import "../styling/Toast.css"

// #region Type definitions
interface IToastPayload {
    id?: string
    title: string
    detail?: string
    messageLevel?: number
    timeout?: number
}

type IToast = Required<IToastPayload>

interface ToastProviderProps {
    children: React.ReactNode
}

interface ToastContextState {
    toasts: IToast[]
    /** @deprecated Please use `useToastDispatch` instead */
    addToast: (title: string, detail?: string, messageLevel?: number, timeout?: number) => void
    /** @deprecated Please use `useToastDispatch` instead */
    removeToast: (id: string) => void
}

type ToastContextAction = {
    type: "add-toast"
    payload: IToastPayload
} | {
    type: "remove-toast"
    toastId: string
}
// #endregion

// #region Context definitions
const ToastContext = createContext(
    {} as ToastContextState
)
const ToastContextDispatch = createContext(
    {} as React.Dispatch<ToastContextAction>
)
// #endregion

// #region Hook definitions
export function useToast() {
    return useContext(ToastContext)
}
export function useToastDispatch() {
    return useContext(ToastContextDispatch)
}
// #endregion

// #region Provider definition
export default function ToastProvider({
    children
}: Readonly<ToastProviderProps>) {
    // #region Context configurations
    const initialState: ToastContextState = {
        toasts: [],
        addToast: () => { },
        removeToast: () => { }
    }

    const [rawState, dispatch] = useReducer(ToastReducer, initialState)
    // #endregion

    // #region Callbacks
    const removeToast = useCallback((id: string) => {
        dispatch({ type: "remove-toast", toastId: id })
    }, [])

    const addToast = useCallback((title: string, detail?: string, messageLevel?: number, timeout?: number) => {
        const id = uuid()
        const payload = {
            id,
            title,
            detail: detail ?? "",
            messageLevel: messageLevel ?? ErrorLevel.Info,
            timeout: timeout ?? 3000
        }

        dispatch({ type: "add-toast", payload })

        return payload
    }, [])
    // #endregion

    // #region Context state
    const state = useMemo(() => ({
        ...rawState,
        /** @deprecated Please use `useToastDispatch` instead */
        addToast,
        /** @deprecated Please use `useToastDispatch` instead */
        removeToast
    }), [rawState, addToast, removeToast])
    // #endregion

    // #region Effects
    useEffect(() => {
        const toast = rawState.toasts[rawState.toasts.length - 1]
        if (toast?.timeout)
            setTimeout(() => {
                dispatch({ type: "remove-toast", toastId: toast.id })
            }, toast.timeout)
    }, [removeToast, rawState.toasts])
    // #endregion

    return (
        <ToastContext.Provider value={state}>
            <ToastContextDispatch.Provider value={dispatch}>
                {children}
                <div
                    className="column toast-container"
                >
                    {rawState.toasts.map(toast => (
                        <div
                            key={toast.id}
                            className={"toast " + (toast.messageLevel >= ErrorLevel.Warning ? "warning" : "info")}
                        >
                            <span className="left">
                                {toast.messageLevel >= ErrorLevel.Warning ? <GoAlertFill /> : <MdInfo />}
                            </span>
                            <span>
                                <span className="title">{toast.title}</span><br />
                                <span className="detail">{toast.detail}</span>
                            </span>
                            <span className="right">
                                <IoCloseSharp onClick={() => { removeToast(toast.id) }} />
                            </span>
                        </div>
                    ))}
                </div>
            </ToastContextDispatch.Provider>
        </ToastContext.Provider>
    )
}
// #endregion

// #region Reducer definition
function ToastReducer(
    state: ToastContextState,
    action: ToastContextAction
): ToastContextState {
    switch (action.type) {
        case "remove-toast": {
            return {
                ...state,
                toasts: state.toasts.filter(toast => toast.id !== action.toastId)
            }
        }
        case "add-toast": {
            const { id, title, detail, messageLevel, timeout } = action.payload

            const toastId = id ?? uuid()
            const toast = {
                id: toastId,
                title,
                detail: detail ?? "",
                messageLevel: messageLevel ?? ErrorLevel.Info,
                timeout: timeout ?? 3000
            }

            return {
                ...state,
                toasts: [...state.toasts, toast]
            }
        }
        default: {
            return state
        }
    }
}
// #endregion
