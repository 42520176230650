import { useBrowserUserAgent } from "@hooks/useBrowserUserAgent"
import { useAuthInfo } from "@propelauth/react"
import ConversationProvider from "@pages/Conversation/Interview/contexts/ConversationProvider"
import axios from "axios"
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react"
import { useParams, useSearchParams } from "react-router-dom"
import { v4 as uuid } from "uuid"
import EmbedBox from "../../components/EmbedBox"
import TopBarMessage from "../../components/TopBarMessage"
import Loading from "../../components/loading/Loading"
import { BASE_URL } from "../../hooks/useAxiosAuth"
import "../../styling/Messages.scss"
import { copy, getCopy } from "../../utils/Copy"
import ErrorLevel from "../../utils/ErrorLevel"
import { buttonQuestions, questionTypes, tagRegex } from "../../utils/Variables"
import { trackEvent, updateUserInfo } from "../../utils/traking"
import { sanitizeURL } from "../../utils/urls"
import ErrorPage from "../../components/layouts/ErrorPage"
import ConversationHeader from "./ConversationHeader"
import ConversationInput from "./ConversationInput"
import AgentMessage from "./Interview/components/AgentMessage"
import ConversationButtons from "./Interview/components/ConversationButtons"
import ConversationFooter from "./Interview/components/ConversationFooter"
import NumberResponseContent from "./Interview/components/NumberResponseContent"
import SelectButtons from "./Interview/components/SelectButtons"
import { useDebouncedIsPlaying } from "./Interview/hooks/useDebouncedIsSpeaking"
import { useWebRTCInterview } from "./Interview/hooks/useWebRTCInterview"
import { useWebSocketInterview } from "./Interview/hooks/useWebSocketInterview"
import NpsInput from "./Interview/components/NpsInput"
import ModeratorVoiceMessageSynthesizer from "./Interview/components/ModeratorVoiceMessageSynthesizer"
import WelcomePage from "./WelcomePage"
import { settings } from "@utils/Variables"
import { useTrackInteraction } from "@contexts/TrackInteractionProvider"
import { useGetInterviewEngine } from "./Interview/hooks/useGetInterviewEngine"
import InterviewTerminated from "./Interview/components/InterviewTerminated"
import AssetUpload from "./Interview/components/AssetUpload"

const getOrCreateSurveyUID = projectId => {
    // Fetches this device's anonymous key for the current project
    let uid = localStorage.getItem(projectId)

    // If there is no anonymous key saved in cookies, generate it and save it
    if (!uid) {
        uid = uuid()
        localStorage.setItem(projectId, uid)
    }

    return uid
}

const Conversation = ({ previewProject, language }) => {
    // #region Const values
    const embedVideoId = "div--embedded-interview-video"
    // #endregion

    // #region Params
    const { projectId } = useParams()
    const [searchParams] = useSearchParams()
    // #endregion

    // #region States
    const [headerHeight, setHeaderHeight] = useState(0)
    const [footerHeight, setFooterHeight] = useState(0)

    const [lang, setLang] = useState(searchParams.get("lang") || language)
    const [keyboardStatus, setKeyboardStatus] = useState("close")

    const [interviewSettings, setInterviewSettings] = useState(null)
    const [email, setEmail] = useState("")
    const [messages, setMessages] = useState([])
    const [topBarMessage, setTopBarMessage] = useState(null)
    const [completion, setCompletion] = useState(0)
    const [running, setRunning] = useState(null)

    const [isVideoPlaying, setIsVideoPlaying] = useState(false)

    const [voiceInput, setVoiceInput] = useState(true)
    const [voiceSynthesis, setSynthesis] = useState(false)

    const [hasNewMessage, setHasNewMessage] = useState(false)
    const [error, setError] = useState()

    const [LKroomStartInterval, setLKroomStartInterval] = useState(null)
    const [interviewId, setInterviewId] = useState()
    // #endregion

    // #region Browser hooks
    const { devices: { isMobile }, os: { isIos } } = useBrowserUserAgent()
    // #endregion

    // #region Refs

    const conversationHeaderRef = useRef()
    const conversationFooterRef = useRef()

    const currentMessageRef = useRef()
    const embedVideoRef = useRef()

    // #endregion

    const { startInteraction, stopInteraction } = useTrackInteraction()

    // #region Feature flags
    const { engine } = useGetInterviewEngine(interviewSettings)
    // #endregion

    // #region Debounced hooks
    const { isPlaying, setIsPlaying, setParticipant } = useDebouncedIsPlaying({
        shouldAllowSpeaking: voiceSynthesis,
        isWebRTC: engine === "webrtc"
    })
    // #endregion

    // #region Auth
    const { user } = useAuthInfo()
    // #endregion

    // #region Memos

    // After retrieving user info from PropelAuth, set userId using the following order:
    // query string -> PropelAuth user -> local storage
    const userId = useMemo(
        () => (user === undefined ? null : searchParams.get("userid"))
            || (user ? user.userId : null)
            || getOrCreateSurveyUID(projectId),
        [user, projectId, searchParams]
    )

    const identifier = useMemo(
        () => interviewSettings?.collect_email ? email : userId, [email, userId, interviewSettings])

    const brandColor = useMemo(() => interviewSettings?.accent_color, [interviewSettings])

    const lastMessage = useMemo(
        () => (messages.length ? { ...messages.slice(-1)[0], index: messages.length - 1 } : {}),
        [messages]
    )
    const isTheFirstOpenQuestion = useMemo(() => {
        if (!lastMessage || lastMessage.is_followup) return false

        return messages.find(m => m?.type && !buttonQuestions.includes(m?.type))?.id === lastMessage.id
    }, [lastMessage, messages])

    // Only show buttons if the question has buttons and was not already asked
    const showButtons = useMemo(
        () => running && buttonQuestions.includes(lastMessage?.type) && !lastMessage.is_followup,
        [lastMessage, running]
    )

    const isLastMessageFromInterviewer = useMemo(
        () => ["moderator", "interviewer", "assistant"].includes(lastMessage?.role),
        [lastMessage.role]
    )

    const showInput = useMemo(
        () =>
            !showButtons &&
            lastMessage?.completed &&
            running &&
            isLastMessageFromInterviewer &&
            (!isMobile || lastMessage.type !== "number"),
        [lastMessage, running, showButtons, isMobile, isLastMessageFromInterviewer]
    )

    const currentMessageMarginTopClassName = useMemo(() => {
        if (!running) return "pt-[35vh]"
        if (lastMessage.embed_url || lastMessage.img) return `pt-[5%] ${voiceInput ? "mb-auto" : ""}`
        if (voiceInput) return !isMobile ? "pt-[20vh] mb-auto" : "pt-[10vh] mb-auto"
        if (!["open", "opening"].includes(keyboardStatus)) return "pt-[20vh] md:mb-auto"
        if (lastMessage.type === questionTypes.number) return "pt-[10%]"

        return ""
    }, [running, voiceInput, keyboardStatus, lastMessage, isMobile])

    // true only when the synthesizer has done playing or it's disabled
    const hasFinishedPlaying = useMemo(() => voiceSynthesis ? isPlaying === false : true, [voiceSynthesis, isPlaying])

    const isWebRTC = useMemo(() => engine === "webrtc", [engine])

    const showScaleButtons = useMemo(
        () => showButtons && [questionTypes.nps, questionTypes.scale].includes(lastMessage?.type) && running,
        [lastMessage?.type, running, showButtons]
    )

    const hasEndingMessage = useMemo(
        () => Object.entries(interviewSettings?.ending_message ?? {}).length > 0
            || Object.entries(interviewSettings?.screenout_ending_message ?? {}).length > 0
            || Object.entries(interviewSettings?.quotafull_ending_message ?? {}).length > 0,
        [interviewSettings]
    )

    const moderatorReplyType = useMemo(() => {
        if (!["moderator", "interviewer", "assistant"].includes(lastMessage.role)) return null

        if (messages.length === 1) return "start"
        if (messages[messages.length - 2]?.recording_id) return "voice"
        if (messages[messages.length - 2]?.response) return "button"
        return "text"
    }, [messages, lastMessage.role])

    // #endregion

    // #region Interview hooks
    const {
        liveKitRoom: LKroom,
        hasLiveKitRoomStarted: LKroomStarted,
        trackElementRef,
        initializeConnection: connectWebRTC
    } = useWebRTCInterview({
        userId,
        identifier,
        lang,
        projectId,
        messages,
        interviewSettings,
        error,
        setMessages,
        setInterviewId,
        setHasNewMessage,
        setRunning,
        setCompletion,
        setParticipant,
        setError
    })

    const {
        socket,
        initializeConnection: connectWebSocket,
        reconnectCountRef: reconnectCount
    } = useWebSocketInterview({
        identifier,
        projectId,
        lang,
        userId,
        messages,
        interviewSettings,
        setInterviewId,
        setCompletion,
        setError,
        setMessages,
        setRunning,
        setIsPlaying
    })

    // #endregion

    // #region Callbacks

    // Sends a UI-based answer to backend (buttons, NPS)
    const addClosedMessage = (content, questionId, value) => {
        if (!content.length || !questionId.length) return
        const message = { role: "respondent", content, response: { question_id: questionId, value } }
        setMessages(ms => [...ms, message])

        startInteraction("moderator-reply")

        if (isWebRTC) sendJSON({ message })
    }

    // Function to send JSON trough livekit
    const sendJSON = payload => {
        const encoder = new TextEncoder()
        const data = encoder.encode((JSON.stringify(payload)))
        LKroom?.localParticipant?.publishData(data, { reliable: true })
    }

    // This disables voice on iOS by default
    const setVoiceSynthesis = useCallback(v => setSynthesis(!isIos && v), [isIos])

    const connect = () => {
        if (isWebRTC) {
            connectWebRTC()
            return
        }

        connectWebSocket()
    }

    const sendLKStartMessage = () => {
        // Sends the start signal
        console.log("Sending start signal")
        sendJSON({ start: true })
    }

    const performRedirect = useCallback(redirectUrl => {
        const redirectLink = sanitizeURL(redirectUrl).replaceAll(
            "{userid}",
            identifier
        )

        if (isWebRTC) {
            // Sends the redirect link into the interview object
            sendJSON({ metadata: { key: "redirected_link", value: redirectLink } })

            // Finally closes the connection and redirects to the appropriate link
            LKroom?.disconnect()
        } else {
            // Sends the redirect link into the interview object
            socket?.send(JSON.stringify({ metadata: { redirected_to: redirectLink } }))

            // Finally closes the socket and redirects to the appropriate link
            socket?.close(1000)
        }

        window.open(redirectLink, "_self")
    }, [LKroom, identifier, isWebRTC, sendJSON, socket])

    // #endregion

    // #region Effects

    // [WS/LK] Get preview project settings
    useEffect(() => {
        if (!previewProject) return
        setInterviewSettings({ ...previewProject.interview_settings, ...previewProject.brand_settings })
    }, [previewProject])

    // [WS/LK] Get project settings
    useEffect(() => {
        if (previewProject || !projectId || interviewSettings) return

        axios
            .get(`${BASE_URL}/interviews/${projectId}/settings?v=insights`)
            .then(res => {
                // Sets the interviewSettings and whether or not we are voice enabled
                setInterviewSettings(res.data)
                setVoiceSynthesis(res.data.voice_enabled)

                // If there is no intro message and we already have the user id injected, start the interview
                if (userId && !res.data.intro_message) setRunning(true)

                // If the link does not specify the language or it's erroneous, 
                // sets the project lang as the interview lang
                const supportedLanguages = [...res.data.supported_languages || [], res.data.language]
                if (!lang || !supportedLanguages.includes(lang)) setLang(res.data.language)
            })
            .catch(e => {
                console.log(e)
                const code = e?.response?.status
                const codeDetail = e?.response?.data?.detail
                const customError = [404, 422].includes(code)
                let message = customError ? getCopy(copy.errorProjectNotFound, lang) : null
                let detail = customError ? getCopy(copy.errorProjectNotFoundDetail, lang) : null
                let errorLevel = ErrorLevel.Warning
                if (!code) {
                    message = getCopy(copy.errorNetwork, lang)
                    detail = getCopy(copy.errorNetworkDetail, lang)
                } else if (e.response?.data?.detail === "project_unavailable") {
                    message = getCopy(copy.errorProjectUnavailable, lang)
                    detail = getCopy(copy.errorProjectUnavailableDetail, lang)
                    errorLevel = ErrorLevel.Info
                }
                setError({
                    code,
                    codeDetail,
                    message,
                    detail,
                    showCode: !customError && code,
                    errorLevel,
                    error: !customError ? e : null
                })
            })
    }, [lang, previewProject, projectId, interviewSettings, setVoiceSynthesis, userId])

    // [WS/LK] Track interview load
    useEffect(() => {
        if (projectId && interviewSettings && !previewProject && userId) {
            updateUserInfo({ id: userId, email: user && user.email }, interviewSettings)

            trackEvent("interview_load", {
                project_id: projectId,
                project_name: interviewSettings?.project_name,
                project_status: interviewSettings?.project_status,
                product_version: "v1",
                workspace_id: interviewSettings?.workspace_id,
                workspace_name: interviewSettings?.workspace_name,
                organization_id: interviewSettings?.organization_id,
                organization_name: interviewSettings?.organization_name
            })
        }
    }, [projectId, interviewSettings, userId, previewProject])

    // [LK] Sends last message
    useEffect(() => {
        // If the conversation is not running, return
        if (!isWebRTC || !LKroom) return

        // Send the start message
        sendLKStartMessage()
        setLKroomStartInterval(
            setInterval(sendLKStartMessage, 1000)
        )
    }, [isWebRTC, LKroom])

    // [LK] Clear the interval when the room starts
    useEffect(() => {
        if (!isWebRTC) return

        if (LKroomStarted && LKroomStartInterval) {
            // Clears the interval
            clearInterval(LKroomStartInterval)
            setLKroomStartInterval(null)

            // Starts and closes microphone so it's faster the second time
            LKroom.localParticipant.setMicrophoneEnabled(true)
            LKroom.localParticipant.setMicrophoneEnabled(false)
        }
    }, [isWebRTC, LKroom, LKroomStarted, LKroomStartInterval])

    // [WS/LK] Reset keyboard status when message changes
    useEffect(() => {
        setKeyboardStatus("close")
    }, [lastMessage])

    // [WS/LK] Connects to LiveKit/WS once it starts running
    useEffect(() => {
        if (running && identifier && (!isWebRTC || !LKroom))
            connect()

    }, [running, identifier, isWebRTC, LKroom])

    // [WS/LK] If the user switches off voice synthesis, isPlaying is set to null
    useEffect(() => {
        if (!isWebRTC) {
            setIsPlaying(voiceSynthesis ? null : false)
            return
        }

        if (!trackElementRef.current) return
        sendJSON({ speak: voiceSynthesis })
        trackElementRef.current.muted = !voiceSynthesis
    }, [voiceSynthesis, isWebRTC])

    // [WS] Sends last message
    useEffect(() => {
        if (isWebRTC) return

        // If the conversation is not running, return
        if (!running) return

        //  Can't send a message if Glaut is Speaking
        if (isPlaying) return

        // Prevents sending a message if the websocket is not open yet
        if (socket?.readyState !== WebSocket.OPEN) return

        // Prevents sending a message if there is no user message to send at the end of the list
        if (messages.length && isLastMessageFromInterviewer) return

        // Sends the message together with the current language
        socket?.send(JSON.stringify({ message: lastMessage, lang }))
    }, [isWebRTC, running, messages, isPlaying, lastMessage, socket, lang, isLastMessageFromInterviewer])

    // [WS/LK] Waits for the end of the survey and closes the show
    useEffect(() => {
        // If the interview is done (including the voice) we set a timer
        if (running || messages?.length === 0) return

        const hasVoiceEnded = (!isWebRTC && hasFinishedPlaying) || (isWebRTC && !isPlaying)
        if (!hasVoiceEnded) return

        // Redirect or finish the interview
        setTimeout(() => {
            let redirectLink = null
            if (lastMessage.termination_reason === "completed" && interviewSettings?.redirect_link)
                redirectLink = sanitizeURL(interviewSettings?.redirect_link).replaceAll(
                    "{userid}",
                    identifier
                )

            if (lastMessage.termination_reason === "screenout" && interviewSettings?.screenout_redirect_link)
                redirectLink = sanitizeURL(interviewSettings?.screenout_redirect_link).replaceAll(
                    "{userid}",
                    identifier
                )

            if (lastMessage.termination_reason === "quotafull" && interviewSettings?.quotafull_redirect_link)
                redirectLink = sanitizeURL(interviewSettings?.quotafull_redirect_link).replaceAll(
                    "{userid}",
                    identifier
                )

            if (redirectLink !== null) {
                performRedirect(redirectLink)
                return
            }


            // If not present, it will open the feedback popup
            // TODO: Temporary disable the feedback popup
            // setOpenFeedback(false)

            // Finally closes the connection and redirects to the appropriate link
            if (isWebRTC) {
                LKroom?.disconnect()
                setCompletion(100)
            } else {
                socket?.close(1000)
            }
        }, 1000)
    }, [
        running,
        interviewSettings,
        isPlaying,
        isWebRTC,
        socket,
        LKroom,
        lastMessage?.termination_reason,
        performRedirect,
        lang,
        hasEndingMessage
    ])

    // Effect to track moderator_reply_received event
    useEffect(() => {
        if (!moderatorReplyType) return
        const moderatorReplyInteraction = stopInteraction("moderator-reply")

        trackEvent(
            "moderator_reply_received",
            {
                project_id: projectId,
                project_name: interviewSettings?.project_name,
                project_status: interviewSettings?.project_status,
                product_version: "v1",
                workspace_id: interviewSettings?.workspace_id,
                workspace_name: interviewSettings?.workspace_name,
                organization_id: interviewSettings?.organization_id,
                organization_name: interviewSettings?.organization_name,
                identifier,
                latency: moderatorReplyInteraction.latency,
                moderator_reply_type: moderatorReplyType
            },
            moderatorReplyInteraction.latency >= settings.interviewLatencyLimit
                ? new Error("The moderator's reply is too slow")
                : null
        )
    }, [identifier, interviewSettings, moderatorReplyType, projectId, stopInteraction])

    // Track the interview error
    useEffect(() => {
        if (!error) return

        trackEvent(error.eventName || "interview_error", {
            project_id: projectId,
            project_name: interviewSettings?.project_name,
            project_status: interviewSettings?.project_status,
            product_version: "v1",
            workspace_id: interviewSettings?.workspace_id,
            workspace_name: interviewSettings?.workspace_name,
            organization_id: interviewSettings?.organization_id,
            organization_name: interviewSettings?.organization_name,
            identifier,
            errorCode: error.code || null,
            errorCodeDetail: error.codeDetail || null,
            errorLevel: error.errorLevel || null,
            reconnectCount: reconnectCount.current
        }, error.error)
    }, [error, interviewSettings, projectId, identifier, reconnectCount])

    // Set isPlaying = false when voice changes to false
    useEffect(() => {
        if (!voiceSynthesis) setIsPlaying(false)
    }, [voiceSynthesis, setIsPlaying])

    useEffect(() => {
        if (!hasNewMessage) return

        setIsPlaying(voiceSynthesis ? null : false)
        setHasNewMessage(false)
    }, [hasNewMessage, voiceSynthesis, setIsPlaying])

    // Dynamically define the margin for the message regarding the header and footer
    useLayoutEffect(() => {
        const handleWindowResize = () => {
            const currHeaderHeight = conversationHeaderRef?.current?.getBoundingClientRect()?.height ?? 0
            if (currHeaderHeight !== headerHeight)
                setHeaderHeight(currHeaderHeight)

            const currFooterHeight = conversationFooterRef?.current?.getBoundingClientRect()?.height ?? 0
            if (currFooterHeight !== footerHeight)
                setFooterHeight(currFooterHeight)
        }

        window.addEventListener("resize", handleWindowResize)
        if (headerHeight === 0 || footerHeight === 0) handleWindowResize()

        return () => {
            window.removeEventListener("resize", handleWindowResize)
        }
    })

    // #endregion

    // Error page
    if (error)
        return (
            <ErrorPage
                lang={lang}
                code={error.code}
                message={error.message}
                detail={error.detail}
                showCode={error.showCode}
                errorLevel={error.errorLevel}
            />
        )

    // Loading screen
    if (!interviewSettings)
        return <Loading fullPage={true} powered={true} />

    // Welcome page
    if (running === null && (!userId || interviewSettings?.intro_message))
        return (
            <WelcomePage
                interviewSettings={interviewSettings}
                lang={language ?? lang}
                setLang={setLang}
                email={email}
                prefilledId={userId}
                setEmail={setEmail}
                setRunning={setRunning}
                previewProject={previewProject}
            />
        )

    if (running === false && lastMessage.termination_reason !== null)
        if (lastMessage.termination_reason !== "completed" && !hasEndingMessage)
            return (
                <InterviewTerminated
                    lang={lang}
                    terminationReason={lastMessage.termination_reason}
                />
            )

    // Actual survey
    return (
        <ConversationProvider embedVideoId={embedVideoId} LKroom={LKroom} sendJSON={sendJSON} isWebRTC={isWebRTC}>
            <div
                className={[
                    "overflow-y-auto p-0 bg-glaut-off-white w-full",
                    "h-dvh",
                    lastMessage.img ? "with-image" : "",
                    `${lastMessage.type}-question`
                ].join(" ")}
            >
                <TopBarMessage
                    message={topBarMessage}
                    messageLevel={ErrorLevel.Warning}
                    setMessage={setTopBarMessage}
                />
                <div className="content-wrapper h-full py-0 px-0 2xl:px-[4em] box-border">
                    <ConversationHeader
                        interviewSettings={interviewSettings}
                        previewProject={previewProject}
                        running={running}
                        completion={completion}
                        voice={voiceSynthesis}
                        setVoice={setVoiceSynthesis}
                        lang={lang}
                        customRef={conversationHeaderRef}
                    />
                    <div
                        className="flex flex-col w-full h-full box-border items-center"
                        style={{ overflowY: previewProject ? "auto" : "none" }}
                    >
                        <div
                            id="div--header-spacing-box"
                            style={{ marginTop: `${headerHeight}px` }}
                        >
                        </div>
                        <div
                            className={`current-message overflow-auto transition-all ease-in px-[1em] h-full
                                ${currentMessageMarginTopClassName}
                            `}
                            ref={currentMessageRef}
                            style={{
                                color: brandColor,
                                marginBottom: `${footerHeight}px`
                            }}
                        >
                            {(lastMessage && isLastMessageFromInterviewer && lastMessage.content) ? (
                                <>
                                    {lastMessage.embed_url && (
                                        <div className="mb-2 md:flex md:justify-center">
                                            <EmbedBox
                                                id={embedVideoId}
                                                src={lastMessage.embed_url}
                                                customRef={embedVideoRef}
                                                onVideoLoaded={() => {
                                                    setIsVideoPlaying(null)
                                                }}
                                                onVideoEnded={() => {
                                                    setIsVideoPlaying(false)
                                                }}
                                            />
                                        </div>
                                    )}
                                    {lastMessage.img && (
                                        <img
                                            src={lastMessage.img}
                                            className="message-image fade-in"
                                            alt="question-illustration"
                                        />
                                    )}
                                    {isWebRTC && (
                                        <AgentMessage
                                            message={lastMessage.content.replace(tagRegex, "")}
                                            isSpeaking={!lastMessage.restarted && voiceSynthesis}
                                        />
                                    )}
                                    {!isWebRTC && (
                                        <ModeratorVoiceMessageSynthesizer
                                            setIsPlaying={setIsPlaying}
                                            lastMessage={lastMessage}
                                            lang={lang}
                                            isVoiceEnabled={voiceSynthesis}
                                        />
                                    )}

                                    {/* multipleChoice for retro-compatibility */}
                                    {showButtons && lastMessage.type === questionTypes.multipleChoice && (
                                        <ConversationButtons
                                            addClosedMessage={addClosedMessage}
                                            lastMessage={lastMessage}
                                            isPlaying={isPlaying}
                                            color={brandColor}
                                            lang={lang}
                                        />
                                    )}

                                    {showButtons && lastMessage.type === questionTypes.select && (
                                        <SelectButtons
                                            addClosedMessage={addClosedMessage}
                                            lastMessage={lastMessage}
                                            isPlaying={isPlaying}
                                            color={brandColor}
                                            lang={lang}
                                            isVideoPlaying={isVideoPlaying}
                                        />
                                    )}

                                    {showButtons && lastMessage.type === questionTypes.asset && (
                                        <AssetUpload
                                            lang={lang}
                                            interviewId={interviewId}
                                            isPlaying={isPlaying}
                                            lastMessage={lastMessage}
                                            setMessages={setMessages}
                                        />
                                    )}

                                    {isMobile && lastMessage.type === questionTypes.number && (
                                        <NumberResponseContent
                                            isPlaying={isPlaying}
                                            setMessages={setMessages}
                                            lastMessage={lastMessage}
                                            setKeyboardStatus={setKeyboardStatus}
                                            currentVoiceInput={voiceInput}
                                            setVoiceInput={setVoiceInput}
                                        />
                                    )}
                                </>
                            ) : (
                                <Loading color={brandColor} />
                            )}

                            {showScaleButtons && (
                                <NpsInput
                                    addClosedMessage={addClosedMessage}
                                    message={lastMessage}
                                    isPlaying={isPlaying}
                                    min={lastMessage.min}
                                    max={lastMessage.max}
                                />
                            )}
                        </div>

                        {showInput && <ConversationInput
                            lang={lang}
                            isPlaying={isPlaying}
                            setMessages={setMessages}
                            voiceInput={voiceInput}
                            setVoiceInput={setVoiceInput}
                            running={running}
                            lastMessage={lastMessage}
                            color={brandColor}
                            previewProject={previewProject}
                            interviewSettings={interviewSettings}
                            completion={completion}
                            keyboardStatus={keyboardStatus}
                            setKeyboardStatus={setKeyboardStatus}
                            isTheFirstOpenQuestion={isTheFirstOpenQuestion}
                            embedVideoRef={embedVideoRef}
                            footerHeight={footerHeight}
                            setIsPlaying={setIsPlaying}
                            setTopBarMessage={setTopBarMessage}
                        />}
                    </div>

                    <ConversationFooter
                        isPlaying={isPlaying}
                        isVoiceInput={voiceInput}
                        setIsVoiceInput={setVoiceInput}
                        lang={lang}
                        showInputToggle={showInput}
                        customRef={conversationFooterRef}
                    />
                </div>
            </div>
        </ConversationProvider>
    )
}

export default Conversation