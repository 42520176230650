import { useMemo } from "react"
import NpsContentItem from "../NpsContentItem"
import { IBaseScaleStats } from "src/@types/processing/statistics"
import { View } from "@react-pdf/renderer"
import { Style } from "@react-pdf/types"

interface NpsContentProps {
    values: IBaseScaleStats["values_count"]
    designMode?: "nps" | "scale"
    asPdf?: boolean
    twPdf?: (input: string) => Style
}

export default function ScaleContent({
    values,
    designMode = "nps",
    asPdf,
    twPdf
}: Readonly<NpsContentProps>) {
    // #region Memos
    const totalOccurrences = useMemo(
        () => Object.values(values).reduce((prev, curr) => prev + curr, 0),
        [values]
    )
    // #endregion

    if (asPdf && twPdf)
        return (
            <View style={twPdf("mt-[10.58px]")}>
                {Object.entries(values)
                    .sort(([scoreA], [scoreB]) => Number(scoreB) - Number(scoreA))
                    .map(([score, occurrences], index) => (
                        <NpsContentItem
                            index={index}
                            key={score}
                            occurrences={occurrences}
                            percentage={totalOccurrences === 0 ? 0 : Math.round((occurrences / totalOccurrences) * 100)}
                            score={Number(score)}
                            asPdf
                            twPdf={twPdf}
                        />
                    ))}
            </View>
        )

    return (
        <div className="mt-[0.75em]">
            {Object.entries(values)
                .filter(([, score]) => score !== undefined)
                .sort(([scoreA], [scoreB]) => Number(scoreB) - Number(scoreA))
                .map(([score, occurrences], index) => (
                    <NpsContentItem
                        index={index}
                        key={score}
                        occurrences={occurrences}
                        percentage={totalOccurrences === 0 ? 0 : Math.round((occurrences / totalOccurrences) * 100)}
                        score={Number(score)}
                        designMode={designMode}
                    />
                ))}
        </div>
    )
}