import { IBaseSegment } from "./segmentation"
import { IPendingTask } from "./tasks"

export const analysisTypes = ["thematic", "entity", "interpretation"] as const
export type IAnalysisType = typeof analysisTypes[number]
const analysisStatuses = ["pending", "building", "completed", "aborted"] as const
export type IAnalysisStatus = typeof analysisStatuses[number]

export interface IAnalysisCategory extends IBaseSegment {
    label: string
    url?: string
    description: string | null
    id: string
    parent: string | null
    deleted?: boolean
}

export interface IBaseAnalysis {
    id: string

    goal: string
    finalization: string
    sources: string[]
    type: IAnalysisType
    title: string | null
    categories: IAnalysisCategory[]

    deleted: boolean
    status: IAnalysisStatus

    previous_run: string | null
    previous_run_created_at: string
    created_at: string
    completed_at: string | null

    tasks: IPendingTask[]

    project_id: string | null

    completion: number | null
}

export interface IThematicAnalysis extends IBaseAnalysis {
    fixed_codebook: boolean
    type: "thematic"
}

export interface INamedEntityAnalysis extends IBaseAnalysis {
    type: "entity"
}

export interface IInterpretativeAnalysis extends IBaseAnalysis {
    type: "interpretation"
    framework_description: string
}

export type IAnalysis =
    | IThematicAnalysis
    | INamedEntityAnalysis
    | IInterpretativeAnalysis