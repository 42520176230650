import Papa from "papaparse"
import { useEffect, useMemo, useState } from "react"
import { useProject } from "../../contexts/ProjectProvider"
import { normalizeCSVData } from "../../utils/normalize-csv-data"
import CSVSettingsTabProjectSettings from "../CSVSettingsTabProjectSettings"
import CSVSettingsTabSelectCSVFile from "../CSVSettingsTabSelectCSVFile"
import CSVSettingsTabVerbatimsPreview from "../CSVSettingsTabVerbatimsPreview"
import "./index.scss"

export default function CSVSettingsTab() {
    // #region Contexts
    const { interviewQuestions } = useProject()
    // #endregion

    // #region States

    const [file, setFile] = useState<File>()
    const [fileContent, setFileContent] = useState<string[][]>()

    const [selectedColumn, setSelectedColumn] = useState<number>()
    const [normalizedFileContent, setNormalizedFileContent] = useState<ReturnType<typeof normalizeCSVData>>()

    // #endregion

    // #region Memos
    const showImport = useMemo(
        () => !normalizedFileContent && !interviewQuestions?.length,
        [normalizedFileContent, interviewQuestions]
    )
    // #endregion

    // #region Effects

    // Parse CSV file when selected
    useEffect(() => {
        if (!file) return

        Papa.parse(file, {
            complete: result => {
                setFileContent(result.data as string[][])
            },
            header: false
        })
    }, [file])

    // Normalize file contente after parsing the CSV
    useEffect(() => {
        if (!fileContent) return

        setNormalizedFileContent(normalizeCSVData(fileContent, true))
    }, [fileContent])

    // #endregion

    return (
        <div className="csv-settings h-full mx-8 mt-8 overflow-hidden">
            {showImport && (<CSVSettingsTabSelectCSVFile setFile={setFile} />)}
            {!showImport && (
                <div className="flex flex-row h-full">
                    <CSVSettingsTabProjectSettings
                        file={file}
                        fileContent={normalizedFileContent}
                        setFileContent={setNormalizedFileContent}
                        selectedColumn={selectedColumn}
                        setSelectedColumn={setSelectedColumn}
                    />
                    <CSVSettingsTabVerbatimsPreview
                        fileContent={normalizedFileContent}
                        selectedColumn={selectedColumn}
                    />
                </div>
            )}
        </div>
    )
}
