/* eslint-disable max-len */
import { ClipPath, Defs, G, Path, Rect, Svg } from "@react-pdf/renderer"

export default function PdfGlautWideLogo() {
    return (
        <Svg width="51" height="21" viewBox="0 0 51 21" fill="none">
            <G clip-path="url(#clip0_9069_165713)">
                <Path d="M9.49714 17.6962C9.49714 19.6441 7.73326 20.9995 5.02235 20.9995C2.31145 20.9995 0.408431 19.6665 0 17.593H1.84467C2.02869 18.7824 3.29886 19.5633 5.02235 19.5633C6.74584 19.5633 7.832 18.8227 7.832 17.6962C7.832 16.5696 6.66057 15.7662 4.71266 15.7662C3.72974 15.7662 2.58074 15.9503 1.53498 16.2375V14.6801C3.09241 14.4153 4.4703 13.9844 5.53401 13.3875C5.18393 13.4683 4.81589 13.5087 4.40298 13.5087C1.94341 13.5087 0.300713 12.0949 0.300713 9.9405C0.300713 7.78614 2.10499 6.16588 4.77101 6.16588C6.12646 6.16588 7.25301 6.55636 8.03397 7.21164C8.38405 6.86156 8.33917 5.73501 8.17759 5.12012H9.73502C9.98187 6.3095 9.63179 7.53928 8.77004 8.07339C9.07973 8.58505 9.24131 9.18198 9.24131 9.83727C9.24131 11.7672 7.82751 13.365 5.36346 14.3704C7.82751 14.5365 9.48816 15.8066 9.48816 17.6962M4.78896 12.3193C6.46757 12.3193 7.59861 11.3767 7.59861 9.95845C7.59861 8.54016 6.49001 7.59763 4.78896 7.59763C3.08792 7.59763 1.93444 8.54016 1.93444 9.95845C1.93444 11.3767 3.08343 12.3193 4.78896 12.3193Z" fill="#190041" />
                <Path d="M12.9217 2.28809H11.3598V16.646H12.9217V2.28809Z" fill="#190041" />
                <Path d="M23.3075 10.8252V16.6509H21.768V14.7838C21.0275 16.0989 19.7348 16.8978 18.0518 16.8978C15.9782 16.8978 14.524 15.686 14.524 13.8637C14.524 11.8126 16.3283 10.5379 19.1424 10.5379C20.0221 10.5379 20.9467 10.6591 21.7456 10.888V10.8072C21.7456 8.98052 21.1083 7.58468 19.12 7.58468C17.5805 7.58468 16.5751 8.40603 16.9432 9.9455H15.2825C14.7304 7.7687 16.5527 6.14844 19.1379 6.14844C21.97 6.14844 23.303 8.05594 23.303 10.8252M21.7456 12.2614C21.0095 12.037 20.1074 11.8934 19.2636 11.8934C17.2932 11.8934 16.1846 12.6923 16.1846 13.9221C16.1846 14.9275 16.9432 15.502 18.2178 15.502C20.0849 15.502 21.5616 14.2318 21.7456 12.2614Z" fill="#190041" />
                <Path d="M31.714 6.39511H33.2714V16.6508H31.714V14.5368C30.9555 16.1346 29.7212 16.8976 28.2266 16.8976C25.4349 16.8976 24.9233 14.2316 24.9233 12.3824V6.39062H26.4807V12.36C26.4807 13.7962 26.8308 15.3985 28.6575 15.3985C30.4842 15.3985 31.714 13.8815 31.714 11.7047V6.39062V6.39511Z" fill="#190041" />
                <Path d="M40.7399 7.80854H37.8449V13.7151C37.8449 14.6801 38.011 15.461 39.2812 15.461C39.7524 15.461 40.1654 15.3578 40.5334 15.2142V16.628C40.125 16.7716 39.5864 16.8928 39.0164 16.8928C36.3055 16.8928 36.2875 14.5544 36.2875 13.733V7.80405H34.4384V6.39026H36.2875V3.74219H37.8449V6.39026H40.7399V7.80405V7.80854Z" fill="#190041" />
                <Path d="M50.5287 4.71175L47.0279 3.60315C47.6966 3.17677 48.3205 2.85361 48.7738 2.75038C49.1374 2.66959 49.3663 2.31053 49.29 1.94699C49.2137 1.58792 48.8591 1.34556 48.4866 1.43084C47.746 1.59241 46.7855 2.131 45.834 2.76834L45.4121 0.721692C45.3358 0.358144 44.9902 0.124755 44.6132 0.196567C44.2497 0.272867 44.0118 0.631927 44.0881 0.995476L44.4561 2.78629L42.2838 2.0951C41.9337 1.98289 41.5477 2.18037 41.4355 2.53495C41.3233 2.88952 41.5208 3.27102 41.8754 3.38323L44.0567 4.07442C43.1007 4.83293 42.3556 5.4927 42.1806 5.64979C41.9023 5.89665 41.8799 6.32752 42.1312 6.60579C42.2614 6.7539 42.4499 6.8302 42.6339 6.8302C42.7955 6.8302 42.957 6.77186 43.0872 6.65516C43.4866 6.2961 44.1644 5.71263 44.9364 5.10671L45.4346 7.51242C45.5019 7.83108 45.7801 8.05101 46.0988 8.05101C46.1437 8.05101 46.1886 8.05101 46.2335 8.03754C46.597 7.96124 46.8349 7.60218 46.7586 7.23863L46.2469 4.76561L50.1203 5.99539C50.1876 6.01783 50.2549 6.02681 50.3267 6.02681C50.614 6.02681 50.8788 5.84279 50.9731 5.55554C51.0853 5.20097 50.8878 4.81947 50.5332 4.70726" fill="#FF5491" />
            </G>
            <Defs>
                <ClipPath id="clip0_9069_165713">
                    <Rect width="51" height="20.812" fill="white" transform="translate(0 0.1875)" />
                </ClipPath>
            </Defs>
        </Svg>
    )
}