import { ISelectScreeningOption, ISelectScreeningQuestion } from "@/@types/interview-question"
import { copy, getCopy } from "@utils/Copy"
import { RecursivePartial } from "@utils/types/recursive-partial"
import { useCallback, useEffect, useMemo, useState } from "react"
import { editScreeningCopy } from "../../utils/copy"
import EditScreeningQuotaOption from "../EditScreeningQuotaOption"
import GlautMessage from "@components/layouts/GlautMessage"
import { useLegacyProject } from "@pages/Project/ProjectArea/contexts/ProjectProvider"

interface IEditScreeningQuotaProps {
    question: RecursivePartial<ISelectScreeningQuestion>
    lang: string
    disabled: boolean
    setQuestion: React.Dispatch<React.SetStateAction<RecursivePartial<ISelectScreeningQuestion>>>
}

export default function EditScreeningQuota({
    question,
    lang,
    disabled,
    setQuestion
}: Readonly<IEditScreeningQuotaProps>) {
    // #region Contexts
    const { project } = useLegacyProject()
    // #endregion

    // #region Util functions
    const distributeQuotaEvenly = useCallback((options: RecursivePartial<ISelectScreeningOption>[]) => {
        const optionCount = options.length
        const evenQuota = Math.floor(100 / optionCount)
        const remainder = 100 - (evenQuota * optionCount)

        return options.map((opt, index, arr) => ({
            ...opt,
            quota: evenQuota + (index === arr.length - 1 ? remainder : 0)
        }))
    }, [])

    const areQuotasDistributedEvenly = useCallback((options: RecursivePartial<ISelectScreeningOption>[]) => {
        const optionCount = options.length
        const evenQuota = Math.floor(100 / optionCount)
        const remainder = 100 - (evenQuota * optionCount)

        return options.every((opt, idx, arr) => {
            if (idx === arr.length - 1) return opt.quota === evenQuota + remainder
            return opt.quota === evenQuota
        })
    }, [])
    // #endregion 

    // #region States
    const [shouldDistributeQuota, setShouldDistributeQuota] = useState(
        // is 1. new question and 2. either 2.1. no quota set or 2.2. quota is distributed evenly
        () => question.id === undefined && (
            !question.options?.length
            || question.options.every(opt => !opt.quota)
            || areQuotasDistributedEvenly(question.options)
        )
    )
    // #endregion

    // #region Memos
    const hasResponsesTarget = useMemo(() => !!project?.general_settings?.max_responses, [project])
    const areQuotasSummingUpTo100 = useMemo(() => {
        const quotas = question.options?.map(opt => opt.quota)
        return quotas?.reduce((acc, curr) => (acc ?? 0) + (curr ?? 0), 0) === 100
    }, [question])
    // #endregion

    // #region Callbacks
    const handleChangeOptionLabel = useCallback((e: React.ChangeEvent<HTMLInputElement>, id: string) => {
        const options = (question.options ?? []) as Partial<ISelectScreeningOption>[]

        setQuestion({
            ...question,
            options: options.map(opt =>
                opt.id === id ? { ...opt, label: { ...opt.label, [lang]: e.target.value } } : opt
            ) ?? []
        })
    }, [setQuestion, question, lang])

    const handleRemoveOption = useCallback((id: string) => {
        const options = (question.options ?? []) as Partial<ISelectScreeningOption>[]
        const filteredOptions = options.filter(opt => opt.id !== id)

        setQuestion({
            ...question,
            options: shouldDistributeQuota ? distributeQuotaEvenly(filteredOptions) : filteredOptions
        })
    }, [setQuestion, question, shouldDistributeQuota, distributeQuotaEvenly])

    const handleChangeOptionQuota = useCallback((quota: number, id: string) => {
        setShouldDistributeQuota(false)

        const options = (question.options ?? []) as Partial<ISelectScreeningOption>[]
        setQuestion({
            ...question,
            options: options.map(opt => opt.id === id ? { ...opt, quota } : opt)
        })
    }, [setQuestion, question])
    // #endregion

    // #region Effects

    // onRenderCalculateQuotasIfNewQuestion
    useEffect(() => {
        if (!shouldDistributeQuota) return

        setQuestion(question => ({ ...question, options: distributeQuotaEvenly(question.options ?? []) }))
    }, [shouldDistributeQuota, distributeQuotaEvenly, setQuestion, question.options?.length])

    // #endregion

    return (
        <div className="flex flex-col gap-[0.75rem]">
            <p className="text-[13.33px] font-medium text-glaut-text-midnight">
                {getCopy(editScreeningCopy.setQuotasToControlYourTargetAudience)}
            </p>
            <GlautMessage
                message={getCopy(editScreeningCopy.screeningWithQuotasRequiresSettingAResponsesTargetFirst) ?? ""}
            />
            <div className="flex flex-col gap-[0.25rem]">
                <div className="flex gap-[0.25rem]">
                    <p className="flex-1 text-[13.33px] font-medium py-[0.5rem] text-center rounded-[0.25rem]
                            border-1
                            text-[rgb(0,0,0,0.6)] border-glaut-very-light-grey">
                        {getCopy(copy.words.option)}
                    </p>
                    <p className="flex-1 text-[13.33px] font-medium py-[0.5rem] text-center rounded-[0.25rem]
                            border-1
                            text-[rgb(0,0,0,0.6)] border-glaut-very-light-grey">
                        {getCopy(editScreeningCopy.quotaPercentage)}
                    </p>
                </div>
                {question.options?.map((opt, idx) => (
                    <EditScreeningQuotaOption
                        key={opt.id}
                        option={opt}
                        lang={lang}
                        disabled={disabled}
                        isRemovalAllowed={idx > 0}
                        valid={areQuotasSummingUpTo100}
                        handleRemoveOption={handleRemoveOption}
                        handleChangeOptionLabel={handleChangeOptionLabel}
                        handleChangeOptionQuota={handleChangeOptionQuota}
                    />
                ))}
            </div>
            {!hasResponsesTarget && (
                <GlautMessage
                    message={getCopy(editScreeningCopy.pleaseSetAResponsesTargetFirstToUseQuotasPeriod) ?? ""}
                    type="danger"
                />
            )}
            {!areQuotasSummingUpTo100 && (
                <GlautMessage
                    message={getCopy(editScreeningCopy.pleaseMakeSureTheQuotasSumUpTo100Percent) ?? ""}
                    type="danger"
                />
            )}
        </div>
    )
}