import GlautTooltip from "@components/layouts/GlautTooltip"
import { Text, View } from "@react-pdf/renderer"
import { Style } from "@react-pdf/types"
import { Fragment, useRef } from "react"
import { PiDotsThreeCircle } from "react-icons/pi"
import { v4 as uuid } from "uuid"

interface IProjectMetricsBoxEntryTextProps {
    value: string
    description: string
    details?: {
        [slug: string]: {
            label: string
            value: number
        }
    }
    asPdf?: boolean
    twPdf?: (input: string) => Style
}

export default function ProjectMetricsBoxTextEntry({
    description,
    value,
    details,
    asPdf = false,
    twPdf
}: Readonly<IProjectMetricsBoxEntryTextProps>) {
    // #region Refs
    const tooltipIdRef = useRef(`tooltip--project-metrics-box-entry--${uuid()}`)
    // #endregion

    if (asPdf && twPdf)
        return (
            <View style={
                twPdf("flex flex-col justify-center rounded-[0.5em] text-center p-[0.5em] flex-1 h-full bg-glautCards")
            }>
                <Text style={twPdf("font-sans text-[12.22px] leading-[18.33px] text-glautMidnight")}>
                    {value}
                </Text>
                <Text style={[twPdf("font-sans"), twPdf("text-[10.18px] text-glautTextMidnight")]}>
                    {description}
                </Text>
            </View>
        )

    return (
        <div className={`relative flex flex-col justify-center rounded-[0.5em] text-center p-[0.5em] flex-1 h-full
            bg-glaut-cards 
        `}>
            <p className={value === "Not available"
                ? "text-[13.33px] leading-[28.8px] text-glaut-light-grey"
                : "text-[19.2px] text-glaut-text-midnight"
            }>
                {value}
            </p>
            <p className="text-[13.33px] font-medium text-glaut-text-midnight whitespace-nowrap">
                {description}
            </p>
            {details && (
                <>
                    <PiDotsThreeCircle
                        className="absolute bottom-[0.25rem] right-[0.25rem] text-glaut-grey h-[1.25em] w-[1.25em]"
                        data-tooltip-id={tooltipIdRef.current}
                    />
                    <GlautTooltip id={tooltipIdRef.current}>
                        <div className="grid grid-cols-3 gap-[0.5rem]" style={{ columnGap: "0.5rem" }}>
                            {Object.entries(details).map(([slug, detail]) => (
                                <Fragment key={slug}>
                                    <p className="text-[11.11px] text-glaut-text-midnight text-start col-span-2">
                                        {detail.label}
                                    </p>
                                    <p className="text-[11.11px] text-glaut-text-midnight text-end">
                                        {detail.value}
                                    </p>
                                </Fragment>
                            ))}
                        </div>
                    </GlautTooltip>
                </>
            )}
        </div>
    )
}