import { useEffect, useRef, useState } from "react"
import { MdEdit } from "react-icons/md"

interface IEditableTitleProps {
    disabled?: boolean
    value?: string
    onChange?: (title: string) => void
    onInputFocus?: () => void
    onInputBlur?: () => void
    placeholder?: string
    extend?: boolean
    fontSize?: "16px" | "13.33px"
}

export default function EditableTitle({
    disabled = false,
    onChange,
    value,
    placeholder,
    onInputFocus,
    onInputBlur,
    extend = false,
    fontSize = "16px"
}: Readonly<IEditableTitleProps>) {
    // #region States
    const [isEditing, setIsEditing] = useState(false)
    // #endregion

    // #region Refs
    const inputRef = useRef<HTMLInputElement>(null)
    // #endregion

    // #region Effects

    // onEditingFocusOnInput
    useEffect(() => {
        if (!isEditing) return
        inputRef.current?.focus()
    }, [isEditing])

    // onPressingEnterBlurInput
    useEffect(() => {
        if (!isEditing) return

        const input = inputRef.current
        if (input === null) return

        function handleBlur(e: KeyboardEvent) {
            if (e.key === "Enter" || e.key === "Escape") input!.blur()
        }

        input.addEventListener("keydown", handleBlur)

        return () => { input.removeEventListener("keydown", handleBlur) }
    }, [isEditing])

    // #endregion

    if (!isEditing) return (
        <button
            className={`flex gap-[0.5rem] items-center group transition-none py-[0.25rem] px-0 w-full justify-start
                border-none shadow-none bg-transparent text-base text-start
                text-glaut-text-midnight
                ${!disabled ? "hover:text-glaut-pink" : ""}
                disabled:cursor-default
                ${extend ? "justify-between" : ""}
            `}
            onClick={() => { setIsEditing(true) }}
            disabled={disabled}
        >
            <p className="font-medium min-w-0 whitespace-nowrap overflow-hidden text-ellipsis" style={{ fontSize }}>
                {value || placeholder}
            </p>
            <MdEdit className={`invisible ${!disabled ? "group-hover:visible" : ""}`} />
        </button>
    )

    return (
        <input
            ref={inputRef}
            style={{ fontSize }}
            className="py-[0.25rem] px-[0.5rem] border-1 rounded-[0.5rem] w-full
                m-0 focus:shadow-none
                bg-glaut-very-light-grey border-glaut-light-grey text-glaut-dark-grey
                focus:border-glaut-light-grey focus:text-glaut-dark-grey"
            placeholder={placeholder}
            disabled={disabled}
            defaultValue={value}
            onFocus={onInputFocus}
            onBlur={e => {
                onChange?.(e.target.value)
                setIsEditing(false)
                onInputBlur?.()
            }}
        />
    )
}