import { IQuestionType, ILang } from "@/@types/interview-question"
import { IFilter } from "@/@types/question"

type IOperator = { id: IFilter["operator"]; label: ILang }

const containsOperator: IOperator = { id: "contains", label: { en: "contains" } } as const
const isOperator: IOperator = { id: "is", label: { en: "is" } } as const
const lteOperator: IOperator = { id: "lte", label: { en: "<=" } } as const
const gteOperator: IOperator = { id: "gte", label: { en: ">=" } } as const
const doesNotContainOperator: IOperator = { id: "excludes", label: { en: "does not contain" } } as const

export const allowedOperators: IOperator[] = [
    containsOperator,
    isOperator,
    lteOperator,
    gteOperator,
    doesNotContainOperator
] as const

export const allowedOperatorsForType: { [key in IQuestionType]: IOperator[] } = {
    select: [containsOperator, doesNotContainOperator],
    nps: [isOperator, lteOperator, gteOperator],
    scale: [isOperator, lteOperator, gteOperator],
    open: [],
    asset: []
}

