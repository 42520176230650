import { useGlautAuthInfo } from "@hooks/useGlautAuthInfo"
import TopBar from "../../components/layouts/TopBar"
import Settings from "./Settings"
import Projects from "./Projects"

const tabs = ["Projects", "Workspace members"]
const guestTabs = ["Projects"]

interface IWorkspaceContentProps {
    currentTab: number
    setCurrentTab: (index: number) => void
}

export default function WorkspaceContent({ currentTab, setCurrentTab }: Readonly<IWorkspaceContentProps>) {
    const { currentWsUsers } = useGlautAuthInfo()

    return (
        <div className="flex flex-col w-full h-screen">
            <TopBar
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
                tabs={currentWsUsers !== undefined ? tabs : guestTabs}
            />

            <div className="flex justify-center h-full overflow-auto">
                <div className="flex flex-col w-3/4">
                    {currentTab === 0 && <Projects />}
                    {currentTab === 1 && <Settings />}
                </div>
            </div>
        </div>
    )
}
