import { DetailedHTMLProps, SVGAttributes } from "react"

interface IAiSprinklesProps
    extends DetailedHTMLProps<SVGAttributes<SVGSVGElement>, SVGSVGElement> {
}

export default function AiSprinkles({ fill, ...props }: Readonly<IAiSprinklesProps>) {
    return (
        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M6.62534 3.61702C6.70534 3.61702 6.74562 3.57016 6.76591 3.49645C6.97334 2.37816 6.96019 2.3513
                8.12534 2.13045C8.20534 2.11702 8.25248 2.07045 8.25248 1.98987C8.25248 1.90959 8.20562 1.86273 8.12505
                1.8493C6.96677 1.61502 7.00019 1.58816 6.76591 0.483018C6.74591 0.409589 6.70562 0.362732 6.62534
                0.362732C6.54505 0.362732 6.50477 0.409589 6.48448 0.483018C6.25019 1.58816 6.29019 1.61473 5.12534
                1.8493C5.05162 1.86245 4.99791 1.9093 4.99791 1.98987C4.99791 2.07016 5.05162 2.11702 5.12505
                2.13045C6.29048 2.36473 6.27705 2.37816 6.48448 3.49645C6.50448 3.57016 6.54505 3.61702 6.62534
                3.61702ZM3.38419 8.22416C3.51134 8.22416 3.59848 8.14388 3.61191 8.0233C3.85277 6.2353 3.91305 6.2353
                5.76134 5.88045C5.88191 5.86045 5.96905 5.77987 5.96905 5.65273C5.96905 5.53216 5.88191 5.4453 5.76134
                5.42502C3.91305 5.17073 3.84619 5.11045 3.61191 3.28902C3.59848 3.16845 3.51134 3.0813 3.38419
                3.0813C3.26362 3.0813 3.17648 3.16845 3.16305 3.29559C2.94219 5.09016 2.84848 5.08359 1.01362
                5.42502C0.893051 5.45188 0.805908 5.53216 0.805908 5.65273C0.805908 5.78673 0.893051 5.86045 1.04019
                5.88045C2.86191 6.17502 2.94219 6.22187 3.16305 8.00987C3.17648 8.14388 3.26362 8.22416 3.38419
                8.22416ZM7.92419 15.6373C8.09848 15.6373 8.22562 15.5099 8.25905 15.329C8.73448 11.6596 9.25019 11.1036
                12.8796 10.7019C13.0671 10.6819 13.1945 10.5413 13.1945 10.367C13.1945 10.1927 13.0671 10.059 12.8796
                10.0322C9.25019 9.63045 8.73448 9.07473 8.25905 5.40502C8.22562 5.22416 8.09848 5.10359 7.92419
                5.10359C7.74991 5.10359 7.62305 5.22416 7.59619 5.40502C7.12077 9.07473 6.59848 9.63045 2.97562
                10.0322C2.78134 10.059 2.65419 10.193 2.65419 10.367C2.65419 10.5413 2.78134 10.6819 2.97562
                10.7019C6.59162 11.1773 7.09391 11.6662 7.59619 15.329C7.62305 15.5099 7.75019 15.6373 7.92419 15.6373Z"
                fill="url(#paint0_linear_20213_2169)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_20213_2169"
                    x1="10.0002"
                    y1="6.00043"
                    x2="-2.49976"
                    y2="18.0004"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor={fill ?? "#FF6392"} />
                    <stop offset="1" stopColor={fill ?? "#5465FE"} />
                </linearGradient>
            </defs>
        </svg>

    )
}
