import { IInterview } from "@/@types/entry"
import { useInterviewService } from "@hooks/services/useInterviewService"
import { useEffect, useState } from "react"
import { useLegacyProject } from "../../contexts/ProjectProvider"
import DataTabCsvContentImportData from "./components/DataTabCsvContentImportData"
import DataTabCsvContentPreview from "./components/DataTabCsvContentPreview"
import DataTabCsvContentProvider from "./contexts/DataTabCsvContentProvider"
import Loading from "@components/loading/Loading"

export default function DataTabCsvContent() {
    // #region Params
    const { project } = useLegacyProject()
    // #endregion
    // #region Services

    const interviewService = useInterviewService()
    // #endregion

    // #region States
    const [interviews, setInterviews] = useState<IInterview[]>()
    // #endregion

    // #region Effects

    // onLoadFetchEntries
    useEffect(() => {
        if (!project) return

        interviewService.getInterviews({ projectId: project._id })
            .then(response => { setInterviews(response.interviews) })
            .catch(e => console.log(e))
    }, [interviewService, project])

    // #endregion

    if (!interviews) return <Loading fullPage />

    return (
        <DataTabCsvContentProvider interviews={interviews}>
            <div className="flex flex-row flex-1 pt-[2rem] px-[4rem] overflow-hidden">
                <DataTabCsvContentImportData />
                <DataTabCsvContentPreview />
            </div>
        </DataTabCsvContentProvider>
    )
}