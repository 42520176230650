import { copy, getCopy } from "@utils/Copy"
import OutlineTabSidebarShareInterviewCopyButton from "../OutlineTabSidebarShareInterviewCopyButton"

export default function OutlineTabSidebarShareInterview() {
    return (
        <div className="flex flex-col gap-[0.75rem]">
            <p className="text-[13.33px] text-glaut-bar">
                {getCopy(copy.outline.sidebar.shareInterview)?.toUpperCase()}
            </p>
            <OutlineTabSidebarShareInterviewCopyButton />
        </div>
    )
}