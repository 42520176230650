import useClickOutside from "@hooks/useClickOutside"
import { CSSProperties, forwardRef, MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from "react"

interface IBaseFloatingLayoutProps {
    id?: string
    children: React.ReactNode
    className?: string
    style?: CSSProperties
    anchorRef?: MutableRefObject<HTMLElement | null>
    onClose?: (event: MouseEvent) => void
    horizontalOffset?: number
    verticalOffset?: number
    /**
     * Defines how far the mouse must go out of the container to make it call `onClose`.
     */
    boundingBoxOffset?: number
    stickyToRef?: boolean
    scrollableContainerRef?: MutableRefObject<HTMLElement | null>
    place?: "bottom" | "right" | "left"
}

interface IFloatingLayoutProps extends IBaseFloatingLayoutProps {
    stickyToRef?: false
}

interface IFloatingLayoutStickyProps extends IBaseFloatingLayoutProps {
    stickyToRef: true
    scrollableContainerRef: MutableRefObject<HTMLElement | null>
}

const FloatingLayout = forwardRef<
    HTMLDivElement,
    Readonly<IFloatingLayoutProps | IFloatingLayoutStickyProps>
>(({
    id,
    className,
    style,
    anchorRef,
    onClose,
    verticalOffset,
    horizontalOffset,
    stickyToRef,
    scrollableContainerRef,
    place = "right",
    children
}, ref) => {
    // #region States
    const [widthAfterRender, setWidthAfterRender] = useState(0)
    // #endregion

    // #region Refs
    const innerRef = useRef<HTMLDivElement>()
    // #endregion

    // #region Click outside hook
    useClickOutside(innerRef, onClose)
    // #endregion

    // #region States
    const [forceRerenderState, setForceRerenderState] = useState(false)
    // #endregion

    // #region Dimension values
    const ownHeight = innerRef.current?.getBoundingClientRect().height ?? 0
    const ownTopPosition = innerRef.current?.getBoundingClientRect().top ?? 0
    const windowBottomPosition = window.scrollY + window.innerHeight
    // #endregion

    // #region Memos
    const isLowerPositionedThanOwnHeight = useMemo(
        () => (windowBottomPosition - ownTopPosition) < ownHeight,
        [ownTopPosition, windowBottomPosition, ownHeight]
    )
    const actualHorizontalOffset = useMemo(() => {
        if (horizontalOffset !== undefined) return horizontalOffset

        switch (place) {
            case "right": return 10
            case "left": return -(widthAfterRender + 10)
            default: return 0
        }
    }, [horizontalOffset, place, widthAfterRender])
    const actualVerticalOffset = useMemo(() => {
        if (verticalOffset !== undefined) return verticalOffset

        switch (place) {
            case "bottom": return 10
            default: return 0
        }
    }, [verticalOffset, place])
    const absoluteCoordinates = useMemo(() => {
        switch (place) {
            case "right": {
                const x = (anchorRef?.current?.getBoundingClientRect()?.right ?? 0)
                    + window.scrollX + actualHorizontalOffset

                let y = (anchorRef?.current?.getBoundingClientRect()?.top ?? 0)
                    + window.scrollY + actualVerticalOffset

                if (isLowerPositionedThanOwnHeight)
                    // open from the bottom
                    y = (anchorRef?.current?.getBoundingClientRect()?.bottom ?? 0) - ownHeight

                return { x, y }
            }
            case "bottom": {
                return {
                    x: (anchorRef?.current?.getBoundingClientRect()?.left ?? 0)
                        + window.scrollX + actualHorizontalOffset,
                    y: (anchorRef?.current?.getBoundingClientRect()?.bottom ?? 0)
                        + window.scrollY + actualVerticalOffset
                }
            }
            case "left": {
                const x = (anchorRef?.current?.getBoundingClientRect()?.left ?? 0)
                    + window.scrollX + actualHorizontalOffset

                let y = (anchorRef?.current?.getBoundingClientRect()?.top ?? 0)
                    + window.scrollY + actualVerticalOffset

                if (isLowerPositionedThanOwnHeight)
                    // open from the bottom
                    y = (anchorRef?.current?.getBoundingClientRect()?.bottom ?? 0) - ownHeight

                return { x, y }
            }
            default: {
                return { x: 0, y: 0 }
            }
        }
    }, [
        place,
        anchorRef,
        actualVerticalOffset,
        actualHorizontalOffset,
        isLowerPositionedThanOwnHeight,
        ownHeight,
        forceRerenderState // forceRerenderState necessary for sticky
    ])
    // #endregion

    // #region Callbacks
    const handleUpdateAbsoluteCoordinatesFromRef = useCallback(() => {
        setForceRerenderState(!forceRerenderState)
    }, [forceRerenderState])
    // #endregion

    // #region Effects
    useEffect(() => {
        if (!anchorRef?.current) return

        const scrollableContainerRefCurrent = scrollableContainerRef?.current

        if (stickyToRef)
            scrollableContainerRefCurrent?.addEventListener("scroll", handleUpdateAbsoluteCoordinatesFromRef)

        return () => {
            if (stickyToRef)
                scrollableContainerRefCurrent?.removeEventListener("scroll", handleUpdateAbsoluteCoordinatesFromRef)
        }
    }, [
        anchorRef,
        stickyToRef,
        scrollableContainerRef,
        handleUpdateAbsoluteCoordinatesFromRef
    ])
    // #endregion

    return (
        <div
            id={id}
            ref={element => {
                if (element) {
                    innerRef.current = element
                    setWidthAfterRender(element.scrollWidth)
                }

                if (!ref) return

                if (typeof ref === "function") ref(element)
                else ref.current = element
            }}
            className={className}
            style={{ ...style, top: `${absoluteCoordinates.y}px`, left: `${absoluteCoordinates.x}px` }}
            onMouseLeave={event => onClose?.(event.nativeEvent)}>
            {children}
        </div>
    )
})

FloatingLayout.displayName = "FloatingLayout"

export default FloatingLayout