export const project = {
    project: {
        inviteNewMemberTitle: {
            en: "Invite a guest to the project"
        },
        inviteNewMemberDescription: {
            en: "Project guests only have access to this specific project, with different roles. They are not invited as members of the workspace or organization."
        },
        members: {
            en: "Project guests"
        },
        noMembersYet: {
            en: "You have no user yet"
        },
        invite: {
            en: "Invite"
        },
        inviteSent: {
            en: inviteAddress => "Invitation sent to " + inviteAddress
        },
        inviteUserError: {
            en: "There was an error inviting the user"
        },
        changeUserRoleError: {
            en: "There was an error changing the user role"
        },
        removeUserError: {
            en: "There was an error changing the user role"
        },
        removeMember: {
            en: "This guest will be removed from the current project"
        },
        cannotInvite: {
            en: "You cannot invite members"
        },
        deleteThis: {
            en: "Delete this project"
        },
        deleteDescription: {
            en: "There is no going back. Please be certain."
        },
        deleteButton: {
            en: "Delete"
        },
    },
} as const
