export const sanitizeURL = url => {
    try {
        const urlObj = new URL(url)
        return urlObj.toString()
    } catch (error) {
        return "https://" + url
    }
}

export function isUrlValid(url: string) {
    try {
        const urlObj = new URL(url)
        return !!urlObj
    } catch (_) {
        return false
    }
}