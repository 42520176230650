import { IScreeningQuestion } from "@/@types/interview-question"
import { Drag } from "@components/menus/DragAndDrop"
import ConfirmModal from "@components/Modals/ConfirmModal"
import { useProjectService } from "@hooks/services/useProjectService"
import { copy, getCopy } from "@utils/Copy"
import { useCallback, useState } from "react"
import { MdClose } from "react-icons/md"
import { useOutlineTab } from "../../contexts/OutlineTabProvider"
import { useLegacyProject, useProjectDispatch } from "../../contexts/ProjectProvider"
import { useOutlineTabScreeningAndRedirectsDispatch } from "../../contexts/OutlineTabScreeningAndRedirectsProvider"

interface IOutlineTabContentScreeningAndRedirectsScreeningQuestionsListItemProps {
    screeningQuestion: IScreeningQuestion
    /**
     * 0-indexed position of the IQ (for ordering purposes).
     */
    index: number
}

export default function OutlineTabContentScreeningAndRedirectsScreeningQuestionsListItem({
    screeningQuestion,
    index
}: Readonly<IOutlineTabContentScreeningAndRedirectsScreeningQuestionsListItemProps>) {
    // #region Contexts
    const { project, lang } = useLegacyProject()
    const projectDispatch = useProjectDispatch()
    const { canEdit } = useOutlineTab()
    const outlineTabScreeningAndRedirectsDispatch = useOutlineTabScreeningAndRedirectsDispatch()
    // #endregion

    // #region Services
    const projectService = useProjectService()
    // #endregion

    // #region States
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
    // #endregion

    // #region Callbacks
    const handleSelectIqForEdition = useCallback(() => {
        if (!screeningQuestion.id) return

        outlineTabScreeningAndRedirectsDispatch({
            type: "set-screening-question-to-build",
            screeningQuestionToBuild: screeningQuestion
        })
    }, [screeningQuestion, outlineTabScreeningAndRedirectsDispatch])

    const handleRemoveSq = useCallback(() => {
        if (!project || !screeningQuestion.id) return

        const projectId = project._id
        const questionId = screeningQuestion.id

        projectService.deleteInterviewQuestion({ projectId, questionId }).then(() => {
            projectDispatch({ type: "delete-interview-question", interviewQuestionId: questionId })
            setIsDeleteModalOpen(false)
        })
    }, [screeningQuestion.id, project, projectDispatch, projectService])
    // #endregion

    if (!screeningQuestion.type) return <></>

    return (
        <>
            <Drag
                id={screeningQuestion.id}
                index={index}
                key={screeningQuestion.id}
                disabled={!canEdit || !screeningQuestion.id}
            >
                <button className={`flex justify-start items-center gap-[0.75rem] px-[0.5rem] py-[0.75rem]
                    rounded-[0.25rem] border-1
                    shadow-none m-0 w-full
                    bg-glaut-off-white
                    border-glaut-light-grey
                `} onClick={handleSelectIqForEdition}>
                    <p className="text-[11.11px] font-medium text-glaut-grey">
                        Q{index + 1}
                    </p>
                    <p className="text-[13.33px] font-medium text-glaut-text-midnight">
                        {screeningQuestion.content?.[lang] || screeningQuestion.header}
                    </p>
                </button>
                <button
                    className="border-none shadow-none bg-transparent text-base p-0 m-0 opacity-0 
                        group-hover/drag:enabled:opacity-100
                        disabled:invisible"
                    onClick={() => { setIsDeleteModalOpen(true) }}
                    disabled={!canEdit}>
                    <MdClose className="h-[1rem] w-[1rem] text-glaut-dark-grey" />
                </button>
            </Drag>
            <ConfirmModal
                open={isDeleteModalOpen}
                onClose={() => { setIsDeleteModalOpen(false) }}
                onConfirm={handleRemoveSq}
            >
                <p className="text-[13.33px] font-medium text-glaut-text-midnight">
                    {getCopy(copy.outline.interviewQuestions.areYouSureYouWantToDeleteThisQuestion)}
                </p>
            </ConfirmModal>
        </>
    )
}