import { IAnalysisCategory } from "@/@types/analysis"
import GlautButtonSecondary from "@components/Buttons/GlautButtonSecondary"
import { copy, getCopy } from "@utils/Copy"
import { normalizeToColorIndex } from "@utils/styling/colors-from-index"
import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { FaTag } from "react-icons/fa"
import { MdAddCircle, MdClose } from "react-icons/md"
import { v4 as uuid } from "uuid"

interface IGlautCodeBookToggleComponentState {
    codeBookModality: "create" | "use-existing"
    codeBookThemesWhenUsingExisting: IAnalysisCategory[]
}

export interface IGlautCodeBookToggleComponentRef extends IGlautCodeBookToggleComponentState {
    setCodeBookWhenUsingExisting?: (themes: IAnalysisCategory[]) => void
}

type IGlautCodeBookToggleComponentProps = {
    categoryColorIndexes: { [themeId: string]: number }
    allowAddingCodes?: boolean
} & ({
    value: IGlautCodeBookToggleComponentState
    onChange: (state: IGlautCodeBookToggleComponentState) => void
} | {
    value?: null
    onChange?: null
})

const GlautCodeBookToggleComponent = forwardRef<
    IGlautCodeBookToggleComponentRef | undefined,
    Readonly<IGlautCodeBookToggleComponentProps>
>(({
    categoryColorIndexes,
    allowAddingCodes = false,
    value,
    onChange
}, ref) => {
    // #region States
    const [state, setState] = useState<IGlautCodeBookToggleComponentState>({
        codeBookModality: value?.codeBookModality ?? "create",
        codeBookThemesWhenUsingExisting: value?.codeBookThemesWhenUsingExisting ?? []
    })
    // #endregion

    // #region Refs
    const existingCodeBookThemeInputRefs = useRef<{ [id: string]: HTMLInputElement | null }>({})
    // #endregion

    // #region Memos
    const createModalityButtonClassNames = useMemo(() => `
        rounded-none rounded-s-[4px] bg-glaut-cards shadow-none
        ${state.codeBookModality === "create" ? "border-glaut-pink" : ""}
        ${state.codeBookModality === "use-existing" ? "border-transparent hover:border-glaut-grey duration-100" : ""}
    `, [state.codeBookModality])

    const useExistingModalityButtonClassNames = useMemo(() => `
        rounded-none rounded-e-[4px] bg-glaut-cards shadow-none
        ${state.codeBookModality === "use-existing" ? "border-glaut-pink" : ""}
        ${state.codeBookModality === "create" ? "border-transparent hover:border-glaut-grey duration-100" : ""}
    `, [state.codeBookModality])
    // #endregion

    // #region Callbacks
    const updateState = useCallback((newState: React.SetStateAction<IGlautCodeBookToggleComponentState>) => {
        if (!value || !onChange) {
            setState(newState)
            return
        }

        if (typeof newState === "object") {
            onChange(newState)
            return
        }

        onChange(newState(state))
    }, [onChange, state, value])

    const handleAddThemeForExistingCodeBook = useCallback((parentId?: string) => {
        if (!parentId) {
            const emptyTheme = state.codeBookThemesWhenUsingExisting.find(
                t => !t.parent && t.label.length === 0
            )
            if (emptyTheme !== undefined) {
                existingCodeBookThemeInputRefs.current[emptyTheme.id]?.focus()
                return
            }
        } else {
            const emptyCode = state.codeBookThemesWhenUsingExisting.find(
                t => t.parent === parentId && t.label.length === 0
            )
            if (emptyCode !== undefined) {
                existingCodeBookThemeInputRefs.current[emptyCode.id]?.focus()
                return
            }
        }

        updateState(prevState => ({
            ...prevState,
            codeBookThemesWhenUsingExisting: [...prevState.codeBookThemesWhenUsingExisting, {
                id: uuid(),
                label: "",
                description: "",
                parent: parentId ?? null
            }]
        }))
    }, [state.codeBookThemesWhenUsingExisting, updateState])

    const handleEditThemeFromExistingCodeBook = useCallback((theme: string, id: string) => {
        const themes = [...state.codeBookThemesWhenUsingExisting]
        const index = themes.findIndex(t => t.id === id)

        if (index === -1) return
        themes[index].label = theme

        updateState(state => ({ ...state, codeBookThemesWhenUsingExisting: themes }))
    }, [state.codeBookThemesWhenUsingExisting, updateState])

    const handleRemoveThemeFromExistingCodeBook = useCallback((themeId: string) => {
        const themes = [...state.codeBookThemesWhenUsingExisting].filter(t => t.id !== themeId && t.parent !== themeId)

        updateState(state => ({ ...state, codeBookThemesWhenUsingExisting: themes }))
    }, [state.codeBookThemesWhenUsingExisting, updateState])

    const shouldAllowAddingCodesToThemesInExistingCodebook = useCallback(
        (theme: IAnalysisCategory) => allowAddingCodes && theme.label.length > 0,
        [allowAddingCodes]
    )

    const handleSetExistingCodeBook = useCallback((themes: IAnalysisCategory[]) => {
        updateState(state => ({ ...state, codeBookThemesWhenUsingExisting: themes }))
    }, [updateState])

    const handleSetCodeBookModality = useCallback((codeBookModality: "create" | "use-existing") => {
        updateState(state => ({ ...state, codeBookModality }))
    }, [updateState])
    // #endregion

    // #region Effects

    // onRerenderUpdateRef
    useEffect(() => {
        if (!ref) return

        const refValue: IGlautCodeBookToggleComponentRef = {
            ...state,
            setCodeBookWhenUsingExisting: handleSetExistingCodeBook
        }

        if (typeof ref === "object") {
            ref.current = refValue
            return
        }

        ref(refValue)
    })

    // onUpdateValueUpdateInnerState
    useEffect(() => {
        if (!value) return
        setState(value)
    }, [value])

    // #endregion

    return (
        <div className="flex flex-col">
            <div className="flex flex-row justify-center py-[0.75em]">
                <div className="flex flex-row max-w-[80%]">
                    <button
                        onClick={() => { handleSetCodeBookModality("create") }}
                        className={createModalityButtonClassNames}>
                        <p className={`text-center text-[13.33px] font-medium mx-[10%]
                            ${state.codeBookModality === "create" ? "text-glaut-pink" : "text-glaut-dark-grey"}
                        `}>
                            {getCopy(copy.coding.letGlautCreateACodeBook)}
                        </p>
                    </button>
                    <button
                        onClick={() => { handleSetCodeBookModality("use-existing") }}
                        className={useExistingModalityButtonClassNames}>
                        <p className={`text-center text-[13.33px] font-medium mx-[10%]
                            ${state.codeBookModality === "use-existing" ? "text-glaut-pink" : "text-glaut-dark-grey"}
                        `}>
                            {getCopy(copy.coding.useAnExistingCodeBook)}
                        </p>
                    </button>
                </div>
            </div>
            {state.codeBookModality === "use-existing" && (
                <div className="flex flex-col gap-[0.25em] py-[0.25em] items-center">
                    <div className="flex justify-between w-full">
                        <p className="text-[13.33px] text-glaut-stroke-button self-start">
                            {getCopy(copy.coding.existingCodeBook)}
                        </p>
                        {state.codeBookThemesWhenUsingExisting.length > 0 && (
                            <p className={`text-[13.33px] py-1 px-2 rounded-[0.25em] cursor-pointer
                                text-glaut-light-grey hover:bg-glaut-very-light-grey
                            `} onClick={() => { handleSetExistingCodeBook([]) }}>
                                {getCopy(copy.coding.reset)}
                            </p>
                        )}
                    </div>
                    {state.codeBookThemesWhenUsingExisting.filter(
                        theme => !theme.parent
                    ).map((theme, idx) => (
                        <div key={theme.id} className="flex flex-col w-full">
                            <div className={`flex flex-row justify-between p-[0.5em] items-center w-full
                                gap-[0.5em] border-t-1 border-t-glaut-stroke-glaut
                            `}>
                                <div className="flex flex-row gap-[0.5em] items-center w-full">
                                    <FaTag
                                        className="h-[1em] w-[1em] text-glaut-light-grey"
                                        style={{
                                            color: `var(--color${normalizeToColorIndex(
                                                categoryColorIndexes[theme.id] ?? idx
                                            )})`
                                        }}
                                    />
                                    <span className="absolute opacity-0 whitespace-pre -z-50 box-content">
                                        {theme.label}
                                    </span>
                                    <input
                                        className={`text-[13.33px] font-medium border-0 rounded-none p-0
                                            min-w-1 max-w-full box-content
                                            text-glaut-text-midnight
                                            placeholder:text-glaut-light-grey
                                            focus:shadow-none
                                        `}
                                        placeholder={getCopy(copy.coding.writeANewThemeHere) ?? ""}
                                        value={theme.label}
                                        onChange={ev => {
                                            handleEditThemeFromExistingCodeBook(ev.target.value, theme.id)
                                        }}
                                        ref={element => {
                                            existingCodeBookThemeInputRefs.current[theme.id] = element
                                        }}
                                    />
                                </div>
                                {shouldAllowAddingCodesToThemesInExistingCodebook(theme) && (
                                    <button
                                        className="border-none rounded-none shadow-none p-0 text-[1em]"
                                        onClick={() => { handleAddThemeForExistingCodeBook(theme.id) }}
                                    >
                                        <MdAddCircle className="w-[1em] h-[1em] text-glaut-light-grey" />
                                    </button>
                                )}
                                <button
                                    className="border-none rounded-none shadow-none p-0 text-sm"
                                    onClick={() => { handleRemoveThemeFromExistingCodeBook(theme.id) }}
                                >
                                    <MdClose className="h-[1em] w-[1em] text-glaut-light-grey" />
                                </button>
                            </div>
                            {state.codeBookThemesWhenUsingExisting.filter(c => c.parent === theme.id).map(c => (
                                <div key={c.id} className="flex justify-between p-[0.5em] ml-[1.375em]">
                                    <input
                                        className={`text-[13.33px] border-0 rounded-none p-0 max-w-full
                                            text-glaut-text-midnight
                                            placeholder:text-glaut-light-grey
                                            focus:shadow-none
                                        `}
                                        placeholder={getCopy(copy.coding.writeANewCodeHere) ?? ""}
                                        value={c.label}
                                        onChange={ev => { handleEditThemeFromExistingCodeBook(ev.target.value, c.id) }}
                                        ref={element => { existingCodeBookThemeInputRefs.current[c.id] = element }}
                                    />
                                    <button
                                        className="border-none rounded-none shadow-none p-0 text-sm"
                                        onClick={() => { handleRemoveThemeFromExistingCodeBook(c.id) }}
                                    >
                                        <MdClose className="h-[1em] w-[1em] text-glaut-light-grey" />
                                    </button>
                                </div>
                            ))}
                        </div>
                    ))}
                    <div className="my-[0.5em]">
                        <GlautButtonSecondary onClick={() => { handleAddThemeForExistingCodeBook() }}>
                            <div className="flex flex-row gap-[0.3125em] items-center">
                                <MdAddCircle className="h-[1.25em] w-[1.25em] text-glaut-pink" />
                                <p className="text-[13.33px] font-medium text-glaut-pink">
                                    {getCopy(copy.coding.addTheme)}
                                </p>
                            </div>
                        </GlautButtonSecondary>
                    </div>
                </div>
            )}
        </div>
    )
})

GlautCodeBookToggleComponent.displayName = "GlautCodeBookToggleComponent"

export default GlautCodeBookToggleComponent