import { getCopy, copy } from "@utils/Copy"
import { questionTypes } from "@utils/Variables"

const settingsCopy = copy.coding.settings

export const normalizeCSVData = (data: string[][], hasHeaders: boolean) => {
    const rows = data.splice(hasHeaders ? 1 : 0)
    // Find max column length
    const columnCount = data.reduce(
        (prev, current) => (prev.length > current.length) ? prev : current, []
    ).length

    // Get headers
    const headers = Array.from(
        { length: columnCount },
        (_, i) => (data[0][i] || getCopy(settingsCopy.csvSettings.defaultColumnHeader)?.(i + 1)) ?? ""
    )

    const identifyType = (index: number) => {
        // If the header name is "id" or similar return identifier
        if (["id", "token"].includes(headers[index]?.toLowerCase() ?? ""))
            return questionTypes.identifier

        // // Gets a representative sub set of the column
        // const subsetSize = Math.min(50, rows.length - 1)
        // const column = rows
        //     .map(row => row[index])
        //     .filter(v => v)
        //     .slice(0, subsetSize)

        // // We cannot draw any conclusions
        // if (column.length === 0) return questionTypes.shortText

        // // Checks if the values are all numbers
        // if (column.every(value => /^[\d.]+$/.test(String(value))))
        //     return questionTypes.number

        // // If less than 80% of values are not unique, we are dealing with a multiple choice
        // const values = new Set(column)
        // if (values.size < column.length * 0.8) 
        //     return questionTypes.multipleChoice

        return questionTypes.shortText
    }

    // Identify column types
    const types = Array.from({ length: columnCount }, (_, i) => identifyType(i))
    const hasId = types.find(t => t === questionTypes.identifier)

    const realColumnCount = hasId ? columnCount : columnCount + 1
    const emptyRow = Array.from({ length: columnCount }, () => null)

    return {
        types: ((hasId ? [] : [questionTypes.identifier]) as string[]).concat(types),
        headers: (hasId ? [] : ["ID"]).concat(headers),
        // Be sure that each line has same length
        rows: rows.map(
            (row, i) => ((hasId ? [] : [String(i + 1)]) as (string | null)[])
                .concat(row)
                .concat(emptyRow)
                .splice(0, realColumnCount)
        )
    }
}