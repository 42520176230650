import type { Config } from "tailwindcss"
import tailwindCssAnimate from "tailwindcss-animate"
import borderImagePlugin from "tailwindcss-border-image"
import capsize from "tailwindcss-capsize"

export const namedColorsGradient = {
    "gradient-1": "linear-gradient(to right, #FF6392, #B5BCFD)",
    "gradient-2": "linear-gradient(to right top, #B5BCFD, #FF6392)",
    "icon-gradient-1": "linear-gradient(to right top, #5465FE, #FF6392)"
}

export const tailwindCamelCaseColors = {
    glautGrey: "#BCBCBC",
    glautPink: "#FF6392",
    glautVeryLightGrey: "#F1F1F1",
    glautLightGrey: "#BCBCBC",
    glautDarkGrey: "#4B4458",
    glautCards: "#F7F7F7",
    glautStrokeGlaut: "#DDDDDD",
    glautTextMidnight: "#101720",
    glautMidnight: "#190041",
    glautOffWhite: "#FCFDFD",
    glautCodesGreen: "#00CA82",
    glautCodesRed: "#FB3E49",
    glautCodesYellow: "#E3C700"
}

const config: Config = {
    darkMode: ["class"],
    content: [
        "./src/**/*.{js,ts,jsx,tsx}",
        "./index.html"
    ],
    theme: {
        fontFamily: {
            sans: ["Poppins", "sans-serif"],
            serif: ["Playfair Display", "serif"]
        },
        extend: {
            colors: {
                "glaut-grey": "#BCBCBC",
                "glaut-pink": "#FF6392",
                "glaut-pink-dark": "#CC4F75",
                "glaut-very-light-grey": "#F1F1F1",
                "glaut-light-grey": "#BCBCBC",
                "glaut-light-grey-50%": "#BCBCBC80",
                "glaut-dark-grey": "#565656",
                "glaut-cards": "#F7F7F7",
                "glaut-stroke-button": "#757575",
                "glaut-stroke-glaut": "#DDDDDD",
                "glaut-text-midnight": "#101720",
                "glaut-midnight": "#190041",
                "glaut-off-white": "#FBFBFB",
                "glaut-text-area-off-white": "#FCFDFD",
                "glaut-codes-green": "#00CA82",
                "glaut-codes-red": "#FB3E49",
                "glaut-codes-blue": "#5465FE",
                "glaut-codes-orange": "#FF9800",
                "glaut-codes-yellow": "#E3C700",
                "glaut-codes-pink": "#FF6392",
                "glaut-subcodes-green": "#DCF7E3",
                "glaut-subcodes-orange": "#FFEACA",
                "glaut-subcodes-red": "#FBD7D7",
                "glaut-bar": "#BCBCBC",
                "glaut-dummie-color": "#E3E3E3",
                "glaut-dummie-color2": "#606060",
                "glaut-headers-grey": "#2C2C2C",
                "glaut-gradient-1": namedColorsGradient["gradient-1"],
                "glaut-gradient-2": namedColorsGradient["gradient-2"],
                "glaut-icon-gradient-1": namedColorsGradient["icon-gradient-1"]
            },
            backgroundImage: {
                "glaut-gradient-1": namedColorsGradient["gradient-1"],
                "glaut-gradient-2": namedColorsGradient["gradient-2"],
                "glaut-icon-gradient-1": namedColorsGradient["icon-gradient-1"]
            },
            borderWidth: {
                "1": "1px"
            },
            borderRadius: {
                lg: "var(--radius)",
                md: "calc(var(--radius) - 2px)",
                sm: "calc(var(--radius) - 4px)"
            }
        }
    },
    plugins: [
        capsize({ className: "leading-trim" }),
        tailwindCssAnimate,
        borderImagePlugin
    ]
}
export default config
