export const assetUploadCopy = {
    uploadYourImageOrVideo: {
        en: "Upload your image or video by clicking or dragging it here"
    },
    dropToUpload: {
        en: "Drop to upload"
    },
    assetNotUploaded: {
        en: "Asset not uploaded"
    },
    assetNotUploadedDetail: {
        en: "It was not possible to upload the asset. Please try again."
    },
    fileNotAccepted: {
        en: "File not accepted"
    }
}
