import { useState, useMemo, useCallback } from "react"
import { copy, getCopy } from "@utils/Copy"
import ErrorLevel from "@utils/ErrorLevel"

import { useGlautAuthInfo } from "@hooks/useGlautAuthInfo"
import { useToast } from "@contexts/ToastProvider"

import Members from "@components/Auth/Members"
import DeletePopup from "@components/layouts/DeletePopup"
import GlautButtonSecondary from "@components/Buttons/GlautButtonSecondary"
import { useWorkspaceService } from "@hooks/services/useWorkspaceService"
import { useNavigate } from "react-router-dom"

const roles = [
    {
        slug: "owner",
        title: "Owner",
        description: "Can edit projects and add or remove members of the workspace"
    },
    {
        slug: "editor",
        title: "Editor",
        description: "Can edit projects but cannot add or remove members of the workspace"
    },
    {
        slug: "viewer",
        title: "Viewer",
        description: "Can only view the projects in the workspace"
    }
]

export default function Settings() {
    const {
        currentWs,
        currentOrgUsers,
        currentWsUsers,
        inviteWsUser,
        changeWsUserRole,
        removeWsUser,
        currentWsIsAtLeastRole
    } = useGlautAuthInfo()
    // #region Hooks
    const navigate = useNavigate()
    const { addToast } = useToast()
    // #endregion

    // #region State
    const [openDelete, setOpenDelete] = useState(false)
    const [loading, setLoading] = useState(false)
    // #endregion

    // #region Memo
    const canEdit = useMemo(
        () => currentWsIsAtLeastRole("owner"),
        [currentWsIsAtLeastRole]
    )
    // #endregion

    // #region Services
    const workspaceService = useWorkspaceService()
    // #endregion

    // #region Callbacks
    const inviteUser = useCallback((email, role) => {
        setLoading(true)
        return inviteWsUser(email, role).then(() => {
            addToast(getCopy(copy.workspace.inviteSent)?.(email) ?? "")
            setLoading(false)
        }).catch(e => {
            console.log(e)
            setLoading(false)
            addToast(getCopy(copy.workspace.inviteUserError) ?? "", "", ErrorLevel.Error)
        })
    }, [addToast, inviteWsUser])

    const changeUserRole = useCallback((email, role) => {
        setLoading(true)
        return changeWsUserRole(email, role).then(() => {
            setLoading(false)
        }).catch(e => {
            console.log(e)
            setLoading(false)
            addToast(getCopy(copy.workspace.changeUserRoleError) ?? "")
        })
    }, [addToast, changeWsUserRole])

    const removeUser = useCallback(propeauthUserId => {
        setLoading(true)
        return removeWsUser(propeauthUserId).then(() => {
            setLoading(false)
        }).catch(e => {
            console.log(e)
            setLoading(false)
            addToast(getCopy(copy.org.removeUserError) ?? "")
        })
    }, [addToast, removeWsUser])
    // #endregion

    return (
        <div id="ws-settings" className="flex flex-col mt-24 gap-8 text-[#565656]">
            <Members
                users={currentWsUsers}
                defaultRole="viewer"
                roles={roles}
                canEdit={canEdit}
                inviteUser={inviteUser}
                changeUserRole={changeUserRole}
                removeUser={removeUser}
                loading={loading}
                copy={copy.workspace}
                suggestedUsers={currentOrgUsers && currentWsUsers && currentOrgUsers.filter(
                    u => u.role === "member" && !currentWsUsers.find(cu => cu.email === u.email)
                )}
            />
            {canEdit && <div
                id="danger-zone"
                className="flex flex-row justify-between items-center p-4 rounded-md bg-[#FBD7D7] mb-20"
            >
                {getCopy(copy.workspace.deleteThis)}
                <GlautButtonSecondary onClick={() => setOpenDelete(true)}>
                    {getCopy(copy.workspace.deleteButton)}
                </GlautButtonSecondary>
            </div>}
            {openDelete && currentWs && (
                <DeletePopup
                    confirmString={currentWs.name}
                    entityName="workspace"
                    close={() => setOpenDelete(false)}
                    onConfirm={() => {
                        workspaceService.deleteWorkspace({ workspaceId: currentWs._id }).then(() => {
                            setOpenDelete(false)
                            navigate(`/o/${currentWs.org_id}`)
                        })
                    }}
                />
            )}
        </div>
    )
}
