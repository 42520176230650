import { copy, getCopy } from "@utils/Copy"

export const screenerDropdownOptionsMap = {
    qualify: {
        label: getCopy(copy.words.qualify) ?? "",
        value: "qualify" as const
    },
    disqualify: {
        label: getCopy(copy.words.disqualify) ?? "",
        value: "disqualify" as const
    }
}

export const screenerDropdownOptionsList = Object.values(screenerDropdownOptionsMap)