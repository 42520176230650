import { IReport } from "@/@types/reports"
import GlautWideLogo from "@assets/glaut-wide-logo.svg"
import GlautButtonPrimary from "@components/Buttons/GlautButtonPrimary"
import GlautButtonSecondary from "@components/Buttons/GlautButtonSecondary"
import Loading from "@components/loading/Loading"
import { pdf } from "@react-pdf/renderer"
import { copy, getCopy } from "@utils/Copy"
import { download } from "@utils/miscellaneous"
import { useCallback, useMemo, useState } from "react"
import {
    MdArrowBackIos,
    MdCheck,
    MdDownload,
    MdOutlineCopyAll,
    MdOutlineMenu,
    MdShare,
    MdSimCardDownload
} from "react-icons/md"
import slugify from "slugify"

interface IReportDetailsHeaderProps {
    report: IReport
    onBack?: () => void
    renderReportPdf: () => JSX.Element
}

export default function ReportDetailsHeader({
    report,
    onBack,
    renderReportPdf
}: Readonly<IReportDetailsHeaderProps>) {
    // #region States
    const [hasCopiedUrl, setHasCopiedUrl] = useState(false)
    const [isDownloadingReport, setIsDownloadingReport] = useState(false)
    const [isOptionsContextMenuOpen, setIsOptionsContextMenuOpen] = useState(false)
    const [headerHeight, setHeaderHeight] = useState(0)
    // #endregion

    // #region Memos
    const reportUrl = useMemo(
        () => report ? `${window.location.origin}/r/${report.id}` : "",
        [report]
    )
    // #endregion

    // #region Callbacks
    const handleClickToGoBack = useCallback(() => {
        onBack?.()
    }, [onBack])

    const handleCopyUrl = useCallback(() => {
        if (!("clipboard" in navigator) || !("writeText" in navigator.clipboard)) return

        navigator.clipboard.writeText(reportUrl).then(() => {
            setHasCopiedUrl(true)

            setTimeout(() => {
                setHasCopiedUrl(false)
            }, 3000)
        })
    }, [reportUrl])

    const handleDownloadReport = useCallback(() => {
        if (!report) return

        setIsDownloadingReport(true)

        const fileName = `${slugify(report.title, { lower: true, trim: true })}.pdf`

        pdf(renderReportPdf()).toBlob().then(blob => {
            download(blob, fileName)
        }).finally(() => {
            setIsDownloadingReport(false)
        })
    }, [renderReportPdf, report])

    const handleShareUrl = useCallback(() => {
        if (!("share" in navigator)) return
        navigator.share({
            title: report.title,
            text: "A report made with Glaut",
            url: reportUrl
        })
    }, [reportUrl, report])
    // #endregion

    // #region Element memos
    const contextMenuOptions = useMemo(() => {
        const iconClassName = "w-[1.25em] h-[1.25em] text-glaut-text-midnight"
        const paragraphClassName = "text-[13.33px] text-glaut-text-midnight"

        return [
            {
                id: "copy-url",
                icon: <MdOutlineCopyAll className={iconClassName} />,
                text: <p className={paragraphClassName}>{getCopy(copy.reports.contextMenuOptions.copyUrl)}</p>,
                onClick: () => {
                    handleCopyUrl()
                    setIsOptionsContextMenuOpen(false)
                }
            },
            {
                id: "share-url",
                icon: <MdShare className={iconClassName} />,
                text: <p className={paragraphClassName}>{getCopy(copy.reports.contextMenuOptions.shareUrl)}</p>,
                onClick: () => {
                    handleShareUrl()
                    setIsOptionsContextMenuOpen(false)
                }
            },
            {
                id: "download-report",
                icon: <MdDownload className={iconClassName} />,
                text: <p className={paragraphClassName}>{getCopy(copy.reports.contextMenuOptions.downloadReport)}</p>,
                onClick: () => {
                    handleDownloadReport()
                    setIsOptionsContextMenuOpen(false)
                }
            }
        ] as const
    }, [handleCopyUrl, handleShareUrl, handleDownloadReport])
    // #endregion

    if (!report) return <></>

    return (
        <div
            className={`flex justify-between items-center py-[0.625em] px-[1.25em] border-b-1 
                border-b-glaut-light-grey bg-glaut-off-white
            `}
            ref={element => {
                if (!element) return
                setHeaderHeight(element.offsetHeight)
            }}
            id="div--report-header"> {/** ID used in Index component */}
            {onBack && (
                <div className="flex gap-[0.75em] justify-start items-center max-w-[50%]">
                    <button
                        className={`flex flex-row gap-[0.3125em] items-center border-none shadow-none px-[0.5em]
                            py-[0.3125em]
                            hover:bg-glaut-very-light-grey
                        `}
                        onClick={handleClickToGoBack}
                    >
                        <MdArrowBackIos
                            className="h-[1.25em] w-[1.25em] text-glaut-stroke-button"
                        />
                        <p className="font-medium text-[13.33px] underline text-glaut-stroke-button">
                            {getCopy(copy.actions.back)}
                        </p>
                    </button>
                    <p className={`text-[11.11px] font-medium text-glaut-grey lg:max-w-96
                        overflow-hidden text-ellipsis whitespace-nowrap
                    `}>
                        {report.title}
                    </p>
                </div>
            )}
            {!onBack && (
                <img src={GlautWideLogo} alt="glaut" className="h-9" />
            )}
            <div className="sm:flex hidden sm:gap-[1.25em] sm:items-center">
                <GlautButtonSecondary onClick={handleCopyUrl}>
                    <div className="flex items-center gap-[0.5em]">
                        {!hasCopiedUrl && (
                            <>
                                <MdOutlineCopyAll className="h-[1.25em] w-[1.25em]" />
                                <p className="text-[13.33px] font-medium whitespace-nowrap">
                                    {getCopy(copy.actions.copyUrl)}
                                </p>
                            </>
                        )}
                        {hasCopiedUrl && (
                            <>
                                <MdCheck className="h-[1.25em] w-[1.25em]" />
                                <p className="text-[13.33px] font-medium">
                                    {getCopy(copy.actions.copiedExclamation)}
                                </p>
                            </>
                        )}
                    </div>
                </GlautButtonSecondary>
                <GlautButtonPrimary onClick={handleDownloadReport} disabled={isDownloadingReport}>
                    {isDownloadingReport && (
                        <Loading />
                    )}
                    {!isDownloadingReport && (
                        <>
                            <MdSimCardDownload className="h-[1.25em] w-[1.25em]" />
                            <p className="text-[13.33px] font-medium whitespace-nowrap">
                                {getCopy(copy.reports.downloadReport)}
                            </p>
                        </>
                    )}
                </GlautButtonPrimary>
            </div>
            <MdOutlineMenu
                className="w-[1.25em] h-[1.25em] text-glaut-dark-grey sm:invisible sm:hidden"
                onClick={() => { setIsOptionsContextMenuOpen(prev => !prev) }}
            />
            <div className={`absolute border-1 rounded-[0.25em] transition-all right-2
                bg-glaut-off-white border-glaut-grey shadow-[0px_0px_8px_0px_rgb(0,0,0,0.15)]
                sm:hidden
                ${isOptionsContextMenuOpen ? "p-[0.75em] visible opacity-100 flex flex-col" : ""}
                ${!isOptionsContextMenuOpen ? "p-0 invisible opacity-0" : ""}
            `} style={{ top: headerHeight - 5 }}>
                {contextMenuOptions.map(opt => (
                    <button
                        key={opt.id}
                        className={`rounded-none shadow-none border-none 
                            justify-start px-0 flex gap-[0.5em] w-full py-[0.5em]
                        `}
                        onClick={opt.onClick}>
                        {opt.icon} {opt.text}
                    </button>
                ))}
            </div>
        </div>
    )
}