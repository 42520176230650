import { copy, getCopy } from "@utils/Copy"
import { ArrayElement } from "@utils/types/array-element"
import { forwardRef } from "react"
import { MdSearch } from "react-icons/md"
import Select, { MultiValue, SelectInstance } from "react-select"

interface IOption {
    label: React.ReactNode
    value: string
}

interface IQuestionValueSelectProps {
    options: IOption[]
    value?: IOption[]
    isDisabled?: boolean
    onSelect?: (value: string[]) => void
}

const QuestionValueSelect = forwardRef<
    SelectInstance<ArrayElement<IQuestionValueSelectProps["options"]>, true>,
    Readonly<IQuestionValueSelectProps>
>(({
    options,
    value,
    isDisabled,
    onSelect
}, ref) => (
    <Select
        ref={ref}
        placeholder={
            <div className="flex gap-[0.3125em] items-center">
                <MdSearch className="text-glaut-grey" />
                <p className="text-glaut-grey text-[13.33px] font-medium">
                    {getCopy(copy.coding.typeHereToSetAValue)}
                </p>
            </div>
        }
        classNames={{
            container: () => "flex-1",
            option: () => "px-[0.5em] border-1 border-transparent hover:border-glaut-grey",
            indicatorSeparator: () => "hidden",
            control: () => "pl-[0.25em] pr-[0.75em] border-0 outline-0"
        }}
        styles={{ // some styles don't work with class names
            option: () => ({
                backgroundColor: "white"
            }),
            placeholder: style => ({
                ...style,
                color: "var(--color-glaut-text-midnight)"
            }),
            dropdownIndicator: style => ({
                ...style,
                display: "none"
            }),
            clearIndicator: style => ({
                ...style,
                display: "none"
            }),
            control: style => ({
                ...style,
                borderColor: "var(--color-glaut-light-grey)",
                backgroundColor: "var(--color-glaut-cards)",
                boxShadow: "none",
                borderRadius: "0.25em",
                minHeight: 0,
                ":hover": {
                    ...style[":hover"],
                    borderColor: "black"
                },
                ":focus": {
                    ...style[":focus"],
                    borderColor: "red"
                }
            })
        }}
        onChange={newValue => {
            if (!Array.isArray(newValue)) return

            const value = newValue as MultiValue<{ label: React.ReactNode, value: string }>
            onSelect?.(value.map(v => v.value))
        }}
        value={value}
        options={options}
        isClearable
        isSearchable={false}
        isDisabled={isDisabled}
        isMulti
        controlShouldRenderValue={false}
    />
))

QuestionValueSelect.displayName = "AnalysisTabAnalysisColFilteringModalQuestionValueSelect"

export default QuestionValueSelect