import { Tooltip } from "react-tooltip"
import "./index.scss"
import { glautTooltipId } from "./constants/id"

interface IGlautTooltipProps {
    id: string
    children?: React.ReactNode
}

export default function GlautTooltip({
    id,
    children
}: Readonly<IGlautTooltipProps>) {
    if (children)
        return (
            <Tooltip id={id ?? glautTooltipId} border="1px solid var(--color-glaut-stroke-glaut)" opacity={1}>
                {children}
            </Tooltip>
        )


    return (
        <Tooltip id={id ?? glautTooltipId} border="1px solid var(--color-glaut-stroke-glaut)" opacity={1} />
    )
}