import { IScreeningQuestion } from "@/@types/interview-question"
import { createContext, useContext, useReducer } from "react"
import { IInterview } from "src/@types/entry"

// #region Type definitions
interface IConversationOverlayProviderProps {
    interview: IInterview
    deletableEntry?: boolean
    children: React.ReactNode
    lang: string
    screeningQuestions?: IScreeningQuestion[]
}

interface IConversationOverlayContextState {
    interview: IInterview
    deletableEntry: boolean
    lang: string
    screeningQuestions: IScreeningQuestion[]
}

type IConversationOverlayContextAction = {
    type: ""
}
// #endregion

// #region Context definitions
const ConversationOverlayContext = createContext(
    {} as IConversationOverlayContextState
)

const ConversationOverlayContextDispatch = createContext(
    {} as React.Dispatch<IConversationOverlayContextAction>
)
// #endregion

// #region Hook definitions
export function useConversationOverlay() {
    return useContext(ConversationOverlayContext)
}

export function useConversationOverlayDispatch() {
    return useContext(ConversationOverlayContextDispatch)
}
// #endregion

// #region Provider definition
export default function ConversationOverlayProvider({
    interview,
    deletableEntry,
    children,
    lang,
    screeningQuestions = []
}: Readonly<IConversationOverlayProviderProps>) {
    const initialState: IConversationOverlayContextState = {
        interview,
        deletableEntry: deletableEntry ?? false,
        lang,
        screeningQuestions
    }

    const [state, dispatch] = useReducer(ConversationOverlayReducer, initialState)

    return (
        <ConversationOverlayContext.Provider value={state}>
            <ConversationOverlayContextDispatch.Provider value={dispatch}>
                {children}
            </ConversationOverlayContextDispatch.Provider>
        </ConversationOverlayContext.Provider>
    )
}
// #endregion

// #region Reducer definition
function ConversationOverlayReducer(
    state: IConversationOverlayContextState,
    action: IConversationOverlayContextAction
): IConversationOverlayContextState {
    switch (action.type) {
        default: {
            return state
        }
    }
}
// #endregion