import { ForwardedRef, useEffect } from "react"

const useClickOutside = (
    ref: ForwardedRef<HTMLDivElement | undefined>,
    callback?: (event: MouseEvent) => void
) => {
    useEffect(() => {
        const handleClickOutside = ev => {
            if (typeof ref === "function") return
            if (ref?.current && !ref.current.contains(ev.target)) callback?.(ev)
        }

        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [ref, callback])
}

export default useClickOutside
