export const interviewTerminatedUncooperativeCopy = {
    message: {
        en: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Thank you< br />
                <span className="text-glaut-pink font-medium">for your time.</span>
            </p>
        ),
        it: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Grazie<br />
                <span className="text-glaut-pink font-medium">per il tuo tempo.</span>
            </p>
        ),
        es: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Gracias<br />
                <span className="text-glaut-pink font-medium">por tu tiempo.</span>
            </p>
        ),
        fr: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Merci<br />
                <span className="text-glaut-pink font-medium">pour votre temps.</span>
            </p>
        ),
        de: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Danke<br />
                <span className="text-glaut-pink font-medium">für Ihre Zeit.</span>
            </p>
        )
    },
    detail: {
        en: "It seems we couldn’t align on this interview. We hope to connect again in the future!",
        it: "Sembra che non abbiamo potuto raggiungere un accordo su questa intervista. Speriamo di rivedervi in futuro!",
        es: "Pare que no pudimos llegar a un acuerdo sobre esta entrevista. ¡Esperamos volver a verte pronto!",
        fr: "Il semble que nous n'avons pas pu trouver un accord sur cette entrevue. Nous espérons vous revoir bientôt!",
        de: "Es scheint, dass wir auf diese Befragung nicht zustimmen konnten. Wir hoffen, bald wieder zu sprechen!"
    }
}