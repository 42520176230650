import { normalizeTag } from "@utils/strings"
import { CSSProperties, DetailedHTMLProps, HTMLAttributes, useEffect, useRef, useState } from "react"
import { Draggable, Droppable } from "react-beautiful-dnd"
import { BiTrash } from "react-icons/bi"
import { IoIosAdd } from "react-icons/io"
import { MdDragIndicator } from "react-icons/md"

interface IDragProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    id: string
    index: number
    cumulativeIdx?: number
    parentScroll?: number
    disabled?: boolean
}

export function Drag({
    id,
    index,
    cumulativeIdx,
    parentScroll,
    ...props
}: Readonly<IDragProps>) {
    // #region States
    const [initialWidth, setInitialWidth] = useState<number>()
    // #endregion

    // #region Refs
    const startingScroll = useRef<number | null>()
    const draggableRef = useRef<HTMLDivElement>(null)
    // #endregion

    // #region Effects
    useEffect(() => {
        if (!draggableRef.current) return
        setInitialWidth(draggableRef.current.offsetWidth)
    }, [])
    // #endregion

    return (
        <Draggable draggableId={id} index={index} isDragDisabled={props.disabled}>
            {provided => {
                let style: CSSProperties = {
                    ...(provided.draggableProps.style ?? {}),
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    width: "100%",
                    cursor: "grab !important"
                }

                // Sets the starting parent scroll when starting to drag the object
                if (style.top && !startingScroll.current)
                    startingScroll.current = parentScroll
                else if (!style.top && startingScroll.current)
                    startingScroll.current = null

                // I needed to accurately set the position in case we are moving this draggable
                if (style.left && parentScroll !== undefined)
                    style = {
                        ...style,
                        left: 0,
                        top: (cumulativeIdx || index) * 50 + parentScroll - (startingScroll.current ?? 0),
                        position: "absolute"
                    }


                return (
                    <div ref={provided.innerRef} {...provided.draggableProps} style={style} {...props}>
                        <div
                            className="flex gap-[0.5rem] items-center group/drag"
                            style={{ maxWidth: `${initialWidth}px` }} // avoid expansion when dragging
                            ref={draggableRef}
                        >
                            <div {...provided.dragHandleProps}>
                                <MdDragIndicator className={`w-[1rem] h-[1rem] text-transparent 
                                    ${!props.disabled ? "group-hover/drag:text-glaut-light-grey" : ""}
                                `} />
                            </div>
                            {props.children}
                        </div>
                    </div>
                )
            }}
        </Draggable>
    )
}

interface IDropProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    id: string
    type?: string
    disabled?: boolean
    gap?: number | string
}

export function Drop({
    id,
    type,
    ...props
}: Readonly<IDropProps>) {
    return (
        <Droppable droppableId={id} type={type} isDropDisabled={props.disabled}>
            {provided => (
                <div {...props} ref={provided.innerRef} {...provided.droppableProps}>
                    {props.children}
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    )
}

/** @deprecated */
const BucketInput = ({ index, bucket, addBucket, editBucket, deleteBucket, disabled = false }) => {
    const [name, setName] = useState(bucket.name)

    const normalizeAndEditBucket = name => {
        name = normalizeTag(name)
        setName(name)
        editBucket(normalizeTag(name), bucket.id)
    }

    return (
        <div key={index} className="option" style={{ width: "100%" }}>
            <span className="index">{index + 1}</span>
            <input
                key={index}
                placeholder="Tag"
                value={name}
                onChange={e => setName(e.target.value)}
                onBlur={e => normalizeAndEditBucket(e.target.value)}
                style={{ backgroundColor: "transparent" }}
                disabled={disabled}
            />
            {addBucket && (
                <IoIosAdd
                    className={["clickable", disabled ? "disabled" : ""].join(" ")}
                    onClick={e => !disabled && addBucket(e, bucket.id)}
                />
            )}
            <BiTrash
                className={["clickable danger", disabled ? "disabled" : ""].join(" ")}
                onClick={() => !disabled && deleteBucket(bucket.id)}
            />
        </div>
    )
}

/** @deprecated */
export const RecursiveBucketDrag = ({
    bucket,
    editBucket,
    addBucket,
    deleteBucket,
    index,
    parentScroll,
    disabled = false
}) => (
    <Drag
        key={bucket.id}
        id={bucket.id}
        index={index}
        cumulativeIdx={bucket.index}
        parentScroll={parentScroll}
        disabled={disabled}
    >
        <div className="column" style={{ gap: 0, width: "100%" }}>
            <BucketInput
                index={index}
                bucket={bucket}
                editBucket={editBucket}
                deleteBucket={deleteBucket}
                addBucket={addBucket}
                disabled={disabled}
            />
            <Drop id={bucket.id} type="sub-tags" disabled={disabled}>
                {bucket.children?.map((c, idx) => (
                    <Drag
                        key={c.id}
                        id={c.id}
                        index={idx}
                        cumulativeIdx={c.index}
                        parentScroll={parentScroll}
                        disabled={disabled}
                    >
                        <BucketInput
                            index={idx}
                            bucket={c}
                            editBucket={editBucket}
                            deleteBucket={deleteBucket}
                            addBucket={undefined}
                        />
                    </Drag>
                ))}
            </Drop>
        </div>
    </Drag>
)
