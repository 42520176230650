import React, { useEffect } from "react"
import { Route, Routes } from "react-router-dom"

import "./styling/Base.css"
import "./styling/Page.css"
import "./styling/Menus.css"
import "./styling/Animations.css"
import "./styling/Inputs.css"
import "./styling/Campaign.css"
import "./styling/Charts.css"
import "./styling/Layouts.css"
import "./styling/Scrollbar.css"
import "./styling/Sizing.css"
import "./styling/DnD.css"
import "./styling/Buttons.css"
import "./App.scss"

import useProtectedRoute from "./hooks/useProtectedRoute"
import { useAuthInfo, useRedirectFunctions } from "@propelauth/react"
import { updateUserInfo } from "./utils/traking"
import Conversation from "./pages/Conversation"
import Admin from "./pages/Admin"
import Project from "./pages/Project"
import ReportArea from "./pages/Project/ReportArea"
import Organization from "./pages/Organization"
import Workspace from "./pages/Workspace"

function App() {
    const { isOpen, isLoggedIn } = useProtectedRoute()
    const { redirectToLoginPage } = useRedirectFunctions()
    // Must use useAuthInfo instead of useGlautAuthInfo because the Glaut UserContext is not available here
    const { user } = useAuthInfo()

    useEffect(() => {
        if (user) updateUserInfo({ email: user.email, id: user.userId })
    }, [user])

    useEffect(() => {
        if (!isOpen && isLoggedIn === false) redirectToLoginPage({postLoginRedirectUrl: window.location.href})
    }, [isOpen, isLoggedIn, redirectToLoginPage])
    return (
        <Routes>
            <Route path="/" element={<Organization />} />
            <Route path="/o/:orgId" element={<Organization />} />
            <Route path="/w/:wsId" element={<Workspace />} />
            <Route path="/p/:projectId" element={<Project />} />
            <Route path="/r/:reportId" element={<ReportArea />} />
            <Route path="/i/:projectId" element={<Conversation />} />
            <Route path="/admin" element={<Admin />} />
        </Routes>
    )
}

export default App
