import GlautWideLogo from "@assets/glaut-wide-logo.svg"
import { copy, getCopy } from "@utils/Copy"
import React, { MutableRefObject, useRef } from "react"
import { MdMicNone, MdOutlineKeyboard } from "react-icons/md"
import { Tooltip } from "react-tooltip"
import { useConversation } from "../../contexts/ConversationProvider"
import { useBrowserUserAgent } from "@hooks/useBrowserUserAgent"
import { useGlautBranding } from "../../hooks/useGlautBranding"

export const tooltipId = "keyboard-icon-tooltip"

interface IConversationFooterProps {
    lang: string
    isPlaying: boolean | null
    isVoiceInput: boolean
    setIsVoiceInput: React.Dispatch<React.SetStateAction<boolean>>
    showInputToggle: boolean
    customRef?: MutableRefObject<HTMLDivElement>
}

export default function ConversationFooter({
    lang,
    isPlaying,
    isVoiceInput,
    setIsVoiceInput,
    showInputToggle,
    customRef
}: Readonly<IConversationFooterProps>) {
    // #region Branding
    const { hasBranding } = useGlautBranding()
    // #endregion

    // #region Contexts
    const { isRecording } = useConversation()
    // #endregion

    // #region Browser hooks
    const { devices: { isMobile } } = useBrowserUserAgent()
    // #endregion

    // #region Refs
    const footerRef = useRef<HTMLDivElement>(null)
    // #endregion

    return (
        <>
            <div
                className={`fixed bottom-0 left-1/2 -translate-x-1/2 right-0 flex flex-row justify-between items-center
                    w-full max-w-[800px] box-border p-[0.75em] pointer-events-none
                    md:justify-center md:mb-[5vh]
                `}
                ref={customRef}
            >
                {hasBranding && (
                    <div className="bg-glaut-off-white" ref={footerRef}>
                        <a
                            href="https://www.glaut.com"
                            target="_blank"
                            rel="noreferrer"
                            className="pointer-events-auto"
                        >
                            <div className="flex flex-col md:flex-row md:gap-[5px] items-center justify-center">
                                <p className="text-[11.11px] font-medium text-glaut-stroke-button">
                                    Built with
                                </p>
                                <img src={GlautWideLogo} alt="glaut" />
                            </div>
                        </a>
                    </div>
                )}
                {showInputToggle && (
                    <button
                        className={`toggle-input p-[0.5em] rounded-[0.875em] cursor-pointer border-none w-[3em] h-[3em]
                            shadow-[0_0_8px_0_rgba(0,0,0,0.15)] group pointer-events-auto
                            disabled:bg-glaut-cards disabled:border-0
                            md:absolute md:right-0 md:mb-[18vh] md:w-[4em] md:h-[4em]
                            ${!isVoiceInput ? "md:hidden" : ""}
                        `}
                        onClick={() => { setIsVoiceInput(!isVoiceInput) }}
                        disabled={isRecording || isPlaying || isPlaying === null}
                        data-tooltip-id={tooltipId}
                        data-tooltip-content={isVoiceInput
                            ? getCopy(copy.interview.activateKeyboardMode, lang)
                            : getCopy(copy.interview.activateMicrophoneMode, lang)
                        }
                        data-tooltip-place="top"
                        data-tooltip-hidden={isMobile}
                    >
                        {isVoiceInput
                            ? (
                                <MdOutlineKeyboard
                                    className={`h-[1.563em] w-[1.563em] text-glaut-midnight 
                                        group-disabled:text-glaut-grey
                                        group-hover:text-glaut-pink
                                        md:h-[1.688em] md:w-[1.688em]
                                    `}
                                />
                            )
                            : (
                                <MdMicNone
                                    className={`h-[1.125em] w-[1.125em] text-glaut-midnight 
                                        group-disabled:text-glaut-grey
                                        group-hover:text-glaut-pink
                                        md:h-[1.313em] md:w-[1.313em]
                                    `}
                                />
                            )
                        }
                    </button>
                )}

            </div>
            <Tooltip id={tooltipId} />
        </>
    )
}