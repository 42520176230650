import ReportDetails from "@components/Reports/ReportDetails"
import { useReportService } from "@hooks/services/useReportService"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

export default function ReportArea() {
    // #region Params
    const { reportId } = useParams()
    // #endregion

    // #region Services
    const reportService = useReportService()
    // #endregion

    // #region States
    const [currReport, setCurrReport] = useState<Awaited<ReturnType<typeof reportService["getReportDetails"]>>>()
    // #endregion

    // #region Effects
    useEffect(() => {
        if (!reportId) return

        reportService.getReportDetails({ reportId }).then(res => { setCurrReport(res) })
    }, [reportId, reportService])
    // #endregion

    if (!reportId || !currReport) return <></>

    return (
        <div className="h-screen">
            <ReportDetails
                report={currReport.report}
                project={currReport.project}
                metrics={currReport.metrics}
                analyses={currReport.analyses}
                interviewQuestions={currReport.questions}
                numberOfEntries={currReport.metrics.started}
            />
        </div>
    )
}