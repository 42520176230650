import GlautTextAreaMultiLine from "@components/inputs/GlautTextAreaMultiLine"
import { useDebouncedInputChange } from "@hooks/useDebouncedInputChange"
import { copy, getCopy } from "@utils/Copy"
import { ChangeEventHandler, useCallback } from "react"
import { useOutlineTab } from "../../contexts/OutlineTabProvider"
import { useLegacyProject } from "../../contexts/ProjectProvider"
import OutlineTabContentDefaultTitle from "../OutlineTabContentDefaultTitle"

export default function OutlineTabContentOverviewWelcomeMessage() {
    // #region Contexts
    const { project, setProject } = useLegacyProject()
    const { canEdit } = useOutlineTab()
    // #endregion

    // #region Callbacks
    const handleChangeProjectContext: ChangeEventHandler<HTMLTextAreaElement> = useCallback(ev => {
        if (!project) return

        const rawIntroMessage = ev.target.value
        const introMessage = rawIntroMessage.length > 0 ? rawIntroMessage : null

        setProject(prev => {
            if (!prev || prev.source === "CSV" || !prev.interview_settings) return prev
            return {
                ...prev,
                interview_settings: {
                    ...prev.interview_settings,
                    intro_message: {
                        ...prev.interview_settings.intro_message,
                        [project.general_settings.language]: introMessage
                    }
                }
            }
        })
    }, [project, setProject])
    // #endregion

    // #region Debounced inputs
    const { debouncedOnChange } = useDebouncedInputChange({ callback: handleChangeProjectContext })
    // #endregion

    if (project?.source !== "interview") return <></>

    return (
        <div className="flex flex-col flex-1 gap-[0.5em]">
            <OutlineTabContentDefaultTitle
                title={getCopy(copy.outline.overview.welcomeMessage) ?? ""}
                subtitle={getCopy(copy.outline.overview.thisMessageWillBeDisplayedInTheInitial) ?? ""}
            />
            <GlautTextAreaMultiLine
                className="flex-1"
                placeholder={getCopy(copy.interview.welcome) ?? ""}
                defaultValue={
                    project.interview_settings?.intro_message[project.general_settings.language] ?? ""
                }
                disabled={!canEdit}
                onChange={debouncedOnChange}
            />
        </div>
    )
}