import { useEffect, useState } from "react"
import { FaCheckSquare, FaRegSquare } from "react-icons/fa"
import { MdClose } from "react-icons/md"
import { useDataTab, useDataTabDispatch } from "../../contexts/DataTabProvider"

interface IDataTabTableHeaderItemStatusModalFilterProps {
    onClose?: () => void
}

const filterOptions = {
    "completed": "Completed",
    "started": "Uncompleted"
} as const

export default function DataTabTableHeaderItemStatusModalFilter({
    onClose
}: Readonly<IDataTabTableHeaderItemStatusModalFilterProps>) {
    // #region Contexts
    const { entriesFilters } = useDataTab()
    const dataTabDispatch = useDataTabDispatch()
    // #endregion

    // #region States
    const [stateChecked, setStateChecked] = useState<Record<keyof typeof filterOptions, boolean>>({
        completed: entriesFilters.status === undefined || entriesFilters.status === "completed",
        started: entriesFilters.status === undefined || entriesFilters.status === "started"
    })
    // #endregion

    // #region Callbacks
    useEffect(() => {
        const noneChecked = !stateChecked.completed && !stateChecked.started
        if (noneChecked) return

        let status: keyof typeof filterOptions | undefined
        const bothChecked = stateChecked.completed && stateChecked.started
        if (!bothChecked)
            status = stateChecked.completed ? "completed" : "started"

        dataTabDispatch({ type: "set-filter-status", status })
    }, [stateChecked, dataTabDispatch])
    // #endregion

    return (
        <>
            <div className="relative z-10">
                <div className={`absolute z-10 top-0 left-[100%] bg-white px-[15px] py-[10px] min-w-[155px] border-1
                    rounded-[10px] border-glaut-grey`}>
                    <div className="flex flex-col">
                        <div className="flex flex-row justify-between items-center">
                            <p className="font-semibold text-glaut-grey">Show</p>
                            <MdClose
                                className="text-glaut-grey"
                                style={{ width: "20px", height: "20px" }}
                                onClick={onClose} />
                        </div>
                        {Object.entries(filterOptions).map(([state, label]) => (
                            <div key={state}>
                                <hr />
                                <div className="flex flex-row gap-2 py-2" onClick={() => {
                                    setStateChecked({ ...stateChecked, [state]: !stateChecked[state] })
                                }}>
                                    {stateChecked[state]
                                        ? <FaCheckSquare className="rounded-none text-glaut-midnight" />
                                        : <FaRegSquare className="rounded-none text-glaut-midnight" />}
                                    {label}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {/** Invisible div for closing the filter modal. */}
            <div className="absolute inset-0 z-0" onClick={onClose}></div>
        </>
    )
}