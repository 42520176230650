import { IAnalysisType } from "src/@types/analysis"
import { IQuestionType } from "src/@types/interview-question"

const TYPE_EMOJIS = {
    thematic: "🏷️",
    interpretation: "🧠",
    entity: "📺",
    select: "🗳️",
    open: "💬",
    nps: "⭐",
    scale: "⭐",
    asset: "🖼️"
} as const

export function questionLabelFromType(type: IAnalysisType | IQuestionType) {
    switch (type) {
        case "thematic":
            return `${TYPE_EMOJIS.thematic} Thematic Analysis`
        case "interpretation":
            return `${TYPE_EMOJIS.interpretation} Interpretative analysis`
        case "entity":
            return `${TYPE_EMOJIS.entity} Named entities`
        case "select":
            return `${TYPE_EMOJIS.select} Select`
        case "open":
            return `${TYPE_EMOJIS.open} Open`
        case "nps":
            return `${TYPE_EMOJIS.nps} Rate`
        case "scale":
            return `${TYPE_EMOJIS.scale} Rate`
        case "asset":
            return `${TYPE_EMOJIS.asset} Asset`
        default:
            return ""
    }
}

export function questionEmojiFromType(type: IAnalysisType | IQuestionType) {
    return TYPE_EMOJIS[type] ?? ""
}