import { INpsQuestion, IScaleQuestion } from "@/@types/interview-question"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useOutlineTabIqs, useOutlineTabIqsDispatch } from "../../contexts/OutlineTabIqsProvider"
import { useOutlineTab } from "../../contexts/OutlineTabProvider"
import GlautRateModalityComponent, {
    IGlautRateModalityComponentState
} from "@components/inputs/GlautRateModalityComponent"

export default function OutlineTabContentIqsQuestionFormBodyRateConfig() {
    // #region Contexts
    const { canEdit } = useOutlineTab()
    const { iqToBeBuilt } = useOutlineTabIqs()
    const outlineTabIqsDispatch = useOutlineTabIqsDispatch()
    // #endregion

    // #region States
    const [rateModality, setRateModality] = useState<"nps" | "custom">(
        () => iqToBeBuilt?.type === "nps"
            && (iqToBeBuilt as Partial<INpsQuestion>).min === 1
            && (iqToBeBuilt as Partial<INpsQuestion>).max === 10
            ? "nps" : "custom"
    )
    // #endregion

    // #region Memos
    const question = useMemo(() => {
        if (iqToBeBuilt?.type !== "nps" && iqToBeBuilt?.type !== "scale") return
        return iqToBeBuilt as Partial<INpsQuestion | IScaleQuestion>
    }, [iqToBeBuilt])
    // #endregion

    // #region Callbacks
    const handleRateConfigChange = useCallback((config: IGlautRateModalityComponentState) => {
        setRateModality(config.rateModality)

        if (config.rateModality === "nps") {
            outlineTabIqsDispatch({ type: "set-iq-attribute", attribute: "min", value: 1 })
            outlineTabIqsDispatch({ type: "set-iq-attribute", attribute: "min_copy", value: null })
            outlineTabIqsDispatch({ type: "set-iq-attribute", attribute: "max", value: 10 })
            outlineTabIqsDispatch({ type: "set-iq-attribute", attribute: "max_copy", value: null })
            outlineTabIqsDispatch({ type: "set-iq-attribute", attribute: "type", value: "nps" })

            return
        }

        const { min, max } = config.customScore

        outlineTabIqsDispatch({ type: "set-iq-attribute", attribute: "min", value: min.value ?? undefined })
        outlineTabIqsDispatch({ type: "set-iq-attribute", attribute: "min_copy", value: min.copy ?? undefined })
        outlineTabIqsDispatch({ type: "set-iq-attribute", attribute: "max", value: max.value ?? undefined })
        outlineTabIqsDispatch({ type: "set-iq-attribute", attribute: "max_copy", value: max.copy ?? undefined })
        outlineTabIqsDispatch({ type: "set-iq-attribute", attribute: "type", value: "scale" })
    }, [outlineTabIqsDispatch])
    // #endregion

    // #region Effects

    // onResetIqResetState
    useEffect(() => {
        let newRateModality: "nps" | "custom" = "nps"
        if (iqToBeBuilt?.type === "scale") newRateModality = "custom"

        setRateModality(newRateModality)
    }, [iqToBeBuilt?.type, iqToBeBuilt?.id])

    // #endregion

    if (!question) return <></>

    return (
        <div>
            <GlautRateModalityComponent
                value={rateModality === "nps" ? { rateModality: "nps" } : {
                    rateModality,
                    customScore: {
                        min: { value: question.min ?? null, copy: /** question.min_copy ?? */ null },
                        max: { value: question.max ?? null, copy: /** question.max_copy ?? */ null }
                    }
                }}
                onChange={handleRateConfigChange}
                disabled={!canEdit}
                allowChangingModality={iqToBeBuilt?.id === undefined}
                upperLimit={10}
                withMinMaxLabels={false}
            />
        </div>
    )
}