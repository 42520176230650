import { IAnalysisType } from "@/@types/analysis"

export type INewAnalysisOption = {
    title: string
    description: string
    type: IAnalysisType
}

const thematic: INewAnalysisOption = {
    title: "🏷️ Thematic analysis",
    description: "Identify relevant concepts in the form of themes and codes",
    type: "thematic"
}

const interpretation: INewAnalysisOption = {
    title: "🧠 Interpretative analysis",
    description: "Classify responses into predefined categories based on interpretative frameworks",
    type: "interpretation"
}

const entity: INewAnalysisOption = {
    title: "📺 Named entities",
    description: "Extract known entities like brands, celebrities, locations…",
    type: "entity"
}

export const newAnalysisOptions: INewAnalysisOption[] = [thematic, interpretation, entity]

export const newAnalysisOptionsMap: { [type in IAnalysisType]: INewAnalysisOption } = {
    thematic,
    interpretation,
    entity
}