import { copy, getCopy } from "@utils/Copy"
import { useCallback, useEffect, useMemo, useState } from "react"
import { MdClose, MdLightbulbOutline } from "react-icons/md"
import { useAnalysisTabAnalysisCol } from "../../contexts/AnalysisTabAnalysisColProvider"

const dismissedLocalStorageKey = "analysis-tab-has-dismissed-ta-mini-guide"

export default function AnalysisTabAnalysisColThematicMiniGuide() {
    // #region Contexts
    const { codeBook: { edits } } = useAnalysisTabAnalysisCol()
    // #endregion

    // #region States
    const [hasClosedCodeBookProposalAlert, setHasClosedCodeBookProposalAlert] = useState(true)
    // #endregion

    // #region Memos
    const glautCodeBookAlertClassNames = useMemo(() => {
        const baseClassNames = `flex flex-row justify-between rounded-[4px] border-1 transition-all
            border-glaut-dummie-color bg-glaut-cards`

        if (hasClosedCodeBookProposalAlert)
            return `${baseClassNames} opacity-0 invisible h-0 w-full p-0 mb-0 gap-0`

        return `${baseClassNames} opacity-100 visible h-max w-full p-[0.75em] mb-[0.5em] gap-[0.75em]`
    }, [hasClosedCodeBookProposalAlert])
    // #endregion

    // #region Callbacks
    const dismissMiniGuide = useCallback(() => {
        localStorage.setItem(dismissedLocalStorageKey, "true")
        setHasClosedCodeBookProposalAlert(true)
    }, [])

    const hasDismissedBefore = useCallback(() => localStorage.getItem(dismissedLocalStorageKey) !== null, [])
    // #endregion

    // #region Effects

    useEffect(() => {
        if (hasDismissedBefore()) return
        setHasClosedCodeBookProposalAlert(false)
    }, [hasDismissedBefore])

    useEffect(() => {
        if (Object.entries(edits).length === 0 || hasClosedCodeBookProposalAlert) return
        dismissMiniGuide()
    }, [dismissMiniGuide, edits, hasClosedCodeBookProposalAlert])

    // #endregion

    return (
        <div className={glautCodeBookAlertClassNames}>
            <div className="flex flex-row gap-[0.75em] items-center">
                <MdLightbulbOutline className="text-black w-[1em] h-[1em]" />
                <p className="text-[11.11px] text-black font-normal">
                    {getCopy(copy.coding.hereIsGlautsProposalOfThemesAndCodes)}
                </p>
            </div>
            <div className="flex flex-col justify-start">
                <MdClose
                    className="text-glaut-stroke-button w-[14px] h-[14px]"
                    onClick={dismissMiniGuide}
                />
            </div>
        </div>
    )
}