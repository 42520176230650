interface IOutlineTabContentDefaultTitleProps {
    title: string
    subtitle?: string
    isSmall?: boolean
}

export default function OutlineTabContentDefaultTitle({
    title,
    subtitle,
    isSmall
}: Readonly<IOutlineTabContentDefaultTitleProps>) {
    return (
        <div className={`flex flex-col ${!isSmall ? "gap-[0.75em]" : "gap-[0.25em]"}`}>
            <p className="text-[13.33px] font-medium text-glaut-text-midnight">
                {title}
            </p>
            {subtitle && (
                <p className="text-[13.33px] text-glaut-dark-grey">
                    {subtitle}
                </p>
            )}
        </div>
    )
}