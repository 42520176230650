import { IReport } from "@/@types/reports"
import { BaseApiService } from "@services/@shared/api/BaseApiService"
import { isStatsASegmentedStats } from "@utils/analysis/segmentation"
import { AxiosInstance } from "axios"
import {
    IReportGateway,
    IReportGatewayDeleteReportBlockRequest,
    IReportGatewayDeleteReportBlockResponse,
    IReportGatewayReportDetailsRequest,
    IReportGatewayReportDetailsResponse,
    IReportGatewayReportListRequest,
    IReportGatewayReportListResponse
} from "../IReportGateway"

interface IReportsApiServiceProps {
    api: AxiosInstance
}

export default class ReportsApiService extends BaseApiService implements IReportGateway {
    private readonly props: IReportsApiServiceProps

    constructor(props: IReportsApiServiceProps) {
        super()

        this.props = props
    }

    public async getReportList(
        request: IReportGatewayReportListRequest
    ): Promise<IReportGatewayReportListResponse> {
        const { projectId } = request

        return await this.props.api
            .get<IReport[]>(`projects/${projectId}/reports/v2`)
            .then(res => ({ reports: res.data }))
    }

    public async getReportDetails(
        request: IReportGatewayReportDetailsRequest
    ): Promise<IReportGatewayReportDetailsResponse> {
        const { reportId } = request

        return await this.props.api
            .get(`reports/v2/${reportId}`)
            .then(res => ({
                ...res.data,
                report: {
                    ...res.data.report,
                    appendix: res.data.report.appendix.map(b => {
                        if (!("stats" in b)) return b

                        const { stats } = b

                        if (Array.isArray(stats) && stats.every(isStatsASegmentedStats))
                            return {
                                ...b,
                                stats: this.parseNoAnswerCategoryForSegmentedStats(stats)
                            }

                        if (b.type === "nps_insight") return b
                        if (b.type === "select_insight")
                            return {
                                ...b,
                                stats: this.parseNoAnswerCategoryForQuestionSelectOptions(b.stats)
                            }

                        return {
                            ...b,
                            stats: this.parseNoAnswerCategoryForAnalysisStats(b.stats)
                        }
                    })
                }
            }))
    }

    public async deleteReportBlock(
        request: IReportGatewayDeleteReportBlockRequest
    ): Promise<IReportGatewayDeleteReportBlockResponse> {
        const { projectId, reportId, blockId } = request

        return await this.props.api
            .delete(`projects/${projectId}/reports/${reportId}/blocks/${blockId}`)
            .then(res => res.data)
    }
}