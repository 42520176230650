import GlautButtonSecondary from "@components/Buttons/GlautButtonSecondary"
import { useEffect, useRef } from "react"
import { DragDropContext } from "react-beautiful-dnd"
import { BiTrash } from "react-icons/bi"
import { FaTags } from "react-icons/fa"
import { MdAddCircle } from "react-icons/md"
import { v4 as uuid } from "uuid"
import { useEditQuestionOptions } from "../../../hooks/useEditQuestion"
import { normalizeQuestionOrOption } from "../../../utils/strings"
import { Drag, Drop } from "../../menus/DragAndDrop"
import IntSetting from "../../menus/Setting/IntSetting"

const EditMultipleChoice = ({ question, setQuestion, deleteOption, scroll, lang, disabled = false }) => {
    // #region Refs
    const optionsRef = useRef<(HTMLInputElement | null)[]>([])
    // #endregion

    // #region Edit question hooks
    const { addOption, editOption } = useEditQuestionOptions(setQuestion, lang)
    // #endregion

    // #region Callbacks
    const handleOptionDragEnd = result => {
        // Destructure properties from the result
        const { destination, source } = result

        // If there's no destination (e.g. drag is canceled or item is dragged outside droppable area)
        if (!destination)
            return

        // If the source and the destination is the same, no need to do anything
        if (destination.index === source.index)
            return

        // Create a new schema array by reordering the questions
        const newOptions = Array.from(question.options)
        const [movedOption] = newOptions.splice(source.index, 1)
        newOptions.splice(destination.index, 0, movedOption)

        setQuestion(p => ({ ...p, options: newOptions }))
    }

    const handleKeyPress = index => event => {
        if (event.key === "Enter") {
            event.preventDefault()

            // If it's the last input, add a new one
            if (index === question.options.length - 1) addOption()
            // Otherwise, focus the next input
            else optionsRef.current[index + 1]?.focus()
        }
    }
    // #endregion

    // #region Effects

    useEffect(() => {
        // Focus on the last input field (newly added) when the inputs array changes
        const lastInputIndex = question.options?.length - 1
        if (lastInputIndex > 0)
            optionsRef.current[lastInputIndex]?.focus()

    }, [question.options?.length])

    useEffect(() => {
        // If there are no options, add at least one
        if (!question.options?.length) addOption()

        // Update the refs array to the current length of inputs
        optionsRef.current = optionsRef.current.slice(0, question.options || 0)
    }, [question.options, addOption])

    // #endregion

    return (
        <>
            <IntSetting
                containerClassName="flex flex-row justify-between items-center my-[12px]"
                descriptionClassName="text-[13.33px] font-medium text-[rgb(0,0,0,0.6)]"
                inputClassName="bg-glaut-cards border-1 border-glaut-bar"
                setting={{
                    min: 1,
                    max: question.options?.length,
                    description: "How many options can the user select?",
                    disabled
                }}
                value={question.max_choices || 1}
                setValue={v => setQuestion(q => ({ ...q, max_choices: v }))}
            />
            <div style={{ width: "100%", position: "relative", paddingBottom: "1em" }}>
                <DragDropContext onDragEnd={handleOptionDragEnd}>
                    <Drop id="root" type="options" gap="8px">
                        {question.options?.map((o, idx) => (
                            <Drag id={uuid()} key={idx} index={idx} parentScroll={scroll} disabled={disabled}>
                                <div key={idx} className="flex flex-row items-center w-full group">
                                    <FaTags className="text-glaut-grey w-[16px] h-[16px]" />
                                    <input
                                        className="border-none focus:shadow-none"
                                        key={idx}
                                        placeholder={`${idx + 1}- Option`}
                                        value={o.label?.[lang]}
                                        ref={el => (optionsRef.current[idx] = el)}
                                        onChange={e => editOption(e.target.value, o.id)}
                                        onBlur={e => editOption(normalizeQuestionOrOption(e.target.value), o.id)}
                                        onKeyDown={handleKeyPress(idx)}
                                        disabled={disabled}
                                    />
                                    <BiTrash
                                        className={`clickable danger opacity-0 group-hover:opacity-100
                                            ${disabled ? "disabled" : ""}
                                        `}
                                        onClick={() => !disabled && deleteOption(o.id)}
                                    />
                                </div>
                            </Drag>
                        ))}
                    </Drop>
                </DragDropContext>
                <div className="flex justify-center">
                    <GlautButtonSecondary onClick={() => addOption()} disabled={disabled}>
                        <div className="flex flex-row items-center justify-center gap-[5px]">
                            <MdAddCircle className="w-[20px] h-[20px]" />
                            <p className="text-[13.33px] font-medium">Add option</p>
                        </div>
                    </GlautButtonSecondary>
                </div>
            </div>
        </>
    )
}

export default EditMultipleChoice
