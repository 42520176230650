import { MouseEventHandler, useMemo } from "react"
import { MdAdd, MdInfoOutline } from "react-icons/md"

interface IGlautChipComponentProps {
    onClick?: MouseEventHandler<HTMLButtonElement>
    disabled?: boolean
    label: string,
    tooltipContent?: string
    isHighlighted?: boolean
}

export default function GlautChipComponent({
    onClick,
    disabled = false,
    isHighlighted = false,
    label,
    tooltipContent
}: Readonly<IGlautChipComponentProps>) {
    // #region Memos
    const buttonClassNames = useMemo(() => {
        const baseClassNames = "flex gap-0 items-center rounded-full px-[0.5em] border-1 transition-all"
            + " shadow-none m-0 py-0 group"

        if (isHighlighted)
            return `${baseClassNames} bg-glaut-midnight border-glaut-very-light-grey`

        return `${baseClassNames}
            bg-glaut-cards border-glaut-very-light-grey
            enabled:hover:bg-glaut-dark-grey enabled:hover:border-glaut-grey
            enabled:hover:gap-[0.3125em]
        `
    }, [isHighlighted])

    const paragraphClassNames = useMemo(() => {
        const baseClassNames = "text-[11.11px] font-medium text-glaut-dark-grey"

        if (isHighlighted) return `${baseClassNames} text-glaut-off-white`

        return `${baseClassNames} group-enabled:group-hover:text-glaut-grey group-disabled:text-glaut-light-grey`
    }, [isHighlighted])
    // #endregion

    return (
        <div className="flex gap-[0.5em]">
            <button className={buttonClassNames} onClick={onClick} disabled={disabled}>
                <MdAdd className={`h-[1em] w-0 transition-all
                    text-glaut-grey
                    ${!isHighlighted ? "group-enabled:group-hover:w-[1em]" : ""}
                `} />
                <p className={paragraphClassNames}>
                    {label}
                </p>
            </button>
            {tooltipContent && (
                <MdInfoOutline
                    className="w-[1.125em] h-[1.125em] text-glaut-grey"
                    data-tooltip-id="tooltip--glaut-app"
                    data-tooltip-html={tooltipContent}
                    data-tooltip-place="right"
                />
            )}
        </div>
    )
}