import { IQuestionType } from "@/@types/interview-question"
import { copy, getCopy } from "@utils/Copy"

interface IQuestionTypeDetail {
    title: string
    description: string
    isHidden?: boolean
}
export const questionTypeDetails = (lang?: string) => ({
    open: {
        title: `💬 ${getCopy(copy.interviewQuestions.type.open.title, lang)}`,
        description: getCopy(copy.interviewQuestions.type.open.description, lang)
    },
    select: {
        title: `🗳️ ${getCopy(copy.interviewQuestions.type.select.title)}`,
        description: getCopy(copy.interviewQuestions.type.select.description, lang)
    },
    nps: {
        title: `⭐ ${getCopy(copy.interviewQuestions.type.rate.title)}`,
        description: getCopy(copy.interviewQuestions.type.rate.description, lang)
    },
    scale: {
        title: `⭐ ${getCopy(copy.interviewQuestions.type.rate.title)}`,
        description: getCopy(copy.interviewQuestions.type.rate.description, lang),
        isHidden: true
    },
    asset: {
        title: `🎬 ${getCopy(copy.interviewQuestions.type.asset.title)}`,
        description: getCopy(copy.interviewQuestions.type.asset.description, lang)
    }
}) as { [slug in IQuestionType]: IQuestionTypeDetail }