import GlautIconAlt from "@assets/glaut-icon-alt.svg"
import GlautIconDisabled from "@assets/glaut-icon-disabled.svg"
import GlautButtonPrimary from "@components/Buttons/GlautButtonPrimary"
import GlautAnalysisCreationForm from "@components/Forms/GlautAnalysisCreationForm"
import Loading from "@components/loading/Loading"
import { useAnalysisService } from "@hooks/services/useAnalysisService"
import {
    IAnalysisGatewayCreateAnalysisRequestCategory,
    IAnalysisGatewayUpdateAnalysisRequest
} from "@services/analyses/IAnalysisGateway"
import { questionLabelFromType } from "@utils/analysis/labels"
import { intersection } from "@utils/array/intersection"
import { copy, getCopy } from "@utils/Copy"
import { getProjectLang } from "@utils/language"
import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import {
    MdArrowBackIos
} from "react-icons/md"
import { IAnalysis } from "src/@types/analysis"
import { IProject } from "src/@types/project"
import { ProjectContext } from "../../../AutoSaveProject"
import { useAnalysisTab, useAnalysisTabDispatch } from "../../contexts/AnalysisTabProvider"
import { useProject } from "../../contexts/ProjectProvider"

export default function AnalysisTabAnalysisCreationForm() {
    // #region Contexts
    const { project } = useContext(ProjectContext) as { project: IProject | null }
    const { interviewQuestions } = useProject()
    const { newAnalysisType, editingAnalysis, categoryColorIndexes } = useAnalysisTab()
    const analysisTabDispatch = useAnalysisTabDispatch()
    // #endregion

    // #region Services
    const analysisService = useAnalysisService()
    // #endregion

    // #region States

    const [allowSubmission, setAllowSubmission] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)

    const [analysisData, setAnalysisData] = useState({
        goal: editingAnalysis?.goal ?? "",
        title: editingAnalysis?.title ?? "",
        categories: editingAnalysis?.categories ?? [],
        selectedIqIds: editingAnalysis?.sources ?? [],
        fixedCodebook: (editingAnalysis?.type === "thematic" ? editingAnalysis?.fixed_codebook : undefined) ?? false
    })

    // #endregion

    // #region Memos
    const lang = useMemo(() => getProjectLang(project), [project])
    const analysisType = useMemo(
        () => editingAnalysis?.type ?? newAnalysisType ?? undefined,
        [editingAnalysis, newAnalysisType]
    )
    const newAnalysisTypeLabel = useMemo(
        () => analysisType ? questionLabelFromType(analysisType) : "",
        [analysisType]
    )
    // #endregion

    // #region Callbacks
    const handleClickToGoBack = useCallback(() => {
        analysisTabDispatch({ type: "close-analysis-creation-or-editing" })
    }, [analysisTabDispatch])

    const formatExistingCodebookToRequest = useCallback(() => {
        const { categories } = analysisData

        const themes = categories.filter(c => c.parent === null)
        const codes = categories.filter(c => c.parent !== null)

        return themes.map(theme => ({
            label: theme.label,
            description: !theme?.description ? null : theme.description,
            children: codes.filter(c => c.parent === theme.id).map(code => ({
                label: code.label,
                description: !code?.description ? null : code.description
            }))
        }))
    }, [analysisData])

    const handleSubmission = useCallback(() => {
        if (!project) return

        setIsSubmitting(true)

        const { categories, fixedCodebook, goal, title, selectedIqIds = [] } = analysisData

        let newCategories: IAnalysisGatewayCreateAnalysisRequestCategory[] = []
        if (newAnalysisType === "interpretation" || editingAnalysis?.type === "interpretation")
            newCategories = categories
        else if (newAnalysisType === "thematic" || editingAnalysis?.type === "thematic")
            newCategories = fixedCodebook ? formatExistingCodebookToRequest() : []

        newCategories = newCategories.map(c => ({ ...c, description: !c?.description ? null : c.description }))

        let promise: Promise<IAnalysis>
        let whenEditingDidItRerun = false
        if (editingAnalysis) {
            const payload: IAnalysisGatewayUpdateAnalysisRequest["analysis"] = {
                id: editingAnalysis.id,
                type: editingAnalysis.type
            }

            if (title !== editingAnalysis.title)
                payload.title = title
            if (goal !== editingAnalysis.goal) {
                whenEditingDidItRerun = true
                payload.goal = goal
            }
            if (intersection(selectedIqIds, editingAnalysis.sources).length !== editingAnalysis.sources.length) {
                whenEditingDidItRerun = true
                payload.sources = selectedIqIds
            }
            if (
                newCategories.length > 0 &&
                JSON.stringify(newCategories) !== JSON.stringify(editingAnalysis.categories)
            ) {
                whenEditingDidItRerun = true
                payload.categories = newCategories
            }
            if (editingAnalysis.type === "thematic") {
                whenEditingDidItRerun = fixedCodebook !== editingAnalysis.fixed_codebook
                payload.fixed_codebook = fixedCodebook
            }

            promise = analysisService.updateAnalysis({
                projectId: project._id,
                analysis: payload
            })
        }
        else {
            if (!newAnalysisType) return
            promise = analysisService.createAnalysis({
                projectId: project._id,
                analysis: {
                    goal,
                    title,
                    sources: selectedIqIds,
                    categories: newCategories,
                    type: newAnalysisType
                }
            })
        }

        promise.then(analysis => {
            if (editingAnalysis)
                analysisTabDispatch({ type: "update-analysis", analysis, oldAnalysisToReplaceId: editingAnalysis.id })
            else
                analysisTabDispatch({ type: "add-analysis", analysis })

            if (!editingAnalysis || whenEditingDidItRerun)
                analysisTabDispatch({ type: "set-analysis-as-processing", analysisId: analysis.id })

            analysisTabDispatch({ type: "close-analysis-creation-or-editing" })
        }).finally(() => {
            setIsSubmitting(false)
        })
    }, [
        project,
        editingAnalysis,
        analysisService,
        newAnalysisType,
        analysisData,
        formatExistingCodebookToRequest,
        analysisTabDispatch
    ])
    // #endregion

    // #region Effects

    // onRerenderValidateSubmissionStatus
    useEffect(() => {
        const { categories, fixedCodebook, goal, selectedIqIds, title } = analysisData

        const hasValidTitle = title.length === 0 || title.length >= 3
        const hasNotEmptyPromptAndAtLeastOneOpenIqSelected = goal.length > 0 && selectedIqIds.length > 0

        if (analysisType === "interpretation") {
            const hasAtLeastOneCategory = categories.length > 1
            const hasAllCategoriesFilled = categories.every(
                c => c.label.length > 0 && c.description!.length > 0
            )

            setAllowSubmission(
                hasValidTitle &&
                hasNotEmptyPromptAndAtLeastOneOpenIqSelected &&
                hasAtLeastOneCategory &&
                hasAllCategoriesFilled
            )
            return
        }

        if (!fixedCodebook) {
            setAllowSubmission(hasValidTitle && hasNotEmptyPromptAndAtLeastOneOpenIqSelected)
            return
        }

        const hasAtLeastOneNonEmptyTheme = categories.some(t => t.label.length > 0)
        setAllowSubmission(hasValidTitle && hasNotEmptyPromptAndAtLeastOneOpenIqSelected && hasAtLeastOneNonEmptyTheme)
    }, [analysisData, analysisType])

    // #endregion

    if (!project) return <Loading />

    return (
        <div className="flex-1 flex flex-col max-w-[100%]">
            <div className="flex flex-row justify-between mr-[1.5em]">
                <div className="flex flex-row items-center gap-[0.75em]">
                    <button
                        className="flex flex-row gap-[0.3125em] items-center border-none shadow-none px-0"
                        onClick={handleClickToGoBack}
                    >
                        <MdArrowBackIos className="h-[1.25em] w-[1.25em] text-glaut-pink" />
                        <p className="font-medium text-[13.33px] text-glaut-pink underline">
                            {getCopy(copy.coding.back)}
                        </p>
                    </button>
                    <div className="rounded-full w-1 h-1 bg-glaut-grey"></div>
                    <p className="font-medium text-[13.33px] text-glaut-text-midnight">
                        {newAnalysisTypeLabel}
                    </p>
                </div>
                <GlautButtonPrimary onClick={handleSubmission} disabled={!allowSubmission || isSubmitting}>
                    {isSubmitting && (<Loading />)}
                    {!isSubmitting && (
                        <>
                            <img src={allowSubmission ? GlautIconAlt : GlautIconDisabled} alt="glaut-icon" />
                            <p className="font-medium text-[13.33px]">
                                {getCopy(editingAnalysis ? copy.coding.saveExclamation : copy.coding.createExclamation)}
                            </p>
                        </>
                    )}
                </GlautButtonPrimary>
            </div>
            <GlautAnalysisCreationForm
                lang={lang}
                defaultAnalysisValue={editingAnalysis ?? { type: newAnalysisType }}
                designMode="default"
                withInterviewQuestionsSelection
                interviewQuestions={interviewQuestions ?? []}
                categoryColorIndexes={categoryColorIndexes}
                value={analysisData}
                onChange={data => { setAnalysisData(data) }}
            />
        </div>
    )
}