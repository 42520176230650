import Loading from "@components/loading/Loading"
import Pagination from "@components/navigation/Pagination"
import { useContext, useEffect, useMemo, useRef, useState } from "react"
import {
    MdLanguage
} from "react-icons/md"
import { IProject } from "src/@types/project"
import { ProjectContext } from "../../../AutoSaveProject"
import { useDataTab, useDataTabDispatch } from "../../contexts/DataTabProvider"
import DataTabTableHeaderItem from "../DataTabTableHeaderItem"
import DataTabTableHeaderItemDate from "../DataTabTableHeaderItemDate"
import DataTabTableHeaderItemId from "../DataTabTableHeaderItemId"
import DataTabTableHeaderItemPlatform from "../DataTabTableHeaderItemPlatform"
import DataTabTableHeaderItemStatus from "../DataTabTableHeaderItemStatus"
import DataTabTableRowEmpty from "../DataTabTableRowEmpty"
import DataTabTableRowItem from "../DataTabTableRowItem"
import { useInterviewService } from "@hooks/services/useInterviewService"
import { useProject, useProjectDispatch } from "../../contexts/ProjectProvider"
import usePrevProps from "@hooks/usePrevProps"

export default function DataTabTable() {
    // #region Contexts
    const { shouldDataTabRefetchInterviews } = useProject()
    const projectDispatch = useProjectDispatch()
    const { conversationsData, entriesFilters, entriesSorts } = useDataTab()
    const dataTabDispatch = useDataTabDispatch()
    const { project } = useContext(ProjectContext) as { project: IProject | null }
    // #endregion

    // #region Services
    const interviewService = useInterviewService()
    // #endregion

    // #region States
    const [page, setPage] = useState(0)
    const [maxPage, setMaxPage] = useState(0)
    const [isTableLoading, setIsTableLoading] = useState(true)
    // #endregion

    // #region Prev props
    const prevPage = usePrevProps(page)
    const prevEntriesSorts = usePrevProps(entriesSorts)
    const prevEntriesFilters = usePrevProps(entriesFilters)
    // #endregion

    // #region Refs
    const containerDivRef = useRef<HTMLDivElement>(null)
    // #endregion

    // #region Memos
    const isTableEmpty = useMemo(
        () => conversationsData === undefined || conversationsData.interviews.length === 0,
        [conversationsData]
    )

    const emptyTableCustomMessage = useMemo(
        () => Object.values(entriesFilters).every(filter => filter === undefined)
            ? undefined
            : "No matching data for the selected filters",
        [entriesFilters]
    )

    const shouldRefetchInterviews = useMemo(() => {
        if (shouldDataTabRefetchInterviews) return true

        const hasSortsChanged = JSON.stringify(prevEntriesSorts) !== JSON.stringify(entriesSorts)
        const hasFiltersChanged = JSON.stringify(prevEntriesFilters) !== JSON.stringify(entriesFilters)
        const hasPageChanged = prevPage !== page

        return hasSortsChanged || hasFiltersChanged || hasPageChanged
    }, [
        entriesFilters,
        entriesSorts,
        prevEntriesFilters,
        prevEntriesSorts,
        shouldDataTabRefetchInterviews,
        page,
        prevPage
    ])
    // #endregion

    // #region Effects

    // onLoadRetrieveEntries
    useEffect(() => {
        if (!project || !shouldRefetchInterviews) return

        interviewService.getInterviews({
            projectId: project._id,
            page,
            identifier: entriesFilters.id,
            platforms: entriesFilters.platform ? [entriesFilters.platform] : undefined,
            completionStatus: entriesFilters.status,
            sortBy: "last_updated",
            sortOrder: entriesSorts.date
        }).then(conversationsData => {
            dataTabDispatch({ type: "set-conversations-data", conversationsData })
            projectDispatch({ type: "clear-force-data-tab-interviews-refetch" })
            setMaxPage(conversationsData.n_pages)

            setIsTableLoading(false)
            containerDivRef.current?.scroll({ top: 0, behavior: "smooth" })
        })
    }, [
        conversationsData,
        project,
        page,
        entriesFilters,
        entriesSorts,
        dataTabDispatch,
        projectDispatch,
        interviewService,
        shouldRefetchInterviews
    ])

    // onChangePageScrollToTop
    useEffect(() => {
    }, [page])

    // #endregion

    return (
        <>
            <div ref={containerDivRef} className={isTableLoading ? "overflow-hidden" : "overflow-auto"}>
                <table className="border-separate border-spacing-x-[21px] border-spacing-y-[6px] w-full">
                    <thead>
                        <tr>
                            <DataTabTableHeaderItemId />
                            <DataTabTableHeaderItemStatus />
                            <DataTabTableHeaderItemDate />
                            <DataTabTableHeaderItemPlatform />
                            <DataTabTableHeaderItem
                                title="Language"
                                icon={<MdLanguage className="text-glaut-grey mr-1 w-[14px] h-[14px]" />}
                            />
                        </tr>
                    </thead>
                    {/**
                      * weird class names to create a margin between thead and tbody (margin and padding don't work)
                      * @see https://stackoverflow.com/questions/9258754/spacing-between-thead-and-tbody
                      */}
                    <tbody className="before:content-['@'] before:block before:leading-[4px] before:indent-[-999999px]">
                        {!isTableLoading && conversationsData?.interviews?.map(entry => (
                            <DataTabTableRowItem key={entry._id} entry={entry} />
                        ))}
                    </tbody>
                </table>
                {!isTableLoading && isTableEmpty && (
                    <DataTabTableRowEmpty
                        customMessage={emptyTableCustomMessage}
                    />
                )}
                {!isTableLoading && !isTableEmpty && (
                    <Pagination
                        page={page}
                        setPage={setPage}
                        nPages={maxPage}
                    />
                )}
            </div>

            {isTableLoading && (
                <div className="flex flex-1 flex-col justify-center items-center">
                    <Loading />
                </div>
            )}
        </>
    )

}