import { IInterview } from "@/@types/entry"
import FloatingLayout from "@components/layouts/FloatingLayout"
import { useInterviewService } from "@hooks/services/useInterviewService"
import { copy, getCopy } from "@utils/Copy"
import { textColorWithCssColorVarFromIndex as colorFromIndex } from "@utils/styling/colors-from-index"
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { FaTag, FaTags } from "react-icons/fa"
import { MdSearch } from "react-icons/md"
import { IAnalysisCategory } from "src/@types/analysis"
import { ICategoryStats } from "src/@types/processing/statistics"
import { IProject } from "src/@types/project"
import { ProjectContext } from "../../../AutoSaveProject"
import { useAnalysisTab, useAnalysisTabDispatch } from "../../contexts/AnalysisTabProvider"
import { useAnalysisTabVerbatimsCol } from "../../contexts/AnalysisTabVerbatimsColProvider"
import AnalysisTabVerbatimsColItemCode from "../AnalysisTabVerbatimsColItemCode"
import { useAnalysisService } from "@hooks/services/useAnalysisService"

interface IAnalysisTabVerbatimsColItemSearchCodeBarProps {
    interview: IInterview
}

export default function AnalysisTabVerbatimsColItemSearchCodeBar({
    interview
}: Readonly<IAnalysisTabVerbatimsColItemSearchCodeBarProps>) {
    // #region Contexts
    const { project } = useContext(ProjectContext) as { project: IProject | null }
    const { analysesStats, selectedAnalysis, categoryColorIndexes } = useAnalysisTab()
    const analysisTabDispatch = useAnalysisTabDispatch()
    const { verbatimsListElement } = useAnalysisTabVerbatimsCol()
    // #endregion

    // #region Services
    const analysisService = useAnalysisService()
    const interviewService = useInterviewService()
    // #endregion

    // #region States
    const [searchValue, setSearchValue] = useState("")
    const [isShowingCodesDropdown, setIsShowingCodesDropdown] = useState(false)
    const [dropdownHeight, setDropdownHeight] = useState(0)
    const [isAddingNewThemeAndAssigning, setIsAddingNewThemeAndAssigning] = useState(false)
    // #endregion

    // #region Refs
    const containerDivRef = useRef<HTMLDivElement>(null)
    const searchBarDivRef = useRef<HTMLDivElement>(null)
    const searchBarInputRef = useRef<HTMLInputElement>(null)
    // #endregion

    // #region Values
    const dropdownPlacement =
        (window.scrollY + window.innerHeight) - (containerDivRef.current?.getBoundingClientRect().bottom ?? 0) < 224
            ? "top"
            : "down"
    // #endregion

    // #region Stats utils
    const filterByNonAssignedCodes = useCallback(
        (stats: ICategoryStats<IAnalysisCategory>) =>
            !interview.analysis_results.some(r => r.meaning_units.some(mu => mu.category_id === stats.category.id)) ||
            (stats.children.length > 0 && stats.children.some(filterByNonAssignedCodes)),
        [interview.analysis_results]
    )

    const filterBySearchedContent = useCallback(
        (stats: ICategoryStats<IAnalysisCategory>) =>
            stats.category.label.toLowerCase().includes(searchValue.toLowerCase()) ||
            (stats.children.length > 0 && stats.children.some(filterBySearchedContent)),
        [searchValue]
    )

    const filterByCodes = useCallback(
        (stats: ICategoryStats<IAnalysisCategory>) => stats.children.length === 0,
        []
    )

    const filterByThemes = useCallback(
        (stats: ICategoryStats<IAnalysisCategory>) => stats.children.length > 0,
        []
    )

    const someWithLabel = useCallback(
        (stats: ICategoryStats<IAnalysisCategory>, label: string) =>
            stats.category.label === label || stats.children.some(stats => someWithLabel(stats, label)),
        []
    )
    // #endregion

    // #region Memos
    const analysisStats = useMemo(
        () => selectedAnalysis
            ? analysesStats[selectedAnalysis.id]?.stats.filter(s => s.category.id !== "empty")
            : undefined,
        [analysesStats, selectedAnalysis]
    )

    const themesToShow = useMemo(
        () => analysisStats?.filter(filterBySearchedContent)?.filter(filterByNonAssignedCodes) ?? [],
        [analysisStats, filterByNonAssignedCodes, filterBySearchedContent]
    )

    const shouldShowAddNewThemeButton = useMemo(
        () => selectedAnalysis && searchValue !== "" && (
            themesToShow.length === 0 || themesToShow.some(stats => !someWithLabel(stats, searchValue))
        ) && !(interview.analysis_results
            .find(r => r.analysis_id === selectedAnalysis.id)
            ?.meaning_units
            .map(mu => selectedAnalysis.categories.find(c => c.id === mu.category_id))
            .filter(i => !!i)
            .some(c => c.label === searchValue)),
        [interview.analysis_results, searchValue, selectedAnalysis, someWithLabel, themesToShow]
    )
    // #endregion

    // #region Callbacks
    const handleToggleDropdownVisibility = useCallback((value?: boolean) => {
        setIsShowingCodesDropdown(prev => value ?? !prev)
    }, [])

    const handleUpdateSearchedValue = useCallback((value: string) => {
        setSearchValue(value)

        handleToggleDropdownVisibility(true)
    }, [handleToggleDropdownVisibility])

    const handleSelectCode = useCallback((categoryId: string) => {
        if (!project || !selectedAnalysis) return

        const projectId = project._id
        const analysisId = selectedAnalysis.id
        const interviewId = interview._id
        interviewService.assignCategoryToInterview({ interviewId, analysisId, categoryId, projectId })
            .then(() => {
                setSearchValue("")
                setIsShowingCodesDropdown(false)

                analysisTabDispatch({
                    type: "assign-category-to-interview",
                    analysisId,
                    categoryId,
                    interviewId
                })
                analysisTabDispatch({ type: "force-refresh-analyses-stats" })
            })
    }, [analysisTabDispatch, interview._id, interviewService, project, selectedAnalysis])

    const renderItemCodesElement = useCallback((stats: ICategoryStats<IAnalysisCategory>) => {
        const children = stats.children.filter(filterByCodes).filter(filterByNonAssignedCodes)
        if (children.length === 0) return undefined

        return (
            <div className="flex flex-wrap my-[0.5em]" style={{ gap: "0.3125em" }}>
                {children.map(codeStats => (
                    <AnalysisTabVerbatimsColItemCode
                        key={codeStats.category.id}
                        codeStats={codeStats}
                        index={categoryColorIndexes[stats.category.id] ?? 1}
                        onClick={() => { handleSelectCode(codeStats.category.id) }}
                    />
                ))}
            </div>
        )
    }, [categoryColorIndexes, filterByCodes, filterByNonAssignedCodes, handleSelectCode])

    const handleAddSearchValueAsNewTheme = useCallback(() => {
        if (!project || !selectedAnalysis || !shouldShowAddNewThemeButton || searchValue === "") return

        setIsAddingNewThemeAndAssigning(true)

        const label = searchValue
        const projectId = project._id
        const analysisId = selectedAnalysis.id

        analysisService.createAnalysisCategory({
            projectId,
            analysisId,
            category: { label, description: null, parent: null }
        }).then(theme => {
            analysisTabDispatch({ type: "create-new-theme", theme })
            handleSelectCode(theme.id)
        }).finally(() => {
            setIsAddingNewThemeAndAssigning(false)
        })
    }, [
        project,
        selectedAnalysis,
        shouldShowAddNewThemeButton,
        searchValue,
        analysisService,
        analysisTabDispatch,
        handleSelectCode
    ])
    // #endregion

    // #region Effects
    useEffect(() => {
        if (!verbatimsListElement || !isShowingCodesDropdown) return

        function onScroll() {
            setIsShowingCodesDropdown(false)
            searchBarInputRef.current?.blur()
        }

        verbatimsListElement.addEventListener("scroll", onScroll)
        return () => { verbatimsListElement.removeEventListener("scroll", onScroll) }
    }, [verbatimsListElement, isShowingCodesDropdown])
    // #endregion

    // #region Element memos
    const floatingLayoutContentElement = useMemo(() => themesToShow.map((stats, idx) => (
        <div
            key={stats.category.id}
            className={`flex flex-col ${idx === 0 ? "" : "border-t-1 border-t-glaut-stroke-glaut"}`}
        >
            <button
                className="text-[1em] flex gap-[0.5em] p-[0.5em] w-full justify-start text-start
                    border-none shadow-none rounded-none hover:bg-glaut-very-light-grey"
                onClick={() => { handleSelectCode(stats.category.id) }}
            >
                <FaTag className={`w-[1em] h-[1em] rounded-none
                        ${colorFromIndex(categoryColorIndexes[stats.category.id] ?? 1)}
                    `} />
                <p className="text-[13.33px] font-medium text-glaut-text-midnight">
                    {stats.category.label}
                </p>
            </button>
            {renderItemCodesElement(stats)}
            <div className="flex flex-col">
                {stats.children
                    .filter(filterByThemes)
                    .filter(filterByNonAssignedCodes)
                    .map(subThemeStats => (
                        <div key={subThemeStats.category.id} className="flex flex-col">
                            <div className="flex gap-[0.5em] items-center p-[0.5em]">
                                <FaTags className={`h-[1em] w-[1em]
                                    ${colorFromIndex(categoryColorIndexes[subThemeStats.category.id] ?? 1)}
                                `} />
                                <p className="text-[13.33px] font-medium text-glaut-text-midnight">
                                    {subThemeStats.category.label}
                                </p>
                            </div>
                            <div className="flex flex-wrap my-[0.5em]" style={{ gap: "0.3125em" }}>
                                {subThemeStats.children
                                    .filter(filterByNonAssignedCodes)
                                    .filter(filterBySearchedContent)
                                    .map(codeStats => (
                                        <AnalysisTabVerbatimsColItemCode
                                            key={codeStats.category.id}
                                            codeStats={codeStats}
                                            index={categoryColorIndexes[stats.category.id] ?? 1}
                                            onClick={() => { handleSelectCode(codeStats.category.id) }}
                                        />
                                    ))}
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    )), [
        themesToShow,
        categoryColorIndexes,
        filterByNonAssignedCodes,
        filterBySearchedContent,
        filterByThemes,
        handleSelectCode,
        renderItemCodesElement
    ])
    // #endregion

    if (!analysisStats) return <></>

    return (
        <div className="flex gap-[0.3125em] items-center w-full overflow-hidden" ref={containerDivRef}>
            <div className={`flex gap-[0.3125em] px-[0.625em] py-[0.3125em] border-1 rounded-[0.25em] flex-1
                items-center
                border-glaut-grey
            `} ref={searchBarDivRef}>
                <MdSearch className="w-[1.125em] h-[1.125em] text-glaut-light-grey" />
                <input
                    className={`bg-transparent border-none p-0 text-[13.33px] font-medium rounded-none
                        text-glaut-text-midnight
                        placeholder:text-glaut-grey
                        focus:shadow-none
                    `}
                    ref={searchBarInputRef}
                    placeholder={getCopy(copy.coding.typeHereToSearchForACode) ?? ""}
                    value={searchValue}
                    onChange={ev => { handleUpdateSearchedValue(ev.target.value) }}
                    onFocus={() => { handleToggleDropdownVisibility(true) }}
                    onKeyDown={ev => {
                        if (ev.key === "Escape") {
                            searchBarInputRef.current?.blur()
                            handleToggleDropdownVisibility(false)
                            setSearchValue("")
                        }

                        if (ev.key === "Enter")
                            handleAddSearchValueAsNewTheme()
                    }}
                />
            </div>

            {isShowingCodesDropdown && (
                <FloatingLayout
                    anchorRef={containerDivRef}
                    className={`absolute rounded-[0.25em] p-[0.75em] border-1 max-h-56 overflow-auto z-50
                        bg-glaut-off-white border-glaut-grey
                    `}
                    style={{
                        width: searchBarDivRef.current?.offsetWidth ?? "100%"
                    }}
                    horizontalOffset={-(searchBarDivRef.current?.offsetWidth ?? 0)}
                    verticalOffset={
                        dropdownPlacement === "top"
                            ? - dropdownHeight - 5
                            : (searchBarDivRef.current?.offsetHeight ?? 0) + 5
                    }
                    boundingBoxOffset={10}
                    onClose={() => {
                        setIsShowingCodesDropdown(false)
                        searchBarInputRef.current?.blur()
                        setSearchValue("")
                    }}
                    ref={element => { if (element) setDropdownHeight(element.offsetHeight) }}
                >
                    {shouldShowAddNewThemeButton && (
                        <button
                            className="flex gap-[0.5em] p-[0.5em] rounded-none bg-glaut-very-light-grey
                                shadow-none m-0 border-none justify-start w-full text-base"
                            onClick={() => { handleAddSearchValueAsNewTheme() }}
                            disabled={isAddingNewThemeAndAssigning}
                        >
                            <FaTag className={`w-[1em] h-[1em] rounded-none ${colorFromIndex(0)}`} />
                            <p className="text-[13.33px] font-medium text-glaut-dark-grey">
                                {getCopy(copy.coding.addNewThemeAsAThemeCallback)?.(
                                    <span className="text-glaut-text-midnight">{`"${searchValue}"`}</span>,
                                    selectedAnalysis?.type === "entity" ? "entity" : "theme"
                                )}
                            </p>
                        </button>
                    )}
                    {floatingLayoutContentElement}
                    {floatingLayoutContentElement.length === 0 && !shouldShowAddNewThemeButton && (
                        <div className="flex w-full justify-center">
                            <p className="text-[13.33px] font-medium text-glaut-text-midnight">
                                No themes to show.
                            </p>
                        </div>
                    )}
                </FloatingLayout>
            )}
        </div>
    )
}