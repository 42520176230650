import { copy, getCopy } from "@utils/Copy"
import { ChangeEventHandler, FocusEventHandler, useCallback, useState } from "react"
import { useOutlineTab } from "../../contexts/OutlineTabProvider"
import { useLegacyProject } from "../../contexts/ProjectProvider"
import OutlineTabContentDefaultToggle from "../OutlineTabContentDefaultToggle"

export default function OutlineTabContentOverviewResponsesTarget() {
    // #region Contexts
    const { project, setProject } = useLegacyProject()
    const { canEdit } = useOutlineTab()
    // #endregion

    // #region States
    const [maxResponses, setMaxResponses] = useState(1000)
    // #endregion

    // #region Callbacks
    const handleToggle: ChangeEventHandler<HTMLInputElement> = useCallback(ev => {
        setProject(prev => {
            if (!prev) return prev

            return {
                ...prev,
                general_settings: {
                    ...prev.general_settings,
                    max_responses: ev.target.checked ? maxResponses : null
                }
            }
        })
    }, [maxResponses, setProject])

    const handleUpdateMaxResponses = useCallback((newMaxResponses: number) => {
        if (newMaxResponses < 1 || isNaN(newMaxResponses)) return

        setMaxResponses(newMaxResponses)
        setProject(prev => {
            if (!prev) return prev

            return {
                ...prev,
                general_settings: {
                    ...prev.general_settings,
                    max_responses: newMaxResponses
                }
            }
        })
    }, [setProject])

    const handleBlurNumberInput: FocusEventHandler<HTMLInputElement> = useCallback(e => {
        const value = parseInt(e.target.value)
        if (value < 1 || isNaN(value)) e.target.value = "1"
    }, [])
    // #endregion

    if (!project) return <></>

    return (
        <div className="flex flex-col gap-[0.75em]">
            <OutlineTabContentDefaultToggle
                onChange={handleToggle}
                checked={project.general_settings.max_responses !== null}
                disabled={!canEdit}
                label={getCopy(copy.outline.overview.responsesTarget) ?? ""}
                tooltipContent={getCopy(copy.outline.overview.setAMaximumNumberOfResponses) ?? ""}
            />
            {project.general_settings.max_responses !== null && (
                <input
                    className="border-1 w-[20%] focus:shadow-none text-center rounded-[0.25em] 
                    border-glaut-bar bg-glaut-off-white enabled:hover:border-glaut-pink"
                    type="number"
                    name="tentacles"
                    defaultValue={project.general_settings.max_responses ?? 1000}
                    min={1}
                    max={999999}
                    step={100}
                    onChange={e => handleUpdateMaxResponses(parseInt(e.target.value))}
                    onBlur={handleBlurNumberInput}
                    disabled={!canEdit}
                />
            )}
        </div>
    )
}