import { Text, View } from "@react-pdf/renderer"
import { Style } from "@react-pdf/types"

interface IAnalysisMetricsProps {
    blocks: {
        label: string
        value: number | string
    }[]
    asPdf?: boolean
    twPdf?: (input: string) => Style
}

export default function AnalysisMetrics({
    blocks,
    asPdf,
    twPdf
}: Readonly<IAnalysisMetricsProps>) {
    if (asPdf && twPdf)
        return (
            <View style={[
                twPdf("flex flex-row items-center gap-[5.09px] w-full border-1 rounded-[5.09px] p-[5.09px]"),
                twPdf("bg-glautOffWhite border-glautStrokeGlaut")
            ]}>
                {blocks.map(({ label, value }) => (
                    <View
                        key={label}
                        style={[
                            twPdf("flex flex-col items-center rounded-[5.09px] border-1 flex-1 p-[5.09px]"),
                            twPdf("bg-glautCards border-glautVeryLightGrey")
                        ]}
                    >
                        <Text style={twPdf("text-[12.22px] font-sans")}>{value}</Text>
                        <Text style={twPdf("text-[10.18px] font-sans")}>{label}</Text>
                    </View>
                ))}
            </View>
        )

    return (
        <div className={`flex flex-row items-center gap-[0.5em] w-full border-1 rounded-[10px] p-[0.5em]
            bg-glaut-off-white border-glaut-stroke-glaut`}>
            {blocks.map(({ label, value }) => (
                <div key={label} className={`flex flex-col items-center rounded-[0.5em] border-1 flex-1 p-[0.5em]
                    bg-glaut-cards border-glaut-very-light-grey
                `}>
                    <p className="text-[13.33px] font-medium text-glaut-midnight">
                        {value}
                    </p>
                    <p className="text-[11.11px] font-medium text-glaut-text-midnight">
                        {label}
                    </p>
                </div>
            ))}
        </div>
    )
}