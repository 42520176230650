export const reports = {
    reports: {
        createReport: {
            en: "Create report"
        },
        noReportWasCreatedYet: {
            en: "No report was created yet"
        },
        downloadReport: {
            en: "Download report"
        },
        previewReport: {
            en: "Preview report"
        },
        responsesAnalyzed: {
            en: "responses analyzed"
        },
        showDetails: {
            en: "See details"
        },
        hideDetails: {
            en: "Hide details"
        },
        noDetailsToShow: {
            en: "No details to show"
        },
        selectedIqs: {
            en: "Selected IQs"
        },
        filterBy: {
            en: "Filter by"
        },
        breakdownBy: {
            en: "Breakdown by"
        },
        index: {
            en: "Index"
        },
        contextMenuOptions: {
            copyUrl: {
                en: "Copy URL"
            },
            shareUrl: {
                en: "Share URL"
            },
            downloadReport: {
                en: "Download report"
            }
        }
    }
} as const