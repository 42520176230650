import { ChangeEventHandler } from "react"
import useAxiosAuth from "../../../hooks/useAxiosAuth"

interface IImageInputProps {
    projectId: string
    imageId: string
    setUrl: (src: string) => void
    value?: string
    disabled?: boolean
}

const ImageInput = ({
    projectId,
    imageId,
    setUrl,
    value,
    disabled = false
}: Readonly<IImageInputProps>) => {
    const axiosAuth = useAxiosAuth()

    // #region Callbacks
    const handleImageChange: ChangeEventHandler<HTMLInputElement> = e => {
        const file = e.target.files?.[0]
        if (file) submitImage(file)
    }

    const submitImage = (imageFile: File) => {
        const formData = new FormData()
        formData.append("image", imageFile)
        formData.append("image_id", imageId)

        axiosAuth
            .post(`datasets/${projectId}/upload-image`, formData)
            .then(response => {
                console.log(response.data)
                setUrl(response.data)
            })
            .catch(error => console.error(error))
    }
    // #endregion

    return (
        <div className="rounded-[0.25em] border-1 border-glaut-grey" style={{ margin: "1em" }}>
            <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                style={{ display: "none" }}
                id="fileInput"
                disabled={disabled}
            />
            <label htmlFor="fileInput" className={["button", disabled ? "disabled" : "", "p-[0.5em]"].join(" ")}>
                {value ? "Change Image" : "Choose Image"}
            </label>
        </div>
    )
}

export default ImageInput
