import { formatDateForReport } from "@utils/date/format-date"
import { useReportDetails } from "../../contexts/ReportDetailsContext"
import { Text } from "@react-pdf/renderer"
import { useMemo } from "react"

export default function ReportDetailsContentLastEditedAt() {
    // #region Contexts
    const { report, renderAsPdf, tw } = useReportDetails()
    // #endregion

    // #region Memos
    const content = useMemo(() => formatDateForReport(report.last_edited_at), [report.last_edited_at])
    // #endregion

    if (renderAsPdf)
        return (
            <Text style={tw("font-sans text-[8.48px] text-glautDarkGrey px-[8px] self-start")}>
                {content}
            </Text>
        )

    return (
        <p className="text-[13.33px] text-glaut-dark-grey mt-[3rem] mx-[12px] self-end">
            {content}
        </p>
    )
}