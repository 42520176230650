import { useMemo } from "react"
import { useOutlineTabIqs } from "../../contexts/OutlineTabIqsProvider"
import ExAnteAnalysisListItem from "../OutlineTabContentIqsQuestionFormBodyExAnteAnalysisListItem"

export default function OutlineTabContentIqsQuestionFormBodyExAnteAnalysisList() {
    // #region Contexts
    const { iqToBeBuilt } = useOutlineTabIqs()
    // #endregion

    // #region Memos
    const sortedAnalyses = useMemo(() => {
        if (!iqToBeBuilt) return []
        const { analyses = [] } = iqToBeBuilt

        analyses.sort((a, b) => {
            if (!a?.previous_run_created_at && !b?.previous_run_created_at) return 0
            if (!a?.previous_run_created_at) return 1
            if (!b?.previous_run_created_at) return -1

            return Date.parse(a.previous_run_created_at) - Date.parse(b.previous_run_created_at)
        })

        return analyses
    }, [iqToBeBuilt])
    // #endregion

    if (!iqToBeBuilt?.analyses) return <></>

    return (
        <div className="flex flex-col gap-[0.25em]">
            {sortedAnalyses.map((analysis, idx) => (
                <ExAnteAnalysisListItem
                    key={analysis?.id ?? idx}
                    analysis={analysis}
                />
            ))}
        </div>
    )
}