import { IProjectResults } from "@/@types/project"
import GlautInfoIcon2 from "@assets/glaut-info-icon-2.svg"
import { monitorForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter"
import EmptySpaceBox from "@components/EmptySpaceBox"
import Pagination from "@components/navigation/Pagination"
import { useAnalysisService } from "@hooks/services/useAnalysisService"
import { useInterviewService } from "@hooks/services/useInterviewService"
import { extractIdFromSegment } from "@utils/analysis/segmentation"
import { copy, getCopy } from "@utils/Copy"
import React, { useEffect, useMemo } from "react"
import { MdOutlineChat } from "react-icons/md"
import { ICategoryStats } from "src/@types/processing/statistics"
import { IInterview } from "src/@types/entry"
import { useAnalysisTab, useAnalysisTabDispatch } from "../../contexts/AnalysisTabProvider"
import AnalysisTabVerbatimsColItem from "../AnalysisTabVerbatimsColItem"
import {
    useAnalysisTabVerbatimsCol,
    useAnalysisTabVerbatimsColDispatch
} from "../../contexts/AnalysisTabVerbatimsColProvider"
import Loading from "@components/loading/Loading"

interface IAnalysisTabVerbatimsColProps {
    nEntries: number
    page: number
    setPage: React.Dispatch<React.SetStateAction<number>>
    nPages: number
    isLoadingVerbatims?: boolean
}

export default function AnalysisTabVerbatimsCol({
    nEntries,
    page,
    setPage,
    nPages,
    isLoadingVerbatims = false
}: Readonly<IAnalysisTabVerbatimsColProps>) {
    // #region Contexts
    const { selectedCategory, selectedAnalysis, entries } = useAnalysisTab()
    const analysisTabDispatch = useAnalysisTabDispatch()
    const { verbatimsListElement } = useAnalysisTabVerbatimsCol()
    const analysisTabVerbatimsColDispatch = useAnalysisTabVerbatimsColDispatch()
    // #endregion

    // #region Services
    const interviewService = useInterviewService()
    const analysisService = useAnalysisService()
    // #endregion

    // #region Memos
    const allValidSelectedCodeIds = useMemo(() => {
        if (!selectedCategory || !selectedAnalysis) return undefined
        if (selectedCategory.category.id === "empty") return ["empty"]

        const validSelectedCodes: string[] = []

        // needs recursion here
        /**********************************************************************/
        function markAllChildrenAsSelected(stats: ICategoryStats) {
            validSelectedCodes.push(extractIdFromSegment(stats.category))

            for (const childStats of stats.children)
                markAllChildrenAsSelected(childStats)
        }
        /**********************************************************************/

        markAllChildrenAsSelected(selectedCategory)

        return validSelectedCodes
    }, [selectedAnalysis, selectedCategory])

    const sortedEntries = useMemo(() => {
        if (!entries || entries.length === 0) return entries ?? []

        const entriesWithNoCodes: IInterview[] = []
        const entriesWithCodes: IInterview[] = []
        for (const entry of entries) {
            const values = selectedAnalysis
                ? entry.analysis_results.find(r => r.analysis_id === selectedAnalysis.id) ?? null
                : null

            // no codes for entry => add to the end
            // there are codes for entry => add to the start
            if (!values || values.meaning_units.length === 0)
                entriesWithNoCodes.push(entry)
            else
                entriesWithCodes.push(entry)
        }

        return entriesWithCodes.concat(entriesWithNoCodes)
    }, [entries, selectedAnalysis])
    // #endregion

    // #region Effects

    // onDropThemeOrCodeAssignItToEntry
    useEffect(() => monitorForElements({
        onDrop: ({ source, location }) => {
            if (!selectedAnalysis?.project_id) return

            // Unpacks the relevant values
            const destination = location.current.dropTargets[0]
            if (!destination) return

            const { data: sourceData } = source
            const { data: destData } = destination

            // Checks that we have the data we need
            if (!sourceData || !destData) return

            // Unpacks entry, question and source code
            const { entry } = destData as { entry?: IInterview }
            if (!entry) return

            const { stats: categoryToBeAssignedStats } = sourceData as { stats: ICategoryStats }

            const projectId = selectedAnalysis.project_id
            const analysisId = selectedAnalysis.id
            const categoryId = extractIdFromSegment(categoryToBeAssignedStats.category)
            const interviewId = entry._id

            interviewService.assignCategoryToInterview({ projectId, analysisId, categoryId, interviewId }).then(() => {
                analysisTabDispatch({ type: "assign-category-to-interview", analysisId, categoryId, interviewId })
                analysisService.getAnalysesStats({ projectId })
                    .then(res => {
                        const { analysis_results: analysesStats } = res as IProjectResults
                        analysisTabDispatch({ type: "set-analyses-stats", analysesStats })
                    })
            })
        }
    }))

    // onPageChangeScrollUp
    useEffect(() => {
        if (!verbatimsListElement) return
        verbatimsListElement.scrollTo({ top: 0, behavior: "smooth" })
    }, [page, verbatimsListElement])

    // #endregion

    return (
        <div className="flex flex-col overflow-hidden h-full">
            <div className="flex flex-row justify-between my-[0.75em] mx-[0.75em]">
                <span className="leading-trim text-[13.33px] text-[rgb(0,0,0,0.6)]">
                    {getCopy(copy.coding.verbatims)}
                </span>
                <div className="flex flex-row gap-2">
                    <MdOutlineChat className="text-glaut-grey" />
                    <span className="text-glaut-midnight text-[13.33px]">
                        {`${sortedEntries.length} out of ${nEntries}`}
                    </span>
                </div>
            </div>
            {isLoadingVerbatims && (<Loading fullPage />)}
            {!isLoadingVerbatims && (
                <div className="overflow-auto h-full px-[0.5em]" ref={elem => {
                    if (!elem) return
                    analysisTabVerbatimsColDispatch({ type: "set-verbatims-list-element", verbatimsListElement: elem })
                }}>
                    {sortedEntries.length > 0
                        ? (
                            <div className="flex flex-col gap-[0.5em]">
                                {sortedEntries.map((e, idx) => (
                                    <AnalysisTabVerbatimsColItem
                                        key={e._id}
                                        entry={e}
                                        startExpanded
                                        allValidSelectedCodeIds={allValidSelectedCodeIds}
                                        index={idx}
                                    />
                                ))}
                                <Pagination
                                    page={page}
                                    setPage={setPage}
                                    nPages={nPages}
                                    disabled={isLoadingVerbatims}
                                    style={{ marginTop: "1em", marginBottom: "1em" }}
                                />
                            </div>
                        ) : (
                            <EmptySpaceBox
                                message={getCopy(copy.coding.emptyVerbatimsColumnMessage) ?? ""}
                                leftIcon={<img src={GlautInfoIcon2} alt="Info" />}
                            />
                        )
                    }
                </div>
            )}
        </div>
    )
}
