import ModalLayout from "@components/layouts/ModalLayout"
import {
    useAnalysisTabAnalysisCol,
    useAnalysisTabAnalysisColDispatch
} from "../../contexts/AnalysisTabAnalysisColProvider"
import { getCopy, copy } from "@utils/Copy"
import AnalysisTabAnalysisColThemeCode from "../AnalysisTabAnalysisColThemeCode"
import { IoArrowForwardSharp } from "react-icons/io5"
import GlautButtonSecondary from "@components/Buttons/GlautButtonSecondary"
import GlautButtonPrimary from "@components/Buttons/GlautButtonPrimary"
import { useContext, useEffect, useState } from "react"
import { useAnalysisService } from "@hooks/services/useAnalysisService"
import { IProject } from "src/@types/project"
import { ProjectContext } from "../../../AutoSaveProject"
import { useAnalysisTab, useAnalysisTabDispatch } from "../../contexts/AnalysisTabProvider"
import Loading from "@components/loading/Loading"

const fakeCodeToStartComponent: ReturnType<typeof useAnalysisTabAnalysisCol>["categoryBeingMerged"] = {
    category: {
        id: "",
        label: "",
        parent: null,
        description: null
    },
    children: [],
    frequency: 0,
    index: 0,
    occurrences: 0,
    themeId: ""
}

interface IAnalysisTabAnalysisColMergingBoxModalConfirmationProps {
    isOpen: boolean
    onClose?: () => void
}

export default function AnalysisTabAnalysisColMergingBoxModalConfirmation({
    isOpen,
    onClose
}: Readonly<IAnalysisTabAnalysisColMergingBoxModalConfirmationProps>) {
    // #region Contexts
    const { project } = useContext(ProjectContext) as { project: IProject | null }
    const { selectedAnalysis, selectedCategory } = useAnalysisTab()
    const analysisTabDispatch = useAnalysisTabDispatch()
    const { categoryBeingMerged, targetCategoryBeingMerged } = useAnalysisTabAnalysisCol()
    const analysisTabAnalysisColDispatch = useAnalysisTabAnalysisColDispatch()
    // #endregion

    // #region Services
    const analysisService = useAnalysisService()
    // #endregion

    // #region States
    const [isLoading, setIsLoading] = useState(false)
    const [fromCode, setFromCode] = useState<typeof categoryBeingMerged>(fakeCodeToStartComponent)
    const [toCode, setToCode] = useState<typeof targetCategoryBeingMerged>(fakeCodeToStartComponent)
    // #endregion

    // #region Callbacks

    // Performs the merging (current code into the target code) via websocket
    const handleMerge = () => {
        if (!project || !selectedAnalysis || !categoryBeingMerged || !targetCategoryBeingMerged) return

        const projectId = project._id
        const analysisId = selectedAnalysis.id
        const sourceCodeId = categoryBeingMerged.category.id
        const targetCodeId = targetCategoryBeingMerged.category.id

        setIsLoading(true)

        analysisService.mergeCodes({ projectId, analysisId, sourceCodeId, targetCodeId }).then(({ analysisStats }) => {
            if (selectedCategory?.category.id === sourceCodeId) analysisTabDispatch({ type: "clear-selected-category" })

            analysisTabDispatch({ type: "merge-categories", analysisId, sourceCodeId, targetCodeId, analysisStats })
            analysisTabAnalysisColDispatch({ type: "clear-category-being-merged" })
            onClose?.()
        })
            .finally(() => {
                setIsLoading(false)
            })
    }

    // #endregion

    // #region Effects
    useEffect(() => {
        if (categoryBeingMerged) setFromCode(categoryBeingMerged)
        if (targetCategoryBeingMerged) setToCode(targetCategoryBeingMerged)
    }, [categoryBeingMerged, targetCategoryBeingMerged])
    // #endregion

    if (!toCode || !fromCode) return <></>

    return (
        <ModalLayout
            isOpen={isOpen}
            onClose={() => !isLoading && onClose?.()}
            className={`flex flex-col items-center rounded-xl px-4 py-6 gap-3 transition-all border-[1px]
                border-glaut-grey bg-glaut-off-white shadow-[0px_0px_8px_0px_rgb(0,0,0,0.15)]
                ${isOpen ? "scale-100 opacity-100" : "scale-125 opacity-0"}
            `}
        >
            <p className="text-[13.33px] font-medium text-glaut-text-midnight">
                {getCopy(categoryBeingMerged?.category.id === categoryBeingMerged?.themeId
                    ? copy.coding.merge.confirmTitleThemes
                    : copy.coding.merge.confirmTitle
                )}
            </p>
            <div className="flex flex-row gap-[0.5em] items-center justify-center">
                <AnalysisTabAnalysisColThemeCode
                    codeStats={fromCode}
                    themeId={fromCode.themeId}
                    colorIndex={fromCode.index}
                    forceShowColor
                />
                <IoArrowForwardSharp className="h-[1em] w-[1em] text-glaut-text-midnight" />
                <AnalysisTabAnalysisColThemeCode
                    codeStats={toCode}
                    themeId={toCode.themeId}
                    colorIndex={toCode.index}
                    forceShowColor
                />
            </div>
            <div className="flex flex-row justify-center items-center gap-[2em]">
                <GlautButtonSecondary onClick={onClose} disabled={isLoading}>
                    {getCopy(copy.coding.cancel)}
                </GlautButtonSecondary>
                <GlautButtonPrimary onClick={handleMerge} disabled={isLoading}>
                    {!isLoading ? getCopy(copy.coding.confirm) : (
                        <Loading />
                    )}
                </GlautButtonPrimary>
            </div>
        </ModalLayout>
    )
}