import { useState, useMemo } from "react"
import { copy, getCopy } from "@utils/Copy"
import { useNavigate } from "react-router-dom"

import { useGlautAuthInfo } from "@hooks/useGlautAuthInfo"

import Button from "@components/layouts/Button"

import Cloud from "@assets/cloud.svg"
import { MdOutlineFileUpload, MdAddCircle } from "react-icons/md"
import CreateWorkspacePopup from "./CreateWorkspacePopup"

export default function Workspaces() {
    // #region Hooks
    const navigate = useNavigate()
    const { currentOrg, submitOrgLogo, currentOrgIsAtLeastRole } = useGlautAuthInfo()
    // #endregion

    // #region State
    const [openCreateWorkspace, setOpenCreateWorkspace] = useState(false)
    // #endregion

    // #region Memo
    const canEdit = useMemo(
        () => currentOrgIsAtLeastRole("owner"),
        [currentOrgIsAtLeastRole]
    )
    // #endregion

    return (
        <div id="workspace-list" className="flex flex-col mt-24 gap-4 overflow-hidden pb-8">
            <div className="flex justify-between items-center mb-6">
                <div>
                    {currentOrg?.logo ? <img
                        src={currentOrg.logo}
                        alt="Org logo"
                        className="max-h-10 max-w-36"
                    /> : canEdit && <div>
                        <Button
                            className="gray"
                            label={getCopy(copy.org.uploadLogo)}
                            acceptFile=".png, .gif"
                            handleFileChange={submitOrgLogo}
                            icon={<MdOutlineFileUpload />}
                            reverse={true}
                        />
                    </div>}
                </div>
                {canEdit && <Button
                    className="tertiary"
                    label={getCopy(copy.org.addWorkspace)}
                    onClick={() => setOpenCreateWorkspace(true)}
                    icon={<MdAddCircle />}
                    reverse={true}
                />}
            </div>
            {currentOrg?.workspaces?.length ? (
                <div className="flex flex-col gap-4 overflow-y-auto overflow-x-hidden px-2">
                    {currentOrg?.workspaces?.map(w => (
                        <div
                            key={w._id}
                            onClick={() => navigate(`/w/${w._id}`)}
                            className="workspace clickable box long-bar"
                        >
                            <h3>{w.name}</h3>
                        </div>
                    ))}
                </div>
            ) : (
                <div className="w-full mt-36 flex justify-center items-center">
                    <img src={Cloud} className="h-8 m-2" />
                    <span className="text-glaut-stroke-button">{getCopy(copy.org.noWorkspaces)}</span>
                </div>
            )}
            {openCreateWorkspace && currentOrg && (
                <CreateWorkspacePopup setOpen={setOpenCreateWorkspace} org={currentOrg} />
            )}
        </div>
    )
}
