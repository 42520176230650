import { IAnalysis } from "@/@types/analysis"
import { IAnalysisGatewayCreateAnalysisRequest } from "@services/analyses/IAnalysisGateway"

interface IBuildAnalysisPayloadProps {
    analysis: Partial<IAnalysis>
    sources: string[]
    projectId: string
    questionTitle?: string
}

export function buildAnalysisPayload({
    analysis,
    sources,
    projectId,
    questionTitle
}: Readonly<IBuildAnalysisPayloadProps>): IAnalysisGatewayCreateAnalysisRequest | null {
    const actualGoal = analysis.goal ? analysis.goal : questionTitle

    if (!analysis.type || !actualGoal || actualGoal.length < 8) return null
    if (sources.length === 0) return null

    if (analysis.type === "interpretation" && !analysis.categories?.length) return null

    const categories = analysis.categories?.map(c => ({
        ...c,
        description: !c.description?.length ? null : c.description
    }))

    return {
        projectId,
        analysis: {
            type: analysis.type,
            title: analysis.title,
            goal: actualGoal,
            categories,
            sources,
            fixed_codebook: analysis.type === "thematic" ? analysis.fixed_codebook : undefined
        }
    } as IAnalysisGatewayCreateAnalysisRequest
}