import Loading from "@components/loading/Loading"
import { useInterviewService } from "@hooks/services/useInterviewService"
import { useGlautAuthInfo } from "@hooks/useGlautAuthInfo"
import usePrevProps from "@hooks/usePrevProps"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import { IProjectResults, ISegmentedProjectResults } from "src/@types/project"
import { IFilter } from "src/@types/question"
import { useAnalysisService } from "../../../../../hooks/services/useAnalysisService"
import AnalysisTabAnalysisColProvider from "../../contexts/AnalysisTabAnalysisColProvider"
import AnalysisTabCodeOccurrencesProvider from "../../contexts/AnalysisTabCodeOccurrencesProvider"
import { useAnalysisTab, useAnalysisTabDispatch } from "../../contexts/AnalysisTabProvider"
import AnalysisTabVerbatimsColProvider from "../../contexts/AnalysisTabVerbatimsColProvider"
import { useLegacyProject } from "../../contexts/ProjectProvider"
import AnalysisTabAnalysisCol from "../AnalysisTabAnalysisCol"
import AnalysisTabAnalysisCreationForm from "../AnalysisTabAnalysisCreationForm"
import AnalysisTabEmptyState from "../AnalysisTabEmptyState"
import AnalysisTabQuestionsCol from "../AnalysisTabQuestionsCol"
import AnalysisTabVerbatimsCol from "../AnalysisTabVerbatimsCol"
import { IQuestionStats } from "@/@types/processing/statistics"
import { formatFiltersForApi } from "./utils/format-filters-for-api"
import { packDuplicatedContainsFilters } from "./utils/pack-duplicated-contains-filters"

export default function AnalysisTabContent() {
    // #region Contexts
    const { project } = useLegacyProject()
    const {
        analysisFilters,
        questionFilters,
        selectedBreakdown,
        selectedCategory,
        selectedCategorySegmentId,
        newAnalysisType,
        editingAnalysis,
        selectedAnalysis,
        analyses,
        processingAnalysisIds,
        entries,
        selectedInterviewQuestionId,
        shouldForceRefreshEntries,
        shouldForceRefreshAnalysesStats,
        questionsStats
    } = useAnalysisTab()
    const analysisTabDispatch = useAnalysisTabDispatch()
    // #endregion

    // #region Services
    const analysisService = useAnalysisService()
    const interviewService = useInterviewService()
    // #endregion

    // #region States
    const [nEntries, setNEntries] = useState(0)
    const [page, setPage] = useState(0)
    const [nPages, setNPages] = useState(0)
    const [isLoadingVerbatims, setIsLoadingVerbatims] = useState(false)
    const [isLoadingAnalyses, setIsLoadingAnalyses] = useState(false)
    // #endregion

    // #region Other hooks

    const { projectId } = useParams()
    const { isLoggedIn } = useGlautAuthInfo()

    // #endregion

    // #region Memos
    const pageState = useMemo(() => {
        if (
            newAnalysisType === undefined &&
            editingAnalysis === undefined &&
            (selectedAnalysis || selectedInterviewQuestionId)
        ) return "render-analysis"

        if (newAnalysisType !== undefined || editingAnalysis !== undefined)
            return "creating-or-editing-analysis"

        if (
            analyses?.length === 1 &&
            selectedAnalysis === undefined &&
            editingAnalysis === undefined &&
            processingAnalysisIds[0] === analyses[0].id
        )
            return "processing-analysis"

        return "empty-page"
    }, [
        analyses,
        editingAnalysis,
        newAnalysisType,
        processingAnalysisIds,
        selectedAnalysis,
        selectedInterviewQuestionId
    ])

    const shouldShowVerbatims = useMemo(() => !selectedInterviewQuestionId,
        // if (selectedInterviewQuestionId)

        //     return !!interviewQuestions?.find(iq => iq.id === selectedInterviewQuestionId)?.probing_instructions

        // return true
        [selectedInterviewQuestionId]
    )
    // #endregion

    // #region Callbacks
    const buildQuestionFiltersForApi = useCallback(
        (includeBreakdown = true) => ([
            ...formatFiltersForApi(questionFilters),
            includeBreakdown && selectedBreakdown?.type === "iq" && selectedCategorySegmentId
                ? {
                    id: selectedBreakdown.id,
                    operator: "contains",
                    value: [selectedCategorySegmentId]
                }
                : null
        ] as IFilter[]).filter(i => i !== null).reduce(packDuplicatedContainsFilters, [] as IFilter[]),
        [selectedBreakdown, selectedCategorySegmentId, questionFilters]
    )
    const buildAnalysisFiltersForApi = useCallback(
        (includeSelectedCode = true, includeBreakdown = true) => [
            ...formatFiltersForApi(analysisFilters),
            (includeSelectedCode && selectedAnalysis?.id && selectedCategory?.category.id)
                ? ({
                    id: selectedAnalysis.id,
                    operator: selectedCategory?.category.id === "empty" ? "is" : "contains",
                    value: selectedCategory?.category.id === "empty" ? [] : [selectedCategory.category.id]
                } as IFilter)
                : null,
            includeBreakdown && selectedBreakdown?.type === "analysis" && selectedCategorySegmentId
                ? {
                    id: selectedBreakdown.id,
                    operator: "contains" as const,
                    value: [selectedCategorySegmentId]
                }
                : null
        ].filter(i => i !== null).reduce(packDuplicatedContainsFilters, [] as IFilter[]),
        [
            analysisFilters,
            selectedAnalysis?.id,
            selectedCategory?.category.id,
            selectedBreakdown,
            selectedCategorySegmentId
        ]
    )
    // #endregion

    // #region Prev props
    const prevAnalysisFiltersForApi = usePrevProps(buildAnalysisFiltersForApi())
    const prevQuestionFiltersForApi = usePrevProps(buildQuestionFiltersForApi())
    const prevSelectedBreakdown = usePrevProps(selectedBreakdown)
    const prevPage = usePrevProps(page)
    const shouldReFetchDataFor = useMemo(() => {
        const shouldReFetchAll =
            JSON.stringify(prevAnalysisFiltersForApi) !== JSON.stringify(buildAnalysisFiltersForApi()) ||
            JSON.stringify(prevQuestionFiltersForApi) !== JSON.stringify(buildQuestionFiltersForApi()) ||
            selectedBreakdown !== prevSelectedBreakdown ||
            prevPage !== page

        if (shouldReFetchAll) return "all"
        if (shouldForceRefreshAnalysesStats) return "analyses-stats"
        if (shouldForceRefreshEntries) return "entries"

        return null
    }, [
        buildAnalysisFiltersForApi,
        buildQuestionFiltersForApi,
        prevAnalysisFiltersForApi,
        prevQuestionFiltersForApi,
        selectedBreakdown,
        prevSelectedBreakdown,
        shouldForceRefreshEntries,
        shouldForceRefreshAnalysesStats,
        prevPage,
        page
    ])

    const prevSelectedCategory = usePrevProps(selectedCategory)
    const hasSelectedCategoryChanged = useMemo(
        () => JSON.stringify(selectedCategory) !== JSON.stringify(prevSelectedCategory),
        [selectedCategory, prevSelectedCategory]
    )
    // #endregion

    // #region Effects

    // onLoadingResetEntries
    useEffect(() => {
        analysisTabDispatch({ type: "clear-entries" })
    }, [analysisTabDispatch, projectId])

    // onChangeAnalysisResetPage
    useEffect(() => {
        setPage(0)
    }, [selectedAnalysis?.id])

    // onLoadAndNecessaryGetEntries
    useEffect(() => {
        const shouldReFetchEntries = shouldReFetchDataFor === "entries" || shouldReFetchDataFor === "all"
        if (!isLoggedIn || !projectId || (entries && !shouldReFetchEntries)) return

        const questionFilters = buildQuestionFiltersForApi(true)
        const analysisFilters = buildAnalysisFiltersForApi(true)

        setIsLoadingVerbatims(true)

        interviewService.getInterviews({
            projectId,
            page,
            analysisFilters: analysisFilters.length > 0 ? analysisFilters : undefined,
            questionFilters: questionFilters.length > 0 ? questionFilters : undefined
        }).then(entriesData => {
            analysisTabDispatch({ type: "set-entries", entries: entriesData.interviews })
            analysisTabDispatch({ type: "mark-entries-as-refreshed" })
            setNPages(entriesData.n_pages)
            setNEntries(entriesData.total_interviews)
        }).finally(() => {
            setIsLoadingVerbatims(false)
        })
    }, [
        isLoggedIn,
        projectId,
        page,
        entries,
        interviewService,
        shouldReFetchDataFor,
        analysisTabDispatch,
        buildAnalysisFiltersForApi,
        buildQuestionFiltersForApi
    ])

    // onLoadGetProjectAnalysesAndStats
    useEffect(() => {
        const shouldReFetchAnalyses = shouldReFetchDataFor === "analyses-stats" || shouldReFetchDataFor === "all"
        if (!project || !shouldReFetchAnalyses) return

        if (!hasSelectedCategoryChanged) // loading state only for the cases where the selected category does not change
            setIsLoadingAnalyses(true)

        const questionFilters = buildQuestionFiltersForApi(false)
        const analysisFilters = buildAnalysisFiltersForApi(false)

        const analysesPromise = analysisService.getAnalyses({ projectId: project._id })
        const analysesStatsPromise = analysisService.getAnalysesStats({
            projectId: project._id,
            questionFilters: questionFilters.length > 0 ? questionFilters : undefined,
            analysisFilters: analysisFilters.length > 0 ? analysisFilters : undefined,
            segmentByQuestionId: selectedBreakdown?.type === "iq" ? selectedBreakdown.id : undefined,
            segmentByAnalysisId: selectedBreakdown?.type === "analysis" ? selectedBreakdown.id : undefined
        })

        Promise.all([analysesPromise, analysesStatsPromise]).then(([analysesResponse, analysesStatsResponse]) => {
            analysisTabDispatch({ type: "set-analyses", analyses: analysesResponse.analyses })
            analysisTabDispatch({ type: "mark-analyses-stats-as-refreshed" })
            if (selectedBreakdown) {
                analysisTabDispatch({
                    type: "set-breakdown-stats",
                    breakdownStats: analysesStatsResponse as ISegmentedProjectResults[]
                })
            } else {
                analysisTabDispatch({
                    type: "set-analyses-stats",
                    analysesStats: (analysesStatsResponse as IProjectResults).analysis_results
                })

                analysisTabDispatch({
                    type: "set-questions-stats",
                    questionsStats: (analysesStatsResponse as IProjectResults<IQuestionStats>).question_results
                })
            }

            analysesResponse.analyses.forEach(analysis => {
                if (analysis.completion !== null && analysis.completion !== 1)
                    analysisTabDispatch({ type: "set-analysis-as-processing", analysisId: analysis.id })
                else
                    analysisTabDispatch({ type: "unset-analysis-as-processing", analysisId: analysis.id })
            })
        }).finally(() => {
            setIsLoadingAnalyses(false)
        })
    }, [
        analysisService,
        analysisTabDispatch,
        project,
        buildAnalysisFiltersForApi,
        buildQuestionFiltersForApi,
        shouldReFetchDataFor,
        questionsStats,
        selectedBreakdown,
        hasSelectedCategoryChanged
    ])

    // #endregion

    if (!project)
        return (
            <div className="flex flex-col justify-center h-full">
                <Loading />
            </div>
        )

    return (
        <div className="coding flex flex-row overflow-hidden h-full">
            <div className="w-[20vw] box-border">
                <AnalysisTabQuestionsCol />
            </div>
            <div className="flex justify-center w-[80vw] h-full">
                <div className={`flex flex-row justify-center gap-6 pt-[3em] box-border mx-5 h-full flex-1
                        2xl:mx-20 xl:mx-10
                    `}>
                    {pageState === "render-analysis" && (
                        <AnalysisTabCodeOccurrencesProvider>
                            <div className="flex-1 h-full overflow-hidden max-w-[50%]">
                                <AnalysisTabAnalysisColProvider>
                                    <AnalysisTabAnalysisCol isLoadingAnalyses={isLoadingAnalyses} />
                                </AnalysisTabAnalysisColProvider>
                            </div>
                            {shouldShowVerbatims && (
                                <div className="flex-1 h-full overflow-hidden box-border">
                                    <AnalysisTabVerbatimsColProvider>
                                        <AnalysisTabVerbatimsCol
                                            nEntries={nEntries}
                                            page={page}
                                            setPage={setPage}
                                            nPages={nPages}
                                            isLoadingVerbatims={isLoadingVerbatims}
                                        />
                                    </AnalysisTabVerbatimsColProvider>
                                </div>
                            )}
                        </AnalysisTabCodeOccurrencesProvider>
                    )}
                    {pageState === "creating-or-editing-analysis" && (
                        <AnalysisTabAnalysisCreationForm />
                    )}
                    {pageState === "empty-page" && (
                        <AnalysisTabEmptyState />
                    )}
                    {pageState === "processing-analysis" && (
                        <AnalysisTabEmptyState state="processing-first" />
                    )}
                </div>
            </div>
        </div>
    )
}

