import { IScreeningQuestion } from "@/@types/interview-question"
import { createContext, useContext, useReducer } from "react"

// #region Type definitions
interface OutlineTabScreeningAndRedirectsProviderProps {
    children: React.ReactNode
}

interface OutlineTabScreeningAndRedirectsContextState {
    screeningQuestionToBuild?: Partial<IScreeningQuestion>
}

type OutlineTabScreeningAndRedirectsContextAction = {
    type: "set-screening-question-to-build"
    screeningQuestionToBuild: Partial<IScreeningQuestion>
} | {
    type: "clear-screening-question-to-build"
}
// #endregion

// #region Context definitions
const OutlineTabScreeningAndRedirectsContext = createContext(
    {} as OutlineTabScreeningAndRedirectsContextState
)
const OutlineTabScreeningAndRedirectsContextDispatch = createContext(
    {} as React.Dispatch<OutlineTabScreeningAndRedirectsContextAction>
)
// #endregion

// #region Hook definitions
export function useOutlineTabScreeningAndRedirects() {
    return useContext(OutlineTabScreeningAndRedirectsContext)
}
export function useOutlineTabScreeningAndRedirectsDispatch() {
    return useContext(OutlineTabScreeningAndRedirectsContextDispatch)
}
// #endregion

// #region Provider definition
export default function OutlineTabScreeningAndRedirectsProvider({
    children
}: Readonly<OutlineTabScreeningAndRedirectsProviderProps>) {
    const initialState: OutlineTabScreeningAndRedirectsContextState = {}

    const [state, dispatch] = useReducer(OutlineTabScreeningAndRedirectsReducer, initialState)

    return (
        <OutlineTabScreeningAndRedirectsContext.Provider value={state}>
            <OutlineTabScreeningAndRedirectsContextDispatch.Provider value={dispatch}>
                {children}
            </OutlineTabScreeningAndRedirectsContextDispatch.Provider>
        </OutlineTabScreeningAndRedirectsContext.Provider>
    )
}
// #endregion

// #region Reducer definition
function OutlineTabScreeningAndRedirectsReducer(
    state: OutlineTabScreeningAndRedirectsContextState,
    action: OutlineTabScreeningAndRedirectsContextAction
): OutlineTabScreeningAndRedirectsContextState {
    switch (action.type) {
        case "set-screening-question-to-build": {
            return {
                ...state,
                screeningQuestionToBuild: action.screeningQuestionToBuild
            }
        }
        case "clear-screening-question-to-build": {
            return {
                ...state,
                screeningQuestionToBuild: undefined
            }
        }
        default: {
            return state
        }
    }
}
// #endregion