export const glautAssetComponentCopy = {
    image: {
        uploadAnImage: {
            en: "Upload an image"
        },
        imageRequirements: {
            en: "Image requirements"
        },
        imageMustEitherBePngOrGif: {
            en: "Image must be either a PNG or GIF format. Note: GIF images are not animated"
        },
        theMaximumFileSizeIs: {
            en: "The maximum file size is 30 KB"
        },
        theImageOnInterviewIsDisplayed: {
            en: "The image on interview is displayed at exactly 320 x 132 pixels; therefore, the recommended image size that you upload is exactly 320 x 132 pixels"
        }
    },
    video: {
        insertAVideo: {
            en: "Insert a video"
        },
        easilyIntegrateVideosFromYt: {
            en: "Easily integrate videos from YouTube"
        }
    },
    urlParameter: {
        urlParameter: {
            en: "URL parameter"
        },
        defineAUrlParameterForThisQuestion: {
            en: "Define a URL parameter for this question"
        },
        parameterUnderscoreName: {
            en: "parameter_name"
        }
    }
}