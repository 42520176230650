export const dataTab = {
    dataTab: {
        keyInterviewData: {
            en: "Key interview data",
            it: "Dati chiave dell'intervista",
            es: "Datos clave de la entrevista",
            fr: "Données clés de l'entrevue",
            de: "Schlüsseldaten der Interviews"
        }
    }
}