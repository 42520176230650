import GlautChipComponent from "@components/inputs/GlautChipComponent"
import { glautAssetComponentTypes, IGlautAssetComponentType } from "../../constants/types"
import { useGlautAssetComponent, useGlautAssetComponentDispatch } from "../../contexts/GlautAssetComponentProvider"
import { useCallback } from "react"
import { glautAssetComponentCopy } from "../../utils/copy"
import { getCopy } from "@utils/Copy"
import ReactDOMServer from "react-dom/server"

export default function GlautAssetComponentOptions() {
    // #region Contexts
    const { selectedOptions, disabledType, disabled, onChange } = useGlautAssetComponent()
    const glautAssetComponentDispatch = useGlautAssetComponentDispatch()
    // #endregion

    // #region Callbacks
    const label = useCallback((type: IGlautAssetComponentType) => {
        switch (type) {
            case "image": return `🖼️ ${getCopy(glautAssetComponentCopy.image.uploadAnImage) ?? ""}`
            case "video": return `🎬 ${getCopy(glautAssetComponentCopy.video.insertAVideo) ?? ""}`
            case "url-parameter": return getCopy(glautAssetComponentCopy.urlParameter.urlParameter) ?? ""
            default: return ""
        }
    }, [])

    const handleSelectOption = useCallback((type: IGlautAssetComponentType) => {
        onChange[type]?.(null)
        glautAssetComponentDispatch({ type: "add-selected-option", assetType: type })
    }, [glautAssetComponentDispatch, onChange])
    // #endregion

    // #region Element callbacks
    const tooltipContent = useCallback((type: IGlautAssetComponentType) => {
        if (type !== "image") return

        return ReactDOMServer.renderToStaticMarkup(
            <div className="flex flex-col items-start p-[0.75em] rounded-[0.25em] max-w-[400px]">
                <p className="text-[13.33px] font-medium">
                    {getCopy(glautAssetComponentCopy.image.imageRequirements)}
                </p>
                <ul className="text-start list-disc list-inside">
                    <li>{getCopy(glautAssetComponentCopy.image.imageMustEitherBePngOrGif)}</li>
                    <li>{getCopy(glautAssetComponentCopy.image.theMaximumFileSizeIs)}</li>
                    <li>{getCopy(glautAssetComponentCopy.image.theImageOnInterviewIsDisplayed)}</li>
                </ul>
            </div>
        )
    }, [])
    // #endregion

    return (
        <div className="flex justify-between">
            <div className="flex gap-[1.25em]">
                {glautAssetComponentTypes.filter(t => t !== "url-parameter").map(type => (
                    <GlautChipComponent
                        key={type}
                        onClick={() => { handleSelectOption(type) }}
                        label={label(type)}
                        tooltipContent={tooltipContent(type)}
                        isHighlighted={selectedOptions.includes(type)}
                        disabled={disabled || disabledType[type]}
                    />
                ))}
            </div>
            <GlautChipComponent
                key={"url-parameter"}
                onClick={() => { handleSelectOption("url-parameter") }}
                label={label("url-parameter")}
                tooltipContent={tooltipContent("url-parameter")}
                isHighlighted={selectedOptions.includes("url-parameter")}
                disabled={disabled || disabledType["url-parameter"]}
            />
        </div>
    )
}