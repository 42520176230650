import { AxiosInstance } from "axios"
import IWorkspaceGateway, { 
    IWorkspaceGatewayDeleteRequest,
    IWorkspaceGatewayGetWorkspaceRequest,
    IWorkspaceGatewayGetUsersRequest,
    IWorkspaceGatewayInviteUserRequest,
    IWorkspaceGatewayChangeUserRoleRequest,
    IWorkspaceGatewayRemoveUserRequest
} from "../IWorkspaceGateway"
import { IWorkspaceWithProjects } from "src/@types/workspace"
import { IWorkspaceUser } from "src/@types/user"

interface IWorkspacesApiServiceProps {
    api: AxiosInstance
}

export default class WorkspacesApiService implements IWorkspaceGateway {
    private props: IWorkspacesApiServiceProps

    constructor(props: IWorkspacesApiServiceProps) {
        this.props = props
    }

    public async getWorkspace(
        request: IWorkspaceGatewayGetWorkspaceRequest
    ): Promise<IWorkspaceWithProjects> {
        const { workspaceId } = request

        return await this.props.api
            .get<IWorkspaceWithProjects>(
                `workspaces/${workspaceId}`
            )
            .then(res => res.data)
            .catch(e => {
                console.log(e)
                return Promise.reject(e)
            })
    }

    public async getUsers(request: IWorkspaceGatewayGetUsersRequest): Promise<IWorkspaceUser[]> {
        const { workspaceId } = request

        return await this.props.api
            .get<IWorkspaceUser[]>(
                `workspaces/${workspaceId}/users`
            )
            .then(res => res.data)
            .catch(e => {
                console.log(e)
                return Promise.reject(e)
            })
    }

    public async deleteWorkspace(request: IWorkspaceGatewayDeleteRequest): Promise<null> {
        const { workspaceId } = request

        return await this.props.api
            .delete<void>(`workspaces/${workspaceId}`)
            .then(() => null)
    }

    public async inviteUser(request: IWorkspaceGatewayInviteUserRequest): Promise<IWorkspaceUser> {
        const { workspaceId, email, role } = request

        return this.props.api
            .put<IWorkspaceUser>(`workspaces/${workspaceId}/invite`, {email, role})
            .then(response => response.data)
            .catch(e => {
                console.log(e)
                return Promise.reject(e)
            })
    }

    public async changeUserRole(request: IWorkspaceGatewayChangeUserRoleRequest): Promise<IWorkspaceUser> {
        const { workspaceId, email, role } = request

        return this.props.api
            .put<IWorkspaceUser>(
                `workspaces/${workspaceId}/change_role?email=${encodeURIComponent(email)}&role=${role}`
            )
            .then(response => response.data)
            .catch(e => {
                console.log(e)
                return Promise.reject(e)
            })
    }

    public async removeUser(request: IWorkspaceGatewayRemoveUserRequest): Promise<null> {
        const { workspaceId, propelauthUserId } = request

        return this.props.api
            .put<IWorkspaceUser>(`workspaces/${workspaceId}/remove?user_id=${propelauthUserId}`)
            .then(() => null)
            .catch(e => {
                console.log(e)
                return Promise.reject(e)
            })
    }
}