import { useState } from "react"

import { UserProvider } from "@hooks/useGlautAuthInfo"
import WorkspaceContent from "./WorkspaceContent"

export default function Workspace() {
    const [currentTab, setCurrentTab] = useState(0)

    return (
        <UserProvider>
            <WorkspaceContent currentTab={currentTab} setCurrentTab={setCurrentTab} />
        </UserProvider>
    )
}
