import { isPromise } from "./is-promise"

/**
 * Runs a callback after a possible promise.
 */
export function continueWith<T>(
    possiblePromise: T | Promise<T> | undefined,
    callback: (...args: unknown[]) => unknown
) {
    if (!isPromise(possiblePromise)) {
        callback()
        return
    }

    possiblePromise.then(() => {
        callback()
    })
}