import { MdOutlineSms } from "react-icons/md"
import GlautInfoIcon from "@assets/glaut-info-icon.svg"
import { getCopy, copy } from "@utils/Copy"

interface IReportsTabEmptyProps {
    customMessage?: string
}

export default function ReportsTabEmpty({
    customMessage
}: Readonly<IReportsTabEmptyProps>) {
    return (
        <div className="flex-1 flex flex-col w-full h-96 justify-center items-center">
            <div className="flex bg-glaut-cards rounded-full w-28 h-28 justify-center items-center">
                <MdOutlineSms
                    className="text-white overflow-visible -rotate-[11deg]"
                    style={{ height: "82px", width: "82px" }} />
            </div>
            <div className="flex flex-row gap-2 mt-5 text-glaut-stroke-button">
                <img src={GlautInfoIcon} alt="Info" /> {customMessage ?? getCopy(copy.reports.noReportWasCreatedYet)}
            </div>
        </div>
    )
}