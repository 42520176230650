import { createContext, useContext, useReducer } from "react"

// #region Type definitions
interface AnalysisTabVerbatimsColProviderProps {
    children: React.ReactNode
}

interface AnalysisTabVerbatimsColContextState {
    verbatimsListElement?: HTMLDivElement
}

type AnalysisTabVerbatimsColContextAction = {
    type: "set-verbatims-list-element"
    verbatimsListElement: HTMLDivElement
}
// #endregion

// #region Context definitions
const AnalysisTabVerbatimsColContext = createContext(
    {} as AnalysisTabVerbatimsColContextState
)
const AnalysisTabVerbatimsColContextDispatch = createContext(
    {} as React.Dispatch<AnalysisTabVerbatimsColContextAction>
)
// #endregion

// #region Hook definitions
export function useAnalysisTabVerbatimsCol() {
    return useContext(AnalysisTabVerbatimsColContext)
}
export function useAnalysisTabVerbatimsColDispatch() {
    return useContext(AnalysisTabVerbatimsColContextDispatch)
}
// #endregion

// #region Provider definition
export default function AnalysisTabVerbatimsColProvider({
    children
}: Readonly<AnalysisTabVerbatimsColProviderProps>) {
    const initialState: AnalysisTabVerbatimsColContextState = {}

    const [state, dispatch] = useReducer(AnalysisTabVerbatimsColReducer, initialState)

    return (
        <AnalysisTabVerbatimsColContext.Provider value={state}>
            <AnalysisTabVerbatimsColContextDispatch.Provider value={dispatch}>
                {children}
            </AnalysisTabVerbatimsColContextDispatch.Provider>
        </AnalysisTabVerbatimsColContext.Provider>
    )
}
// #endregion

// #region Reducer definition
function AnalysisTabVerbatimsColReducer(
    state: AnalysisTabVerbatimsColContextState,
    action: AnalysisTabVerbatimsColContextAction
): AnalysisTabVerbatimsColContextState {
    switch (action.type) {
        case "set-verbatims-list-element": {
            if (state.verbatimsListElement === action.verbatimsListElement) return state

            return {
                ...state,
                verbatimsListElement: action.verbatimsListElement
            }
        }
        default: {
            return state
        }
    }
}
// #endregion