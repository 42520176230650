import { DragDropContext, OnDragEndResponder } from "react-beautiful-dnd"
import ListItem from "../OutlineTabContentScreeningAndRedirectsScreeningQuestionsListItem"
import { Drop } from "@components/menus/DragAndDrop"
import { useOutlineTab } from "../../contexts/OutlineTabProvider"
import { useLegacyProject, useProject, useProjectDispatch } from "../../contexts/ProjectProvider"
import { useProjectService } from "@hooks/services/useProjectService"

export default function OutlineTabContentScreeningAndRedirectsScreeningQuestionsList() {
    // #region Contexts
    const { project } = useLegacyProject()
    const { screeningQuestions, projectQuestions } = useProject()
    const projectDispatch = useProjectDispatch()
    const { canEdit } = useOutlineTab()
    // #endregion

    // #region Services
    const projectService = useProjectService()
    // #endregion

    // #region Callbacks
    const onDragEnd: OnDragEndResponder = result => {
        if (!project) return

        const { destination, source } = result

        // cancelled drag / dragged outside droppable area
        if (!destination) return

        // source === destination
        if (destination.index === source.index) return

        const question = projectQuestions?.find(q => q.index === source.index)
        if (!question) return

        const index = destination.index
        const questionId = question.id

        projectDispatch({ type: "set-interview-question-index", interviewQuestionId: questionId, index })

        projectService.setInterviewQuestionOrder({ projectId: project._id, index, questionId }).catch(() => {
            projectDispatch({
                type: "set-interview-question-index",
                interviewQuestionId: questionId,
                index: source.index
            })
        })
    }
    // #endregion

    return (
        <div className="flex-1 flex flex-col gap-[0.75rem]">
            <DragDropContext onDragEnd={onDragEnd}>
                <Drop id="root" type="question" disabled={!canEdit}>
                    <div className="flex flex-col gap-[0.5em]">
                        {(screeningQuestions ?? []).map((sq, sqIdx) => (
                            <ListItem
                                key={sq.id}
                                screeningQuestion={sq}
                                index={sqIdx}
                            />
                        ))}
                    </div>
                </Drop>
            </DragDropContext>
        </div>
    )
}