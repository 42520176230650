import OutlineTabContentIqsQuestionForm from "../OutlineTabContentIqsQuestionForm"
import OutlineTabContentIqsQuestionList from "../OutlineTabContentIqsQuestionList"
import OutlineTabContentIqsQuestionSyntheticAnswers from "../OutlineTabContentIqsQuestionSyntheticAnswers"

export default function OutlineTabContentIqsContent() {
    return (
        <div className="flex gap-[1.5em] h-full">
            <OutlineTabContentIqsQuestionList />
            <OutlineTabContentIqsQuestionForm />
            <OutlineTabContentIqsQuestionSyntheticAnswers />
        </div>
    )
}