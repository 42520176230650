import { IProject } from "@/@types/project"
import FaTagsOutline from "@assets/fa-tags-outline.svg"
import { copy, getCopy } from "@utils/Copy"
import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { MdOutlineChat, MdOutlineFilterAlt, MdOutlineLayers } from "react-icons/md"
import { ProjectContext } from "../../../AutoSaveProject"
import { useAnalysisTabAnalysisCol } from "../../contexts/AnalysisTabAnalysisColProvider"
import { useAnalysisTab } from "../../contexts/AnalysisTabProvider"
import { useProject } from "../../contexts/ProjectProvider"
import AnalysisTabAnalysisColActionsBoxItem from "../AnalysisTabAnalysisColActionsBoxItem"
import AnalysisTabAnalysisColBreakdownModal from "../AnalysisTabAnalysisColBreakdownModal"
import AnalysisTabAnalysisColCodeOccurrencesModeToggle from "../AnalysisTabAnalysisColCodeOccurrencesModeToggle"
import AnalysisTabAnalysisColFilteringModal from "../AnalysisTabAnalysisColFilteringModal"
import AnalysisTabAnalysisColReviewCodeBookModal from "../AnalysisTabAnalysisColReviewCodeBookModal"

export default function AnalysisTabAnalysisColActionsBox() {
    // #region Contexts
    const { project } = useContext(ProjectContext) as { project: IProject | null }
    const { numberOfEntries } = useProject()
    const {
        analysisFilters,
        questionFilters,
        selectedAnalysis,
        selectedBreakdown,
        analysesStats,
        selectedInterviewQuestionId,
        questionsStats
    } = useAnalysisTab()
    const { categoryBeingEdited } = useAnalysisTabAnalysisCol()
    // #endregion

    // #region States
    const [currentOpenModal, setCurrentOpenModal] = useState<"review-codebook" | "filtering" | "breakdown">()
    // #endregion

    // #region Memos
    const shouldShowReviewCodebookOption = useMemo(
        () => ["thematic", "entity"].includes(selectedAnalysis?.type ?? ""),
        [selectedAnalysis?.type]
    )
    const shouldShowFilteringModal = useMemo(
        () => project?.source === "interview",
        [project]
    )
    const shouldShowBreakdownModal = useMemo(
        () => project?.source === "interview",
        [project]
    )
    const isReviewCodebookModalButtonDisabled = useMemo(
        () => project?.source === "interview" && !["ended"].includes(project?.status ?? ""),
        [project]
    )

    const totalResponses = useMemo(() => {
        if (selectedAnalysis)
            return (analysesStats[selectedAnalysis.id]?.interview_count ?? numberOfEntries)

        if (selectedInterviewQuestionId) {
            const stats = questionsStats[selectedInterviewQuestionId]
            if (!stats || Array.isArray(stats)) return numberOfEntries

            return stats.interview_count ?? numberOfEntries
        }

        return numberOfEntries
    }, [analysesStats, numberOfEntries, questionsStats, selectedAnalysis, selectedInterviewQuestionId])
    // #endregion

    // #region Callbacks
    const handleCloseModal = useCallback(() => {
        setCurrentOpenModal(undefined)
    }, [])
    // #endregion

    // #region Effects

    // onEditingCategoryOpenCodebookModal
    useEffect(() => {
        if (categoryBeingEdited === undefined || categoryBeingEdited.type !== "theme") return
        setCurrentOpenModal("review-codebook")
    }, [categoryBeingEdited])

    // #endregion

    return (
        <div className={`flex flex-row justify-between rounded-[0.5em] border-1 items-center px-[1em]
            bg-glaut-off-white border-glaut-stroke-glaut
        `}>
            <div className="flex flex-row gap-[0.5em] py-[1em]">
                <MdOutlineChat className="h-[1.125em] w-[1.125em] text-glaut-grey" />
                <p className="text-[11.11px] text-glaut-text-midnight">
                    {`${totalResponses} ${getCopy(copy.coding.totalResponses)}`}
                </p>
            </div>
            <div className="flex flex-row gap-[0.625em] h-full items-center">
                {shouldShowReviewCodebookOption && (
                    <AnalysisTabAnalysisColActionsBoxItem
                        onClick={() => { setCurrentOpenModal("review-codebook") }}
                        tooltipContent={getCopy(copy.coding.reviewCodebook) ?? ""}
                        disabled={isReviewCodebookModalButtonDisabled}
                    >
                        <img src={FaTagsOutline} alt="review-codebook" />
                    </AnalysisTabAnalysisColActionsBoxItem>
                )}
                {shouldShowBreakdownModal && (
                    <AnalysisTabAnalysisColActionsBoxItem
                        onClick={() => { setCurrentOpenModal("breakdown") }}
                        isApplied={selectedBreakdown !== undefined}
                        tooltipContent={getCopy(copy.coding.breakdownBy) ?? ""}
                    >
                        <MdOutlineLayers />
                    </AnalysisTabAnalysisColActionsBoxItem>
                )}
                {shouldShowFilteringModal && (
                    <AnalysisTabAnalysisColActionsBoxItem
                        onClick={() => { setCurrentOpenModal("filtering") }}
                        isApplied={Object.values({ ...analysisFilters, ...questionFilters }).length > 0}
                        tooltipContent={getCopy(copy.coding.filterBy) ?? ""}
                    >
                        <MdOutlineFilterAlt />
                    </AnalysisTabAnalysisColActionsBoxItem>
                )}
                <div className={`flex flex-col justify-center pl-[1em] border-s-1 h-full rounded-none
                    border-s-glaut-stroke-glaut    
                `}>
                    <AnalysisTabAnalysisColCodeOccurrencesModeToggle />
                </div>
            </div>

            {shouldShowReviewCodebookOption && selectedAnalysis !== undefined && ( // selectedAnalysis needed internally
                <AnalysisTabAnalysisColReviewCodeBookModal
                    isOpen={currentOpenModal === "review-codebook"}
                    onClose={handleCloseModal}
                />
            )}
            {shouldShowBreakdownModal && (
                <AnalysisTabAnalysisColBreakdownModal
                    isOpen={currentOpenModal === "breakdown"}
                    onClose={handleCloseModal}
                />
            )}
            {shouldShowFilteringModal && (
                <AnalysisTabAnalysisColFilteringModal
                    isOpen={currentOpenModal === "filtering"}
                    onClose={handleCloseModal}
                />
            )}
        </div>
    )
}