import GlautInputText from "@components/inputs/GlautInputText"
import { getCopy } from "@utils/Copy"
import { continueWith } from "@utils/promises/continue-with"
import { ChangeEventHandler, useCallback, useMemo } from "react"
import { MdClose } from "react-icons/md"
import { useGlautAssetComponent, useGlautAssetComponentDispatch } from "../../contexts/GlautAssetComponentProvider"
import { glautAssetComponentCopy } from "../../utils/copy"

export default function GlautAssetComponentSelectedOptionUrlParameter() {
    // #region Contexts
    const { disabled, disabledType, onChange, value } = useGlautAssetComponent()
    const glautAssetComponentDispatch = useGlautAssetComponentDispatch()
    // #endregion

    // #region Memos
    const isOptionDisabled = useMemo(() => disabled || disabledType["url-parameter"], [disabled, disabledType])
    // #endregion

    // #region Callbacks
    const handleChangeValue: ChangeEventHandler<HTMLInputElement> = useCallback(ev => {
        const url = ev.target.value.replace(" ", "")
        onChange["url-parameter"]?.(url || null)
    }, [onChange])

    const handleCloseSelectedOption = useCallback(() => {
        const onRemoveResponse = onChange["url-parameter"]?.(undefined)

        continueWith(onRemoveResponse, () => {
            glautAssetComponentDispatch({ type: "remove-selected-option", assetType: "url-parameter" })
        })
    }, [glautAssetComponentDispatch, onChange])
    // #endregion

    return (
        <div className="flex flex-col gap-[0.5em] p-[0.75em] bg-glaut-cards rounded-[0.5em]">
            <div className="flex justify-between">
                <p className="text-[13.33px] text-glaut-dark-grey">
                    {getCopy(glautAssetComponentCopy.urlParameter.defineAUrlParameterForThisQuestion)}
                </p>
                <button
                    className="text-base p-0 m-0 rounded-none shadow-none border-none bg-transparent"
                    onClick={handleCloseSelectedOption}
                    disabled={isOptionDisabled}
                >
                    <MdClose className="h-[1em] w-[1em] text-glaut-dummie-color2" />
                </button>
            </div>
            <GlautInputText
                placeholder={getCopy(glautAssetComponentCopy.urlParameter.parameterUnderscoreName) ?? ""}
                onChange={handleChangeValue}
                value={value["url-parameter"] ?? ""}
                disabled={isOptionDisabled}
            />
        </div>
    )
}