import GlautButtonPrimary from "@components/Buttons/GlautButtonPrimary"
import GlautButtonSecondary from "@components/Buttons/GlautButtonSecondary"
import Sprinkles from "@components/Icons/Sprinkles"
import GlautIcon from "@components/Icons/GlautIcon"
import { copy, getCopy } from "@utils/Copy"
import { questionTypeDetails } from "@utils/project/interview-questions"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useOutlineTabIqs, useOutlineTabIqsDispatch } from "../../contexts/OutlineTabIqsProvider"
import { useOutlineTab } from "../../contexts/OutlineTabProvider"
import { useLegacyProject, useProjectDispatch } from "../../contexts/ProjectProvider"
import { useProjectService } from "@hooks/services/useProjectService"
import { useAnalysisService } from "@hooks/services/useAnalysisService"
import { buildIqPayload } from "./utils/build-iq-payload"
import { buildAnalysisPayload } from "./utils/build-analysis-payload"
import Loading from "@components/loading/Loading"
import { IAnalysisGatewayUpdateAnalysisRequest } from "@services/analyses/IAnalysisGateway"
import { MdCheck } from "react-icons/md"
import { useToastDispatch } from "@contexts/ToastProvider"
import ErrorLevel from "@utils/ErrorLevel"
import usePrevProps from "@hooks/usePrevProps"
import { IInterviewQuestion } from "@/@types/interview-question"
import { namedColors } from "@utils/Variables"
import { glautTooltipId } from "@components/layouts/GlautTooltip/constants/id"

export default function OutlineTabContentIqsQuestionFormHeader() {
    // #region Contexts
    const { project, lang } = useLegacyProject()
    const projectDispatch = useProjectDispatch()
    const { canEdit } = useOutlineTab()
    const {
        originalIqToBeBuilt,
        iqToBeBuilt,
        isEditing,
        isSimulateQuestionAllowed,
        isSimulateQuestionSidebarOpen,
        shouldGenerateSyntheticAnswers,
        maxAmountOfSyntheticAnswers
    } = useOutlineTabIqs()
    const outlineTabIqsDispatch = useOutlineTabIqsDispatch()
    // #endregion

    // #region Prev props
    const prevIqId = usePrevProps(iqToBeBuilt?.id)
    // #endregion

    // #region Toast
    const toastDispatch = useToastDispatch()
    // #endregion

    // #region Services
    const projectService = useProjectService()
    const analysisService = useAnalysisService()
    // #endregion

    // #region States
    const [isLoading, setIsLoading] = useState(false)
    const [hasSaved, setHasSaved] = useState(false)
    // #endregion

    // #region Memos
    const iqPayload = useMemo(() => {
        if (!project || !iqToBeBuilt || (isEditing && !iqToBeBuilt.id)) return null
        const projectId = project._id

        return buildIqPayload(iqToBeBuilt, lang, projectId, originalIqToBeBuilt)
    }, [iqToBeBuilt, isEditing, lang, project, originalIqToBeBuilt])
    const areAnalysesValid = useMemo(() => {
        if (!project || !iqToBeBuilt) return false
        return (iqToBeBuilt.analyses ?? []).every(
            a => buildAnalysisPayload({
                analysis: a,
                sources: [""],
                projectId: project._id,
                questionTitle: iqToBeBuilt.content?.[lang] ?? undefined
            }) !== null
        )
    }, [iqToBeBuilt, lang, project])
    const canSaveQuestion = useMemo(
        () => canEdit && (iqToBeBuilt?.content?.[lang]?.length ?? 0) >= 3 && !!iqPayload && areAnalysesValid,
        [canEdit, iqToBeBuilt?.content, lang, iqPayload, areAnalysesValid]
    )
    const shouldCreateExAnteAnalyses = useMemo(
        () => iqToBeBuilt?.type === "open" || (
            iqToBeBuilt?.probing_instructions !== null && iqToBeBuilt?.probing_instructions !== undefined
        ),
        [iqToBeBuilt?.type, iqToBeBuilt?.probing_instructions]
    )
    const isSimulateButtonShown = useMemo(
        () => iqToBeBuilt && iqToBeBuilt.type !== "asset" && (iqToBeBuilt.type === "open" || (
            iqToBeBuilt.probing_instructions !== null && iqToBeBuilt.probing_instructions !== undefined
        )),
        [iqToBeBuilt]
    )
    const isSimulateButtonDisabled = useMemo(
        () => project?.source !== "interview"
            || (
                ["select", "nps", "scale"].includes(iqToBeBuilt?.type ?? "")
                && iqToBeBuilt?.probing_instructions === null
            )
            || !isSimulateQuestionAllowed
            || shouldGenerateSyntheticAnswers
            || JSON.stringify(originalIqToBeBuilt) !== JSON.stringify(iqToBeBuilt)
            || (iqToBeBuilt?.syntheticAnswers?.length ?? 0) >= maxAmountOfSyntheticAnswers,
        [
            project?.source,
            iqToBeBuilt,
            isSimulateQuestionAllowed,
            shouldGenerateSyntheticAnswers,
            originalIqToBeBuilt,
            maxAmountOfSyntheticAnswers
        ]
    )
    // #endregion

    // #region Callbacks
    const updateOrAddIqToContext = useCallback((iq: IInterviewQuestion) => {
        if (isEditing)
            projectDispatch({ type: "update-interview-question", interviewQuestion: iq })
        else
            projectDispatch({ type: "add-interview-question", interviewQuestion: iq })

        outlineTabIqsDispatch({ type: "set-iq-to-be-built", iq, isEditing: true })
        if (isEditing) outlineTabIqsDispatch({ type: "filter-old-synth-answers" })
    }, [projectDispatch, outlineTabIqsDispatch, isEditing])

    const handleSaveQuestion = useCallback(() => {
        if (!project || !iqToBeBuilt || (isEditing && !iqToBeBuilt.id) || iqPayload === null) return

        const projectId = project._id

        setIsLoading(true)

        const iqPromise = isEditing
            ? projectService.updateInterviewQuestion({ ...iqPayload, questionId: iqToBeBuilt.id! })
            : projectService.createInterviewQuestion(iqPayload)

        iqPromise.then(responseIq => {
            if (!shouldCreateExAnteAnalyses) {
                iqToBeBuilt.analyses?.filter(a => !!a.id).map(async analysis => analysisService.deleteAnalysis({
                    projectId,
                    analysisId: analysis.id!
                }))

                // In meanwhile analyses are handled, updates IQ locally
                updateOrAddIqToContext(responseIq)
                return []
            }

            // Force a single ex-ante for non-open questions
            const analyses = iqToBeBuilt.type === "open"
                ? (iqToBeBuilt.analyses ?? [])
                : [iqToBeBuilt.analyses?.[0] ?? {
                    type: "thematic",
                    sources: [responseIq.id],
                    projectId,
                    goal: iqToBeBuilt.content?.[lang] ?? undefined,
                    isEditing: false
                }].filter(a => !!a)

            const analysisEditionPromises = analyses
                .filter(a => a.isEditing && a.id)
                .map(async analysis => analysisService.updateAnalysis({
                    projectId,
                    analysis: {
                        ...analysis,
                        goal: !analysis.goal?.length ? responseIq.content[lang] : analysis.goal
                    } as IAnalysisGatewayUpdateAnalysisRequest["analysis"] // typing workaround
                }))

            const analysisCreationPromises = analyses
                .filter(a => !a.isEditing)
                .map(analysis => buildAnalysisPayload({
                    analysis,
                    sources: [responseIq.id],
                    projectId,
                    questionTitle: iqToBeBuilt.content?.[lang] ?? undefined
                }))
                .filter(payload => !!payload)
                .map(async payload => analysisService.createAnalysis(payload))

            // In meanwhile analyses are handled, updates IQ locally
            updateOrAddIqToContext(responseIq)

            return Promise.allSettled([...analysisCreationPromises, ...analysisEditionPromises])
        }).then(analysesResults => {
            const analyses = analysesResults.filter(result => result.status === "fulfilled").map(result => result.value)
            outlineTabIqsDispatch({
                type: "replace-analyses-to-iq-to-be-built",
                analyses,
                shouldReloadOriginalIq: true,
                isEditing: true
            })

            setHasSaved(true)

            setTimeout(() => { setHasSaved(false) }, 2000)
        }).catch(() => {
            toastDispatch({
                type: "add-toast", payload: {
                    title: "Question not saved",
                    detail: "It was not possible to save the question.",
                    messageLevel: ErrorLevel.Warning
                }
            })
        }).finally(() => { setIsLoading(false) })
    }, [
        outlineTabIqsDispatch,
        projectService,
        analysisService,
        project,
        iqToBeBuilt,
        isEditing,
        iqPayload,
        lang,
        toastDispatch,
        shouldCreateExAnteAnalyses,
        updateOrAddIqToContext
    ])

    const handleSimulateQuestion = useCallback(() => {
        if (!isSimulateQuestionSidebarOpen) {
            outlineTabIqsDispatch({ type: "open-simulate-question-sidebar" })
            return
        }

        outlineTabIqsDispatch({ type: "force-generate-synthetic-answers" })
    }, [isSimulateQuestionSidebarOpen, outlineTabIqsDispatch])
    // #endregion

    // #region Effects

    // onChangeIqCancelHasSavedTimeout
    useEffect(() => {
        if (!prevIqId || prevIqId === iqToBeBuilt?.id) return
        setHasSaved(false)
    }, [iqToBeBuilt?.id, prevIqId])

    // #endregion

    if (!iqToBeBuilt?.type) return <></>

    return (
        <div className="flex justify-between items-center">
            <p className="text-[16px] text-glaut-bar">
                {questionTypeDetails()[iqToBeBuilt.type].title.toUpperCase()}
            </p>
            <div className="flex gap-[0.75em] items-center">
                {isSimulateButtonShown && (
                    <div className="relative p-[1px] group">
                        <div className={`absolute inset-0 opacity-0 rounded-[0.3125rem] transition-all z-0
                            bg-glaut-icon-gradient-1
                            ${!isSimulateButtonDisabled ? "group-hover:opacity-100" : ""}
                        `} />
                        <GlautButtonSecondary
                            disabled={isSimulateButtonDisabled}
                            onClick={handleSimulateQuestion}
                            data-tooltip-id={glautTooltipId}
                            data-tooltip-content={
                                getCopy(copy.outline.interviewQuestions.simulateTheQuestionOutcomeWithSyntheticData)
                            }
                            data-tooltip-hidden={isSimulateButtonDisabled}
                            className="relative border-none z-1"
                        >
                            <Sprinkles designMode="ai" fill={isSimulateButtonDisabled ? namedColors.grey : undefined} />
                            <p className={`text-[13.33px] font-medium bg-clip-text
                                ${isSimulateButtonDisabled ? "text-glaut-grey" : ""}
                                ${!isSimulateButtonDisabled ? "bg-glaut-icon-gradient-1 text-transparent" : ""}
                            `}>
                                {getCopy(copy.outline.interviewQuestions.simulateQuestion)}
                            </p>
                        </GlautButtonSecondary>
                    </div>
                )}
                {hasSaved && (
                    <div className="flex items-center gap-[0.3125rem] py-[0.375rem] px-[0.5rem] rounded-[0.25rem]
                            bg-glaut-codes-green">
                        <MdCheck className="w-[1rem] h-[1rem] text-glaut-off-white" />
                        <p className="text-[13.33px] font-medium text-glaut-off-white">
                            {getCopy(copy.actions.savedExclamation)}
                        </p>
                    </div>
                )}
                {!hasSaved && (
                    <GlautButtonPrimary
                        onClick={handleSaveQuestion}
                        disabled={hasSaved || !canSaveQuestion || isLoading}
                    >
                        {isLoading && (<Loading />)}
                        {!isLoading && (
                            <>
                                <GlautIcon className="fill-glaut-off-white group-disabled:fill-glaut-grey" />
                                <p className="text-[13.33px] font-medium">
                                    {getCopy(copy.actions.saveExclamation)}
                                </p>
                            </>
                        )}
                    </GlautButtonPrimary>
                )}
            </div>
        </div>
    )
}