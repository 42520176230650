import FloatingMenu from "@components/layouts/FloatingMenu"
import LoadingBar from "@components/loading/LoadingBar"
import RelatedQuestions from "@pages/Project/ProjectArea/components/AnalysisTabQuestionsColQuestionItemRelatedQuestions"
import Title from "@pages/Project/ProjectArea/components/AnalysisTabQuestionsColQuestionItemTitle"
import { copy, getCopy } from "@utils/Copy"
import { getLanguageKey, getProjectLang } from "@utils/language"
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import ReactDOM from "react-dom"
import { BiDotsVerticalRounded } from "react-icons/bi"
import {
    MdClose,
    MdEdit
} from "react-icons/md"
import { IAnalysis } from "src/@types/analysis"
import { IProject, IProjectResults } from "src/@types/project"
import { ProjectContext } from "../../../AutoSaveProject"
import { useAnalysisTab, useAnalysisTabDispatch } from "../../contexts/AnalysisTabProvider"
import { useProject } from "../../contexts/ProjectProvider"
import DeleteQuestionModal from "../AnalysisTabQuestionsColDeleteQuestionModal"
import Label from "../AnalysisTabQuestionsColQuestionItemLabel"
import { useAnalysisService } from "@hooks/services/useAnalysisService"
import { useInterviewService } from "@hooks/services/useInterviewService"
import usePrevProps from "@hooks/usePrevProps"
import { IInterviewQuestion } from "src/@types/interview-question"

interface IAnalysisTabQuestionsColQuestionItemProps {
    analysis: {
        id: string
        title: string
        sources: string[]
    } & ({
        isAnalysis?: false
        type: IInterviewQuestion["type"]
    } | {
        isAnalysis: true
        type: IAnalysis["type"]
        completion?: number
    })
    index: number
}

export default function AnalysisTabQuestionsColQuestionItem({
    analysis,
    index
}: Readonly<IAnalysisTabQuestionsColQuestionItemProps>) {
    // #region Contexts
    const { project } = useContext(ProjectContext) as { project: IProject | null }
    const { projectQuestions } = useProject()
    const {
        selectedAnalysis,
        processingAnalysisIds,
        newAnalysisType,
        editingAnalysis,
        selectedInterviewQuestionId
    } = useAnalysisTab()
    const analysisTabDispatch = useAnalysisTabDispatch()
    // #endregion

    // #region Prev props
    const prevSelectedAnalysis = usePrevProps(selectedAnalysis)
    // #endregion

    // #region Services
    const analysisService = useAnalysisService()
    const interviewService = useInterviewService()
    // #endregion

    // #region States

    const [showOptionsMenu, setShowOptionsMenu] = useState(false)
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

    // Loading percentage for analysis
    const [loading, setLoading] = useState<number | undefined>(analysis.isAnalysis ? analysis.completion : undefined)
    const [hasCancelledProcessing, setHasCancelledProcessing] = useState(false)
    const [processingPoolingInterval, setProcessingPoolingInterval] = useState<NodeJS.Timeout>()
    const [interviewsPoolingCount, setInterviewsPoolingCount] = useState<number>(0)

    // #endregion

    // #region Refs
    const questionContainerRef = useRef<HTMLDivElement>(null)
    // #endregion

    // #region Memos
    const lang = useMemo(() => getProjectLang(project), [project])
    const cardState = useMemo(() => {
        if (processingAnalysisIds.includes(analysis.id)) return "processing"
        if (newAnalysisType !== undefined) return "disabled"
        if (editingAnalysis !== undefined && editingAnalysis.id !== analysis.id) return "disabled"
        if (selectedAnalysis?.id === analysis.id || selectedInterviewQuestionId === analysis.id) return "selected"

        return "not-selected"
    }, [
        processingAnalysisIds,
        analysis,
        newAnalysisType,
        selectedAnalysis?.id,
        editingAnalysis,
        selectedInterviewQuestionId
    ])

    const shouldShowHoverStyling = useMemo(() => cardState === "not-selected", [cardState])

    const shouldRenderAnalysisLoadingBar = useMemo(() => cardState === "processing", [cardState])
    // #endregion

    // #region Callbacks
    const handleClickOnAnalysis = useCallback(() => {
        if (["disabled", "processing"].includes(cardState)) return

        analysisTabDispatch({ type: "clear-selected-category" })
        analysisTabDispatch({ type: "remove-analysis-filtering" })
        analysisTabDispatch({ type: "remove-breakdown" })

        if (analysis.isAnalysis)
            analysisTabDispatch({ type: "select-analysis", analysisId: analysis.id })
        else
            analysisTabDispatch({ type: "select-question", interviewQuestionId: analysis.id })
    }, [analysisTabDispatch, analysis, cardState])

    const handleDeleteAnalysis = useCallback(() => {
        if (!analysis.isAnalysis) return

        setIsDeleteModalOpen(false)

        const projectId = project?._id
        if (!projectId) return

        analysisService.deleteAnalysis({ projectId, analysisId: analysis.id })
            .then(() => {
                analysisTabDispatch({ type: "remove-analysis", analysisId: analysis.id })
                analysisTabDispatch({ type: "select-first-analysis" })
            })
    }, [analysis, analysisService, project?._id, analysisTabDispatch])

    const cancelAnalysis = useCallback(() => {
        const projectId = project?._id
        if (!projectId) return

        setHasCancelledProcessing(true)

        analysisService.cancelAnalysisExecution({ analysisId: analysis.id, projectId })
            .then(response => {
                const { isSuccess } = response
                if (!isSuccess) return

                setProcessingPoolingInterval(prev => {
                    if (prev) clearInterval(prev)
                    return undefined
                })

                setLoading(undefined)
                setHasCancelledProcessing(false)
                analysisTabDispatch({ type: "unset-analysis-as-processing", analysisId: analysis.id })
                analysisTabDispatch({ type: "remove-analysis", analysisId: analysis.id })
            })
    }, [
        analysis.id,
        analysisService,
        analysisTabDispatch,
        project?._id
    ])
    // #endregion

    // #region Effects

    // onProcessingGetCompletionEveryTwoSeconds
    useEffect(() => {
        const projectId = project?._id
        if (
            !projectId ||
            cardState !== "processing" ||
            hasCancelledProcessing ||
            interviewsPoolingCount > 5
        ) return

        const interval = setInterval(() => {
            analysisService.getAnalysisExecutionProgress({ analysisId: analysis.id, projectId })
                .then(response => {
                    const { completion } = response

                    if (completion !== 100 && completion !== null && completion !== undefined) {
                        setLoading(completion)
                        return
                    }

                    analysisTabDispatch({ type: "unset-analysis-as-processing", analysisId: analysis.id })

                    setLoading(undefined)
                    setInterviewsPoolingCount(0)

                    analysisService.getAnalysis({ analysisId: analysis.id, projectId })
                        .then(analysis => {
                            analysisTabDispatch({ type: "update-analysis", analysis })
                        })

                    analysisService.getAnalysesStats({ projectId })
                        .then(res => {
                            const { analysis_results: analysesStats } = res as IProjectResults
                            analysisTabDispatch({ type: "set-analyses-stats", analysesStats })
                        })

                    analysisTabDispatch({ type: "force-refresh-entries" })
                })

            if (selectedAnalysis?.id !== analysis.id) return

            analysisService.getAnalysesStats({ projectId })
                .then(res => {
                    const { analysis_results: analysesStats } = res as IProjectResults
                    analysisTabDispatch({ type: "set-analyses-stats", analysesStats })
                })

            analysisService.getAnalysis({ projectId, analysisId: analysis.id })
                .then(analysisResponse => {
                    analysisTabDispatch({ type: "update-analysis", analysis: analysisResponse })
                })

            setInterviewsPoolingCount(prev => {
                if (prev < 5) return prev + 1

                interviewService.getInterviews({ projectId })
                    .then(({ interviews }) => {
                        analysisTabDispatch({ type: "set-entries", entries: interviews })
                    })

                return 0
            })

        }, 5000)

        return () => { clearInterval(interval) }
    }, [
        analysis,
        analysisService,
        analysisTabDispatch,
        cardState,
        hasCancelledProcessing,
        processingPoolingInterval,
        project,
        loading,
        selectedAnalysis?.id,
        interviewService,
        interviewsPoolingCount
    ])

    useEffect(() => {
        if (
            (prevSelectedAnalysis?.id !== selectedAnalysis?.id || hasCancelledProcessing)
            && processingPoolingInterval
        ) {
            clearInterval(processingPoolingInterval)
            setProcessingPoolingInterval(undefined)
        }
    }, [hasCancelledProcessing, prevSelectedAnalysis?.id, processingPoolingInterval, selectedAnalysis])

    // #endregion

    return (
        <div
            className={`project-area-question 
                flex flex-col gap-[0.3125em] rounded-[4px] py-[0.625em] px-[0.75em] border-1
                group/question shadow-none bg-glaut-off-white
                ${["processing", "disabled"].includes(cardState) ? "cursor-not-allowed" : ""}
                ${shouldShowHoverStyling ? "hover:border-glaut-grey" : ""}
                ${cardState === "not-selected" ? "border-transparent cursor-pointer" : ""}
                ${cardState === "selected" ? "project-area-question-selected border-glaut-stroke-button" : ""}
                ${cardState === "disabled" ? "border-transparent cursor-not-allowed" : ""}
                ${cardState === "processing" ? "border-transparent cursor-not-allowed" : ""}
            `}
            ref={questionContainerRef}
            onClick={handleClickOnAnalysis}
        >
            <div className="flex flex-row justify-between">
                <Title state={cardState} questionType={analysis.type} index={index} />
                <div className="flex flex-row">
                    {editingAnalysis === undefined && ( // this check avoids unexpected flows when editing analysis
                        <BiDotsVerticalRounded
                            onClick={() => setShowOptionsMenu(!showOptionsMenu)}
                            className={`project-area-question-bidots-icon w-4 h-4
                                ${cardState === "not-selected" ? "hidden" : ""}
                                ${cardState === "selected" ? "text-glaut-midnight cursor-pointer" : ""}
                                ${cardState === "disabled" ? "hidden" : ""}
                                ${cardState === "processing" ? "hidden" : ""}
                                ${!analysis.isAnalysis ? "hidden" : ""}
                            `}
                        />
                    )}
                </div>
            </div>
            <Label state={cardState} label={analysis.title} />
            <RelatedQuestions
                state={cardState}
                questions={
                    (projectQuestions ?? [])
                        .filter(iq => analysis.sources.includes(iq.id))
                        .map(iq => ({
                            index: iq.index,
                            label: getLanguageKey(iq.content, lang) || iq.header || "",
                            domain: iq.domain
                        }))
                }
            />
            {shouldRenderAnalysisLoadingBar && (
                <div className="bar">
                    <LoadingBar percentage={loading} style={{ flex: 1 }} />
                    <button
                        className={`project-area-cancel-ongoing-analysis-button
                            border-none shadow-none px-3 py-[6px] font-medium text-center 
                            bg-glaut-pink text-glaut-off-white
                            group hover:bg-glaut-off-white hover:text-glaut-pink
                        `}
                        onClick={cancelAnalysis}>
                        Cancel
                    </button>
                </div>
            )}
            {analysis.isAnalysis && showOptionsMenu && (
                ReactDOM.createPortal(
                    <FloatingMenu
                        onClose={() => { setShowOptionsMenu(false) }}
                        anchorRef={questionContainerRef}
                        className="relative border-1 border-glaut-grey shadow-[0px_0px_8px_0px_#00000026] z-[50000]"
                        elements={[
                            {
                                icon: <MdEdit className="w-4 h-4 text-glaut-midnight" />,
                                text: getCopy(copy.coding.editAnalysis),
                                textClassName: "text-[13.33px]",
                                class: "edit-analysis",
                                onClick: () => {
                                    analysisTabDispatch({ type: "start-editing-analysis", analysisId: analysis.id })
                                }
                            },
                            {
                                icon: <MdClose className="w-4 h-4 text-glaut-midnight" />,
                                text: getCopy(copy.coding.deleteAnalysis),
                                textClassName: "text-[13.33px] mr-4",
                                class: "delete-analysis",
                                onClick: () => {
                                    setIsDeleteModalOpen(true)
                                }
                            }
                        ]}
                    />,
                    document.getElementById("portal")!
                )
            )}
            <DeleteQuestionModal
                open={isDeleteModalOpen}
                onClose={() => {
                    setIsDeleteModalOpen(false)
                }}
                onConfirm={handleDeleteAnalysis}
            />
        </div>
    )
}