import { useInterviewService } from "@hooks/services/useInterviewService"
import { ErrorLevel, IErrorPageProps } from "@components/layouts/ErrorPage"
import { settings, wsReconnectableErrors } from "@utils/Variables"
import { DisconnectReason, RemoteParticipant, Room, RoomEvent } from "livekit-client"
import React, { useCallback, useRef, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { IInterviewMessage } from "src/@types/entry"
import { IProjectBrandSettings, IProjectInterviewSettings } from "@/@types/project"
import { ArrayElement } from "@utils/types/array-element"

interface IWebRTCError extends IErrorPageProps { }

interface IUseWebRTCInterviewProps {
    projectId: string
    identifier: string
    lang: string
    userId: string
    messages: IInterviewMessage[]
    error: IWebRTCError
    interviewSettings: IProjectInterviewSettings & IProjectBrandSettings
    setMessages: React.Dispatch<React.SetStateAction<IInterviewMessage[]>>
    setHasNewMessage: React.Dispatch<React.SetStateAction<boolean>>
    setRunning: React.Dispatch<React.SetStateAction<boolean>>
    setCompletion: React.Dispatch<React.SetStateAction<number>>
    setParticipant: React.Dispatch<React.SetStateAction<RemoteParticipant>>
    setError: React.Dispatch<React.SetStateAction<IWebRTCError>>
    setInterviewId: React.Dispatch<React.SetStateAction<string>>
}

export function useWebRTCInterview({
    userId,
    identifier,
    lang,
    projectId,
    messages,
    error,
    interviewSettings,
    setMessages,
    setHasNewMessage,
    setRunning,
    setCompletion,
    setParticipant,
    setError,
    setInterviewId
}: Readonly<IUseWebRTCInterviewProps>) {

    // #region Query parameters
    const [searchParams] = useSearchParams()
    // #endregion

    // #region Services
    const interviewService = useInterviewService()
    // #endregion

    // #region States
    const [liveKitRoom, setLiveKitRoom] = useState<Room>()
    const [hasLiveKitRoomStarted, setHasLiveKitRoomStarted] = useState(false)
    // #endregion

    // #region Refs
    const trackElementRef = useRef<HTMLMediaElement>()
    const managerIdentifier = useRef<string>()

    // Stores the ws reconnect time to reduce the chances of clogging the server
    const reconnectCountRef = useRef(0)
    // #endregion

    // #region Error handling

    // Used to make error accessible in the handlers
    const errorRef = useRef(error)
    const setErrorRef = useCallback((data: IWebRTCError) => {
        errorRef.current = data
        setError(data)
    }, [setError])

    // #endregion

    // #region Callbacks
    const initializeConnection = useCallback((previousRoom?: Room) => {
        const reconnectTime = (reconnectCountRef.current + 1) * 0.75 * 1000
        console.log("Reconnect time: ", reconnectTime)

        // Clears the manager identifier
        managerIdentifier.current = undefined

        const reconnect = () => {
            // Increases the reconnect count
            reconnectCountRef.current += 1
            setTimeout(initializeConnection, reconnectTime)
        }

        // Establish a LiveKit room and returns the token
        const prefills = { ...Object.fromEntries(Array.from(searchParams.entries())), tracking_id: userId }
        interviewService.startWebRTCInterview({
            projectId, identifier, lang, prefills, useSttStreaming: searchParams.get("use_stt_streaming") === "true"
        })
            .then(({ token }) => {
                console.log("Connecting to room: ", previousRoom)

                const decoder = new TextDecoder()
                const room = new Room()

                // First thing first, connects to the room with a retry mechanism
                room.connect(settings.liveKitUrl, token)
                    .then(() => setLiveKitRoom(room))
                    .catch(e => {
                        console.log(e)
                        reconnect()
                    })

                const sessionTimeout = () => {
                    setErrorRef({
                        code: 3008,
                        codeDetail: "websocket_disconnection",
                        showCode: false,
                        errorLevel: ErrorLevel.Info,
                        eventName: "interview_session_expired"
                    })
                }

                // Connected to the server
                room.on(RoomEvent.Connected, () => {
                    console.log("Connected")
                    reconnectCountRef.current = 0
                })

                // Creates a listener for JSON inputs
                room.on(RoomEvent.DataReceived, payload => {
                    const data = JSON.parse(decoder.decode(payload))
                    console.log(`Received data (${data.manager_identifier}): `, data)

                    if (
                        (!managerIdentifier.current && !data?.start) || 
                        (managerIdentifier.current && data?.manager_identifier !== managerIdentifier.current)
                    ) {
                        console.log(`Received message from another interviewer (current ${managerIdentifier.current})`)
                        return
                    }

                    if (data?.start) {
                        if (!managerIdentifier.current)
                            managerIdentifier.current = data.manager_identifier

                        console.log("Room started")
                        setHasLiveKitRoomStarted(true)
                        return
                    }

                    if (data?.close) {
                        console.log("Server send close message")
                        room.disconnect()

                        if (data?.close === 3008) {
                            console.log("Session timeout")

                            sessionTimeout()
                            return
                        }

                        if (wsReconnectableErrors.includes(data?.close as ArrayElement<typeof wsReconnectableErrors>))
                            reconnect()

                        return
                    }

                    // Loads previous messages
                    if (data.messages && !messages.length) {
                        data.messages[data.messages.length - 1].restarted = true
                        setMessages(data.messages)
                    }

                    // Adds a new message to the chat, both from assistant or user
                    if (data.new_message) {
                        setMessages(ms => [...ms, { ...data.new_message, ...data.question }])
                        setHasNewMessage(true)
                    }

                    // Updates the last message
                    if (data.message || (data.message === null && !!data.termination_reason)) {
                        if ("interview_id" in data && typeof data.interview_id === "string")
                            setInterviewId(data.interview_id)

                        if (["respondent", "interviewee", "user"].includes(data.message.role))
                            setMessages(ms => [...ms, { ...data.message, ...data.question }])
                        else setMessages(ms =>
                            ms.map((m, idx) => {
                                if (idx !== ms.length - 1) return m

                                const endingMessageKey = data.termination_reason
                                    ? `${data.termination_reason}_ending_message`
                                    : "ending_message"

                                const terminationReason = data.termination_reason ?? null

                                return {
                                    ...(data.message ?? {}),
                                    ...(data.question ?? {}),
                                    role: data.role ?? "moderator",
                                    content: (!data.message?.content?.length && terminationReason
                                        ? interviewSettings?.[endingMessageKey]?.[lang]
                                        : data.message?.content) ?? "",
                                    termination_reason: terminationReason
                                }
                            })
                        )
                    }

                    // Changes running state
                    if (data.running !== undefined) setRunning(data.running)

                    // Updates survey's completion status
                    if (data.completion) setCompletion(data.completion)
                })

                // Attaches the input track to an element
                room.on(RoomEvent.TrackSubscribed, (track, _, participant) => {
                    const trackElement = track.attach()

                    // Appends the element to the DOM
                    const root = document.getElementById("root")!
                    root.appendChild(trackElement)

                    // Updates the state
                    setParticipant(participant)
                    trackElementRef.current = trackElement

                })

                // If the connection is lost due to duplicate identity, show the session timeout message
                room.on(RoomEvent.Disconnected, (reason?: DisconnectReason | undefined) => {
                    console.log("Disconnected: ", reason)
                    if (reason === DisconnectReason.DUPLICATE_IDENTITY)
                        sessionTimeout()
                })
            })
            // If we couldn't connect, try again
            .catch(e => {
                console.log(e)
                reconnect()
            })
    }, [
        identifier,
        interviewService,
        lang,
        messages.length,
        projectId,
        searchParams,
        setCompletion,
        setErrorRef,
        setHasNewMessage,
        setMessages,
        setParticipant,
        setRunning,
        interviewSettings,
        setInterviewId,
        userId
    ])
    // #endregion

    return {
        liveKitRoom,
        hasLiveKitRoomStarted,
        trackElementRef,
        initializeConnection
    }
}