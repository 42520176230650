import useAxiosAuth from "@hooks/useAxiosAuth"
import InterviewsApiService from "@services/interviews/api/interviews.api"
import IInterviewGateway from "@services/interviews/IInterviewGateway"
import { useMemo } from "react"

/**
 * Obtains the instance of the service to interact with interviews.
 */
export function useInterviewService(): IInterviewGateway {
    const axiosInstance = useAxiosAuth()
    return useMemo(() => new InterviewsApiService({ api: axiosInstance }), [axiosInstance])
}