import { copy, getCopy } from "@utils/Copy"
import { useMemo } from "react"
import { FaRegImage, FaUsersCog } from "react-icons/fa"
import { MdOutlineChat, MdOutlineSubject } from "react-icons/md"
import { useOutlineTabDispatch } from "../../contexts/OutlineTabProvider"
import OutlineTabSidebarProjectSetupOption from "../OutlineTabSidebarProjectSetupOption"

export default function OutlineTabSidebarProjectSetup() {
    // #region Contexts
    const outlineTabDispatch = useOutlineTabDispatch()
    // #endregion

    // #region Memos
    const sidebarOptions = useMemo(() => [
        // {
        //     id: "briefing",
        //     label: getCopy(copy.outline.sidebar.briefing) ?? "",
        //     Icon: MdOutlineDraw,
        //     onClick: () => outlineTabDispatch({
        //         type: "set-sidebar-selected-option",
        //         option: "briefing"
        //     })
        // },
        {
            id: "overview",
            label: getCopy(copy.outline.sidebar.overview) ?? "",
            Icon: MdOutlineSubject,
            onClick: () => outlineTabDispatch({
                type: "set-sidebar-selected-option",
                option: "overview"
            })
        },
        {
            id: "interview-questions",
            label: getCopy(copy.outline.sidebar.interviewQuestions) ?? "",
            Icon: MdOutlineChat,
            onClick: () => outlineTabDispatch({
                type: "set-sidebar-selected-option",
                option: "interview-questions"
            })
        },
        {
            id: "screening-and-redirects",
            label: getCopy(copy.outline.sidebar.screeningAndRedirects) ?? "",
            Icon: FaUsersCog,
            onClick: () => outlineTabDispatch({
                type: "set-sidebar-selected-option",
                option: "screening-and-redirects"
            })
        },
        {
            id: "branding",
            label: getCopy(copy.outline.sidebar.branding) ?? "",
            Icon: FaRegImage,
            onClick: () => outlineTabDispatch({
                type: "set-sidebar-selected-option",
                option: "branding"
            })
        }
    ] as const, [outlineTabDispatch])
    // #endregion

    return (
        <div className="flex flex-col gap-[0.75em]">
            <p className="text-[13.33px] text-glaut-bar">
                {getCopy(copy.outline.sidebar.projectSetUp)?.toUpperCase()}
            </p>
            {sidebarOptions.map(({ id, label, Icon, onClick }) => (
                <OutlineTabSidebarProjectSetupOption
                    key={id}
                    id={id}
                    label={label}
                    Icon={Icon}
                    onClick={onClick}
                />
            ))}
        </div>
    )
}