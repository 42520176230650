import { useCallback, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { copy, getCopy } from "@utils/Copy"
import { useGlautAuthInfo } from "@hooks/useGlautAuthInfo"
import { useProjectService } from "@hooks/services/useProjectService"

import ProjectStatus from "@components/Project/ProjectStatus"
import Button from "@components/layouts/Button"

import Cloud from "@assets/cloud.svg"
import { MdAddCircle, MdArrowBackIosNew } from "react-icons/md"

function Projects() {
    // #region Hooks
    const navigate = useNavigate()
    const { currentWs, currentWsIsAtLeastRole } = useGlautAuthInfo()
    // #endregion

    // #region Services
    const projectService = useProjectService()
    // #endregion

    // #region Memos
    const canEdit = useMemo(
        () => currentWsIsAtLeastRole("editor"),
        [currentWsIsAtLeastRole]
    )
    const projects = useMemo(
        () => currentWs?.projects?.filter(p => p.version === "insights") ?? [],
        [currentWs]
    )
    // #endregion

    // #region Callbacks
    const createNewProject = useCallback(() => {
        currentWs && projectService.createProject(
            {
                workspaceId: currentWs._id
            }
        ).then(res => {
            navigate(`/p/${res._id}`)
        })
    }, [currentWs, projectService, navigate])
    // #endregion

    return (
        <div id="project-list" className="flex flex-col mt-24 gap-4 pb-4">
            <div className="flex justify-between items-center mb-6">
                <Button
                    className="tertiary gray"
                    label={getCopy(copy.workspace.back)}
                    onClick={() => navigate(`/o/${currentWs?.org_id}`)}
                    icon={<MdArrowBackIosNew />}
                    reverse={true}
                />
                {canEdit && (
                    <Button
                        label={getCopy(copy.workspace.createProject)}
                        onClick={createNewProject}
                        icon={<MdAddCircle />}
                        reverse={true}
                    />
                )}
            </div>
            {!projects.length && (
                <div className="w-full mt-36 flex justify-center items-center">
                    <img src={Cloud} className="h-8 m-2" />
                    <span className="text-glaut-stroke-button">{getCopy(copy.workspace.noProjectsYet)}</span>
                </div>
            )}
            {projects.length ? projects.map(p => (
                <div
                    key={p._id}
                    onClick={() => navigate(`/p/${p._id}`)}
                    className="project w-full flex flex-row justify-between items-center
                        border border-glaut-stroke-glaut rounded-md
                        bg-glaut-off-white p-2 cursor-pointer hover:bg-glaut-cards"
                >
                    <h3>{p.name || "Untitled"}</h3>
                    <ProjectStatus project={p} />
                </div>
            )) : (
                <div className="w-full mt-36 flex justify-center items-center">
                    <img src={Cloud} className="h-8 m-2" />
                    <span className="text-glaut-stroke-button">{getCopy(copy.workspace.noProjectsYet)}</span>
                </div>
            )}
        </div>
    )
}

export default Projects
