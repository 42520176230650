import { useMemo } from "react"
import { FaCheckCircle } from "react-icons/fa"
import { ITerminationReason } from "@/@types/entry"
import { copy, getCopy } from "@utils/Copy"
import { FaCircleXmark } from "react-icons/fa6"

const statusMap = {
    completed: {
        label: getCopy(copy.words.completed),
        icon: <FaCheckCircle className="text-glaut-codes-green h-[0.875rem] w-[0.875rem]" />,
        backgroundClassName: "bg-glaut-subcodes-green"
    },
    uncompleted: {
        label: getCopy(copy.words.uncompleted),
        icon: <FaCircleXmark className="text-glaut-codes-orange h-[0.875rem] w-[0.875rem]" />,
        backgroundClassName: "bg-glaut-subcodes-orange"
    },
    uncooperative: {
        label: getCopy(copy.words.nonCooperative),
        icon: <FaCircleXmark className="text-glaut-codes-red h-[0.875rem] w-[0.875rem]" />,
        backgroundClassName: "bg-glaut-subcodes-red"
    },
    quotafull: {
        label: getCopy(copy.words.quotafull),
        icon: <FaCircleXmark className="text-glaut-codes-red h-[0.875rem] w-[0.875rem]" />,
        backgroundClassName: "bg-glaut-subcodes-red"
    },
    screenout: {
        label: getCopy(copy.words.screenedOut),
        icon: <FaCircleXmark className="text-glaut-codes-red h-[0.875rem] w-[0.875rem]" />,
        backgroundClassName: "bg-glaut-subcodes-red"
    }
}

export function useTableRowItemStatus(status: ITerminationReason | "uncompleted") {
    return useMemo(() => ({
        text: statusMap[status].label,
        icon: statusMap[status].icon,
        backgroundClassName: statusMap[status].backgroundClassName
    }), [status])
}