import { ISelectOption } from "@/@types/interview-question"
import { ISegmentedProjectResults, IVariableResults } from "@/@types/project"
import { searchCategoryInStats } from "@utils/analysis/category-stats"
import {
    extractIdFromSegment,
    extractQuestionResultsCategoryStatsFromDefaultOrSegmentedStats
} from "@utils/analysis/segmentation"
import { normalizeToColorIndex } from "@utils/styling/colors-from-index"
import { createContext, useContext, useReducer } from "react"
import { IAnalysis, IAnalysisCategory, IAnalysisType } from "src/@types/analysis"
import { IInterview } from "src/@types/entry"
import { ICategoryStats, IQuestionStats } from "src/@types/processing/statistics"

// #region Type definitions

interface IAnalysisTabProviderProps {
    children: React.ReactNode
}

interface IAnalysisTabContextState {
    /**
     * Filters applied on the selected analysis based on other analyses.
     */
    analysisFilters: { [analysisId: string]: { optionId: string }[] }
    /**
     * Filters applied on the selected analysis based on IQs.
     */
    questionFilters: { [questionId: string]: { optionId: string }[] }
    /**
     * Breakdown IQ.
     */
    selectedBreakdown?: {
        id: string
        type: "iq" | "analysis"
    }
    /**
     * The selected analysis in the first column.
     */
    selectedAnalysis?: IAnalysis
    /**
     * The selected question in the first column.
     */
    selectedInterviewQuestionId?: string
    /**
     * The IDs of the analyses that are currently processing.
     */
    processingAnalysisIds: string[]
    /**
     * When creating or editing an analysis, this will be set to the respective type (otherwise `undefined`)
     */
    newAnalysisType?: IAnalysisType
    /**
     * The analysis that is being currently edited.
     */
    editingAnalysis?: IAnalysis
    /**
     * Analyses available for the project.
     */
    analyses?: IAnalysis[]
    /**
     * Stats for the analyses available for the project.
     */
    analysesStats: {
        [analysisId: string]: IVariableResults<ICategoryStats<IAnalysisCategory>[]>
    }
    /**
     * Stats for when a breakdown operation has been done.
     */
    breakdownStats?: ISegmentedProjectResults[]
    /**
     * Stats for the questions available for the project.
     */
    questionsStats: {
        [questionId: string]: IVariableResults<IQuestionStats> | ISegmentedProjectResults<ISelectOption>[]
    }
    /**
     * Color index for each category.
     */
    categoryColorIndexes: {
        [categoryId: string]: number
    }
    /**
     * Selected category to filter the verbatims.
     */
    selectedCategory?: ICategoryStats<IAnalysisCategory>
    /**
     * Segment of the selected category when breakdown is applied.
     */
    selectedCategorySegmentId?: string
    /**
     * Entries of a project.
     */
    entries?: IInterview[]
    /**
     * Flag for refetching entries.
     */
    shouldForceRefreshEntries: boolean
    /**
     * Flag for refetching analyses stats.
     */
    shouldForceRefreshAnalysesStats: boolean
    /**
     * Selected interview to show over the conversation overlay.
     */
    selectedInterview?: IInterview
}

type IAnalysisTabContextAction = {
    type: "set-analysis-filtering"
    analysisFilters: IAnalysisTabContextState["analysisFilters"]
    questionFilters: IAnalysisTabContextState["questionFilters"]
} | {
    type: "remove-analysis-filtering"
} | {
    type: "remove-verbatims-code-filter"
} | {
    type: "start-new-analysis-creation"
    analysisType: IAnalysisType
} | {
    type: "start-editing-analysis"
    analysisId: string
} | {
    type: "close-analysis-creation-or-editing"
} | {
    type: "select-analysis"
    analysisId: string
} | {
    type: "select-question"
    interviewQuestionId: string
} | {
    type: "select-first-analysis"
} | {
    type: "set-analysis-as-processing"
    analysisId: string | string[]
} | {
    type: "unset-analysis-as-processing"
    analysisId: string | string[]
} | {
    type: "set-analyses"
    analyses: IAnalysis[]
} | {
    type: "add-analysis"
    analysis: IAnalysis
} | {
    type: "update-analysis"
    analysis: IAnalysis
    oldAnalysisToReplaceId?: string
} | {
    type: "remove-analysis"
    analysisId: string
} | {
    type: "set-analyses-stats"
    analysesStats: IAnalysisTabContextState["analysesStats"]
} | {
    type: "set-questions-stats"
    questionsStats: IAnalysisTabContextState["questionsStats"]
} | {
    type: "set-selected-category"
    category: NonNullable<IAnalysisTabContextState["selectedCategory"]>
    /**
     * Set it if you want to filter by segment together with the category.
     */
    selectedCategorySegmentId?: IAnalysisTabContextState["selectedCategorySegmentId"]
} | {
    type: "clear-selected-category"
} | {
    type: "set-breakdown"
    breakdown: IAnalysisTabContextState["selectedBreakdown"]
} | {
    type: "remove-breakdown"
} | {
    type: "set-breakdown-stats"
    breakdownStats: ISegmentedProjectResults[]
} | {
    type: "clear-breakdown-stats"
} | {
    type: "create-new-theme"
    theme: IAnalysisCategory
} | {
    type: "remove-category-from-interview"
    interviewId: string
    categoryId: string
    analysisId: string
} | {
    type: "assign-category-to-interview"
    interviewId: string
    categoryId: string
    analysisId: string
} | {
    type: "set-entries"
    entries: IInterview[]
} | {
    type: "clear-entries"
} | {
    type: "change-parent-of-category"
    analysisId: string
    categoryId: string
    newParentCategoryId: string
    /**
     * Used to update the stats after changing the parent.
     */
    analysisStats?: IVariableResults<ICategoryStats<IAnalysisCategory>[]>
} | {
    type: "promote-category-to-theme"
    analysisId: string
    categoryId: string
    /**
     * Used to update the stats after changing the parent.
     */
    analysisStats?: IVariableResults<ICategoryStats<IAnalysisCategory>[]>
} | {
    type: "remove-category-from-analysis"
    analysisId: string
    categoryId: string
    /**
     * Used to update the stats after changing the parent.
     */
    analysisStats?: IVariableResults<ICategoryStats<IAnalysisCategory>[]>
} | {
    type: "add-category-as-subtheme"
    analysisId: string
    themeId: string
    subThemeId: string
    subThemeLabel: string
    codeIds: string[]
    /**
     * Used to update the stats after changing the parent.
     */
    analysisStats?: IVariableResults<ICategoryStats<IAnalysisCategory>[]>
} | {
    type: "merge-categories"
    analysisId: string
    sourceCodeId: string
    targetCodeId: string
    /**
     * Used to update the stats after changing the parent.
     */
    analysisStats?: IVariableResults<ICategoryStats<IAnalysisCategory>[]>
} | {
    type: "force-refresh-entries"
} | {
    type: "mark-entries-as-refreshed"
} | {
    type: "update-theme-labels"
    analysisId: string
    themesData: {
        id: string
        label: string
    }[]
} | {
    type: "force-refresh-analyses-stats"
} | {
    type: "mark-analyses-stats-as-refreshed"
}
// #endregion

// #region Context definitions
const AnalysisTabContext = createContext({} as IAnalysisTabContextState)
const AnalysisTabContextDispatch = createContext({} as React.Dispatch<IAnalysisTabContextAction>)
// #endregion

// #region Hook definitions
export function useAnalysisTab() {
    return useContext(AnalysisTabContext)
}

export function useAnalysisTabDispatch() {
    return useContext(AnalysisTabContextDispatch)
}
// #endregion

// #region Util functions
function buildCategoryColorIndexes(
    statsList: ICategoryStats[],
    definedIndex?: number
): IAnalysisTabContextState["categoryColorIndexes"] {
    let map: Record<string, number> = {}

    for (const statsIdx in statsList) {
        const stats = statsList[statsIdx]
        const assignedColorIndex = definedIndex ?? normalizeToColorIndex(Number(statsIdx))

        const categoryId = extractIdFromSegment(stats.category)

        map[categoryId] = assignedColorIndex
        map = {
            ...map,
            ...buildCategoryColorIndexes(stats.children, assignedColorIndex)
        }
    }

    return map
}
// #endregion

// #region Provider definition
export default function AnalysisTabProvider({
    children
}: Readonly<IAnalysisTabProviderProps>) {
    const initialState: IAnalysisTabContextState = {
        analysisFilters: {},
        questionFilters: {},
        processingAnalysisIds: [],
        analysesStats: {},
        questionsStats: {},
        categoryColorIndexes: {},
        shouldForceRefreshEntries: false,
        shouldForceRefreshAnalysesStats: false
    }

    const [state, dispatch] = useReducer(AnalysisTabReducer, initialState)

    return (
        <AnalysisTabContext.Provider value={state}>
            <AnalysisTabContextDispatch.Provider value={dispatch}>
                {children}
            </AnalysisTabContextDispatch.Provider>
        </AnalysisTabContext.Provider>
    )
}
// #endregion

// #region Reducer definition
function AnalysisTabReducer(
    state: IAnalysisTabContextState,
    action: IAnalysisTabContextAction
): IAnalysisTabContextState {
    switch (action.type) {
        case "set-analysis-filtering": {
            return {
                ...state,
                analysisFilters: action.analysisFilters,
                questionFilters: action.questionFilters,
                selectedBreakdown: undefined,
                breakdownStats: undefined
            }
        }
        case "remove-analysis-filtering": {
            return {
                ...state,
                analysisFilters: {},
                questionFilters: {}
            }
        }
        case "start-new-analysis-creation": {
            return {
                ...state,
                newAnalysisType: action.analysisType
            }
        }
        case "close-analysis-creation-or-editing": {
            return {
                ...state,
                newAnalysisType: undefined,
                editingAnalysis: undefined
            }
        }
        case "select-analysis": {
            if (!state.analyses) return state
            if (action.analysisId === state.selectedAnalysis?.id) return state

            const categoryColorIndexes = buildCategoryColorIndexes(state.analysesStats[action.analysisId]?.stats ?? [])

            return {
                ...state,
                selectedInterviewQuestionId: undefined,
                selectedAnalysis: state.analyses.find(a => a.id === action.analysisId),
                categoryColorIndexes
            }
        }
        case "select-question": {
            if (action.interviewQuestionId === state.selectedInterviewQuestionId) return state

            let categoryColorIndexes = state.categoryColorIndexes
            const questionStats = state.questionsStats[action.interviewQuestionId]
            if (questionStats) {
                const stats = extractQuestionResultsCategoryStatsFromDefaultOrSegmentedStats(
                    questionStats,
                    action.interviewQuestionId
                )
                if (stats) categoryColorIndexes = buildCategoryColorIndexes(stats)
            }

            return {
                ...state,
                selectedAnalysis: undefined,
                selectedInterviewQuestionId: action.interviewQuestionId,
                categoryColorIndexes
            }
        }
        case "select-first-analysis": {
            if (!state.analyses) return state
            if (state.analyses.length === 0)
                return {
                    ...state,
                    selectedAnalysis: undefined

                }

            const analysis = state.analyses[0]
            const categoryColorIndexes = buildCategoryColorIndexes(state.analysesStats[analysis.id]?.stats ?? [])

            return {
                ...state,
                selectedAnalysis: analysis,
                categoryColorIndexes: { ...state.categoryColorIndexes, ...categoryColorIndexes }
            }
        }
        case "set-analysis-as-processing": {
            return {
                ...state,
                processingAnalysisIds: [...state.processingAnalysisIds, action.analysisId].flat()
            }
        }
        case "unset-analysis-as-processing": {
            return {
                ...state,
                processingAnalysisIds: state.processingAnalysisIds.filter(
                    i => Array.isArray(action.analysisId) ? !action.analysisId.includes(i) : i !== action.analysisId
                )
            }
        }
        case "set-analyses": {
            return {
                ...state,
                analyses: action.analyses,
                selectedAnalysis: state.selectedAnalysis
                    ? action.analyses.find(a => a.id === state.selectedAnalysis!.id)
                    : undefined
            }
        }
        case "add-analysis": {
            if (!state.analyses) return state
            if (state.analyses.some(a => a.id === action.analysis.id)) return state

            return {
                ...state,
                analyses: [...state.analyses, action.analysis]
            }
        }
        case "update-analysis": {
            if (!state.analyses) return state
            return {
                ...state,
                analyses: state.analyses.map(a => {
                    if (a.id === action.oldAnalysisToReplaceId) return action.analysis
                    if (a.id !== action.analysis.id) return a
                    return action.analysis
                }),
                selectedAnalysis: state.selectedAnalysis?.id === action.analysis.id
                    ? action.analysis
                    : undefined
            }
        }
        case "remove-analysis": {
            if (!state.analyses) return state
            return {
                ...state,
                analyses: state.analyses.filter(a => a.id !== action.analysisId),
                selectedAnalysis: state.selectedAnalysis?.id === action.analysisId
                    ? undefined
                    : state.selectedAnalysis
            }
        }
        case "start-editing-analysis": {
            if (!state.analyses) return state
            const analysis = state.analyses.find(a => a.id === action.analysisId)
            if (!analysis) return state

            return {
                ...state,
                editingAnalysis: analysis
            }
        }
        case "set-analyses-stats": {
            let categoryColorIndexes: IAnalysisTabContextState["categoryColorIndexes"] | undefined

            const { selectedAnalysis } = state
            if (selectedAnalysis)
                categoryColorIndexes = buildCategoryColorIndexes(action.analysesStats[selectedAnalysis.id]?.stats ?? [])

            return {
                ...state,
                analysesStats: action.analysesStats,
                categoryColorIndexes: categoryColorIndexes ?? state.categoryColorIndexes
            }
        }
        case "set-questions-stats": {
            return {
                ...state,
                questionsStats: action.questionsStats
            }
        }
        case "set-selected-category": {
            return {
                ...state,
                selectedCategory: action.category,
                selectedCategorySegmentId: action.selectedCategorySegmentId
            }
        }
        case "clear-selected-category": {
            if (!state.selectedCategory) return state

            return {
                ...state,
                selectedCategory: undefined,
                selectedCategorySegmentId: undefined
            }
        }
        case "set-breakdown": {
            return {
                ...state,
                selectedBreakdown: action.breakdown,
                questionFilters: {},
                analysisFilters: {}
            }
        }
        case "remove-breakdown": {
            return {
                ...state,
                selectedBreakdown: undefined,
                breakdownStats: undefined
            }
        }
        case "set-breakdown-stats": {
            return {
                ...state,
                breakdownStats: action.breakdownStats
            }
        }
        case "clear-breakdown-stats": {
            return {
                ...state,
                breakdownStats: undefined
            }
        }
        case "create-new-theme": {
            if (!state.selectedAnalysis || !state.analyses) return state

            const newAnalysis = {
                ...state.selectedAnalysis,
                categories: [...state.selectedAnalysis.categories, action.theme]
            }

            const analysisStats = state.analysesStats[newAnalysis.id]
            if (!analysisStats) return state

            const newAnalysisStats = {
                ...analysisStats,
                stats: [...analysisStats.stats, {
                    category: action.theme,
                    occurrences: 0,
                    children: [],
                    frequency: 0
                }]
            }

            const categoryColorIndexes = buildCategoryColorIndexes(newAnalysisStats.stats)

            return {
                ...state,
                analyses: state.analyses.map(a => {
                    if (a.id !== newAnalysis.id) return a
                    return newAnalysis
                }),
                selectedAnalysis: newAnalysis,
                analysesStats: { ...state.analysesStats, [newAnalysis.id]: newAnalysisStats },
                categoryColorIndexes
            }
        }
        case "remove-category-from-interview": {
            if (!state.selectedAnalysis) return state
            const stats = searchCategoryInStats(state.analysesStats[state.selectedAnalysis.id].stats, action.categoryId)
            if (!stats) return state

            // Pointer change
            stats.occurrences--

            return {
                ...state,
                entries: state.entries?.map(e => {
                    if (e._id !== action.interviewId) return e

                    const analysisResult = e.analysis_results.find(
                        r => r.analysis_id === action.analysisId &&
                            r.meaning_units.some(v => v.category_id === action.categoryId)
                    )
                    if (!analysisResult) return e

                    // Changes as pointer
                    analysisResult.meaning_units = analysisResult.meaning_units.filter(
                        v => v.category_id !== action.categoryId
                    )

                    return { ...e }
                })
            }
        }
        case "assign-category-to-interview": {
            if (!state.selectedAnalysis) return state
            const stats = searchCategoryInStats(state.analysesStats[state.selectedAnalysis.id].stats, action.categoryId)
            if (!stats) return state

            // Pointer change
            stats.occurrences++

            return {
                ...state,
                entries: state.entries?.map(e => {
                    if (e._id !== action.interviewId) return e

                    const result = e.analysis_results.find(r => r.analysis_id === action.analysisId)

                    if (!result)
                        e.analysis_results.push({
                            analysis_id: action.analysisId,
                            created_at: new Date().toISOString(),
                            meaning_units: [{ category_id: action.categoryId, quote: null }]
                        })
                    else if (!result.meaning_units.some(v => v.category_id === action.categoryId))
                        result.meaning_units.push({ category_id: action.categoryId, quote: null })
                    else return e

                    // Force new pointer
                    return {
                        ...e,
                        analysis_results: [
                            ...e.analysis_results.map(r => ({
                                ...r,
                                meaning_units: [...r.meaning_units]
                            }))
                        ]
                    }
                })
            }
        }
        case "set-entries": {
            return {
                ...state,
                entries: action.entries
            }
        }
        case "clear-entries": {
            return {
                ...state,
                entries: undefined
            }
        }
        case "change-parent-of-category": {
            if (!state.analyses) return state
            const analysis = state.analyses.find(a => a.id === action.analysisId)
            if (!analysis?.categories.some(c => c.id === action.newParentCategoryId)) return state

            const newAnalysis = {
                ...analysis,
                categories: analysis.categories.map(c => {
                    if (c.id !== action.categoryId) return c
                    return { ...c, parent: action.newParentCategoryId }
                })
            }

            const newAnalysisStats = action.analysisStats ?? state.analysesStats[action.analysisId]
            const categoryColorIndexes = buildCategoryColorIndexes(newAnalysisStats.stats)

            return {
                ...state,
                analyses: state.analyses.map(a => {
                    if (a.id !== action.analysisId) return a
                    return newAnalysis
                }),
                analysesStats: { ...state.analysesStats, [action.analysisId]: newAnalysisStats },
                categoryColorIndexes,
                selectedAnalysis: state.selectedAnalysis?.id === action.analysisId
                    ? newAnalysis
                    : state.selectedAnalysis
            }
        }
        case "promote-category-to-theme": {
            if (!state.analyses) return state
            const analysis = state.analyses.find(a => a.id === action.analysisId)
            if (!analysis) return state

            const newAnalysis = {
                ...analysis,
                categories: analysis.categories.map(c => {
                    if (c.id !== action.categoryId) return c
                    return { ...c, parent: null }
                })
            }

            const newAnalysisStats = action.analysisStats ?? state.analysesStats[action.analysisId]
            const categoryColorIndexes = buildCategoryColorIndexes(newAnalysisStats.stats)

            return {
                ...state,
                analyses: state.analyses.map(a => {
                    if (a.id !== action.analysisId) return a

                    return newAnalysis
                }),
                analysesStats: { ...state.analysesStats, [action.analysisId]: newAnalysisStats },
                categoryColorIndexes,
                selectedAnalysis: state.selectedAnalysis?.id === action.analysisId
                    ? newAnalysis
                    : state.selectedAnalysis
            }
        }
        case "remove-category-from-analysis": {
            if (!state.analyses) return state
            const analysis = state.analyses.find(a => a.id === action.analysisId)
            if (!analysis) return state

            const newAnalysis = {
                ...analysis,
                categories: analysis.categories.filter(
                    c => c.id !== action.categoryId && c.parent !== action.categoryId
                )
            }

            return {
                ...state,
                analyses: state.analyses.map(a => {
                    if (a.id !== action.analysisId) return a
                    return newAnalysis
                }),
                analysesStats: {
                    ...state.analysesStats,
                    [action.analysisId]: action.analysisStats ?? state.analysesStats[action.analysisId]
                },
                selectedAnalysis: state.selectedAnalysis?.id === action.analysisId
                    ? newAnalysis
                    : state.selectedAnalysis
            }
        }
        case "add-category-as-subtheme": {
            if (!state.analyses) return state
            const analysis = state.analyses.find(a => a.id === action.analysisId)
            if (!analysis) return state

            const newAnalysis = {
                ...analysis,
                categories: [
                    ...analysis.categories.map(c => {
                        if (!action.codeIds.includes(c.id)) return c
                        return { ...c, parent: action.subThemeId }
                    }),
                    {
                        id: action.subThemeId,
                        label: action.subThemeLabel,
                        parent: action.themeId,
                        description: null
                    }
                ]
            }

            const newAnalysisStats = action.analysisStats ?? state.analysesStats[action.analysisId]
            const categoryColorIndexes = buildCategoryColorIndexes(newAnalysisStats.stats)

            return {
                ...state,
                analyses: state.analyses.map(a => {
                    if (a.id !== action.analysisId) return a
                    return newAnalysis
                }),
                analysesStats: { ...state.analysesStats, [action.analysisId]: newAnalysisStats },
                categoryColorIndexes,
                selectedAnalysis: state.selectedAnalysis?.id === action.analysisId
                    ? newAnalysis
                    : state.selectedAnalysis
            }
        }
        case "merge-categories": {
            if (!state.analyses) return state
            const analysis = state.analyses.find(a => a.id === action.analysisId)
            if (!analysis) return state

            const newAnalysis = {
                ...analysis,
                categories: analysis.categories.filter(c => c.id !== action.sourceCodeId)
            }

            const newAnalysisStats = action.analysisStats ?? state.analysesStats[action.analysisId]

            return {
                ...state,
                analyses: state.analyses.map(a => {
                    if (a.id !== action.analysisId) return a
                    return newAnalysis
                }),
                entries: (state.entries ?? []).map(i => {
                    const analysisResult = i.analysis_results.find(r => r.analysis_id === action.analysisId)
                    if (!analysisResult) return i

                    if (analysisResult.meaning_units.some(v => v.category_id === action.sourceCodeId)) {
                        analysisResult.meaning_units = analysisResult.meaning_units.filter(
                            v => v.category_id !== action.sourceCodeId
                        )
                        if (!analysisResult.meaning_units.some(v => v.category_id === action.targetCodeId))
                            analysisResult.meaning_units.push({ category_id: action.targetCodeId, quote: null })
                    }

                    return { ...i }
                }),
                analysesStats: { ...state.analysesStats, [action.analysisId]: newAnalysisStats },
                selectedAnalysis: state.selectedAnalysis?.id === action.analysisId
                    ? newAnalysis
                    : state.selectedAnalysis
            }
        }
        case "force-refresh-entries": {
            return {
                ...state,
                shouldForceRefreshEntries: true
            }
        }
        case "mark-entries-as-refreshed": {
            return {
                ...state,
                shouldForceRefreshEntries: false
            }
        }
        case "update-theme-labels": {
            if (!state.analyses) return state

            const analysis = state.analyses.find(a => a.id === action.analysisId)
            if (!analysis) return state

            const analysisStats = state.analysesStats[action.analysisId]
            if (!analysisStats) return state

            const newAnalysis = {
                ...analysis,
                categories: analysis.categories.map(c => {
                    const themeData = action.themesData.find(t => t.id === c.id)
                    if (!themeData) return c

                    return { ...c, label: themeData.label }
                })
            }

            const newAnalysesStats = {
                ...analysisStats,
                stats: analysisStats.stats.map(stats => {
                    for (const themeData of action.themesData) {
                        const categoryStats = searchCategoryInStats(stats, themeData.id)
                        if (categoryStats === null) continue

                        // Pointer change
                        categoryStats.category.label = themeData.label
                    }

                    return stats
                })
            }

            return {
                ...state,
                analyses: state.analyses?.map(a => a.id === action.analysisId ? newAnalysis : a),
                analysesStats: { ...state.analysesStats, [action.analysisId]: newAnalysesStats },
                selectedAnalysis: state.selectedAnalysis?.id === action.analysisId
                    ? newAnalysis
                    : state.selectedAnalysis
            }
        }
        case "force-refresh-analyses-stats": {
            return {
                ...state,
                shouldForceRefreshAnalysesStats: true
            }
        }
        case "mark-analyses-stats-as-refreshed": {
            return {
                ...state,
                shouldForceRefreshAnalysesStats: false
            }
        }
        default: {
            return state
        }
    }
}
// #endregion