import {
    ChartConfig,
    ChartContainer,
    ChartTooltip,
    ChartTooltipContent
} from "@/components/ui/chart"
import { isStatsACategoryStats, isStatsASegmentedStats } from "@utils/analysis/segmentation"
import { getProjectLang } from "@utils/language"
import { normalizeToColorIndex } from "@utils/styling/colors-from-index"
import { useMemo } from "react"
import { Pie, PieChart } from "recharts"
import { ISelectInsight } from "src/@types/reports"
import { useReportDetails } from "../../contexts/ReportDetailsContext"

interface IReportDetailsSelectInsightBlockDonutProps {
    block: ISelectInsight
}

export default function ReportDetailsSelectInsightBlockDonut({
    block
}: Readonly<IReportDetailsSelectInsightBlockDonutProps>) {
    // #region Contexts
    const { project, renderAsPdf } = useReportDetails()
    // #endregion

    // #region Memos
    const lang = useMemo(() => getProjectLang(project), [project])
    const chartConfig: ChartConfig = useMemo(() => (block.stats ?? []).reduce((config, stats, idx) => {
        if (isStatsASegmentedStats(stats)) return config

        const id = stats.category.id
        const label = stats.category.label[lang]

        config[id] = { label, color: `var(--color${normalizeToColorIndex(idx)})` }

        return config
    }, {} as ChartConfig), [block.stats, lang])

    const chartData = useMemo(() => !block.stats ? [] : block.stats
        .filter(stats => isStatsACategoryStats(stats))
        .map((stats, idx) => ({
            data: stats.occurrences,
            name: stats.category.label[lang],
            fill: `var(--color${normalizeToColorIndex(idx)})`
        })), [block.stats, lang])
    // #endregion

    if (renderAsPdf || chartData.length === 0)
        return <></>

    return (
        <div className="min-h-[300px] min-w-[300px]">
            <ChartContainer
                config={chartConfig}
                className={`mx-auto aspect-square
                    min-w-[250px] min-h-[250px]
                    2xl:min-w-[300px] 2xl:min-h-[300px]
                `}
            >
                <PieChart>
                    <ChartTooltip
                        cursor={false}
                        content={<ChartTooltipContent hideLabel />}
                    />
                    <Pie
                        data={chartData}
                        dataKey="data"
                        nameKey="name"
                        innerRadius={100}
                    />
                </PieChart>
            </ChartContainer>
        </div>
    )
}
