import { IProjectStatus } from "@/@types/project"
import { namedColors } from "@utils/Variables"
import { FaEdit, FaRegStopCircle } from "react-icons/fa"
import { IconType } from "react-icons/lib"
import { MdRemoveRedEye, MdSettings, MdWifiTethering } from "react-icons/md"

export const projectStatusLabelMap: { [status in IProjectStatus]: string } = {
    set_up: "Set up",
    test: "Test",
    live: "Live",
    ended: "Ended",
    not_set: "Not set"
}

export const projectStatusTargetMap: { [status in IProjectStatus]: string[] } = {
    set_up: ["test", "live"],
    test: ["set_up", "live"],
    live: ["ended"],
    ended: [],
    not_set: ["set_up", "test", "live", "ended"]
}

type IProjectStatusColorMap = {
    [status in IProjectStatus]: { hex: string, className: { bg: string, text: string } }
}

export const projectStatusColorMap: IProjectStatusColorMap = {
    ended: {
        hex: namedColors["codes-red"],
        className: {
            bg: "bg-glaut-codes-red",
            text: "text-glaut-codes-red"
        }
    },
    live: {
        hex: namedColors["codes-green"],
        className: {
            bg: "bg-glaut-codes-green",
            text: "text-glaut-codes-green"
        }
    },
    not_set: {
        hex: namedColors["dark-grey"],
        className: {
            bg: "bg-glaut-dark-grey",
            text: "text-glaut-dark-grey"
        }
    },
    test: {
        hex: namedColors["codes-orange"],
        className: {
            bg: "bg-glaut-codes-orange",
            text: "text-glaut-codes-orange"
        }
    },
    set_up: {
        hex: namedColors["dark-grey"],
        className: {
            bg: "bg-glaut-dark-grey",
            text: "text-glaut-dark-grey"
        }
    }
}

export const projectStatusIconMap: { [status in IProjectStatus]: IconType } = {
    set_up: FaEdit,
    test: MdRemoveRedEye,
    live: MdWifiTethering,
    ended: FaRegStopCircle,
    not_set: MdSettings
}