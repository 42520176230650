import { getCopy } from "@utils/Copy"
import { glautRateModalityComponentCopy } from "../../utils/copy"

interface IGlautRateModalityComponentCopyInputProps {
    label: string
    value: string
    onChange?: (value: string) => void
    disabled?: boolean
}

export default function GlautRateModalityComponentCopyInput({
    label,
    onChange,
    value,
    disabled
}: Readonly<IGlautRateModalityComponentCopyInputProps>) {
    return (
        <div className="flex gap-[0.25rem] items-center">
            <p className="text-[13.33px] font-medium text-[rgb(0,0,0,0.6)] whitespace-nowrap min-w-[20%]">
                {label}
            </p>
            <input
                className="rounded-[0.25rem] border-1 py-[0.5rem] px-[0.75em]
                    border-glaut-cards bg-glaut-off-white
                    hover:border-glaut-pink
                    focus:border-glaut-pink focus:shadow-none"
                placeholder={getCopy(glautRateModalityComponentCopy.writeALabelHereEllipsis) ?? ""}
                value={value}
                onChange={e => { onChange?.(e.target.value) }}
                disabled={disabled}
            />
        </div>
    )
}