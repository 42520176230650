import { IAnalysis, IAnalysisCategory } from "@/@types/analysis"
import { IInterviewQuestion } from "@/@types/interview-question"
import GlautCodeBookToggleComponent, {
    IGlautCodeBookToggleComponentRef
} from "@components/inputs/GlautCodeBookToggleComponent"
import GlautIACategories from "@components/inputs/GlautIACategories"
import { copy, getCopy } from "@utils/Copy"
import { getLanguageKey } from "@utils/language"
import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md"
import GlautAnalysisCreationFormGoalTextArea from "./components/GlautAnalysisCreationFormGoalTextArea"
import GlautAnalysisCreationFormTitleInput from "./components/GlautAnalysisCreationFormTitleInput"
import EmptySpaceBox from "@components/EmptySpaceBox"
import { glautAnalysisCreationFormCopy } from "./utils/copy"

export interface IGlautAnalysisCreationFormRef {
    goal: string
    title: string
    categories: IAnalysisCategory[]
    fixedCodebook: boolean
    selectedIqIds: string[]
}

export interface IGlautAnalysisCreationFormState extends IGlautAnalysisCreationFormRef { }
export type IGlautAnalysisCreationFormOnChangeHandler = (state: IGlautAnalysisCreationFormState) => void

type IGlautAnalysisCreationFormProps = {
    defaultAnalysisValue?: Partial<IAnalysis>
    categoryColorIndexes?: { [themeId: string]: number }
    lang?: string
    designMode?: "default" | "compact"
} & ({
    withInterviewQuestionsSelection: true
    interviewQuestions: IInterviewQuestion[]
} | {
    withInterviewQuestionsSelection?: false
    interviewQuestions?: null
}) & ({
    /**
     * When defined with `onChange`, it will enter in controlled state.
     */
    value: IGlautAnalysisCreationFormState
    /**
     * When defined with `value`, it will enter in controlled state.
     */
    onChange: IGlautAnalysisCreationFormOnChangeHandler
} | ({
    value?: null
    onChange?: null
}))

const GlautAnalysisCreationForm = forwardRef<
    IGlautAnalysisCreationFormRef | undefined,
    Readonly<IGlautAnalysisCreationFormProps>
>(({
    lang,
    defaultAnalysisValue,
    withInterviewQuestionsSelection = false,
    interviewQuestions,
    categoryColorIndexes = {},
    onChange,
    value,
    designMode = "default"
}, ref) => {
    // #region States

    const [state, setState] = useState<IGlautAnalysisCreationFormState>({
        title: defaultAnalysisValue?.title ?? (value ? value.title : undefined) ?? "",
        goal: defaultAnalysisValue?.goal ?? (value ? value.goal : undefined) ?? "",
        selectedIqIds: defaultAnalysisValue?.sources ?? (value ? value.selectedIqIds : undefined) ?? [],
        categories: defaultAnalysisValue?.categories ?? (value ? value.categories : undefined) ?? [],
        fixedCodebook: (defaultAnalysisValue?.type === "thematic" ? defaultAnalysisValue?.fixed_codebook : undefined)
            ?? (value ? value.fixedCodebook : undefined) ?? false
    })

    // #endregion

    // #region Refs
    const codeBookComponentRef = useRef<IGlautCodeBookToggleComponentRef>()
    // #endregion

    // #region Memos
    const analysisType = useMemo(() => defaultAnalysisValue?.type ?? undefined, [defaultAnalysisValue])

    const allowedIqsToBeSelected = useMemo(
        () => interviewQuestions?.filter(iq => iq.type !== "asset") ?? [],
        [interviewQuestions]
    )
    // #endregion

    // #region Callbacks
    const updateState = useCallback((newState: React.SetStateAction<IGlautAnalysisCreationFormState>) => {
        setState(newState)
        if (!value || !onChange) return

        if (typeof newState === "object") {
            onChange(newState)
            return
        }

        onChange(newState(state))
    }, [onChange, state, value])

    const handleToggleIqSelection = useCallback((iqId: string) => {
        updateState(state => ({
            ...state,
            selectedIqIds: state.selectedIqIds.includes(iqId)
                ? state.selectedIqIds.filter(id => id !== iqId)
                : [...state.selectedIqIds, iqId]
        }))
    }, [updateState])
    // #endregion

    // #region Effects

    // onUpdateStateUpdateRef
    useEffect(() => {
        if (!ref || !codeBookComponentRef.current) return

        const { codeBookModality, codeBookThemesWhenUsingExisting } = codeBookComponentRef.current

        let refCategories = state.categories
        if (analysisType !== "interpretation")
            refCategories = codeBookModality === "use-existing" ? codeBookThemesWhenUsingExisting : []

        const refValue: IGlautAnalysisCreationFormRef = {
            ...state,
            categories: refCategories,
            fixedCodebook: analysisType !== "interpretation" && codeBookModality === "use-existing",
            selectedIqIds: withInterviewQuestionsSelection ? state.selectedIqIds : []
        }

        if (typeof ref === "object") {
            ref.current = refValue
            return
        }

        ref(refValue)
    })

    // #endregion

    return (
        <div className="flex gap-[1.5rem] overflow-hidden">
            <div className="flex flex-col gap-[0.75rem] flex-1 overflow-auto pr-1">
                {designMode === "default" && (
                    <GlautAnalysisCreationFormGoalTextArea
                        value={value?.goal ?? state.goal}
                        onChange={ev => { updateState(state => ({ ...state, goal: ev.target.value })) }}
                        placeholder={getCopy(copy.coding.instructGlautHowToAnalyzeTheOpenData) ?? ""}
                        designMode="default"
                        autoResize
                    />
                )}
                {analysisType === "thematic" && (
                    <GlautCodeBookToggleComponent
                        categoryColorIndexes={categoryColorIndexes}
                        allowAddingCodes={analysisType === "thematic"}
                        value={{
                            codeBookModality: state.fixedCodebook ? "use-existing" : "create",
                            codeBookThemesWhenUsingExisting: state.categories
                        }}
                        onChange={state => {
                            updateState(prev => ({
                                ...prev,
                                fixedCodebook: state.codeBookModality === "use-existing",
                                categories: state.codeBookThemesWhenUsingExisting
                            }))
                        }}
                    />
                )}
                {analysisType === "interpretation" && (
                    <GlautIACategories
                        categoryColorIndexes={categoryColorIndexes}
                        value={state.categories}
                        onChange={categories => { updateState(state => ({ ...state, categories })) }}
                    />
                )}
                {designMode === "default" && (
                    <GlautAnalysisCreationFormTitleInput
                        value={value?.title ?? state.title}
                        onChange={ev => { updateState(state => ({ ...state, title: ev.target.value })) }}
                        placeholder={getCopy(copy.coding.writeATitleHere) ?? ""}
                    />
                )}
                {designMode === "compact" && (
                    <GlautAnalysisCreationFormGoalTextArea
                        value={value?.goal ?? state.goal}
                        onChange={ev => { updateState(state => ({ ...state, goal: ev.target.value })) }}
                        placeholder={getCopy(copy.coding.instructGlautHowToAnalyzeTheOpenData) ?? ""}
                        designMode="compact"
                        autoResize
                    />
                )}
            </div>
            {withInterviewQuestionsSelection && (
                <div className="flex flex-col flex-1 overflow-auto pr-[1.5rem]">
                    <p className="text-[13.33px] font-medium text-glaut-text-midnight mt-[0.6875rem]">
                        {getCopy(copy.coding.interviewQuestionsSelection)}
                    </p>
                    <p className="text-[13.33px] text-glaut-dark-grey my-[0.75rem]">
                        {getCopy(copy.coding.select1To4InterviewQuestionsToRunTheAnalysisOn)}
                    </p>
                    <div className="flex flex-col w-full py-[0.375rem] flex-1">
                        {!!allowedIqsToBeSelected.length && allowedIqsToBeSelected.map((iq, index) => (
                            <div
                                key={iq.id}
                                className="flex flex-row gap-[0.25rem] items-center py-[0.25rem] px-[0.5rem]"
                            >
                                <button
                                    className="border-none shadow-none p-0 m-0 bg-transparent text-sm"
                                    onClick={() => { handleToggleIqSelection(iq.id) }}
                                >
                                    {!state.selectedIqIds.includes(iq.id) && (
                                        <MdCheckBoxOutlineBlank
                                            className="h-[0.875rem] w-[0.875rem] text-glaut-text-midnight"
                                        />
                                    )}
                                    {state.selectedIqIds.includes(iq.id) && (
                                        <MdCheckBox
                                            className="h-[0.875rem] w-[0.875rem] text-glaut-text-midnight"
                                        />
                                    )}
                                </button>
                                <div className="flex flex-row gap-[0.5rem] items-center">
                                    <p className="text-[11.11px] font-medium text-glaut-grey">
                                        {`Q${index + 1}`}
                                    </p>
                                    <p className="text-[11.11px] font-medium text-glaut-text-midnight">
                                        {getLanguageKey(iq.content, lang) || iq.header}
                                    </p>
                                </div>
                            </div>
                        ))}
                        {!allowedIqsToBeSelected.length && (
                            <EmptySpaceBox
                                message={getCopy(glautAnalysisCreationFormCopy.noInterviewQuestionsFound) ?? ""}
                            />
                        )}
                    </div>
                </div>
            )}
        </div>
    )
})

GlautAnalysisCreationForm.displayName = "GlautAnalysisCreationForm"

export default GlautAnalysisCreationForm