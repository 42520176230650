import { IInterviewerReply } from "@/@types/api/interviewer"
import { IProjectBrandSettings, IProjectInterviewSettings } from "@/@types/project"
import { baseWS } from "@hooks/useAuthSocket"
import { useWebSocketCleanup } from "@hooks/useWebSocketCleanup"
import ErrorLevel from "@utils/ErrorLevel"
import { ArrayElement } from "@utils/types/array-element"
import { settings, wsReconnectableErrors } from "@utils/Variables"
import { useCallback, useRef, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { IInterviewMessage } from "src/@types/entry"

interface IWebSocketError {
    code: number
    codeDetail: string
    error?: CloseEvent
    showCode: boolean
    errorLevel: number
    eventName: string
}

interface IUseWebSocketInterviewProps {
    projectId: string
    identifier: string
    lang: string
    userId: string
    messages: (IInterviewMessage | IInterviewerReply)[]
    interviewSettings: IProjectInterviewSettings & IProjectBrandSettings
    setMessages: React.Dispatch<React.SetStateAction<(IInterviewMessage | IInterviewerReply)[]>>
    setRunning: React.Dispatch<React.SetStateAction<boolean>>
    setCompletion: React.Dispatch<React.SetStateAction<number>>
    setError: React.Dispatch<React.SetStateAction<IWebSocketError>>
    setIsPlaying: React.Dispatch<React.SetStateAction<boolean | null>>
    setInterviewId: React.Dispatch<React.SetStateAction<string>>
}

export function useWebSocketInterview({
    identifier,
    projectId,
    lang,
    userId,
    messages,
    interviewSettings,
    setCompletion,
    setError,
    setMessages,
    setRunning,
    setIsPlaying,
    setInterviewId
}: Readonly<IUseWebSocketInterviewProps>) {
    // #region Query params
    const [searchParams] = useSearchParams()
    // #endregion

    // #region States
    const [socket, setSocket] = useState<WebSocket>()
    // #endregion

    // #region Refs

    // Stores the ws reconnect time to reduce the chances of clogging the server
    const reconnectCountRef = useRef(0)

    const appendChunkRef = useRef()

    // #endregion

    // #region WebSocket hooks

    // Closes the WS at the end if not WebRTC
    useWebSocketCleanup(socket)

    // #endregion

    // #region Callbacks
    const initializeConnection = useCallback(() => {
        // Establish a WebSocket connection
        const sock = new WebSocket(`${baseWS}/interviews/${projectId}/${identifier}?v=insights`)

        // Initializes the agent by sending the prefills to the back
        sock.onopen = () => {
            sock.send(
                JSON.stringify({ ...Object.fromEntries(Array.from(searchParams.entries())), lang, tracking_id: userId })
            )
        }

        // never let the ws close
        sock.onclose = e => {
            const reconnectTime = (reconnectCountRef.current + 1) * 0.75 * 1000
            console.log("Reconnect time: ", reconnectTime)

            // If we had a client side error, tries to reconnect
            if (
                reconnectCountRef.current < settings.interviewReconnectionsLimit &&
                wsReconnectableErrors.includes(e.code as ArrayElement<typeof wsReconnectableErrors>)
            ) {
                // Increases the reconnect count
                reconnectCountRef.current += 1
                setTimeout(initializeConnection, reconnectTime)
            }
            // If the ws was disconnected correctly resets the reconnect count
            else if (e.code === 1000) { reconnectCountRef.current = 0 }
            else {
                const errorMessage = `Interview: WebSocket disconnection error: ${e.code}`
                console.log(errorMessage)
                const errorLevel = e.code === 3008 ? ErrorLevel.Info : ErrorLevel.Warning
                const eventName = e.code === 3008 ? "interview_session_expired" : "interview_error"
                setError({
                    code: e.code === 4404 ? 404 : e.code,
                    codeDetail: "websocket_disconnection",
                    error: e,
                    showCode: false,
                    errorLevel,
                    eventName
                })
            }
        }

        sock.onmessage = async event => {
            if (typeof event.data === "string") {
                const data = JSON.parse(event.data)

                // Loads previous messages
                if (data.messages && !messages.length) setMessages(data.messages)

                // Adds a new message to the chat (both assistant and user's transcription)
                if (data.new_message) {
                    setIsPlaying(null)
                    setMessages(ms => [
                        ...ms,
                        {
                            content: "",
                            role: "moderator",
                            date: new Date().toISOString(),
                            question_id: "",
                            recording_id: null,
                            asset_url: null
                        }
                    ])
                }

                // Updates the last message
                if (data.message || (data.message === null && !!data.termination_reason))
                    setMessages(ms =>
                        ms.map((m, idx) => {
                            if (idx !== ms.length - 1) return m

                            if ("interview_id" in data && typeof data.interview_id === "string")
                                setInterviewId(data.interview_id)

                            const endingMessageKey = data.termination_reason
                                ? `${data.termination_reason}_ending_message`
                                : "ending_message"

                            const terminationReason = data.termination_reason ?? null

                            return {
                                ...(data.message ?? {}),
                                ...(data.question ?? {}),
                                role: data.role ?? "moderator",
                                content: (!data.message?.content?.length && terminationReason
                                    ? interviewSettings?.[endingMessageKey]?.[lang]
                                    : data.message?.content) ?? "",
                                termination_reason: terminationReason
                            }
                        })
                    )

                // Changes running state
                if (data.running !== undefined) setRunning(data.running)
                // Updates survey's completion status
                if (data.completion) setCompletion(data.completion)
            } else if (event.data instanceof Blob && appendChunkRef) {
                // The audio chunk is received as a Blob. Convert it to an ArrayBuffer
                const blob = new Blob([event.data], { type: "audio/mpeg" })
                // append the blob to the audio chunk
                blob.arrayBuffer().then(appendChunkRef.current)
            }
        }

        setSocket(sock)
    }, [
        identifier,
        lang,
        messages.length,
        projectId,
        searchParams,
        setCompletion,
        setError,
        setMessages,
        setRunning,
        userId,
        setIsPlaying,
        interviewSettings
    ])
    // #endregion

    return {
        socket,
        initializeConnection,
        reconnectCountRef,
        appendChunkRef
    }
}