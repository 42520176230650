import { IFilter } from "@/@types/question"

/**
 * Merges filters with the same ID when their operator is "contains".
 * When a matching filter is found, their values are combined.
 * Otherwise, the current filter is added to the array.
 *
 * @param prev - The existing array of filters
 * @param curr - The current filter to merge or add
 * @returns A new array of filters with merged or added values
 */
export function packDuplicatedContainsFilters<TFilter extends IFilter = IFilter>(prev: TFilter[], curr: TFilter) {
    const newPrev = [...prev]

    // Pack analysis filters from the same ID
    const filterIdx = newPrev.findIndex(i => i.id === curr.id)
    if (filterIdx !== -1) {
        const filter = newPrev[filterIdx]
        if (filter.operator === "contains" && curr.operator === "contains")
            filter.value.push(...curr.value)
    } else {
        newPrev.push(curr)
    }

    return newPrev
}