import { AxiosInstance } from "axios"
import IInterviewGateway, {
    IInterviewGatewayAssignCategoryToInterviewRequest,
    IInterviewGatewayAssignCategoryToInterviewResponse,
    IInterviewGatewayDeleteInterviewRequest,
    IInterviewGatewayDeleteInterviewResponse,
    IInterviewGatewayGetFrequencyPerQuestionRequest,
    IInterviewGatewayGetFrequencyPerQuestionResponse,
    IInterviewGatewayGenerateSyntheticAnswersRequest,
    IInterviewGatewayGenerateSyntheticAnswersResponse,
    IInterviewGatewayGetInterviewLanguageRequest,
    IInterviewGatewayGetInterviewLanguageResponse,
    IInterviewGatewayGetInterviewsRequest,
    IInterviewGatewayGetInterviewsResponse,
    IInterviewGatewayGetRecordingUrlRequest,
    IInterviewGatewayGetRecordingUrlResponse,
    IInterviewGatewayGetFrequencyPerAnalysisRequest,
    IInterviewGatewayGetFrequencyPerAnalysisResponse,
    IInterviewGatewayRemoveCategoryFromInterviewRequest,
    IInterviewGatewayRemoveCategoryFromInterviewResponse,
    IInterviewGatewaySaveInterviewLanguageRequest,
    IInterviewGatewaySaveInterviewLanguageResponse,
    IInterviewGatewayStartWebRTCInterviewRequest,
    IInterviewGatewayStartWebRTCInterviewResponse,
    IInterviewGatewayUploadAssetRequest,
    IInterviewGatewayUploadAssetResponse,
    IInterviewGatewayReadSentenceRequest,
    IInterviewGatewayReadSentenceResponse
} from "../IInterviewGateway"
import { isDate } from "date-fns"

interface IInterviewsApiServiceProps {
    api: AxiosInstance
}

export default class InterviewsApiService implements IInterviewGateway {
    private readonly props: IInterviewsApiServiceProps

    constructor(props: IInterviewsApiServiceProps) {
        this.props = props
    }

    public async getInterviews(
        request: IInterviewGatewayGetInterviewsRequest
    ): Promise<IInterviewGatewayGetInterviewsResponse> {
        const {
            projectId,
            analysisFilters,
            completionStatus,
            identifier,
            languages,
            page,
            pageSize,
            platforms,
            questionFilters,
            sortBy,
            sortOrder,
            synthetic,
            lastUpdatedAfter
        } = request

        let actualSortOrder: number | undefined
        if (sortOrder !== undefined)
            actualSortOrder = sortOrder === "asc" ? 1 : -1

        const actualCompletionStatus = completionStatus !== undefined ? completionStatus === "completed" : undefined

        const actualPlatforms: string[] = []
        if (platforms?.includes("desktop"))
            actualPlatforms.push("desktop")

        if (platforms?.includes("tablet"))
            actualPlatforms.push("tablet")

        if (platforms?.includes("mobile"))
            actualPlatforms.push("mobile")

        let actualLastUpdatedAfter: string | undefined
        if (lastUpdatedAfter)
            actualLastUpdatedAfter = isDate(lastUpdatedAfter) ? lastUpdatedAfter.toISOString() : lastUpdatedAfter

        return await this.props.api
            .get(`projects/${projectId}/interviews`, {
                params: {
                    page,
                    page_size: pageSize,
                    sort_by: sortBy,
                    identifier,
                    languages: languages ? languages.join(",") : undefined,
                    sorting_order: actualSortOrder,
                    completion_status: actualCompletionStatus,
                    platforms: actualPlatforms.length > 0 ? actualPlatforms.join(",") : undefined,
                    analysis_filters: JSON.stringify(analysisFilters),
                    question_filters: JSON.stringify(questionFilters),
                    synthetic,
                    last_updated_after: actualLastUpdatedAfter
                }
            })
            .then(response => response.data)
    }

    public async getRecordingUrl(
        request: IInterviewGatewayGetRecordingUrlRequest
    ): Promise<IInterviewGatewayGetRecordingUrlResponse> {
        const { projectId, recordingId } = request

        return await this.props.api
            .get(`interviews/${projectId}/get-recording/${recordingId}`)
            .then(res => ({ recordingUrl: res.data }))
            .catch(e => {
                console.log(e)
                return { recordingUrl: "" }
            })
    }

    public async startWebRTCInterview(
        request: IInterviewGatewayStartWebRTCInterviewRequest
    ): Promise<IInterviewGatewayStartWebRTCInterviewResponse> {
        const { identifier, lang, prefills, projectId, useSttStreaming } = request

        return await this.props.api
            .post(`interviews/${projectId}/${identifier}/start/${lang}`, { prefills }, {
                params: {
                    use_stt_streaming: useSttStreaming ? "true" : undefined
                }
            }).then(res => ({
                token: res.data.token
            }))
            .catch(err => {
                console.log(err)

                return {
                    token: ""
                }
            })
    }

    public async saveInterviewLanguage(
        request: IInterviewGatewaySaveInterviewLanguageRequest
    ): Promise<IInterviewGatewaySaveInterviewLanguageResponse> {
        const { projectId, lang } = request

        localStorage.setItem(`interview-${projectId}-language`, lang)

        return Promise.resolve({
            isSuccessful: true
        })
    }

    public async getInterviewLanguage(
        request: IInterviewGatewayGetInterviewLanguageRequest
    ): Promise<IInterviewGatewayGetInterviewLanguageResponse> {
        const { projectId } = request

        return Promise.resolve({
            lang: localStorage.getItem(`interview-${projectId}-language`) ?? undefined
        })
    }

    public async assignCategoryToInterview(
        request: IInterviewGatewayAssignCategoryToInterviewRequest
    ): Promise<IInterviewGatewayAssignCategoryToInterviewResponse> {
        const { analysisId, categoryId, interviewId, projectId } = request

        return await this.props.api
            .post(`projects/${projectId}/interviews/${interviewId}/analysis-results/${analysisId}/meaning-units`, {
                category_id: categoryId
            })
    }

    public async removeCategoryFromInterview(
        request: IInterviewGatewayRemoveCategoryFromInterviewRequest
    ): Promise<IInterviewGatewayRemoveCategoryFromInterviewResponse> {
        const { analysisId, categoryId, interviewId, projectId } = request

        return await this.props.api.delete(
            `projects/${projectId}/interviews/${interviewId}/analysis-results/${analysisId}/meaning-units/${categoryId}`
        )
    }

    public async deleteInterview(
        request: IInterviewGatewayDeleteInterviewRequest
    ): Promise<IInterviewGatewayDeleteInterviewResponse> {
        const { interviewId, projectId } = request

        return await this.props.api
            .delete(`projects/${projectId}/interviews/${interviewId}`)
    }

    public async uploadAsset(
        request: IInterviewGatewayUploadAssetRequest
    ): Promise<IInterviewGatewayUploadAssetResponse> {
        const { asset, interviewId, projectId, questionId } = request

        const formData = new FormData()
        formData.append("asset", asset)
        formData.append("question_id", questionId)

        return await this.props.api
            .post(`interviews/${projectId}/${interviewId}/upload-asset`, formData)
            .then(res => ({ assetUrl: res.data.asset_url }))
    }

    public async getFrequencyPerQuestion(
        request: IInterviewGatewayGetFrequencyPerQuestionRequest
    ): Promise<IInterviewGatewayGetFrequencyPerQuestionResponse> {
        const { projectId } = request

        return await this.props.api
            .get(`projects/${projectId}/question-interviews-count`)
            .then(res => res.data)
    }

    public async getFrequencyPerAnalysis(
        request: IInterviewGatewayGetFrequencyPerAnalysisRequest
    ): Promise<IInterviewGatewayGetFrequencyPerAnalysisResponse> {
        const { projectId } = request

        return await this.props.api
            .get(`projects/${projectId}/analysis-interviews-count`)
            .then(res => res.data)
    }

    public async generateSyntheticAnswers(
        request: IInterviewGatewayGenerateSyntheticAnswersRequest
    ): Promise<IInterviewGatewayGenerateSyntheticAnswersResponse> {
        const { projectId, amount, lang } = request

        if (amount <= 0 || !Number.isInteger(amount))
            return Promise.reject(new Error("The amount must be a positive integer."))

        return await this.props.api
            .post(`projects/${projectId}/interviews/synthesize`, {}, { params: { lang, n: amount } })
            .then(res => res.data)
    }

    public async readSentence(
        request: IInterviewGatewayReadSentenceRequest
    ): Promise<IInterviewGatewayReadSentenceResponse> {
        const { projectId, sentence, lang } = request

        return await this.props.api
            .post(`interviews/${projectId}/read`, { sentence, lang }, { responseType: "blob" })
            .then(res => ({ audioBlob: res.data }))
    }
}
