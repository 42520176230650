import React, { createContext, useCallback, useEffect, useMemo, useRef, useState } from "react"

import { useParams } from "react-router-dom"
import useAutoSave from "../../hooks/useAutoSave"

import { findEdits } from "../../utils/Edits"
import { useWebSocket } from "../../hooks/useWebSocket"
import { useGlautAuthInfo } from "@hooks/useGlautAuthInfo"
import { getProjectLang } from "../../utils/language"

export const ProjectContext = createContext({})

export const ProjectProvider = ({ currentTab, children }) => {
    const [project, setProject] = useState(null)
    const [org, setOrg] = useState()
    const [originalProject, setOriginalProject] = useState(null)

    const { projectId } = useParams()
    const projectIdRef = useRef(projectId)
    const projectDefined = useRef(false)

    const { setCurrentWsId, currentProjectIsAtLeastRole } = useGlautAuthInfo()
    const { addCallback, sendMessage, socket } = useWebSocket()

    const canEdit = useMemo(
        () => currentProjectIsAtLeastRole("editor"),
        [currentProjectIsAtLeastRole]
    )

    // Instantiates the hook to use auto saving for the project object, and saves when the tab changes
    useAutoSave(
        () => {
            // If the projects are not defined it means the project is not loaded yet
            if (project && originalProject) {
                console.log("Saving ", projectIdRef.current)
                // Find edits in the project by comparing the current state of the project with the previous
                const edits = findEdits(originalProject, project).filter(edit => edit.path !== "completion")

                console.log("edits", edits)

                // If there were any edits, send a message to the websocket (Remove this once debouncing is implemented)
                if (edits.length > 0)
                    sendMessage({ operation: "update_project", edits, id: projectIdRef.current })

            }
        },
        project,
        socket?.readyState === WebSocket.OPEN,
        [currentTab]
    )

    // Register the websocket message handling function
    useEffect(() => {
        addCallback(data => {
            // We have received the updated version of this project
            if (data.updated_project?._id === projectIdRef.current) {
                setProject(data.updated_project)
                setOriginalProject(data.updated_project)
            }

            // We have received a new project on ws connection
            // We only overwrite the local state if it's a different project
            if (data.init_project?._id === projectIdRef.current && !projectDefined.current) {
                setProject(data.init_project)
                setOriginalProject(data.init_project)
            }

            // This stores the current org for future use
            if (data.project_org) setOrg(data.project_org)
        })
    }, [])

    // Stores in a ref wether or not we have a project in the state
    useEffect(() => {
        projectDefined.current = !!project
        setCurrentWsId(project?.workspace_id)
    }, [project])

    // Resets projects when loading
    useEffect(() => {
        setProject(null)
        setOriginalProject(null)
        projectIdRef.current = projectId
        projectDefined.current = false
    }, [projectId])

    // Used to force save the project at certain points
    const forceSave = useCallback(updatedProject => {
        const edits = findEdits(originalProject, updatedProject)
        sendMessage({ operation: "update_project", edits, id: project._id })
    }, [originalProject, project, sendMessage])

    const lang = useMemo(() => getProjectLang(project), [project])

    const state = useMemo(() => ({
        project,
        org,
        setProject: canEdit ? setProject : () => { },
        forceSave,
        canEdit,
        lang
    }), [canEdit, forceSave, org, project, lang])

    // Provide the project state and updater function to context consumers
    return (
        <ProjectContext.Provider value={state}>
            {children}
        </ProjectContext.Provider>
    )
}
