import CodeOccurrencesBox from "@components/Coding/CodeOccurrencesBox"
import { Text, View } from "@react-pdf/renderer"
import { isEllipsisActive } from "@utils/html-elements/is-ellipsis-active"
import { namedColors } from "@utils/Variables"
import { useEffect, useMemo, useRef, useState } from "react"
import { useReportDetails } from "../../contexts/ReportDetailsContext"
import { normalizeToColorIndex } from "@utils/styling/colors-from-index"

interface IReportDetailsInsightBlockThemeSegmentedThemeProps {
    colorIndex: number
    label: string
    occurrences: number
    frequency: number
}

export default function ReportDetailsInsightBlockThemeSegmentedTheme({
    colorIndex,
    frequency,
    label,
    occurrences
}: Readonly<IReportDetailsInsightBlockThemeSegmentedThemeProps>) {
    // #region Contexts
    const { renderAsPdf, tw } = useReportDetails()
    // #endregion

    // #region States
    const [hasTooltip, setHasTooltip] = useState(false)
    // #endregion

    // #region Refs
    const titleParagraphRef = useRef<HTMLParagraphElement>(null)
    // #endregion

    // #region Memos
    const backgroundColor = useMemo(
        () => namedColors[`color${normalizeToColorIndex(colorIndex)}-light`],
        [colorIndex]
    )
    // #endregion

    // #region Effects
    useEffect(() => {
        if (!titleParagraphRef.current) return
        setHasTooltip(isEllipsisActive(titleParagraphRef.current))
    }, [label])
    // #endregion

    if (renderAsPdf)
        return (
            <View style={[
                tw("flex flex-row items-center gap-[5.09px] rounded-full pl-[3px] pr-[3px]"),
                { backgroundColor }
            ]}>
                <CodeOccurrencesBox
                    number={occurrences}
                    percentage={frequency}
                    asPdf
                    twPdf={tw}
                />
                <View style={tw("max-w-40")}>
                    <Text style={[
                        tw("font-sans text-[8.48px] rounded-none"),
                        tw("text-glautTextMidnight")
                    ]}>
                        {label}
                    </Text>
                </View>
            </View>
        )

    return (
        <div
            className="flex flex-row gap-[0.25em] items-center rounded-full border-1
                pr-[0.3125em] pl-[0.0625em] max-w-[45%] border-transparent"
            style={{ backgroundColor }}
            data-tooltip-id="tooltip--glaut-app"
            data-tooltip-content={label}
            data-tooltip-place="right"
            data-tooltip-hidden={!hasTooltip}
        >
            <div className="py-[0.0625em]">
                <CodeOccurrencesBox
                    number={occurrences}
                    percentage={frequency}
                />
            </div>
            <p className={`text-[13.33px] text-ellipsis whitespace-nowrap overflow-hidden
                rounded-none leading-[18px]
                text-glaut-text-midnight
            `} ref={titleParagraphRef}>
                {label}
            </p>
        </div>
    )
}