import { IAnalysisType } from "@/@types/analysis"
import { questionEmojiFromType } from "@utils/analysis/labels"

interface IAnalysisTabAnalysisColBreakdownModalAnalysisSelectLabelProps {
    index: number
    label: string
    type: IAnalysisType
}

export default function AnalysisTabAnalysisColBreakdownModalAnalysisSelectLabel({
    index,
    label,
    type
}: Readonly<IAnalysisTabAnalysisColBreakdownModalAnalysisSelectLabelProps>) {
    return (
        <div className="flex flex-row items-center gap-[0.25em] cursor-pointer">
            <span className="text-[11.11px] font-medium text-glaut-grey">
                {`A${index + 1}`}
            </span>
            <span className="text-[11.11px] font-medium text-glaut-grey">
                {questionEmojiFromType(type)}
            </span>
            <span className={`text-[13.33px] font-medium text-ellipsis whitespace-nowrap overflow-hidden
                text-glaut-text-midnight
            `}>
                {label}
            </span>
        </div>
    )
}