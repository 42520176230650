import { createContext, useContext, useReducer } from "react"
import { IReport } from "src/@types/reports"

// #region Types
interface IReportsTabProviderProps {
    currOpenReport?: IReport
    children: React.ReactNode
}

interface IReportsTabContextState {
    currReportsPage: {
        id: string
        title: string
    }[]
    currOpenReport?: IReport
}

type IReportsTabContextAction = {
    type: "set-curr-reports-page"
    reports: IReportsTabContextState["currReportsPage"]
} | {
    type: "set-curr-open-report"
    report: NonNullable<IReportsTabContextState["currOpenReport"]>
} | {
    type: "close-curr-report"
}
// #endregion

// #region Context definitions
const ReportsTabContext = createContext({} as IReportsTabContextState)
const ReportsTabContextDispatch = createContext({} as React.Dispatch<IReportsTabContextAction>)
// #endregion

// #region Hook definitions
export function useReportsTab() {
    return useContext(ReportsTabContext)
}

export function useReportsTabDispatch() {
    return useContext(ReportsTabContextDispatch)
}
// #endregion

// #region Provider definition
export default function ReportsTabProvider({ children, currOpenReport }: Readonly<IReportsTabProviderProps>) {
    const initialState: IReportsTabContextState = {
        currReportsPage: [],
        currOpenReport
    }

    const [state, dispatch] = useReducer(ReportsTabReducer, initialState)

    return <ReportsTabContext.Provider value={state}>
        <ReportsTabContextDispatch.Provider value={dispatch}>
            {children}
        </ReportsTabContextDispatch.Provider>
    </ReportsTabContext.Provider>
}
// #endregion

// #region Reducer definition
function ReportsTabReducer(
    state: IReportsTabContextState,
    action: IReportsTabContextAction
): IReportsTabContextState {
    switch (action.type) {
        case "set-curr-reports-page": {
            return {
                ...state,
                currReportsPage: action.reports,
                currOpenReport: undefined
            }
        }
        case "set-curr-open-report": {
            return {
                ...state,
                currOpenReport: action.report
            }
        }
        case "close-curr-report": {
            return {
                ...state,
                currOpenReport: undefined
            }
        }
        default: {
            return state
        }
    }
}
// #endregion