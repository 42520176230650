import { IInterview } from "@/@types/entry"
import IProjectGateway from "@services/projects/IProjectGateway"
import React, { createContext, useContext, useReducer } from "react"
import {
    IInterviewQuestion,
    IQuestion,
    IQuestionDomain,
    IScreeningQuestion,
    questionDomains
} from "@/@types/interview-question"
import { ProjectContext as LegacyProjectContext } from "../../AutoSaveProject"
import { IProject } from "@/@types/project"
import { IOrganization } from "@/@types/organization"

// #region Type definitions
type AnalysisState = Awaited<ReturnType<IProjectGateway["getAnalysis"]>>

interface IProjectProviderProps {
    children: React.ReactNode
}

interface IProjectContextState {
    numberOfEntries: number
    /**
     * @deprecated
     */
    analysisData?: AnalysisState
    isAnalysisTabExportDataButtonActive: boolean
    projectQuestions?: IQuestion[]
    interviewQuestions?: IInterviewQuestion[]
    screeningQuestions?: IScreeningQuestion[]
    selectedInterview?: IInterview
    shouldDataTabRefetchInterviews: boolean
}

type IProjectContextAction = {
    type: "set-analysis"
    analysisData: AnalysisState
} | {
    type: "set-number-of-entries"
    numberOfEntries: number
} | {
    type: "set-analysis-tab-export-data-button-state"
    isActive: boolean
} | {
    type: "set-interview-questions"
    interviewQuestions: IQuestion[]
} | {
    type: "add-interview-question"
    interviewQuestion: IQuestion
} | {
    type: "update-interview-question"
    interviewQuestion: IQuestion
} | {
    type: "delete-interview-question"
    interviewQuestionId: string
} | {
    type: "set-interview-question-index"
    interviewQuestionId: string
    index: number
} | {
    type: "select-interview"
    interview: IInterview
} | {
    type: "clear-selected-interview"
} | {
    type: "force-data-tab-interviews-refetch"
} | {
    type: "clear-force-data-tab-interviews-refetch"
}
// #endregion

// #region Context definitions
const ProjectContext = createContext({} as IProjectContextState)
const ProjectContextDispatch = createContext({} as React.Dispatch<IProjectContextAction>)
// #endregion

// #region Hooks definitions
export function useProject() {
    return useContext(ProjectContext)
}

export function useProjectDispatch() {
    return useContext(ProjectContextDispatch)
}

export function useLegacyProject() {
    return useContext(LegacyProjectContext) as {
        org: IOrganization | null
        project: IProject | null
        setProject: React.Dispatch<React.SetStateAction<IProject | null>>
        forceSave: (updatedProject: IProject) => void
        canEdit: boolean
        lang: string
    }
}
// #endregion

// #region Util functions
function filterInterviewQuestions(questions: IQuestion[]): IInterviewQuestion[] {
    return questions.filter(q => q.domain === "interview")
}

function filterScreeningQuestions(questions: IQuestion[]): IScreeningQuestion[] {
    return questions.filter(q => q.domain === "screening")
}
// #endregion

// #region Provider definition
export default function ProjectProvider({
    children
}: Readonly<IProjectProviderProps>) {
    const initialState: IProjectContextState = {
        numberOfEntries: 0,
        isAnalysisTabExportDataButtonActive: false,
        shouldDataTabRefetchInterviews: false
    }

    const [state, dispatch] = useReducer(ProjectReducer, initialState)

    return (
        <ProjectContext.Provider value={state}>
            <ProjectContextDispatch.Provider value={dispatch}>
                {children}
            </ProjectContextDispatch.Provider>
        </ProjectContext.Provider>
    )
}
// #endregion

// #region Reducer definition
function ProjectReducer(state: IProjectContextState, action: IProjectContextAction): IProjectContextState {
    switch (action.type) {
        case "set-analysis": {
            return {
                ...state,
                analysisData: action.analysisData
            }
        }
        case "set-number-of-entries": {
            return {
                ...state,
                numberOfEntries: action.numberOfEntries
            }
        }
        case "set-analysis-tab-export-data-button-state": {
            return {
                ...state,
                isAnalysisTabExportDataButtonActive: action.isActive
            }
        }
        case "set-interview-questions": {
            return {
                ...state,
                projectQuestions: action.interviewQuestions,
                interviewQuestions: filterInterviewQuestions(action.interviewQuestions),
                screeningQuestions: filterScreeningQuestions(action.interviewQuestions)
            }
        }
        case "add-interview-question": {
            if (!state.projectQuestions) return state

            if (state.projectQuestions.some(iq => iq.id === action.interviewQuestion.id)) return state

            const newQuestions = [
                ...(state.projectQuestions ?? []),
                action.interviewQuestion
            ]

            return {
                ...state,
                projectQuestions: newQuestions,
                interviewQuestions: filterInterviewQuestions(newQuestions),
                screeningQuestions: filterScreeningQuestions(newQuestions)
            }
        }
        case "update-interview-question": {
            if (!state.projectQuestions) return state

            const newQuestions = state.projectQuestions.map(iq => {
                if (iq.id === action.interviewQuestion.id)
                    return action.interviewQuestion

                return iq
            })

            return {
                ...state,
                projectQuestions: newQuestions,
                interviewQuestions: filterInterviewQuestions(newQuestions),
                screeningQuestions: filterScreeningQuestions(newQuestions)
            }
        }
        case "delete-interview-question": {
            if (!state.projectQuestions) return state

            const newQuestions = state.projectQuestions.filter(iq => iq.id !== action.interviewQuestionId)
            if (newQuestions.length === state.projectQuestions.length) return state

            return {
                ...state,
                projectQuestions: newQuestions,
                interviewQuestions: filterInterviewQuestions(newQuestions),
                screeningQuestions: filterScreeningQuestions(newQuestions)
            }
        }
        case "set-interview-question-index": {
            if (!state.projectQuestions || !state.interviewQuestions || !state.screeningQuestions) return state

            const desiredQuestionIdx = state.projectQuestions.findIndex(iq => iq.id === action.interviewQuestionId)
            if (desiredQuestionIdx === -1) return state

            const desiredQuestion = state.projectQuestions[desiredQuestionIdx]
            const questionType = desiredQuestion.domain

            // sort for index update later
            const tempQuestions = [...state.projectQuestions]
            tempQuestions.sort((a, b) => {
                if (a.domain !== b.domain) {
                    if (a.domain === "screening") return -1
                    if (b.domain === "screening") return 1

                    return 0
                }

                return a.index - b.index
            })

            // Remove (will be just 1)
            const tempRemovedIqs = tempQuestions.splice(desiredQuestionIdx, 1)

            // Insert back
            const shiftAmount: { [domain in IQuestionDomain]: number } = {
                screening: 0,
                interview: state.screeningQuestions.length
            }

            tempQuestions.splice(action.index + shiftAmount[questionType], 0, ...tempRemovedIqs)

            // Update indexes
            const currentIdx = questionDomains.reduce((acc, domain) => {
                acc[domain] = 0
                return acc
            }, {} as { [domain in IQuestionDomain]: number })

            tempQuestions.forEach(iq => {
                iq.index = currentIdx[iq.domain]
                currentIdx[iq.domain]++
            })

            return {
                ...state,
                projectQuestions: tempQuestions,
                interviewQuestions: filterInterviewQuestions(tempQuestions),
                screeningQuestions: filterScreeningQuestions(tempQuestions)
            }
        }
        case "select-interview": {
            return {
                ...state,
                selectedInterview: action.interview
            }
        }
        case "clear-selected-interview": {
            return {
                ...state,
                selectedInterview: undefined
            }
        }
        case "force-data-tab-interviews-refetch": {
            return {
                ...state,
                shouldDataTabRefetchInterviews: true
            }
        }
        case "clear-force-data-tab-interviews-refetch": {
            return {
                ...state,
                shouldDataTabRefetchInterviews: false
            }
        }
        default: {
            return state
        }
    }
}
// #endregion