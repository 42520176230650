import { IQuestionType } from "@/@types/interview-question"
import SimulateImage from "@assets/simulate.svg"
import FloatingLayout from "@components/layouts/FloatingLayout"
import { copy, getCopy } from "@utils/Copy"
import { questionTypeDetails } from "@utils/project/interview-questions"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { MdAddCircleOutline, MdPlaylistPlay } from "react-icons/md"
import { useOutlineTabIqs, useOutlineTabIqsDispatch } from "../../contexts/OutlineTabIqsProvider"
import { useOutlineTab } from "../../contexts/OutlineTabProvider"
import { useLegacyProject, useProject, useProjectDispatch } from "../../contexts/ProjectProvider"
import OutlineTabContentIqsQuestionListItem from "../OutlineTabContentIqsQuestionListItem"
import { DragDropContext, OnDragEndResponder } from "react-beautiful-dnd"
import { Drop } from "@components/menus/DragAndDrop"
import { useProjectService } from "@hooks/services/useProjectService"
import { glautTooltipId } from "@components/layouts/GlautTooltip/constants/id"
import { useToastDispatch } from "@contexts/ToastProvider"
import ErrorLevel from "@utils/ErrorLevel"
import { isAxiosError } from "axios"
import * as Sentry from "@sentry/react"
import GlautButtonSecondary from "@components/Buttons/GlautButtonSecondary"

export default function OutlineTabContentIqsQuestionList() {
    // #region Contexts
    const { project } = useLegacyProject()
    const { interviewQuestions } = useProject()
    const projectDispatch = useProjectDispatch()
    const { canEdit } = useOutlineTab()
    const { iqToBeBuilt, isEditing, isSimulateQuestionSidebarOpen } = useOutlineTabIqs()
    const outlineTabIqsDispatch = useOutlineTabIqsDispatch()
    const toastDispatch = useToastDispatch()
    // #endregion

    // #region Services
    const projectService = useProjectService()
    // #endregion

    // #region States
    const [isChooseQuestionTypePopupOpen, setIsChooseQuestionTypePopupOpen] = useState(false)
    // #endregion

    // #region Refs
    const createQuestionButtonRef = useRef<HTMLButtonElement>(null)
    // #endregion

    // #region Memos
    const sortedIqs = useMemo(() => {
        if (!interviewQuestions) return []

        const sortedIqs = [...interviewQuestions]
        sortedIqs.sort((a, b) => a.index - b.index)

        return sortedIqs
    }, [interviewQuestions])
    // #endregion

    // #region Callbacks
    const handleAddNewQuestion = useCallback(() => {
        setIsChooseQuestionTypePopupOpen(true)
    }, [])

    const handleSelectQuestionType = useCallback((slug: IQuestionType) => {
        outlineTabIqsDispatch({ type: "set-iq-to-be-built", iq: { type: slug } })
        setIsChooseQuestionTypePopupOpen(false)
    }, [outlineTabIqsDispatch])

    const onDragEnd: OnDragEndResponder = result => {
        if (!project) return

        const { destination, source } = result

        // cancelled drag / dragged outside droppable area
        if (!destination) return

        // source === destination
        if (destination.index === source.index) return

        const question = interviewQuestions?.find(q => q.index === source.index)
        if (!question) return

        const index = destination.index
        const questionId = question.id

        projectDispatch({ type: "set-interview-question-index", interviewQuestionId: questionId, index })

        projectService.setInterviewQuestionOrder({ projectId: project._id, index, questionId }).then(iqs => {
            outlineTabIqsDispatch({
                type: "set-iq-attribute",
                attribute: "last_updated",
                value: iqs.find(iq => iq.id === questionId)?.last_updated ?? new Date().toISOString(),
                overrideOriginalIq: true
            })

            outlineTabIqsDispatch({
                type: "set-iq-attribute",
                attribute: "index",
                value: index,
                overrideOriginalIq: true
            })
        }).catch(e => {
            Sentry.captureException(e, scope => {
                scope.setContext("data", {
                    projectId: project._id,
                    questionId,
                    sourceIndex: source.index,
                    destinationIndex: destination.index
                })

                return scope
            })

            projectDispatch({
                type: "set-interview-question-index",
                interviewQuestionId: questionId,
                index: source.index
            })

            if (
                isAxiosError(e) &&
                e.response?.status === 400 &&
                e.response?.data?.error.error_type === "question_moved_before_filter"
            ) {
                toastDispatch({
                    type: "add-toast", payload: {
                        title: "Invalid reordering",
                        detail: "You attempted to move a question before one of its filter questions. Remove the " +
                            "question filters and try again.",
                        messageLevel: ErrorLevel.Error
                    }
                })

                return
            }

            toastDispatch({
                type: "add-toast", payload: {
                    title: "Reordering not possible",
                    detail: "It was not possible to reorder the questions. Please reload the page and try again.",
                    messageLevel: ErrorLevel.Error
                }
            })
        })
    }

    const onShowQuestionsList = useCallback(() => {
        outlineTabIqsDispatch({ type: "close-simulate-question-sidebar" })
    }, [outlineTabIqsDispatch])
    // #endregion

    // #region Effects

    // onFirstLoadAutoSelectFirstQuestion
    useEffect(() => {
        if (!interviewQuestions?.length || iqToBeBuilt !== undefined) return

        outlineTabIqsDispatch({
            type: "set-iq-to-be-built",
            iq: interviewQuestions[0],
            isEditing: true,
            shouldReloadOriginalIq: true
        })
    }, [interviewQuestions, iqToBeBuilt, outlineTabIqsDispatch])

    // #endregion

    if (!project) return <></>

    if (isSimulateQuestionSidebarOpen)
        return (
            <button
                type="button"
                className="p-[0.5rem] rounded-[0.25rem] border-1 group
                    shadow-none m-0
                    border-transparent
                    bg-glaut-cards
                    hover:bg-glaut-dummie-color hover:border-glaut-grey"
                onClick={onShowQuestionsList}
                data-tooltip-id={glautTooltipId}
                data-tooltip-content={getCopy(copy.outline.interviewQuestions.showQuestionsList)}
                data-tooltip-place="top-start"
            >
                <MdPlaylistPlay className="h-[1em] w-[1em] text-glaut-dark-grey" />
            </button>
        )

    return (
        <div className="flex-1 flex flex-col gap-[0.5rem]">
            <div className="flex justify-between">
                <p className="text-[16px] text-glaut-bar">
                    {getCopy(copy.outline.interviewQuestions.questionList)?.toUpperCase()}
                </p>
                <GlautButtonSecondary
                    onClick={handleAddNewQuestion}
                    disabled={!canEdit}
                    ref={createQuestionButtonRef}
                    data-tooltip-id={glautTooltipId}
                    data-tooltip-content={getCopy(copy.outline.interviewQuestions.addQuestion)}
                    data-tooltip-hidden={!canEdit}
                    className="group"
                    squared
                >
                    <MdAddCircleOutline className="w-[1.125rem] h-[1.125rem]" />
                </GlautButtonSecondary>
            </div>
            {!sortedIqs.length && !iqToBeBuilt && (
                <div className="flex flex-col justify-center items-center rounded-[0.5rem] bg-glaut-cards flex-1">
                    <img src={SimulateImage} alt="simulate" />
                    <p className="text-[16px] text-glaut-dummie-color">
                        {getCopy(copy.outline.interviewQuestions.noQuestionsCreatedYet)}
                    </p>
                </div>
            )}
            {(!!sortedIqs.length || !!iqToBeBuilt) && (
                <div className="flex-1 gap-[0.5em] overflow-auto pr-1">
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Drop id="root" type="question" disabled={!canEdit}>
                            <div className="flex flex-col gap-[0.5em]">
                                {sortedIqs.map((iq, iqIdx) => (
                                    <OutlineTabContentIqsQuestionListItem
                                        key={iq.id}
                                        interviewQuestion={iq}
                                        index={iqIdx}
                                    />
                                ))}
                                {!isEditing && iqToBeBuilt && (
                                    <OutlineTabContentIqsQuestionListItem
                                        interviewQuestion={iqToBeBuilt}
                                        index={sortedIqs.length}
                                    />
                                )}
                            </div>
                        </Drop>
                    </DragDropContext>
                </div>
            )}
            {isChooseQuestionTypePopupOpen && (
                <FloatingLayout
                    anchorRef={createQuestionButtonRef}
                    className="absolute rounded-[0.5em] p-[0.5em] border-1 min-w-40 flex flex-col z-[999999999999]
                        bg-glaut-off-white border-glaut-grey shadow-[0px_0px_8px_0px_rgb(0,0,0,0.15)]"
                    onClose={() => { setIsChooseQuestionTypePopupOpen(false) }}
                >
                    <p className="text-[11.11px] font-medium text-glaut-stroke-button pl-[0.75em]">
                        {getCopy(copy.outline.interviewQuestions.chooseTheTypeOfQuestionToAdd)}:
                    </p>
                    <div className="flex flex-col gap-[0.75em]">
                        {Object.entries(questionTypeDetails()).filter(([, o]) => !o.isHidden).map(([slug, o]) => (
                            <button
                                key={slug}
                                onClick={() => { handleSelectQuestionType(slug as IQuestionType) }}
                                className="flex flex-col gap-[0.25em] py-[0.5em] px-[0.75em] w-full cursor-pointer
                                    border-1 rounded-[0.5em]
                                    shadow-none items-start
                                    border-glaut-cards
                                    hover:bg-glaut-cards"
                            >
                                <p className="text-[16px] font-medium text-glaut-text-midnight">
                                    {o.title}
                                </p>
                                <span className="text-[11.11px] font-medium text-[#666666]">
                                    {o.description}
                                </span>
                            </button>
                        ))}
                    </div>
                </FloatingLayout>
            )}
        </div>
    )
}