import { FFmpeg } from "@ffmpeg/ffmpeg"
import { fetchFile } from "@ffmpeg/util"
import { FileData } from "node_modules/@ffmpeg/ffmpeg/dist/esm/types"

/**
 * Converts an web audio file to mp3.
 * 
 * @see https://ffmpegwasm.netlify.app/docs/getting-started/usage#transcode-webm-to-mp4-video
 * @see https://github.com/ffmpegwasm/ffmpeg.wasm/issues/532
 * @see https://github.com/WebAssembly/spec/issues/1031
 */
export const convertToMp3 = async (inputUrl: string, originalFormat: string, targetFormat: string) => {

    const inputFileName = "input." + originalFormat
    const outputFileName = "output." + targetFormat

    // Loads ffmpeg instance
    const ffmpeg = new FFmpeg()
    await ffmpeg.load()

    // Loads original file from URL
    await ffmpeg.writeFile(inputFileName, await fetchFile(inputUrl))

    // Converts into target format and returns a URL
    await ffmpeg.exec(["-i", inputFileName, outputFileName])
    const data = await ffmpeg.readFile(outputFileName) as FileData & { buffer: string }

    const blob = new Blob([data.buffer], { type: "audio/mp3" })
    const url = URL.createObjectURL(blob)

    return { blob, url }
}
