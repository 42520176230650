import Cloud from "@assets/cloud.svg"
import Exclamation from "@assets/exclamation.png"
import GlautLogo from "@assets/GlautLogo.png"
import Separator from "@assets/separator.png"
import { copy, getCopy } from "@utils/Copy"
import "./index.scss"
import { useMemo } from "react"

export enum ErrorLevel {
    Error = 40,
    Warning = 30,
    Info = 20
}

export interface IErrorPageProps {
    lang?: string
    code?: number
    message?: string
    detail?: React.ReactNode
    codeDetail?: string
    showCode?: boolean
    errorLevel?: ErrorLevel
    eventName?: string
    icon?: string
    iconExtraClass?: string
    separatorExtraClass?: string
}

const ErrorPage = ({
    lang,
    code,
    message,
    detail,
    icon,
    showCode = true,
    errorLevel = ErrorLevel.Warning,
    iconExtraClass = "",
    separatorExtraClass = ""
}: Readonly<IErrorPageProps>) => {
    // #region Memos
    const messagePrefix = useMemo(() => showCode && code ? code + ": " : "", [showCode, code])
    const messageContent = useMemo(
        () => (message || getCopy<string>(copy[`error${code}`], lang) || getCopy(copy.errorGeneric, lang)) ?? "",
        [code, lang, message]
    )

    const actualMessage = useMemo(
        () => <>
            {messagePrefix}
            {messageContent}
        </>,
        [messagePrefix, messageContent]
    )

    const actualDetail = useMemo(
        () => (
            detail
            || getCopy<string>(copy[`error${code}Detail`], lang)
            || getCopy(copy.errorGenericDetail, lang)
        ) ?? "",
        [code, detail, lang]
    )

    const actualIcon = useMemo(() => {
        if (icon) return icon

        if (errorLevel >= ErrorLevel.Warning)
            return errorLevel >= ErrorLevel.Error ? Exclamation : Cloud

        return GlautLogo
    }, [icon, errorLevel])
    // #endregion

    return (
        <div className="error-page">
            <div className="error-message">
                <img className={"error-icon " + iconExtraClass} src={actualIcon} />
                <h2>{actualMessage}</h2>
                <img className={"separator " + separatorExtraClass} src={Separator} />
                <div className="detail">{actualDetail}</div>
            </div>
        </div>
    )
}

export default ErrorPage
