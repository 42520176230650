import { VerbatimHeader, VerbatimRow } from "@components/Coding/Verbatims"
import { useInterviewService } from "@hooks/services/useInterviewService"
import { useGlautAuthInfo } from "@hooks/useGlautAuthInfo"
import { copy, getCopy } from "@utils/Copy"
import { getProjectLang } from "@utils/language"
import { useContext, useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import { IInterview } from "src/@types/entry"
import { IProject } from "src/@types/project"
import { ProjectContext } from "../../../AutoSaveProject"
import { useProject } from "../../contexts/ProjectProvider"
import { normalizeCSVData } from "../../utils/normalize-csv-data"
import Loading from "@components/loading/Loading"

const settingsCopy = copy.coding.settings

interface ICSVSettingsTabVerbatimsPreviewProps {
    fileContent?: ReturnType<typeof normalizeCSVData>
    selectedColumn?: number
}

export default function CSVSettingsTabVerbatimsPreview({
    fileContent,
    selectedColumn
}: Readonly<ICSVSettingsTabVerbatimsPreviewProps>) {
    // #region Propelauth
    const { isLoggedIn } = useGlautAuthInfo()
    // #endregion

    // #region Params
    const { projectId } = useParams()
    // #endregion

    // #region Contexts
    const { interviewQuestions } = useProject()
    const { project } = useContext(ProjectContext) as { project: IProject | null }
    // #endregion

    // #region Services
    const interviewService = useInterviewService()
    // #endregion

    // #region States
    const [entries, setEntries] = useState<IInterview[]>()
    // #endregion

    // #region Memos

    const lang = useMemo(() => getProjectLang(project), [project])

    // Set questionIds from schema
    const questionIds = useMemo(
        () => interviewQuestions?.map(q => q.id) ?? [],
        [interviewQuestions]
    )
    // Set headers from file or from schema
    const headers = useMemo(
        () => fileContent
            ? fileContent.headers
            : ["ID"].concat(interviewQuestions?.map(q => q.content[lang] ?? q.header ?? "") ?? []),
        [fileContent, interviewQuestions, lang]
    )
    // Set rows from file or from entries
    const rows = useMemo(
        () => fileContent
            ? fileContent.rows
            : (entries ?? []).map(e => [e.identifier].concat(
                questionIds.map(
                    id => e.messages.filter(m => m.role === "respondent" && m.question_id === id).map(m => m.content)
                ).flat()
            )),
        [fileContent, entries, questionIds]
    )

    // #endregion

    // #region Effects

    // Load entries if needed
    useEffect(() => {
        if (fileContent || !projectId || !isLoggedIn) return

        interviewService.getInterviews({ projectId })
            .then(response => {
                setEntries(response.interviews)
            })
            .catch(e => console.log(e))
    }, [isLoggedIn, fileContent, interviewService, projectId])

    // #endregion

    if (!fileContent && !entries)
        return (
            <div className="flex justify-center w-full">
                <Loading />
            </div>
        )

    return (
        <div className="verbatims-preview max-w-[60%]">
            <h2 className="title">
                {getCopy(settingsCopy.preview)}
            </h2>
            <div className="scroll">
                <div className="verbatims-table">
                    <VerbatimHeader headers={headers} selectedColumn={selectedColumn} />
                    {rows.map((r, idx) => (
                        <VerbatimRow
                            key={`${r[0]}-${idx}`}
                            row={r}
                            selectedColumn={selectedColumn}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}