export const glautRateModalityComponentCopy = {
    netPromoterScore: {
        en: "Net promoter score"
    },
    customScore: {
        en: "Custom score"
    },
    defineTheScaleForTheScore: {
        en: "Define the scale for the score"
    },
    fromCapital: {
        en: "From"
    },
    to: {
        en: "to"
    },
    minLabelCopy: {
        en: "Min label copy"
    },
    maxLabelCopy: {
        en: "Max label copy"
    },
    writeALabelHereEllipsis: {
        en: "Write a label here..."
    }
}