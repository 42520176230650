import { useMemo } from "react"
import { useAnalysisTabAnalysisCol } from "../contexts/AnalysisTabAnalysisColProvider"
import { useAnalysisTab } from "../contexts/AnalysisTabProvider"

export function useAnalysisTabAnalysisColDraggable() {
    // #region Contexts
    const { selectedBreakdown } = useAnalysisTab()
    const {
        categoryBeingMerged,
        categoryBeingUsedToCreateSubTheme
    } = useAnalysisTabAnalysisCol()
    // #endregion

    // #region Memos
    const isAllowedToDrag = useMemo(
        () => categoryBeingMerged === undefined &&
            categoryBeingUsedToCreateSubTheme === undefined &&
            selectedBreakdown === undefined,
        [categoryBeingMerged, categoryBeingUsedToCreateSubTheme, selectedBreakdown]
    )
    // #endregion

    return { isAllowedToDrag }
}