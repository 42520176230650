import { ChangeEventHandler, useCallback, useMemo, useState } from "react"
import OutlineTabContentDefaultTitle from "../OutlineTabContentDefaultTitle"
import { copy, getCopy } from "@utils/Copy"
import { useLegacyProject } from "../../contexts/ProjectProvider"
import { useOutlineTab } from "../../contexts/OutlineTabProvider"
import { useDebouncedInputChange } from "@hooks/useDebouncedInputChange"

interface IOutlineTabContentBrandingColorPickerProps {
    resource: "font" | "background"
}

export default function OutlineTabContentBrandingColorPicker({
    resource
}: Readonly<IOutlineTabContentBrandingColorPickerProps>) {
    // #region Contexts
    const { project, setProject } = useLegacyProject()
    const { canEdit } = useOutlineTab()
    // #endregion

    // #region States
    const [currHexColor, setCurrHexColor] = useState(
        project?.source === "interview"
            ? (resource === "font" ? project.brand_settings.accent_color : project.brand_settings.background_color)
            : undefined
    )
    // #endregion

    // #region Memos
    const title = useMemo(() => getCopy(
        resource === "font" ? copy.outline.branding.fontColor : copy.outline.branding.backgroundColor
    ) ?? "", [resource])
    // #endregion

    // #region Callbacks
    const handleChooseColor: ChangeEventHandler<HTMLInputElement> = useCallback(ev => {
        const color = ev.target.value
        const attribute: "accent_color" | "background_color" = resource === "font"
            ? "accent_color"
            : "background_color"

        setProject(prev => {
            if (prev?.source !== "interview") return prev

            return {
                ...prev,
                brand_settings: {
                    ...prev.brand_settings,
                    [attribute]: color
                }
            }
        })
    }, [resource, setProject])
    // #endregion

    // #region Debounced inputs
    const { debouncedOnChange } = useDebouncedInputChange({
        preCallback: ev => { setCurrHexColor(ev.target.value) },
        callback: handleChooseColor
    })
    // #endregion

    return (
        <label className="flex flex-row justify-between gap-[1.5em] py-[0.625em] px-[0.75em] border-1 rounded-[0.25em]
            w-full h-full
            shadow-none text-left
            bg-glaut-off-white border-glaut-stroke-glaut">
            <OutlineTabContentDefaultTitle
                title={title}
                subtitle={currHexColor ?? "Pick a color"}
                isSmall
            />
            <div
                className="h-full aspect-square border-1 border-glaut-stroke-button cursor-pointer"
                style={{ backgroundColor: currHexColor }}
            >
                <input
                    className="invisible"
                    type="color"
                    value={currHexColor}
                    onChange={debouncedOnChange}
                    disabled={!canEdit}
                    style={{ backgroundColor: currHexColor, borderColor: currHexColor }}
                />
            </div>
        </label>
    )
}