import FloatingLayout from "@components/layouts/FloatingLayout"
import { MdClose } from "react-icons/md"
import { copy, getCopy } from "@utils/Copy"
import { MutableRefObject } from "react"
import { IAnalysisType } from "@/@types/analysis"
import { newAnalysisOptions } from "@/constants/analyses/options"

interface INewAnalysisPopupProps {
    onClose?: () => void
    onSelect?: (analysisType: IAnalysisType) => void
    anchorRef?: MutableRefObject<HTMLElement | null>
    place?: "right" | "left"
    horizontalOffset?: number
}

export default function NewAnalysisPopup({
    onClose,
    onSelect,
    anchorRef,
    horizontalOffset,
    place = "right"
}: Readonly<INewAnalysisPopupProps>) {
    return (
        <FloatingLayout
            id="div--new-analysis-popup"
            className="absolute w-96 ml-2 z-[500000] overflow-hidden shadow-[0px_0px_8px_0px_#00000026]"
            anchorRef={anchorRef}
            onClose={onClose}
            place={place}
            horizontalOffset={horizontalOffset}
            boundingBoxOffset={10}>
            <div className="flex flex-col py-[0.625em] px-[0.9375em] border-1 bg-white border-glaut-stroke-glaut">
                <div className="flex flex-row justify-between">
                    <p className="font-medium text-[11.11px] text-glaut-text-midnight">
                        {getCopy(copy.coding.chooseTheTypeOfAnalysisToCreate)}
                    </p>
                    <MdClose
                        className="text-glaut-text-midnight w-[0.875em] h-[0.875em] cursor-pointer"
                        onClick={onClose}
                    />
                </div>
                {newAnalysisOptions.map(option => (
                    <button
                        key={option.type}
                        className={`flex flex-col gap-[0.25em] p-[0.5em] border-0 w-full transition-colors
                            shadow-none text-left border-t-1 border-t-glaut-cards items-start
                            hover:bg-glaut-cards
                        `}
                        onClick={() => { onSelect?.(option.type) }}
                    >
                        <p className="font-medium text-[13.33px] text-glaut-text-midnight">
                            {option.title}
                        </p>
                        <p className="font-medium text-[11.11px] text-[#666666]">
                            {option.description}
                        </p>
                    </button>
                ))}
            </div>
        </FloatingLayout>
    )
}