import { Fragment, useCallback } from "react"
import { IGlautAssetComponentType } from "../../constants/types"
import { useGlautAssetComponent } from "../../contexts/GlautAssetComponentProvider"
import GlautAssetComponentSelectedOptionImage from "../GlautAssetComponentSelectedOptionImage"
import GlautAssetComponentSelectedOptionUrlParameter from "../GlautAssetComponentSelectedOptionUrlParameter"
import GlautAssetComponentSelectedOptionVideo from "../GlautAssetComponentSelectedOptionVideo"

export default function GlautAssetComponentSelectedOptions() {
    // #region Contexts
    const { selectedOptions } = useGlautAssetComponent()
    // #endregion

    // #region Element callbacks
    const optionElement = useCallback((selectedOption: IGlautAssetComponentType) => {
        switch (selectedOption) {
            case "image": {
                return <GlautAssetComponentSelectedOptionImage />
            }
            case "video": {
                return <GlautAssetComponentSelectedOptionVideo />
            }
            case "url-parameter": {
                return <GlautAssetComponentSelectedOptionUrlParameter />
            }
            default: {
                return <></>
            }
        }
    }, [])
    // #endregion

    return (
        <div className="flex flex-col gap-[0.5em]">
            {selectedOptions.map(type => (
                <Fragment key={type}>
                    {optionElement(type)}
                </Fragment>
            ))}
        </div>
    )
}