import { MouseEvent as ReactMouseEvent } from "react"

interface IAnalysisTabAnalysisColThemeAndCodeActionsMenuItemProps {
    label: string
    icon: JSX.Element
    onClick?: (e: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => void
    disabled?: boolean
}

export default function AnalysisTabAnalysisColThemeAndCodeActionsMenuItem({
    icon,
    label,
    onClick,
    disabled
}: Readonly<IAnalysisTabAnalysisColThemeAndCodeActionsMenuItemProps>) {
    return (
        <button
            className={`flex flex-row gap-[0.5em] px-[0.75em] py-[0.5em] border-none rounded-none shadow-none w-full
                justify-start bg-transparent
                hover:bg-glaut-cards
            `}
            onClick={onClick}
            disabled={disabled}
        >
            {icon}
            <p className="text-[13.33px] text-glaut-text-midnight">
                {label}
            </p>
        </button>
    )
}