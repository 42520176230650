import ConversationOverlay from "@components/ConversationOverlay"
import { UserProvider } from "@hooks/useGlautAuthInfo"
import React, { useCallback, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import Loading from "../../components/loading/Loading"
import { useInterviewService } from "../../hooks/services/useInterviewService"
import { useProjectService } from "../../hooks/services/useProjectService"
import { WebSocketProvider } from "../../hooks/useWebSocket"
import { ProjectProvider as ProjectProviderLegacy } from "./AutoSaveProject"
import AnalysisTab from "./ProjectArea/components/AnalysisTab"
import CSVSettingsTab from "./ProjectArea/components/CSVSettingsTab"
import DataTab from "./ProjectArea/components/DataTab"
import OutlineTab from "./ProjectArea/components/OutlineTab"
import ReportsTab from "./ProjectArea/components/ReportsTab"
import SettingsTab from "./ProjectArea/components/SettingsTab"
import SourceChooser from "./ProjectArea/components/SourceChooser"
import TopBar from "./ProjectArea/components/TopBar"
import ProjectProvider, {
    useLegacyProject,
    useProject,
    useProjectDispatch
} from "./ProjectArea/contexts/ProjectProvider"

const ProjectContent = ({ currentTab, setCurrentTab }) => {
    // #region Navigation hooks
    const navigate = useNavigate()
    const { projectId } = useParams()
    // #endregion

    // #region Contexts
    const { project, setProject, lang } = useLegacyProject()
    const { interviewQuestions, numberOfEntries, selectedInterview, screeningQuestions } = useProject()
    const projectDispatch = useProjectDispatch()
    // #endregion

    // #region Services
    const projectService = useProjectService()
    const interviewService = useInterviewService()
    // #endregion

    // #region Callback
    const handleProjectDataFetch = useCallback(() => {
        if (!projectId) return

        const projectEntriesPromise = interviewService.getInterviews({ projectId })
        const projectAnalysisPromise = projectService.getAnalysis({ projectId })
        const projectInterviewQuestionsPromise = projectService.getInterviewQuestions({ projectId })

        Promise.all([
            projectEntriesPromise,
            projectAnalysisPromise,
            projectInterviewQuestionsPromise
        ]).then(([
            projectEntries,
            projectAnalysis,
            projectInterviewQuestions
        ]) => {
            projectDispatch({ type: "set-analysis", analysisData: projectAnalysis })
            projectDispatch({ type: "set-number-of-entries", numberOfEntries: projectEntries.total_interviews ?? 0 })
            projectDispatch({
                type: "set-interview-questions",
                interviewQuestions: projectInterviewQuestions.interviewQuestions
            })
        })
    }, [interviewService, projectService, projectId, projectDispatch])

    const setSource = useCallback(source => { setProject(p => ({ ...p, source })) }, [setProject])

    const handleDeleteProject = useCallback(() => {
        projectService.deleteProject({ projectId })
            .then(() => navigate("/"))
            .catch(e => console.log(e))
    }, [navigate, projectId, projectService])

    const handleSelectCsvSource = useCallback(() => {
        setSource("CSV")
        setCurrentTab(5)
    }, [setSource, setCurrentTab])

    const handleSelectInterviewSource = useCallback(() => {
        setSource("interview")
        setCurrentTab(0)
    }, [setSource, setCurrentTab])
    // #endregion

    // #region Effects

    // Defaults to the data tab when changing project or to the correct CSV view
    useEffect(() => {
        if (currentTab !== null || !project?.source || !interviewQuestions) return

        const isCsvProject = project.source === "CSV"
        if (isCsvProject) {
            setCurrentTab(interviewQuestions.length ? 2 : 5)
            return
        }

        setCurrentTab(1)
    }, [project, currentTab, setCurrentTab, interviewQuestions])

    // onLoadProjectGetProjectData
    useEffect(() => {
        if (!project?.source) return

        handleProjectDataFetch()

        const timeout = 1000 * 60 * 2 // minutes
        const interval = setInterval(() => {
            handleProjectDataFetch()
        }, timeout)

        return () => { clearInterval(interval) }
    }, [handleProjectDataFetch, project?.source])

    // onChangeNumberOfEntries
    useEffect(() => {
        projectDispatch({
            type: "set-analysis-tab-export-data-button-state",
            isActive: numberOfEntries !== 0
        })
    }, [projectDispatch, numberOfEntries])

    // #endregion

    if (!project) return <Loading fullPage />

    if (!project?.source)
        return (
            <SourceChooser
                handleSelectSource={
                    source => source === "csv" ? handleSelectCsvSource() : handleSelectInterviewSource()
                }
                handleDeleteProject={handleDeleteProject}
            />
        )

    return (
        <>
            <TopBar currentTab={currentTab} setCurrentTab={setCurrentTab} />

            {currentTab === 0 && <OutlineTab />}
            {currentTab === 1 && <DataTab />}
            {currentTab === 2 && <AnalysisTab />}
            {currentTab === 3 && <ReportsTab />}
            {currentTab === 4 && <SettingsTab />}
            {currentTab === 5 && <CSVSettingsTab />}

            {selectedInterview && (
                <ConversationOverlay
                    interview={selectedInterview}
                    onClose={() => { projectDispatch({ type: "clear-selected-interview" }) }}
                    onDeleteInterview={() => { projectDispatch({ type: "force-data-tab-interviews-refetch" }) }}
                    deletableEntry={currentTab === 1}
                    screeningQuestions={screeningQuestions}
                    lang={lang}
                />
            )}
        </>
    )
}

const Project = () => {
    const [currentTab, setCurrentTab] = useState(null)

    const { projectId } = useParams()

    return (
        <UserProvider>
            <WebSocketProvider url={`/datasets/${projectId}`}>
                <ProjectProviderLegacy currentTab={currentTab}>
                    <ProjectProvider>
                        <div className="h-screen flex flex-col">
                            <ProjectContent currentTab={currentTab} setCurrentTab={setCurrentTab} />
                        </div>
                    </ProjectProvider>
                </ProjectProviderLegacy>
            </WebSocketProvider>
        </UserProvider>
    )
}

export default Project
