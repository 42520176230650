import { FaHashtag, FaPercent } from "react-icons/fa"
import {
    useAnalysisTabCodeOccurrences,
    useAnalysisTabCodeOccurrencesDispatch
} from "../../contexts/AnalysisTabCodeOccurrencesProvider"
import { useCallback, useEffect, useRef } from "react"
import { getCopy, copy } from "@utils/Copy"
import { useAnalysisTab } from "../../contexts/AnalysisTabProvider"

export default function AnalysisTabAnalysisColCodeOccurrencesModeToggle() {
    // #region Contexts
    const { selectedBreakdown } = useAnalysisTab()
    const { shouldShowCodeOccurrencesAsNumber } = useAnalysisTabCodeOccurrences()
    const analysisTabCodeOccurrencesDispatch = useAnalysisTabCodeOccurrencesDispatch()
    // #endregion

    // #region States
    const prevShouldShowAsNumber = useRef<boolean>()
    // #endregion

    // #region Refs
    const inputRef = useRef<HTMLInputElement>(null)
    // #endregion

    // #region Callbacks
    const handleClassNameForIcon = useCallback((type: "number" | "percentage") => {
        const isActive = type === "number" ? shouldShowCodeOccurrencesAsNumber : !shouldShowCodeOccurrencesAsNumber
        return isActive ? "bg-glaut-light-grey-50% text-glaut-midnight" : "text-glaut-grey"
    }, [shouldShowCodeOccurrencesAsNumber])

    const handleToggle = useCallback((forceValueForShowAsNumber?: boolean) => {
        analysisTabCodeOccurrencesDispatch({
            type: "change-global-code-occurrences-mode",
            shouldShowCodeOccurrencesAsNumber: forceValueForShowAsNumber ?? !shouldShowCodeOccurrencesAsNumber
        })
    }, [analysisTabCodeOccurrencesDispatch, shouldShowCodeOccurrencesAsNumber])
    // #endregion

    // #region Effects

    // onApplyBreakdownMoveToPercentage
    useEffect(() => {
        if (!selectedBreakdown) return
        if (prevShouldShowAsNumber.current !== undefined) return

        prevShouldShowAsNumber.current = shouldShowCodeOccurrencesAsNumber
        if (inputRef.current) {
            inputRef.current.checked = true
            handleToggle(false)
        }
    }, [selectedBreakdown, handleToggle, shouldShowCodeOccurrencesAsNumber])

    // onRemoveBreakdownMoveBackToLastState
    useEffect(() => {
        if (!selectedBreakdown) return
        if (prevShouldShowAsNumber.current === undefined) return

        if (inputRef.current) {
            inputRef.current.checked = !prevShouldShowAsNumber.current
            handleToggle(prevShouldShowAsNumber.current)
        }

        prevShouldShowAsNumber.current = undefined
    }, [selectedBreakdown, analysisTabCodeOccurrencesDispatch, handleToggle, shouldShowCodeOccurrencesAsNumber])

    // #endregion

    return (
        <div
            className="flex flex-row gap-[8px] items-center"
            data-tooltip-id="tooltip--glaut-app"
            data-tooltip-content={getCopy(copy.coding.switchBetweenNumberOfCitationsAndDistribution)}
            data-tooltip-place="bottom">
            <FaHashtag
                className={`w-[16px] h-[16px] rounded-[2px] p-[3px] ${handleClassNameForIcon("number")}`}
            />
            <label className="relative inline-flex cursor-pointer items-center">
                <input
                    id="input--code-occurrences-mode-switch"
                    type="checkbox"
                    className="peer sr-only"
                    ref={inputRef}
                    onChange={() => { handleToggle() }}
                />
                <label htmlFor="input--code-occurrences-mode-switch" className="hidden"></label>
                <div className={`peer h-6 w-9 rounded-full bg-glaut-midnight 
                            after:absolute after:left-[2px] after:top-0.5 after:h-5 after:w-5 after:transition-all
                            after:border-2 after:border-glaut-midnight after:bg-white after:rounded-full
                            after:content-['']
                            peer-checked:bg-glaut-midnight peer-checked:after:translate-x-[0.75rem]`}>
                </div>
            </label>
            <FaPercent
                className={`w-[16px] h-[16px] rounded-[2px] p-[3px] ${handleClassNameForIcon("percentage")}`}
            />
        </div>
    )
}