import { copy, getCopy } from "@utils/Copy"
import { useLegacyProject } from "../../contexts/ProjectProvider"
import { useEffect, useState } from "react"
import Loading from "@components/loading/Loading"
import { useInterviewService } from "@hooks/services/useInterviewService"

export default function CSVSettingsTabProjectUsage() {
    // #region Contexts
    const { project } = useLegacyProject()
    // #endregion

    // #region Services
    const interviewService = useInterviewService()
    // #endregion

    // #region States
    const [usage, setUsage] = useState<number>()
    // #endregion

    // #region Effects
    useEffect(() => {
        if (!project) return

        interviewService.getFrequencyPerAnalysis({ projectId: project._id })
            .then(response => {
                setUsage(Object.values(response).reduce((prev, curr) => prev + curr, 0))
            })
    }, [interviewService, project])
    // #endregion

    if (project?.source !== "CSV") return <></>
    if (usage === undefined) return <Loading />

    return (
        <div className="flex gap-[0.5em] items-center p-[0.75rem] rounded-[0.25rem] bg-glaut-cards">
            <p className="text-[13.33px] font-medium text-glaut-[#565656]">
                {getCopy(copy.coding.settings.csvSettings.totalNumberOfAnalysesRun) ?? ""}:
            </p>
            <p className="text-[13.33px] px-[0.75rem] py-[0.5rem] font-medium
                text-glaut-text-midnight bg-glaut-off-white
            ">
                {usage ?? 0}
            </p>
        </div>
    )
}