import { useBrowserUserAgent } from "@hooks/useBrowserUserAgent"
import { useCallback, useMemo, useState } from "react"
import { copy, getCopy } from "../../../../../utils/Copy"
import SendIcon from "../SendIcon"

// addClosedMessage(o.label[lang], lastMessage.id, o.id)

interface IOption {
    id: string
    label: Record<string, string>
}

interface ISelectButtonsProps {
    lastMessage: {
        id: string
        max_choices: number
        options: IOption[]
    }
    addClosedMessage: (content: string, questionId: string, value: unknown) => void
    isPlaying: boolean
    isVideoPlaying?: boolean
    lang: string
}

export default function SelectButtons({
    lastMessage,
    addClosedMessage,
    isPlaying,
    isVideoPlaying,
    lang
}: Readonly<ISelectButtonsProps>) {
    // #region States
    const [selected, setSelected] = useState<string[]>([])
    // #endregion

    // #region Browser hooks
    const { devices: { isMobile } } = useBrowserUserAgent()
    // #endregion

    // #region Memos
    const isMultiSelect = useMemo(() => lastMessage.max_choices > 1, [lastMessage])

    const sendIconContainerClassNames = useMemo(
        () => isMobile
            ? "fixed bottom-0 right-0 p-[0.75em] z-50"
            : "flex justify-end self-end mt-[1.5em]",
        [isMobile]
    )

    const isSelectingAllowed = useMemo(
        () => isPlaying === false && isVideoPlaying === false,
        [isPlaying, isVideoPlaying]
    )
    // #endregion

    // #region Callbacks
    const handleSelectOption = (option: IOption) => {
        // If the question is mono select, directly sends the selected option
        if (!isMultiSelect) addClosedMessage(option.label[lang], lastMessage.id, [option.id])
        // If it's multi-select and the selected array already contains the clicked option, we remove it
        else if (selected.includes(option.id)) setSelected(s => s.filter(o => o !== option.id))
        // If the option was not selected before and we have space, we now mark it as selected
        else if (lastMessage.max_choices > selected.length) setSelected(s => [...s, option.id])
    }

    const submit = () => {
        addClosedMessage(
            lastMessage.options
                .filter(o => selected.includes(o.id))
                .map(o => o.label[lang])
                .join(", "),
            lastMessage.id,
            selected
        )
    }

    const optionClassName = useCallback((optionId: string) => {
        const baseClassNames = "select-option border-1 border-glaut-grey rounded-[0.25em] w-full py-[0.5em] px-[1em]"

        if (isPlaying || isPlaying === null || isVideoPlaying || isVideoPlaying === null)
            return `${baseClassNames} cursor-not-allowed bg-glaut-cards text-glaut-light-grey`

        if (selected.includes(optionId))
            return `${baseClassNames} cursor-pointer bg-glaut-pink text-white font-medium`

        return `${baseClassNames} cursor-pointer bg-glaut-off-white text-black font-medium hover:bg-glaut-cards`
    }, [isPlaying, isVideoPlaying, selected])
    // #endregion

    return (
        <div className={`flex flex-col items-center overflow-hidden h-full min-h-40 ${!isMobile ? "mt-[1.5em]" : ""}`}>
            <div className={`flex flex-col overflow-hidden h-full
                ${isMobile ? "w-full" : "w-[max(500px,50%)]"}
            `}>
                <p className={`font-medium text-glaut-stroke-button
                    ${isMobile ? "text-[11.11px] mb-[0.5em]" : "text-[13.33px] mb-[1.25em]"}
                `}>
                    {getCopy(copy.interview.selectOption, lang)?.(lastMessage.max_choices)}
                </p>
                <div className="flex flex-col gap-[0.75em] items-center overflow-auto flex-1 px-1">
                    {lastMessage.options.map(o => (
                        <div
                            key={o.id}
                            className={optionClassName(o.id)}
                            onClick={() => isSelectingAllowed && handleSelectOption(o)}
                        >
                            {o.label[lang]}
                        </div>
                    ))}
                </div>
            </div>
            {isMultiSelect && (
                <div className={sendIconContainerClassNames}>
                    <SendIcon
                        onClick={submit}
                        disabled={!selected.length || isPlaying}
                    />
                </div>
            )}
        </div>
    )
}

