import useAxiosAuth from "@hooks/useAxiosAuth"
import { IReportGateway } from "@services/reports/IReportGateway"
import ReportsApiService from "@services/reports/api/reports.api"
import { useMemo } from "react"

/**
 * Obtains the instance of the service to interact with reports.
 */
export function useReportService(): IReportGateway {
    const axiosInstance = useAxiosAuth()
    return useMemo(() => new ReportsApiService({ api: axiosInstance }), [axiosInstance])
}