import { ButtonHTMLAttributes, DetailedHTMLProps, forwardRef } from "react"

interface IGlautButtonPrimaryProps
    extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    squared?: boolean
}

const GlautButtonPrimary = forwardRef<HTMLButtonElement, Readonly<IGlautButtonPrimaryProps>>(({
    children,
    onClick,
    disabled,
    className,
    squared = false
}, ref) => (
    <button
        className={`rounded-[0.25rem] font-medium border-0 shadow-none group text-base
            bg-glaut-pink text-glaut-off-white
            hover:bg-[#CC4F75] hover:text-glaut-off-white
            disabled:bg-glaut-off-white disabled:border-0 disabled:hover:text-glaut-grey
            ${squared ? "p-[0.5rem]" : "px-[0.75rem] py-[0.35rem]"}
            ${className}
        `}
        onClick={onClick}
        disabled={disabled}
        ref={ref}
    >
        {children}
    </button>
))

GlautButtonPrimary.displayName = "GlautButtonPrimary"

export default GlautButtonPrimary