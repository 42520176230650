import { RefObject } from "react"
import "./index.scss"

interface IPopupProps {
    close: () => void
    fullWidth?: boolean
    children: React.ReactNode
    popupRef?: RefObject<HTMLDivElement> | null
}

export default function Popup({
    close,
    fullWidth,
    children,
    popupRef = null
}: Readonly<IPopupProps>) {
    return (
        <div className="popup">
            <div className="overlay" onClick={close}></div>
            <div className="popup-contents fade-in" ref={popupRef}>
                <div
                    className="scroll column"
                    style={{
                        width: fullWidth ? "min(95vw, 1200px)" : "min(95vw, 600px)",
                        alignItems: "center",
                        maxHeight: "80vh",
                        padding: "0 2em"
                    }}
                >
                    {children}
                </div>
            </div>
        </div>
    )
}
