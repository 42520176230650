import { useRef, useEffect } from "react"

const defaultDelay = 1000

export type IUseAutoSave = (
    save: () => void,
    data: unknown,
    open: boolean,
    dependencies: unknown[],
    delay?: number,
) => void

const useAutoSave: IUseAutoSave = (
    save,
    data,
    open,
    dependencies = [],
    delay = defaultDelay
) => {
    // #region Refs
    const debouncedSave = useRef<NodeJS.Timeout>()
    // #endregion

    // #region Effects

    // onDataChangeExecuteDebouncedSave
    useEffect(() => {
        if (!open) return

        if (debouncedSave.current) clearTimeout(debouncedSave.current)
        debouncedSave.current = setTimeout(save, delay)

        return () => clearTimeout(debouncedSave.current)
    }, [save, data, open, delay])

    // onDependenciesChangeExecuteSave
    useEffect(save, [...dependencies, save])

    // #endregion
}

export default useAutoSave
