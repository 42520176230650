import { useState, useCallback } from "react"
import { copy, getCopy } from "@utils/Copy"
import { useNavigate } from "react-router-dom"

import Popup from "@components/Popups/Popup"
import TextualSetting from "@components/menus/Setting/TextualSetting"
import Loading from "@components/loading/Loading"

import { useOrganizationService } from "@hooks/services/useOrganizationService"
import { IOrganization, IOrganizationWithWorkspaces } from "src/@types/organization"

interface ICreateWorkspacePopupProps {
    setOpen: (open: boolean) => void,
    org: IOrganization | IOrganizationWithWorkspaces
}

export default function CreateWorkspacePopup({setOpen, org}: Readonly<ICreateWorkspacePopupProps>) {
    // #region State
    const [isLoading, setIsLoading] = useState(false)
    const [name, setName] = useState("")
    // #endregion

    // #region Hooks
    const navigate = useNavigate()
    // #endregion

    // #region Services
    const organizationService = useOrganizationService()
    // #endregion

    // #region Callbacks
    const createWorkspace = useCallback(() => {
        setIsLoading(true)

        organizationService.createWorkspace({organizationId: org._id, workspaceName: name})
            .then(res => {
                navigate("/w/" + res._id)
            })
    }, [name, navigate, org._id, organizationService])
    // #endregion

    return <Popup close={() => setOpen(false)}>
        <h2>Create Workspace</h2>
        <TextualSetting setting={{ slug: "workspace_name", placeholder: "Acme, Inc" }} value={name} setValue={setName}/>
        {isLoading ?
            <Loading /> :
            <button className="accent-button" onClick={createWorkspace} disabled={name.length < 3}>
                {getCopy(copy.org.createWorkspace)}
            </button>
        }
    </Popup>
}
