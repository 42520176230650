import Loading from "@components/loading/Loading"
import { useProjectService } from "@hooks/services/useProjectService"
import ProjectMetricsBoxStatusLabel from "@components/Project/ProjectMetricsBoxStatusLabel"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useMetricsIndicators } from "@hooks/useMetricsIndicators"
import ProjectMetricsBoxGraphProportionEntry from "../ProjectMetricsBoxGraphProportionEntry"
import ProjectMetricsBoxTextEntry from "../ProjectMetricsBoxTextEntry"
import { IProjectStatus } from "@/@types/project"
import { copy, getCopy } from "@utils/Copy"
import { IProjectMetrics } from "@services/projects/IProjectGateway"
import { MdRefresh } from "react-icons/md"

interface IProjectMetricsBoxProps {
    projectId: string
    projectStatus: IProjectStatus
    withHeader?: boolean
}

interface IProjectMetricsBoxWithoutHeaderProps extends IProjectMetricsBoxProps {
    withHeader?: false
}

interface IProjectMetricsBoxWithHeaderProps extends IProjectMetricsBoxProps {
    withHeader: true
    onRefresh?: () => void
}

export default function ProjectMetricsBox({
    projectId,
    projectStatus,
    ...props
}: Readonly<IProjectMetricsBoxWithoutHeaderProps | IProjectMetricsBoxWithHeaderProps>) {
    // #region Services
    const projectService = useProjectService()
    // #endregion

    // #region States
    const [metrics, setMetrics] = useState<IProjectMetrics>()
    const [isLoadingMetrics, setIsLoadingMetrics] = useState(false)
    // #endregion

    // #region Metrics hooks
    const {
        avgInterviewLengthMinutes,
        avgInterviewLengthSeconds,
        completionRate,
        desktopPercentage,
        mobilePercentage
    } = useMetricsIndicators(metrics)
    // #endregion

    // #region Memos
    const avgInterviewLength = useMemo(() => {
        if (!avgInterviewLengthMinutes || !avgInterviewLengthSeconds) return "Not available"
        return `${avgInterviewLengthMinutes}:${avgInterviewLengthSeconds} min`
    }, [avgInterviewLengthMinutes, avgInterviewLengthSeconds])
    // #endregion

    // #region Callbacks
    const handleMetricsRetrieval = useCallback(() => {
        setIsLoadingMetrics(true)
        projectService.getMetrics({ projectId }).then(metrics => {
            setMetrics(metrics)
        }).finally(() => {
            setIsLoadingMetrics(false)
        })
    }, [projectId, projectService])

    const handleRefresh = useCallback(() => {
        handleMetricsRetrieval()

        if (props.withHeader) props.onRefresh?.()
    }, [props, handleMetricsRetrieval])
    // #endregion

    // #region Effects
    useEffect(() => {
        handleMetricsRetrieval()

        const timeout = 1000 * 60 * 1 // minutes
        const interval = setInterval(() => {
            handleMetricsRetrieval()
        }, timeout)

        return () => {
            clearInterval(interval)
        }
    }, [handleMetricsRetrieval])
    // #endregion

    if (metrics === undefined) return <Loading />

    return (
        <div className="flex flex-col gap-[0.25rem]">
            {props.withHeader && (
                <div className="self-end">
                    {isLoadingMetrics ? (<Loading />) : (
                        <button
                            className="flex items-center gap-[0.5rem]
                            border-none shadow-none px-[0.5rem] py-[0.25rem] m-0 bg-transparent transition-none 
                            hover:bg-glaut-very-light-grey"
                            onClick={handleRefresh}
                            disabled={isLoadingMetrics}
                        >
                            <MdRefresh className="text-glaut-dark-grey h-[1em] w-[1em]" />
                            <p className="text-[11.11px] font-medium text-glaut-dark-grey underline">
                                {getCopy(copy.words.refresh)}
                            </p>
                        </button>
                    )}
                </div>
            )}
            <div className={`flex justify-center items-center gap-[0.5em] rounded-[0.625em] p-2 border-1
                bg-glaut-off-white border-glaut-stroke-glaut`}>
                <ProjectMetricsBoxTextEntry
                    description={getCopy(copy.words.started) ?? ""}
                    value={String(metrics.started)}
                />
                <ProjectMetricsBoxTextEntry
                    description={getCopy(copy.words.completed) ?? ""}
                    value={String(metrics.completed)}
                />
                <ProjectMetricsBoxTextEntry
                    description={getCopy(copy.words.rejected) ?? ""}
                    value={String(metrics.rejected ?? 0)}
                    details={{
                        screenedOut: {
                            label: "Screened out",
                            value: metrics.screened_outs
                        },
                        quotaFulls: {
                            label: "Quota full",
                            value: metrics.quota_fulls
                        },
                        uncooperative: {
                            label: "Non cooperative",
                            value: metrics.uncooperative
                        }
                    }}
                />
                <ProjectMetricsBoxTextEntry
                    description={getCopy(copy.words.completionRate) ?? ""}
                    value={`${completionRate}%`}
                />
                <ProjectMetricsBoxTextEntry
                    description={getCopy(copy.words.avgIntervTime) ?? ""}
                    value={avgInterviewLength}
                />
                <ProjectMetricsBoxStatusLabel status={projectStatus} />
                <ProjectMetricsBoxGraphProportionEntry
                    desktopPercentage={desktopPercentage}
                    mobilePercentage={mobilePercentage}
                />
            </div>
        </div>
    )
}