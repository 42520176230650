import { StyleSheet, Text, View } from "@react-pdf/renderer"
import { namedColors } from "@utils/Variables"
import { SpecialComponents } from "react-markdown/lib/ast-to-react"
import { NormalComponents } from "react-markdown/lib/complex-types"

const styles = StyleSheet.create({
    p: {
        fontFamily: "Poppins",
        fontSize: "10.18px",
        color: namedColors["dark-grey"],
        marginBottom: "10.18px"
    },
    strong: {
        fontWeight: 600
    },
    em: {
        fontStyle: "italic"
    },
    h1: {
        fontFamily: "Poppins",
        fontSize: "12.22px",
        color: namedColors["dark-grey"],
        marginTop: "5.09px",
        marginBottom: "10.18px"
    },
    "h1:nth-child(1)": {
        marginTop: "0em"
    },
    h2: {
        fontFamily: "Poppins",
        fontSize: "12.22px",
        color: namedColors["dark-grey"],
        marginTop: "5.09px",
        marginBottom: "10.18px"
    },
    h3: {
        fontFamily: "Poppins",
        fontSize: "12.22px",
        color: namedColors["dark-grey"],
        marginTop: "5.09px",
        marginBottom: "10.18px"
    },
    div: {

    },
    ul: {
        fontFamily: "Poppins",
        color: namedColors["dark-grey"],
        paddingHorizontal: "8px",
        marginBottom: "0px",
        paddingTop: "0px"
    },
    li: {
        display: "flex",
        flexDirection: "row",
        columnGap: "5.09px",
        alignItems: "flex-start",
        marginBottom: "0px",
        paddingLeft: "1px",
        fontSize: "10.18px"
    },
    liBullet: {
        width: "10px",
        textAlign: "center"
    },
    liContent: {
        flex: 1
    }
})

export const markdownToPdfComponents: Partial<Omit<NormalComponents, keyof SpecialComponents> & SpecialComponents> = {
    p: ({ children }) => (
        <Text style={styles.p}>{children}</Text>
    ),
    strong: ({ children }) => (
        <Text style={styles.strong}>{children}</Text>
    ),
    em: ({ children }) => (
        <Text style={styles.em}>{children}</Text>
    ),
    h1: ({ children }) => (
        <Text style={styles.h1}>{children}</Text>
    ),
    h2: ({ children }) => (
        <Text style={styles.h2}>{children}</Text>
    ),
    h3: ({ children }) => (
        <Text style={styles.h3}>{children}</Text>
    ),
    div: ({ children }) => (
        <View style={styles.div}>{children}</View>
    ),
    ul: ({ children, depth }) => (
        <View style={[
            styles.ul,
            { paddingLeft: `${depth * 8}px` },
            depth === 0 ? { paddingBottom: "5.09px" } : {}
        ]}>
            {children}
        </View >
    ),
    li: ({ children }) => {
        // Workaround type due to missing type-hinting
        type IObjectChildNode = { props?: { node?: { tagName: string }, children?: string[] } }
        const childrenArr = (Array.isArray(children) ? [...children] : [children] as React.ReactNode[])

        const unorderedListElementIdx = childrenArr.findIndex(
            child => typeof child === "object" && (child as IObjectChildNode)?.props?.node?.tagName === "ul"
        )

        // Separate ul elements
        let unorderedListElement
        if (unorderedListElementIdx !== -1)
            unorderedListElement = childrenArr.splice(unorderedListElementIdx, 1)

        // Remove initial break lines (avoid marker to be vertically misaligned)
        if (childrenArr[0] === "\n")
            childrenArr.splice(0, 1)

        // Remove final break lines (avoid sub-lists to be too far)
        if (childrenArr[childrenArr.length - 1] === "\n")
            childrenArr.splice(childrenArr.length - 1, 1)

        return (
            <View>
                <View style={styles.li} wrap={false}>
                    <Text>•</Text>
                    <Text style={styles.liContent}>{childrenArr}</Text>
                </View>
                {unorderedListElement && (
                    <View>
                        {unorderedListElement}
                    </View>
                )}
            </View>
        )
    }
}