import GlautTextAreaMultiLine from "@components/inputs/GlautTextAreaMultiLine"
import { copy, getCopy } from "@utils/Copy"
import { DetailedHTMLProps, forwardRef, TextareaHTMLAttributes, useEffect, useMemo, useRef, useState } from "react"
import { MdOutlineArrowDropDownCircle } from "react-icons/md"

interface IGlautAnalysisCreationFormGoalTextAreaProps
    extends DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
    designMode?: "compact" | "default"
    autoResize?: boolean
}

const GlautAnalysisCreationFormGoalTextArea = forwardRef<
    HTMLTextAreaElement,
    IGlautAnalysisCreationFormGoalTextAreaProps
>(({
    designMode = "default",
    autoResize = false,
    ...props
}, ref) => {
    // #region States
    const [whenCompactIsExpanded, setWhenCompactIsExpanded] = useState(false)
    // #endregion

    // #region Refs
    const textAreaRef = useRef<HTMLTextAreaElement>()
    // #endregion

    // #region Element memos
    const textAreaElement = useMemo(() => (
        <GlautTextAreaMultiLine
            {...props}
            ref={elem => {
                if (elem !== null) textAreaRef.current = elem

                if (!ref) return

                if (typeof ref === "function") {
                    ref(elem)
                    return
                }

                ref.current = elem
            }}
            designMode="ai"
            className="min-h-[6em]"
        />
    ), [props, ref])
    // #endregion

    // #region Effects
    useEffect(() => {
        if (!autoResize || !textAreaRef.current) return

        textAreaRef.current.style.height = "auto"
        textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight + 2}px`
    }, [autoResize, props.value])
    // #endregion

    return (
        <div className="flex flex-col gap-[0.5rem]">
            {designMode === "compact" && (
                <>
                    <div className="flex gap-[0.5rem] items-center">
                        <button
                            className="border-none shadow-none p-0 m-0 bg-transparent text-base"
                            onClick={() => { setWhenCompactIsExpanded(prev => !prev) }}
                        >
                            <MdOutlineArrowDropDownCircle className={`text-glaut-dark-grey transition-all duration-500
                                ${!whenCompactIsExpanded ? "-rotate-90" : ""}
                            `} />
                        </button>
                        <p className="text-[13.33px] font-medium text-glaut-text-midnight">
                            {getCopy(copy.words.advanced)}
                        </p>
                    </div>
                    {whenCompactIsExpanded && (
                        <>
                            <p className="text-[13.33px] font-medium text-glaut-dark-grey">
                                {getCopy(copy.coding.analysisGoal)}
                            </p>
                            {textAreaElement}
                        </>
                    )}
                </>
            )}
            {designMode === "default" && (
                <>
                    <p className="text-[13.33px] font-medium text-glaut-text-midnight mt-[0.875rem]">
                        {getCopy(copy.coding.analysisGoal)}
                    </p>
                    <p className="text-[13.33px] text-glaut-dark-grey">
                        {getCopy(copy.coding.clarifyTheAnalysisGoalAndRelevantDetails)}
                    </p>
                    {textAreaElement}
                </>
            )}
        </div>
    )
})

GlautAnalysisCreationFormGoalTextArea.displayName = "GlautAnalysisCreationFormGoalTextArea"

export default GlautAnalysisCreationFormGoalTextArea