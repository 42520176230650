import { useCallback } from "react"

interface IGlautTogglesV2Props<TSelection> {
    disabled?: boolean
    availableSelections: readonly { slug: TSelection, label: string }[]
    value: TSelection
    onChange: (selection: TSelection) => void
}

/**
 * @see Figma https://www.figma.com/design/CudeCZSSQPEH8Ivz8peesY/Glaut?node-id=4813-15871&t=PDH8z2JIyU0VQjSX-4
 */
export default function GlautTogglesV2<TSelection extends string = string>({
    disabled = false,
    availableSelections,
    value,
    onChange
}: Readonly<IGlautTogglesV2Props<TSelection>>) {
    // #region Callbacks
    const selectionClassNames = useCallback((currSelection: TSelection) => {
        const selectionIdx = availableSelections.findIndex(s => s.slug === currSelection)
        if (selectionIdx === -1) return ""
        let baseClassNames = "rounded-none bg-glaut-cards shadow-none flex-1 p-[1rem]"

        const isFirstOption = selectionIdx === 0
        const isLastOption = selectionIdx === availableSelections.length - 1
        if (isFirstOption) baseClassNames += " rounded-s-[0.25rem]"
        if (isLastOption) baseClassNames += " rounded-e-[0.25rem]"

        if (value === currSelection) return `${baseClassNames} border-glaut-pink`
        return `${baseClassNames} border-transparent enabled:hover:border-glaut-grey duration-100`
    }, [availableSelections, value])

    const handleSetSelection = useCallback((currSelection: TSelection) => {
        onChange(currSelection)
    }, [onChange])
    // #endregion

    return (
        <div className="flex flex-row">
            {availableSelections.map(({ slug, label }) => (
                <button
                    key={slug}
                    onClick={() => { handleSetSelection(slug) }}
                    className={selectionClassNames(slug)}
                    disabled={disabled}
                >
                    <p className={`text-center text-[13.33px] font-medium
                                ${value === slug ? "text-glaut-pink" : "text-glaut-dark-grey"}
                            `}>
                        {label}
                    </p>
                </button>
            ))}
        </div>
    )
}