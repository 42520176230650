/**
 * Normalizes the index from 1 to 10.
 */
export function normalizeToColorIndex(index: number) {
    return Math.round((index % 10) + 1)
}

/**
 * Gets a dynamic indexed background color for TailwindCSS className.
 * 
 * @see https://tailwindcss.com/docs/content-configuration#dynamic-class-names
 */
export function bgColorWithCssColorVarFromIndex(index: number) {
    return {
        1: "bg-[var(--color1)]",
        2: "bg-[var(--color2)]",
        3: "bg-[var(--color3)]",
        4: "bg-[var(--color4)]",
        5: "bg-[var(--color5)]",
        6: "bg-[var(--color6)]",
        7: "bg-[var(--color7)]",
        8: "bg-[var(--color8)]",
        9: "bg-[var(--color9)]",
        10: "bg-[var(--color10)]"
    }[normalizeToColorIndex(index)]
}

/**
 * Gets a dynamic indexed background light color for TailwindCSS className.
 * 
 * @see https://tailwindcss.com/docs/content-configuration#dynamic-class-names
 */
export function bgColorWithCssLightColorVarFromIndex(index: number) {
    return {
        1: "bg-[var(--color1-light)]",
        2: "bg-[var(--color2-light)]",
        3: "bg-[var(--color3-light)]",
        4: "bg-[var(--color4-light)]",
        5: "bg-[var(--color5-light)]",
        6: "bg-[var(--color6-light)]",
        7: "bg-[var(--color7-light)]",
        8: "bg-[var(--color8-light)]",
        9: "bg-[var(--color9-light)]",
        10: "bg-[var(--color10-light)]"
    }[normalizeToColorIndex(index)]
}

/**
 * Gets a dynamic indexed text color for TailwindCSS className.
 * 
 * @see https://tailwindcss.com/docs/content-configuration#dynamic-class-names
 */
export function textColorWithCssColorVarFromIndex(index: number) {
    return {
        1: "text-[var(--color1)]",
        2: "text-[var(--color2)]",
        3: "text-[var(--color3)]",
        4: "text-[var(--color4)]",
        5: "text-[var(--color5)]",
        6: "text-[var(--color6)]",
        7: "text-[var(--color7)]",
        8: "text-[var(--color8)]",
        9: "text-[var(--color9)]",
        10: "text-[var(--color10)]"
    }[normalizeToColorIndex(index)]
}

/**
 * Gets a dynamic indexed text light color for TailwindCSS className.
 * 
 * @see https://tailwindcss.com/docs/content-configuration#dynamic-class-names
 */
export function textColorWithCssLightColorVarFromIndex(index: number) {
    return {
        1: "text-[var(--color1-light)]",
        2: "text-[var(--color2-light)]",
        3: "text-[var(--color3-light)]",
        4: "text-[var(--color4-light)]",
        5: "text-[var(--color5-light)]",
        6: "text-[var(--color6-light)]",
        7: "text-[var(--color7-light)]",
        8: "text-[var(--color8-light)]",
        9: "text-[var(--color9-light)]",
        10: "text-[var(--color10-light)]"
    }[normalizeToColorIndex(index)]
}