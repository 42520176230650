import { useState } from "react"
import { copy, getCopy } from "@utils/Copy"
import { useNavigate } from "react-router-dom"

import Popup from "@components/Popups/Popup"
import TextualSetting from "@components/menus/Setting/TextualSetting"
import Loading from "@components/loading/Loading"

import { useOrganizationService } from "@hooks/services/useOrganizationService"

interface ICreateOrganizationPopupProps {
    setOpen: (open: boolean) => void
}

export default function CreateOrganizationPopup({setOpen}: Readonly<ICreateOrganizationPopupProps>) {
    // #region States
    const [isLoading, setIsLoading] = useState(false)
    const [name, setName] = useState("")
    // #endregion

    // #region Hooks
    const navigate = useNavigate()
    // #endregion

    // #region Services
    const organizationService = useOrganizationService()
    // #endregion

    const createOrganization = () => {
        setIsLoading(true)

        organizationService.createOrganization({name})
            .then(res => {
                setOpen(false)
                navigate("/o/" + res._id)
            })
            .catch(e => console.log(e))
    }

    return <Popup close={() => setOpen(false)}>
        <h2>{getCopy(copy.org.createOrganization)}</h2>
        <TextualSetting
            setting={{ slug: "organization_name", placeholder: "Acme, Inc" }}
            value={name}
            setValue={setName}
        />
        {isLoading ?
            <Loading /> :
            <button className="accent-button" onClick={createOrganization} disabled={name.length < 3}>
                {getCopy(copy.org.createOrganization)}
            </button>
        }
    </Popup>
}
