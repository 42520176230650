import { copy, getCopy } from "@utils/Copy"
import OutlineTabSidebarProjectStatusButton from "../OutlineTabSidebarProjectStatusButton"

export default function OutlineTabSidebarProjectStatus() {
    return (
        <div className="flex flex-col gap-[0.75em]">
            <p className="text-[13.33px] text-glaut-bar">
                {getCopy(copy.outline.sidebar.projectStatus)?.toUpperCase()}
            </p>
            <OutlineTabSidebarProjectStatusButton />
        </div>
    )
}