import { useMemo } from "react"
import { MdPhoneIphone } from "react-icons/md"
import { IoMdDesktop } from "react-icons/io"
import { Style } from "@react-pdf/types"
import { Text, View } from "@react-pdf/renderer"
import PdfIcon from "@components/Pdf/PdfIcon"
import { namedColors } from "@utils/Variables"

interface IProjectMetricsBoxGraphProportionEntryProps {
    mobilePercentage: number
    desktopPercentage: number
    asPdf?: boolean
    twPdf?: (input: string) => Style
}

export default function ProjectMetricsBoxGraphProportionEntry({
    desktopPercentage,
    mobilePercentage,
    asPdf,
    twPdf
}: Readonly<IProjectMetricsBoxGraphProportionEntryProps>) {
    // #region Memos
    const { mobileWidth, desktopWidth } = useMemo(() => {
        // Total sum in pixels: 115px
        const totalWidth = 115

        if (mobilePercentage + desktopPercentage !== 100)
            return {
                mobileWidth: totalWidth / 2,
                desktopWidth: totalWidth / 2
            }


        let mobileWidth = Math.floor((mobilePercentage / 100) * totalWidth)
        const desktopWidth = Math.floor((desktopPercentage / 100) * totalWidth)

        if (mobileWidth + desktopWidth < totalWidth)
            mobileWidth = totalWidth - desktopWidth


        return { mobileWidth, desktopWidth }
    }, [mobilePercentage, desktopPercentage])

    const mobileBarClassNames = useMemo(() => {
        const baseClassName = `bg-[#5465FE] rounded-l ${asPdf ? "h-[5.09px]" : "h-2"}`
        const hasWrongPercentageSum = desktopPercentage + mobilePercentage > 100
        const hasOnlyMobile = desktopPercentage === 0 && mobilePercentage === 100

        if (hasWrongPercentageSum || !hasOnlyMobile) return baseClassName
        return `${baseClassName} rounded-r`
    }, [desktopPercentage, mobilePercentage, asPdf])

    const desktopBarClassNames = useMemo(() => {
        const baseClassName = `bg-[#FF6392] rounded-r ${asPdf ? "h-[5.09px]" : "h-2"}`
        const hasWrongPercentageSum = desktopPercentage + mobilePercentage > 100
        const hasOnlyDesktop = mobilePercentage === 0 && desktopPercentage === 100

        if (hasWrongPercentageSum || !hasOnlyDesktop) return baseClassName
        return `${baseClassName} rounded-l`
    }, [desktopPercentage, mobilePercentage, asPdf])
    // #endregion

    if (asPdf && twPdf)
        return (
            <View style={
                twPdf("flex flex-row justify-center items-center gap-[5.09px] p-[20px] bg-glautCards rounded-[5.09px]")
            }>
                <Text style={twPdf("text-[12.22px]")}>{mobilePercentage}%</Text>
                <PdfIcon icon={MdPhoneIphone} color={namedColors.grey} size={"8.91px"} />
                <View style={twPdf("flex flex-row gap-[1px]")}>
                    <View style={[twPdf(mobileBarClassNames), { width: `${mobileWidth}px` }]}></View>
                    <View style={[twPdf(desktopBarClassNames), { width: `${desktopWidth}px` }]}></View>
                </View>
                <PdfIcon icon={IoMdDesktop} color={namedColors.grey} size={"8.91px"} />
                <Text style={twPdf("text-[12.22px]")}>{desktopPercentage}%</Text>
            </View>
        )

    return (
        <div className="flex flex-row items-center gap-2 p-[1.25em] bg-glaut-cards rounded-[0.5em]">
            <p className="text-[19.2px]">{mobilePercentage}%</p>
            <MdPhoneIphone className="text-glaut-grey w-[14px] h-[14px]" />
            <div className="flex flex-row gap-[1px] rounded-none">
                <div className={mobileBarClassNames} style={{ width: `${mobileWidth}px` }}></div>
                <div className={desktopBarClassNames} style={{ width: `${desktopWidth}px` }}></div>
            </div>
            <div className="rounded-none">
                <IoMdDesktop className="text-glaut-grey w-[14px] h-[14px]" />
            </div>
            <p className="text-[19.2px]">{desktopPercentage}%</p>
        </div>
    )
}