import {
    useAnalysisTabCodeOccurrences,
    useAnalysisTabCodeOccurrencesDispatch
} from "@pages/Project/ProjectArea/contexts/AnalysisTabCodeOccurrencesProvider"
import { Text, View } from "@react-pdf/renderer"
import { Style } from "@react-pdf/types"
import { bgColorWithCssLightColorVarFromIndex, normalizeToColorIndex } from "@utils/styling/colors-from-index"
import { namedColors } from "@utils/Variables"
import { MouseEvent as ReactMouseEvent, useCallback, useEffect, useMemo, useState } from "react"

interface ICodeOccurrencesBoxProps {
    number: number
    /**
     * From 0 to 1.
     */
    percentage: number
    bgColorIndex?: number
    asPdf?: boolean
    twPdf?: (input: string) => Style
}

export default function CodeOccurrencesBox({
    number,
    percentage,
    bgColorIndex,
    asPdf,
    twPdf
}: Readonly<ICodeOccurrencesBoxProps>) {
    // #region Contexts

    // can be {} when in legacy components
    const {
        shouldShowTooltipOnCodeHover,
        shouldShowCodeOccurrencesAsNumber
    } = useAnalysisTabCodeOccurrences()

    // can be {} when in legacy components
    const analysisTabCodeOccurrencesDispatch = useAnalysisTabCodeOccurrencesDispatch()

    // #endregion

    // #region States
    const [shouldShowNumberOverPercentage, setShouldShowNumberOverPercentage] = useState<boolean | undefined>(
        shouldShowCodeOccurrencesAsNumber === undefined ? true : undefined
    )
    // #endregion

    // #region Memos

    const localShouldShowNumber = useMemo(() => {
        if (shouldShowCodeOccurrencesAsNumber === undefined)
            return shouldShowNumberOverPercentage ?? true

        if (shouldShowNumberOverPercentage === undefined)
            return shouldShowCodeOccurrencesAsNumber

        return shouldShowNumberOverPercentage
    }, [shouldShowNumberOverPercentage, shouldShowCodeOccurrencesAsNumber])

    // For open text (not coding) questions, `percentage` comes as the identifier. The validation guarantees "-"
    const validatedPercentage = useMemo(() => {
        if (isNaN(Number(percentage))) return "-"
        return Number((percentage * 100).toFixed(2)).toFixed(2)
    }, [percentage])

    // #endregion

    // #region Callbacks
    const handleClickOnCodeOccurrences = useCallback((e: ReactMouseEvent<HTMLSpanElement, MouseEvent>) => {
        e.stopPropagation()
        setShouldShowNumberOverPercentage(prev => prev === undefined ? !shouldShowCodeOccurrencesAsNumber : !prev)

        if (typeof analysisTabCodeOccurrencesDispatch !== "function") return

        if (!shouldShowTooltipOnCodeHover)
            analysisTabCodeOccurrencesDispatch({ type: "mark-code-tooltip-as-viewed" })
    }, [analysisTabCodeOccurrencesDispatch, shouldShowCodeOccurrencesAsNumber, shouldShowTooltipOnCodeHover])
    // #endregion

    // #region Effects
    useEffect(() => {
        if (shouldShowNumberOverPercentage === shouldShowCodeOccurrencesAsNumber)
            setShouldShowNumberOverPercentage(undefined)
    }, [shouldShowNumberOverPercentage, shouldShowCodeOccurrencesAsNumber])
    // #endregion

    if (asPdf && twPdf)
        return (
            <View style={[
                twPdf("rounded-full px-[2.14px] py-[1px] bg-glautOffWhite"),
                bgColorIndex !== undefined ? {
                    backgroundColor: namedColors[`color${normalizeToColorIndex(bgColorIndex)}-light`]
                } : {}
            ]}>
                <Text style={twPdf("text-[5.09px]")}>
                    {number}
                </Text>
            </View>
        )

    return (
        <button
            className={`cursor-pointer border-1 border-transparent shadow-none py-0 rounded-full px-[0.25em] min-w-max
                hover:border-glaut-dark-grey
                ${bgColorIndex !== undefined ? bgColorWithCssLightColorVarFromIndex(bgColorIndex) : ""}
            `}
            onClick={handleClickOnCodeOccurrences}
            data-tooltip-id="tooltip--glaut-app"
            data-tooltip-content="Click to toggle occurrences mode"
            data-tooltip-place="top">
            <div className={`flex flex-row items-center justify-center h-full min-w-3 text-[11.11px] leading-[14px] 
                font-medium
                text-glaut-midnight
            `}>
                {localShouldShowNumber ? number : (
                    <>
                        {String(validatedPercentage).split(".").join(",")}
                        {validatedPercentage !== "-" && (<p className="font-bold text-[6.6px]">%</p>)}
                    </>
                )}
            </div>
        </button>
    )
}