import useAxiosAuth from "@hooks/useAxiosAuth"
import AnalysisApiService from "@services/analyses/api/analysis.api"
import IAnalysisGateway from "@services/analyses/IAnalysisGateway"
import { useMemo } from "react"

/**
 * Obtains the instance of the service to interact with analyses.
 */
export function useAnalysisService(): IAnalysisGateway {
    const axiosInstance = useAxiosAuth()
    return useMemo(() => new AnalysisApiService({ api: axiosInstance }), [axiosInstance])
}