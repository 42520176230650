import { IAnalysisCategory } from "src/@types/analysis"
import { ICategoryStats } from "src/@types/processing/statistics"
import { ISelectOption } from "src/@types/interview-question"

export function searchCategoryInStats<T extends IAnalysisCategory | ISelectOption>(
    stats: ICategoryStats<T> | ICategoryStats<T>[],
    categoryId: string
): ICategoryStats<T> | null {
    const input = Array.isArray(stats) ? stats : [stats]

    for (const categoryStats of input) {
        if (categoryStats.category.id === categoryId) return categoryStats

        const childCategory = searchCategoryInStats(categoryStats.children, categoryId)
        if (childCategory) return childCategory
    }

    return null
}

export function searchParentCategoryInStats<T extends IAnalysisCategory | ISelectOption>(
    stats: ICategoryStats<T> | ICategoryStats<T>[],
    categoryId: string
): ICategoryStats<T> | null {
    const input = Array.isArray(stats) ? stats : [stats]

    for (const categoryStats of input) {
        if (categoryStats.children.some(c => c.category.id === categoryId)) return categoryStats

        const childCategory = searchParentCategoryInStats(categoryStats.children, categoryId)
        if (childCategory) return childCategory
    }

    return null
}

export function removeCategoryInStats<T extends IAnalysisCategory | ISelectOption>(
    stats: ICategoryStats<T>[],
    categoryId: string
): boolean {
    for (let categoryStatsIdx = 0; categoryStatsIdx < stats.length; categoryStatsIdx++) {
        const categoryStats = stats[categoryStatsIdx]
        if (categoryStats.category.id === categoryId) {
            stats.splice(categoryStatsIdx, 1)
            return true
        }

        const hasSucceeded = removeCategoryInStats(categoryStats.children, categoryId)
        if (hasSucceeded) return true
    }

    return false
}