import GlautStar from "@components/Icons/GlautStar"
import { getCopy } from "@utils/Copy"
import { interviewTerminatedQuotaFullCopy } from "./utils/copy"
import GlautBottomRisk from "@components/Drawings/GlautBottomRisk"

interface IInterviewTerminatedQuotaFullProps {
    lang: string
}

export default function InterviewTerminatedQuotaFull({ lang }: Readonly<IInterviewTerminatedQuotaFullProps>) {
    return (
        <div className="h-dvh w-dvw flex flex-col items-start justify-center gap-[1.5em] mx-[4em] max-w-[300px] 
            pb-10">
            <GlautStar className="h-[2rem] w-[2rem]" />
            <div className="flex flex-col">
                {getCopy(interviewTerminatedQuotaFullCopy.message, lang)}
                <GlautBottomRisk className="w-[40%] mx-8" />
            </div>
            <p className="text-[16px] text-glaut-text-midnight">
                {getCopy(interviewTerminatedQuotaFullCopy.detail, lang)}
            </p>
        </div>
    )
}