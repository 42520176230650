import { ISelectScreeningOption, ISelectScreeningQuestion } from "@/@types/interview-question"
import { copy, getCopy } from "@utils/Copy"
import { RecursivePartial } from "@utils/types/recursive-partial"
import { useCallback } from "react"
import { editScreeningCopy } from "../../utils/copy"
import EditScreeningOption from "../EditScreeningScreenerOption"

interface IEditScreeningScreenerProps {
    question: RecursivePartial<ISelectScreeningQuestion>
    lang: string
    disabled: boolean
    setQuestion: (question: RecursivePartial<ISelectScreeningQuestion>) => void
}

export default function EditScreeningScreener({
    question,
    lang,
    disabled,
    setQuestion
}: Readonly<IEditScreeningScreenerProps>) {
    // #region Callbacks
    const handleChangeOptionLabel = useCallback((e: React.ChangeEvent<HTMLInputElement>, id: string) => {
        const options = (question.options ?? []) as Partial<ISelectScreeningOption>[]

        setQuestion({
            ...question,
            options: options.map(opt =>
                opt.id === id ? { ...opt, label: { ...opt.label, [lang]: e.target.value } } : opt
            ) ?? []
        })
    }, [setQuestion, question, lang])

    const handleRemoveOption = useCallback((id: string) => {
        const options = (question.options ?? []) as Partial<ISelectScreeningOption>[]
        setQuestion({ ...question, options: options.filter(opt => opt.id !== id) })
    }, [setQuestion, question])

    const handleChangeOptionLogic = useCallback((logic: NonNullable<ISelectScreeningOption["action"]>, id: string) => {
        const options = (question.options ?? []) as Partial<ISelectScreeningOption>[]
        setQuestion({ ...question, options: options.map(opt => opt.id === id ? { ...opt, action: logic } : opt) })
    }, [setQuestion, question])
    // #endregion

    return (
        <div className="flex flex-col gap-[0.75rem]">
            <p className="text-[13.33px] font-medium text-glaut-text-midnight">
                {getCopy(editScreeningCopy.chooseWithOptionsQualityDisqualifyRespondents)}
            </p>
            <div className="flex flex-col gap-[0.25rem]">
                <div className="flex gap-[0.25rem]">
                    <p className="flex-1 text-[13.33px] font-medium py-[0.5rem] text-center rounded-[0.25rem]
                            border-1
                            text-[rgb(0,0,0,0.6)] border-glaut-very-light-grey">
                        {getCopy(copy.words.option)}
                    </p>
                    <p className="flex-1 text-[13.33px] font-medium py-[0.5rem] text-center rounded-[0.25rem]
                            border-1
                            text-[rgb(0,0,0,0.6)] border-glaut-very-light-grey">
                        {getCopy(copy.words.logic)}
                    </p>
                </div>
                {question.options?.map((opt, idx) => (
                    <EditScreeningOption
                        key={opt.id}
                        option={opt}
                        lang={lang}
                        disabled={disabled}
                        isRemovalAllowed={idx > 0}
                        handleRemoveOption={handleRemoveOption}
                        handleChangeOptionLabel={handleChangeOptionLabel}
                        handleChangeOptionLogic={handleChangeOptionLogic}
                    />
                ))}
            </div>
        </div>
    )
}