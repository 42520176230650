import { useCallback, useEffect, useMemo } from "react"
import { MdChevronLeft, MdChevronRight } from "react-icons/md"
import { useOutlineTabIqs, useOutlineTabIqsDispatch } from "../../contexts/OutlineTabIqsProvider"
import { outlineTabContentIqsQuestionSyntheticAnswersListCopy as copy } from "./utils/copy"
import { getCopy } from "@utils/Copy"
import ModeratorMessage from "./components/ModeratorMessage"
import RespondentMessage from "./components/RespondentMessage"

export default function OutlineTabContentIqsQuestionSyntheticAnswersList() {
    // #region Contexts
    const { iqToBeBuilt, currSynthAnswerIndex } = useOutlineTabIqs()
    const outlineTabIqsDispatch = useOutlineTabIqsDispatch()
    // #endregion

    // #region Memos
    const currPage = useMemo(() => currSynthAnswerIndex + 1, [currSynthAnswerIndex])
    const totalPages = useMemo(
        () => iqToBeBuilt?.syntheticAnswers?.length ?? 0,
        [iqToBeBuilt?.syntheticAnswers?.length]
    )
    const paginationText = useMemo(
        () => `${currPage} ${getCopy(copy.of)} ${totalPages} ${getCopy(copy.syntheticAnswers)}`,
        [currPage, totalPages]
    )

    const isFirstPage = useMemo(() => currPage === 1, [currPage])
    const isLastPage = useMemo(() => currPage === totalPages, [currPage, totalPages])

    const messages = useMemo(
        () => iqToBeBuilt?.syntheticAnswers?.[currSynthAnswerIndex]?.messages?.filter(
            m => m.question_id === iqToBeBuilt?.id
        ).map(a => ({ role: a.role, content: a.content, date: a.date })) ?? [],
        [currSynthAnswerIndex, iqToBeBuilt?.syntheticAnswers, iqToBeBuilt?.id]
    )
    // #endregion

    // #region Callbacks
    const handlePrevSynthAnswer = useCallback(() => {
        outlineTabIqsDispatch({ type: "decrease-curr-synth-answer-index" })
    }, [outlineTabIqsDispatch])

    const handleNextSynthAnswer = useCallback(() => {
        outlineTabIqsDispatch({ type: "increase-curr-synth-answer-index" })
    }, [outlineTabIqsDispatch])
    // #endregion

    // #region Effects

    // onArrowKeysNavigateThroughSyntheticAnswers
    useEffect(() => {
        if (!iqToBeBuilt?.syntheticAnswers?.length) return

        const handleKeyDown = (event: KeyboardEvent) => {
            // Skip if any input element is focused
            if (document.activeElement?.tagName === "INPUT" || document.activeElement?.tagName === "TEXTAREA") return

            if (event.key === "ArrowLeft") handlePrevSynthAnswer()
            if (event.key === "ArrowRight") handleNextSynthAnswer()
        }
        window.addEventListener("keydown", handleKeyDown)
        return () => window.removeEventListener("keydown", handleKeyDown)
    }, [handleNextSynthAnswer, handlePrevSynthAnswer, iqToBeBuilt?.syntheticAnswers?.length])

    // #endregion

    if (!iqToBeBuilt?.syntheticAnswers?.length) return <></>

    return (
        <div className="flex flex-col overflow-hidden">
            <div className="flex p-[0.5rem] gap-[0.25rem] items-center rounded-t-[0.5rem] bg-glaut-cards w-max">
                <button
                    disabled={isFirstPage}
                    type="button"
                    className="border-none shadow-none p-[0.25rem] m-0 bg-transparent rounded-[0.25rem] 
                        text-glaut-pink
                        hover:bg-glaut-very-light-grey"
                    onClick={handlePrevSynthAnswer}
                >
                    <MdChevronLeft />
                </button>
                <p className="text-[11.11px] font-medium text-glaut-text-midnight">
                    {paginationText}
                </p>
                <button
                    disabled={isLastPage}
                    type="button"
                    className="border-none shadow-none p-[0.25rem] m-0 bg-transparent rounded-[0.25rem] 
                        text-glaut-pink
                        hover:bg-glaut-very-light-grey"
                    onClick={handleNextSynthAnswer}
                >
                    <MdChevronRight />
                </button>
            </div>
            <div className="flex flex-col flex-1 gap-[0.75rem] rounded-e-[1em] rounded-se-[1em] p-[1.5rem]
                overflow-auto
                bg-glaut-cards">
                {messages.map((m, index) => (
                    m.role === "moderator"
                        ? <ModeratorMessage key={`${m.role}-${m.date}-${index}`} message={m.content} />
                        : <RespondentMessage key={`${m.role}-${m.date}-${index}`} message={m.content} />
                ))}
            </div>
        </div>
    )
}