import { createContext, useContext, useEffect, useMemo, useReducer } from "react"
import { glautAssetComponentTypes, IGlautAssetComponentType } from "../constants/types"
import { IGlautAssetComponentValue } from "../@types/value"
import { IGlautAssetComponentOnChange } from "../@types/on-change"
import { IGlautAssetComponentDisabled } from "../@types/disabled"

// #region Type definitions
interface GlautAssetComponentProviderProps {
    children: React.ReactNode
    disabled?: boolean
    disabledType?: IGlautAssetComponentDisabled
    value?: IGlautAssetComponentValue
    onChange?: IGlautAssetComponentOnChange
}

interface GlautAssetComponentContextState {
    selectedOptions: IGlautAssetComponentType[]
    disabled: boolean
    disabledType: IGlautAssetComponentDisabled
    value: IGlautAssetComponentValue
    onChange: IGlautAssetComponentOnChange
}

type GlautAssetComponentContextAction = {
    type: "add-selected-option"
    assetType: IGlautAssetComponentType
} | {
    type: "remove-selected-option"
    assetType: IGlautAssetComponentType
} | {
    type: "set-selected-options"
    selectedOptions: IGlautAssetComponentType[]
} | {
    type: "set-type-disabled"
    rules: {
        assetType: IGlautAssetComponentType
        isDisabled: boolean
    }[]
}
// #endregion

// #region Context definitions
const GlautAssetComponentContext = createContext(
    {} as GlautAssetComponentContextState
)
const GlautAssetComponentContextDispatch = createContext(
    {} as React.Dispatch<GlautAssetComponentContextAction>
)
// #endregion

// #region Hook definitions
export function useGlautAssetComponent() {
    return useContext(GlautAssetComponentContext)
}
export function useGlautAssetComponentDispatch() {
    return useContext(GlautAssetComponentContextDispatch)
}
// #endregion

// #region Provider definition
export default function GlautAssetComponentProvider({
    children,
    value = {},
    onChange = {},
    disabledType = {},
    disabled = false
}: Readonly<GlautAssetComponentProviderProps>) {
    // #region Context state
    const initialState: GlautAssetComponentContextState = {
        disabled,
        value,
        onChange,
        disabledType,
        selectedOptions: Object.entries(value)
            .filter(([, data]) => !!data)
            .map(([key]) => key) as IGlautAssetComponentType[]
    }
    const [rawState, dispatch] = useReducer(GlautAssetComponentReducer, initialState)
    // #endregion

    // #region Memos
    const state = useMemo(() => ({ ...rawState, disabled, value }), [rawState, disabled, value])
    // #endregion

    // #region Effects
    useEffect(() => {
        if (Object.entries(value).every(([, data]) => data === undefined)) {
            dispatch({ type: "set-selected-options", selectedOptions: [] })
            dispatch({
                type: "set-type-disabled", rules: glautAssetComponentTypes.map(
                    type => ({ assetType: type, isDisabled: false })
                )
            })

            return
        }

        Object.entries(value).forEach(([key, data]) => {
            if (data !== undefined)
                dispatch({ type: "add-selected-option", assetType: key as IGlautAssetComponentType })
            else
                dispatch({ type: "remove-selected-option", assetType: key as IGlautAssetComponentType })
        })
    }, [value])
    // #endregion

    return (
        <GlautAssetComponentContext.Provider value={state}>
            <GlautAssetComponentContextDispatch.Provider value={dispatch}>
                {children}
            </GlautAssetComponentContextDispatch.Provider>
        </GlautAssetComponentContext.Provider>
    )
}
// #endregion

// #region Reducer definition
function GlautAssetComponentReducer(
    state: GlautAssetComponentContextState,
    action: GlautAssetComponentContextAction
): GlautAssetComponentContextState {
    switch (action.type) {
        case "add-selected-option": {
            if (state.selectedOptions.includes(action.assetType)) return state

            const newSelectedOptions = [...state.selectedOptions, action.assetType].filter(
                opt => !(
                    (action.assetType === "image" && opt === "video") ||
                    (action.assetType === "video" && opt === "image")
                )
            )

            newSelectedOptions.sort((a, b) => {
                if (a === "url-parameter") return 1
                if (b === "url-parameter") return -1
                return 0
            })

            const newDisabledType = { ...state.disabledType }

            if (action.assetType === "image") {
                newDisabledType.image = false
                newDisabledType.video = true
            }
            if (action.assetType === "video") {
                newDisabledType.image = true
                newDisabledType.video = false
            }

            return { ...state, selectedOptions: newSelectedOptions, disabledType: newDisabledType }
        }
        case "remove-selected-option": {
            const newDisabledType = { ...state.disabledType }
            if (action.assetType === "image")
                newDisabledType.video = false
            if (action.assetType === "video")
                newDisabledType.image = false

            return {
                ...state,
                selectedOptions: state.selectedOptions.filter(opt => opt !== action.assetType),
                disabledType: newDisabledType
            }
        }
        case "set-selected-options": {
            return { ...state, selectedOptions: action.selectedOptions }
        }
        case "set-type-disabled": {
            const newDisabledType = {
                ...state.disabledType,
                ...action.rules.reduce((prev, curr) => {
                    prev[curr.assetType] = curr.isDisabled
                    return prev
                }, {})
            }

            return {
                ...state,
                disabledType: newDisabledType
            }
        }
        default: {
            return state
        }
    }
}
// #endregion