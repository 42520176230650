import { IAnalysis, IAnalysisType } from "@/@types/analysis"
import { IInterview } from "@/@types/entry"
import { IInterviewQuestion, IQuestionType } from "@/@types/interview-question"
import { deepCopy } from "@utils/miscellaneous"
import { createContext, useContext, useMemo, useReducer } from "react"
import { v4 as uuid } from "uuid"

// #region Types
interface IOutlineTabIqsProviderProps {
    children: React.ReactNode
}

interface IOutlineTabIqsContextState {
    iqToBeBuilt?: {
        type?: IQuestionType
        domain: "interview"
        analyses?: (Partial<IAnalysis> & { isEditing: boolean })[]
        syntheticAnswers?: IInterview[]
    } & Omit<Partial<IInterviewQuestion>, "type" | "domain">
    originalIqToBeBuilt?: IOutlineTabIqsContextState["iqToBeBuilt"]
    isEditing: boolean
    selectedExAnteAnalysisId?: string
    isSimulateQuestionSidebarOpen: boolean
    /**
     * Blocks or allows simulate question.
     */
    isSimulateQuestionAllowed: boolean
    currSynthAnswerIndex: number
    /**
     * Force generate synthetic answers.
     */
    shouldGenerateSyntheticAnswers: boolean
    /**
     * Max amount of synthetic answers for a question.
     */
    maxAmountOfSyntheticAnswers: number
}

type IOutlineTabIqsContextAction = {
    type: "set-iq-to-be-built"
    iq: Partial<IInterviewQuestion>
    isEditing?: boolean
    shouldReloadOriginalIq?: boolean
} | {
    type: "set-iq-attribute"
    attribute: string
    value: unknown
    overrideOriginalIq?: boolean
} | {
    type: "clear-iq-being-built"
} | {
    type: "add-analysis-to-iq-to-be-built"
    analysisType: IAnalysisType
} | {
    type: "set-analysis-to-iq-to-be-built"
    analysis: Partial<IAnalysis>
    isEditing?: boolean
    shouldReloadOriginalIq?: boolean
} | {
    type: "replace-analyses-to-iq-to-be-built"
    analyses: Partial<IAnalysis>[]
    isEditing?: boolean
    shouldReloadOriginalIq?: boolean
} | {
    type: "remove-analysis-from-iq-to-be-built"
    analysisId: string
} | {
    type: "set-selected-ex-ante-analysis-id"
    analysisId: string
} | {
    type: "remove-selected-ex-ante-analysis"
} | {
    type: "open-simulate-question-sidebar"
} | {
    type: "close-simulate-question-sidebar"
} | {
    type: "add-synthetic-answers-to-iq-to-be-built"
    syntheticAnswers: IInterview[]
} | {
    type: "set-synthetic-answers-to-iq-to-be-built"
    syntheticAnswers: IInterview[]
    shouldReloadOriginalIq?: boolean
} | {
    type: "set-curr-synth-answer-index"
    index: number
} | {
    type: "increase-curr-synth-answer-index"
} | {
    type: "decrease-curr-synth-answer-index"
} | {
    type: "force-generate-synthetic-answers"
} | {
    type: "clear-generate-synthetic-answers"
} | {
    type: "filter-old-synth-answers"
}
// #endregion

// #region Context definitions
const OutlineTabIqsContext = createContext(
    {} as IOutlineTabIqsContextState
)
const OutlineTabIqsContextDispatch = createContext(
    {} as React.Dispatch<IOutlineTabIqsContextAction>
)
// #endregion

// #region Hook definitions
export function useOutlineTabIqs() {
    return useContext(OutlineTabIqsContext)
}

export function useOutlineTabIqsDispatch() {
    return useContext(OutlineTabIqsContextDispatch)
}
// #endregion

// #region Util functions
function setAttributeOfPartialIq(
    attribute: string,
    value: unknown,
    iq?: IOutlineTabIqsContextState["iqToBeBuilt"]
) {
    if (!iq) return { [attribute]: value, domain: "interview" as const, syntheticAnswers: [] }

    return { ...iq, [attribute]: value, syntheticAnswers: [] }
}

function shouldAllowSimulateQuestion({ iqToBeBuilt: iq, currSynthAnswerIndex, isSimulateQuestionSidebarOpen }: {
    iqToBeBuilt?: IOutlineTabIqsContextState["iqToBeBuilt"]
    currSynthAnswerIndex: number
    isSimulateQuestionSidebarOpen: boolean
}) {
    // question must exist
    return !!iq?.id && (
        // sidebar is not open
        !isSimulateQuestionSidebarOpen ||
        // last synthetic answer is shown
        (!!iq?.syntheticAnswers?.length && currSynthAnswerIndex === (iq?.syntheticAnswers?.length ?? 0) - 1)
    )
}
// #endregion

// #region Provider definition
export default function OutlineTabIqsProvider({
    children
}: Readonly<IOutlineTabIqsProviderProps>) {
    const initialState: IOutlineTabIqsContextState = {
        isEditing: false,
        isSimulateQuestionSidebarOpen: false,
        currSynthAnswerIndex: 0,
        isSimulateQuestionAllowed: false,
        shouldGenerateSyntheticAnswers: false,
        maxAmountOfSyntheticAnswers: 80
    }

    const [state, dispatch] = useReducer(OutlineTabIqsReducer, initialState)

    const actualState = useMemo(() => ({
        ...state,
        isSimulateQuestionAllowed: shouldAllowSimulateQuestion({ ...state })
    }), [state])

    return (
        <OutlineTabIqsContext.Provider value={actualState}>
            <OutlineTabIqsContextDispatch.Provider value={dispatch}>
                {children}
            </OutlineTabIqsContextDispatch.Provider>
        </OutlineTabIqsContext.Provider>
    )
}
// #endregion

// #region Reducer definition
function OutlineTabIqsReducer(
    state: IOutlineTabIqsContextState,
    action: IOutlineTabIqsContextAction
): IOutlineTabIqsContextState {
    switch (action.type) {
        case "set-iq-to-be-built": {
            const baseValues = {
                domain: action.iq.domain ?? "interview" as const,
                img: action.iq.img ?? undefined,
                embed_url: action.iq.embed_url?.length ? action.iq.embed_url : undefined,
                header: action.iq.header?.length ? action.iq.header : undefined,
                last_updated: action.iq.last_updated ?? new Date().toISOString(),
                syntheticAnswers: []
            }

            const newIqToBeBuilt = { ...action.iq, ...baseValues }

            return {
                ...state,
                iqToBeBuilt: newIqToBeBuilt,
                originalIqToBeBuilt: action.shouldReloadOriginalIq
                    ? deepCopy(newIqToBeBuilt)
                    : state.originalIqToBeBuilt,
                isEditing: action.isEditing ?? false,
                selectedExAnteAnalysisId: undefined,
                currSynthAnswerIndex: 0
            }
        }
        case "set-iq-attribute": {
            const newIqToBeBuilt = setAttributeOfPartialIq(action.attribute, action.value, state.iqToBeBuilt)
            return {
                ...state,
                iqToBeBuilt: newIqToBeBuilt,
                originalIqToBeBuilt: action.overrideOriginalIq
                    ? setAttributeOfPartialIq(action.attribute, action.value, state.originalIqToBeBuilt)
                    : state.originalIqToBeBuilt
            }
        }
        case "clear-iq-being-built": {
            return {
                ...state,
                iqToBeBuilt: undefined,
                selectedExAnteAnalysisId: undefined,
                currSynthAnswerIndex: 0
            }
        }
        case "add-analysis-to-iq-to-be-built": {
            if (!state.iqToBeBuilt) return state

            const newAnalysisId = uuid()
            const newAnalyses = [...(state.iqToBeBuilt.analyses ?? []), {
                id: newAnalysisId,
                type: action.analysisType,
                isEditing: false
            }]

            return {
                ...state,
                iqToBeBuilt: { ...state.iqToBeBuilt, analyses: newAnalyses },
                selectedExAnteAnalysisId: newAnalysisId
            }
        }
        case "set-analysis-to-iq-to-be-built": {
            if (!state.iqToBeBuilt) return state

            const newAnalyses = state.iqToBeBuilt.analyses ?? []
            const isEditing = action.isEditing ?? false
            const analysisIdx = newAnalyses.findIndex(a => a.id === action.analysis.id)
            if (analysisIdx === -1)
                newAnalyses.push({ ...action.analysis, isEditing })
            else
                newAnalyses[analysisIdx] = { ...action.analysis, isEditing }

            const newIqToBeBuilt = { ...state.iqToBeBuilt, analyses: newAnalyses }

            return {
                ...state,
                iqToBeBuilt: newIqToBeBuilt,
                originalIqToBeBuilt: action.shouldReloadOriginalIq
                    ? deepCopy(newIqToBeBuilt)
                    : state.originalIqToBeBuilt
            }
        }
        case "replace-analyses-to-iq-to-be-built": {
            if (!state.iqToBeBuilt) return state

            const newIqToBeBuilt = {
                ...state.iqToBeBuilt,
                analyses: action.analyses.map(a => ({ ...a, isEditing: action.isEditing ?? false }))
            }

            return {
                ...state,
                selectedExAnteAnalysisId: undefined,
                iqToBeBuilt: newIqToBeBuilt,
                originalIqToBeBuilt: action.shouldReloadOriginalIq
                    ? deepCopy(newIqToBeBuilt)
                    : state.originalIqToBeBuilt
            }
        }
        case "remove-analysis-from-iq-to-be-built": {
            if (!state.iqToBeBuilt) return state

            const newAnalyses = (state.iqToBeBuilt.analyses ?? []).filter(a => a.id !== action.analysisId)

            return { ...state, iqToBeBuilt: { ...state.iqToBeBuilt, analyses: newAnalyses } }
        }
        case "set-selected-ex-ante-analysis-id": {
            if (!state.iqToBeBuilt || !state.iqToBeBuilt.analyses?.length) return state
            return { ...state, selectedExAnteAnalysisId: action.analysisId }
        }
        case "remove-selected-ex-ante-analysis": {
            return { ...state, selectedExAnteAnalysisId: undefined }
        }
        case "open-simulate-question-sidebar": {
            return {
                ...state,
                isSimulateQuestionSidebarOpen: true
            }
        }
        case "close-simulate-question-sidebar": {
            return {
                ...state,
                isSimulateQuestionSidebarOpen: false
            }
        }
        case "add-synthetic-answers-to-iq-to-be-built": {
            if (!state.iqToBeBuilt) return state
            const newIqToBeBuilt = {
                ...state.iqToBeBuilt,
                syntheticAnswers: [...(state.iqToBeBuilt.syntheticAnswers ?? []), ...action.syntheticAnswers]
            }

            return {
                ...state,
                iqToBeBuilt: newIqToBeBuilt
            }
        }
        case "set-synthetic-answers-to-iq-to-be-built": {
            if (!state.iqToBeBuilt) return state

            const newIqToBeBuilt = { ...state.iqToBeBuilt, syntheticAnswers: action.syntheticAnswers }
            const isCurrSynthAnswerIndexValid = state.currSynthAnswerIndex >= 0
                && state.currSynthAnswerIndex <= (action.syntheticAnswers.length - 1)

            return {
                ...state,
                iqToBeBuilt: newIqToBeBuilt,
                currSynthAnswerIndex: isCurrSynthAnswerIndexValid
                    ? state.currSynthAnswerIndex
                    : 0,
                originalIqToBeBuilt: action.shouldReloadOriginalIq
                    ? deepCopy(newIqToBeBuilt)
                    : state.originalIqToBeBuilt
            }
        }
        case "set-curr-synth-answer-index": {
            const maxIndex = (state.iqToBeBuilt?.syntheticAnswers?.length ?? 1) - 1
            const newIndex = Math.min(Math.max(action.index, 0), maxIndex)

            return {
                ...state,
                currSynthAnswerIndex: newIndex
            }
        }
        case "increase-curr-synth-answer-index": {
            const canIncrease = state.currSynthAnswerIndex < (state.iqToBeBuilt?.syntheticAnswers?.length ?? 0) - 1
            const newIndex = canIncrease ? state.currSynthAnswerIndex + 1 : state.currSynthAnswerIndex
            return {
                ...state,
                currSynthAnswerIndex: newIndex
            }
        }
        case "decrease-curr-synth-answer-index": {
            const canDecrease = state.currSynthAnswerIndex > 0
            const newIndex = canDecrease ? state.currSynthAnswerIndex - 1 : state.currSynthAnswerIndex
            return {
                ...state,
                currSynthAnswerIndex: newIndex
            }
        }
        case "force-generate-synthetic-answers": {
            return {
                ...state,
                shouldGenerateSyntheticAnswers: true
            }
        }
        case "clear-generate-synthetic-answers": {
            return {
                ...state,
                shouldGenerateSyntheticAnswers: false
            }
        }
        case "filter-old-synth-answers": {
            if (!state.iqToBeBuilt || !state.iqToBeBuilt.last_updated) return state

            const lastUpdatedMilliseconds = Date.parse(state.iqToBeBuilt.last_updated)

            return {
                ...state,
                iqToBeBuilt: {
                    ...state.iqToBeBuilt,
                    syntheticAnswers: state.iqToBeBuilt.syntheticAnswers?.filter(
                        a => Date.parse(a.last_updated) > lastUpdatedMilliseconds
                    )
                }
            }
        }
        default: {
            return state
        }
    }
}
// #endregion