import { copy, getCopy } from "@utils/Copy"
import { useCallback, useRef, useState } from "react"
import { MdAddCircleOutline } from "react-icons/md"
import { useOutlineTabIqs, useOutlineTabIqsDispatch } from "../../contexts/OutlineTabIqsProvider"
import { useOutlineTab } from "../../contexts/OutlineTabProvider"
import NewAnalysisPopup from "@components/Popups/NewAnalysisPopup"
import { IAnalysisType } from "@/@types/analysis"
import ExAnteAnalysisList from "../OutlineTabContentIqsQuestionFormBodyExAnteAnalysisList"
import { glautTooltipId } from "@components/layouts/GlautTooltip/constants/id"
import GlautButtonSecondary from "@components/Buttons/GlautButtonSecondary"

export default function OutlineTabContentIqsQuestionFormBodyExAnteAnalysis() {
    // #region Contexts
    const { canEdit } = useOutlineTab()
    const { iqToBeBuilt } = useOutlineTabIqs()
    const outlineTabIqsDispatch = useOutlineTabIqsDispatch()
    // #endregion

    // #region States
    const [isSelectingAnalysis, setIsSelectingAnalysis] = useState(false)
    // #endregion

    // #region Refs
    const createAnalysisButtonRef = useRef<HTMLButtonElement>(null)
    // #endregion

    // #region Callbacks
    const handleOpenNewAnalysisPopup = useCallback(() => {
        setIsSelectingAnalysis(true)
    }, [])

    const handleSelectNewAnalysisType = useCallback((type: IAnalysisType) => {
        outlineTabIqsDispatch({ type: "add-analysis-to-iq-to-be-built", analysisType: type })
        setIsSelectingAnalysis(false)
    }, [outlineTabIqsDispatch])
    // #endregion

    if (!iqToBeBuilt) return <></>

    return (
        <div className="flex flex-col gap-[0.75em]">
            <div className="flex justify-between">
                <p className="text-[16px] text-glaut-bar">
                    {getCopy(copy.outline.interviewQuestions.analyzeTheQuestion)?.toUpperCase()}
                </p>
                <GlautButtonSecondary
                    className="group"
                    onClick={handleOpenNewAnalysisPopup}
                    disabled={!canEdit}
                    ref={createAnalysisButtonRef}
                    data-tooltip-id={glautTooltipId}
                    data-tooltip-content={getCopy(copy.outline.interviewQuestions.addAnalysis)}
                    data-tooltip-hidden={!canEdit}
                    squared
                >
                    <MdAddCircleOutline className="w-[1.125em] h-[1.125em]" />
                </GlautButtonSecondary>
                {isSelectingAnalysis && (
                    <NewAnalysisPopup
                        anchorRef={createAnalysisButtonRef}
                        onClose={() => { setIsSelectingAnalysis(false) }}
                        onSelect={handleSelectNewAnalysisType}
                        place="left"
                    />
                )}
            </div>
            <ExAnteAnalysisList />
        </div>
    )
}