import { IInterview } from "@/@types/entry"
import ArrowKeys from "@components/Icons/ArrowKeys"
import GlautMessage from "@components/layouts/GlautMessage"
import { useInterviewService } from "@hooks/services/useInterviewService"
import { copy, getCopy } from "@utils/Copy"
import { deepCopy } from "@utils/miscellaneous"
import { useCallback, useEffect, useRef, useState } from "react"
import { MdClose } from "react-icons/md"
import { useOutlineTabIqs, useOutlineTabIqsDispatch } from "../../contexts/OutlineTabIqsProvider"
import { useLegacyProject } from "../../contexts/ProjectProvider"
import List from "../OutlineTabContentIqsQuestionSyntheticAnswersList"
import Loading from "../OutlineTabContentIqsQuestionSyntheticAnswersLoading"
import { IDataPoolingMode } from "./@types/pooling"
import { FINISH_GENERATION_TIMEOUT_MS, POLLING_INTERVAL_MS } from "./constants/intervals"
import { SYNTHETIC_ANSWERS_BATCH_SIZE } from "./constants/batch"
import { POOLING_TOLERANCE_COUNTER_MAX_VALUE } from "./constants/tolerance"

export default function OutlineTabContentIqsQuestionSyntheticAnswers() {
    // #region Contexts
    const { project, lang } = useLegacyProject()
    const {
        iqToBeBuilt,
        isSimulateQuestionSidebarOpen,
        shouldGenerateSyntheticAnswers,
        maxAmountOfSyntheticAnswers
    } = useOutlineTabIqs()
    const outlineTabIqsDispatch = useOutlineTabIqsDispatch()
    // #endregion

    // #region Services
    const interviewService = useInterviewService()
    // #endregion

    // #region States
    const [dataPoolingMode, setDataPoolingMode] = useState<IDataPoolingMode>("initial")
    const [generationPercentage, setGenerationPercentage] = useState(0)
    // #endregion

    // #region Refs
    const synthAnswersPrevAmountRef = useRef(0)
    const synthAnswersCurrGenerationAmountRef = useRef(0)
    const synthAnswersPoolingToleranceCounterRef = useRef(0)
    // #endregion

    // #region Callbacks
    const handleCloseQuestionSimulation = useCallback(() => {
        outlineTabIqsDispatch({ type: "close-simulate-question-sidebar" })
    }, [outlineTabIqsDispatch])

    const generateSyntheticAnswers = useCallback(() => {
        if (!project || dataPoolingMode !== "creating") return

        const prevAmountBeforeGeneration = iqToBeBuilt?.syntheticAnswers?.length ?? 0
        const amount = Math.min(
            maxAmountOfSyntheticAnswers - prevAmountBeforeGeneration,
            SYNTHETIC_ANSWERS_BATCH_SIZE
        )

        if (amount <= 0) return

        synthAnswersCurrGenerationAmountRef.current = amount
        synthAnswersPrevAmountRef.current = prevAmountBeforeGeneration

        interviewService.generateSyntheticAnswers({ projectId: project._id, amount, lang }).then(() => {
            setDataPoolingMode("pooling")
        })
    }, [
        project,
        dataPoolingMode,
        iqToBeBuilt?.syntheticAnswers?.length,
        maxAmountOfSyntheticAnswers,
        interviewService,
        lang
    ])

    const fetchSyntheticAnswers = useCallback(() => {
        if (!project) return Promise.resolve({ n_pages: 0, total_interviews: 0, interviews: [] })
        return interviewService.getInterviews({
            projectId: project._id,
            synthetic: true,
            page: 0,
            lastUpdatedAfter: iqToBeBuilt?.last_updated,
            pageSize: maxAmountOfSyntheticAnswers
        })
    }, [interviewService, project, iqToBeBuilt, maxAmountOfSyntheticAnswers])

    const filterInterviewsFromIqToBeBuilt = useCallback((interviews: IInterview[]) => interviews.filter(
        i => i.messages.some(m => m.question_id === iqToBeBuilt?.id)
    ), [iqToBeBuilt])
    // #endregion

    // #region Effects

    // onShouldGenerateSyntheticAnswers
    useEffect(() => {
        if (!shouldGenerateSyntheticAnswers || dataPoolingMode !== "done") return

        setDataPoolingMode("creating")
    }, [dataPoolingMode, shouldGenerateSyntheticAnswers])

    // onFirstRenderMarkToCreatingOrDone
    useEffect(() => {
        if (!project || !iqToBeBuilt || !isSimulateQuestionSidebarOpen || dataPoolingMode !== "initial") return

        if (iqToBeBuilt.syntheticAnswers?.length) {
            setDataPoolingMode("done")
            return
        }

        fetchSyntheticAnswers().then(({ interviews }) => {
            if (interviews.length > 0) {
                setDataPoolingMode("done")
                outlineTabIqsDispatch({
                    type: "set-synthetic-answers-to-iq-to-be-built",
                    syntheticAnswers: interviews,
                    shouldReloadOriginalIq: true
                })
                return
            }

            setDataPoolingMode("creating")
        })
    }, [
        project,
        dataPoolingMode,
        iqToBeBuilt,
        isSimulateQuestionSidebarOpen,
        outlineTabIqsDispatch,
        fetchSyntheticAnswers
    ])

    // onDataCreatingGenerateSyntheticAnswers
    useEffect(() => {
        if (!project || !iqToBeBuilt || !isSimulateQuestionSidebarOpen || dataPoolingMode !== "creating") return

        generateSyntheticAnswers()
        setDataPoolingMode("pooling")
    }, [dataPoolingMode, generateSyntheticAnswers, iqToBeBuilt, isSimulateQuestionSidebarOpen, project])

    // onDataPoolingFetchSyntheticAnswers
    useEffect(() => {
        if (!project || !iqToBeBuilt || !isSimulateQuestionSidebarOpen || dataPoolingMode !== "pooling") return

        const interval = setInterval(() => {
            fetchSyntheticAnswers().then(({ interviews }) => {
                // Does it have enough interviews?
                const numOfGeneratedInterviews = interviews.length - synthAnswersPrevAmountRef.current
                const generationAmount = synthAnswersCurrGenerationAmountRef.current || 1
                setGenerationPercentage((numOfGeneratedInterviews / generationAmount) * 100)
                if (numOfGeneratedInterviews < generationAmount) return

                // Pooling tolerance counter to assert all interviews are correctly generated
                synthAnswersPoolingToleranceCounterRef.current++
                const shouldForceContinue =
                    synthAnswersPoolingToleranceCounterRef.current > POOLING_TOLERANCE_COUNTER_MAX_VALUE

                // Do all interviews have at least one message?
                const allInterviewsHaveAtLeastOneMessage = interviews.every(i => i.messages.length > 0)
                if (!shouldForceContinue && !allInterviewsHaveAtLeastOneMessage) return

                // Does the last interview has an answer from respondent to the curr IQ?
                const lastInterview = deepCopy(interviews[interviews.length - 1])

                const reversedMessages = [...lastInterview.messages].reverse()
                const isLastInterviewMessageFromRespondent = reversedMessages.find(
                    message => message.question_id === iqToBeBuilt.id
                )?.role === "respondent"

                if (!shouldForceContinue && !isLastInterviewMessageFromRespondent) return

                // For not open questions, does the last interview has follow ups?
                if (iqToBeBuilt.type !== "open") {
                    const doesLastInterviewHasFollowUps = lastInterview.messages.filter(
                        m => m.question_id === iqToBeBuilt.id && m.role === "moderator"
                    ).length > 1

                    if (!shouldForceContinue && !doesLastInterviewHasFollowUps) return
                }

                // For UX: finish loading state
                setTimeout(() => {
                    outlineTabIqsDispatch({
                        type: "set-synthetic-answers-to-iq-to-be-built",
                        syntheticAnswers: interviews,
                        shouldReloadOriginalIq: true
                    })

                    outlineTabIqsDispatch({ type: "clear-generate-synthetic-answers" })

                    setDataPoolingMode("done")
                    setGenerationPercentage(0)
                    synthAnswersCurrGenerationAmountRef.current = 0
                    synthAnswersPoolingToleranceCounterRef.current = 0
                }, FINISH_GENERATION_TIMEOUT_MS)
            })
        }, POLLING_INTERVAL_MS)

        return () => { clearInterval(interval) }
    }, [
        project,
        iqToBeBuilt,
        dataPoolingMode,
        isSimulateQuestionSidebarOpen,
        fetchSyntheticAnswers,
        outlineTabIqsDispatch,
        filterInterviewsFromIqToBeBuilt
    ])

    // onChangeIqMarkAsInitialState
    useEffect(() => {
        if (!project) return

        setDataPoolingMode("initial")
    }, [project, iqToBeBuilt?.id])

    // onZeroSyntheticAnswersTriggerGeneration
    useEffect(() => {
        if (
            !project ||
            !isSimulateQuestionSidebarOpen ||
            dataPoolingMode !== "done" ||
            !!iqToBeBuilt?.syntheticAnswers?.length
        ) return

        setDataPoolingMode("initial")
    }, [dataPoolingMode, iqToBeBuilt, isSimulateQuestionSidebarOpen, project])

    // #endregion

    if (!iqToBeBuilt || !isSimulateQuestionSidebarOpen) return <></>

    return (
        <div className="flex-1 flex flex-col gap-[0.5rem]">
            <div className="flex justify-between items-center">
                <p className="text-[16px] text-glaut-bar">
                    {getCopy(copy.outline.interviewQuestions.questionSimulation)?.toUpperCase()}
                </p>
                <button
                    type="button"
                    aria-label="Close question simulation"
                    className="border-none shadow-none p-[0.25rem] m-0 bg-transparent rounded-[0.25rem] 
                        hover:bg-glaut-stroke-glaut"
                    onClick={handleCloseQuestionSimulation}
                >
                    <MdClose className="h-[1em] w-[1em] text-glaut-dark-grey" />
                </button>
            </div>
            <div className="flex-1 flex flex-col gap-[0.5rem] overflow-auto">
                {dataPoolingMode !== "done" && (
                    <Loading percentage={generationPercentage} />
                )}
                {dataPoolingMode === "done" && <List />}
                <GlautMessage
                    message={getCopy(
                        copy.outline.interviewQuestions.tipUseYourArrowKeysToChangeSimulatedResponsesExclamation
                    ) ?? ""}
                    alignment="center"
                    icon={<ArrowKeys />}
                />
            </div>
        </div>
    )
}