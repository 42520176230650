import PdfGlautWideLogo from "@components/Pdf/PdfGlautWideLogo"
import { useBrowserUserAgent } from "@hooks/useBrowserUserAgent"
import { Document, Page, View } from "@react-pdf/renderer"
import { forwardRef, Fragment, useCallback, useEffect, useRef } from "react"
import { IReport } from "src/@types/reports"
import { useReportDetails } from "../../contexts/ReportDetailsContext"
import { defaultPageTwStyling } from "../../utils/pdf-styling"
import KeyInterviewData from "../ReportDetailsContentKeyInterviewData"
import LastEditedAt from "../ReportDetailsContentLastEditedAt"
import StandardTextSession from "../ReportDetailsContentStandardTextSession"
import Title from "../ReportDetailsContentTitle"
import ReportDetailsNpsInsightBlock from "../ReportDetailsNpsInsightBlock"
import ReportDetailsOpenInsightBlock from "../ReportDetailsOpenInsightBlock"
import ReportDetailsSelectInsightBlock from "../ReportDetailsSelectInsightBlock"
import { ArrayElement } from "@utils/types/array-element"

const ReportDetailsContent = forwardRef<HTMLDivElement>((_, ref) => {
    // #region Contexts
    const { report, scrollToBlockId, renderAsPdf, tw } = useReportDetails()
    // #endregion

    // #region Refs
    const blockRefs = useRef<{ [blockId: string]: HTMLDivElement }>({})
    // #endregion

    // #region Browser hooks
    const { os: { isIos } } = useBrowserUserAgent()
    // #endregion

    // #region Callbacks
    const assignToBlockRefs = useCallback(
        (block: ArrayElement<IReport["appendix"]>, element: HTMLDivElement | null) => {
            if (!element) return
            blockRefs.current[block.id] = element
        },
        []
    )
    // #endregion

    // #region Effects
    useEffect(() => {
        if (!scrollToBlockId) return

        if (isIos) {
            // In iOS, the `.scrollIntoView()` function makes the ReportDetailsHeader to disappear. This workaround does
            // the trick
            const element = blockRefs.current[scrollToBlockId]
            if (!element) return

            const { x, y } = element.getBoundingClientRect()
            document.getElementById("div--report-container")?.scrollBy(x, y - 60)
            return
        }

        blockRefs.current[scrollToBlockId]?.scrollIntoView({ behavior: "smooth" })
    }, [scrollToBlockId, isIos])
    // #endregion

    // #region Element callbacks
    const toBlockComponent = useCallback((block: ArrayElement<IReport["appendix"]>, index: number) => {
        if (block.type === "open")
            return (
                <ReportDetailsOpenInsightBlock
                    key={index}
                    block={block}
                    ref={element => { assignToBlockRefs(block, element) }}
                />
            )

        if (block.type === "select")
            return (
                <ReportDetailsSelectInsightBlock
                    key={index}
                    block={block}
                    ref={element => { assignToBlockRefs(block, element) }}
                />
            )

        if (block.type === "nps")
            return (
                <ReportDetailsNpsInsightBlock
                    key={index}
                    block={block}
                    ref={element => { assignToBlockRefs(block, element) }}
                />
            )

        return <Fragment key={index}></Fragment>
    }, [assignToBlockRefs])
    // #endregion

    if (!report) return <></>

    if (renderAsPdf)
        return (
            <Document title={report.title}>
                <Page
                    bookmark="Introduction"
                    style={tw(`${defaultPageTwStyling} flex flex-col gap-[15.28px]`)}
                    size="A4"
                >
                    <View style={tw("flex flex-col")}>
                        <View style={tw("px-[8px] pb-[4px] border-b-1 border-b-glautLightGrey")}>
                            <PdfGlautWideLogo />
                        </View>
                        <View style={tw("mb-[30.54px]")}>
                            <Title />
                        </View>
                        <LastEditedAt />
                    </View>
                    <StandardTextSession title="Project description" content={report.project_description} />
                    <StandardTextSession title="Project goal" content={report.goal} />
                    <KeyInterviewData />
                </Page>
                <Page
                    bookmark="Executive summary"
                    style={tw(`${defaultPageTwStyling} flex flex-col gap-[0.75em]`)}
                    size="A4"
                >
                    <StandardTextSession title="Executive summary" content={report.executive_summary} />
                </Page>
                {report.appendix.map(toBlockComponent)}
            </Document>
        )

    return (
        <div className={`mx-[0.625em] flex flex-col gap-[0.75em] pb-96
            sm:container sm:mx-auto sm:max-w-[80%] sm:mt-20
            lg:max-w-[70%]
            2xl:max-w-[50%]
        `} ref={ref}>
            <Title />
            <LastEditedAt />
            <StandardTextSession title="Project description" content={report.project_description} />
            <StandardTextSession title="Project goal" content={report.goal} />
            <KeyInterviewData />
            <StandardTextSession title="Executive summary" content={report.executive_summary} />
            {report.appendix.map(toBlockComponent)}
        </div>
    )
})

ReportDetailsContent.displayName = "ReportDetailsContent"

export default ReportDetailsContent