import { ParticipantEvent, RemoteParticipant } from "livekit-client"
import { useState, useEffect, useRef } from "react"

interface IUseDebouncedIsPlayingProps {
    shouldAllowSpeaking?: boolean
    isWebRTC?: boolean
}

export function useDebouncedIsPlaying({
    shouldAllowSpeaking = true,
    isWebRTC = false
}: Readonly<IUseDebouncedIsPlayingProps>) {
    // #region States
    const [participant, setParticipant] = useState<RemoteParticipant>()
    const [isPlaying, setIsPlaying] = useState(false)
    // #endregion

    // #region Refs
    const timeoutRef = useRef<NodeJS.Timeout>()
    // #endregion

    useEffect(() => {
        // We still need to set the participant
        if (!isWebRTC || !participant) return

        const handleIsSpeakingChanged = speaking => {
            // Clear any existing timeout
            if (timeoutRef.current) clearTimeout(timeoutRef.current)
            if (!shouldAllowSpeaking) {
                if (isPlaying || isPlaying === null) setIsPlaying(false)
                return
            }

            // If speaking is true we update it right always, else we debounce
            if (speaking) setIsPlaying(true)
            else timeoutRef.current = setTimeout(() => setIsPlaying(speaking), 500)
        }

        // Attach the event listener to the participant
        participant.on(ParticipantEvent.IsSpeakingChanged, handleIsSpeakingChanged)

        // Cleanup the event listener and timeout on unmount
        return () => {
            participant.off(ParticipantEvent.IsSpeakingChanged, handleIsSpeakingChanged)
            if (timeoutRef.current) clearTimeout(timeoutRef.current)
        }
    }, [isWebRTC, participant, shouldAllowSpeaking, isPlaying])

    return { isPlaying, setIsPlaying, setParticipant }
}