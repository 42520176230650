import { View } from "@react-pdf/renderer"
import { useReportDetails } from "../../contexts/ReportDetailsContext"

interface IReportDetailsContentKeyInterviewDataMetricWrapperProps {
    children: React.ReactNode
}

export default function ReportDetailsContentKeyInterviewDataMetricWrapper({
    children
}: Readonly<IReportDetailsContentKeyInterviewDataMetricWrapperProps>) {
    // #region Contexts
    const { renderAsPdf, tw } = useReportDetails()
    // #endregion

    if (renderAsPdf)
        return (
            <View style={tw("bg-glautCards rounded-[5.09px] flex-1 flex flex-row items-center justify-center")}>
                {children}
            </View>
        )

    return (
        <div className="bg-glaut-cards rounded-[0.5em] flex-1 flex items-center justify-center min-h-16">
            {children}
        </div>
    )
}