import GlautButtonPrimary from "@components/Buttons/GlautButtonPrimary"
import GlautButtonSecondary from "@components/Buttons/GlautButtonSecondary"
import ModalLayout from "@components/layouts/ModalLayout"
import Loading from "@components/loading/Loading"
import { useAnalysisService } from "@hooks/services/useAnalysisService"
import { copy, getCopy } from "@utils/Copy"
import { deepCopy } from "@utils/miscellaneous"
import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { MdAddCircle, MdOutlineInfo } from "react-icons/md"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import { IProject, IProjectResults } from "src/@types/project"
import { v4 as uuid } from "uuid"
import { ProjectContext } from "../../../AutoSaveProject"
import {
    useAnalysisTabAnalysisCol,
    useAnalysisTabAnalysisColDispatch
} from "../../contexts/AnalysisTabAnalysisColProvider"
import { useAnalysisTab, useAnalysisTabDispatch } from "../../contexts/AnalysisTabProvider"
import AnalysisTabAnalysisColReviewCodeBookModalThemeItem from "../AnalysisTabAnalysisColReviewCodeBookModalThemeItem"
import GlautTextAreaMultiLine from "@components/inputs/GlautTextAreaMultiLine"

interface IManualEditedTheme {
    label: string
    description: string | null
    id: string
}

interface IAnalysisTabAnalysisColReviewCodeBookModalProps {
    isOpen: boolean
    onClose?: () => void
}

// Note: the modal works for TA, IA, and entity. IA CTA has been deactivated for the time being due to redundancy, but
// it isn't definitive. Please do not remove any IA functionality until further notice.
export default function AnalysisTabAnalysisColReviewCodeBookModal({
    isOpen,
    onClose
}: Readonly<IAnalysisTabAnalysisColReviewCodeBookModalProps>) {
    // #region Contexts
    const { project } = useContext(ProjectContext) as { project: IProject | null }
    const { selectedAnalysis, categoryColorIndexes, processingAnalysisIds, analysesStats } = useAnalysisTab()
    const analysisTabDispatch = useAnalysisTabDispatch()
    const { categoryBeingEdited, codeBook: { isInClearState, edits } } = useAnalysisTabAnalysisCol()
    const analysisTabAnalysisColDispatch = useAnalysisTabAnalysisColDispatch()
    // #endregion

    // #region Services
    const analysisService = useAnalysisService()
    // #endregion

    // #region States

    const [promptContent, setPromptContent] = useState(selectedAnalysis?.finalization ?? "")
    const [isRunningUpdateWithoutGrouping, setIsRunningUpdateWithoutGrouping] = useState(false)

    // As the final analysis will change, we need a deep copy of the current one
    const [newAnalysisData, setNewAnalysisData] = useState(deepCopy(selectedAnalysis))
    const [addedCategories, setAddedCategories] = useState<string[]>([])
    const [removedCategories, setRemovedCategories] = useState<string[]>([])

    const [isSubmittingReview, setIsSubmittingReview] = useState(false)

    const [selectedTab, setSelectedTab] = useState<"manual" | "prompt">("manual")

    // #endregion

    // #region Memos
    const selectedTabIndex = useMemo(() => ({ manual: 0, prompt: 1 }[selectedTab] ?? 0), [selectedTab])

    const isProcessing = useMemo(
        () => isRunningUpdateWithoutGrouping ||
            (selectedAnalysis && processingAnalysisIds.includes(selectedAnalysis.id)),
        [isRunningUpdateWithoutGrouping, processingAnalysisIds, selectedAnalysis]
    )
    const areNoActionsAvailableToBeMade = useMemo(() => Object.entries(edits).length === 0, [edits])
    const hasAnyOldThemeHadChanges = useMemo(() => Object.entries(edits).some(([themeId, themeData]) => {
        const category = (selectedAnalysis?.categories ?? []).find(c => c.id === themeId)
        if (!category) return false

        return category.label !== themeData.newLabel || (category.description ?? "") !== themeData.instruction
    }), [selectedAnalysis, edits])
    const doesAllThemesHaveADefinedLabel = useMemo(
        () => Object.entries(edits)
            .every(([, themeData]) => themeData.newLabel.length > 0),
        [edits]
    )
    const hasAnyOldThemeBeenRemoved = useMemo(
        () => {
            const previousNumberOfThemes = (selectedAnalysis?.categories ?? []).length
            const remainingNumberOfOldThemes = (newAnalysisData?.categories ?? []).length
                - addedCategories.length

            return previousNumberOfThemes > remainingNumberOfOldThemes
        },
        [newAnalysisData?.categories, selectedAnalysis?.categories, addedCategories.length]
    )
    const hasAnyOldThemeHadInstructionsChanged = useMemo(
        () => Object.entries(edits)
            .map(([themeId, themeData]) => {
                const category = (selectedAnalysis?.categories ?? []).find(c => c.id === themeId)
                if (!category) return undefined
                return { themeData, category }
            })
            .filter(i => !!i)
            .some(({ category, themeData }) => (category.description ?? "") !== themeData.instruction),
        [selectedAnalysis, edits]
    )

    const allowSavingManualEdit = useMemo(() => {
        if (isProcessing) return false

        // Zero actions to be made
        if (areNoActionsAvailableToBeMade) return false

        // No added themes
        if (addedCategories.length === 0)
            return hasAnyOldThemeHadChanges ? doesAllThemesHaveADefinedLabel : hasAnyOldThemeBeenRemoved

        // New themes have been added
        return doesAllThemesHaveADefinedLabel
    }, [
        isProcessing,
        areNoActionsAvailableToBeMade,
        addedCategories.length,
        hasAnyOldThemeHadChanges,
        hasAnyOldThemeBeenRemoved,
        doesAllThemesHaveADefinedLabel
    ])

    const modalTitleCopy = useMemo(() => {
        if (!newAnalysisData) return undefined
        if (newAnalysisData.type === "thematic") return copy.coding.reviewThemesAndCodes
        if (newAnalysisData.type === "interpretation") return copy.coding.editCategories

        return copy.coding.reviewEntities
    }, [newAnalysisData])

    const modalAddItemCopy = useMemo(() => {
        if (!newAnalysisData) return ""
        if (newAnalysisData.type === "thematic") return copy.coding.addTheme
        if (newAnalysisData.type === "interpretation") return copy.coding.addCategory

        return copy.coding.addEntity
    }, [newAnalysisData])
    // #endregion

    // #region Callbacks
    const handleSelectTab = useCallback((index: number) => {
        setSelectedTab(({ 0: "manual", 1: "prompt" } as const)[index] ?? "manual")
    }, [])

    const handleCloseModal = useCallback(() => {
        onClose?.()

        // for UX
        setTimeout(() => {
            setSelectedTab("manual")
        }, 200)
    }, [onClose])

    const handleExecuteUpdateWithoutGrouping = useCallback(() => {
        if (!project || !selectedAnalysis) return

        setIsRunningUpdateWithoutGrouping(true)

        const currThemesData = Object.entries(edits)
            .filter(([themeId, themeData]) => {
                const category = selectedAnalysis.categories.find(c => c.id === themeId)
                return category && category.label !== themeData.newLabel
            })
            .map(([themeId, themeData]) => ({ id: themeId, label: themeData.newLabel }))

        const projectId = project._id
        const analysisId = selectedAnalysis.id

        const updateCurrThemesPromises = currThemesData.map(
            async themeData => analysisService.updateAnalysisCategory({
                projectId,
                analysisId,
                categoryId: themeData.id,
                category: {
                    parent: null,
                    label: themeData.label,
                    description: selectedAnalysis.categories.find(c => c.id === themeData.id)?.description ?? null
                }
            })
        )

        const newThemesPromises = addedCategories
            .map(themeId => edits[themeId])
            .filter(i => !!i)
            .map(async theme => analysisService.createAnalysisCategory({
                projectId,
                analysisId,
                category: {
                    parent: null,
                    label: theme.newLabel,
                    description: theme.instruction.length > 0 ? theme.instruction : null
                }
            }))

        const removedThemesPromises = removedCategories.map(themeId => analysisService.deleteAnalysisCategory({
            projectId,
            analysisId,
            categoryId: themeId
        }))

        Promise.allSettled([
            ...updateCurrThemesPromises,
            ...newThemesPromises,
            ...removedThemesPromises
        ]).then(() => Promise.all([
            analysisService.getAnalysis({ projectId, analysisId }),
            analysisService.getAnalysesStats({ projectId })
        ])).then(([analysis, analysesStats]) => {
            analysisTabDispatch({ type: "update-analysis", analysis })
            analysisTabDispatch({
                type: "set-analyses-stats",
                analysesStats: (analysesStats as IProjectResults).analysis_results
            })

            analysisTabAnalysisColDispatch({ type: "clear-code-book" })
            setAddedCategories([])
            setRemovedCategories([])
        }).finally(() => {
            setIsRunningUpdateWithoutGrouping(false)
        })
    }, [
        analysisService,
        analysisTabAnalysisColDispatch,
        analysisTabDispatch,
        edits,
        project,
        selectedAnalysis,
        addedCategories,
        removedCategories
    ])

    const handleSavePrompt = useCallback(() => {
        if (
            !project ||
            !selectedAnalysis ||
            promptContent === "" ||
            isProcessing
        ) return

        const projectId = project._id
        const analysisId = selectedAnalysis.id

        setIsSubmittingReview(true)

        analysisService.executeCodebookReview({ projectId, analysisId, type: "prompt", promptContent }).then(() => {
            analysisTabDispatch({ type: "set-analysis-as-processing", analysisId })
            handleCloseModal()
        }).finally(() => {
            setIsSubmittingReview(false)
        })
    }, [analysisService, isProcessing, project, promptContent, selectedAnalysis, analysisTabDispatch, handleCloseModal])

    const handleSaveManualEdit = useCallback(() => {
        if (
            !project ||
            !selectedAnalysis ||
            !newAnalysisData ||
            isProcessing
        ) return

        const shouldUpdateWithoutGrouping = selectedAnalysis.type === "entity" ||
            (addedCategories.length === 0 && removedCategories.length === 0 && !hasAnyOldThemeHadInstructionsChanged)

        if (shouldUpdateWithoutGrouping) {
            handleExecuteUpdateWithoutGrouping()
            handleCloseModal()
            return
        }

        const editThemes: IManualEditedTheme[] = Object.entries(edits).map(([themeId, themeData]) => {
            const currentTheme = newAnalysisData.categories.find(c => c.parent === null && c.id === themeId)
            const label = (themeData.newLabel || currentTheme?.label) ?? ""
            const description = (themeData.instruction || currentTheme?.description) ?? null

            return { id: themeId, label, description }
        })

        const themes = newAnalysisData.categories
            .filter(c => c.parent === null && !removedCategories.includes(c.id))
            .map(c => editThemes.find(t => t.id === c.id) ?? { ...c, description: c.description ?? null })

        const projectId = project._id
        const analysisId = newAnalysisData.id

        setIsSubmittingReview(true)

        analysisService.executeCodebookReview({ projectId, analysisId, type: "manual", themes }).then(() => {
            analysisTabDispatch({ type: "set-analysis-as-processing", analysisId })
            setAddedCategories([])
            setRemovedCategories([])
            handleCloseModal()
        }).finally(() => {
            setIsSubmittingReview(false)
        })
    }, [
        project,
        selectedAnalysis,
        edits,
        newAnalysisData,
        isProcessing,
        analysisService,
        handleExecuteUpdateWithoutGrouping,
        handleCloseModal,
        addedCategories.length,
        removedCategories,
        hasAnyOldThemeHadInstructionsChanged,
        analysisTabDispatch
    ])

    const handleAddThemeFromManualEdit = useCallback(() => {
        if (!newAnalysisData || Object.entries(edits).some(([, data]) => data.newLabel === "")) return

        const themeId = uuid()
        setNewAnalysisData({
            ...newAnalysisData,
            categories: [
                ...newAnalysisData.categories,
                {
                    id: themeId,
                    label: "",
                    description: "",
                    parent: null
                }
            ]
        })
        setAddedCategories(prev => [...prev, themeId])
        analysisTabAnalysisColDispatch({
            type: "add-code-book-edit",
            themeId,
            instruction: "",
            newLabel: ""
        })
        analysisTabAnalysisColDispatch({
            type: "set-category-being-edited",
            category: { id: themeId, attribute: "name", type: "theme" }
        })
    }, [setNewAnalysisData, analysisTabAnalysisColDispatch, newAnalysisData, edits])

    const handleRemoveThemeFromManualEdit = useCallback((themeId: string) => {
        if (!newAnalysisData || !selectedAnalysis) return

        const theme = selectedAnalysis.categories.find(c => c.id === themeId)
        if (theme)
            setRemovedCategories(prev => [...prev, theme.id])

        setAddedCategories(prev => prev.filter(id => id !== themeId))
        setNewAnalysisData({
            ...newAnalysisData,
            categories: newAnalysisData.categories.filter(c => c.id !== themeId)
        })
        analysisTabAnalysisColDispatch({
            type: "add-code-book-edit",
            themeId,
            instruction: "",
            newLabel: "",
            shouldRemoveIfEmpty: true
        })
    }, [analysisTabAnalysisColDispatch, selectedAnalysis, newAnalysisData])
    // #endregion

    // #region Effects

    // onChangeSelectedAnalysisRefreshCodebook
    useEffect(() => {
        analysisTabAnalysisColDispatch({ type: "clear-code-book" })
        setNewAnalysisData(deepCopy(selectedAnalysis))
        setAddedCategories([])
        setRemovedCategories([])
        setPromptContent(selectedAnalysis?.finalization ?? "")
    }, [analysisTabAnalysisColDispatch, selectedAnalysis])

    // onNoEditsDoneRefreshAnalysisSnapshot
    useEffect(() => {
        if (
            !isOpen ||
            !selectedAnalysis ||
            !(isInClearState && Object.entries(edits).length === 0) ||
            removedCategories.length > 0 ||
            addedCategories.length > 0
        ) return

        setNewAnalysisData(deepCopy(selectedAnalysis))
        analysisTabAnalysisColDispatch({
            type: "set-code-book",
            codeBook: selectedAnalysis.categories.reduce((edits, c) => {
                if (c.parent) return edits

                edits[c.id] = { newLabel: c.label, instruction: c.description ?? "" }
                return edits
            }, {})
        })
    }, [
        isOpen,
        analysisTabAnalysisColDispatch,
        isInClearState,
        selectedAnalysis,
        edits,
        removedCategories.length,
        addedCategories.length
    ])

    // onEditingCategoryFocusOnItsInput
    useEffect(() => {
        if (categoryBeingEdited === undefined || categoryBeingEdited.type !== "theme") return
        setSelectedTab("manual")
    }, [categoryBeingEdited])

    // #endregion

    // #region Element memos

    const themeListElement = useMemo(() => {
        if (!newAnalysisData) return <Loading />

        const categories = newAnalysisData.categories
            .filter(c => c.id !== "empty") // No "No answer"
            .filter(c => c.parent === null) // Only root codes

        const stats = analysesStats[newAnalysisData.id]?.stats
        if (stats && stats.length > 0) {
            const occurrencesMap = categories.reduce((prev, curr) => {
                prev[curr.id] = stats.find(s => s.category.id === curr.id)?.occurrences ?? 0
                return prev
            }, {} as { [categoryId: string]: number })

            categories.sort((a, b) => occurrencesMap[b.id] - occurrencesMap[a.id])
        }

        return categories.map(c => (
            <AnalysisTabAnalysisColReviewCodeBookModalThemeItem
                key={c.id}
                label={c.label}
                description={c.description}
                colorIndex={categoryColorIndexes[c.id] ?? Math.round(Math.random() * 10)}
                themeId={c.id}
                removeCodeCallback={handleRemoveThemeFromManualEdit}
            />
        ))
    }, [newAnalysisData, analysesStats, categoryColorIndexes, handleRemoveThemeFromManualEdit])

    // #endregion

    return (
        <ModalLayout
            isOpen={isOpen}
            onClose={handleCloseModal}
            className={`
                flex flex-col bg-white rounded-xl shadow p-[1.125em] gap-3 transition-all border-1 border-glaut-grey
                w-[552px] max-h-screen overflow-auto no-scrollbar
                ${isOpen ? "scale-100 opacity-100" : "scale-125 opacity-0"}
            `}
        >
            <span className={`text-[1em] ${selectedAnalysis?.type === "thematic" ? "mb-[0.6875em]" : ""}`}>
                {modalTitleCopy ? getCopy(modalTitleCopy) : undefined}
            </span>
            {selectedAnalysis?.type !== "entity" && (
                <div className={`flex flex-row items-center gap-[0.75em] rounded-[4px] border-1 transition-all
                    border-glaut-dummie-color bg-glaut-cards p-[0.75em]
                `}>
                    <MdOutlineInfo className="text-glaut-text-midnight w-[1em] h-[1em]" />
                    <p className="text-[11.11px] text-glaut-text-midnight font-normal">
                        {getCopy(copy.coding.clickingTheSaveButtonWillTriggerGlaut)}
                    </p>
                </div>
            )}
            <Tabs
                className="w-full border-0"
                selectedTabClassName="border-b-glaut-pink border-b-2"
                selectedIndex={selectedTabIndex}
                onSelect={index => { handleSelectTab(index) }}
            >
                <TabList
                    className={`w-full grid grid-cols-2 border-b-1 border-b-glaut-stroke-glaut rounded-none
                        ${selectedAnalysis?.type !== "thematic" ? "hidden" : ""}
                    `}
                    style={{ columnGap: 0, padding: 0, marginBottom: "0.75em" }}
                >
                    <Tab
                        className={`w-full flex flex-row justify-center rounded-none mb-0 h-[36px] items-center 
                            cursor-pointer
                            ${selectedTab === "manual" ? "text-glaut-text-midnight" : "text-glaut-dark-grey"}
                            ${selectedTab === "manual" ? "bg-glaut-cards" : ""}
                        `}
                        disabled={isProcessing}
                    >
                        <p className="justify-self-center font-medium text-[13.33px]">
                            {getCopy(copy.coding.manualEdit)}
                        </p>
                    </Tab>
                    <Tab
                        className={`w-full flex flex-row justify-center rounded-none mb-0 h-[36px] items-center 
                            cursor-pointer
                            ${selectedTab === "prompt" ? "bg-glaut-cards" : ""}
                        `}
                        disabled={isProcessing}
                    >
                        <p className="justify-self-center font-medium text-[13.33px]
                            text-transparent bg-clip-text bg-gradient-to-tr from-[#5465FE] to-[#FF6392]">
                            {getCopy(copy.coding.promptGlaut)}
                        </p>
                    </Tab>
                </TabList>
                <TabPanel>
                    {selectedAnalysis?.type === "thematic" && (
                        <p className="text-glaut-dark-grey text-[13.33px] font-medium mb-[0.25em] mt-[0.75em]">
                            {getCopy(copy.coding.addEditOrDeleteThemesDirectly)}
                        </p>
                    )}
                    <div className="max-h-[50vh] overflow-auto rounded-none">
                        {themeListElement}
                    </div>
                    <div className={`flex w-full justify-center py-[8px] border-t-1 rounded-none
                        border-t-glaut-stroke-glaut    
                    `}>
                        <GlautButtonSecondary onClick={handleAddThemeFromManualEdit}>
                            <MdAddCircle className="w-[20px] h-[20px]" />
                            {modalAddItemCopy ? getCopy(modalAddItemCopy) : undefined}
                        </GlautButtonSecondary>
                    </div>
                    <div className="flex flex-row justify-between">
                        <GlautButtonSecondary onClick={handleCloseModal}>
                            {getCopy(copy.coding.close)}
                        </GlautButtonSecondary>
                        <GlautButtonPrimary
                            onClick={handleSaveManualEdit}
                            disabled={!allowSavingManualEdit || isProcessing || isSubmittingReview}
                        >
                            {!isSubmittingReview ? getCopy(copy.coding.save) : <Loading />}
                        </GlautButtonPrimary>
                    </div>
                </TabPanel>
                <TabPanel className="flex flex-col gap-[0.5rem]">
                    <p className="text-glaut-dark-grey text-[13.33px] font-medium">
                        {getCopy(copy.coding.instructGlautHowToReviewCurrentThemesOrGenerateANewProposal)}
                    </p>
                    <GlautTextAreaMultiLine
                        value={promptContent}
                        onChange={event => {
                            setPromptContent(event.target.value)
                        }}
                        placeholder={
                            getCopy(copy.coding.createANewXYZThemeThatIncludesAllCodesMentioningABCConcept) ?? ""
                        }
                        designMode="ai"
                    />
                    <div className="flex flex-row justify-between">
                        <GlautButtonSecondary onClick={handleCloseModal}>
                            {getCopy(copy.coding.close)}
                        </GlautButtonSecondary>
                        <GlautButtonPrimary
                            onClick={handleSavePrompt}
                            disabled={promptContent.length === 0 || isProcessing || isSubmittingReview}
                        >
                            {!isSubmittingReview ? getCopy(copy.coding.save) : <Loading />}
                        </GlautButtonPrimary>
                    </div>
                </TabPanel>
            </Tabs>
        </ModalLayout>
    )
}