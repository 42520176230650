export const org = {
    org: {
        createOrganization: {
            en: "Create organization"
        },
        logout: {
            en: "Logout"
        },
        searchOrg: {
            en: "Search..."
        },
        uploadLogo: {
            en: "Upload logo"
        },
        uploadTitle: {
            en: "Logo image requirements"
        },
        uploadInfo: {
            en: <ul className="list-disc pl-4">
                <li>The logo must be either a PNG or GIF format. Note: GIF images are not animated</li>
                <li>The maximum file size is 30 KB.</li>
                <li>The logo on your services pages is displayed at exactly 320 x 132 pixels; therefore, the recommended image size that you upload is exactly 320 x 132 pixels.</li>
            </ul>
        },
        uploadImage: {
            en: "Upload Image"
        },
        addOrganization: {
            en: "Add organization"
        },
        addWorkspace: {
            en: "Add workspace"
        },
        createWorkspace: {
            en: "Create workspace"
        },
        noWorkspaces: {
            en: "No workspaces yet"
        },
        inviteNewMemberTitle: {
            en: "Invite a new member into the organization"
        },
        inviteNewMemberDescription: {
            en: "These are all the members belonging to the organization. You can then control which members to invite to each workspace in the relative members section."
        },
        members: {
            en: "Organization members"
        },
        noMembersYet: {
            en: "You have no members yet"
        },
        removeMember: {
            en: "This member will be removed from the current organization"
        },
        invite: {
            en: "Invite"
        },
        inviteSent: {
            en: inviteAddress => "Invitation sent to " + inviteAddress
        },
        inviteUserError: {
            en: "There was an error inviting the user"
        },
        changeUserRoleError: {
            en: "There was an error changing the user role"
        },
        removeUserError: {
            en: "There was an error changing the user role"
        },
        cannotInvite: {
            en: "You cannot invite members"
        },
        deleteThis: {
            en: "Delete this organization"
        },
        deleteButton: {
            en: "Delete"
        },
    },
} as const
