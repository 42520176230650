import ConversationTranscriptBox from "@components/ConversationTranscriptBox"
import { useCallback, useEffect, useState } from "react"
import { IInterview } from "src/@types/entry"
import ConversationOverlayDate from "./components/ConversationOverlayDate"
import ConversationOverlayHeader from "./components/ConversationOverlayHeader"
import ConversationOverlayInterviewLength from "./components/ConversationOverlayInterviewLength"
import ConversationOverlayTranscriptHeader from "./components/ConversationOverlayTranscriptHeader"
import ConversationOverlayProvider from "./contexts/ConversationOverlayProvider"
import ConversationOverlayRelatedData from "./components/ConversationOverlayRelatedData"
import { IScreeningQuestion } from "@/@types/interview-question"

interface IConversationOverlayProps {
    interview: IInterview
    /**
     * Callback after closing the overlay (right after closing animation).
     */
    onClose?: () => void
    /**
     * Callback after the interview deletion (which already calls the API).
     */
    onDeleteInterview?: () => void
    /**
     * Defines if the entry is deletable (by showing the trash icon on top of the overlay).
     */
    deletableEntry?: boolean
    /**
     * Language for the screening question labels.
     */
    lang: string
    /**
     * Screening questions to be displayed in the related data section.
     */
    screeningQuestions?: IScreeningQuestion[]
}

export default function ConversationOverlay({
    interview,
    onClose,
    onDeleteInterview,
    lang,
    screeningQuestions,
    deletableEntry = false
}: Readonly<IConversationOverlayProps>) {
    // #region States
    const [isOpen, setIsOpen] = useState(false)
    // #endregion

    // #region Callbacks
    const handleCloseOverlay = useCallback(() => {
        setIsOpen(false)

        setTimeout(() => {
            onClose?.()
        }, 300)
    }, [onClose])

    const handleOnDeleteInterview = useCallback(() => {
        onDeleteInterview?.()
    }, [onDeleteInterview])
    // #endregion

    // #region Effects

    // onChangeInterviewOpenOverlay
    useEffect(() => {
        setIsOpen(true)
    }, [interview])

    // #endregion

    return (
        <ConversationOverlayProvider
            interview={interview}
            deletableEntry={deletableEntry}
            lang={lang}
            screeningQuestions={screeningQuestions}
        >
            <div className={`absolute right-0 top-0 bottom-0 border-l-1 transition-all duration-300 z-20 m-3 rounded-lg
                overflow-hidden w-[35%]
                bg-glaut-off-white border-l-glaut-grey shadow-[0px_0px_8px_0px_rgb(0,0,0,0.15)]
                ${isOpen ? "opacity-100 right-0" : "opacity-0 right-[-35%]"}
            `}>
                <div className="flex flex-col px-[0.75em] py-[1em] gap-[0.5em] h-full">
                    <ConversationOverlayHeader
                        onClose={handleCloseOverlay}
                        onDeleteInterview={handleOnDeleteInterview}
                    />
                    <div className="flex flex-col gap-[0.5em] overflow-auto">
                        <div className="flex px-[1.125em]">
                            <div className="flex-1">
                                <ConversationOverlayDate />
                            </div>
                            <div className="flex-1">
                                <ConversationOverlayInterviewLength />
                            </div>
                        </div>

                        <ConversationOverlayRelatedData />

                        <ConversationOverlayTranscriptHeader />

                        <div>
                            <ConversationTranscriptBox
                                projectId={interview.project_id}
                                messages={interview.messages}
                                showQuestionHeaders
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={`fixed inset-0 w-full h-full z-10
                ${isOpen ? "bg-[rgb(0,0,0,0.2)] visible" : "bg-transparent invisible"}
            `} onClick={handleCloseOverlay}>
            </div>
        </ConversationOverlayProvider>
    )
}