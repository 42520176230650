import { ISelectScreeningOption } from "@/@types/interview-question"
import GlautDropdown from "@components/inputs/GlautDropdown"
import GlautInputText from "@components/inputs/GlautInputText"
import { copy, getCopy } from "@utils/Copy"
import { RecursivePartial } from "@utils/types/recursive-partial"
import { MdRemoveCircle } from "react-icons/md"
import { screenerDropdownOptionsList, screenerDropdownOptionsMap } from "./constants/screener-dropdown-options"

interface IEditScreeningScreenerOptionProps {
    option: RecursivePartial<ISelectScreeningOption>
    lang: string
    disabled: boolean
    isRemovalAllowed?: boolean
    handleRemoveOption?: (id: string) => void
    handleChangeOptionLabel?: (e: React.ChangeEvent<HTMLInputElement>, id: string) => void
    handleChangeOptionLogic?: (logic: "qualify" | "disqualify", id: string) => void
}

export default function EditScreeningScreenerOption({
    option,
    lang,
    disabled,
    isRemovalAllowed = false,
    handleRemoveOption,
    handleChangeOptionLabel,
    handleChangeOptionLogic
}: Readonly<IEditScreeningScreenerOptionProps>) {
    return (
        <div className="flex gap-[0.25rem]">
            <div className="flex-1">
                <GlautInputText
                    value={option.label?.[lang] ?? ""}
                    onChange={e => option.id && handleChangeOptionLabel?.(e, option.id)}
                    disabled={disabled}
                    placeholder={getCopy(copy.actions.writeHereEllipsis) ?? ""}
                    noBorder
                />
            </div>
            <div className="flex gap-[0.25rem] items-center flex-1">
                <GlautDropdown
                    className="flex-1"
                    options={screenerDropdownOptionsList}
                    defaultOption={option.action ? screenerDropdownOptionsMap[option.action] : undefined}
                    onSelectOption={logicOpt => {
                        if (!option.id) return
                        handleChangeOptionLogic?.(logicOpt as "qualify" | "disqualify", option.id)
                    }}
                    disabled={disabled}
                />
                <button
                    className="border-none shadow-none p-0 m-0"
                    disabled={!isRemovalAllowed || disabled}
                    onClick={() => isRemovalAllowed && option.id && handleRemoveOption?.(option.id)}
                >
                    <MdRemoveCircle
                        className={`${!isRemovalAllowed ? "text-glaut-stroke-glaut" : "text-glaut-pink"}`}
                    />
                </button>
            </div>
        </div>
    )
}
