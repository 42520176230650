import { useRef } from "react"

const Button = ({ id, label, icon, reverse, onClick, disabled, className, acceptFile, handleFileChange }) => {
    const fileInputRef = useRef(null)

    if (acceptFile) return (<label
        className={"button " + (className || "primary")}
        style={disabled ? { cursor: "not-allowed" } : undefined}
        disabled={disabled}
    >
        {reverse && icon}
        <input
            type="file"
            onChange={handleFileChange}
            onClick={onClick}
            ref={fileInputRef}
            accept={acceptFile}
            disabled={disabled}
        />
        <span>{label}</span>
        {!reverse && icon}
    </label>)

    return (<button
        id={id}
        className={className || "primary"}
        onClick={onClick}
        disabled={disabled}
    >
        {reverse && icon}
        <span className="font-medium">{label}</span>
        {!reverse && icon}
    </button>)
}

export default Button
