import { IInterviewMessage } from "src/@types/entry"

export const transcribeConversation = (messages: IInterviewMessage[]) => {
    const rolesMapping = {
        user: "respondent",
        assistant: "moderator"
    }

    if (!messages) return ""

    return messages
        .map(message => {
            const role = rolesMapping[message.role]
            const content = message.content.replace(/<[^>]*>/g, "") // Regular expression to remove tags
            return `${role}: ${content} \n`
        })
        .join("")
}

export const isURL = (str: string) => {
    // Regular expression pattern to match a URL
    const urlPattern = /^(http(s)?:\/\/)?(www\.)?[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+([/?].*)?$/

    return urlPattern.test(str)
}

export const normalizeQuestionOrOption = (question: string) => {
    // Remove trailing spaces
    let result = question.trim()
    // Remove new line
    result = result.replace("\n", "")
    // Remove multiple whitespaces
    result = result.replace(/ +(?= )/g, "")

    return result.charAt(0).toUpperCase() + result.slice(1)
}

export const normalizeTag = (tag: string) => normalizeQuestionOrOption(tag)
