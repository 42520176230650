import React, { useEffect, useRef } from "react"
import useClickOutside from "../../../hooks/useClickOutside"
import "./index.scss"

export const FloatingMenu = ({
    anchorRef,
    className,
    onClose,
    elements,
    boundingBoxOffset = 0,
    autoClose = true,
    position = "left",
    distance = 10,
    children
}) => {
    const dropdownRef = useRef()

    // Use the hook to close the list when clickng outside
    useClickOutside(dropdownRef, autoClose ? onClose : () => { })

    let top = anchorRef?.current?.getBoundingClientRect()?.top + window.scrollY
    let left = anchorRef?.current?.getBoundingClientRect()?.right + window.scrollX + distance
    if (position === "bottom") {
        top += anchorRef?.current?.getBoundingClientRect()?.bottom + window.scrollY + distance
        left = anchorRef?.current?.getBoundingClientRect()?.left + window.scrollX
    }

    const menuStyle = {
        top: `${top}px`,
        left: `${left}px`
    }
    const clickAndClose = (e, onClick) => {
        if (onClick || onClose) {
            e.preventDefault()
            if (onClick) onClick(e)
            if (onClose) onClose(e)
        }
    }
    const handleAnchorMouseLeave = event => {
        if (!anchorRef.current) return

        const { top, bottom, left, right } = anchorRef.current.getBoundingClientRect()

        // The mouse can only leave for the right without closing the menu
        // This avoids having a code menu stuck on the page
        if (autoClose)
            if (position === "left" && event.clientX <= right && (
                event.clientY <= top - boundingBoxOffset ||
                event.clientY >= bottom + boundingBoxOffset ||
                event.clientX <= left - boundingBoxOffset)
            ) onClose(event)
            else if (position === "bottom" && event.clientY >= bottom && (
                event.clientX <= left - boundingBoxOffset ||
                event.clientX >= right + boundingBoxOffset ||
                event.clientY <= top - boundingBoxOffset)
            ) onClose(event)
    }
    useEffect(() => {
        if (anchorRef && anchorRef.current)
            anchorRef.current.addEventListener("mouseleave", handleAnchorMouseLeave)
        return () => {
            if (anchorRef && anchorRef.current)
                anchorRef.current.removeEventListener("mouseleave", handleAnchorMouseLeave)
        }
    }, [anchorRef])

    return (
        <div
            className={`floating-menu ${className}`}
            ref={dropdownRef}
            style={menuStyle}
            onMouseLeave={autoClose ? onClose : null}
        >
            {elements.filter(el => el).map((el, i) => (
                <div
                    key={i}
                    className={[
                        "floating-menu-line flex flex-row gap-2 items-center",
                        el.class,
                        (!el.onClick ? "disabled" : "")
                    ].join(" ")}
                    onClick={e => clickAndClose(e, el.onClick)}
                >
                    {el.icon} <span className={el.textClassName}>
                        {el.text}
                    </span>
                </div>
            ))}
            {children}
        </div>
    )
}
export default FloatingMenu
