import { FaTags } from "react-icons/fa"
import { getCopy, copy } from "@utils/Copy"
import GlautButtonSecondary from "@components/Buttons/GlautButtonSecondary"
import GlautButtonPrimary from "@components/Buttons/GlautButtonPrimary"
import {
    useAnalysisTabAnalysisCol,
    useAnalysisTabAnalysisColDispatch
} from "../../contexts/AnalysisTabAnalysisColProvider"
import { useCallback, useContext, useMemo, useState } from "react"
import AnalysisTabAnalysisColThemeCode from "../AnalysisTabAnalysisColThemeCode"
import { normalizeToColorIndex } from "@utils/styling/colors-from-index"
import { useAnalysisService } from "@hooks/services/useAnalysisService"
import { ProjectContext } from "../../../AutoSaveProject"
import { IProject } from "src/@types/project"
import { useAnalysisTab, useAnalysisTabDispatch } from "../../contexts/AnalysisTabProvider"
import Loading from "@components/loading/Loading"

interface IAnalysisTabAnalysisColThemeCreateSubThemeFormProps {
    /**
     * Used for coloring.
     */
    colorIndex: number
}

export default function AnalysisTabAnalysisColThemeCreateSubThemeForm({
    colorIndex
}: Readonly<IAnalysisTabAnalysisColThemeCreateSubThemeFormProps>) {
    // #region Contexts
    const { project } = useContext(ProjectContext) as { project: IProject | null }
    const { selectedAnalysis } = useAnalysisTab()
    const analysisTabDispatch = useAnalysisTabDispatch()
    const { categoryBeingUsedToCreateSubTheme } = useAnalysisTabAnalysisCol()
    const analysisTabAnalysisColDispatch = useAnalysisTabAnalysisColDispatch()
    // #endregion

    // #region Services
    const analysisService = useAnalysisService()
    // #endregion

    // #region States
    const [subThemeLabel, setSubThemeLabel] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    // #endregion

    // #region Memos
    const shouldShowPlaceholderForCodesArea = useMemo(
        () => (categoryBeingUsedToCreateSubTheme?.codes ?? []).length === 0,
        [categoryBeingUsedToCreateSubTheme]
    )
    const isCreationAllowed = useMemo(
        () => subThemeLabel.length > 0 && (categoryBeingUsedToCreateSubTheme?.codes ?? []).length >= 1,
        [subThemeLabel, categoryBeingUsedToCreateSubTheme]
    )
    // #endregion

    // #region Callbacks
    const handleCancelCreation = useCallback(() => {
        analysisTabAnalysisColDispatch({ type: "clear-category-being-used-to-create-sub-theme" })
    }, [analysisTabAnalysisColDispatch])

    const handleCreateSubtheme = useCallback(() => {
        if (!project || !selectedAnalysis || !categoryBeingUsedToCreateSubTheme) return

        const projectId = project._id
        const analysisId = selectedAnalysis.id
        const themeId = categoryBeingUsedToCreateSubTheme.themeId
        const codes = categoryBeingUsedToCreateSubTheme.codes.map(c => c.category)

        setIsLoading(true)

        analysisService.createSubTheme({ analysisId, codes, projectId, subThemeLabel, themeId })
            .then(({ analysisStats, subThemeId }) => {
                analysisTabDispatch({
                    type: "add-category-as-subtheme",
                    analysisId,
                    codeIds: codes.map(c => c.id),
                    themeId,
                    analysisStats,
                    subThemeLabel,
                    subThemeId
                })
                analysisTabAnalysisColDispatch({ type: "clear-category-being-used-to-create-sub-theme" })
            })
            .finally(() => {
                setIsLoading(false)
            })

    }, [
        analysisService,
        analysisTabAnalysisColDispatch,
        analysisTabDispatch,
        categoryBeingUsedToCreateSubTheme,
        project,
        selectedAnalysis,
        subThemeLabel
    ])
    // #endregion

    return (
        <div className="flex flex-col my-[0.75em] gap-[0.75em]">
            <div className="flex flex-row gap-[0.5em] p-[0.5em] bg-glaut-cards items-center w-full">
                <FaTags className="h-[1em] w-[1em]" style={{
                    color: `var(--color${normalizeToColorIndex(colorIndex)})`
                }} />
                <input
                    className="flex-1 border-none bg-transparent focus:shadow-none p-0"
                    placeholder={getCopy(copy.coding.createSubtheme.selectNamePlaceholder) ?? ""}
                    value={subThemeLabel}
                    onChange={e => { setSubThemeLabel(e.target.value) }}
                />
            </div>
            <div className={`flex items-center mx-[1.375em] border-1 border-dashed rounded-[0.25em] py-[0.875em]
                px-[1.5em]
                bg-glaut-cards border-glaut-light-grey
                ${shouldShowPlaceholderForCodesArea ? "justify-center" : "flex-wrap"}
            `}>
                {shouldShowPlaceholderForCodesArea && (
                    <p className="text-[11.11px] font-medium text-glaut-grey">
                        {getCopy(copy.coding.createSubtheme.selectCodes)}
                    </p>
                )}
                {!shouldShowPlaceholderForCodesArea && (
                    <>
                        {categoryBeingUsedToCreateSubTheme!.codes.map(code => (
                            <AnalysisTabAnalysisColThemeCode
                                key={code.category.id}
                                codeStats={code}
                                colorIndex={colorIndex}
                                themeId={categoryBeingUsedToCreateSubTheme!.themeId}
                                forceShowColor
                            />
                        ))}
                    </>
                )}
            </div>
            <div className="flex flex-row justify-end gap-[0.75em]">
                <GlautButtonSecondary onClick={handleCancelCreation} disabled={isLoading}>
                    {getCopy(copy.coding.cancel)}
                </GlautButtonSecondary>
                <GlautButtonPrimary
                    onClick={handleCreateSubtheme}
                    disabled={isLoading || !isCreationAllowed}
                >
                    {!isLoading ? getCopy(copy.coding.createSubtheme.createSubtheme) : (
                        <Loading />
                    )}
                </GlautButtonPrimary>
            </div>
        </div>
    )
}