import { IIntervieweeReply } from "@/@types/api/interviewer"
import { useBrowserUserAgent } from "@hooks/useBrowserUserAgent"
import { useCallback, useEffect, useRef, useState } from "react"
import { IInterviewMessage } from "src/@types/entry"
import { useConversation } from "../../contexts/ConversationProvider"
import SendIcon from "../SendIcon"

interface INumberResponseContentProps {
    setMessages: React.Dispatch<React.SetStateAction<(IInterviewMessage | IIntervieweeReply)[]>>
    isPlaying: boolean | null
    setKeyboardStatus: React.Dispatch<React.SetStateAction<"close" | "closing" | "opening" | "open">>
    currentVoiceInput: boolean
    setVoiceInput: React.Dispatch<React.SetStateAction<boolean>>
    lastMessage: IInterviewMessage
}

export default function NumberResponseContent({
    setMessages,
    isPlaying,
    setKeyboardStatus,
    currentVoiceInput,
    setVoiceInput
}: Readonly<INumberResponseContentProps>) {
    // #region Contexts
    const { sendJSON } = useConversation()
    // #endregion

    // #region Browser hooks
    const { devices: { isMobile } } = useBrowserUserAgent()
    // #endregion

    // #region States
    const [numberContent, setNumberContent] = useState<string>("")
    // #endregion

    // #region Refs
    const lastCurrentVoiceInput = useRef(currentVoiceInput)
    // #endregion

    // #region Callbacks

    // Appends a new respondent-generated message
    const addMessage = useCallback(() => {
        // We cannot add an empty message
        if (!numberContent.length) return

        const message: IIntervieweeReply = {
            content: numberContent,
            recording_id: null
        }

        sendJSON({ message })
        setMessages(ms => [...ms, message])
    }, [setMessages, sendJSON, numberContent])

    // #endregion

    // #region Effects
    useEffect(() => {
        setVoiceInput(false)

        const voiceInputToReturn = lastCurrentVoiceInput.current

        return () => {
            setVoiceInput(voiceInputToReturn)
        }
    }, [setVoiceInput])
    // #endregion

    return (
        <div className="mt-[10%] md:mt-0">
            <input
                className={`text-[64px] font-light p-0 text-center border-none focus:shadow-none
                    text-glaut-midnight bg-transparent w-full`}
                placeholder="00"
                type="number"
                value={numberContent}
                onChange={e => {
                    e.preventDefault()
                    const rawValue = e.target.value

                    const value = Number(rawValue)
                    if (isNaN(value)) return

                    setNumberContent(rawValue)
                }}
                disabled={!!isPlaying}
                onFocus={() => {
                    if (!isMobile) return
                    setKeyboardStatus("open")
                }}
                onBlur={() => {
                    if (!isMobile) return
                    setKeyboardStatus("close")
                }}
                onKeyDown={e => {
                    if (e.key === "Enter")
                        addMessage()
                }}
            />
            <div className="fixed bottom-0 right-0 p-[0.75em] z-50">
                <SendIcon
                    onClick={addMessage}
                    disabled={numberContent.length === 0}
                />
            </div>
        </div>
    )
}