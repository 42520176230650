interface IModeratorMessageProps {
    message: string
}

export default function ModeratorMessage({ message }: Readonly<IModeratorMessageProps>) {
    return (
        <div className="flex justify-start" role="log" aria-label="Moderator message">
            <p className="text-[13.33px] text-glaut-text-midnight">
                {message.replace(/<.*>/g, "")}
            </p>
        </div>
    )
}
