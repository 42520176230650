import { IScreeningQuestion } from "@/@types/interview-question"

interface IScreeningOption {
    slug: IScreeningQuestion["screening_type"]
    label: string
}

export const screeningOptions: IScreeningOption[] = [
    { slug: "screener", label: "Screener" },
    { slug: "quota", label: "Quota" }
] as const
