import { IAnalysisType } from "@/@types/analysis"
import { questionEmojiFromType } from "@utils/analysis/labels"

interface IAnalysisTabAnalysisColFilteringModalAnalysisSelectLabelProps {
    index: number
    label: string
    type: IAnalysisType
}

export default function AnalysisTabAnalysisColFilteringModalAnalysisSelectLabel({
    index,
    label,
    type
}: Readonly<IAnalysisTabAnalysisColFilteringModalAnalysisSelectLabelProps>) {
    return (
        <div className="flex flex-row items-center gap-[0.25em] cursor-pointer">
            <p className="text-[11.11px] font-medium text-glaut-grey">
                {`A${index + 1}`}
            </p>
            <p className="text-[11.11px] font-medium text-glaut-grey">
                {questionEmojiFromType(type)}
            </p>
            <p className={`text-[13.33px] font-medium text-ellipsis whitespace-nowrap overflow-hidden
                text-glaut-text-midnight
            `}>
                {label}
            </p>
        </div>
    )
}