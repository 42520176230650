import { DetailedHTMLProps, SVGAttributes } from "react"

interface IGlautBottomRiskProps
    extends DetailedHTMLProps<SVGAttributes<SVGSVGElement>, SVGSVGElement> {

}

export default function GlautBottomRisk({
    ...props
}: Readonly<IGlautBottomRiskProps>) {
    return (
        <svg width="120" height="10" viewBox="0 0 120 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} >
            <g clipPath="url(#clip0_17913_343401)">
                <path d="M1.63086 5.71321C1.63086 5.71321 67.9832 -6.40661 118.369 8.84685"
                    stroke="#FF6392" strokeWidth="2.67821" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_17913_343401">
                    <rect width="119" height="10" fill="white" transform="translate(0.5)" />
                </clipPath>
            </defs>
        </svg>
    )
}