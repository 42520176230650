import { useMemo } from "react"

interface IAnalysisTabQuestionsColQuestionItemLabelProps {
    state: "not-selected" | "selected" | "disabled" | "processing"
    label: string
}

export default function AnalysisTabQuestionsColQuestionItemLabel({
    label,
    state
}: Readonly<IAnalysisTabQuestionsColQuestionItemLabelProps>) {
    // #region Memos
    const shouldShowHoverStyling = useMemo(() => state === "not-selected", [state])
    // #endregion

    return (
        <div className={`question-text font-medium text-[13.33px] line-clamp-3
            ${shouldShowHoverStyling ? "group-hover/question:text-glaut-text-midnight" : ""}
            ${state === "not-selected" ? "text-glaut-dark-grey" : ""}
            ${state === "selected" ? "text-glaut-midnight" : ""}
            ${state === "disabled" ? "text-glaut-stroke-glaut" : ""}
            ${state === "processing" ? "text-glaut-stroke-glaut" : ""}
        `}>
            {label}
        </div>
    )
}