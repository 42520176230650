import { createContext, useContext, useReducer } from "react"

interface IAnalysisTabCodeOccurrencesProviderProps {
    children: React.ReactNode
    shouldShowCodeOccurrencesAsNumber?: boolean
}

interface IAnalysisTabCodeOccurrencesContextState {
    /**
     * Code's tooltip appearance state.
     */
    shouldShowTooltipOnCodeHover: boolean
    /**
     * Defines if the code occurrences will be shown as number (if not, it'll be as percentage)
     */
    shouldShowCodeOccurrencesAsNumber: boolean
}

type IAnalysisTabCodeOccurrencesContextAction = {
    type: "mark-code-tooltip-as-viewed"
} | {
    type: "change-global-code-occurrences-mode"
    shouldShowCodeOccurrencesAsNumber: boolean
}
// #endregion

// #region Context definitions
const AnalysisTabCodeOccurrencesContext = createContext({} as IAnalysisTabCodeOccurrencesContextState)
const AnalysisTabCodeOccurrencesContextDispatch = createContext(
    {} as React.Dispatch<IAnalysisTabCodeOccurrencesContextAction>
)
// #endregion

// #region Hook definitions
export function useAnalysisTabCodeOccurrences() {
    return useContext(AnalysisTabCodeOccurrencesContext)
}

export function useAnalysisTabCodeOccurrencesDispatch() {
    return useContext(AnalysisTabCodeOccurrencesContextDispatch)
}
// #endregion

// #region Provider definition
export default function AnalysisTabCodeOccurrencesProvider({
    children,
    shouldShowCodeOccurrencesAsNumber
}: Readonly<IAnalysisTabCodeOccurrencesProviderProps>) {
    const initialState: IAnalysisTabCodeOccurrencesContextState = {
        shouldShowTooltipOnCodeHover: true,
        shouldShowCodeOccurrencesAsNumber: shouldShowCodeOccurrencesAsNumber ?? true
    }

    const [state, dispatch] = useReducer(AnalysisTabCodeOccurrencesReducer, initialState)

    return (
        <AnalysisTabCodeOccurrencesContext.Provider value={state}>
            <AnalysisTabCodeOccurrencesContextDispatch.Provider value={dispatch}>
                {children}
            </AnalysisTabCodeOccurrencesContextDispatch.Provider>
        </AnalysisTabCodeOccurrencesContext.Provider>
    )
}
// #endregion

// #region Reducer definition
function AnalysisTabCodeOccurrencesReducer(
    state: IAnalysisTabCodeOccurrencesContextState,
    action: IAnalysisTabCodeOccurrencesContextAction
): IAnalysisTabCodeOccurrencesContextState {
    switch (action.type) {
        case "mark-code-tooltip-as-viewed": {
            return {
                ...state,
                shouldShowTooltipOnCodeHover: false
            }
        }
        case "change-global-code-occurrences-mode": {
            return {
                ...state,
                shouldShowCodeOccurrencesAsNumber: action.shouldShowCodeOccurrencesAsNumber
            }
        }
        default: {
            return state
        }
    }
}
// #endregion