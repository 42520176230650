import { IProjectResults, ISegmentedProjectResults } from "@/@types/project"
import { BaseApiService, IAnalysesStatsResponse } from "@services/@shared/api/BaseApiService"
import { AxiosError, AxiosInstance } from "axios"
import { IAnalysis, IInterpretativeAnalysis } from "src/@types/analysis"
import { ICategoryStats } from "src/@types/processing/statistics"
import IAnalysisGateway, {
    IAnalysisGatewayCancelAnalysisExecutionProgressRequest,
    IAnalysisGatewayCancelAnalysisExecutionProgressResponse,
    IAnalysisGatewayCreateAnalysisCategoryRequest,
    IAnalysisGatewayCreateAnalysisCategoryResponse,
    IAnalysisGatewayCreateAnalysisRequest,
    IAnalysisGatewayCreateAnalysisResponse,
    IAnalysisGatewayCreateSubThemeRequest,
    IAnalysisGatewayCreateSubThemeResponse,
    IAnalysisGatewayDeleteAnalysisCategoryRequest,
    IAnalysisGatewayDeleteAnalysisCategoryResponse,
    IAnalysisGatewayDeleteAnalysisRequest,
    IAnalysisGatewayDeleteAnalysisResponse,
    IAnalysisGatewayExecuteCodebookReviewRequest,
    IAnalysisGatewayExecuteCodebookReviewResponse,
    IAnalysisGatewayGetAnalysesRequest,
    IAnalysisGatewayGetAnalysesResponse,
    IAnalysisGatewayGetAnalysesStatsRequest,
    IAnalysisGatewayGetAnalysesStatsResponse,
    IAnalysisGatewayGetAnalysisExecutionProgressRequest,
    IAnalysisGatewayGetAnalysisExecutionProgressResponse,
    IAnalysisGatewayGetAnalysisRequest,
    IAnalysisGatewayGetAnalysisResponse,
    IAnalysisGatewayGetCodebookReviewExecutionProgressRequest,
    IAnalysisGatewayGetCodebookReviewExecutionProgressResponse,
    IAnalysisGatewayMergeCodesRequest,
    IAnalysisGatewayMergeCodesResponse,
    IAnalysisGatewayPromoteCategoryToThemeRequest,
    IAnalysisGatewayPromoteCategoryToThemeResponse,
    IAnalysisGatewaySetParentOfCategoryRequest,
    IAnalysisGatewaySetParentOfCategoryResponse,
    IAnalysisGatewayUpdateAnalysisCategoryRequest,
    IAnalysisGatewayUpdateAnalysisCategoryResponse,
    IAnalysisGatewayUpdateAnalysisRequest,
    IAnalysisGatewayUpdateAnalysisResponse
} from "../IAnalysisGateway"

interface IAnalysisApiServiceProps {
    api: AxiosInstance
}

export default class AnalysisApiService extends BaseApiService implements IAnalysisGateway {
    private readonly props: IAnalysisApiServiceProps
    private readonly promises: { [methodName: string]: Promise<unknown> }

    constructor(props: IAnalysisApiServiceProps) {
        super()

        this.props = props
        this.promises = {}
    }

    public async getAnalyses(
        request: IAnalysisGatewayGetAnalysesRequest
    ): Promise<IAnalysisGatewayGetAnalysesResponse> {
        const { projectId } = request

        return await this.props.api
            .get<IAnalysis[]>(`projects/${projectId}/analyses`)
            .then(response => ({ analyses: response.data }))
    }

    public async getAnalysis(
        request: IAnalysisGatewayGetAnalysisRequest
    ): Promise<IAnalysisGatewayGetAnalysisResponse> {
        const { analysisId, projectId } = request

        return await this.props.api
            .get(`projects/${projectId}/analyses/${analysisId}`)
            .then(response => response.data)
    }

    public async getAnalysesStats(
        request: IAnalysisGatewayGetAnalysesStatsRequest
    ): Promise<IAnalysisGatewayGetAnalysesStatsResponse> {
        const ongoingRequest = this.getOngoingRequest<IAnalysisGatewayGetAnalysesStatsResponse>("getAnalysesStats")
        if (ongoingRequest) return ongoingRequest

        type IAnalysesStatsSegmentationResponse = {
            segment: ICategoryStats
            project_results: IAnalysesStatsResponse
        }

        const { projectId, analysisFilters, questionFilters, segmentByAnalysisId, segmentByQuestionId } = request

        const promise = this.props.api
            .get<IAnalysesStatsResponse | IAnalysesStatsSegmentationResponse[]>(`projects/${projectId}/results`, {
                params: {
                    analysis_filters: analysisFilters?.length ? JSON.stringify(analysisFilters) : undefined,
                    question_filters: questionFilters?.length ? JSON.stringify(questionFilters) : undefined,
                    segment_by_question_id: segmentByQuestionId,
                    segment_by_analysis_id: segmentByAnalysisId
                }
            })
            .then(res => {
                if (segmentByAnalysisId || segmentByQuestionId) {
                    const newSegmentedProjectResults: ISegmentedProjectResults[] =
                        (res.data as IAnalysesStatsSegmentationResponse[])
                            .map(segmentedProjectResults => ({
                                segment: segmentedProjectResults.segment,
                                project_results: {
                                    interview_count: segmentedProjectResults.project_results.interview_count,
                                    analysis_results: this.parseNoAnswerCategoryForAnalysisResults(
                                        segmentedProjectResults.project_results.analysis_results
                                    ),
                                    question_results: this.parseNoAnswerCategoryForQuestionResults(
                                        segmentedProjectResults.project_results.question_results
                                    )
                                }
                            }))

                    return newSegmentedProjectResults
                }

                const interviewCount = (res.data as IAnalysesStatsResponse).interview_count
                const newAnalysisResults = this.parseNoAnswerCategoryForAnalysisResults(
                    (res.data as IAnalysesStatsResponse).analysis_results
                )
                const newQuestionsResults = this.parseNoAnswerCategoryForQuestionResults(
                    (res.data as IAnalysesStatsResponse).question_results
                )

                return {
                    interview_count: interviewCount,
                    question_results: newQuestionsResults,
                    analysis_results: newAnalysisResults
                } as IProjectResults
            })
            .catch((err: AxiosError) => {
                if (err.response?.status === 404) {
                    if (segmentByAnalysisId || segmentByQuestionId)
                        return []

                    return {
                        interview_count: 0,
                        question_results: {},
                        analysis_results: {}
                    } as IProjectResults
                }

                throw err
            })
            .finally(() => {
                delete this.promises.getAnalysesStats
            })

        this.promises.getAnalysesStats = promise
        return await promise
    }

    public async getAnalysisExecutionProgress(
        request: IAnalysisGatewayGetAnalysisExecutionProgressRequest
    ): Promise<IAnalysisGatewayGetAnalysisExecutionProgressResponse> {
        const { analysisId, projectId } = request

        return await this.props.api
            .get<IAnalysis>(`projects/${projectId}/analyses/${analysisId}`)
            .then(res => {
                const { completion, status } = res.data

                if (completion !== null)
                    return { completion: completion * 100 }

                if (status === "completed")
                    return { completion: 100 }

                if (status === "building")
                    return { completion: 0 }

                return { completion: 100 }
            })
    }

    public async cancelAnalysisExecution(
        request: IAnalysisGatewayCancelAnalysisExecutionProgressRequest
    ): Promise<IAnalysisGatewayCancelAnalysisExecutionProgressResponse> {
        const { analysisId, projectId } = request

        return await this.deleteAnalysis({ analysisId, projectId })
            .then(() => ({ isSuccess: true }))
            .catch(() => ({ isSuccess: false }))
    }

    public async createAnalysis(
        request: IAnalysisGatewayCreateAnalysisRequest
    ): Promise<IAnalysisGatewayCreateAnalysisResponse> {
        const { analysis, projectId } = request

        return await this.props.api
            .post<IAnalysis>(`projects/${projectId}/analyses`, {
                type: analysis.type,
                sources: analysis.sources,
                title: !analysis.title?.length ? null : analysis.title,
                goal: analysis.goal,
                framework_description: analysis.type === "interpretation"
                    ? (analysis as Omit<IInterpretativeAnalysis, "id">).framework_description
                    : undefined,
                categories: analysis.categories?.map(c => ({
                    label: c.label,
                    description: c.description,
                    children: c.children
                })),
                fixed_codebook: analysis.type === "thematic" && !!analysis.categories?.length
                    ? true
                    : undefined
            })
            .then(response => ({ ...response.data, completion: 0 }))
    }

    public async createAnalysisCategory(
        request: IAnalysisGatewayCreateAnalysisCategoryRequest
    ): Promise<IAnalysisGatewayCreateAnalysisCategoryResponse> {
        const { analysisId, category, projectId } = request

        return await this.props.api
            .post(`projects/${projectId}/analyses/${analysisId}/categories`, {
                parent: category.parent,
                label: category.label,
                description: !category.description?.length ? null : category.description
            })
            .then(response => response.data)
    }

    public async updateAnalysis(
        request: IAnalysisGatewayUpdateAnalysisRequest
    ): Promise<IAnalysisGatewayUpdateAnalysisResponse> {
        const { analysis, projectId } = request

        let categories: IAnalysisGatewayUpdateAnalysisRequest["analysis"]["categories"]
        const isThematicAndShouldParseCategories = analysis.type === "thematic" && analysis.fixed_codebook === false
        const isInterpretationAndShouldParseCategories = analysis.type === "interpretation"

        if (isThematicAndShouldParseCategories || isInterpretationAndShouldParseCategories)
            categories = analysis.categories?.map(c => ({
                ...c,
                description: !c.description?.length ? null : c.description
            }))

        return await this.props.api
            .patch(`projects/${projectId}/analyses/${analysis.id}`, {
                goal: analysis.goal,
                sources: analysis.sources,
                title: !analysis.title?.length ? null : analysis.title,
                categories,
                fixed_codebook: analysis.type === "thematic" ? analysis.fixed_codebook : undefined
            }).then(response => response.data)
    }

    public async updateAnalysisCategory(
        request: IAnalysisGatewayUpdateAnalysisCategoryRequest
    ): Promise<IAnalysisGatewayUpdateAnalysisCategoryResponse> {
        const { analysisId, category, projectId, categoryId } = request

        return await this.props.api
            .patch(`projects/${projectId}/analyses/${analysisId}/categories/${categoryId}`, {
                parent: category.parent,
                label: category.label,
                description: !category.description?.length ? null : category.description
            })
            .then(response => response.data)
    }

    public async deleteAnalysis(
        request: IAnalysisGatewayDeleteAnalysisRequest
    ): Promise<IAnalysisGatewayDeleteAnalysisResponse> {
        const { analysisId, projectId } = request

        return await this.props.api
            .delete(`projects/${projectId}/analyses/${analysisId}`)
            .then(response => response.data)
    }

    public async deleteAnalysisCategory(
        request: IAnalysisGatewayDeleteAnalysisCategoryRequest
    ): Promise<IAnalysisGatewayDeleteAnalysisCategoryResponse> {
        const { analysisId, projectId, categoryId } = request

        return await this.props.api
            .delete(`projects/${projectId}/analyses/${analysisId}/categories/${categoryId}`)
            .then(() => this.getAnalysesStats({ projectId }).then(res => ({
                analysisStats: (res as IProjectResults).analysis_results[analysisId]
                    ?? { stats: [], interview_count: 0 }
            })))
    }

    public async setParentOfCategory(
        request: IAnalysisGatewaySetParentOfCategoryRequest
    ): Promise<IAnalysisGatewaySetParentOfCategoryResponse> {
        const { analysisId, category, newParentCategoryId, projectId } = request

        return await this.updateAnalysisCategory({
            projectId,
            analysisId,
            categoryId: category.id,
            category: { ...category, parent: newParentCategoryId }
        }).then(() => this.getAnalysesStats({ projectId }).then(res => ({
            analysisStats: (res as IProjectResults).analysis_results[analysisId]
                ?? { stats: [], interview_count: 0 }
        })))
    }

    public async promoteCategoryToTheme(
        request: IAnalysisGatewayPromoteCategoryToThemeRequest
    ): Promise<IAnalysisGatewayPromoteCategoryToThemeResponse> {
        const { analysisId, category, projectId } = request

        return await this.updateAnalysisCategory({
            categoryId: category.id,
            analysisId,
            category: {
                ...category,
                parent: null
            },
            projectId
        }).then(() => this.getAnalysesStats({ projectId }).then(res => ({
            analysisStats: (res as IProjectResults).analysis_results[analysisId]
                ?? { stats: [], interview_count: 0 }
        })))
    }

    public async createSubTheme(
        request: IAnalysisGatewayCreateSubThemeRequest
    ): Promise<IAnalysisGatewayCreateSubThemeResponse> {
        const { analysisId, codes, projectId, subThemeLabel, themeId } = request

        const subThemeCategory = await this.createAnalysisCategory({
            projectId, analysisId, category: {
                label: subThemeLabel,
                parent: themeId,
                description: null
            }
        })

        if (!subThemeCategory?.id) throw new Error("Subtheme not created.")

        const promises = codes.map(async code => this.updateAnalysisCategory({
            analysisId,
            projectId,
            categoryId: code.id,
            category: {
                ...code,
                parent: subThemeCategory.id
            }
        }))

        await Promise.all(promises)

        return this.getAnalysesStats({ projectId }).then(res => ({
            subThemeId: subThemeCategory.id,
            analysisStats: (res as IProjectResults).analysis_results[analysisId]
                ?? { stats: [], interview_count: 0 }
        }))
    }

    public async mergeCodes(
        request: IAnalysisGatewayMergeCodesRequest
    ): Promise<IAnalysisGatewayMergeCodesResponse> {
        const { analysisId, projectId, sourceCodeId, targetCodeId } = request

        return await this.props.api
            .delete(`projects/${projectId}/analyses/${analysisId}/categories/${sourceCodeId}`, {
                params: { merge_into: targetCodeId ?? "" }
            })
            .then(() => this.getAnalysesStats({ projectId }).then(res => ({
                analysisStats: (res as IProjectResults).analysis_results[analysisId]
                    ?? { stats: [], interview_count: 0 }
            })))
    }

    public async executeCodebookReview(
        request: IAnalysisGatewayExecuteCodebookReviewRequest
    ): Promise<IAnalysisGatewayExecuteCodebookReviewResponse> {
        const { analysisId, projectId, type } = request

        if (type === "prompt") {
            const { promptContent } = request
            return await this.props.api
                .patch(`projects/${projectId}/analyses/${analysisId}/build-themes`, {
                    context: promptContent
                })
                .then(res => res.data)
        }

        const { themes } = request

        return await this.props.api
            .patch(`projects/${projectId}/analyses/${analysisId}/new-themes`,
                themes.map(t => ({
                    label: t.label,
                    description: !t.description?.length ? null : t.description
                }))
            ).then(res => res.data)
    }

    public async getCodebookReviewExecutionProgress(
        request: IAnalysisGatewayGetCodebookReviewExecutionProgressRequest
    ): Promise<IAnalysisGatewayGetCodebookReviewExecutionProgressResponse> {
        const { analysisId, projectId } = request

        return this.getAnalysis({ analysisId, projectId }).then(res => ({
            completion: (res.completion ?? 1) * 100
        }))
    }

    // #region Private fields
    private getOngoingRequest<T = unknown>(methodName: string): Promise<T> | undefined {
        return this.promises[methodName] as Promise<T>
    }
    // #endregion
}