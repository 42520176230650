export const dataTabCsvContentImportDataCopy = {
    importData: {
        en: "Import data"
    },
    columnSelection: {
        en: "Column selection"
    },
    selectTheColumnsYouWantToImportFromYourFile: {
        en: "Select the columns you want to import from your file. Use the " +
            "preview window to verify the data and rename columns if needed. " +
            "These names will appear in your analysis."
    },
    numberOfResponses: {
        en: "# of responses"
    }
}
