import EditableTitle from "@components/inputs/EditableTitle"
import { useProjectService } from "@hooks/services/useProjectService"
import { copy, getCopy } from "@utils/Copy"
import { getProjectLang } from "@utils/language"
import { deepCopy } from "@utils/miscellaneous"
import { useCallback, useEffect, useMemo, useState } from "react"
import { MdClose } from "react-icons/md"
import { useParams } from "react-router-dom"
import { IInterviewQuestion } from "src/@types/interview-question"
import { useLegacyProject, useProject, useProjectDispatch } from "../../../../contexts/ProjectProvider"
import { dataTabCsvContentImportDataCopy } from "./utils/copy"
import { useDataTabCsvContentDispatch } from "../../contexts/DataTabCsvContentProvider"
import { useInterviewService } from "@hooks/services/useInterviewService"

const settingsCopy = copy.coding.settings

export default function DataTabCsvContentImportData() {
    // #region Params
    const { projectId } = useParams()
    // #endregion

    // #region Contexts
    const { interviewQuestions } = useProject()
    const projectDispatch = useProjectDispatch()
    const { project } = useLegacyProject()
    const dataTabCsvContentDispatch = useDataTabCsvContentDispatch()
    // #endregion

    // #region Services
    const projectService = useProjectService()
    const interviewService = useInterviewService()
    // #endregion

    // #region States
    const [updateColumnTimeout, setUpdateColumnTimeout] = useState<NodeJS.Timeout>()
    const [currInterviewQuestions, setCurrInterviewQuestions] = useState<IInterviewQuestion[]>([])
    const [iqsFrequency, setIqsFrequency] = useState<Record<string, number>>()
    // #endregion

    // #region Memos

    const lang = useMemo(() => getProjectLang(project), [project])

    // Set questionIds from schema
    const questionIds = useMemo(
        () => interviewQuestions?.map(q => q.id) ?? [],
        [interviewQuestions]
    )
    // Set headers from file or from schema
    const headers = useMemo(
        () => currInterviewQuestions.map(q => q.content[lang] ?? q.header ?? ""),
        [currInterviewQuestions, lang]
    )

    // #endregion

    // #region Callbacks

    const reloadInterviewQuestions = useCallback(() => {
        if (!projectId) return

        projectService.getInterviewQuestions({ projectId })
            .then(({ interviewQuestions }) => {
                projectDispatch({ type: "set-interview-questions", interviewQuestions })
            })
    }, [projectDispatch, projectId, projectService])

    const handleSetHeaders = useCallback((index: number, value: string) => {
        if (!project || !interviewQuestions) return

        const newIqs = deepCopy(currInterviewQuestions)
        const iq = newIqs[index]
        if (!iq) return

        // Change in pointer
        iq.content[lang] = value
        setCurrInterviewQuestions(newIqs)

        if (updateColumnTimeout) clearTimeout(updateColumnTimeout)
        setUpdateColumnTimeout(setTimeout(() => {
            // Update project question
            projectService.updateInterviewQuestion({
                projectId: project._id,
                questionId: questionIds[index],
                data: { ...iq, content: { ...iq.content, [lang]: value }, header: iq.header ?? undefined }
            }).then(() => {
                reloadInterviewQuestions()
            })
        }, 1000))
    }, [
        currInterviewQuestions,
        interviewQuestions,
        lang,
        project,
        projectService,
        questionIds,
        reloadInterviewQuestions,
        updateColumnTimeout
    ])

    const handleDeleteColumn = useCallback((index: number) => {
        if (!project) return

        // Delete column (question) from project
        projectService.deleteInterviewQuestion({ projectId: project._id, questionId: questionIds[index - 1] })
            .then(() => {
                reloadInterviewQuestions()
            })
    }, [project, projectService, questionIds, reloadInterviewQuestions])

    // #endregion

    // #region Effects

    // onUpdateIqs
    useEffect(() => {
        if (!interviewQuestions) return
        setCurrInterviewQuestions(interviewQuestions)
    }, [interviewQuestions])

    // onLoadFetchFrequencyPerQuestion
    useEffect(() => {
        if (!projectId) return

        interviewService.getFrequencyPerQuestion({ projectId })
            .then(response => { setIqsFrequency(response) })
    }, [interviewService, projectId])

    // #endregion

    if (project?.source !== "CSV") return <></>

    return (
        <div className="flex flex-col gap-[1em] w-full max-w-[40%] px-[1.25rem] overflow-hidden">
            <p className="text-[16px] text-glaut-bar">
                {getCopy(dataTabCsvContentImportDataCopy.importData)?.toUpperCase()}
            </p>
            <div className="flex flex-col gap-[0.5em]">
                <p className="text-[13.33px] font-medium text-glaut-text-midnight">
                    {getCopy(dataTabCsvContentImportDataCopy.columnSelection)}
                </p>
                <p className="text-[13.33px] text-glaut-dark-grey">
                    {getCopy(dataTabCsvContentImportDataCopy.selectTheColumnsYouWantToImportFromYourFile)}
                </p>
            </div>
            <div className="flex flex-col gap-[0.5rem] flex-1 overflow-auto pb-4">
                {headers.map((s, i) => (
                    <div key={i} className="flex justify-between items-center gap-[0.5rem]">
                        <div className="flex flex-col flex-1 min-w-0">
                            <EditableTitle
                                value={s || ""}
                                onChange={value => handleSetHeaders(i, value)}
                                placeholder={getCopy(settingsCopy.csvSettings.questionPlaceholder)?.(i + 1) ?? ""}
                                fontSize="13.33px"
                                onInputFocus={
                                    () => dataTabCsvContentDispatch({ type: "set-selected-column-index", index: i })
                                }
                                onInputBlur={
                                    () => dataTabCsvContentDispatch({ type: "set-selected-column-index", index: null })
                                }
                            />
                            <p className="text-[11.11px] text-glaut-codes-red">
                                {getCopy(dataTabCsvContentImportDataCopy.numberOfResponses)}:{" "}
                                {iqsFrequency?.[questionIds[i]] ?? 0}
                            </p>
                        </div>
                        <button
                            className="p-0 m-0 border-none shadow-none text-base"
                            onClick={() => handleDeleteColumn(i + 1)}
                            disabled={headers.length === 1}
                        >
                            <MdClose className="h-[1em] w-[1em] text-glaut-dark-grey" />
                        </button>
                    </div>
                ))}
            </div>
        </div>
    )
}
