export const interviewQuestions = {
    interviewQuestions: {
        type: {
            open: {
                title: {
                    en: "Open"
                },
                description: {
                    en: "Respondent can answer openly, either by voice or text"
                }
            },
            select: {
                title: {
                    en: "Select"
                },
                description: {
                    en: "Respondent has to select one or more options (multiple-choice)"
                }
            },
            rate: {
                title: {
                    en: "Rate"
                },
                description: {
                    en: "Respondent has to provide a rating"
                }
            },
            number: {
                title: {
                    en: "Number"
                },
                description: {
                    en: "Respondent must answer with a number on a set scale"
                }
            },
            asset: {
                title: {
                    en: "Asset"
                },
                description: {
                    en: "Respondent has to upload an image or video"
                }
            }
        }
    }
}