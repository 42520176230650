import Loading from "@components/loading/Loading"
import { useMemo } from "react"
import OutlineTabProvider from "../../contexts/OutlineTabProvider"
import { useLegacyProject } from "../../contexts/ProjectProvider"
import OutlineTabContent from "../OutlineTabContent"
import OutlineTabSidebar from "../OutlineTabSidebar"

export default function OutlineTab() {
    // #region Contexts
    const { project } = useLegacyProject()
    // #endregion

    // #region Memos
    const isLegacyReviews = useMemo(
        () => project !== null && "sources" in project && Array.isArray(project.sources)
            && project?.sources?.length > 1,
        [project]
    )
    // #endregion

    // For retro compatibility
    if (isLegacyReviews) return <></>

    if (!project) return <Loading />

    return (
        <OutlineTabProvider project={project}>
            <div className="flex h-full overflow-hidden p-[0.625em] bg-glaut-off-white">
                <OutlineTabSidebar />
                <div className="mx-[3.75em] mt-[3em] w-full">
                    <OutlineTabContent />
                </div>
            </div>
        </OutlineTabProvider>
    )
}
