import { colors, questionTypes } from "./Variables"
import mime from "mime"
import * as Sentry from "@sentry/react"

export const stringToColor = (str: string) => {
    if (!str) return

    let hash = 0
    for (let i = 0; i < str.length; i++)
        hash = (hash + str.charCodeAt(i)) % colors.length

    return colors[hash] ?? colors[0]
}

/**
 * Downloads a file from a string or blob.
 */
export const download = (content: string | Blob, fileName: string, contentType?: string) => {
    const a = document.createElement("a")
    const file = content instanceof Blob ? content : new Blob([content], { type: contentType })
    a.href = URL.createObjectURL(file)
    a.download = fileName
    a.click()
}

/**
 * Tries to download a file from a URL.
 * 
 * If the download fails, it will try to download the provided fallback blob.
 */
export function downloadFromUrl(url: string, name: string, fallbackBlob?: Blob) {
    fetch(url)
        .then(response => {
            response.blob().then(blob => {
                const contentType = response.headers.get("Content-Type") ?? ""
                const extension = mime.getExtension(contentType)
                const fileName = extension?.length ? `${name}.${extension}` : name

                const link = document.createElement("a")
                link.href = URL.createObjectURL(blob)
                link.download = fileName
                link.click()
            })
        })
        .catch(err => {
            Sentry.captureException(err, scope => {
                scope.setContext("data", {
                    action: "download from URL",
                    downloadUrl: url,
                    fileName: name,
                    hasFallbackBlob: fallbackBlob !== undefined
                })

                return scope
            })

            if (!fallbackBlob) return

            const extension = mime.getExtension(fallbackBlob?.type ?? "")
            const fileName = extension?.length ? `${name}.${extension}` : name
            download(fallbackBlob, fileName, fallbackBlob?.type)
        })
        .catch(err => {
            Sentry.captureException(err, scope => {
                scope.setContext("data", {
                    action: "download from blob",
                    fileName: name
                })

                return scope
            })
        })
}

export const getOptions = question => {
    switch (question.type) {
        case questionTypes.multipleChoice:
            return question.options || []

        case questionTypes.select:
            return question.options || []

        case questionTypes.insight:
            return question.buckets || []

        case questionTypes.entity:
            return question.entities || []
    }
}

export function deepCopy<T>(obj: T): T {
    return JSON.parse(JSON.stringify(obj))
}