import GlautStar from "@components/Icons/GlautStar"
import { getCopy } from "@utils/Copy"
import { interviewTerminatedUncooperativeCopy } from "./utils/copy"

interface IInterviewTerminatedUncooperativeProps {
    lang: string
}

export default function InterviewTerminatedUncooperative({ lang }: Readonly<IInterviewTerminatedUncooperativeProps>) {
    return (
        <div className="h-dvh w-dvw flex flex-col items-start justify-center gap-[1.5em] mx-[4em] max-w-[300px] 
            pb-10">
            <GlautStar className="h-[2rem] w-[2rem]" />
            {getCopy(interviewTerminatedUncooperativeCopy.message, lang)}
            <p className="text-[16px] text-glaut-text-midnight">
                {getCopy(interviewTerminatedUncooperativeCopy.detail, lang)}
            </p>
        </div>
    )
}