import GlautButtonPrimary from "@components/Buttons/GlautButtonPrimary"
import GlautButtonSecondary from "@components/Buttons/GlautButtonSecondary"
import { conversationOverlayCopy } from "@components/ConversationOverlay/utils/copy"
import ModalLayout from "@components/layouts/ModalLayout"
import { getCopy, copy } from "@utils/Copy"

interface IConversationOverlayDeleteInterviewModalProps {
    isOpen: boolean
    onClose?: () => void
    onConfirm?: () => void
}

export default function ConversationOverlayDeleteInterviewModal({
    isOpen,
    onClose,
    onConfirm
}: Readonly<IConversationOverlayDeleteInterviewModalProps>) {
    return (
        <ModalLayout
            isOpen={isOpen}
            onClose={onClose}
            className={`
                flex flex-col rounded-[0.25em] shadow px-[1em] py-[1.125em] gap-3 transition-all w-60 border-[1px]
                bg-white border-glaut-grey
                ${isOpen ? "scale-100 opacity-100" : "scale-125 opacity-0"}
            `}
        >
            <div className="flex justify-center items-center text-center">
                <p className="text-[13.33px] font-medium text-glaut-text-midnight">
                    {getCopy(conversationOverlayCopy.areYouSureYouWantToDeleteThisEntry)}
                </p>
            </div>
            <div className="flex flex-row justify-between">
                <GlautButtonSecondary onClick={onClose}>
                    {getCopy(copy.actions.cancel)}
                </GlautButtonSecondary>
                <GlautButtonPrimary onClick={() => {
                    onConfirm?.()
                    onClose?.()
                }}>
                    {getCopy(copy.actions.delete)}
                </GlautButtonPrimary>
            </div>
        </ModalLayout>
    )
}