import { copy, getCopy } from "@utils/Copy"
import { useCallback } from "react"
import {
    MdClose,
    MdMergeType
} from "react-icons/md"
import {
    useAnalysisTabAnalysisCol,
    useAnalysisTabAnalysisColDispatch
} from "../../contexts/AnalysisTabAnalysisColProvider"
import AnalysisTabAnalysisColMergingBoxModalConfirmation from "../AnalysisTabAnalysisColMergingBoxModalConfirmation"

export default function AnalysisTabAnalysisColMergingBox() {
    // #region Contexts
    const { categoryBeingMerged, targetCategoryBeingMerged } = useAnalysisTabAnalysisCol()
    const analysisTabAnalysisColDispatch = useAnalysisTabAnalysisColDispatch()
    // #endregion

    // #region Callbacks
    const handleResetMerging = useCallback(() => {
        analysisTabAnalysisColDispatch({ type: "clear-category-being-merged" })
    }, [analysisTabAnalysisColDispatch])
    // #endregion

    if (!categoryBeingMerged) return <></>

    return (
        <>
            {!targetCategoryBeingMerged && (
                <div className={`flex flex-col p-[0.75em] border-1 rounded-[0.25em] my-[0.5em] 
                    bg-glaut-cards border-glaut-dummie-color
                `}>
                    <div className="flex flex-row justify-between items-center">
                        <div className="flex flex-row gap-[0.5em] items-center">
                            <MdMergeType
                                className="h-[1em] w-[1em] text-glaut-text-midnight"
                            />
                            <p className="text-[11.11px] font-medium text-glaut-text-midnight">
                                {getCopy(
                                    categoryBeingMerged.category.id === categoryBeingMerged.themeId
                                        ? copy.coding.merge.mergeThemeWithTitle
                                        : copy.coding.merge.mergeCodeWithTitle
                                )}
                            </p>
                            <p className="text-[11.11px] text-glaut-text-midnight">
                                {getCopy(
                                    categoryBeingMerged.category.id === categoryBeingMerged.themeId
                                        ? copy.coding.merge.mergeThemeWithDescription
                                        : copy.coding.merge.mergeCodeWithDescription
                                )}
                            </p>
                        </div>
                        <MdClose
                            className="h-[1em] w-[1em] text-glaut-stroke-button cursor-pointer"
                            onClick={handleResetMerging}
                        />
                    </div>
                </div>
            )}

            <AnalysisTabAnalysisColMergingBoxModalConfirmation
                isOpen={targetCategoryBeingMerged !== undefined}
                onClose={() => {
                    analysisTabAnalysisColDispatch({ type: "clear-target-category-being-merged" })
                }}
            />
        </>
    )
}