import CodeOccurrencesBox from "@components/Coding/CodeOccurrencesBox"
import { useGlautAuthInfo } from "@hooks/useGlautAuthInfo"
import { isEllipsisActive } from "@utils/html-elements/is-ellipsis-active"
import { normalizeToColorIndex } from "@utils/styling/colors-from-index"
import { memo, useEffect, useMemo, useRef, useState } from "react"
import { MdClose } from "react-icons/md"
import { IAnalysisCategory } from "src/@types/analysis"
import { ICategoryStats } from "src/@types/processing/statistics"

interface ICodeProps {
    codeStats: ICategoryStats<IAnalysisCategory>
    index: number
    isGrey?: boolean
    onRemove?: () => void
    onClick?: () => void
}

const AnalysisTabVerbatimsColItemCode = ({
    codeStats,
    index,
    isGrey = false,
    onRemove,
    onClick
}: Readonly<ICodeProps>) => {
    // #region Hooks
    const {
        currentProjectIsAtLeastRole
    } = useGlautAuthInfo()
    // #endregion

    // #region States
    const [hasTooltip, setHasTooltip] = useState(false)
    // #endregion

    // #region Refs
    const codeParagraphRef = useRef<HTMLParagraphElement>(null)
    // #endregion

    // #region Memos
    const canEdit = useMemo(
        () => currentProjectIsAtLeastRole("editor"),
        [currentProjectIsAtLeastRole]
    )
    // #endregion

    // #region Effects

    // onTextEllipsisShowTooltip
    useEffect(() => {
        if (!codeParagraphRef.current) return
        setHasTooltip(isEllipsisActive(codeParagraphRef.current))
    }, [codeStats.category.label])

    // #endregion

    return (<div
        className={`flex flex-row rounded-full pr-[0.3125em] pl-[0.0625em] gap-[0.25em] items-center border-1 
            max-w-[45%]
            border-transparent
            ${onClick ? "cursor-pointer" : ""}
        `}
        style={{
            backgroundColor: isGrey
                ? "var(--color-glaut-dummie-color)"
                : `var(--color${normalizeToColorIndex(index)}-light)`
        }}
        onClick={onClick}
        data-tooltip-id="tooltip--glaut-app"
        data-tooltip-content={codeStats.category.label}
        data-tooltip-place="right"
        data-tooltip-hidden={!hasTooltip}
    >
        <div className="py-[0.0625em]">
            <CodeOccurrencesBox number={codeStats.occurrences} percentage={codeStats.frequency} />
        </div>
        <p className={`text-[13.33px] text-ellipsis whitespace-nowrap overflow-hidden leading-[18px] rounded-none
            text-glaut-text-midnight
        `} ref={codeParagraphRef}>
            {codeStats.category.label}
        </p>
        {onRemove && (
            <button
                className="border-0 shadow-none bg-transparent p-0 m-0 text-base"
                onClick={e => {
                    e.stopPropagation()
                    onRemove()
                }}
                disabled={!canEdit}
            >
                <MdClose className="h-[0.875em] w-[0.875em] text-glaut-text-midnight" />
            </button>
        )}
    </div>
    )
}

export default memo(AnalysisTabVerbatimsColItemCode)