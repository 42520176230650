import GlautIcon from "@assets/GlautLogo.png"
import ModalLayout from "@components/layouts/ModalLayout"
import Loading from "@components/loading/Loading"
import { useInterviewService } from "@hooks/services/useInterviewService"
import { convertToMp3 } from "@utils/audio"
import { isSafari } from "@utils/device"
import { downloadFromUrl } from "@utils/miscellaneous"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { FaCirclePause, FaCirclePlay } from "react-icons/fa6"
import { MdClose, MdDownload, MdOpenInNew, MdOutlineSms } from "react-icons/md"
import { IInterviewMessage } from "src/@types/entry"
import CsvMessage from "../ConversationTranscriptBoxCsvMessage"

export interface IConversationTranscriptBoxMessageItemProps extends IInterviewMessage {
    isFollowUp?: boolean
}

interface IConversationTranscriptBoxMessageProps {
    message: IConversationTranscriptBoxMessageItemProps
    shouldRenderFollowUpHeader?: boolean
    projectId?: string
    useLegacyStyling?: boolean,
    highlightedQuote?: string
    designMode?: "interview" | "csv"
}

export default function ConversationTranscriptBoxMessage({
    message,
    shouldRenderFollowUpHeader,
    projectId,
    useLegacyStyling = false,
    highlightedQuote,
    designMode = "interview"
}: Readonly<IConversationTranscriptBoxMessageProps>) {
    // #region Services
    const interviewService = useInterviewService()
    // #endregion

    // #region States
    const [isPlayingAudio, setIsPlayingAudio] = useState(false)
    const [assetFile, setAssetFile] = useState<File>()
    const [shouldShowImageExpanded, setShouldShowImageExpanded] = useState(false)
    // #endregion

    // #region Refs
    const recordingUrl = useRef<string | undefined>()
    const actualAudioUrl = useRef<string | undefined>()
    const mp3Data = useRef<{ url: string, blob: Blob } | undefined>()
    // #endregion

    // #region Memos
    const contentClassNames = useMemo(
        () => {
            if (designMode === "csv") return ""

            const moderatorClassNames = useLegacyStyling
                ? "bg-glaut-very-light-grey text-glaut-dark-grey"
                : "text-glaut-text-midnight"

            const baseClassNames = "py-[0.75em] px-[1em] max-w-[85%] text-[13.33px] break-words " + (
                message.role === "moderator"
                    ? ` text-start rounded-r-[0.75em] rounded-tl-[0.75em] rounded-bl-none
                        ${moderatorClassNames}
                    `
                    : ` text-start rounded-l-[0.75em] rounded-tr-[0.75em] rounded-br-none
                        ${useLegacyStyling ? "bg-glaut-midnight text-white" : "bg-[#EDEDED] text-glaut-text-midnight"}
                    `
            )

            if (message.isFollowUp)
                return baseClassNames + " border-glaut-pink" + (
                    message.role === "moderator"
                        ? " border-[1px]"
                        : " border-[2px]"
                )

            return baseClassNames + (
                message.role === "moderator" && useLegacyStyling
                    ? " border-[1px] border-glaut-grey"
                    : ""
            )
        },
        [message.role, message.isFollowUp, useLegacyStyling, designMode]
    )
    // #endregion

    // #region Callbacks
    const fetchAudioUrl = useCallback(async () => {
        if (!projectId || !message.recording_id) return null
        if (actualAudioUrl.current) return actualAudioUrl.current

        // Store original recording URL
        const response = await interviewService.getRecordingUrl({ projectId, recordingId: message.recording_id })
        recordingUrl.current = response.recordingUrl

        // Convert to mp3
        try {
            mp3Data.current = await convertToMp3(response.recordingUrl, "webm", "mp3")
        } catch (error) {
            console.error(error)
        }

        // If safari, use mp3, otherwise use original
        actualAudioUrl.current = (isSafari() ? mp3Data.current?.url : response.recordingUrl) ?? response.recordingUrl

        return actualAudioUrl.current
    }, [projectId, message, actualAudioUrl, interviewService])

    const handleToggleAudioPlayback = useCallback(async (value?: boolean) => {
        const newValue = value ?? !isPlayingAudio

        if (!newValue) {
            setIsPlayingAudio(false)
            return
        }

        // only fetch when playing
        fetchAudioUrl().then(() => {
            setIsPlayingAudio(newValue)
        })
    }, [fetchAudioUrl, isPlayingAudio])

    const handleOnEndedAudioPlayback = useCallback(() => {
        setIsPlayingAudio(false)
    }, [])

    const handleDownloadRecording = useCallback(() => {
        fetchAudioUrl().then(() => {
            if (!mp3Data.current?.url) return
            downloadFromUrl(mp3Data.current.url, `Glaut recording ${message.recording_id}`, mp3Data.current.blob)
        })
    }, [message.recording_id, fetchAudioUrl])

    // #endregion

    // #region Effects

    // onAssetPresentSetAssetFile
    useEffect(() => {
        if (!message.asset_url?.length) return

        fetch(message.asset_url).then(response => {
            response.blob().then(blob => {
                setAssetFile(new File([blob], "asset", { type: response.headers.get("Content-Type") ?? undefined }))
            })
        })
    }, [message.asset_url])

    // #endregion

    // #region Element memos
    const content: React.ReactNode = useMemo(
        () => {
            const currContent = message.content.replace(/<.+>$/, "")
            if (!highlightedQuote) return currContent

            const splitContent = currContent.split(highlightedQuote)

            return splitContent.reduce((prevJsx, currStr, idx) => (idx > 0
                ? (<>
                    {prevJsx}<span className="text-glaut-pink-dark font-medium">{highlightedQuote}</span>{currStr}
                </>)
                : <>{currStr}</>
            ), <></>)
        },
        [message.content, highlightedQuote]
    )
    // #endregion

    if (message.asset_url?.length) {
        if (!assetFile) return <Loading />
        const assetType = assetFile.type.split("/")[0]

        return (
            <div className="flex justify-end">
                {assetType === "image" && (
                    <div className="flex justify-end">
                        <div
                            className="inline-block relative max-w-[50%] max-h-[125px] cursor-pointer"
                            onClick={() => {
                                // @TODO: make this div a button (and the below button a div)
                                setShouldShowImageExpanded(true)
                            }}
                        >
                            <img
                                src={message.asset_url}
                                alt="asset"
                                className="rounded-[0.5rem] object-contain max-h-full"
                            />
                            <div className="absolute inset-0
                                bg-gradient-to-t from-glaut-dark-grey from-5% to-transparent to-35%" />
                            <button
                                className="absolute bottom-1 left-1 border-none bg-transparent p-1 
                                    hover:bg-glaut-light-grey"
                                onClick={() => { setShouldShowImageExpanded(true) }}
                            >
                                <MdOpenInNew className="text-glaut-off-white" />
                            </button>
                        </div>
                        <ModalLayout
                            isOpen={shouldShowImageExpanded}
                            onClose={() => { setShouldShowImageExpanded(false) }}
                            onClick={() => { setShouldShowImageExpanded(false) }}
                            className="relative flex justify-center items-center w-full h-full transition-colors
                                bg-black/60"
                        >
                            <img src={message.asset_url} alt="asset" />
                            <button
                                className="absolute top-5 right-5 bg-transparent border-none p-0 shadow-none"
                                onClick={() => { setShouldShowImageExpanded(false) }}
                            >
                                <MdClose className="text-glaut-off-white h-[3em] w-[3em]" />
                            </button>
                        </ModalLayout>
                    </div>
                )}
                {assetType === "video" && (
                    <video className="max-w-[50%] max-h-[125px] rounded-[0.5rem]" controls>
                        <source src={URL.createObjectURL(assetFile)} />
                    </video>
                )}
            </div>
        )
    }

    return (
        <>
            {shouldRenderFollowUpHeader && (
                <div className={`border-t-1 border-dashed border-glaut-pink h-8 rounded-none 
                    px-[5px] pt-[10px] pb-[25px]`}>
                    <div className="flex flex-row items-center gap-1">
                        <img src={GlautIcon} alt="glaut-icon" className="h-4 w-4" />
                        <p className="text-[11.11px]">Follow up by Glaut</p>
                    </div>
                </div>
            )}
            <div className="flex flex-row gap-2 items-center">
                {designMode === "csv" && (
                    <CsvMessage content={message.content} />
                )}
                {designMode === "interview" && (
                    <>
                        {message.recording_id ? (
                            <>
                                <div className="flex-1 flex flex-row-reverse items-center group">
                                    <div className="p-[0.5em] transition-colors hover:bg-glaut-light-grey w-max">
                                        {isPlayingAudio
                                            ? <FaCirclePause onClick={() => { handleToggleAudioPlayback(false) }} />
                                            : <FaCirclePlay onClick={() => { handleToggleAudioPlayback(true) }} />}
                                    </div>
                                    <button className="p-[0.5rem] invisible group-hover:visible
                                    m-0 bg-transparent border-none shadow-none" onClick={handleDownloadRecording}>
                                        <MdDownload className="text-glaut-stroke-button" />
                                    </button>
                                </div>
                                {isPlayingAudio && actualAudioUrl.current && (
                                    <audio
                                        controls
                                        autoPlay
                                        key={actualAudioUrl.current}
                                        onEnded={handleOnEndedAudioPlayback}
                                        className="hidden"
                                    >
                                        <source
                                            src={actualAudioUrl.current}
                                            type={isSafari() ? "audio/mpeg" : "audio/webm"}
                                        />
                                    </audio>
                                )}
                            </>
                        ) : message.role === "respondent" && (<div className="flex-1"></div>)
                        }
                        {message.isFollowUp && message.role === "moderator" && (
                            <MdOutlineSms className="text-glaut-grey mt-1 self-start" />
                        )}
                        <p className={contentClassNames}>
                            {content}
                        </p>
                    </>
                )}
            </div>
        </>
    )
}