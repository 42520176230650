import GlautButtonSecondary from "@components/Buttons/GlautButtonSecondary"
import { copy, getCopy } from "@utils/Copy"
import { useCallback, useEffect, useState } from "react"
import { MdAddCircleOutline } from "react-icons/md"
import List from "../OutlineTabContentScreeningAndRedirectsScreeningQuestionsList"
import EditQuestionPopup from "@components/Popups/EditQuestionPopup"
import { useLegacyProject } from "../../contexts/ProjectProvider"
import {
    useOutlineTabScreeningAndRedirects,
    useOutlineTabScreeningAndRedirectsDispatch
} from "../../contexts/OutlineTabScreeningAndRedirectsProvider"
import { useOutlineTab } from "../../contexts/OutlineTabProvider"

export default function OutlineTabContentScreeningAndRedirectsScreeningQuestions() {
    // #region Contexts
    const { canEdit } = useOutlineTab()
    const { lang } = useLegacyProject()
    const { screeningQuestionToBuild } = useOutlineTabScreeningAndRedirects()
    const outlineTabScreeningAndRedirectsDispatch = useOutlineTabScreeningAndRedirectsDispatch()
    // #endregion

    // #region States
    const [isAddModalOpen, setIsAddModalOpen] = useState(false)
    // #endregion

    // #region Callbacks
    const handleCloseAddModal = useCallback(() => {
        outlineTabScreeningAndRedirectsDispatch({ type: "clear-screening-question-to-build" })
        setIsAddModalOpen(false)
    }, [outlineTabScreeningAndRedirectsDispatch])
    // #endregion

    // #region Effects
    useEffect(() => {
        if (!screeningQuestionToBuild) return
        setIsAddModalOpen(true)
    }, [screeningQuestionToBuild])
    // #endregion

    return (
        <div className="flex-1 flex flex-col gap-[0.75rem]">
            <p className="text-[16px] text-glaut-bar">
                {getCopy(copy.outline.screeningAndRedirects.screeningQuestions)?.toUpperCase()}
            </p>
            <div className="flex items-center justify-between py-[0.625rem]">
                <p className="text-[13.33px] font-medium text-glaut-text-midnight">
                    {getCopy(copy.outline.screeningAndRedirects.createNewQuestions)}
                </p>
                <GlautButtonSecondary squared onClick={() => { setIsAddModalOpen(true) }} disabled={!canEdit}>
                    <MdAddCircleOutline className="w-[1.125rem] h-[1.125rem]" />
                </GlautButtonSecondary>
            </div>
            <p className="text-[13.33px] text-glaut-dark-grey">
                {getCopy(copy.outline.screeningAndRedirects.addScreeningQuestionsToFilterRespondents)}
            </p>
            <List />

            {isAddModalOpen && (
                <EditQuestionPopup
                    defaultType="select"
                    defaultDomain="screening"
                    isOpen={isAddModalOpen}
                    originalQuestion={{
                        ...screeningQuestionToBuild,
                        type: "select",
                        domain: "screening",
                        img: screeningQuestionToBuild?.img ?? undefined,
                        embed_url: screeningQuestionToBuild?.embed_url ?? undefined,
                        header: screeningQuestionToBuild?.header?.length ? screeningQuestionToBuild.header : undefined
                    }}
                    onClose={handleCloseAddModal}
                    lang={lang}
                    disabled={!canEdit}
                />
            )}
        </div>
    )
}