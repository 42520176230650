import AISprinkles from "@assets/ai-sprinkles.svg"
import { useReportDetails } from "../../contexts/ReportDetailsContext"
import { Path, Svg, Text, View } from "@react-pdf/renderer"

export default function ReportDetailsContentTitle() {
    // #region Contexts
    const { report, renderAsPdf, tw } = useReportDetails()
    // #endregion

    if (renderAsPdf)
        return (
            <View style={tw("flex flex-row px-[8px]")}>
                <Text style={tw("font-serif text-[30.54px] font-medium text-glautTextMidnight flex-1")}>
                    {report.title}
                </Text>
                <Svg width="10.18" height="10.18" viewBox="0 0 16 16" fill="none" style={tw("self-end")}>
                    <Path
                        // eslint-disable-next-line max-len
                        d="M7.62509 3.61702C7.70509 3.61702 7.74538 3.57016 7.76566 3.49645C7.97309 2.37816 7.95995 2.3513 9.12509 2.13045C9.20509 2.11702 9.25224 2.07045 9.25224 1.98987C9.25224 1.90959 9.20538 1.86273 9.12481 1.8493C7.96652 1.61502 7.99995 1.58816 7.76566 0.483018C7.74566 0.409589 7.70538 0.362732 7.62509 0.362732C7.54481 0.362732 7.50452 0.409589 7.48424 0.483018C7.24995 1.58816 7.28995 1.61473 6.12509 1.8493C6.05138 1.86245 5.99766 1.9093 5.99766 1.98987C5.99766 2.07016 6.05138 2.11702 6.12481 2.13045C7.29024 2.36473 7.27681 2.37816 7.48424 3.49645C7.50424 3.57016 7.54481 3.61702 7.62509 3.61702ZM4.38395 8.22416C4.51109 8.22416 4.59824 8.14388 4.61166 8.0233C4.85252 6.2353 4.91281 6.2353 6.76109 5.88045C6.88166 5.86045 6.96881 5.77987 6.96881 5.65273C6.96881 5.53216 6.88166 5.4453 6.76109 5.42502C4.91281 5.17073 4.84595 5.11045 4.61166 3.28902C4.59824 3.16845 4.51109 3.0813 4.38395 3.0813C4.26338 3.0813 4.17624 3.16845 4.16281 3.29559C3.94195 5.09016 3.84824 5.08359 2.01338 5.42502C1.89281 5.45188 1.80566 5.53216 1.80566 5.65273C1.80566 5.78673 1.89281 5.86045 2.03995 5.88045C3.86166 6.17502 3.94195 6.22187 4.16281 8.00987C4.17624 8.14388 4.26338 8.22416 4.38395 8.22416ZM8.92395 15.6373C9.09824 15.6373 9.22538 15.5099 9.25881 15.329C9.73424 11.6596 10.25 11.1036 13.8794 10.7019C14.0668 10.6819 14.1942 10.5413 14.1942 10.367C14.1942 10.1927 14.0668 10.059 13.8794 10.0322C10.25 9.63045 9.73424 9.07473 9.25881 5.40502C9.22538 5.22416 9.09824 5.10359 8.92395 5.10359C8.74966 5.10359 8.62281 5.22416 8.59595 5.40502C8.12052 9.07473 7.59824 9.63045 3.97538 10.0322C3.78109 10.059 3.65395 10.193 3.65395 10.367C3.65395 10.5413 3.78109 10.6819 3.97538 10.7019C7.59138 11.1773 8.09366 11.6662 8.59595 15.329C8.62281 15.5099 8.74995 15.6373 8.92395 15.6373Z"
                        fill="#BCBCBC"
                    />
                </Svg>
            </View>
        )

    return (
        <div className="flex">
            <p className="font-serif text-[48px] font-medium text-glaut-text-midnight mx-[12px] flex-1">
                {report.title}
            </p>
            <img src={AISprinkles} alt="sprinkles" className="self-end" />
        </div>
    )
}