import { IProject } from "@/@types/project"
import { useGlautAuthInfo } from "@hooks/useGlautAuthInfo"
import { createContext, useContext, useMemo, useReducer } from "react"

// #region Types
export type IOutlineTabSidebarOption =
    | "briefing"
    | "overview"
    | "interview-questions"
    | "screening-and-redirects"
    | "branding"

interface IOutlineTabProviderProps {
    children: React.ReactNode
    project: IProject
}

interface IOutlineTabContextState {
    sidebarSelectedOption: IOutlineTabSidebarOption
    canEdit: boolean
    hasPermission: boolean
}

type IOutlineTabContextAction = {
    type: "set-sidebar-selected-option"
    option: IOutlineTabSidebarOption
}
// #endregion

// #region Context definitions
const OutlineTabContext = createContext({} as IOutlineTabContextState)
const OutlineTabContextDispatch = createContext({} as React.Dispatch<IOutlineTabContextAction>)
// #endregion

// #region Hook definitions
export function useOutlineTab() {
    return useContext(OutlineTabContext)
}

export function useOutlineTabDispatch() {
    return useContext(OutlineTabContextDispatch)
}
// #endregion

// #region Provider definition
export default function OutlineTabProvider({ children, project }: Readonly<IOutlineTabProviderProps>) {
    // #region Glaut permissions
    const { currentProjectIsAtLeastRole } = useGlautAuthInfo()
    // #endregion

    // #region Memos
    const hasPermission = useMemo(() => currentProjectIsAtLeastRole("editor"), [currentProjectIsAtLeastRole])
    const canEdit = useMemo(
        () => hasPermission
            && project?.source === "interview"
            && ["not_set", "set_up"].includes(project.status),
        [hasPermission, project]
    )
    // #endregion

    // #region Provider configuration
    const initialState: IOutlineTabContextState = {
        sidebarSelectedOption: "overview",
        canEdit: false,
        hasPermission: false
    }

    const [rawState, dispatch] = useReducer(OutlineTabReducer, initialState)

    const state = useMemo(() => ({ ...rawState, canEdit, hasPermission }), [rawState, canEdit, hasPermission])
    // #endregion

    return (
        <OutlineTabContext.Provider value={state}>
            <OutlineTabContextDispatch.Provider value={dispatch}>
                {children}
            </OutlineTabContextDispatch.Provider>
        </OutlineTabContext.Provider>
    )
}
// #endregion

// #region Reducer definition
function OutlineTabReducer(state: IOutlineTabContextState, action: IOutlineTabContextAction): IOutlineTabContextState {
    switch (action.type) {
        case "set-sidebar-selected-option": {
            return {
                ...state,
                sidebarSelectedOption: action.option
            }
        }
        default: {
            return state
        }
    }
}
// #endregion