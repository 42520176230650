import Provider from "../../contexts/OutlineTabScreeningAndRedirectsProvider"
import EndingPaths from "../OutlineTabContentScreeningAndRedirectsEndingPaths"
import ScreeningQuestions from "../OutlineTabContentScreeningAndRedirectsScreeningQuestions"

export default function OutlineTabContentScreeningAndRedirects() {
    return (
        <Provider>
            <div className="flex gap-[1.5rem] w-full h-full">
                <ScreeningQuestions />
                <EndingPaths />
            </div>
        </Provider>
    )
}