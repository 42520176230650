export const editScreeningCopy = {
    chooseWithOptionsQualityDisqualifyRespondents: {
        en: "Choose which options qualify/disqualify respondents"
    },
    setQuotasToControlYourTargetAudience: {
        en: "Set quotas to control your target audience"
    },
    screeningWithQuotasRequiresSettingAResponsesTargetFirst: {
        en: "Screening with quotas requires setting a responses target first in the Overview section."
    },
    quotaPercentage: {
        en: "Quota (%)"
    },
    pleaseSetAResponsesTargetFirstToUseQuotasPeriod: {
        en: "Please set a responses target first to use quotas."
    },
    pleaseMakeSureTheQuotasSumUpTo100Percent: {
        en: "Please make sure the quotas sum up to 100%."
    }
}