import { useOutlineTab } from "../../contexts/OutlineTabProvider"
import Branding from "../OutlineTabContentBranding"
import Iqs from "../OutlineTabContentIqs"
import Overview from "../OutlineTabContentOverview"
import ScreeningAndRedirects from "../OutlineTabContentScreeningAndRedirects"

export default function OutlineTabContent() {
    // #region Contexts
    const { sidebarSelectedOption } = useOutlineTab()
    // #endregion

    return (
        <>
            {sidebarSelectedOption === "overview" && <Overview />}
            {sidebarSelectedOption === "interview-questions" && <Iqs />}
            {sidebarSelectedOption === "screening-and-redirects" && <ScreeningAndRedirects />}
            {sidebarSelectedOption === "branding" && <Branding />}
        </>
    )
}