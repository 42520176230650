export const interviewTerminatedScreenoutCopy = {
    message: {
        en: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Unfortunately,< br />
                <span className="text-glaut-pink font-medium">you are not <br />a right fit.</span>
            </p>
        ),
        it: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Sfortunatamente, <br />
                <span className="text-glaut-pink">non sei <br />un candidato adatto.</span>
            </p>
        ),
        es: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Lo lamentamos, <br />
                <span className="text-glaut-pink">no será posible <br />continuar.</span>
            </p>
        ),
        fr: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Malheureusement, <br />
                <span className="text-glaut-pink">vous n’êtes pas<br /> la bonne personne.</span>
            </p>
        ),
        de: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.6px]">
                Leider, <br />
                <span className="text-glaut-pink">passen Sie <br /> nicht zu uns.</span>
            </p>
        )
    },
    detail: {
        en: "Thank you, but you didn’t match the requirements for this interview.",
        it: "Grazie, ma non hai soddisfatto i requisiti per questa intervista.",
        es: "Agradecemos tu tiempo, pero no cumples con los requerimientos para realizar esta entrevista.",
        fr: "Merci, mais vous n'avez pas répondu aux exigences de cette entrevue.",
        de: "Danke, aber Sie haben die Anforderungen für diese Befragung nicht erfüllt."
    }
}