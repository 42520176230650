import { IProject, IProjectScreeningType } from "@/@types/project"
import GlautSwitchWithLabel from "@components/inputs/GlautSwitchWithLabel"
import GlautTextAreaMultiLine from "@components/inputs/GlautTextAreaMultiLine"
import GlautTextAreaSingleLine from "@components/inputs/GlautTextAreaSingleLine"
import { copy, getCopy } from "@utils/Copy"
import { useCallback, useState } from "react"
import { useOutlineTab } from "../../contexts/OutlineTabProvider"
import { useLegacyProject } from "../../contexts/ProjectProvider"

interface OutlineTabContentScreeningAndRedirectsEndingPathsItemProps {
    redirectType: IProjectScreeningType
}

export default function OutlineTabContentScreeningAndRedirectsEndingPathsItem({
    redirectType
}: Readonly<OutlineTabContentScreeningAndRedirectsEndingPathsItemProps>) {
    // #region Contexts
    const { lang } = useLegacyProject()
    // #endregion

    // #region Util values
    const redirectLinkKey = redirectType === "complete" ? "redirect_link" : `${redirectType}_redirect_link`
    const endingMessageKey = redirectType === "complete" ? "ending_message" : `${redirectType}_ending_message`
    // #endregion

    // #region Contexts
    const { project, setProject } = useLegacyProject()
    const { canEdit } = useOutlineTab()
    // #endregion

    // #region States
    const [isToggleChecked, setIsToggleChecked] = useState(
        project?.source === "interview" && !!(
            project?.brand_settings[redirectLinkKey] || project?.brand_settings[endingMessageKey]
        )
    )
    // #endregion

    // #region Callbacks
    const handleToggleRedirect = useCallback((value?: boolean) => {
        setIsToggleChecked(prev => {
            const newValue = value ?? !prev

            setProject(prev => (prev?.source !== "interview" ? prev : {
                ...prev,
                brand_settings: {
                    ...prev.brand_settings,
                    [redirectLinkKey]: newValue ? (prev.brand_settings[redirectLinkKey] ?? null) : null,
                    [endingMessageKey]: newValue ? (prev.brand_settings[endingMessageKey] ?? null) : null
                }
            }) as IProject)

            return newValue
        })
    }, [endingMessageKey, redirectLinkKey, setProject])

    const handleUpdateUrl = useCallback((value: string) => {
        const actualValue = /^(http|https):\/\//.test(value) ? value : `http://${value}`
        setProject(prev => (prev?.source !== "interview" ? prev : {
            ...prev,
            brand_settings: { ...prev.brand_settings, [redirectLinkKey]: actualValue }
        }) as IProject)
    }, [redirectLinkKey, setProject])

    const handleUpdateEndingMessage = useCallback((value: string) => {
        setProject(prev => (prev?.source !== "interview" ? prev : {
            ...prev,
            brand_settings: { ...prev.brand_settings, [endingMessageKey]: { [lang]: value } }
        }) as IProject)
    }, [endingMessageKey, setProject, lang])
    // #endregion

    if (project?.source !== "interview") return <></>

    return (
        <div className="flex flex-col gap-[0.5rem] pr-1">
            <GlautSwitchWithLabel
                checked={isToggleChecked}
                onChange={e => { handleToggleRedirect(e.target.checked) }}
                label={getCopy(copy.outline.screeningAndRedirects.types[redirectType].switchLabel) ?? ""}
                disabled={!canEdit}
            />
            <div className="flex flex-col gap-[0.75rem]" style={!isToggleChecked ? { display: "none" } : undefined}>
                <div className="flex flex-col gap-[0.5rem]">
                    <p className="text-[13.33px] text-glaut-dark-grey">
                        {getCopy(copy.outline.screeningAndRedirects.types[redirectType].urlLabel)}
                    </p>
                    <GlautTextAreaSingleLine
                        defaultValue={project.brand_settings[redirectLinkKey] ?? ""}
                        onChange={e => { handleUpdateUrl(e.target.value) }}
                        placeholder="https://www.mysite.com/redirect_link"
                        disabled={!canEdit}
                    />
                </div>
                <div className="flex flex-col gap-[0.5rem]">
                    <p className="text-[13.33px] font-medium text-glaut-text-midnight">
                        {getCopy(copy.outline.screeningAndRedirects.endingMessage)}
                    </p>
                    {getCopy(copy.outline.screeningAndRedirects.types[redirectType].endingMessageLabel) && (
                        <p className="text-[13.33px] text-glaut-dark-grey">
                            {getCopy(copy.outline.screeningAndRedirects.types[redirectType].endingMessageLabel)}
                        </p>
                    )}
                    <GlautTextAreaMultiLine
                        defaultValue={project.brand_settings[endingMessageKey]?.[lang] ?? ""}
                        onChange={e => { handleUpdateEndingMessage(e.target.value) }}
                        placeholder={getCopy(
                            copy.outline.screeningAndRedirects.types[redirectType].endingMessagePlaceholder
                        ) ?? ""}
                        disabled={!canEdit}
                    />
                </div>
            </div>
        </div >
    )
}