import { copy, getCopy } from "@utils/Copy"
import { useCallback } from "react"
import { useLegacyProject } from "../../contexts/ProjectProvider"
import { languages } from "@utils/Variables"
import LanguageDropdown from "@components/inputs/LanguageDropdown"

interface ICSVSettingsTabProjectProjectLanguageProps {
    disabled?: boolean
}

export default function CSVSettingsTabProjectProjectLanguage({
    disabled = false
}: Readonly<ICSVSettingsTabProjectProjectLanguageProps>) {
    // #region Contexts
    const { project, setProject } = useLegacyProject()
    // #endregion

    // #region Callbacks
    const handleChangeProjectLanguage = useCallback((lang: string) => {
        setProject(prev => prev ? ({ ...prev, general_settings: { ...prev.general_settings, language: lang } }) : prev)
    }, [setProject])
    // #endregion

    if (project?.source !== "CSV") return <></>

    return (
        <div className="flex flex-col gap-[0.5em]">
            <p className="text-[13.33px] font-medium text-[#565656]">
                {getCopy(copy.coding.settings.csvSettings.projectLanguage) ?? ""}
            </p>
            <p className="text-[13.33px] text-glaut-dark-grey">
                {getCopy(copy.coding.settings.csvSettings.projectLanguageDescription) ?? ""}
            </p>
            <div className="w-[400px] self-center">
                <LanguageDropdown
                    languages={languages}
                    currLanguage={project?.general_settings?.language}
                    onSelect={handleChangeProjectLanguage}
                    disabled={disabled}
                />
            </div>
        </div>
    )
}