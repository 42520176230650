import GlautButtonSecondary from "@components/Buttons/GlautButtonSecondary"
import Loading from "@components/loading/Loading"
import NewAnalysisPopup from "@components/Popups/NewAnalysisPopup"
import { useGlautAuthInfo } from "@hooks/useGlautAuthInfo"
import { copy, getCopy } from "@utils/Copy"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import ReactDOM from "react-dom"
import { MdAddCircle } from "react-icons/md"
import { IAnalysisType } from "src/@types/analysis"
import { useAnalysisTab, useAnalysisTabDispatch } from "../../contexts/AnalysisTabProvider"
import AnalysisTabQuestionsColQuestionItem from "../AnalysisTabQuestionsColQuestionItem"
import { useSortedIqsAndAnalyses } from "./hooks/useSortedIqsAndAnalyses"
import { isQuestionItemAnAnalysis } from "./utils/is-question-item-an-analysis"

export default function AnalysisTabQuestionsCol() {
    // #region Hooks
    const { currentProjectIsAtLeastRole } = useGlautAuthInfo()
    // #endregion

    // #region Contexts
    const {
        analyses,
        selectedAnalysis,
        newAnalysisType,
        editingAnalysis,
        selectedInterviewQuestionId
    } = useAnalysisTab()
    const analysisTabDispatch = useAnalysisTabDispatch()
    // #endregion

    // #region States
    const [hasClickedToCreateNewAnalysis, setHasClickedToCreateNewAnalysis] = useState(false)
    // #endregion

    // #region Memos
    const canEdit = useMemo(
        () => currentProjectIsAtLeastRole("editor"),
        [currentProjectIsAtLeastRole]
    )
    // #endregion

    // #region Helper hooks
    const { sortedInterviewQuestions, sortedIqsAndAnalyses } = useSortedIqsAndAnalyses()
    // #endregion

    // #region Refs
    const buttonNewAnalysisRef = useRef<HTMLButtonElement>(null)
    // #endregion

    // #region Callbacks
    const handleCloseCreateNewAnalysisMenu = useCallback(() => {
        setHasClickedToCreateNewAnalysis(false)
    }, [])

    const handleSelectNewAnalysisType = useCallback((analysisType: IAnalysisType) => {
        handleCloseCreateNewAnalysisMenu()
        analysisTabDispatch({ type: "start-new-analysis-creation", analysisType })
    }, [handleCloseCreateNewAnalysisMenu, analysisTabDispatch])
    // #endregion

    // #region Effects

    // onLoadSelectFirstAnalysis
    useEffect(() => {
        if (selectedInterviewQuestionId || selectedAnalysis || sortedIqsAndAnalyses.length === 0) return

        const item = sortedIqsAndAnalyses[0]
        if (isQuestionItemAnAnalysis(item))
            analysisTabDispatch({ type: "select-analysis", analysisId: item.id })
        else
            analysisTabDispatch({ type: "select-question", interviewQuestionId: item.id })
    }, [selectedAnalysis, analysisTabDispatch, sortedIqsAndAnalyses, selectedInterviewQuestionId])

    // #endregion

    return (
        <div
            id="div--analysis-tab-questions-col"
            className="h-full overflow-y-hidden bg-[#EDEDED] pt-[3em] flex flex-col"
        >
            <div className="header mx-[0.75em] mb-[0.5em] flex justify-between">
                <p className="text-[rgb(0,0,0,0.6)] font-normal text-[13.33px] ml-[0.75em] my-[0.75em]">
                    {getCopy(copy.coding.analyses)}
                </p>
                <GlautButtonSecondary
                    onClick={() => { setHasClickedToCreateNewAnalysis(true) }}
                    ref={buttonNewAnalysisRef}
                    disabled={!canEdit || newAnalysisType !== undefined || editingAnalysis !== undefined}
                >
                    <div className="flex flex-row gap-[0.3125em] items-center">
                        <MdAddCircle className="text-glaut-pink h-[1.5em] w-[1.5em] group-disabled:text-glaut-grey" />
                        <p className="text-glaut-pink font-medium text-[13.33px] group-disabled:text-glaut-grey">
                            {getCopy(copy.coding.newAnalysis)}
                        </p>
                    </div>
                </GlautButtonSecondary>
            </div>
            {!analyses && (
                <div className="flex flex-col justify-center h-full">
                    <Loading />
                </div>
            )}
            {analyses && (
                <div className="flex flex-col gap-[0.5em] pb-[0.5em] overflow-auto h-full px-[0.5em]">
                    {sortedIqsAndAnalyses.map((item, idx) => isQuestionItemAnAnalysis(item)
                        ? ( // analysis
                            <AnalysisTabQuestionsColQuestionItem
                                key={item.id}
                                analysis={{
                                    ...item,
                                    title: item.title ?? (item.sources.length === 1
                                        ? (sortedInterviewQuestions.find(
                                            iq => iq.id === item.sources[0]
                                        )?.title ?? item.goal)
                                        : item.goal),
                                    completion: item.completion ? item.completion * 100 : undefined,
                                    isAnalysis: true
                                }}
                                index={idx + 1}
                            />
                        )
                        : ( // IQ
                            <AnalysisTabQuestionsColQuestionItem
                                key={item.id}
                                analysis={{
                                    id: item.id,
                                    title: item.title,
                                    sources: item.sources,
                                    type: item.type
                                }}
                                index={idx + 1}
                            />
                        )
                    )}
                </div>
            )}
            {hasClickedToCreateNewAnalysis && (
                ReactDOM.createPortal(
                    <NewAnalysisPopup
                        onClose={handleCloseCreateNewAnalysisMenu}
                        onSelect={handleSelectNewAnalysisType}
                        anchorRef={buttonNewAnalysisRef}
                        horizontalOffset={2}
                        place="right"
                    />,
                    document.getElementById("portal")!
                )
            )}
        </div>
    )
}
