import GlautButtonPrimary from "@components/Buttons/GlautButtonPrimary"
import GlautButtonSecondary from "@components/Buttons/GlautButtonSecondary"
import ModalLayout from "@components/layouts/ModalLayout"
import { copy, getCopy } from "@utils/Copy"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { SelectInstance } from "react-select"
import { useAnalysisTab, useAnalysisTabDispatch } from "../../contexts/AnalysisTabProvider"
import AnalysisSelectLabel from "../AnalysisTabAnalysisColBreakdownModalAnalysisSelectLabel"
import QuestionSelect from "../AnalysisTabAnalysisColBreakdownModalQuestionSelect"
import QuestionSelectLabel from "../AnalysisTabAnalysisColBreakdownModalQuestionSelectLabel"
import { useSortedIqsAndAnalyses } from "../AnalysisTabQuestionsCol/hooks/useSortedIqsAndAnalyses"
import { isQuestionItemAnAnalysis } from "../AnalysisTabQuestionsCol/utils/is-question-item-an-analysis"
import { buildBreakdownSelectedString, parseBreakdownSelectedString } from "./utils/build-breakdown-selected-string"

interface IAnalysisTabAnalysisColBreakdownModalProps {
    isOpen: boolean
    onClose?: () => void
}

export default function AnalysisTabAnalysisColBreakdownModal({
    isOpen,
    onClose
}: Readonly<IAnalysisTabAnalysisColBreakdownModalProps>) {
    // #region Contexts
    const { selectedBreakdown } = useAnalysisTab()
    const analysisTabDispatch = useAnalysisTabDispatch()
    // #endregion

    // #region States
    const [selectedItem, setSelectedItem] = useState<string>()
    // #endregion

    // #region Memos
    const isSavingAllowed = useMemo(
        () => selectedItem !== (selectedBreakdown ? buildBreakdownSelectedString(selectedBreakdown) : undefined),
        [selectedBreakdown, selectedItem]
    )
    // #endregion

    // #region Helper hooks
    const { sortedInterviewQuestions, sortedIqsAndAnalysesForBreakdown } = useSortedIqsAndAnalyses()
    // #endregion

    // #region Refs
    const selectRef = useRef<SelectInstance<{ label: React.ReactNode; value: string }>>()
    // #endregion

    // #region Callbacks
    const updateSelectedItemFromCurrentBreakdown = useCallback(() => {
        setSelectedItem(selectedBreakdown ? buildBreakdownSelectedString(selectedBreakdown) : undefined)

        if (!selectedBreakdown) selectRef.current?.clearValue()
    }, [selectedBreakdown])

    const handleSaveBreakdown = useCallback(() => {
        if (!selectedItem) {
            analysisTabDispatch({ type: "remove-breakdown" })
            onClose?.()
            return
        }

        const parsedItem = parseBreakdownSelectedString(selectedItem)
        if (!parsedItem) return

        analysisTabDispatch({ type: "set-breakdown", breakdown: parsedItem })
        onClose?.()
    }, [analysisTabDispatch, onClose, selectedItem])
    // #endregion

    // #region Element memos
    const selectOptions = useMemo(
        () => sortedIqsAndAnalysesForBreakdown.map((item, idx) => ({
            value: buildBreakdownSelectedString(item),
            label: isQuestionItemAnAnalysis(item) ? (
                <AnalysisSelectLabel
                    index={idx}
                    label={item.title ?? (
                        item.sources.length === 1 ? (sortedInterviewQuestions.find(
                            iq => iq.id === item.sources[0]
                        )?.title ?? item.goal) : item.goal
                    )}
                    type={item.type}
                />
            ) : (
                <QuestionSelectLabel
                    index={idx}
                    type={item.type}
                    label={item.title}
                    domain={item.domain}
                />
            )
        })),
        [sortedIqsAndAnalysesForBreakdown, sortedInterviewQuestions]
    )

    const selectedOption = useMemo(
        () => selectOptions.find(opt => opt.value === selectedItem),
        [selectedItem, selectOptions]
    )
    // #endregion

    // #region Effects
    useEffect(() => {
        if (!isOpen) return
        updateSelectedItemFromCurrentBreakdown()
    }, [isOpen, updateSelectedItemFromCurrentBreakdown])
    // #endregion

    return (
        <ModalLayout
            isOpen={isOpen}
            onClose={onClose}
            className={`flex flex-col items-center rounded-[0.25em] py-[0.625em] px-[1em] overflow-y-visible 
                w-[552px] max-h-screen no-scrollbar transition-all border-1
                shadow-[0px_0px_8px_0px_rgb(0,0,0,0.15)] bg-white border-glaut-stroke-glaut
                ${isOpen ? "scale-100 opacity-100" : "scale-125 opacity-0"}
            `}
        >
            <p className="text-[13.33px] font-medium text-[rgb(0,0,0,0.6)] self-start">
                {getCopy(copy.coding.breakdownBy)}
            </p>
            <div className="w-full flex flex-col gap-[0.5em] mb-[0.5em]">
                <div className="flex flex-row items-center w-full">
                    <QuestionSelect
                        options={selectOptions}
                        value={selectedOption}
                        onSelect={val => {
                            setSelectedItem(val ?? undefined)
                        }}
                        ref={element => {
                            if (!element) return
                            selectRef.current = element
                        }}
                    />
                </div>
            </div>
            <div className="flex flex-row justify-between pt-[0.5em] w-full">
                <GlautButtonSecondary onClick={onClose}>
                    {getCopy(copy.coding.cancel)}
                </GlautButtonSecondary>
                <GlautButtonPrimary onClick={handleSaveBreakdown} disabled={!isSavingAllowed}>
                    {getCopy(copy.coding.save)}
                </GlautButtonPrimary>
            </div>
        </ModalLayout>
    )
}