import { MdInfoOutline } from "react-icons/md"
import GlautSwitch, { IGlautSwitchProps } from "../GlautSwitch"
import { glautTooltipId } from "@components/layouts/GlautTooltip/constants/id"

interface IGlautSwitchWithLabelProps extends IGlautSwitchProps {
    label: string
    tooltip?: {
        id?: string
        content: string
        place?: "top" | "bottom" | "left" | "right"
        className?: string
    }
}

export default function GlautSwitchWithLabel({
    label,
    tooltip,
    ...props
}: Readonly<IGlautSwitchWithLabelProps>) {
    return (
        <div className="flex items-center gap-[0.5rem]">
            <GlautSwitch {...props} />
            <p className="text-[13.33px] font-medium text-glaut-text-midnight">
                {label}
            </p>
            {tooltip && (
                <MdInfoOutline
                    className="h-[1rem] w-[1rem] text-glaut-grey"
                    data-tooltip-id={tooltip.id ?? glautTooltipId}
                    data-tooltip-content={tooltip.content}
                    data-tooltip-place={tooltip.place ?? "top"}
                    data-tooltip-class-name={tooltip.className}
                />
            )}
        </div>

    )
}