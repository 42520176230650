import { useConversationOverlay } from "@components/ConversationOverlay/contexts/ConversationOverlayProvider"
import {
    useConversationOverlayInterviewLength
} from "@components/ConversationOverlay/hooks/useConversationOverlayInterviewLength"
import { MdAccessTime } from "react-icons/md"

export default function ConversationOverlayInterviewLength() {
    // #region Contexts
    const { interview } = useConversationOverlay()
    // #endregion

    // #region Formatting hooks
    const { minutes, seconds } = useConversationOverlayInterviewLength(interview.total_duration)
    // #endregion

    return (
        <div className="flex gap-[0.5em] items-center my-[0.25em]">
            <MdAccessTime className="h-[1em] w-[1em] text-glaut-grey" />
            <p className="text-[13.33px] text-glaut-midnight">
                {String(minutes).padStart(2, "0")}min {String(seconds).padStart(2, "0")}sec
            </p>
        </div>
    )
}