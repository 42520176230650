import { ITerminationReason } from "@/@types/entry"
import ErrorPage from "@components/layouts/ErrorPage"
import ErrorLevel from "@utils/ErrorLevel"
import InterviewTerminatedScreenout from "../InterviewTerminatedScreenout"
import InterviewTerminatedQuotaFull from "../InterviewTerminatedQuotaFull"
import InterviewTerminatedUncooperative from "../InterviewTerminatedUncooperative"

interface IInterviewTerminatedProps {
    lang: string
    terminationReason: Exclude<ITerminationReason, "completed">
}

export default function InterviewTerminated({
    lang,
    terminationReason
}: Readonly<IInterviewTerminatedProps>) {
    if (terminationReason === "screenout")
        return <InterviewTerminatedScreenout lang={lang} />

    if (terminationReason === "quotafull")
        return <InterviewTerminatedQuotaFull lang={lang} />

    if (terminationReason === "uncooperative")
        return <InterviewTerminatedUncooperative lang={lang} />

    return (
        <ErrorPage
            lang={lang}
            errorLevel={ErrorLevel.Error}
        />
    )
}