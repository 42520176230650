import { useMemo } from "react"

/**
 * @prop {number} length The length in seconds.
 */
export function useConversationOverlayInterviewLength(length: number) {
    return useMemo(() => ({
        minutes: length >= 0 ? Math.floor(length / 60) : 0,
        seconds: length >= 0 ? Math.round(length % 60) : 0
    }), [length])
}