import { IGlautAssetComponentOnChange } from "./@types/on-change"
import { IGlautAssetComponentValue } from "./@types/value"
import Options from "./components/GlautAssetComponentOptions"
import SelectedOptions from "./components/GlautAssetComponentSelectedOptions"
import Provider from "./contexts/GlautAssetComponentProvider"

interface IGlautAssetComponentProps {
    disabled?: boolean
    value?: IGlautAssetComponentValue
    onChange?: IGlautAssetComponentOnChange
}

export default function GlautAssetComponent({
    disabled,
    value,
    onChange
}: Readonly<IGlautAssetComponentProps>) {
    return (
        <Provider disabled={disabled} value={value} onChange={onChange}>
            <div className="flex flex-col gap-[0.5em] w-full">
                <Options />
                <SelectedOptions />
            </div>
        </Provider>
    )
}