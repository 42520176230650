import { languages } from "@utils/Variables"
import { useCallback } from "react"
import { MdOutlineClose } from "react-icons/md"
import { useLegacyProject } from "../../contexts/ProjectProvider"
import { useOutlineTab } from "../../contexts/OutlineTabProvider"
import LanguageDropdown from "@components/inputs/LanguageDropdown"

interface IOutlineTabContentOverviewInterviewLanguageItemProps {
    languages: typeof languages
    lang?: string
    onRemove?: (lang: string) => void
    onSelectNewLanguage: (selectedLang: string, currLang?: string) => void
}

export default function OutlineTabContentOverviewInterviewLanguageItem({
    languages,
    lang,
    onRemove,
    onSelectNewLanguage
}: Readonly<IOutlineTabContentOverviewInterviewLanguageItemProps>) {
    // #region Contexts
    const { project } = useLegacyProject()
    const { canEdit } = useOutlineTab()
    // #endregion

    // #region Callbacks
    const handleRemoveLanguage = useCallback(() => {
        if (!lang) return
        onRemove?.(lang)
    }, [onRemove, lang])
    // #endregion

    if (!project) return <></>

    return (
        <div className="flex justify-center items-center gap-[0.5em] w-full">
            <div className="w-[0.875em]"></div> {/** intentional empty div for alignment */}
            <div className="w-[50%]">
                <LanguageDropdown
                    languages={languages}
                    onSelect={langCode => { onSelectNewLanguage(langCode, lang) }}
                    currLanguage={lang}
                    disabled={lang === project.general_settings.language || !canEdit}
                />
            </div>
            <div className="w-[0.875em]">
                {canEdit && !!onRemove && (
                    <button
                        className="border-none shadow-none rounded-none p-0 text-[1em] disabled:cursor-not-allowed"
                        onClick={handleRemoveLanguage}
                    >
                        <MdOutlineClose className="text-glaut-dark-grey w-[0.875em] h-[0.875em]" />
                    </button>
                )}
            </div>
        </div>
    )
}