import { IProjectResults, IVariableResults } from "@/@types/project"
import { ISegmentedStats } from "@/@types/segmentation"
import { IAnalysisCategory } from "src/@types/analysis"
import { IBaseScaleStats, ICategoryStats, INpsStats, IStats } from "src/@types/processing/statistics"
import { ISelectOption } from "src/@types/interview-question"
import { ISegment } from "@/@types/base/generics"

type IApiCategoryStats<TSegment extends ISegment = ISegment> = Omit<ICategoryStats<TSegment>, "category"> & {
    category: TSegment | null
}

type IApiSegmentedStats<TSegment extends ISegment = ISegment, TStats extends IStats = IStats> = {
    segment: Omit<ISegmentedStats<TSegment, TStats>["segment"], "category"> & {
        category: TSegment | null
    }
    segmented: IApiCategoryStats<TSegment>[] | INpsStats | IBaseScaleStats
}

export type IAnalysesStatsResponse = {
    interview_count: number
    analysis_results: {
        [analysisId: string]: {
            interview_count: number
            stats: IApiCategoryStats<IAnalysisCategory>[]
        }
    }
    question_results: {
        [interviewQuestionId: string]: IVariableResults<ICategoryStats<ISelectOption>[] | INpsStats>
    }
}

export abstract class BaseApiService {
    protected defaultNoAnswerAnalysisCategory(): IAnalysisCategory {
        return {
            id: "empty",
            label: "No answer",
            parent: null,
            description: "Absence of a theme."
        }
    }

    protected defaultNoAnswerSelectOption(supportedLanguages: string[]): ISelectOption {
        return {
            id: "empty",
            label: supportedLanguages.reduce(
                (obj, lang) => ({ ...obj, [lang]: "No answer" }),
                {}
            )
        }
    }

    protected buildDefaultEmptyCategoryFromApiCategoryStats(apiStats: IApiCategoryStats[]) {
        const label = apiStats.find(stats => stats.category !== null)?.category?.label

        let emptyCategory: IAnalysisCategory | ISelectOption
        if (typeof label === "object")
            emptyCategory = this.defaultNoAnswerSelectOption(Object.keys(label))
        else // undefined included here
            emptyCategory = this.defaultNoAnswerAnalysisCategory()

        return emptyCategory
    }

    protected parseNoAnswerCategoryForAnalysisStats(arrStats: IApiCategoryStats<IAnalysisCategory>[]) {
        return arrStats.map(stats => {
            const newStats: ICategoryStats<IAnalysisCategory> = {
                ...stats,
                category: stats.category ?? this.defaultNoAnswerAnalysisCategory()
            }

            return newStats
        })
    }

    protected parseNoAnswerCategoryForQuestionSelectOptions(arrOptionStats: ICategoryStats<ISelectOption>[]) {
        const supportedLanguages = Object.keys(
            arrOptionStats.find(stats => !!stats.category)?.category.label ?? { en: "" }
        )

        return arrOptionStats.map(stats => {
            const newStats: ICategoryStats<ISelectOption> = {
                ...stats,
                category: stats.category ?? this.defaultNoAnswerSelectOption(supportedLanguages)
            }

            return newStats
        })
    }

    protected parseNoAnswerCategoryForAnalysisResults(analysisResults: IAnalysesStatsResponse["analysis_results"]) {
        return Object.entries(analysisResults).reduce((prev, [analysisId, analysisStats]) => {
            prev[analysisId] = {
                stats: this.parseNoAnswerCategoryForAnalysisStats(analysisStats.stats),
                interview_count: analysisStats.interview_count
            }
            return prev
        }, {} as IProjectResults["analysis_results"])
    }

    protected parseNoAnswerCategoryForQuestionResults(questionResults: IAnalysesStatsResponse["question_results"]) {
        return Object.entries(questionResults)
            .reduce((prev, [iqId, questionStats]) => {
                if (!Array.isArray(questionStats.stats)) {
                    prev[iqId] = questionStats
                    return prev
                }

                prev[iqId] = {
                    stats: this.parseNoAnswerCategoryForQuestionSelectOptions(questionStats.stats),
                    interview_count: questionStats.interview_count
                }

                return prev
            }, {} as IProjectResults["question_results"])
    }

    protected parseNoAnswerCategoryForSegmentedStats(segmentedStats: IApiSegmentedStats[]): ISegmentedStats[] {
        const emptySegmentCategory = this.buildDefaultEmptyCategoryFromApiCategoryStats(
            segmentedStats.map(stats => stats.segment)
        )

        const emptySegmentedCategory = this.buildDefaultEmptyCategoryFromApiCategoryStats(
            segmentedStats.map(stats => stats.segmented).filter(stats => Array.isArray(stats)).flat()
        )

        return segmentedStats.map(stats => ({
            segment: {
                ...stats.segment,
                category: stats.segment.category ?? emptySegmentCategory
            },
            segmented: !Array.isArray(stats.segmented) ? stats.segmented : stats.segmented.map(
                segmentedItem => ({
                    ...segmentedItem,
                    category: segmentedItem.category ?? emptySegmentedCategory
                })
            )
        }))
    }
}