import { useState, useMemo, useCallback } from "react"
import { copy, getCopy } from "@utils/Copy"
import ErrorLevel from "@utils/ErrorLevel"

import { useGlautAuthInfo } from "@hooks/useGlautAuthInfo"
import { useToast } from "@contexts/ToastProvider"

import Members from "@components/Auth/Members"
import DeletePopup from "@components/layouts/DeletePopup"
import GlautButtonSecondary from "@components/Buttons/GlautButtonSecondary"
import { useOrganizationService } from "@hooks/services/useOrganizationService"
import { useNavigate } from "react-router-dom"

const roles = [
    {
        slug: "owner",
        title: "Owner",
        description: "Can edit projects and workspaces and add or remove users"
    },
    {
        slug: "member",
        title: "Member",
        description: "Can't edit projects until they are assigned to a workspace"
    }
]

export default function Settings() {
    const {
        currentOrg,
        currentOrgUsers,
        isSuperuser,
        inviteOrgUser,
        changeOrgUserRole,
        removeOrgUser,
        currentOrgIsAtLeastRole
    } = useGlautAuthInfo()
    // #region Hooks
    const navigate = useNavigate()
    const { addToast } = useToast()
    // #endregion

    // #region State
    const [openDelete, setOpenDelete] = useState(false)
    const [loading, setLoading] = useState(false)
    // #endregion

    // #region Memo
    const canEdit = useMemo(
        () => currentOrgIsAtLeastRole("owner"),
        [currentOrgIsAtLeastRole]
    )
    const canDelete = useMemo(
        () => canEdit && isSuperuser,
        [canEdit, isSuperuser]
    )
    // #endregion

    // #region Services
    const organizationService = useOrganizationService()
    // #endregion

    // #region Callbacks
    const inviteUser = useCallback((email, role) => {
        setLoading(true)
        return inviteOrgUser(email, role).then(() => {
            addToast(getCopy(copy.org.inviteSent)?.(email) ?? "")
            setLoading(false)
        }).catch(e => {
            console.log(e)
            setLoading(false)
            addToast(getCopy(copy.org.inviteUserError) ?? "", "", ErrorLevel.Error)
        })
    }, [addToast, inviteOrgUser])

    const changeUserRole = useCallback((email, role) => {
        setLoading(true)
        return changeOrgUserRole(email, role).then(() => {
            setLoading(false)
        }).catch(e => {
            console.log(e)
            setLoading(false)
            addToast(getCopy(copy.org.changeUserRoleError) ?? "")
        })
    }, [addToast, changeOrgUserRole])

    const removeUser = useCallback(email => {
        setLoading(true)
        return removeOrgUser(email).then(() => {
            setLoading(false)
        }).catch(e => {
            console.log(e)
            setLoading(false)
            addToast(getCopy(copy.org.removeUserError) ?? "")
        })
    }, [addToast, removeOrgUser])
    // #endregion

    return (
        <div id="org-settings" className="flex flex-col mt-24 gap-8 text-[#565656]">
            <Members
                users={currentOrgUsers}
                defaultRole="member"
                roles={roles}
                canEdit={canEdit}
                inviteUser={inviteUser}
                changeUserRole={changeUserRole}
                removeUser={removeUser}
                loading={loading}
                copy={copy.org}
            />
            {canDelete && <div
                id="danger-zone"
                className="flex flex-row justify-between items-center p-4 rounded-md bg-[#FBD7D7] mb-20"
            >
                {getCopy(copy.org.deleteThis)}
                <GlautButtonSecondary onClick={() => setOpenDelete(true)}>
                    {getCopy(copy.org.deleteButton)}
                </GlautButtonSecondary>
            </div>}
            {openDelete && currentOrg && (
                <DeletePopup
                    confirmString={currentOrg.name}
                    entityName="organization"
                    close={() => setOpenDelete(false)}
                    onConfirm={() => {
                        organizationService.deleteOrganization({ organizationId: currentOrg._id }).then(() => {
                            localStorage.removeItem("organizationId")
                            setOpenDelete(false)
                            navigate("/")
                        })
                    }}
                />
            )}
        </div>
    )
}
