import { draggable, dropTargetForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter"
import { normalizeToColorIndex } from "@utils/styling/colors-from-index"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { BiDotsVertical } from "react-icons/bi"
import { FaHashtag, FaTags } from "react-icons/fa"
import { useAnalysisTabAnalysisColDraggable } from "../../hooks/useAnalysisTabAnalysisColDraggable"
import AnalysisTabAnalysisColThemeAndCodeActionsMenu from "../AnalysisTabAnalysisColThemeAndCodeActionsMenu"
import Code from "../AnalysisTabAnalysisColThemeCode"
import { useAnalysisTab, useAnalysisTabDispatch } from "../../contexts/AnalysisTabProvider"
import { ICategoryStats } from "src/@types/processing/statistics"
import { IAnalysisCategory } from "src/@types/analysis"

interface IAnalysisTabAnalysisColThemeSubThemeProps {
    themeId: string
    subThemeStats: ICategoryStats<IAnalysisCategory>
    colorIndex: number
    withActionsMenu?: boolean
    isFromLastTheme?: boolean
}

export default function AnalysisTabAnalysisColThemeSubTheme({
    colorIndex,
    themeId,
    subThemeStats,
    isFromLastTheme = false,
    withActionsMenu = false
}: Readonly<IAnalysisTabAnalysisColThemeSubThemeProps>) {
    // #region Contexts
    const { selectedCategory } = useAnalysisTab()
    const analysisTabDispatch = useAnalysisTabDispatch()
    // #endregion

    // #region States
    const [isBeingDragged, setIsBeingDragged] = useState(false)
    const [isBeingUsedAsDroppable, setIsBeingUsedAsDroppable] = useState(false)
    const [isShowingActionsMenu, setIsShowingActionsMenu] = useState(false)
    // #endregion

    // #region Refs
    const actionsMenuAnchorRef = useRef<HTMLDivElement>(null)
    const draggableDivRef = useRef<HTMLDivElement>(null)
    const droppableDivRef = useRef<HTMLDivElement>(null)
    // #endregion

    // #region Analysis column hooks
    const { isAllowedToDrag } = useAnalysisTabAnalysisColDraggable()
    // #endregion

    // #region Memos
    const droppableContainerClassNames = useMemo(
        () => {
            if (!isAllowedToDrag || !isBeingUsedAsDroppable) return ""
            return "border-1 border-dashed border-glaut-stroke-button rounded-[0.25em] m-1 pb-1"
        },
        [isAllowedToDrag, isBeingUsedAsDroppable]
    )

    const isSelected = useMemo(
        () => selectedCategory?.category.id === subThemeStats.category.id,
        [selectedCategory?.category.id, subThemeStats.category.id]
    )
    // #endregion

    // #region Callbacks
    const handleContainerClick = useCallback(() => {
        if (selectedCategory?.category.id === subThemeStats.category.id) {
            analysisTabDispatch({ type: "clear-selected-category" })
            return
        }

        analysisTabDispatch({ type: "set-selected-category", category: subThemeStats })
    }, [analysisTabDispatch, selectedCategory?.category.id, subThemeStats])
    // #endregion

    // #region Effects

    // onRenderTurnIntoDraggable
    useEffect(() => draggable({
        element: draggableDivRef.current!,
        getInitialData: () => ({ stats: subThemeStats }),
        canDrag: () => isAllowedToDrag,
        onGenerateDragPreview: () => setIsBeingDragged(true),
        onDrop: () => setIsBeingDragged(false)
    }), [subThemeStats, isAllowedToDrag])


    // onRenderTurnIntoDroppable
    useEffect(() => dropTargetForElements({
        element: droppableDivRef.current!,
        onDragEnter: ({ source }) => {
            const categoryStats = source.data.stats as ICategoryStats<IAnalysisCategory>

            // if theme => cannot drop on itself
            const categoryId = categoryStats.category.id
            // if code => cannot drop on parent (= subtheme)
            const parentCategoryId = categoryStats.category.parent

            setIsBeingUsedAsDroppable(![categoryId, parentCategoryId].includes(subThemeStats.category.id))
        },
        getData: () => ({ stats: subThemeStats }),
        onDragLeave: () => setIsBeingUsedAsDroppable(false),
        onDrop: () => setIsBeingUsedAsDroppable(false),
        canDrop: () => isAllowedToDrag
    }), [isAllowedToDrag, subThemeStats])

    // #endregion

    // #region Element memos
    const codesElement = useMemo(
        () => [...subThemeStats.children]
            .filter(codeStats => codeStats.children.length === 0)
            .sort((a, b) => b.occurrences - a.occurrences)
            .map(codeStats => (
                <Code
                    key={codeStats.category.id}
                    themeId={subThemeStats.category.id}
                    codeStats={codeStats}
                    colorIndex={colorIndex}
                    withActionsMenu
                    isFromLastTheme={isFromLastTheme}
                />
            )),
        [colorIndex, isFromLastTheme, subThemeStats.category.id, subThemeStats.children]
    )
    // #endregion

    return (
        <div
            className={`flex flex-col
                ${droppableContainerClassNames}
            `}
            ref={droppableDivRef}
        >
            <div
                className={`flex p-[0.25em] m-[0.5em] justify-between items-center group/subtheme rounded-[0.25em]
                    border-1 hover:bg-glaut-cards 
                    ${isSelected ? "border-glaut-light-grey" : "border-transparent"}    
                `}
                ref={draggableDivRef}
                onClick={handleContainerClick}
            >
                <div className="flex gap-[0.5em] items-center" ref={actionsMenuAnchorRef}>
                    <FaTags
                        className="h-[1em] w-[1em]"
                        style={{ color: `var(--color${normalizeToColorIndex(colorIndex)})` }}
                    />
                    <p className="text-[13.33px] font-medium text-glaut-text-midnight">
                        {subThemeStats.category.label}
                    </p>
                    <BiDotsVertical
                        className={`h-[1em] w-[1em] text-glaut-light-grey cursor-pointer
                            group-hover/subtheme:text-glaut-dark-grey
                        `}
                        onClick={e => {
                            e.stopPropagation()
                            setIsShowingActionsMenu(true)
                        }}
                    />
                </div>
                {!isBeingDragged && (
                    <div className="flex items-center">
                        <FaHashtag className="w-[0.875em] h-[0.875em] text-glaut-grey mb-[0.20em]" />
                        <p className="text-[19.2px] text-glaut-grey">
                            {subThemeStats.occurrences}
                        </p>
                    </div>
                )}
            </div>

            {!isBeingDragged && (
                <div className="flex flex-row flex-wrap px-[0.75em] pb-[0.5em]" style={{ gap: "0.625em" }}>
                    {codesElement}
                </div>
            )}

            {withActionsMenu && (
                <AnalysisTabAnalysisColThemeAndCodeActionsMenu
                    isOpen={isShowingActionsMenu}
                    onClose={() => { setIsShowingActionsMenu(false) }}
                    anchorRef={actionsMenuAnchorRef}
                    categoryStats={subThemeStats}
                    isSubTheme
                    parentCategoryId={themeId}
                    openPlacement={isFromLastTheme ? "up" : "down"}
                />
            )}
        </div>
    )
}