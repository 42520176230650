import GlautWideLogo from "@assets/glaut-wide-logo.svg"
import GlautButtonSecondary from "@components/Buttons/GlautButtonSecondary"
import { MdOutlineFileDownload } from "react-icons/md"

interface IReportDetailsInsightBlockDownloadBarProps {
    onDownload: () => void
    showDownloadButton?: boolean
}

export default function ReportDetailsInsightBlockDownloadBar({
    onDownload,
    showDownloadButton = true
}: Readonly<IReportDetailsInsightBlockDownloadBarProps>) {
    return (
        <div className="flex justify-between items-center">
            <div className="flex items-center gap-[0.3125em]">
                <p className="text-[11.11px] font-medium text-glaut-light-grey">
                    Built with
                </p>
                <img src={GlautWideLogo} alt="glaut" />
            </div>
            {showDownloadButton && (
                <GlautButtonSecondary onClick={onDownload}>
                    <MdOutlineFileDownload className="w-[1.125em] h-[1.125em]" />
                    <p className="text-[11.11px] font-medium">Download</p>
                </GlautButtonSecondary>
            )}
        </div>
    )
}