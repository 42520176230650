import { CiViewTable } from "react-icons/ci"
import GlautLogo from "@assets/GlautLogo.png"
import GlautButtonSecondary from "@components/Buttons/GlautButtonSecondary"
import { copy, getCopy } from "@utils/Copy"

interface ISourceChooserProps {
    handleSelectSource: (source: "interview" | "csv") => void
    handleDeleteProject: () => void
}

export default function SourceChooser({ handleSelectSource, handleDeleteProject }: Readonly<ISourceChooserProps>) {
    return (
        <div className="column" style={{ maxWidth: "600px", margin: "auto" }}>
            <div className="box clickable column" onClick={() => handleSelectSource("interview")}>
                <div className="bar">
                    <img src={GlautLogo} className="large-icon" />
                    <h2>New Research Project</h2>
                </div>
                <p>Define the outline and run interviews</p>
            </div>
            <div className="box clickable column" onClick={() => handleSelectSource("csv")}>
                <div className="bar">
                    <CiViewTable className="large-icon" />
                    <h2>Import a CSV</h2>
                </div>
                <p>Import a file containing open-ends and analyze its content</p>
            </div>
            <GlautButtonSecondary onClick={handleDeleteProject} className="mt-[3em]">
                {getCopy(copy.words.cancel)}
            </GlautButtonSecondary>
        </div>
    )
}