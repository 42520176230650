import { IAnalysisCategory } from "@/@types/analysis"
import GlautButtonSecondary from "@components/Buttons/GlautButtonSecondary"
import { copy, getCopy } from "@utils/Copy"
import { normalizeToColorIndex } from "@utils/styling/colors-from-index"
import { forwardRef, Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { FaTag } from "react-icons/fa"
import { MdAddCircle, MdClose, MdEditNote, MdInfoOutline } from "react-icons/md"
import { v4 as uuid } from "uuid"

export interface IGlautIACategoriesRef {
    categories: IAnalysisCategory[]
    setCategories?: (categories: IAnalysisCategory[]) => void
}

type IGlautIACategoriesProps = {
    categoryColorIndexes: { [themeId: string]: number }
} & (
        {
            /**
            * When defined with `onChange`, it enters in controlled state.
            */
            value: IAnalysisCategory[]
            /**
             * When defined with `value`, it enters in controlled state.
             */
            onChange: (categories: IAnalysisCategory[]) => void
        } | {
            value: undefined
            onChange: undefined
        }
    )

const GlautIACategories = forwardRef<IGlautIACategoriesRef, Readonly<IGlautIACategoriesProps>>(({
    categoryColorIndexes = {},
    value,
    onChange
}, ref) => {
    // #region States
    const [selectedCategoryId, setSelectedCategoryId] = useState<string>()
    const [categories, setCategories] = useState<IAnalysisCategory[]>(value ?? [])
    // #endregion

    // #region Refs
    const categoriesInputRefs = useRef<{ [id: string]: HTMLInputElement | null }>({})
    // #endregion

    // #region Memos
    const isAddingCategoryAllowed = useMemo(
        () => categories.every(c => c.label.length > 0 && c.description!.length > 0),
        [categories]
    )
    // #endregion

    // #region Callbacks
    const updateCategories = useCallback((newCategories: React.SetStateAction<IAnalysisCategory[]>) => {
        if (value === undefined || onChange === undefined) {
            // uncontrolled
            setCategories(newCategories)
            return
        }

        // controlled
        if (typeof newCategories === "function") {
            onChange(newCategories(categories))
            return
        }

        onChange(newCategories)
    }, [categories, onChange, value])

    const handleAddCategory = useCallback(() => {
        if (!isAddingCategoryAllowed) return
        const newId = uuid()
        updateCategories(categories => [...categories, { id: newId, label: "", description: "", parent: null }])
        setSelectedCategoryId(newId)

        // UX: auto focus on label input after creating category
        setTimeout(() => {
            categoriesInputRefs.current[newId]?.focus()
        }, 200)
    }, [isAddingCategoryAllowed, updateCategories])

    const handleEditCategoryProperty = useCallback(
        (categoryId: string, property: "label" | "description", content: string) => {
            const localCategories = [...categories]

            const index = localCategories.findIndex(c => c.id === categoryId)
            if (index === -1) return

            localCategories[index][property] = content
            updateCategories(localCategories)
        },
        [categories, updateCategories]
    )

    const handleRemoveCategory = useCallback((categoryId: string) => {
        updateCategories(categories => categories.filter(c => c.id !== categoryId))
    }, [updateCategories])

    const handleToggleCategoryIdSelection = useCallback((categoryId: string) => {
        if (!categories.some(c => c.id === categoryId)) return
        setSelectedCategoryId(currCategoryId => currCategoryId !== categoryId ? categoryId : undefined)
    }, [categories])

    const handleSetCategories = useCallback((categories: IAnalysisCategory[]) => {
        updateCategories(categories)
    }, [updateCategories])
    // #endregion

    // #region Effects

    // onRefDefinedUpdateItsValue
    useEffect(() => {
        if (!ref) return

        const valueRef: IGlautIACategoriesRef = {
            categories,
            setCategories: handleSetCategories
        }

        if (typeof ref === "object") {
            ref.current = valueRef
            return
        }

        ref(valueRef)
    }, [ref, categories, handleSetCategories])

    // onControlledUpdateInternalStateWithValue
    useEffect(() => {
        if (!value || !onChange) return

        setCategories(value)
    }, [onChange, value])

    // #endregion

    return (
        <div className="flex flex-col gap-[0.25em] items-center">
            <div className="flex gap-[0.25em] items-center self-start">
                <p className="text-[13.33px] text-glaut-dark-grey">
                    {getCopy(copy.coding.addEditOrDeleteCategoriesDirectly)}
                </p>
                <MdInfoOutline
                    className="h-[1em] w-[1em] text-glaut-dark-grey"
                    data-tooltip-id="tooltip--glaut-app"
                    data-tooltip-content={
                        getCopy(copy.coding.glautWillUseTheseDescriptionsToClassifyResponses)
                    }
                    data-tooltip-place="right"
                    data-tooltip-class-name="max-w-[400px]"
                />
            </div>
            {categories.map((category, idx) => (
                <Fragment key={category.id}>
                    <div className="flex flex-row justify-between p-[0.5em] items-center w-full">
                        <div className="flex flex-row gap-[0.5em] items-center w-full">
                            <FaTag
                                className="h-[1em] w-[1em] text-glaut-light-grey"
                                style={{
                                    color: `var(--color${normalizeToColorIndex(
                                        categoryColorIndexes[category.id] ?? idx
                                    )})`
                                }}
                            />
                            <input
                                className={`text-[13.33px] font-medium border-0 rounded-none w-full p-0
                                    bg-transparent
                                    text-glaut-text-midnight
                                    placeholder:text-glaut-light-grey
                                    focus:shadow-none
                                `}
                                tabIndex={0}
                                placeholder={getCopy(copy.coding.writeANewCategoryHere) ?? ""}
                                value={category.label}
                                onChange={ev => {
                                    handleEditCategoryProperty(category.id, "label", ev.target.value)
                                }}
                                onFocus={() => { setSelectedCategoryId(category.id) }}
                                ref={element => { categoriesInputRefs.current[category.id] = element }}
                            />
                        </div>
                        <button
                            className="border-none rounded-none shadow-none p-0 text-sm mr-[0.375em] bg-transparent"
                            onClick={() => { handleToggleCategoryIdSelection(category.id) }}
                            tabIndex={-1}
                        >
                            <MdEditNote
                                className={`w-[1.125em] h-[1.125em]
                                    text-glaut-text-midnight
                                    ${selectedCategoryId === category.id ? "bg-glaut-stroke-glaut" : ""}
                                `}
                            />
                        </button>
                        <button
                            className="border-none rounded-none shadow-none p-0 text-sm bg-transparent"
                            onClick={() => { handleRemoveCategory(category.id) }}
                            tabIndex={-1}
                        >
                            <MdClose className="h-[1em] w-[1em] text-glaut-dark-grey" />
                        </button>
                    </div>
                    {selectedCategoryId === category.id && (
                        <div className="flex flex-col gap-[0.3125em] mt-[0.5em] pl-[2em] w-full">
                            <p className="text-[13.33px] text-glaut-dark-grey">
                                {getCopy(copy.coding.addDescription)}
                            </p>
                            <textarea
                                className={`text-[13.33px] font-medium rounded-[4px]
                                    text-glaut-text-midnight border-glaut-grey
                                    placeholder:text-glaut-grey
                                    focus:border-glaut-text-midnight
                                `}
                                tabIndex={0}
                                value={category.description ?? ""}
                                onChange={ev => {
                                    handleEditCategoryProperty(
                                        category.id,
                                        "description",
                                        ev.target.value
                                    )
                                }}
                                placeholder={getCopy(copy.coding.writeADescriptionHere) ?? ""}
                            />
                        </div>
                    )}
                </Fragment>
            ))}
            <div className="my-[0.5em]">
                <GlautButtonSecondary onClick={handleAddCategory} disabled={!isAddingCategoryAllowed}>
                    <div className="flex flex-row gap-[0.3125em] items-center">
                        <MdAddCircle
                            className="h-[1.25em] w-[1.25em] text-glaut-pink group-disabled:text-glaut-grey"
                        />
                        <p className="text-[13.33px] font-medium text-glaut-pink group-disabled:text-glaut-grey">
                            {getCopy(copy.coding.addCategory)}
                        </p>
                    </div>
                </GlautButtonSecondary>
            </div>
        </div>
    )
})

GlautIACategories.displayName = "GlautIACategories"

export default GlautIACategories