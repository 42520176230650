import { IScreeningQuestion, ISelectScreeningOption, ISelectScreeningQuestion } from "@/@types/interview-question"
import GlautButtonSecondary from "@components/Buttons/GlautButtonSecondary"
import GlautTogglesV2 from "@components/inputs/GlautTogglesV2"
import { copy, getCopy } from "@utils/Copy"
import { RecursivePartial } from "@utils/types/recursive-partial"
import { useCallback, useEffect } from "react"
import { MdAddCircle } from "react-icons/md"
import { v4 as uuid } from "uuid"
import EditScreeningScreener from "./components/EditScreeningScreener"
import { screeningOptions } from "./constants/screening-options"
import EditScreeningQuota from "./components/EditScreeningQuota"

interface IEditScreeningProps {
    question: RecursivePartial<IScreeningQuestion>
    setQuestion: React.Dispatch<React.SetStateAction<RecursivePartial<ISelectScreeningQuestion>>>
    disabled: boolean
    lang: string
}

export default function EditScreening({
    question,
    setQuestion,
    disabled,
    lang
}: Readonly<IEditScreeningProps>) {
    // #region Callbacks
    const handleSetScreeningType = useCallback((screeningType: IScreeningQuestion["screening_type"]) => {
        setQuestion({ ...question, screening_type: screeningType })
    }, [setQuestion, question])

    const handleAddOption = useCallback(() => {
        if (!question.screening_type) return

        const newOption = {
            id: uuid(),
            label: { [lang]: "" }
        } as Partial<ISelectScreeningOption>

        setQuestion({ ...question, options: [...(question.options ?? []), newOption] })
    }, [lang, question, setQuestion])
    // #endregion

    // #region Effects

    // onNoScreeningTypeSetToScreener
    useEffect(() => {
        if (!question.screening_type) setQuestion({ ...question, screening_type: "screener" })
        if (!question.options?.length) handleAddOption()
    }, [handleAddOption, question, setQuestion])

    // #endregion

    return (
        <div className="flex flex-col gap-[1.5rem]">
            <div className="pt-[0.75rem] px-[0.25rem]">
                <GlautTogglesV2
                    availableSelections={screeningOptions}
                    value={question.screening_type ?? "screener"}
                    disabled={disabled}
                    onChange={handleSetScreeningType}
                />
            </div>
            {question.screening_type === "screener" && (
                <EditScreeningScreener
                    question={question}
                    lang={lang}
                    disabled={disabled}
                    setQuestion={setQuestion}
                />
            )}
            {question.screening_type === "quota" && (
                <EditScreeningQuota
                    question={question}
                    lang={lang}
                    disabled={disabled}
                    setQuestion={setQuestion}
                />
            )}
            <div className="flex justify-center">
                <GlautButtonSecondary disabled={disabled} onClick={handleAddOption}>
                    <MdAddCircle className="w-[1.25rem] h-[1.25rem]" />
                    <p className="text-[13.33px] font-medium">
                        {getCopy(copy.actions.addOption)}
                    </p>
                </GlautButtonSecondary>
            </div>
        </div>
    )
}