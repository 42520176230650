import PreviewButton from "@components/Buttons/PreviewButton"
import { ChangeEvent as ReactChangeEvent, useCallback, useMemo, useRef, useState } from "react"


interface IProbingInstructionsTextAreaProps {
    placeholder: string
    disabled?: boolean
    probingInstructions: string
    setProbingInstructions: (probingInstructions: string) => void
    probingTemplates: [string, string][]
}

/** @deprecated */
export default function ProbingInstructionsTextArea({
    placeholder,
    disabled = false,
    probingInstructions,
    setProbingInstructions,
    probingTemplates
}: Readonly<IProbingInstructionsTextAreaProps>) {
    // #region Refs
    const textareaRef = useRef<HTMLTextAreaElement>(null)
    // #endregion

    // #region States
    const [focus, setFocus] = useState(false)
    const [templatePlaceholder, setTemplatePlaceholder] = useState<string | null>(null)
    // #endregion

    // #region Memo
    const probingTemplateHidden = useMemo(
        () => Boolean(probingInstructions.length),
        [probingInstructions]
    )
    // #endregion

    // #region Callbacks
    const handleChangeProbingInstructions = useCallback((e: ReactChangeEvent<HTMLTextAreaElement>) => {
        setProbingInstructions(e.target.value)
    }, [setProbingInstructions])
    const handleSelectTemplate = useCallback((template: string) => {
        if (!probingTemplateHidden) setProbingInstructions(template)
    }, [probingTemplateHidden, setProbingInstructions])
    // #endregion

    return (
        <div
            className={`text-[13.33px] font-medium text-glaut-text-midnight
                p-0 rounded-[0.25em] border-1
                bg-glaut-text-area-off-white border-glaut-light-grey
                ${disabled ? "border-glaut-cards" : "hover:border-glaut-pink"}
                ${focus ? "border-glaut-pink" : ""}
            `}
            onClick={() => !disabled && textareaRef.current?.focus()}
        >
            <textarea
                ref={textareaRef}
                className="bg-transparent border-0 focus:border-0 rounded-none placeholder:text-glaut-grey"
                placeholder={templatePlaceholder ?? placeholder}
                value={probingInstructions}
                onChange={handleChangeProbingInstructions}
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
                disabled={disabled}
            />
            <div
                className="flex gap-1 px-3 py-1"
            >
                {
                    !probingTemplateHidden && probingTemplates.map(([label, template]) => (
                        <PreviewButton
                            key={label}
                            disabled={disabled}
                            onPreview={show => setTemplatePlaceholder(show ? template : null)}
                            onClick={() => handleSelectTemplate(template)}
                        >{label}</PreviewButton>
                    ))
                }
            </div>
        </div>
    )
}
