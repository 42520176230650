import { useConversationOverlay } from "@components/ConversationOverlay/contexts/ConversationOverlayProvider"
import { useFormattedDate } from "@hooks/useFormattedDate"
import { RiCalendar2Fill } from "react-icons/ri"

export default function ConversationOverlayDate() {
    // #region Contexts
    const { interview } = useConversationOverlay()
    // #endregion

    // #region Formatting hooks
    const { text } = useFormattedDate(interview.last_updated)
    // #endregion

    return (
        <div className="flex gap-[0.5em] items-center my-[0.25em]">
            <RiCalendar2Fill className="h-[1em] w-[1em] text-glaut-grey" />
            <p className="text-[13.33px] text-glaut-midnight">
                {text}
            </p>
        </div>
    )
}