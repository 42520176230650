import { IMessagesProp } from "@components/ConversationTranscriptBox"
import { MdChevronLeft, MdChevronRight } from "react-icons/md"

interface IConversationTranscriptBoxCsvProps {
    identifier?: string
    lastUpdateDate?: string
    messagesElement: React.ReactNode[]
    customCodesElement: React.ReactNode
    currQuestion?: {
        iqIndex: number
        iqLabel: string
        iqType: string
    }
    currPage: number
    totalPages: number
    questionTypeLabel?: string
    handlePreviousPageClick: () => void
    handleNextPageClick: () => void
    messages: IMessagesProp
}

export default function ConversationTranscriptBoxCsv({
    identifier,
    messagesElement,
    customCodesElement,
    currQuestion,
    currPage,
    totalPages,
    questionTypeLabel,
    handlePreviousPageClick,
    handleNextPageClick,
    messages
}: Readonly<IConversationTranscriptBoxCsvProps>) {
    return (
        <div className="flex flex-col pt-[0.625em] pb-[0.75em] overflow-hidden max-h-full bg-glaut-off-white">
            <div className="flex flex-row mx-[0.75rem] px-[0.75rem] py-[0.25rem] justify-between rounded-[0.25rem]
                    bg-glaut-headers-grey">
                <div className="flex items-center gap-[0.625rem]">
                    <p className="text-[13.33px] underline text-glaut-off-white">
                        {identifier}
                    </p>
                </div>
            </div>
            {!Array.isArray(messages) && currQuestion && (
                <div className={`flex flex-col rounded-[0.25em] gap-[0.3125em] mx-[0.75em] px-[0.75em]
                    transition-all
                    bg-glaut-very-light-grey
                    h-full opacity-100 mt-[0.75em] py-[0.625em]
                `}>
                    <div className="flex flex-row justify-between items-center">
                        <div className="flex flex-row">
                            <p className="text-[11.11px] font-medium text-glaut-text-midnight mr-1">
                                {`Question ${currQuestion.iqIndex} - `}
                            </p>
                            <p className="text-[11.11px] font-medium text-glaut-stroke-button">
                                {questionTypeLabel}
                            </p>
                        </div>
                        <div className="flex flex-row gap-[0.5em] items-center">
                            <button
                                className="border-none shadow-none p-0 bg-transparent text-md"
                                onClick={handlePreviousPageClick}
                                disabled={currPage === 1}>
                                <MdChevronLeft
                                    className={`w-[1.125em] h-[1.125em] m-[0.25em] 
                                        ${currPage === 1 ? "text-glaut-grey" : "text-glaut-pink"}
                                    `}
                                />
                            </button>
                            <p className="text-[11.11px] font-medium text-glaut-text-midnight">
                                {`${currPage} of ${totalPages}`}
                            </p>
                            <button
                                className="border-none shadow-none p-0 bg-transparent text-md"
                                onClick={handleNextPageClick}
                                disabled={currPage === totalPages}>
                                <MdChevronRight
                                    className={`w-[1.125em] h-[1.125em] m-[0.25em] 
                                        ${(currPage === totalPages) ? "text-glaut-grey" : "text-glaut-pink"}
                                    `}
                                />
                            </button>
                        </div>
                    </div>
                    <p className="text-[13.33px] font-medium text-glaut-text-midnight">
                        {currQuestion.iqLabel}
                    </p>
                </div>
            )}
            <div className={`
                flex flex-col px-3 overflow-auto transition-all gap-2 h-full opacity-100 mt-[0.625em]
            `}>
                {...messagesElement}
            </div>
            {customCodesElement && (
                <div className="px-[0.75em] mt-[1em]">
                    {customCodesElement}
                </div>
            )}
        </div>
    )
}