import Button from "@components/layouts/Button"
import ConfirmModal from "@components/Modals/ConfirmModal"
import { copy, getCopy } from "@utils/Copy"
import { ChangeEvent, MouseEventHandler, useCallback, useRef, useState, MouseEvent as ReactMouseEvent } from "react"
import { MdClose } from "react-icons/md"

interface IGlautInputLogoProps {
    id?: string
    src?: string
    alt?: string
    title?: React.ReactNode
    label?: string
    onRemove?: MouseEventHandler<HTMLButtonElement>
    onSelect?: (ev: ChangeEvent<HTMLInputElement>) => void | Promise<void>
    onAfterSelect?: MouseEventHandler<HTMLButtonElement>
    disabled?: boolean
    confirmOnRemove?: boolean
}

/**
 * @see https://stackoverflow.com/questions/1077041/refresh-image-with-a-new-one-at-the-same-url
 */
export default function GlautInputLogo({
    id,
    src,
    alt,
    title,
    label,
    onRemove,
    onSelect,
    onAfterSelect,
    disabled = false,
    confirmOnRemove = true
}: Readonly<IGlautInputLogoProps>) {
    // #region Refs
    const imgRef = useRef<HTMLImageElement>(null)
    const onRemoveEvent = useRef<ReactMouseEvent<HTMLButtonElement, MouseEvent>>()
    // #endregion

    // #region States
    const [lastImgLoad, setLastImgLoad] = useState(new Date().getTime())
    const [isDeleteModalShown, setIsDeleteModalShown] = useState(false)
    // #endregion

    // #region Callbacks
    const handleSelect = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
        const result = onSelect?.(ev)
        if (result === undefined) {
            setLastImgLoad(new Date().getTime())
            return
        }

        result.then(() => { setLastImgLoad(new Date().getTime()) })
    }, [onSelect])

    const handleRemove: MouseEventHandler<HTMLButtonElement> = useCallback(ev => {
        if (!confirmOnRemove) {
            onRemove?.(ev)
            return
        }

        onRemoveEvent.current = ev
        setIsDeleteModalShown(true)
    }, [confirmOnRemove, onRemove])
    // #endregion

    return (
        <div id={id} className="flex flex-col gap-2">
            {title}
            {src && (
                <div className="flex justify-between items-center border rounded-[0.25em]
                    pl-[1.75em] pr-[0.75em] py-[0.625em]
                    bg-glaut-off-white border-glaut-stroke-glaut">
                    <div className="flex gap-[1em] items-center justify-center flex-1">
                        <img
                            ref={imgRef}
                            src={`${src}?t=${lastImgLoad}`} /** this bypasses browser caching */
                            alt={alt ?? "logo"}
                            className="max-h-10 max-w-36 min-w-3 border rounded-[0.25em] border-glaut-stroke-button"
                        />
                        {label && (
                            <p className="text-[13.33px] text-glaut-text-midnight">
                                {label}
                            </p>
                        )}
                    </div>
                    <button
                        className="border-none shadow-none rounded-none p-0 text-base"
                        onClick={handleRemove}
                        disabled={disabled}
                    >
                        <MdClose className="text-[#606060] w-[0.875em] h-[0.875em]" />
                    </button>
                </div>
            )}
            {!src && (
                <div className="flex flex-col border rounded-md bg-glaut-off-white px-3 py-2 gap-2">
                    <div className="border rounded-md bg-glaut-cards px-2 py-1">
                        <span className="text-sm font-bold">{getCopy(copy.org.uploadTitle)}</span>
                        <span className="text-xs">{getCopy(copy.org.uploadInfo)}</span>
                    </div>
                    <Button
                        label={getCopy(copy.org.uploadImage)}
                        acceptFile=".png, .gif"
                        handleFileChange={handleSelect}
                        onClick={onAfterSelect}
                        disabled={disabled}
                    />
                </div>
            )}
            <ConfirmModal
                open={isDeleteModalShown}
                onClose={() => { setIsDeleteModalShown(false) }}
                onConfirm={() => { onRemoveEvent.current && onRemove?.(onRemoveEvent.current) }}
            >
                <p className="text-[13.33px] font-medium text-glaut-text-midnight">
                    {getCopy(copy.outline.areYouSureYouWantToDeleteThisImageQuestion)}
                </p>
            </ConfirmModal>
        </div>
    )
}