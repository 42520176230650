import useAxiosAuth from "@hooks/useAxiosAuth"
import IWorkspaceGateway from "@services/workspaces/IWorkspaceGateway"
import WorkspacesApiService from "@services/workspaces/api/workspaces.api"
import { useMemo } from "react"

/**
 * Obtains the instance of the service to interact with projects.
 */
export function useWorkspaceService(): IWorkspaceGateway {
    const axiosInstance = useAxiosAuth()
    return useMemo(() => new WorkspacesApiService({ api: axiosInstance }), [axiosInstance])
}