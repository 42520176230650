import { ILang } from "@/@types/interview-question"
import { IProject } from "@/@types/project"

export const getLanguage = () => navigator.language
    || ("userLanguage" in navigator && typeof navigator.userLanguage === "string" && navigator.userLanguage)
    || navigator.languages[0]
    || ""

export function isILang(obj: unknown): obj is ILang {
    if (typeof obj !== "object" || obj === null || Array.isArray(obj)) return false
    if (Object.values(obj).some(val => typeof val !== "string")) return false

    return true
}

type ICopyObject<T> = { [lang: string]: T }
export function getLanguageKey<T>(
    obj?: ICopyObject<T>, lang?: string, defaultLang = "en"
): T | null {
    if (!obj) return null

    // If the language is not set use the browser language
    lang = ![undefined, ""].includes(lang) ? lang as string : getLanguage()

    let result: T | null = null

    // If a key for the given language exists return it
    if (obj[lang]) result = obj[lang]
    // If the language is in locale format (ex. en-GB) try to use the first 2 chars
    else if (obj[lang.substring(0, 2)]) result = obj[lang.substring(0, 2)]
    // If a key for the default language exists return it
    else if (obj[defaultLang]) result = obj[defaultLang]

    // If the key is an object, print all the values
    if (!result)
        for (const entry of Object.entries(obj))
            if (entry[1]) return entry[1]

    return result
}

export const getProjectLang = (proj: IProject | null) => proj?.general_settings?.language || "en"
