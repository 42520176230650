import { AxiosInstance } from "axios"
import IOrganizationGateway, { 
    IOrganizationGatewayChangeUserRoleRequest,
    IOrganizationGatewayCreateOrganizationRequest,
    IOrganizationGatewayCreateWorkspaceRequest,
    IOrganizationGatewayDeleteRequest,
    IOrganizationGatewayGetOrganizationRequest,
    IOrganizationGatewayGetUsersRequest,
    IOrganizationGatewayInviteUserRequest,
    IOrganizationGatewayRemoveLogoRequest,
    IOrganizationGatewayRemoveUserRequest,
    IOrganizationGatewaySubmitLogoRequest
} from "../IOrganizationGateway"
import { IOrganization, IOrganizationWithWorkspaces } from "src/@types/organization"
import { IOrganizationUser } from "src/@types/user"
import { IWorkspace } from "src/@types/workspace"

interface IOrganizationsApiServiceProps {
    api: AxiosInstance
}

export default class OrganizationsApiService implements IOrganizationGateway {
    private props: IOrganizationsApiServiceProps

    constructor(props: IOrganizationsApiServiceProps) {
        this.props = props
    }

    public async createOrganization(request: IOrganizationGatewayCreateOrganizationRequest): Promise<IOrganization> {
        const { name } = request
        return await this.props.api
            .post<IOrganization>("organizations/", {name})
            .then(res => res.data)
            .catch(e => {
                console.log(e)
                return Promise.reject(e)
            })
    }

    public async getOrganizations(): Promise<IOrganization[]> {
        return await this.props.api
            .get<IOrganization[]>("organizations/")
            .then(res => res.data)
            .catch(e => {
                console.log(e)
                return Promise.reject(e)
            })
    }

    public async getOrganization(
        request: IOrganizationGatewayGetOrganizationRequest
    ): Promise<IOrganizationWithWorkspaces> {
        const { organizationId } = request

        return await this.props.api
            .get<IOrganizationWithWorkspaces>(
                `organizations/${organizationId}`
            )
            .then(res => res.data)
            .catch(e => {
                console.log(e)
                return Promise.reject(e)
            })
    }

    public async getUsers(request: IOrganizationGatewayGetUsersRequest): Promise<IOrganizationUser[]> {
        const { organizationId } = request

        return await this.props.api
            .get<IOrganizationUser[]>(
                `organizations/${organizationId}/users`
            )
            .then(res => res.data)
            .catch(e => {
                console.log(e)
                return Promise.reject(e)
            })
    }

    public async deleteOrganization(request: IOrganizationGatewayDeleteRequest): Promise<null> {
        const { organizationId } = request

        return await this.props.api
            .delete<void>(`organizations/${organizationId}`)
            .then(() => null)
            .catch(e => {
                console.log(e)
                return Promise.reject(e)
            })
    }

    public async submitLogo(request: IOrganizationGatewaySubmitLogoRequest): Promise<IOrganization> {
        const { organizationId, imageFile } = request

        const formData = new FormData()
        formData.append("image", imageFile)

        return this.props.api
            .post<IOrganization>(`organizations/${organizationId}/upload-logo`, formData)
            .then(response => response.data)
            .catch(e => {
                console.log(e)
                return Promise.reject(e)
            })
    }
    
    public async removeLogo(request: IOrganizationGatewayRemoveLogoRequest): Promise<IOrganization> {
        const { organizationId } = request

        return this.props.api
            .patch<IOrganization>(`organizations/${organizationId}`, {logo: null})
            .then(response => response.data)
            .catch(e => {
                console.log(e)
                return Promise.reject(e)
            })
    }

    public async inviteUser(request: IOrganizationGatewayInviteUserRequest): Promise<IOrganizationUser> {
        const { organizationId, email, role } = request

        return this.props.api
            .put<IOrganizationUser>(`organizations/${organizationId}/invite`, {email, role})
            .then(response => response.data)
            .catch(e => {
                console.log(e)
                return Promise.reject(e)
            })
    }

    public async changeUserRole(request: IOrganizationGatewayChangeUserRoleRequest): Promise<IOrganizationUser> {
        const { organizationId, email, role } = request

        return this.props.api
            .put<IOrganizationUser>(
                `organizations/${organizationId}/change_role?email=${encodeURIComponent(email)}&role=${role}`
            )
            .then(response => response.data)
            .catch(e => {
                console.log(e)
                return Promise.reject(e)
            })
    }

    public async removeUser(request: IOrganizationGatewayRemoveUserRequest): Promise<null> {
        const { organizationId, email } = request

        return this.props.api
            .put<IOrganizationUser>(`organizations/${organizationId}/remove?email=${encodeURIComponent(email)}`)
            .then(() => null)
            .catch(e => {
                console.log(e)
                return Promise.reject(e)
            })
    }

    public async createWorkspace(request: IOrganizationGatewayCreateWorkspaceRequest): Promise<IWorkspace> {
        const { organizationId, workspaceName } = request

        return this.props.api
            .post<IWorkspace>(`organizations/${organizationId}/workspace`, {name: workspaceName})
            .then(response => response.data)
            .catch(e => {
                console.log(e)
                return Promise.reject(e)
            })
        
    }
}