import { MdOpenInNew } from "react-icons/md"
import { IInterview } from "src/@types/entry"
import { useProject, useProjectDispatch } from "../../contexts/ProjectProvider"
import { useMemo } from "react"

interface IDataTabTableRowItemIdProps {
    entry: IInterview
}

export default function DataTabTableRowItemId({
    entry
}: Readonly<IDataTabTableRowItemIdProps>) {
    // #region Contexts
    const { selectedInterview } = useProject()
    const projectDispatch = useProjectDispatch()
    // #endregion

    // #region Memos
    const isInterviewOpen = useMemo(() => selectedInterview?._id === entry._id, [entry._id, selectedInterview?._id])
    // #endregion

    return (
        <td style={{ borderRight: "0px", padding: "0" }}>
            <button
                className={`flex flex-row justify-between rounded-md px-[12px] py-[2px] align-center gap-2 group w-full
                    border-none shadow-none text-start text-base m-0 transition-none
                    ${isInterviewOpen ? "bg-glaut-midnight" : "bg-white"}
                    hover:bg-glaut-midnight
                `}
                onClick={() => { projectDispatch({ type: "select-interview", interview: entry }) }}
            >
                <span className={`text-[13.33px] text-ellipsis overflow-hidden whitespace-nowrap
                    ${isInterviewOpen ? "text-white" : "text-glaut-midnight"}
                    group-hover:text-white
                `}>
                    {entry.identifier}
                </span>
                <div className="flex flex-col justify-center">
                    <MdOpenInNew className={`align-middle w-[14px] h-[14px]
                        ${isInterviewOpen ? "text-white" : "text-glaut-text-midnight"}
                        group-hover:text-white
                    `} />
                </div>
            </button>
        </td>
    )
}