import { copy, getCopy } from "@utils/Copy"
import { ChangeEventHandler, useCallback } from "react"
import { useOutlineTab } from "../../contexts/OutlineTabProvider"
import { useLegacyProject } from "../../contexts/ProjectProvider"
import OutlineTabContentDefaultToggle from "../OutlineTabContentDefaultToggle"

export default function OutlineTabContentOverviewModeratorVoice() {
    // #region Contexts
    const { project, setProject } = useLegacyProject()
    const { canEdit } = useOutlineTab()
    // #endregion

    // #region Callbacks
    const handleToggle: ChangeEventHandler<HTMLInputElement> = useCallback(ev => {
        setProject(prev => {
            if (!prev || prev.source === "CSV" || !prev.interview_settings) return prev

            return {
                ...prev,
                interview_settings: { ...prev.interview_settings, voice_enabled: ev.target.checked }
            }
        })
    }, [setProject])
    // #endregion

    if (project?.source !== "interview") return <></>

    return (
        <OutlineTabContentDefaultToggle
            onChange={handleToggle}
            checked={!!project.interview_settings?.voice_enabled}
            disabled={!canEdit}
            label={getCopy(copy.outline.overview.moderatorVoice) ?? ""}
            tooltipContent={getCopy(copy.outline.overview.engageWithTheModeratorAsInAReal) ?? ""}
        />
    )
}