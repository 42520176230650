import GlautButtonPrimary from "@components/Buttons/GlautButtonPrimary"
import GlautButtonSecondary from "@components/Buttons/GlautButtonSecondary"
import { copy, getCopy } from "@utils/Copy"

interface IConfirmModalProps {
    open: boolean
    onClose?: () => void
    onConfirm?: () => void
    children: React.ReactNode
}

export default function ConfirmModal({
    open,
    onClose,
    onConfirm,
    children
}: Readonly<IConfirmModalProps>) {
    return (
        <div
            className={`
                confirm-modal fixed inset-0 flex justify-center items-center transition-colors overflow-hidden z-50
                ${open ? "visible bg-black/20" : "invisible"}
            `}
            onClick={onClose}
        >
            <div
                onClick={e => e.stopPropagation()}
                className={`
                    flex flex-col bg-white rounded-[0.25em] shadow px-[1.5em] py-[1.125em] gap-3 transition-all
                    max-w-[270px] border-1
                    border-glaut-grey
                    ${open ? "scale-100 opacity-100" : "scale-125 opacity-0"}
                `}
            >
                <div className="flex justify-center items-center text-center">
                    {children}
                </div>
                <div className="flex flex-row justify-between">
                    <GlautButtonSecondary onClick={onClose}>
                        {getCopy(copy.actions.cancel)}
                    </GlautButtonSecondary>
                    <GlautButtonPrimary onClick={() => {
                        onConfirm?.()
                        onClose?.()
                    }}>
                        {getCopy(copy.actions.delete)}
                    </GlautButtonPrimary>
                </div>
            </div>
        </div>
    )
}