import GlautButtonPrimary from "@components/Buttons/GlautButtonPrimary"
import GlautButtonSecondary from "@components/Buttons/GlautButtonSecondary"
import ModalLayout from "@components/layouts/ModalLayout"
import { copy, getCopy } from "@utils/Copy"
import { normalizeToColorIndex } from "@utils/styling/colors-from-index"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { FaTag } from "react-icons/fa"
import { MdAddCircle, MdClose, MdRemoveCircle } from "react-icons/md"
import { SelectInstance } from "react-select"
import { v4 as uuid } from "uuid"
import { useAnalysisTab, useAnalysisTabDispatch } from "../../contexts/AnalysisTabProvider"
import QuestionSelect from "../AnalysisTabAnalysisColFilteringModalQuestionSelect"
import QuestionSelectLabel from "../AnalysisTabAnalysisColFilteringModalQuestionSelectLabel"
import QuestionValueSelect from "../AnalysisTabAnalysisColFilteringModalQuestionValueSelect"
import QuestionValueSelectLabel from "../AnalysisTabAnalysisColFilteringModalQuestionValueSelectLabel"
import { useSortedIqsAndAnalyses } from "../AnalysisTabQuestionsCol/hooks/useSortedIqsAndAnalyses"
import { IQuestionItemCategory } from "../AnalysisTabQuestionsCol/utils/types"
import { isQuestionItemAnAnalysis } from "../AnalysisTabQuestionsCol/utils/is-question-item-an-analysis"
import AnalysisSelectLabel from "../AnalysisTabAnalysisColFilteringModalAnalysisSelectLabel"
import { ISavingFilters } from "./@types/saving-filters"
import { IFilterItem } from "./@types/filter-item"
import { PartiallyNullable } from "@utils/types/partially-optional"

interface IAnalysisTabAnalysisColFilteringModalProps {
    isOpen: boolean
    onClose?: () => void
}

// @TODO: work with a particular provider that manages the global state of the modal (as there are several complex
// logics to manage select-in-select and reload previously selected options).
export default function AnalysisTabAnalysisColFilteringModal({
    isOpen,
    onClose
}: Readonly<IAnalysisTabAnalysisColFilteringModalProps>) {
    // #region Contexts
    const { analysisFilters, questionFilters } = useAnalysisTab()
    const analysisTabDispatch = useAnalysisTabDispatch()
    // #endregion

    // #region States
    const [selectedItems, setSelectedItems] = useState<PartiallyNullable<IFilterItem, "type">[]>([])
    // #endregion

    // #region Refs
    const questionValueRefs = useRef<{
        [entryId: string]: {
            question?: SelectInstance<{ label: React.ReactNode; value: string }>
            questionValue?: SelectInstance<{ label: React.ReactNode; value: string }, true>
        }
    }>({})
    // #endregion

    // #region Helper hooks
    const { sortedIqsAndAnalysesForFiltering, sortedInterviewQuestions } = useSortedIqsAndAnalyses()
    // #endregion

    // #region Memos
    const formattedEligibleItemsOptions = useMemo(
        () => sortedIqsAndAnalysesForFiltering.reduce((prev, curr) => {
            prev[curr.id] = {
                categories: curr.categories.map((option, optionIndex) => ({ ...option, index: optionIndex }))
            }

            return prev
        }, {} as { [itemId: string]: { categories: (IQuestionItemCategory & { index: number })[] } }),
        [sortedIqsAndAnalysesForFiltering]
    )
    const canAddNewItems = useMemo(
        () => !selectedItems.some(item => item.itemId === "")
            && selectedItems.length !== sortedIqsAndAnalysesForFiltering.length,
        [selectedItems, sortedIqsAndAnalysesForFiltering]
    )
    const isSavingAllowed = useMemo(
        () => (selectedItems.length === 1 && selectedItems[0].itemId === "") ||
            (selectedItems.length > 0),
        [selectedItems]
    )
    // #endregion

    // #region Callbacks
    const updateSelectedIqsFromCurrentFilters = useCallback(() => {
        const analysisFiltersEntries = Object.entries(analysisFilters)
        const questionFiltersEntries = Object.entries(questionFilters)

        if (analysisFiltersEntries.length === 0 && questionFiltersEntries.length === 0) {
            setSelectedItems([{ itemId: "", entryId: uuid(), chosenOptionIds: [], type: null }])
            return
        }

        const updatedSelectedItems: typeof selectedItems = [
            ...analysisFiltersEntries.map(([itemId, itemOptions]) => ({
                itemId,
                chosenOptionIds: itemOptions.map(option => option.optionId),
                entryId: uuid(),
                type: "analysis" as const
            })),
            ...questionFiltersEntries.map(([itemId, itemOptions]) => ({
                itemId,
                chosenOptionIds: itemOptions.map(option => option.optionId),
                entryId: uuid(),
                type: "iq" as const
            }))
        ]

        setSelectedItems(updatedSelectedItems)
    }, [analysisFilters, questionFilters])

    const handleAddNewItem = useCallback(() => {
        if (!canAddNewItems) return
        setSelectedItems(prev => [...prev, { itemId: "", entryId: uuid(), chosenOptionIds: [], type: null }])
    }, [canAddNewItems])

    const handleCloseModal = useCallback(() => {
        onClose?.()
    }, [onClose])

    const handleSaveFilters = useCallback(() => {
        const filters = selectedItems.reduce((prev, currSelectedItem) => {
            if (currSelectedItem.chosenOptionIds.length === 0 || !currSelectedItem.type) return prev

            if (!prev[currSelectedItem.type]) prev[currSelectedItem.type] = {}

            prev[currSelectedItem.type][currSelectedItem.itemId] = currSelectedItem.chosenOptionIds.map(
                optionId => ({ optionId })
            )

            return prev
        }, { analysis: {}, iq: {} } as ISavingFilters)

        analysisTabDispatch({
            type: "set-analysis-filtering",
            analysisFilters: filters.analysis,
            questionFilters: filters.iq
        })
        handleCloseModal()
    }, [analysisTabDispatch, selectedItems, handleCloseModal])

    const handleRemoveOption = useCallback((selectedItem: { index: number, entryId: string }, optionId: string) => {
        setSelectedItems(prev => {
            const newSelectedItems = [...prev]

            // Update chosen options in state
            const newChosenOptionIds = newSelectedItems[selectedItem.index].chosenOptionIds.filter(
                id => id !== optionId
            )
            newSelectedItems[selectedItem.index].chosenOptionIds = newChosenOptionIds

            // Update chosen options in ref
            const newValues = questionValueRefs.current[selectedItem.entryId]
                ?.questionValue
                ?.getValue()
                .filter(v => v.value !== optionId) ?? []

            if (newChosenOptionIds.length > 0) {
                questionValueRefs.current[selectedItem.entryId]?.questionValue?.setValue(newValues, "select-option")
                return newSelectedItems
            }

            // If 2+ questions => remove from list; otherwise => just clear the values
            if (newSelectedItems.length > 1) {
                newSelectedItems.splice(selectedItem.index, 1)
            }
            else {
                newSelectedItems[selectedItem.index] = {
                    ...newSelectedItems[selectedItem.index],
                    itemId: "",
                    chosenOptionIds: []
                }

                questionValueRefs.current[selectedItem.entryId]?.question?.clearValue()
            }

            return newSelectedItems
        })
    }, [])
    // #endregion

    // #region Effects

    // onOpenUpdateCurrentSelectedIqs
    useEffect(() => {
        if (!isOpen) return

        updateSelectedIqsFromCurrentFilters()
    }, [isOpen, updateSelectedIqsFromCurrentFilters])

    // #endregion

    // #region Element memos
    const eligibleItemsForSelectionAsOptionMap = useMemo(
        () => sortedIqsAndAnalysesForFiltering
            .map((item, itemIdx) => isQuestionItemAnAnalysis(item) ? ({
                id: item.id,
                value: item.id,
                label: (
                    <AnalysisSelectLabel
                        index={itemIdx}
                        label={item.title ?? (
                            item.sources.length === 1 ? (sortedInterviewQuestions.find(
                                iq => iq.id === item.sources[0]
                            )?.title ?? item.goal) : item.goal
                        )}
                        type={item.type}
                    />
                )
            }) : ({
                id: item.id,
                value: item.id,
                label: (
                    <QuestionSelectLabel
                        index={itemIdx}
                        label={item.title}
                        domain={item.domain}
                        type={item.type}
                    />
                )
            })),
        [sortedIqsAndAnalysesForFiltering, sortedInterviewQuestions]
    )
    const eligibleItemsOptionsAsOptionMap = useMemo(
        () => sortedIqsAndAnalysesForFiltering.reduce((prev, curr) => {
            prev[curr.id] = curr.categories.map((category, idx) => ({
                value: category.id,
                label: (
                    <QuestionValueSelectLabel
                        colorIndex={idx}
                        label={category.label}
                    />
                )
            }))

            return prev
        }, {}) as { [questionId: string]: { value: string, label: React.ReactNode }[] },
        [sortedIqsAndAnalysesForFiltering]
    )
    // #endregion

    return (
        <ModalLayout
            isOpen={isOpen}
            onClose={onClose}
            className={`flex flex-col items-center rounded-[0.25em] py-[0.625em] px-[1em] overflow-y-visible 
                w-[552px] max-h-screen no-scrollbar transition-all border-1
                shadow-[0px_0px_8px_0px_rgb(0,0,0,0.15)] bg-white border-glaut-stroke-glaut
                ${isOpen ? "scale-100 opacity-100" : "scale-125 opacity-0"}
            `}
        >
            <p className="text-[13.33px] font-medium text-[rgb(0,0,0,0.6)] self-start">
                {getCopy(copy.coding.filterBy)}
            </p>
            {selectedItems.map((selectedItem, selectedItemIdx) => (
                <div key={selectedItem.entryId} className="w-full flex flex-col gap-[0.5em] mb-[0.5em]">
                    <div className="flex flex-row items-center w-full">
                        <QuestionSelect
                            ref={element => {
                                questionValueRefs.current[selectedItem.entryId] = {
                                    ...questionValueRefs.current[selectedItem.entryId],
                                    question: element ?? undefined
                                }
                            }}
                            options={
                                // filter previously selected IQs
                                eligibleItemsForSelectionAsOptionMap.filter(
                                    item => !selectedItems.some(prevSelectedItem => prevSelectedItem.itemId === item.id)
                                )
                            }
                            value={eligibleItemsForSelectionAsOptionMap.find(
                                itemOption => itemOption.id === selectedItem.itemId
                            )}
                            onSelect={value => {
                                setSelectedItems(prev => {
                                    const questionItem = sortedIqsAndAnalysesForFiltering.find(
                                        item => item.id === value
                                    )

                                    if (!questionItem) return prev

                                    const newSelectedItems = [...prev]

                                    newSelectedItems[selectedItemIdx] = {
                                        ...newSelectedItems[selectedItemIdx],
                                        itemId: value ?? "",
                                        chosenOptionIds: [],
                                        type: isQuestionItemAnAnalysis(questionItem) ? "analysis" : "iq"
                                    }

                                    return newSelectedItems
                                })

                                questionValueRefs.current[selectedItem.entryId]?.questionValue?.clearValue()
                            }}
                        />
                        {selectedItemIdx !== 0 && (
                            <MdRemoveCircle
                                className="h-[1.25em] w-[1.25em] text-glaut-pink mx-[0.5em]"
                                onClick={() => {
                                    const newSelectedItems = [...selectedItems]
                                    newSelectedItems.splice(selectedItemIdx, 1)
                                    setSelectedItems(newSelectedItems)
                                }}
                            />
                        )}
                    </div>
                    <div className="flex flex-row gap-[0.5em] items-center w-full py-[0.25em]">
                        <p className="text-[13.33px] font-medium text-[rgb(0,0,0,0.6)]">
                            {getCopy(copy.coding.setValue)}
                        </p>
                        <QuestionValueSelect
                            ref={element => {
                                questionValueRefs.current[selectedItem.entryId] = {
                                    ...questionValueRefs.current[selectedItem.entryId],
                                    questionValue: element ?? undefined
                                }
                            }}
                            options={eligibleItemsOptionsAsOptionMap[selectedItem.itemId] ?? []}
                            value={
                                (eligibleItemsOptionsAsOptionMap[selectedItem.itemId] ?? []).filter(
                                    option => selectedItem.chosenOptionIds.includes(option.value)
                                )
                            }
                            onSelect={val => {
                                setSelectedItems(prev => {
                                    const newSelectedItems = [...prev]
                                    newSelectedItems[selectedItemIdx] = {
                                        ...newSelectedItems[selectedItemIdx],
                                        chosenOptionIds: val
                                    }

                                    return newSelectedItems
                                })
                            }}
                            isDisabled={selectedItem.itemId === ""}
                        />
                    </div>
                    {selectedItems[selectedItemIdx].chosenOptionIds.map(optionId => {
                        const option = (formattedEligibleItemsOptions[selectedItem.itemId]?.categories ?? [])
                            .find(opt => opt.id === optionId)

                        const label = option?.label ?? ""
                        const colorIndex = option?.index ?? 0

                        return (
                            <div key={optionId} className="flex flex-row gap-[0.5em] items-center">
                                <FaTag
                                    className="h-[1em] w-[1em] rounded-none"
                                    style={{ color: `var(--color${normalizeToColorIndex(colorIndex)})` }}
                                />
                                <p className={`text-[13.33px] text-glaut-text-midnight font-medium
                                    text-ellipsis overflow-hidden whitespace-nowrap    
                                `}>
                                    {label}
                                </p>
                                <MdClose
                                    className="h-[1em] w-[1em] text-glaut-dark-grey"
                                    onClick={() => {
                                        handleRemoveOption(
                                            { index: selectedItemIdx, entryId: selectedItem.entryId },
                                            optionId
                                        )
                                    }}
                                />
                            </div>
                        )
                    })}
                </div>
            ))}
            <GlautButtonSecondary onClick={handleAddNewItem} disabled={!canAddNewItems}>
                <div className="flex flex-row items-center gap-[0.3125em]">
                    <MdAddCircle className="h-[1.25em] w-[1.25em]" />
                    <p className="text-[13.33px] font-medium">
                        {getCopy(copy.coding.addQuestion)}
                    </p>
                </div>
            </GlautButtonSecondary>
            <div className="flex flex-row justify-between mt-[0.625em] pt-[0.5em] w-full">
                <GlautButtonSecondary onClick={handleCloseModal}>
                    {getCopy(copy.coding.cancel)}
                </GlautButtonSecondary>
                <GlautButtonPrimary onClick={handleSaveFilters} disabled={!isSavingAllowed}>
                    {getCopy(copy.coding.save)}
                </GlautButtonPrimary>
            </div>
        </ModalLayout>
    )
}