import { getCopy } from "@utils/Copy"
import { DetailedHTMLProps, HTMLAttributes } from "react"
import { MdChevronLeft } from "react-icons/md"
import { showMoreButtonCopy } from "./utils/copy"

interface IShowMoreButtonProps extends DetailedHTMLProps<HTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {

}

export default function ShowMoreButton({
    ...props
}: Readonly<IShowMoreButtonProps>) {
    return (
        <button
            className="text-base w-full rounded-[0.25rem] p-[0.5rem] shadow-none gap-[0.25rem]
                bg-glaut-cards border-1 border-glaut-very-light-grey"
            {...props}
        >
            <span className="text-[11.11px] font-medium text-glaut-dark-grey">
                {getCopy(showMoreButtonCopy.showMore)}
            </span>
            <MdChevronLeft className="-rotate-90 text-glaut-dark-grey" />
        </button>
    )
}