import { AxiosInstance } from "axios"
import qs from "qs"
import { IInterviewQuestion } from "src/@types/interview-question"
import { IProjectInterview } from "src/@types/project"
import { IProjectGuest } from "src/@types/user"
import IProjectGateway, {
    IProjectGatewayChangeGuestRoleRequest,
    IProjectGatewayCheckTranslationStatusRequest,
    IProjectGatewayCheckTranslationStatusResponse,
    IProjectGatewayCreateInterviewQuestionRequest,
    IProjectGatewayCreateInterviewQuestionResponse,
    IProjectGatewayCreateProjectRequest,
    IProjectGatewayCreateProjectResponse,
    IProjectGatewayDeleteInterviewQuestionRequest,
    IProjectGatewayDeleteInterviewQuestionResponse,
    IProjectGatewayDeleteProjectRequest,
    IProjectGatewayDeleteProjectResponse,
    IProjectGatewayDownloadExcelRequest,
    IProjectGatewayGetAnalysisRequest,
    IProjectGatewayGetAnalysisResponse,
    IProjectGatewayGetConversationsRequest,
    IProjectGatewayGetConversationsResponse,
    IProjectGatewayGetEntriesRequest,
    IProjectGatewayGetEntriesResponse,
    IProjectGatewayGetGuestsRequest,
    IProjectGatewayGetInterviewQuestionsRequest,
    IProjectGatewayGetInterviewQuestionsResponse,
    IProjectGatewayGetMetricsRequest,
    IProjectGatewayGetMetricsResponse,
    IProjectGatewayGetProjectRequest,
    IProjectGatewayGetProjectResponse,
    IProjectGatewayImportCsvRequest,
    IProjectGatewayImportCsvResponse,
    IProjectGatewayInviteGuestRequest,
    IProjectGatewayRemoveGuestRequest,
    IProjectGatewayRemoveLogoResponse,
    IProjectGatewaySetInterviewQuestionImageRequest,
    IProjectGatewaySetInterviewQuestionOrderRequest,
    IProjectGatewaySetInterviewQuestionOrderResponse,
    IProjectGatewaySetLogoRequest,
    IProjectGatewaySetLogoResponse,
    IProjectGatewayStartTranslationRequest,
    IProjectGatewayStartTranslationResponse,
    IProjectGatewayUpdateInterviewQuestionRequest,
    IProjectGatewayUpdateInterviewQuestionResponse
} from "../IProjectGateway"
import { deepCopy } from "@utils/miscellaneous"

interface IProjectsApiServiceProps {
    api: AxiosInstance
}

export default class ProjectsApiService implements IProjectGateway {
    private readonly props: IProjectsApiServiceProps

    constructor(props: IProjectsApiServiceProps) {
        this.props = props
    }

    public async getProject(
        request: IProjectGatewayGetProjectRequest
    ): Promise<IProjectGatewayGetProjectResponse> {
        const { projectId } = request

        return await this.props.api
            .get(`projects/${projectId}`)
            .then(response => response.data)
    }

    public async createProject(
        request: IProjectGatewayCreateProjectRequest
    ): Promise<IProjectGatewayCreateProjectResponse> {
        const { workspaceId } = request

        return await this.props.api
            .post(`/datasets/${workspaceId}?v=insights`)
            .then(res => res.data)
    }

    public async deleteProject(
        request: IProjectGatewayDeleteProjectRequest
    ): Promise<IProjectGatewayDeleteProjectResponse> {
        const { projectId } = request

        return await this.props.api
            .delete(`/datasets/${projectId}`)
    }

    public async importCsv(
        request: IProjectGatewayImportCsvRequest
    ): Promise<IProjectGatewayImportCsvResponse> {
        const { csv, projectId, includedHeaders } = request

        const formData = new FormData()
        formData.append("table", csv)

        return await this.props.api
            .postForm(`projects/${projectId}/interviews`, formData, {
                params: { included_headers: includedHeaders },
                paramsSerializer: params => qs.stringify(params, { arrayFormat: "repeat" })
            })
    }

    public async getEntries(request: IProjectGatewayGetEntriesRequest): Promise<IProjectGatewayGetEntriesResponse> {
        const { projectId, page, analysisFilters, questionFilters } = request

        return await this.props.api
            .get<IProjectGatewayGetEntriesResponse>(
                `datasets/${projectId}/entries`,
                {
                    params: {
                        page,
                        analysis_filters: JSON.stringify(analysisFilters),
                        question_filters: JSON.stringify(questionFilters)
                    }
                }
            )
            .then(res => res.data)
            .catch(e => {
                console.log(e)

                return {
                    entries: [],
                    n_pages: 0,
                    total_entries: 0
                }
            })
    }

    public async getMetrics(request: IProjectGatewayGetMetricsRequest): Promise<IProjectGatewayGetMetricsResponse> {
        const { projectId } = request

        return await this.props.api
            .get(`projects/${projectId}/metrics/v2`)
            .then(res => res.data)
            .catch(e => {
                console.log(e)

                return {
                    completed: 0,
                    started: 0,
                    avg_interaction_time: 0,
                    platform_split: {}
                }
            })
    }

    public async getAnalysis(request: IProjectGatewayGetAnalysisRequest): Promise<IProjectGatewayGetAnalysisResponse> {
        const { projectId, serializedFilters } = request

        return await this.props.api
            .get<IProjectGatewayGetAnalysisResponse>(`datasets/${projectId}/analysis`, {
                params: {
                    filters: serializedFilters
                }
            })
            .then(res => res.data)
            .catch(e => {
                console.log(e)

                return {
                    analysis: {},
                    n_completed: 0,
                    n_started: 0
                }
            })
    }

    public async getConversations(
        request: IProjectGatewayGetConversationsRequest
    ): Promise<IProjectGatewayGetConversationsResponse> {
        const {
            projectId,
            identifier,
            include,
            languages,
            page,
            pageSize,
            platforms,
            sortBy,
            sortOrder
        } = request

        let actualSortOrder: number | undefined
        if (sortOrder !== undefined)
            actualSortOrder = sortOrder === "asc" ? 1 : -1

        const actualPlatforms: string[] = []
        if (platforms?.includes("desktop"))
            actualPlatforms.push("desktop")

        if (platforms?.includes("mobile") || platforms?.includes("tablet"))
            actualPlatforms.push("tablet")

        return await this.props.api
            .get<IProjectGatewayGetConversationsResponse>(`datasets/${projectId}/conversations`, {
                params: {
                    identifier,
                    include_completed: include?.completed,
                    include_uncompleted: include?.uncompleted,
                    page,
                    page_size: pageSize,
                    sort_by: sortBy,
                    sorting_order: actualSortOrder,
                    languages: languages ? JSON.stringify(languages) : undefined,
                    platforms: actualPlatforms.length > 0 ? JSON.stringify(actualPlatforms) : undefined
                }
            })
            .then(res => res.data)
            .catch(e => {
                console.log(e)

                return {
                    conversations: [],
                    n_pages: 0,
                    total_conversations: 0
                }
            })
    }

    public async downloadExcel(request: IProjectGatewayDownloadExcelRequest): Promise<string> {
        const { projectId } = request

        return await this.props.api
            .get<string>(`projects/${projectId}/download-excel/v2`, { responseType: "blob" })
            .then(response => response.data)
    }

    public async getInterviewQuestions(
        request: IProjectGatewayGetInterviewQuestionsRequest
    ): Promise<IProjectGatewayGetInterviewQuestionsResponse> {
        const { projectId } = request

        return await this.props.api
            .get<IInterviewQuestion[]>(`projects/${projectId}/questions`)
            .then(response => ({ interviewQuestions: response.data }))
            .catch(e => {
                console.log(e)
                return {
                    interviewQuestions: []
                }
            })
    }

    public async createInterviewQuestion(
        request: IProjectGatewayCreateInterviewQuestionRequest
    ): Promise<IProjectGatewayCreateInterviewQuestionResponse> {
        const { data, projectId } = request

        const newData = deepCopy(data)
        if (newData.type === "select")
            newData.options = newData.options?.map(option => ({
                ...option,
                id: !option?.id?.length ? undefined : option.id
            }))

        return await this.props.api
            .post<IInterviewQuestion>(`projects/${projectId}/questions`, newData)
            .then(response => response.data)
    }

    public async updateInterviewQuestion(
        request: IProjectGatewayUpdateInterviewQuestionRequest
    ): Promise<IProjectGatewayUpdateInterviewQuestionResponse> {
        const { data, projectId, questionId } = request

        const newData = deepCopy(data)
        if (newData.options)
            newData.options = newData.options?.map(option => ({
                ...option,
                id: !option?.id?.length ? undefined : option.id
            }))

        return await this.props.api
            .put<IInterviewQuestion>(`projects/${projectId}/questions/${questionId}`, {
                ...newData,
                header: data.header ?? ""
            })
            .then(response => response.data)
    }

    public async deleteInterviewQuestion(
        request: IProjectGatewayDeleteInterviewQuestionRequest
    ): Promise<IProjectGatewayDeleteInterviewQuestionResponse> {
        const { projectId, questionId } = request

        return await this.props.api
            .delete(`projects/${projectId}/questions/${questionId}`)
    }

    public async setInterviewQuestionOrder(
        request: IProjectGatewaySetInterviewQuestionOrderRequest
    ): Promise<IProjectGatewaySetInterviewQuestionOrderResponse> {
        const { index, projectId, questionId } = request

        return await this.props.api
            .patch(`projects/${projectId}/questions/${questionId}/move`, { new_index: index })
            .then(response => response.data)
    }

    public async setInterviewQuestionImage(request: IProjectGatewaySetInterviewQuestionImageRequest): Promise<string> {
        const { image, projectId, questionId } = request

        const formData = new FormData()
        formData.append("image", image)
        formData.append("image_id", questionId)

        return this.props.api
            .post(`datasets/${projectId}/upload-image`, formData)
            .then(response => response.data)
    }

    public async startTranslation(
        request: IProjectGatewayStartTranslationRequest
    ): Promise<IProjectGatewayStartTranslationResponse> {
        const { lang, projectId } = request

        return await this.props.api
            .patch(`projects/${projectId}/translate`, undefined, { params: { lang } })
    }

    public async checkTranslationStatus(
        request: IProjectGatewayCheckTranslationStatusRequest
    ): Promise<IProjectGatewayCheckTranslationStatusResponse> {
        const { projectId } = request

        const projectPromise = this.getProject({ projectId })
        const interviewQuestionsPromise = this.getInterviewQuestions({ projectId })

        return await Promise.all([projectPromise, interviewQuestionsPromise])
            .then(([projectResponse, interviewQuestionsResponse]) => ({
                project: projectResponse as IProjectInterview,
                interviewQuestions: interviewQuestionsResponse.interviewQuestions
            }))
    }

    public async getGuests(request: IProjectGatewayGetGuestsRequest): Promise<IProjectGuest[]> {
        const { projectId } = request

        return await this.props.api
            .get<IProjectGuest[]>(
                `datasets/${projectId}/users`
            )
            .then(res => res.data)
            .catch(e => {
                console.log(e)
                return Promise.reject(e)
            })
    }

    public async inviteGuest(request: IProjectGatewayInviteGuestRequest): Promise<IProjectGuest> {
        const { projectId, email, role } = request

        return this.props.api
            .put<IProjectGuest>(`datasets/${projectId}/invite`, { email, role })
            .then(response => response.data)
            .catch(e => {
                console.log(e)
                return Promise.reject(e)
            })
    }

    public async changeGuestRole(request: IProjectGatewayChangeGuestRoleRequest): Promise<IProjectGuest> {
        const { projectId, email, role } = request

        return this.props.api
            .put<IProjectGuest>(`datasets/${projectId}/change_role?email=${encodeURIComponent(email)}&role=${role}`)
            .then(response => response.data)
            .catch(e => {
                console.log(e)
                return Promise.reject(e)
            })
    }

    public async removeGuest(request: IProjectGatewayRemoveGuestRequest): Promise<null> {
        const { projectId, email } = request

        return this.props.api
            .put<IProjectGuest>(`datasets/${projectId}/remove?email=${encodeURIComponent(email)}`)
            .then(() => null)
            .catch(e => {
                console.log(e)
                return Promise.reject(e)
            })
    }

    public async setLogo(request: IProjectGatewaySetLogoRequest): Promise<IProjectGatewaySetLogoResponse> {
        const { logo, projectId } = request

        const formData = new FormData()
        formData.append("image", logo)
        formData.append("image_id", "logo")

        return this.props.api
            .post(`datasets/${projectId}/upload-image`, formData)
            .then(response => ({ url: response.data }))
    }

    public async removeLogo(): Promise<IProjectGatewayRemoveLogoResponse> {
        throw new Error("Not implemented.")
    }
}