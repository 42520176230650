import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { MdCheck, MdLink, MdOutlineCopyAll } from "react-icons/md"
import { useLegacyProject } from "../../contexts/ProjectProvider"
import { glautTooltipId } from "@components/layouts/GlautTooltip/constants/id"
import { copy, getCopy } from "@utils/Copy"
import { useProjectService } from "@hooks/services/useProjectService"
import DotTyping from "@components/Icons/DotTyping"
import usePrevProps from "@hooks/usePrevProps"
import { IProject } from "@/@types/project"

export default function OutlineTabSidebarShareInterviewCopyButton() {
    // #region Contexts
    const { project, setProject } = useLegacyProject()
    // #endregion

    // #region Services
    const projectService = useProjectService()
    // #endregion

    // #region Prev props
    const prevProjectStatus = usePrevProps(project?.source === "interview" ? project.status : null)
    // #endregion

    // #region States
    const [hasCopiedUrl, setHasCopiedUrl] = useState(false)
    const [translationCompletion, setTranslationCompletion] = useState<number | null>(
        () => project?.source === "interview" ? project.completion : null
    )
    // #endregion

    // #region Refs
    const copyEffectTimeout = useRef<NodeJS.Timeout>()
    // #endregion

    // #region Memos
    const interviewUrl = useMemo(
        () => project ? `${window.location.origin}/i/${project._id}` : "",
        [project]
    )

    const buttonState = useMemo(
        () => {
            if (project?.source !== "interview" || ["set_up"].includes(project.status)) return "disabled"

            if (translationCompletion !== null) return "translating"

            if (hasCopiedUrl) return "copied"

            return "enabled"
        },
        [hasCopiedUrl, project, translationCompletion]
    )

    const buttonBackgroundColor = useMemo(() => {
        if (buttonState === "disabled") return "bg-glaut-off-white"
        if (buttonState === "translating") return "bg-glaut-off-white"
        if (buttonState === "enabled") return "bg-glaut-pink"
        if (buttonState === "copied") return "bg-glaut-codes-green"

        return ""
    }, [buttonState])

    const tooltipText = useMemo(() => {
        if (buttonState === "disabled")
            return getCopy(copy.outline.sidebar.switchToTestModeToCopyTheUrlFromTheInterviewExclamation)

        if (buttonState === "enabled")
            return getCopy(copy.outline.sidebar.copyInterviewUrl)

        if (buttonState === "translating")
            return getCopy(copy.outline.sidebar.pleaseWaitTheTranslationToEnd)

        return null
    }, [buttonState])
    // #endregion

    // #region Callbacks
    const handleCopyUrl = useCallback(() => {
        navigator.clipboard.writeText(interviewUrl).then(() => {
            setHasCopiedUrl(true)

            const timeout = setTimeout(() => {
                setHasCopiedUrl(false)
            }, 2000)

            if (copyEffectTimeout) clearTimeout(copyEffectTimeout.current)

            copyEffectTimeout.current = timeout
        })
    }, [interviewUrl, copyEffectTimeout])
    // #endregion

    // #region Effects

    // onCompletionNotNullPoolProject
    useEffect(() => {
        if (project?.source !== "interview") return
        if (translationCompletion === null && project.completion === null) return

        if (prevProjectStatus !== project.status) setTranslationCompletion(0)

        const interval = setInterval(() => {
            projectService.getProject({ projectId: project._id }).then(res => {
                if (res?.source !== "interview") return

                setTranslationCompletion(res.completion)
                setProject(p => ({ ...p, completion: res.completion }) as IProject)

                if (res.completion === null) clearInterval(interval)
            })
        }, 3000)

        return () => { clearInterval(interval) }
    }, [prevProjectStatus, project, projectService, translationCompletion, setProject])

    // #endregion

    if (!project) return <></>

    return (
        <div className="flex flex-col gap-[0.25rem]">
            <div className="flex gap-[0.5rem] items-center">
                <div className="flex flex-col rounded-[0.25rem] min-w-0 bg-glaut-cards">
                    <div className="flex flex-1 gap-[0.5rem] py-[0.5rem] px-[0.75rem]">
                        <MdLink className="w-[1.25rem] h-[1.25rem] text-glaut-stroke-button" />
                        <p className="min-w-0 text-[13.33px] font-medium text-glaut-grey
                            text-ellipsis overflow-hidden whitespace-nowrap">
                            {interviewUrl}
                        </p>
                    </div>

                    {buttonState === "translating" && (
                        <div
                            className="rounded-full bg-glaut-pink h-[0.125rem] transition-all duration-1000"
                            style={{ width: `${(translationCompletion ?? 0) * 100}%` }}
                        />
                    )}
                </div>
                <button
                    className={`border-none shadow-none text-base p-2 aspect-square
                        ${buttonState === "enabled" ? "hover:bg-[#CC4F75]" : ""}
                        ${buttonBackgroundColor}
                    `}
                    onClick={handleCopyUrl}
                    disabled={buttonState === "disabled"}
                    data-tooltip-id={glautTooltipId}
                    data-tooltip-content={tooltipText}
                >
                    {buttonState === "copied" && (
                        <MdCheck className="w-[1.25rem] h-[1.25rem] text-glaut-off-white" />
                    )}
                    {buttonState === "enabled" && (
                        <MdOutlineCopyAll className="w-[1.25rem] h-[1.25rem] text-glaut-off-white" />
                    )}
                    {buttonState === "disabled" && (
                        <MdOutlineCopyAll className="w-[1.25rem] h-[1.25rem] text-glaut-grey" />
                    )}
                    {buttonState === "translating" && (
                        <DotTyping />
                    )}
                </button>
            </div>
            {buttonState === "translating" && (
                <p className="text-[11.11px] font-medium text-glaut-pink">
                    {getCopy(copy.outline.sidebar.processingTranslationPleaseWaitExclamation)}
                </p>
            )}
        </div>
    )
}