import { ITerminationReason } from "@/@types/entry"
import { useTableRowItemStatus } from "../../hooks/useTableRowItemStatus"

interface IDataTabTableRowItemStatusProps {
    status: ITerminationReason | "uncompleted"
}

export default function DataTabTableRowItemStatus({
    status
}: Readonly<IDataTabTableRowItemStatusProps>) {
    // #region Table row hooks
    const { icon, text, backgroundClassName } = useTableRowItemStatus(status)
    // #endregion

    return (
        // inline styling to ignore CSS modules
        <td style={{ borderRight: "0px", padding: "0" }}>
            <div className={`flex justify-center items-center rounded-[0.25rem] h-full px-[0.75rem] py-[0.25rem] gap-1
                ${backgroundClassName}
            `}>
                <span className="text-glaut-midnight text-[13.33px]">{text}</span> {icon}
            </div>
        </td>
    )
}