import { useState } from "react"

import { UserProvider } from "@hooks/useGlautAuthInfo"
import OrganizationContent from "./OrganizationContent"

export default function Organization() {
    const [currentTab, setCurrentTab] = useState(0)

    return (
        <UserProvider>
            <OrganizationContent
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
            />
        </UserProvider>
    )
}
