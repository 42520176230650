import { useMemo } from "react"
import { MdError, MdOutlineInfo } from "react-icons/md"

const DEFAULT_ICONS = {
    info: <MdOutlineInfo className="h-[1rem] w-[1rem] text-glaut-text-midnight" />,
    danger: <MdError className="h-[1rem] w-[1rem] text-glaut-codes-red" />
}

interface IGlautMessageProps {
    icon?: React.ReactNode
    alignment?: "start" | "center" | "end"
    type?: "info" | "danger"
    message: string
}

export default function GlautMessage({
    icon,
    alignment = "start",
    type = "info",
    message
}: Readonly<IGlautMessageProps>) {
    // #region Const values
    const containerColorsClassNames = {
        info: "bg-glaut-cards border-glaut-dummie-color",
        danger: "bg-glaut-subcodes-red border-glaut-subcodes-red"
    }[type]

    const containerAlignmentClassNames = {
        start: "",
        center: "justify-center",
        end: "justify-end"
    }[alignment]
    // #endregion

    // #region Element memos
    const actualIcon = useMemo(() => icon ?? DEFAULT_ICONS[type], [type, icon])
    // #endregion

    return (
        <div className={`flex items-center gap-[0.75rem] p-[0.75rem] border-1
            ${containerColorsClassNames}
            ${containerAlignmentClassNames}`}
        >
            {actualIcon}
            <p className="text-[11.11px] text-glaut-text-midnight">
                {message}
            </p>
        </div>
    )
}