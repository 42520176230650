import { draggable, dropTargetForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter"
import LoadingBar from "@components/loading/LoadingBar"
import { useAnalysisService } from "@hooks/services/useAnalysisService"
import { useGlautAuthInfo } from "@hooks/useGlautAuthInfo"
import { copy, getCopy } from "@utils/Copy"
import { isEllipsisActive } from "@utils/html-elements/is-ellipsis-active"
import { normalizeQuestionOrOption } from "@utils/strings"
import { bgColorWithCssColorVarFromIndex, normalizeToColorIndex } from "@utils/styling/colors-from-index"
import { MouseEvent as ReactMouseEvent, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { FaCheckCircle, FaHashtag, FaStarHalfAlt, FaTag } from "react-icons/fa"
import { MdCheck, MdClose, MdLayers, MdManageSearch, MdMoreVert } from "react-icons/md"
import { IAnalysisCategory } from "src/@types/analysis"
import { ICategoryStats } from "src/@types/processing/statistics"
import {
    useAnalysisTabAnalysisCol,
    useAnalysisTabAnalysisColDispatch
} from "../../contexts/AnalysisTabAnalysisColProvider"
import { useAnalysisTab, useAnalysisTabDispatch } from "../../contexts/AnalysisTabProvider"
import { useLegacyProject, useProject } from "../../contexts/ProjectProvider"
import { useAnalysisTabAnalysisColDraggable } from "../../hooks/useAnalysisTabAnalysisColDraggable"
import AnalysisTabAnalysisColThemeAndCodeActionsMenu from "../AnalysisTabAnalysisColThemeAndCodeActionsMenu"
import Code from "../AnalysisTabAnalysisColThemeCode"
import AnalysisTabAnalysisColThemeCreateSubThemeForm from "../AnalysisTabAnalysisColThemeCreateSubThemeForm"
import AnalysisTabAnalysisColThemeSubTheme from "../AnalysisTabAnalysisColThemeSubTheme"

interface IAnalysisTabAnalysisColThemeProps {
    themeStats: ICategoryStats<IAnalysisCategory>
    shouldShowCodes?: boolean
    /**
     * Index for defining the border presence or not and the color index.
     */
    index: number
    isLastElement?: boolean
}

export default function AnalysisTabAnalysisColTheme({
    themeStats,
    shouldShowCodes = false,
    index,
    isLastElement = false
}: Readonly<IAnalysisTabAnalysisColThemeProps>) {
    // #region Hooks
    const {
        currentProjectIsAtLeastRole
    } = useGlautAuthInfo()
    // #endregion

    // #region Contexts
    const { project } = useLegacyProject()
    const { interviewQuestions } = useProject()
    const {
        selectedAnalysis,
        selectedCategory,
        categoryColorIndexes,
        selectedInterviewQuestionId,
        selectedBreakdown,
        breakdownStats
    } = useAnalysisTab()
    const analysisTabDispatch = useAnalysisTabDispatch()
    const { categoryBeingMerged, categoryBeingUsedToCreateSubTheme, categoryBeingEdited } = useAnalysisTabAnalysisCol()
    const analysisTabAnalysisColDispatch = useAnalysisTabAnalysisColDispatch()
    // #endregion

    // #region Services
    const analysisService = useAnalysisService()
    // #endregion

    // #region States
    const [isShowingCodes, setIsShowingCodes] = useState(false)
    const [isShowingActionsMenu, setIsShowingActionsMenu] = useState(false)

    const [isBeingDragged, setIsBeingDragged] = useState(false)
    const [isBeingUsedAsDroppable, setIsBeingUsedAsDroppable] = useState(false)

    const [hasTooltip, setHasTooltip] = useState(false)
    const [isEditingLabel, setIsEditingLabel] = useState(false)
    // #endregion

    // #region Refs
    const themeDraggableDivRef = useRef<HTMLDivElement>(null)
    const themeDroppableDivRef = useRef<HTMLDivElement>(null)
    const actionsMenuAnchorRef = useRef<HTMLDivElement>(null)
    const themeParagraphRef = useRef<HTMLParagraphElement>(null)
    const editionInputRef = useRef<HTMLInputElement>()
    // #endregion

    // #region Analysis column hooks
    const { isAllowedToDrag } = useAnalysisTabAnalysisColDraggable()
    // #endregion

    // #region Memos
    const isBreakdownApplied = useMemo(
        () => selectedBreakdown !== undefined && breakdownStats !== undefined,
        [selectedBreakdown, breakdownStats]
    )

    const qualClosedQuestionType = useMemo(() => {
        if (!selectedInterviewQuestionId) return null

        const iq = interviewQuestions?.find(iq => iq.id === selectedInterviewQuestionId)
        if (!iq || iq.type === "open" || iq.probing_instructions === null) return null

        return iq.type
    }, [selectedInterviewQuestionId, interviewQuestions])

    const canEdit = useMemo(
        () => currentProjectIsAtLeastRole("editor"),
        [currentProjectIsAtLeastRole]
    )
    const colorIndex = useMemo(() => {
        if (qualClosedQuestionType === "nps") {
            if (index === 0) return 0 // promoters
            if (index === 1) return 5 // passives
            return 7 // detractors
        }

        return categoryColorIndexes[themeStats.category.id] ?? normalizeToColorIndex(index)
    }, [categoryColorIndexes, themeStats.category.id, index, qualClosedQuestionType])
    const loadingBarBgClassName = useMemo(
        () => bgColorWithCssColorVarFromIndex(colorIndex),
        [colorIndex]
    )
    const hasAnyCode = useMemo(() => themeStats.children.length > 0, [themeStats])
    const codeActionButtonLabel = useMemo(() => {
        if (!hasAnyCode) return getCopy<string>(copy.coding.noCodesToShow)
        return getCopy<string>(isShowingCodes ? copy.coding.hideCodes : copy.coding.showCodes)
    }, [hasAnyCode, isShowingCodes])

    const isAllowedToToggleCodesVisibility = useMemo(
        () => !isEditingLabel && !categoryBeingMerged && !categoryBeingUsedToCreateSubTheme && !isBreakdownApplied,
        [isEditingLabel, isBreakdownApplied, categoryBeingMerged, categoryBeingUsedToCreateSubTheme]
    )

    const shouldShowActionButtons = useMemo(() => {
        if (isEditingLabel || isBeingDragged || themeStats.category.id === "empty") return false

        return qualClosedQuestionType !== null || !isBreakdownApplied
    }, [isEditingLabel, isBeingDragged, themeStats.category.id, qualClosedQuestionType, isBreakdownApplied])

    const shouldShowThreeDotsButton = useMemo(
        () => !isBreakdownApplied && qualClosedQuestionType === null,
        [isBreakdownApplied, qualClosedQuestionType]
    )

    const shouldShowSubThemesElement = useMemo(
        () => !isBreakdownApplied && isShowingCodes,
        [isBreakdownApplied, isShowingCodes]
    )

    const droppableContainerClassNames = useMemo(
        () => {
            if (!canEdit || !isAllowedToDrag || !isBeingUsedAsDroppable) return "border-1 border-transparent mt-1"
            return "border-1 border-dashed border-glaut-stroke-button rounded-[0.25em] mt-1"
        },
        [canEdit, isAllowedToDrag, isBeingUsedAsDroppable]
    )

    const isSelected = useMemo(
        () => selectedCategory?.category.id === themeStats.category.id,
        [selectedCategory?.category.id, themeStats.category.id]
    )

    const isGrey = useMemo(
        () => themeStats.occurrences === 0,
        [themeStats.occurrences]
    )

    const isAvailableToMerge = useMemo(
        // Allow merge to theme only when the theme doesn't have children
        () => !isEditingLabel && categoryBeingMerged && (
            categoryBeingMerged.category.id !== themeStats.category.id && themeStats.children.length === 0
        ),
        [isEditingLabel, categoryBeingMerged, themeStats.category.id, themeStats.children.length]
    )

    const themeDraggableCursorClassName = useMemo(() => {
        if (themeStats.category.id === "empty" || isEditingLabel) return ""

        if (isAvailableToMerge) return "cursor-pointer"
        if (canEdit && isAllowedToDrag && !selectedInterviewQuestionId) return "cursor-grab"

        return ""
    }, [isEditingLabel, themeStats, canEdit, isAllowedToDrag, isAvailableToMerge, selectedInterviewQuestionId])

    const percentage = useMemo(() => {
        const value = Math.min(Math.round(themeStats.frequency * 100), 100)
        if (value === 0) return "<1"

        return String(value)
    }, [themeStats.frequency])

    const themeModality = useMemo(() => {
        if (qualClosedQuestionType === "select") return "qual-select"
        if (qualClosedQuestionType === "nps") return "qual-nps"

        if (isBreakdownApplied) return "breakdown"
        return "default"
    }, [qualClosedQuestionType, isBreakdownApplied])
    // #endregion

    // #region Callbacks
    const handleToggleCodesVisibility = useCallback((e: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation()

        if (!isAllowedToToggleCodesVisibility) return
        setIsShowingCodes(prev => !prev)
    }, [isAllowedToToggleCodesVisibility])

    const handleContainerClick = useCallback(() => {
        if (isEditingLabel || selectedInterviewQuestionId || isBreakdownApplied) return

        if (categoryBeingMerged) {
            if (!isAvailableToMerge) return

            analysisTabAnalysisColDispatch({
                type: "set-target-category-being-merged",
                category: { ...themeStats, index: colorIndex, themeId: themeStats.category.id }
            })
            return
        }

        if (selectedCategory?.category.id === themeStats.category.id) {
            analysisTabDispatch({ type: "clear-selected-category" })
            return
        }

        analysisTabDispatch({ type: "set-selected-category", category: themeStats })
    }, [
        analysisTabDispatch,
        analysisTabAnalysisColDispatch,
        themeStats,
        isEditingLabel,
        colorIndex,
        selectedInterviewQuestionId,
        categoryBeingMerged,
        selectedCategory?.category.id,
        isAvailableToMerge,
        isBreakdownApplied
    ])

    const handleDismissEditing = useCallback(() => {
        setIsEditingLabel(false)
        analysisTabAnalysisColDispatch({ type: "clear-category-being-edited" })
    }, [analysisTabAnalysisColDispatch])

    const handleApplyEdition = useCallback((e?: ReactMouseEvent) => {
        e?.stopPropagation()

        if (!project || !selectedAnalysis || !editionInputRef.current) return

        const label = editionInputRef.current.value
        if (label === "") return

        const projectId = project._id
        const analysisId = selectedAnalysis.id
        const categoryId = themeStats.category.id

        analysisService.updateAnalysisCategory({
            projectId,
            analysisId,
            categoryId,
            category: { ...themeStats.category, label }
        }).then(() => {
            analysisTabDispatch({ type: "update-theme-labels", analysisId, themesData: [{ id: categoryId, label }] })
            handleDismissEditing()
        })
    }, [analysisService, analysisTabDispatch, handleDismissEditing, project, selectedAnalysis, themeStats.category])
    // #endregion

    // #region Effects

    // onMergingOrCreatingSubThemeUpdateCodesVisibility
    useEffect(() => {
        if (!categoryBeingMerged && !categoryBeingUsedToCreateSubTheme) return
        setIsShowingCodes(
            categoryBeingMerged?.themeId === themeStats.category.id ||
            categoryBeingUsedToCreateSubTheme?.themeId === themeStats.category.id ||
            (!!categoryBeingMerged && categoryBeingMerged?.themeId === categoryBeingMerged?.category.id)
        )
    }, [categoryBeingMerged, categoryBeingUsedToCreateSubTheme, themeStats])

    // onBreakdownShowAllCodes
    useEffect(() => {
        setIsShowingCodes(!!isBreakdownApplied)
    }, [isBreakdownApplied])

    // onRenderTurnIntoDraggable
    useEffect(() => {
        if (themeStats.category.id === "empty") return

        return draggable({
            element: themeDraggableDivRef.current!,
            getInitialData: () => ({ stats: themeStats }),
            canDrag: () => canEdit && isAllowedToDrag && !selectedInterviewQuestionId,
            onGenerateDragPreview: () => setIsBeingDragged(true),
            onDrop: () => setIsBeingDragged(false)
        })
    }, [themeStats, isAllowedToDrag, selectedInterviewQuestionId, canEdit])

    // onRenderTurnIntoDroppable
    useEffect(() => {
        if (themeStats.category.id === "empty") return

        return dropTargetForElements({
            element: themeDroppableDivRef.current!,
            onDragEnter: ({ source }) => {
                const categoryStats = source.data.stats as ICategoryStats<IAnalysisCategory>

                // if theme => cannot drop on itself
                const categoryId = categoryStats.category.id
                // if code => cannot drop on parent (= theme)
                const parentCategoryId = categoryStats.category.parent

                setIsBeingUsedAsDroppable(![categoryId, parentCategoryId].includes(themeStats.category.id))
            },
            getData: () => ({ stats: themeStats }),
            onDragLeave: () => setIsBeingUsedAsDroppable(false),
            onDrop: () => setIsBeingUsedAsDroppable(false),
            canDrop: () => canEdit && isAllowedToDrag && ["thematic", "entity"].includes(selectedAnalysis?.type ?? "")
        })
    }, [isAllowedToDrag, themeStats, selectedAnalysis, canEdit])

    // onTextEllipsisShowTooltip
    useEffect(() => {
        if (!themeParagraphRef.current) return
        setHasTooltip(isEllipsisActive(themeParagraphRef.current))
    }, [themeStats.category.label])

    // onEditingCategoryCheckIfIsEditingThisTheme
    useEffect(() => {
        setIsEditingLabel(
            categoryBeingEdited?.type === "theme-inline" && categoryBeingEdited.id === themeStats.category.id
        )
    }, [categoryBeingEdited?.id, categoryBeingEdited?.type, themeStats.category.id])

    // onEditingAttachKeydownEvents
    useEffect(() => {
        if (!isEditingLabel || !editionInputRef.current) return

        const elem = editionInputRef.current

        function onKeyDown(e: KeyboardEvent) {
            e.stopPropagation()

            if (e.key === "Enter") handleApplyEdition()
            if (e.key === "Escape") handleDismissEditing()
        }

        elem.addEventListener("keydown", onKeyDown)

        return () => { elem.removeEventListener("keydown", onKeyDown) }
    }, [handleApplyEdition, handleDismissEditing, isEditingLabel])

    // #endregion

    // #region Element memos
    const subThemesElement = useMemo(
        () => [...themeStats.children]
            .filter(codeStats => codeStats.children.length !== 0 && codeStats.occurrences > 0)
            .sort((a, b) => b.occurrences - a.occurrences)
            .map(codeStats => (
                <AnalysisTabAnalysisColThemeSubTheme
                    key={codeStats.category.id}
                    themeId={themeStats.category.id}
                    subThemeStats={codeStats}
                    colorIndex={colorIndex}
                    withActionsMenu
                    isFromLastTheme={isLastElement}
                />
            )),
        [colorIndex, isLastElement, themeStats.category.id, themeStats.children]
    )

    const codesElement = useMemo(
        () => [...themeStats.children]
            .filter(codeStats => codeStats.occurrences > 0 && (isBreakdownApplied || codeStats.children.length === 0))
            .sort((a, b) => b.occurrences - a.occurrences)
            .map(codeStats => (
                <Code
                    key={codeStats.category.id}
                    themeId={themeStats.category.id}
                    codeStats={codeStats}
                    colorIndex={colorIndex}
                    withActionsMenu
                    isFromLastTheme={isLastElement}
                />
            )),
        [colorIndex, isLastElement, themeStats.category.id, themeStats.children, isBreakdownApplied]
    )
    // #endregion

    return (
        <div className={`flex flex-col pb-1 ${index !== 0 ? "border-t-1 border-glaut-stroke-glaut" : ""}`}>
            <div ref={themeDroppableDivRef} className={`${droppableContainerClassNames}`}>
                <div className={`flex flex-row p-[0.5em] justify-between border-1 rounded-[0.25em] group/theme
                    hover:bg-glaut-cards w-full
                    ${themeDraggableCursorClassName}
                    ${isSelected ? "border-glaut-light-grey" : "border-transparent"}
                `} onClick={handleContainerClick} ref={themeDraggableDivRef}>
                    <div className={`flex flex-row gap-[0.5em] items-center flex-1
                        ${!isEditingLabel ? "max-w-[55%] lg:max-w-[60%] xl:max-w-[65%] 2xl:max-w-[70%]" : ""}
                    `}>
                        {themeModality === "qual-select" && (
                            <FaCheckCircle
                                className="w-[1.125em] h-[1.125em] rounded-none flex-none"
                                style={{
                                    color: isGrey
                                        ? "var(--color-glaut-light-grey)"
                                        : `var(--color${normalizeToColorIndex(colorIndex)})`
                                }}
                            />
                        )}
                        {themeModality === "qual-nps" && (
                            <FaStarHalfAlt
                                className="w-[1.125em] h-[1.125em] rounded-none flex-none"
                                style={{ color: `var(--color${normalizeToColorIndex(colorIndex)})` }}
                            />
                        )}
                        {themeModality === "breakdown" && (
                            <MdLayers
                                className="w-[1.125em] h-[1.125em] rounded-none flex-none"
                                style={{
                                    color: isGrey
                                        ? "var(--color-glaut-light-grey)"
                                        : `var(--color${normalizeToColorIndex(colorIndex)})`
                                }}
                            />
                        )}
                        {themeModality === "default" && (
                            <FaTag
                                className="w-[1em] h-[1em] rounded-none flex-none"
                                style={{
                                    color: isGrey
                                        ? "var(--color-glaut-light-grey)"
                                        : `var(--color${normalizeToColorIndex(colorIndex)})`
                                }}
                            />
                        )}
                        {!isEditingLabel && (
                            <>
                                <p
                                    className={`text-[13.33px] font-medium self-stretch mt-[0.125em]
                                        text-ellipsis whitespace-nowrap overflow-hidden text-nowrap
                                        ${isGrey ? "text-glaut-light-grey" : "text-glaut-text-midnight"}
                                    `}
                                    ref={themeParagraphRef}
                                    data-tooltip-id="tooltip--glaut-app"
                                    data-tooltip-content={themeStats.category.label}
                                    data-tooltip-place="right"
                                    data-tooltip-hidden={!hasTooltip}
                                >
                                    {normalizeQuestionOrOption(themeStats.category.label)}
                                </p>
                                {selectedAnalysis?.type !== "entity" && (
                                    <MdManageSearch
                                        data-tooltip-id="tooltip--glaut-app"
                                        data-tooltip-content={themeStats.category.description}
                                        data-tooltip-place="right"
                                        data-tooltip-class-name="max-w-[400px]"
                                        className="w-[1.125em] h-[1.125em] flex-none 
                                            text-glaut-grey hover:text-glaut-dark-grey"
                                    />
                                )}
                            </>
                        )}
                        {isEditingLabel && (
                            <>
                                <input
                                    ref={element => {
                                        if (!element) {
                                            editionInputRef.current = undefined
                                            return
                                        }

                                        editionInputRef.current = element
                                        element.focus()
                                    }}
                                    className="text-[13.33px] leading-[18px] mr-1
                                        border-none shadow-none rounded-none bg-transparent m-0 p-0 focus:shadow-none"
                                    placeholder={themeStats.category.label}
                                />
                                <MdClose className="h-[1em] w-[1em] cursor-pointer" onClick={handleDismissEditing} />
                                <MdCheck className="h-[1em] w-[1em] cursor-pointer" onClick={handleApplyEdition} />
                            </>
                        )}
                    </div>
                    {shouldShowActionButtons && (
                        <div className="flex flex-row gap-[0.5em] justify-end items-center flex-none justify-self-end"
                            ref={actionsMenuAnchorRef}>
                            {shouldShowCodes && (
                                <button
                                    onClick={handleToggleCodesVisibility}
                                    disabled={!isAllowedToToggleCodesVisibility}
                                    className={`border-0 shadow-none rounded-full px-[0.75em] py-[0.25em] m-0
                                        group/codes-toggle
                                        ${hasAnyCode ? "bg-glaut-very-light-grey" : "bg-glaut-cards cursor-auto"}
                                        disabled:cursor-default disabled:bg-glaut-cards
                                    `}
                                >
                                    <p className={`text-[11.11px] font-medium text-nowrap
                                        ${hasAnyCode ? "text-glaut-text-midnight" : "text-glaut-grey"}
                                        group-disabled/codes-toggle:text-glaut-grey
                                    `}>
                                        {codeActionButtonLabel}
                                    </p>
                                </button>
                            )}

                            {shouldShowThreeDotsButton && (
                                <button
                                    className="border-0 shadow-none p-0 m-0 text-base bg-transparent rounded-none"
                                    onClick={e => {
                                        e.stopPropagation()
                                        setIsShowingActionsMenu(true)
                                    }}
                                    disabled={!canEdit}
                                >
                                    <MdMoreVert className={`w-[1em] h-[1em] 
                                        text-glaut-light-grey group-hover/theme:text-glaut-dark-grey
                                    `} />
                                </button>
                            )}
                        </div>
                    )}
                </div>
                <div className="flex flex-row px-[0.75em] py-[0.5em] items-center gap-[0.25em]">
                    <p className="text-[19.2px] text-glaut-text-midnight min-w-11">
                        {percentage}%
                    </p>
                    <LoadingBar
                        percentage={themeStats.frequency * 100}
                        showPercentage={false}
                        loadingBarFullClassName={`loading-bar-full ${loadingBarBgClassName}`}
                        loadingBarDotClassName={`loading-bar-dot ${loadingBarBgClassName}`}
                        style={{ flex: 1 }}
                    />
                    <FaHashtag className="w-[0.875em] h-[0.875em] text-glaut-grey mb-[0.20em]" />
                    <p className="text-[19.2px] text-glaut-text-midnight">
                        {themeStats.occurrences}
                    </p>
                </div>
                {isShowingCodes && (
                    <div
                        className="flex flex-row flex-wrap px-[0.75em] pb-[0.5em]"
                        style={{ columnGap: "0.625em", rowGap: "0.5em" }}>
                        {codesElement}
                    </div>
                )}

            </div>

            {shouldShowSubThemesElement && (
                <div className="flex flex-col">
                    {subThemesElement}
                </div>
            )}

            {categoryBeingUsedToCreateSubTheme?.themeId === themeStats.category.id && (
                <AnalysisTabAnalysisColThemeCreateSubThemeForm colorIndex={colorIndex} />
            )}

            <AnalysisTabAnalysisColThemeAndCodeActionsMenu
                isOpen={isShowingActionsMenu}
                onClose={() => { setIsShowingActionsMenu(false) }}
                anchorRef={actionsMenuAnchorRef}
                categoryStats={themeStats}
                categoryIndex={colorIndex}
                isTheme
                openPlacement={isLastElement ? "up" : "down"}
            />
        </div>
    )
}