import GlautTextAreaSingleLine from "@components/inputs/GlautTextAreaSingleLine"
import { copy, getCopy } from "@utils/Copy"
import { DetailedHTMLProps, InputHTMLAttributes } from "react"

interface IGlautAnalysisCreationFormTitleInputProps
    extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {

}

export default function GlautAnalysisCreationFormTitleInput({
    ...props
}: Readonly<IGlautAnalysisCreationFormTitleInputProps>) {
    return (
        <div className="flex flex-col gap-[0.5rem]">
            <p className="text-[13.33px] font-medium text-glaut-text-midnight">
                {getCopy(copy.coding.analysisName)}
                <span className="text-[11.11px] text-glaut-bar italic ml-[0.5rem]">
                    ({getCopy(copy.words.optional)})
                </span>
            </p>
            <p className="text-[13.33px] text-glaut-dark-grey">
                {getCopy(copy.coding.theTitleIsUsedToNameTheAnalysis)}
            </p>
            <GlautTextAreaSingleLine {...props} />
        </div>
    )
}