interface IAnalysisTabAnalysisColActionsBoxItemProps {
    onClick?: () => void
    isApplied?: boolean
    tooltipContent?: string
    disabled?: boolean
    children: React.ReactNode
}

export default function AnalysisTabAnalysisColActionsBoxItem({
    onClick,
    isApplied = false,
    tooltipContent,
    disabled = false,
    children
}: Readonly<IAnalysisTabAnalysisColActionsBoxItemProps>) {
    return (
        <button
            onClick={onClick}
            className={`border-1 shadow-none rounded-[0.25em] aspect-[5/4]
                ${!isApplied ? "bg-glaut-cards border-transparent" : ""}
                ${isApplied ? "bg-glaut-stroke-glaut border-glaut-grey text-glaut-stroke-button" : ""}
                hover:border-glaut-light-grey
                disabled:hover:border-transparent
            `}
            data-tooltip-id="tooltip--glaut-app"
            data-tooltip-content={tooltipContent}
            data-tooltip-place="bottom"
            disabled={disabled}
        >
            {children}
        </button>
    )
}