import GlautTextAreaMultiLine from "@components/inputs/GlautTextAreaMultiLine"
import { useDebouncedInputChange } from "@hooks/useDebouncedInputChange"
import { copy, getCopy } from "@utils/Copy"
import { ChangeEventHandler, useCallback } from "react"
import { useOutlineTab } from "../../contexts/OutlineTabProvider"
import { useLegacyProject } from "../../contexts/ProjectProvider"
import OutlineTabContentDefaultTitle from "../OutlineTabContentDefaultTitle"

export default function OutlineTabContentOverviewProjectContext() {
    // #region Contexts
    const { project, setProject } = useLegacyProject()
    const { canEdit } = useOutlineTab()
    // #endregion

    // #region Callbacks
    const handleChangeProjectContext: ChangeEventHandler<HTMLTextAreaElement> = useCallback(ev => {
        const rawContext = ev.target.value
        const context = rawContext.length > 0 ? rawContext : null

        setProject(prev => {
            if (prev?.source !== "interview") return prev
            return { ...prev, interview_settings: { ...prev.interview_settings, context } }
        })
    }, [setProject])
    // #endregion

    // #region Debounced inputs
    const { debouncedOnChange } = useDebouncedInputChange({ callback: handleChangeProjectContext })
    // #endregion

    if (project?.source !== "interview") return <></>

    return (
        <div className="flex flex-col flex-1 gap-[0.5em]">
            <OutlineTabContentDefaultTitle
                title={getCopy(copy.outline.overview.projectContext) ?? ""}
                subtitle={getCopy(copy.outline.overview.provideAnyValuableInformationForGlaut) ?? ""}
            />
            <GlautTextAreaMultiLine
                className="flex-1"
                placeholder={getCopy(copy.outline.overview.weAreInterviewingYoungAdults) ?? ""}
                defaultValue={project?.interview_settings?.context ?? ""}
                disabled={!canEdit}
                onChange={debouncedOnChange}
            />
        </div>
    )
}