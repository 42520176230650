import { IconType } from "react-icons/lib"
import { useOutlineTab } from "../../contexts/OutlineTabProvider"
import { useMemo } from "react"

interface IOutlineTabSidebarProjectSetupOptionProps {
    id: string
    onClick: () => void
    label: string
    Icon: IconType
}

export default function OutlineTabSidebarProjectSetupOption({
    id,
    label,
    Icon,
    onClick
}: Readonly<IOutlineTabSidebarProjectSetupOptionProps>) {
    // #region Contexts
    const { sidebarSelectedOption } = useOutlineTab()
    // #endregion

    // #region Memos
    const isOptionSelected = useMemo(() => sidebarSelectedOption === id, [id, sidebarSelectedOption])
    // #endregion

    return (
        <button
            key={id}
            onClick={onClick}
            className={`flex items-center gap-[1em] py-[0.625em] px-[0.75em] rounded-[0.25em] w-full
                justify-start border-none shadow-none group
                hover:bg-glaut-very-light-grey
                ${isOptionSelected ? "bg-glaut-very-light-grey" : "bg-transparent"}
            `}
        >
            <Icon className={`w-[1.25em] h-[1.25em]
                group-hover:text-glaut-text-midnight
                ${isOptionSelected ? "text-glaut-text-midnight" : "text-glaut-dark-grey"}
            `} />
            <p className={`text-[13.33px] 
                group-hover:text-glaut-text-midnight group-hover:font-medium
                ${isOptionSelected ? "font-medium text-glaut-text-midnight" : "text-glaut-dark-grey"}
            `}>
                {label}
            </p>
        </button>
    )
}