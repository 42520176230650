import { languages } from "@utils/Variables"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useLegacyProject } from "../../contexts/ProjectProvider"
import OutlineTabContentOverviewInterviewLanguageItem from "../OutlineTabContentOverviewInterviewLanguageItem"
import OutlineTabContentDefaultTitle from "../OutlineTabContentDefaultTitle"
import { copy, getCopy } from "@utils/Copy"
import { useOutlineTab } from "../../contexts/OutlineTabProvider"
import { MdAddCircleOutline } from "react-icons/md"
import ConfirmModal from "@components/Modals/ConfirmModal"
import { glautTooltipId } from "@components/layouts/GlautTooltip/constants/id"
import GlautButtonSecondary from "@components/Buttons/GlautButtonSecondary"

export default function OutlineTabContentOverviewInterviewLanguages() {
    // #region Contexts
    const { project, setProject, forceSave } = useLegacyProject()
    const { canEdit } = useOutlineTab()
    // #endregion

    // #region States
    const [isAddingNewLanguage, setIsAddingNewLanguage] = useState(
        project?.general_settings.supported_languages.length === 0
    )
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
    // #endregion

    // #region Refs
    const currLangToBeDeletedRef = useRef<string>()
    // #endregion

    // #region Memos
    const supportedLanguages = useMemo(() => {
        if (!project) return []

        const setLangs: Set<string> = new Set(project.general_settings.supported_languages)
        if (!setLangs.has(project.general_settings.language))
            setLangs.add(project.general_settings.language)

        return [...setLangs].sort((a, b) => {
            if (a === project.general_settings.language) return -1
            if (b === project.general_settings.language) return 1

            return 0
        })
    }, [project])

    const availableLanguages = useMemo(() => {
        if (!project) return languages

        return Object.entries(languages).reduce((prev, [langCode, langData]) => {
            if (project.general_settings.supported_languages.includes(langCode)) return prev
            if (langCode === project.general_settings.language) return prev

            prev[langCode] = langData
            return prev
        }, {} as typeof languages)
    }, [project])
    // #endregion

    // #region Callbacks
    const handleSelectInterviewLanguage = useCallback((selectedLang: string, currLang?: string) => {
        setProject(prev => {
            if (!prev) return prev
            if (!currLang) { // adding new
                setIsAddingNewLanguage(false)

                return {
                    ...prev,
                    general_settings: {
                        ...prev.general_settings,
                        supported_languages: [...prev.general_settings.supported_languages, selectedLang]
                    }
                }
            }

            // replacing existent
            const langToBeReplacedIdx = prev.general_settings.supported_languages.findIndex(l => l === currLang)
            if (langToBeReplacedIdx === -1) return prev

            const newSupportedLanguages = [...prev.general_settings.supported_languages]
            newSupportedLanguages.splice(langToBeReplacedIdx, 1, selectedLang)

            return {
                ...prev,
                general_settings: {
                    ...prev.general_settings,
                    supported_languages: newSupportedLanguages
                }
            }
        })
    }, [setProject])

    const handleAddNewLanguage = useCallback(() => {
        setIsAddingNewLanguage(true)
    }, [])

    const handleOpenDeleteModal = useCallback((lang: string) => {
        currLangToBeDeletedRef.current = lang
        setIsDeleteModalOpen(true)
    }, [])

    const handleCloseDeleteModal = useCallback(() => {
        currLangToBeDeletedRef.current = undefined
        setIsDeleteModalOpen(false)
    }, [])

    const handleConfirmDeleteModal = useCallback(() => {
        if (!project) return

        // forceSave to avoid UX issues (delay to remove a second language fast enough)
        forceSave({
            ...project,
            general_settings: {
                ...project.general_settings,
                supported_languages: project.general_settings.supported_languages.filter(
                    l => l !== currLangToBeDeletedRef.current
                )
            }
        })
    }, [forceSave, project])
    // #endregion

    // #region Effects
    useEffect(() => {
        if (project?.source !== "interview" || ["not_set", "set_up"].includes(project?.status ?? "")) return
        setIsAddingNewLanguage(false)
    }, [project])
    // #endregion

    if (!project) return <></>

    return (
        <>
            <div className="flex">
                <div className="flex-1">
                    <OutlineTabContentDefaultTitle
                        title={getCopy(copy.outline.overview.interviewLanguages) ?? ""}
                        subtitle={getCopy(copy.outline.overview.selectWhichLanguagesToRunInterviewsIn) ?? ""}
                    />
                </div>
                <GlautButtonSecondary
                    className="stretch"
                    onClick={handleAddNewLanguage}
                    disabled={!canEdit}
                    data-tooltip-id={glautTooltipId}
                    data-tooltip-content={getCopy(copy.outline.overview.addTranslation)}
                    data-tooltip-hidden={!canEdit}
                    squared
                >
                    <MdAddCircleOutline className="w-[1.125rem] h-[1.125rem]" />
                </GlautButtonSecondary>
            </div>
            <div className="flex flex-wrap gap-[0.75rem] self-center items-center w-full">
                {supportedLanguages.map(lang => (
                    <OutlineTabContentOverviewInterviewLanguageItem
                        key={lang}
                        lang={lang}
                        languages={{ ...availableLanguages, [lang]: languages[lang] }}
                        onRemove={lang !== project.general_settings.language
                            ? handleOpenDeleteModal
                            : undefined}
                        onSelectNewLanguage={handleSelectInterviewLanguage}
                    />
                ))}
                {isAddingNewLanguage && (
                    <OutlineTabContentOverviewInterviewLanguageItem
                        languages={availableLanguages}
                        onSelectNewLanguage={handleSelectInterviewLanguage}
                    />
                )}
            </div>
            <ConfirmModal
                open={isDeleteModalOpen}
                onClose={handleCloseDeleteModal}
                onConfirm={handleConfirmDeleteModal}
            >
                <p className="text-[13.33px] font-medium text-glaut-text-midnight">
                    {getCopy(copy.outline.overview.areYouSureYouWantToDeleteThisInterLang)}
                </p>
            </ConfirmModal>
        </>
    )
}