import { copy, getCopy } from "@utils/Copy"
import { MdOutlineSms } from "react-icons/md"
import GlautInfoBottomIcon from "@assets/glaut-info-bottom-icon.svg"

interface IAnalysisTabEmptyStateProps {
    state?: "empty" | "processing-first"
}

export default function AnalysisTabEmptyState({
    state
}: Readonly<IAnalysisTabEmptyStateProps>) {
    return (
        <div className="flex flex-col w-full items-center">
            <div className="header flex flex-row mb-[0.75em] self-start">
                <span className="text-[13.33px] text-[rgb(0,0,0,0.6)] mt-[0.75em] ml-[0.75em]">
                    {getCopy(copy.coding.results)}
                </span>
            </div>
            <div className="flex-1 flex flex-col w-full justify-center items-center">
                <div className={`flex rounded-full w-[7.1875em] h-[7.1875em] justify-center items-center mb-[1em]
                    bg-glaut-cards    
                `}>
                    <MdOutlineSms
                        className="text-white overflow-visible -rotate-[11deg]"
                        style={{ height: "82px", width: "82px" }} />
                </div>
                <p className="text-[13.33px] font-medium text-glaut-stroke-button">
                    {getCopy(
                        state === "processing-first"
                            ? copy.coding.theAnalysisIsBeingProcessed
                            : copy.coding.createOneAnalysisToStart
                    )}
                </p>
                {state === "processing-first" && (
                    <p className="text-[11.11px] font-medium text-glaut-stroke-button">
                        {getCopy(copy.coding.resultsWillAppearSoon)}
                    </p>
                )}
                <img src={GlautInfoBottomIcon} alt="Info" className="mt-[0.25em]" />
            </div>
        </div>
    )
}