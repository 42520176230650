import { IInterviewQuestion } from "@/@types/interview-question"
import { copy, getCopy } from "@utils/Copy"
import { useMemo } from "react"
import { useLegacyProject, useProject } from "../../contexts/ProjectProvider"
import { allowedOperators } from "@utils/project/filters"

interface IBranchLogic {
    id: string
    iqLabel: string
    operator: string
    options: { id: string; label: string }[]
}

interface IOutlineTabContentIqsQuestionListItemBranchComponentProps {
    interviewQuestion: Partial<IInterviewQuestion>
}

export default function OutlineTabContentIqsQuestionListItemBranchComponent({
    interviewQuestion
}: Readonly<IOutlineTabContentIqsQuestionListItemBranchComponentProps>) {
    // #region Contexts
    const { projectQuestions } = useProject()
    const { lang } = useLegacyProject()
    // #endregion

    // #region Memos
    const branchLogics: IBranchLogic[] = useMemo(() => (interviewQuestion.filters ?? []).map(filter => {
        const iq = projectQuestions?.find(iq => iq.id === filter.id)
        if (!iq || !["select", "nps", "scale"].includes(iq.type)) return null

        const operatorLabel = getCopy(allowedOperators.find(op => op.id === filter.operator)?.label, lang) ?? ""

        if (iq.type === "select") {
            const options: { id: string; label: string }[] = iq.options.filter(
                option => Array.isArray(filter.value)
                    ? filter.value.map(String).includes(option.id)
                    : filter.value === option.id
            ).map(opt => ({ id: opt.id, label: opt.label[lang] ?? "" }))

            return {
                id: `${iq.id}-${filter.operator}`,
                iqLabel: iq.content[lang] ?? iq.header ?? "",
                operator: operatorLabel,
                options
            }
        }

        if (iq.type === "nps" || iq.type === "scale")
            return {
                id: `${iq.id}-${filter.operator}`,
                iqLabel: iq.content[lang] ?? iq.header ?? "",
                operator: operatorLabel,
                options: Array.isArray(filter.value)
                    ? filter.value.map(val => ({ id: val, label: val }))
                    : [{ id: String(filter.value), label: String(filter.value) }]
            }

        return null
    }).filter(i => !!i), [interviewQuestion.filters, projectQuestions, lang])
    // #endregion

    return (
        <div className="flex flex-col gap-[0.5rem] rounded-[0.5rem] text-start max-w-[500px]
            bg-glaut-cards">
            <p className="text-[11.11px] font-medium text-glaut-dark-grey">
                {getCopy(copy.outline.interviewQuestions.branchLogics)}
            </p>
            {branchLogics.map(filter => (
                <div key={filter.id} className="flex gap-[0.5rem] items-center">
                    <p className="text-[11.11px] font-medium py-[0.25rem] px-[0.5rem] border-1 rounded-[0.125rem]
                        text-glaut-text-midnight max-w-[230px]
                        bg-glaut-cards
                        border-glaut-stroke-glaut">
                        {filter.iqLabel}
                    </p>
                    <p className="text-[11.11px] font-medium py-[0.25rem] px-[0.5rem] rounded-[0.125rem]
                        text-glaut-text-midnight
                        bg-glaut-very-light-grey">
                        {filter.operator}
                    </p>
                    <div className="flex flex-col gap-[0.25rem] max-w-[230px]">
                        {filter.options.map(opt => (
                            <p
                                key={opt.id}
                                className="text-[11.11px] font-medium py-[0.25rem] px-[0.5rem] border-1 text-start
                                    rounded-[0.125rem]
                                    text-glaut-text-midnight
                                    bg-glaut-cards
                                    border-glaut-stroke-glaut">
                                {opt.label}
                            </p>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    )
}