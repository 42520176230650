interface IRespondentMessageProps {
    message: string
}

export default function RespondentMessage({ message }: Readonly<IRespondentMessageProps>) {
    return (
        <div className="flex justify-end" role="log" aria-label="Respondent message">
            <p className="text-[13.33px] py-[0.75rem] px-[1rem] text-start max-w-[80%]
                rounded-s-[0.75rem] rounded-se-[0.75rem] rounded-ee-none
                bg-[#EDEDED] text-glaut-text-midnight">
                {message}
            </p>
        </div>
    )
}
