import { IInterviewQuestion, IQuestion } from "@/@types/interview-question"
import GlautCommonQuestionCreationForm from "@components/Forms/GlautCommonQuestionCreationForm"
import Loading from "@components/loading/Loading"
import { useAnalysisService } from "@hooks/services/useAnalysisService"
import { useProjectService } from "@hooks/services/useProjectService"
import { RecursivePartial } from "@utils/types/recursive-partial"
import { ChangeEventHandler, useCallback, useEffect, useMemo, useState } from "react"
import { v4 as uuid } from "uuid"
import { useOutlineTabIqs, useOutlineTabIqsDispatch } from "../../contexts/OutlineTabIqsProvider"
import { useOutlineTab } from "../../contexts/OutlineTabProvider"
import { useLegacyProject, useProject } from "../../contexts/ProjectProvider"
import ExAnteAnalysis from "../OutlineTabContentIqsQuestionFormBodyExAnteAnalysis"
import Probing from "../OutlineTabContentIqsQuestionFormBodyProbing"
import RateConfig from "../OutlineTabContentIqsQuestionFormBodyRateConfig"
import SelectOptions from "../OutlineTabContentIqsQuestionFormBodySelectOptions"

export default function OutlineTabContentIqsQuestionFormBody() {
    // #region Contexts
    const { project, lang } = useLegacyProject()
    const { projectQuestions } = useProject()
    const { canEdit } = useOutlineTab()
    const { iqToBeBuilt, isEditing } = useOutlineTabIqs()
    const outlineTabIqsDispatch = useOutlineTabIqsDispatch()
    // #endregion

    // #region Services
    const projectService = useProjectService()
    const analysisService = useAnalysisService()
    // #endregion

    // #region States
    const [isLoadingAnalyses, setIsLoadingAnalyses] = useState(false)
    // #endregion

    // #region Memos
    const assetsValue = useMemo(() => ({
        image: iqToBeBuilt?.img,
        video: iqToBeBuilt?.embed_url,
        "url-parameter": iqToBeBuilt?.header
    }), [iqToBeBuilt?.embed_url, iqToBeBuilt?.header, iqToBeBuilt?.img])

    const questionIdx = useMemo(() => {
        if (!iqToBeBuilt?.id) return -1

        const idx = (projectQuestions ?? []).findIndex(q => q.id === iqToBeBuilt.id)
        return idx === -1 ? (projectQuestions?.length ?? 0) : idx
    }, [projectQuestions, iqToBeBuilt?.id])

    const shouldAllowAddingFilters = useMemo(
        () => projectQuestions?.find(
            (iq, idx) => (questionIdx === -1 || idx < questionIdx) && (["select", "nps", "scale"].includes(iq.type))
        ) !== undefined,
        [projectQuestions, questionIdx]
    )
    // #endregion

    // #region Callbacks
    const handleChangeImage = useCallback((image: File | null | undefined) => {
        if (!project) return

        if (!image) {
            outlineTabIqsDispatch({ type: "set-iq-attribute", attribute: "img", value: image })
            return
        }

        const projectId = project._id
        const questionId = uuid()

        projectService.setInterviewQuestionImage({ projectId, questionId, image }).then(url => {
            outlineTabIqsDispatch({ type: "set-iq-attribute", attribute: "img", value: url })
        })
    }, [outlineTabIqsDispatch, project, projectService])

    const handleChangeVideo = useCallback((url: string | null | undefined) => {
        outlineTabIqsDispatch({ type: "set-iq-attribute", attribute: "embed_url", value: url })
    }, [outlineTabIqsDispatch])

    const handleChangeUrlParameter = useCallback((value: string | null | undefined) => {
        outlineTabIqsDispatch({ type: "set-iq-attribute", attribute: "header", value })
    }, [outlineTabIqsDispatch])

    const handleChangeQuestionContent: ChangeEventHandler<HTMLTextAreaElement> = useCallback(e => {
        const questionContent = { ...(iqToBeBuilt?.content ?? {}), [lang]: e.target.value }
        outlineTabIqsDispatch({ type: "set-iq-attribute", attribute: "content", value: questionContent })
    }, [iqToBeBuilt?.content, lang, outlineTabIqsDispatch])

    const setQuestion = useCallback((callback: RecursivePartial<IQuestion> | (
        (iq: RecursivePartial<IQuestion>) => RecursivePartial<IQuestion>)
    ) => {
        if (!iqToBeBuilt) return

        const newIq = typeof callback === "object" ? callback : callback(iqToBeBuilt)
        outlineTabIqsDispatch({ type: "set-iq-to-be-built", iq: newIq as Partial<IInterviewQuestion>, isEditing })
    }, [iqToBeBuilt, isEditing, outlineTabIqsDispatch])
    // #endregion

    // #region Effects

    // onChangeIqFetchAnalyses
    useEffect(() => {
        if (!project || !iqToBeBuilt?.id || iqToBeBuilt.type !== "open") return

        setIsLoadingAnalyses(true)

        analysisService.getAnalyses({ projectId: project._id }).then(res => {
            const relevantAnalyses = res.analyses.filter(a => a.sources.length === 1 && a.sources[0] === iqToBeBuilt.id)
            outlineTabIqsDispatch({
                type: "replace-analyses-to-iq-to-be-built",
                analyses: relevantAnalyses,
                isEditing: true,
                shouldReloadOriginalIq: true
            })
        }).finally(() => {
            setIsLoadingAnalyses(false)
        })
    }, [analysisService, iqToBeBuilt?.id, iqToBeBuilt?.type, outlineTabIqsDispatch, project])

    // #endregion

    if (!iqToBeBuilt) return <></>

    return (
        <div className="flex-1 flex flex-col gap-[2em] h-full overflow-auto pb-5 pr-1">
            <GlautCommonQuestionCreationForm
                iqToBeBuilt={iqToBeBuilt}
                shouldAllowAddingFilters={shouldAllowAddingFilters}
                lang={lang}
                assets={{
                    values: assetsValue,
                    onChange: {
                        image: handleChangeImage,
                        video: handleChangeVideo,
                        "url-parameter": handleChangeUrlParameter
                    }
                }}
                title={{
                    value: iqToBeBuilt.content?.[lang] ?? "",
                    onChange: handleChangeQuestionContent
                }}
                setQuestion={setQuestion}
                disabled={!canEdit}
                filtersDomain="all"
                withAssetComponent={iqToBeBuilt?.type !== "asset"}
            />
            {iqToBeBuilt?.type === "select" && (<SelectOptions />)}
            {(iqToBeBuilt?.type === "nps" || iqToBeBuilt?.type === "scale") && (<RateConfig />)}
            {iqToBeBuilt?.type !== "asset" && (<Probing />)}
            {iqToBeBuilt?.type === "open" && isLoadingAnalyses && (<Loading />)}
            {iqToBeBuilt?.type === "open" && !isLoadingAnalyses && (<ExAnteAnalysis />)}
        </div>
    )
}