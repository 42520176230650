import ModalLayout from "@components/layouts/ModalLayout"
import {
    useAnalysisTabAnalysisCol,
    useAnalysisTabAnalysisColDispatch
} from "../../contexts/AnalysisTabAnalysisColProvider"
import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import GlautButtonSecondary from "@components/Buttons/GlautButtonSecondary"
import GlautButtonPrimary from "@components/Buttons/GlautButtonPrimary"
import { getCopy, copy } from "@utils/Copy"
import { useAnalysisTab, useAnalysisTabDispatch } from "../../contexts/AnalysisTabProvider"
import { useAnalysisService } from "@hooks/services/useAnalysisService"
import { IProject } from "src/@types/project"
import { ProjectContext } from "../../../AutoSaveProject"

export default function AnalysisTabAnalysisColDeleteThemeOrCodeModal() {
    // #region Contexts
    const { project } = useContext(ProjectContext) as { project: IProject | null }
    const { selectedAnalysis, selectedCategory } = useAnalysisTab()
    const analysisTabDispatch = useAnalysisTabDispatch()
    const { categoryBeingDeleted } = useAnalysisTabAnalysisCol()
    const analysisTabAnalysisColDispatch = useAnalysisTabAnalysisColDispatch()
    // #endregion

    // #region Services
    const analysisService = useAnalysisService()
    // #endregion

    // #region States
    const [confirmationQuestionContent, setConfirmationQuestionContent] = useState("")
    // #endregion

    // #region Memos
    const isOpen = useMemo(() => categoryBeingDeleted !== undefined, [categoryBeingDeleted])
    // #endregion

    // #region Callbacks
    const handleCloseModal = useCallback(() => {
        analysisTabAnalysisColDispatch({ type: "clear-category-being-deleted" })
    }, [analysisTabAnalysisColDispatch])

    const handleDeleteThemeOrCode = useCallback(() => {
        if (!project || !selectedAnalysis || !categoryBeingDeleted) return

        const projectId = project._id
        const analysisId = selectedAnalysis.id
        const categoryId = categoryBeingDeleted.id

        analysisService.deleteAnalysisCategory({ analysisId, categoryId, projectId }).then(({ analysisStats }) => {
            if (selectedCategory) analysisTabDispatch({ type: "clear-selected-category" })
            analysisTabDispatch({ type: "remove-category-from-analysis", analysisId, categoryId, analysisStats })
        })
    }, [analysisService, analysisTabDispatch, categoryBeingDeleted, project, selectedAnalysis, selectedCategory])
    // #endregion

    // #region Effects

    // onDeletingCodeUpdateQuestionContent
    useEffect(() => {
        if (categoryBeingDeleted === undefined) return
        setConfirmationQuestionContent(getCopy(
            categoryBeingDeleted.type === "theme"
                ? copy.coding.areYouSureYouWantToDeleteThisTheme
                : copy.coding.areYouSureYouWantToDeleteThisCode
        ) ?? "")
    }, [categoryBeingDeleted])

    // #endregion

    return (
        <ModalLayout
            isOpen={isOpen}
            onClose={() => { analysisTabAnalysisColDispatch({ type: "clear-category-being-deleted" }) }}
            className={`
                flex flex-col bg-white rounded-[0.25em] shadow px-4 py-6 gap-3 transition-all w-56 border-[1px]
                border-glaut-grey
                ${isOpen ? "scale-100 opacity-100" : "scale-125 opacity-0"}
            `}
        >
            <div className="flex justify-center items-center text-center">
                <p className="text-[13.33px] font-medium text-glaut-text-midnight">
                    {confirmationQuestionContent}
                </p>
            </div>
            <div className="flex flex-row justify-between">
                <GlautButtonSecondary onClick={handleCloseModal}>
                    {getCopy(copy.coding.cancel)}
                </GlautButtonSecondary>
                <GlautButtonPrimary onClick={() => {
                    handleDeleteThemeOrCode()
                    handleCloseModal()
                }}>
                    {getCopy(copy.coding.delete)}
                </GlautButtonPrimary>
            </div>
        </ModalLayout>
    )
}