import { conversationOverlayCopy } from "@components/ConversationOverlay/utils/copy"
import { getCopy } from "@utils/Copy"
import { MdOutlineChat } from "react-icons/md"

export default function ConversationOverlayTranscriptHeader() {
    return (
        <div className="flex gap-[0.25em] items-center px-[1.125em] py-[0.25em]">
            <MdOutlineChat className="rounded-none h-[1em] w-[1em] text-glaut-text-midnight" />
            <p className="text-[13.33px] font-medium text-glaut-text-midnight">
                {getCopy(conversationOverlayCopy.conversation)}
            </p>
        </div>
    )
}