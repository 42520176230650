import { ButtonHTMLAttributes, DetailedHTMLProps, forwardRef } from "react"
import { MdAdd } from "react-icons/md"

interface IPreviewButtonProps extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    onPreview?: (show: boolean) => void
}

const PreviewButton = forwardRef<HTMLButtonElement, Readonly<IPreviewButtonProps>>(({
    children,
    onClick,
    onPreview,
    disabled,
    className
}, ref) => (
    <button
        className={`rounded-full text-xs font-medium border-1 shadow-none py-0.5 px-2 gap-0 transition-all
            bg-glaut-cards border-glaut-very-light-grey
            disabled:bg-glaut-cards disabled:border-glaut-very-light-grey disabled:hover:text-glaut-grey
            enabled:hover:gap-[0.3125em]
            hover:bg-glaut-dark-grey hover:border-glaut-grey hover:text-glaut-off-white group
            ${className}
        `}
        onMouseEnter={() => { onPreview && !disabled && onPreview(true) }}
        onMouseLeave={() => { onPreview && !disabled && onPreview(false) }}
        onClick={onClick}
        disabled={disabled}
        ref={ref}
    >
        <MdAdd className="transition-all h-4 text-glaut-off-white w-0 opacity-0
            group-disabled:w-0
            group-hover:w-4 group-hover:opacity-100"/>
        {children}
    </button>
))

PreviewButton.displayName = "TemplateButton"

export default PreviewButton