import { IQuestion } from "@/@types/interview-question"
import { IProjectGeneralSettings } from "@/@types/project"
import { getAllDescendantsIds } from "@utils/nesting"
import { RecursivePartial } from "@utils/types/recursive-partial"
import { useMemo } from "react"
import { v4 as uuid } from "uuid"

export const useEditQuestion = (
    question?: RecursivePartial<IQuestion>,
    lang: string = "en",
    projectGeneralSettings?: IProjectGeneralSettings
) => {
    // #region Memos
    const valid = useMemo(() => {
        if (!question) return false

        if (!question.content?.[lang]) return false

        // If we haven't defined the header and question, it's not valid
        if (question.content?.[lang]?.length > 200 || question.content?.[lang]?.length < 4) return false

        // If the question is a multiple choice with empty option, the question is not valid
        if (question.type === "select" && question.options?.filter(o => (o.label?.[lang]?.length ?? 0) < 1).length)
            return false

        if (question.type === "select" && question.domain === "screening") {
            const quotaSum = question.options?.reduce((acc, curr) => acc + (curr.quota ?? 0), 0)
            if (question.screening_type === "quota") {
                if (quotaSum !== 100) return false
                if (!projectGeneralSettings?.max_responses) return false
            }
            const optionsWithoutAction = question.options?.filter(o => !o.action) ?? []
            if (question.screening_type === "screener" && optionsWithoutAction.length > 0) return false
        }

        // Else it's valid
        return true
    }, [question, lang, projectGeneralSettings?.max_responses])
    // #endregion

    return { valid }
}

export const useEditQuestionOptions = (setQuestion, lang, key = "options") => {
    // #region Callbacks
    const generateBaseOption = () => {
        if (key === "options") return { label: { [lang]: "" }, id: uuid() }
        // else if (key === "buckets")
        return { name: "", id: uuid() }
    }

    const addOption = ({ parent }: { parent?: unknown } = {}) =>
        setQuestion(p => ({
            ...p,
            [key]: [...(p[key] || []), { ...generateBaseOption(), parent }]
        }))

    const editOption = (value, oId) =>
        setQuestion(p => ({
            ...p,
            [key]: p[key].map(o => {
                if (o.id === oId) {
                    if (key === "options") return { ...o, label: { ...o.label, [lang]: value } }
                    if (key === "buckets") return { ...o, name: value }
                }
                return o
            })
        }))

    const deleteOption = optionId =>
        setQuestion(q => {
            const idsToRemove = [...getAllDescendantsIds(q[key], optionId), optionId]
            const newOptions = q[key].filter(o => !idsToRemove.includes(o.id))
            return {
                ...q,
                [key]: newOptions,
                max_choices: Math.min(q.max_choices, newOptions.length)
            }
        })
    // #endregion

    return { addOption, editOption, deleteOption }
}
