import { IQuestion, IQuestionDomain } from "@/@types/interview-question"
import Branch from "@components/Icons/Branch"
import GlautAssetComponent from "@components/inputs/GlautAssetComponent"
import GlautTextAreaMultiLine from "@components/inputs/GlautTextAreaMultiLine"
import { QuestionFilters } from "@components/Popups/EditQuestionPopup/components/QuestionFilter"
import { copy, getCopy } from "@utils/Copy"
import { normalizeQuestionOrOption } from "@utils/strings"
import { RecursivePartial } from "@utils/types/recursive-partial"
import { ChangeEventHandler, useCallback, useEffect, useState } from "react"

interface IGlautCommonQuestionCreationFormProps {
    iqToBeBuilt: RecursivePartial<IQuestion>
    shouldAllowAddingFilters?: boolean
    lang: string
    disabled?: boolean
    assets: {
        values: {
            image: string | null | undefined
            video: string | null | undefined
            "url-parameter": string | null | undefined
        }
        onChange: {
            image: (image: File | null | undefined) => void
            video: (url: string | null | undefined) => void
            "url-parameter": (value: string | null | undefined) => void
        }
    }
    title: {
        value: string
        onChange: ChangeEventHandler<HTMLTextAreaElement>
    }
    setQuestion: (callback: RecursivePartial<IQuestion> | (
        (iq: RecursivePartial<IQuestion>) => RecursivePartial<IQuestion>)
    ) => void
    filtersDomain?: IQuestionDomain | "all"
    withAssetComponent?: boolean
}

export default function GlautCommonQuestionCreationForm({
    iqToBeBuilt,
    lang,
    assets,
    setQuestion,
    title,
    shouldAllowAddingFilters = false,
    disabled = false,
    filtersDomain = "interview",
    withAssetComponent = false
}: Readonly<IGlautCommonQuestionCreationFormProps>) {
    // #region States
    const [isShowingFilters, setIsShowingFilters] = useState(false)
    // #endregion

    // #region Callbacks
    const handleToggleFiltersVisibility = useCallback(() => {
        setIsShowingFilters(prev => !prev)
    }, [])
    // #endregion

    // #region Effects

    // onChangeIqCloseFilters
    useEffect(() => {
        setIsShowingFilters(false)
    }, [iqToBeBuilt?.id])

    // #endregion

    return (
        <div className="flex flex-col gap-[2em]">
            {shouldAllowAddingFilters && isShowingFilters && (
                <QuestionFilters
                    question={iqToBeBuilt}
                    setQuestion={setQuestion}
                    lang={lang}
                    disabled={disabled}
                    filtersDomain={filtersDomain}
                />
            )}
            <div className="flex flex-col gap-[0.5em]">
                <div className="flex justify-between items-center">
                    <p className="text-[13.33px] font-medium text-glaut-text-midnight self-end pt-[0.5em]">
                        {getCopy(copy.outline.interviewQuestions.defineTheQuestion)}
                    </p>
                    {shouldAllowAddingFilters && (
                        <button
                            className={`flex gap-[0.5em] items-center py-0 px-[0.5rem] border-1 rounded-[0.25rem]
                                shadow-none text-base
                                ${isShowingFilters ? "bg-glaut-dummie-color border-glaut-grey" : ""}
                                ${!isShowingFilters ? "bg-transparent border-transparent" : ""}
                            `}
                            onClick={handleToggleFiltersVisibility}
                        >
                            <Branch className="fill-glaut-dark-grey w-[1em] h-[1em]" />
                            <p className="text-[11.11px] font-medium text-glaut-dark-grey underline">
                                {getCopy(copy.outline.interviewQuestions.createABranchLogic)}
                            </p>
                        </button>
                    )}
                </div>
                <GlautTextAreaMultiLine
                    placeholder={getCopy(copy.outline.interviewQuestions.writeYourQuestionHereEllipsis) ?? ""}
                    withBiggerTextSize
                    style={{ height: "4em" }}
                    value={iqToBeBuilt.content?.[lang] ?? ""}
                    onChange={title.onChange}
                    onBlur={e => title.onChange({
                        ...e,
                        target: { ...e.target, value: normalizeQuestionOrOption(e.target.value) }
                    })}
                    disabled={disabled}
                />
                {withAssetComponent && (
                    <div className="flex gap-[1.25em]">
                        <GlautAssetComponent
                            disabled={disabled}
                            value={assets.values}
                            onChange={assets.onChange}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}