import IProjectGateway from "@services/projects/IProjectGateway"
import { useMemo } from "react"

type MetricsState = Awaited<ReturnType<IProjectGateway["getMetrics"]>>

export function useMetricsIndicators(metrics?: MetricsState) {
    // #region Memos
    const completionRate = useMemo(() => {
        if (metrics === undefined) return 0
        if (metrics.started === 0) return 0

        const percentage = (metrics.completed / (metrics.started)) * 100
        return Math.round(percentage)
    }, [metrics])

    const desktopPercentage = useMemo(() => {
        if (metrics?.platform_split.desktop === undefined) return 0

        const mobile = metrics.platform_split.mobile ?? 0
        const tablet = metrics.platform_split.tablet ?? 0
        const desktop = metrics.platform_split.desktop ?? 0

        const divisor = (desktop + mobile + tablet)

        if (divisor === 0) return 0

        const percentage = (desktop / divisor) * 100
        return Math.round(percentage)
    }, [metrics])

    const mobilePercentage = useMemo(() => {
        if (metrics?.platform_split.mobile === undefined && metrics?.platform_split.tablet === undefined) return 0

        const mobile = metrics.platform_split.mobile ?? 0
        const tablet = metrics.platform_split.tablet ?? 0
        const desktop = metrics.platform_split.desktop ?? 0

        const divisor = (desktop + mobile + tablet)

        if (divisor === 0) return 0

        const percentage = ((mobile + tablet) / divisor) * 100
        return Math.round(percentage)
    }, [metrics])

    const avgInterviewLengthMinutes = useMemo(() => {
        if (metrics === undefined || metrics.avg_interaction_time < 0) return 0
        return String(Math.floor(metrics.avg_interaction_time / 60)).padStart(2, "0")
    }, [metrics])

    const avgInterviewLengthSeconds = useMemo(() => {
        if (metrics === undefined || metrics.avg_interaction_time < 0) return 0
        return String(Math.round(metrics.avg_interaction_time % 60)).padStart(2, "0")
    }, [metrics])
    // #endregion

    return {
        completionRate,
        desktopPercentage,
        mobilePercentage,
        avgInterviewLengthMinutes,
        avgInterviewLengthSeconds
    }
}