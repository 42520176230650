import posthog from "posthog-js"
import * as Sentry from "@sentry/react"

export const updateUserInfo = (user, interviewSettings) => {
    if (user) {
        posthog.identify(
            user.id,
            user
        )
        Sentry.setUser(user)
    }
    if (interviewSettings?.project_id)
        posthog.group("project", interviewSettings?.project_id, {
            name: interviewSettings?.project_name
        })
    if (interviewSettings?.workspace_id)
        posthog.group("workspace", interviewSettings?.workspace_id, {
            name: interviewSettings?.workspace_name
        })
    if (interviewSettings?.organization_id)
        posthog.group("organization", interviewSettings?.organization_id, {
            name: interviewSettings?.organization_name
        })
}

export const trackEvent = (eventName, properties, error) => {
    posthog.capture(eventName, properties)
    if (error) Sentry.captureMessage(error)
    
    console.log(`Tracked event: ${eventName}`, properties, error)
}
