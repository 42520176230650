export const interviewTerminatedQuotaFullCopy = {
    message: {
        en: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.2px]">
                Sorry!<br />
                All seats<br />
                <span className="text-glaut-pink font-medium">are taken.</span>
            </p>
        ),
        it: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.2px]">
                Mi dispiace!<br />
                Tutti i posti <br />
                <span className="text-glaut-pink font-medium">sono occupati.</span>
            </p>
        ),
        es: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.2px]">
                Lo sentimos!<br />
                Todos los asientos<br />
                <span className="text-glaut-pink font-medium">están ocupados.</span>
            </p>
        ),
        fr: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.2px]">
                Désolé!<br />
                Tous les sièges<br />
                <span className="text-glaut-pink font-medium">sont occupés.</span>
            </p>
        ),
        de: (
            <p className="text-[36px] text-glaut-midnight font-medium leading-[39.2px]">
                Leider,<br />
                Alle Sitze<br />
                <span className="text-glaut-pink font-medium">sind besetzt.</span>
            </p>
        )
    },
    detail: {
        en: "Thank you, but this project has already been completed.",
        it: "Grazie, ma questo progetto è già stato completato.",
        es: "Gracias, pero este proyecto ya ha sido completado.",
        fr: "Merci, mais ce projet a déjà été terminé.",
        de: "Danke, aber dieses Projekt wurde bereits abgeschlossen."
    }
}