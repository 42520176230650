import { IQuestionItem } from "../../AnalysisTabQuestionsCol/utils/types"
import { isQuestionItemAnAnalysis } from "../../AnalysisTabQuestionsCol/utils/is-question-item-an-analysis"

const ITEM_TYPE = {
    ANALYSIS: "analysis",
    IQ: "iq"
} as const

type IItemType = typeof ITEM_TYPE[keyof typeof ITEM_TYPE]

function isItemType(input: string): input is IItemType {
    return Object.values(ITEM_TYPE).some(itemType => itemType === input)
}

export function buildBreakdownSelectedString(item: IQuestionItem | { type: IItemType; id: string }) {
    if (!item.id || item.id.includes("--")) throw new Error("Invalid ID format")

    if (isItemType(item.type))
        return `${item.type}--${item.id}`

    return `${isQuestionItemAnAnalysis(item as IQuestionItem) ? ITEM_TYPE.ANALYSIS : ITEM_TYPE.IQ}--${item.id}`
}

export function parseBreakdownSelectedString(string: string) {
    const [type, id] = string.split("--")

    if (!type || !id) return undefined

    const itemTypeMatches = Object.values(ITEM_TYPE).some(itemType => itemType === type)
    if (!itemTypeMatches) return undefined

    return { type: type as IItemType, id }
}