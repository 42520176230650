import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { useFeatureFlagEnabled } from "posthog-js/react"

interface IUseGetInterviewEngineFeature {
    web_rtc: boolean
}

interface IUseGetInterviewEngine {
    features?: IUseGetInterviewEngineFeature
}

export function useGetInterviewEngine(interviewSettings?: Readonly<IUseGetInterviewEngine>) {
    // #region PostHog
    const postHogFF = useFeatureFlagEnabled("webrtc")
    // #endregion

    // #region Query parameters
    const [searchParams] = useSearchParams()
    // #endregion

    // #region States
    const [engine, setEngine] = useState<"webrtc" | "websocket">()
    // #endregion

    // #region Effects
    useEffect(() => {
        if (engine !== undefined || !interviewSettings) return

        const isCurrentlyWebRTC =
            searchParams.get("webrtc") === "true" || postHogFF || interviewSettings?.features?.web_rtc

        setEngine(isCurrentlyWebRTC ? "webrtc" : "websocket")
    }, [engine, searchParams, postHogFF, interviewSettings])
    // #endregion

    return { engine }
}
