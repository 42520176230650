import useAxiosAuth from "@hooks/useAxiosAuth"
import IOrganizationGateway from "@services/organizations/IOrganizationGateway"
import OrganizationsApiService from "@services/organizations/api/organizations.api"
import { useMemo } from "react"

/**
 * Obtains the instance of the service to interact with organizations.
 */
export function useOrganizationService(): IOrganizationGateway {
    const axiosAuth = useAxiosAuth()
    return useMemo(() => new OrganizationsApiService({ api: axiosAuth }), [axiosAuth])
}