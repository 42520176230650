import { ButtonHTMLAttributes, DetailedHTMLProps, forwardRef } from "react"

interface IGlautButtonSecondaryProps
    extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    squared?: boolean
}

const GlautButtonSecondary = forwardRef<HTMLButtonElement, Readonly<IGlautButtonSecondaryProps>>(({
    children,
    onClick,
    disabled,
    className,
    squared = false,
    ...props
}, ref) => (
    <button
        className={`rounded-[0.25rem] font-medium border-1 shadow-none group text-base
            bg-glaut-cards text-glaut-pink border-transparent
            disabled:bg-glaut-off-white disabled:text-glaut-grey disabled:border-transparent
            hover:border-glaut-grey
            hover:disabled:border-transparent
            ${squared ? "p-[0.5rem]" : "px-[0.75rem] py-[0.35rem]"}
            ${className}
        `}
        onClick={onClick}
        disabled={disabled}
        ref={ref}
        type="button"
        {...props}
    >
        {children}
    </button>
))

GlautButtonSecondary.displayName = "GlautButtonSecondary"

export default GlautButtonSecondary