import { Circle, Path, Svg } from "@react-pdf/renderer"
import { Style } from "@react-pdf/types"
import { IconBaseProps, IconType } from "react-icons/lib"

interface IPdfIconProps extends IconBaseProps {
    icon: IconType
    pdfStyle?: Style
}

/**
 * @see https://github.com/diegomura/react-pdf/discussions/1334
 */
export default function PdfIcon({
    icon: Icon,
    size = 24,
    color = "black",
    viewBox,
    pdfStyle
}: Readonly<IPdfIconProps>) {
    const iconElement = Icon({ fill: color })

    const paths = iconElement.props.children.filter(child => child.type === "path")
    const circles = iconElement.props.children.filter(child => child.type === "circle")

    const fill = iconElement.props.fill || "black"

    return (
        <Svg
            viewBox={viewBox ?? iconElement.props.attr.viewBox}
            style={[pdfStyle ?? {}, { width: size, height: size }]}
        >
            {paths?.map((path, index) => <Path key={index} d={path.props.d} fill={path.props.fill || fill} />)}
            {circles?.map((circle, index) => (
                <Circle
                    key={index}
                    cx={circle.props.cx}
                    cy={circle.props.cy}
                    r={circle.props.r}
                    fill={circle.props.fill || fill}
                />
            ))}
        </Svg>
    )
}