import GlautSwitchWithLabel from "@components/inputs/GlautSwitchWithLabel"
import { copy, getCopy } from "@utils/Copy"
import { ChangeEventHandler, useCallback, useEffect, useMemo, useState } from "react"
import { useOutlineTabIqs, useOutlineTabIqsDispatch } from "../../contexts/OutlineTabIqsProvider"
import { useOutlineTab } from "../../contexts/OutlineTabProvider"
import ProbingTextArea from "../OutlineTabContentIqsQuestionFormBodyProbingTextArea"
import { probingTemplates } from "./constants/probing-templates"
import GlautTextAreaMultiLine from "@components/inputs/GlautTextAreaMultiLine"
import { MdOutlineArrowDropDownCircle } from "react-icons/md"
import { useLegacyProject } from "../../contexts/ProjectProvider"

export default function OutlineTabContentIqsQuestionFormBodyProbing() {
    // #region Contexts
    const { lang } = useLegacyProject()
    const { canEdit } = useOutlineTab()
    const { iqToBeBuilt } = useOutlineTabIqs()
    const outlineTabIqsDispatch = useOutlineTabIqsDispatch()
    // #endregion

    // #region States
    const [backupProbingInstructions, setBackupProbingInstructions] = useState<string>(
        iqToBeBuilt?.probing_instructions ?? ""
    )
    const [isAdvancedExpanded, setIsAdvancedExpanded] = useState(false)
    // #endregion

    // #region Memos
    const hasProbing = useMemo(
        () => typeof iqToBeBuilt?.probing_instructions === "string",
        [iqToBeBuilt]
    )
    // #endregion

    // #region Callbacks
    const handleToggleHasProbing: ChangeEventHandler<HTMLInputElement> = useCallback(e => {
        if (!e.target.checked)
            setBackupProbingInstructions(iqToBeBuilt?.probing_instructions ?? "")

        outlineTabIqsDispatch({
            type: "set-iq-attribute",
            attribute: "probing_instructions",
            value: e.target.checked ? backupProbingInstructions : null
        })
    }, [backupProbingInstructions, iqToBeBuilt?.probing_instructions, outlineTabIqsDispatch])

    const handleSetProbingInstructions = useCallback((text: string) => {
        outlineTabIqsDispatch({ type: "set-iq-attribute", attribute: "probing_instructions", value: text })
    }, [outlineTabIqsDispatch])

    const handleAnalysisGoalChange: ChangeEventHandler<HTMLTextAreaElement> = useCallback(ev => {
        if (!iqToBeBuilt) return
        if (!iqToBeBuilt.analyses?.length) {
            outlineTabIqsDispatch({
                type: "set-analysis-to-iq-to-be-built",
                analysis: {
                    goal: ev.target.value
                }
            })

            return
        }

        const analysis = iqToBeBuilt.analyses[0]

        outlineTabIqsDispatch({
            type: "set-analysis-to-iq-to-be-built",
            analysis: {
                ...analysis,
                ...{ goal: ev.target.value, type: "thematic" }
            },
            isEditing: analysis.isEditing
        })
    }, [outlineTabIqsDispatch, iqToBeBuilt])
    // #endregion

    // #region Effects
    useEffect(() => {
        setBackupProbingInstructions("")
    }, [iqToBeBuilt?.id])
    // #endregion

    if (!iqToBeBuilt) return <></>

    return (
        <div className="flex flex-col gap-[1.5rem]">
            <div className="flex flex-col gap-[0.5rem]">
                <GlautSwitchWithLabel
                    label={getCopy(copy.outline.interviewQuestions.aiFollowUpQuestions) ?? ""}
                    designMode="ai"
                    checked={hasProbing}
                    onChange={handleToggleHasProbing}
                    disabled={!canEdit}
                    tooltip={{
                        content: getCopy(copy.outline.interviewQuestions.glautWillFollowUpToExpandAndUnderstand) ?? "",
                        className: "max-w-[20rem] text-start",
                        place: "right"
                    }}
                />
                {hasProbing && (
                    <div className="flex flex-col gap-[0.5rem]">
                        <p className="text-[13.33px] font-medium text-glaut-dark-grey">
                            {getCopy(copy.outline.interviewQuestions.instructTheModeratorHowToProbeDeeper) ?? ""}
                        </p>
                        <ProbingTextArea
                            placeholder={getCopy(
                                copy.outline.interviewQuestions.ifTheRespondentMentionsAbcFollowUpWithXyzEllipsis
                            ) ?? ""}
                            probingInstructions={iqToBeBuilt.probing_instructions ?? ""}
                            setProbingInstructions={handleSetProbingInstructions}
                            disabled={!canEdit || !hasProbing}
                            probingTemplates={probingTemplates}
                        />
                    </div>
                )}
            </div>
            {hasProbing && iqToBeBuilt?.type !== "open" && (
                <div className="flex flex-col gap-[0.5rem]">
                    <div className="flex gap-[0.5rem] items-center">
                        <button
                            className="border-none shadow-none p-0 m-0 bg-transparent text-base"
                            onClick={() => { setIsAdvancedExpanded(prev => !prev) }}
                        >
                            <MdOutlineArrowDropDownCircle className={`text-glaut-dark-grey transition-all duration-500
                            ${!isAdvancedExpanded ? "-rotate-90" : ""}
                        `} />
                        </button>
                        <p className="text-[13.33px] font-medium text-glaut-text-midnight">
                            {getCopy(copy.words.advanced)}
                        </p>
                    </div>
                    {isAdvancedExpanded && (
                        <>
                            <p className="text-[13.33px] font-medium text-glaut-dark-grey">
                                {getCopy(copy.coding.analysisGoal)}
                            </p>
                            <GlautTextAreaMultiLine
                                defaultValue={(
                                    iqToBeBuilt.content?.[lang] === iqToBeBuilt.analyses?.[0]?.goal
                                        ? ""
                                        : iqToBeBuilt.analyses?.[0]?.goal
                                ) ?? ""}
                                placeholder={getCopy(
                                    copy.outline.interviewQuestions.instructGlautHowToAnalyzeTheOpenDataFromFollowUps
                                ) ?? ""}
                                onChange={handleAnalysisGoalChange}
                                designMode="ai"
                                className="min-h-[6em]"
                            />
                        </>
                    )}
                </div>
            )}
        </div>
    )
}
