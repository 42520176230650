import { DetailedHTMLProps, InputHTMLAttributes } from "react"

interface IGlautInputTextProps
    extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    noBorder?: boolean
}

export default function GlautInputText({
    noBorder = false,
    className,
    ...props
}: Readonly<IGlautInputTextProps>) {
    return (
        <input
            className={`p-[0.75em] text-[13.33px] font-medium border-0 rounded-none w-full
                ${noBorder ? "" : "border-b-1"}
                border-b-glaut-light-grey text-glaut-text-midnight
                placeholder:text-glaut-grey
                focus:shadow-none focus:border-b-glaut-text-midnight
                enabled:hover:border-b-glaut-text-midnight
                disabled:cursor-not-allowed
                ${className}
            `}
            {...props}
        />
    )
}