import { getCopy } from "@utils/Copy"
import React, { forwardRef, useCallback, useEffect, useMemo, useState } from "react"
import { glautRateModalityComponentCopy } from "./utils/copy"
import GlautRateModalityComponentCopyInput from "./components/GlautRateModalityComponentCopyInput"

export type IGlautRateModalityComponentState = {
    rateModality: "nps"
    customScore?: null
} | {
    rateModality: "custom"
    customScore: {
        min: {
            value: number | null
            copy: string | null
        }
        max: {
            value: number | null
            copy: string | null
        }
    }
}

export type IGlautRateModalityComponentRef = IGlautRateModalityComponentState & {
    setCustomScore?: (score: NonNullable<IGlautRateModalityComponentState["customScore"]>) => void
}

type IGlautRateModalityComponentProps = {
    disabled?: boolean
    allowChangingModality?: boolean
    withMinMaxLabels?: boolean
    upperLimit?: number
} & ({
    value: IGlautRateModalityComponentState
    onChange: (state: IGlautRateModalityComponentState) => void
} | {
    value?: null
    onChange?: null
})

const GlautRateModalityComponent = forwardRef<
    IGlautRateModalityComponentRef | undefined,
    Readonly<IGlautRateModalityComponentProps>
>(({
    allowChangingModality = true,
    withMinMaxLabels = true,
    disabled = false,
    upperLimit,
    value,
    onChange
}, ref) => {
    // #region States
    const [state, setState] = useState<IGlautRateModalityComponentState>(value ?? { rateModality: "nps" })
    // #endregion

    // #region Memos
    const npsModalityButtonClassNames = useMemo(() => `
        rounded-none rounded-s-[4px] bg-glaut-cards shadow-none flex-1 p-[1rem]
        ${state.rateModality === "nps" ? "border-glaut-pink" : ""}
        ${state.rateModality === "custom" ? "border-transparent enabled:hover:border-glaut-grey duration-100" : ""}
    `, [state.rateModality])

    const customModalityButtonClassNames = useMemo(() => `
        rounded-none rounded-e-[4px] bg-glaut-cards shadow-none flex-1 p-[1rem]
        ${state.rateModality === "custom" ? "border-glaut-pink" : ""}
        ${state.rateModality === "nps" ? "border-transparent enabled:hover:border-glaut-grey duration-100" : ""}
    `, [state.rateModality])
    // #endregion

    // #region Callbacks
    const updateState = useCallback((newState: React.SetStateAction<IGlautRateModalityComponentState>) => {
        if (!value || !onChange) {
            setState(newState)
            return
        }

        if (typeof newState === "object") {
            onChange(newState)
            return
        }

        onChange(newState(state))
    }, [onChange, state, value])

    const handleSetCustomScore = useCallback((score: NonNullable<IGlautRateModalityComponentState["customScore"]>) => {
        updateState(state => {
            if (state.rateModality === "nps") return state
            return { ...state, customScore: score }
        })
    }, [updateState])

    const handleSetModality = useCallback((rateModality: IGlautRateModalityComponentState["rateModality"]) => {
        updateState(state => {
            if (rateModality === "nps")
                return { rateModality: "nps", customScore: undefined }

            return {
                rateModality: "custom",
                customScore: state.rateModality === "custom" ? state.customScore : {
                    max: { value: null, copy: null },
                    min: { value: null, copy: null }
                }
            }
        })
    }, [updateState])

    const handleUpdateValue = useCallback((type: "min" | "max", value: number | null) => {
        if (state.rateModality === "nps") return

        if (value === null) {
            handleSetCustomScore({
                ...state.customScore, [type]: {
                    ...state.customScore[type],
                    value: null
                }
            })

            return
        }

        handleSetCustomScore({
            ...state.customScore, [type]: {
                ...state.customScore[type],
                value: (upperLimit && Number(value) > upperLimit) ? upperLimit : Number(value)
            }
        })
    }, [handleSetCustomScore, state.customScore, state.rateModality, upperLimit])
    // #endregion

    // #region Effects

    // onRerenderUpdateRef
    useEffect(() => {
        if (!ref) return

        const refValue: IGlautRateModalityComponentRef = {
            ...state,
            setCustomScore: handleSetCustomScore
        }

        if (typeof ref === "object") {
            ref.current = refValue
            return
        }

        ref(refValue)
    })

    // onUpdateValueUpdateInnerState
    useEffect(() => {
        if (!value) return
        setState(value)
    }, [value])

    // #endregion

    return (
        <div className="flex flex-col">
            <div className="flex flex-row justify-center py-[0.75em]">
                <div className="flex flex-row w-[70%]">
                    <button
                        onClick={() => { handleSetModality("nps") }}
                        className={npsModalityButtonClassNames}
                        disabled={!allowChangingModality || disabled}
                    >
                        <p className={`text-center text-[13.33px] font-medium
                            ${state.rateModality === "nps" ? "text-glaut-pink" : "text-glaut-dark-grey"}
                        `}>
                            {getCopy(glautRateModalityComponentCopy.netPromoterScore)}
                        </p>
                    </button>
                    <button
                        onClick={() => { handleSetModality("custom") }}
                        className={customModalityButtonClassNames}
                        disabled={!allowChangingModality || disabled}
                    >
                        <p className={`text-center text-[13.33px] font-medium
                            ${state.rateModality === "custom" ? "text-glaut-pink" : "text-glaut-dark-grey"}
                        `}>
                            {getCopy(glautRateModalityComponentCopy.customScore)}
                        </p>
                    </button>
                </div>
            </div>
            {state.rateModality === "custom" && (
                <div className="flex flex-col gap-[1.5rem]">
                    <div className="flex flex-col gap-[0.5rem]">
                        <p className="text-[13.33px] font-medium text-glaut-text-midnight">
                            {getCopy(glautRateModalityComponentCopy.defineTheScaleForTheScore)}
                        </p>
                        <div className="flex flex-row gap-[0.5rem] justify-center items-center">
                            <p className="text-[13.33px] font-medium text-[rgb(0,0,0,0.6)]">
                                {getCopy(glautRateModalityComponentCopy.fromCapital)}
                            </p>
                            <input
                                className={`border-b-1 border-0 rounded-none max-w-[75px] text-center
                                    border-b-glaut-light-grey
                                    focus:shadow-none
                                    hover:border-b-glaut-pink
                                `}
                                placeholder="1"
                                value={state.customScore.min.value ?? ""}
                                onChange={e => {
                                    const value = e.target.value
                                    if (isNaN(Number(value))) return

                                    handleUpdateValue("min", value !== "" ? Number(value) : null)
                                }}
                                disabled={disabled}
                            />
                            <p className="text-[13.33px] font-medium text-[rgb(0,0,0,0.6)]">
                                {getCopy(glautRateModalityComponentCopy.to)}
                            </p>
                            <input
                                className={`border-b-1 border-0 rounded-none max-w-[75px] text-center
                                    border-b-glaut-light-grey 
                                    focus:shadow-none
                                    hover:border-b-glaut-pink
                                `}
                                placeholder="10"
                                value={state.customScore.max.value ?? ""}
                                onChange={e => {
                                    const value = e.target.value
                                    if (isNaN(Number(value))) return

                                    handleUpdateValue("max", value !== "" ? Number(value) : null)
                                }}
                                disabled={disabled}
                            />
                        </div>
                    </div>
                    {withMinMaxLabels && (
                        <div className="flex flex-col gap-[0.25rem]">
                            <GlautRateModalityComponentCopyInput
                                label={getCopy(glautRateModalityComponentCopy.minLabelCopy) ?? ""}
                                value={state.customScore.min.copy ?? ""}
                                onChange={copy => {
                                    handleSetCustomScore({
                                        ...state.customScore,
                                        min: { ...state.customScore.min, copy }
                                    })
                                }}
                                disabled={disabled}
                            />
                            <GlautRateModalityComponentCopyInput
                                label={getCopy(glautRateModalityComponentCopy.maxLabelCopy) ?? ""}
                                value={state.customScore.max.copy ?? ""}
                                onChange={copy => {
                                    handleSetCustomScore({
                                        ...state.customScore,
                                        max: { ...state.customScore.max, copy }
                                    })
                                }}
                                disabled={disabled}
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    )
})

GlautRateModalityComponent.displayName = "GlautRateModalityComponent"

export default GlautRateModalityComponent