import EditableTitle from "@components/inputs/EditableTitle"
import { useCallback } from "react"
import { useOutlineTab } from "../../contexts/OutlineTabProvider"
import { useLegacyProject } from "../../contexts/ProjectProvider"
import { copy, getCopy } from "@utils/Copy"

export default function OutlineTabSidebarProjectTitle() {
    // #region Contexts
    const { project, setProject } = useLegacyProject()
    const { canEdit } = useOutlineTab()
    // #endregion

    // #region Callbacks
    const handleChangeProjectTitle = useCallback((name: string) => {
        setProject(prev => {
            if (!prev) return prev
            return { ...prev, name }
        })
    }, [setProject])
    // #endregion

    if (project?.source !== "interview") return <></>

    return (
        <EditableTitle
            disabled={!canEdit}
            onChange={handleChangeProjectTitle}
            value={project?.name}
            placeholder={getCopy(copy.words.untitled) ?? ""}
            extend
        />
    )
}