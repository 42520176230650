import { useCallback, useMemo, useState } from "react"
import { useDropzone } from "react-dropzone"
import { assetUploadCopy } from "./utils/copy"
import { getCopy } from "@utils/Copy"
import { FiUpload } from "react-icons/fi"
import { MdClose } from "react-icons/md"
import GlautButtonPrimary from "@components/Buttons/GlautButtonPrimary"
import SendIcon from "../SendIcon"
import { useInterviewService } from "@hooks/services/useInterviewService"
import { IInterviewMessage } from "@/@types/entry"
import * as Sentry from "@sentry/react"
import { useToastDispatch } from "@contexts/ToastProvider"
import ErrorLevel from "@utils/ErrorLevel"
import { useParams } from "react-router-dom"
import Loading from "@components/loading/Loading"
import { IIntervieweeReply } from "@/@types/api/interviewer"

interface AssetUploadProps {
    lang: string
    interviewId: string
    isPlaying: boolean | null
    setMessages: React.Dispatch<React.SetStateAction<(IIntervieweeReply | IInterviewMessage)[]>>
    lastMessage: IInterviewMessage
}

type ISelectedAssetState = {
    file: File
    serializedFile: string
    type: "image" | "video"
}

export default function AssetUpload({
    lang,
    interviewId,
    isPlaying,
    lastMessage,
    setMessages
}: Readonly<AssetUploadProps>) {
    // #region Params
    const { projectId } = useParams()
    // #endregion

    // #region Contexts
    const toastDispatch = useToastDispatch()
    // #endregion

    // #region Services
    const interviewsService = useInterviewService()
    // #endregion

    // #region States
    const [selectedAsset, setSelectedAsset] = useState<ISelectedAssetState>()
    const [isUploadingAsset, setIsUploadingAsset] = useState(false)
    // #endregion

    // #region Memos
    const isDisabled = useMemo(() => isPlaying !== false || isUploadingAsset, [isPlaying, isUploadingAsset])
    // #endregion

    // #region Callbacks
    const onDrop = useCallback((acceptedFiles: File[]) => {
        if (!acceptedFiles.length) return

        const file = acceptedFiles[0]

        const reader = new FileReader()
        reader.onloadend = () => {
            setSelectedAsset({
                file,
                serializedFile: reader.result as string,
                type: file.type.includes("image") ? "image" : "video"
            })
        }
        reader.readAsDataURL(file)
    }, [])

    const handleUploadAsset = useCallback(() => {
        if (!projectId || !selectedAsset) return

        setIsUploadingAsset(true)

        interviewsService.uploadAsset({
            asset: selectedAsset.file,
            projectId,
            interviewId,
            questionId: lastMessage.question_id
        }).then(({ assetUrl }) => {
            const message: IIntervieweeReply = {
                content: "Asset uploaded",
                asset_url: assetUrl
            }

            setMessages(prev => [...prev, message])
        }).catch(err => {
            Sentry.captureException(err, scope => {
                scope.setContext("data", {
                    projectId,
                    interviewId,
                    questionId: lastMessage.question_id,
                    asset: {
                        name: selectedAsset.file.name,
                        size: selectedAsset.file.size,
                        type: selectedAsset.file.type
                    }
                })

                return scope
            })

            toastDispatch({
                type: "add-toast",
                payload: {
                    messageLevel: ErrorLevel.Error,
                    title: getCopy(assetUploadCopy.assetNotUploaded, lang) ?? "",
                    detail: getCopy(assetUploadCopy.assetNotUploadedDetail, lang) ?? ""
                }
            })

            setIsUploadingAsset(false)
        })
    }, [
        projectId,
        interviewId,
        lang,
        lastMessage.question_id,
        interviewsService,
        selectedAsset,
        setMessages,
        toastDispatch
    ])
    // #endregion

    // #region Dropzone
    const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
        onDrop,
        accept: {
            "image/*": [".png", ".jpg", ".jpeg", ".gif", ".svg"],
            "video/*": [".mp4", ".mov", ".avi", ".mkv", ".webm"]
        },
        maxFiles: 1,
        maxSize: 50 * 1024 * 1024 // 50 MB
    })
    // #endregion

    // #region Dropzone memos
    const dropzoneMessage = useMemo(() => {
        if (isDragReject) return getCopy(assetUploadCopy.fileNotAccepted, lang)
        if (isDragActive) return getCopy(assetUploadCopy.dropToUpload, lang)
        return getCopy(assetUploadCopy.uploadYourImageOrVideo, lang)
    }, [isDragReject, isDragActive, lang])
    // #endregion

    if (selectedAsset)
        return (
            <div className="flex flex-col gap-[0.5rem] pb-2">
                <div className="flex justify-center relative p-1 border-1 rounded-[0.5rem] border-glaut-light-grey">
                    {selectedAsset.type === "image" && (
                        <img
                            src={selectedAsset.serializedFile}
                            alt="Preview"
                            className="w-full rounded-lg max-h-[200px] object-contain"
                        />
                    )}
                    {selectedAsset.type === "video" && (
                        <video controls className="max-h-[200px] object-contain rounded-[0.5rem]">
                            <source src={URL.createObjectURL(selectedAsset.file)} />
                        </video>
                    )}
                    <GlautButtonPrimary
                        onClick={() => setSelectedAsset(undefined)}
                        className="absolute top-2 right-2 flex items-center"
                        squared
                        disabled={isDisabled}
                    >
                        <MdClose className="h-[1em] w-[1em]" />
                    </GlautButtonPrimary>
                </div>
                {!isUploadingAsset && (
                    <SendIcon className="self-end" onClick={handleUploadAsset} disabled={isDisabled} />
                )}
                {isUploadingAsset && (
                    <div className="self-end">
                        <Loading />
                    </div>
                )}
            </div>
        )

    return (
        <div
            className={`flex flex-col gap-[1rem] justify-center items-center border-1 rounded-[0.25rem] min-h-[150px]
                mt-[5%] border-dashed
                border-glaut-dummie-color bg-glaut-cards
                ${isDisabled ? "cursor-not-allowed" : "cursor-pointer"}
            `}
            {...getRootProps()}
        >
            <input {...getInputProps({ disabled: isDisabled })} />
            <FiUpload className="h-[2em] w-[2em] text-glaut-dark-grey" />
            <div className="flex gap-[0.75rem] items-center px-[0.75rem]">
                <p className="text-[13.33px] text-glaut-dark-grey text-center">
                    {dropzoneMessage}
                </p>
            </div>
        </div>
    )
}
