import OptionsInput from "@components/inputs/OptionsInput"
import { useProject } from "@pages/Project/ProjectArea/contexts/ProjectProvider"
import { createSequentialArray } from "@utils/array/sequential-array"
import { getCopy } from "@utils/Copy"
import React, { useCallback, useMemo } from "react"
import { BiTrash } from "react-icons/bi"
import {
    INpsQuestion,
    IQuestion,
    IQuestionDomain,
    IScaleQuestion,
    ISelectOption,
    ISelectQuestion
} from "src/@types/interview-question"
import { IFilter } from "src/@types/question"
import { allowedIqTypesForFiltering } from "./constants/allowed-iq-types-for-filtering"
import { allowedOperatorsForType } from "./constants/allowed-operators"
import { questionFiltersCopy } from "./utils/copy"
import { RecursivePartial } from "@utils/types/recursive-partial"
import { isFilter } from "./utils/is-filter"

interface IQuestionFilterProps {
    filter: IFilter
    setFilter: (f: IFilter | null) => void
    questionIdx: number
    lang: string
    disabled?: boolean
    questionsForFiltering: IQuestion[]
}

export const QuestionFilter = ({
    filter,
    setFilter,
    questionIdx,
    lang,
    disabled,
    questionsForFiltering
}: Readonly<IQuestionFilterProps>) => {
    // #region Memos
    const selectedQuestionForFiltering = useMemo(
        () => questionsForFiltering.find(
            q => allowedIqTypesForFiltering.includes(q.type) && q.id === filter.id
        ) as ISelectQuestion | INpsQuestion | IScaleQuestion | undefined,
        [filter.id, questionsForFiltering]
    )

    const availableQuestionsForFilteringAsOptions = useMemo(
        () => questionsForFiltering?.filter(
            (q, idx) => idx < questionIdx && allowedIqTypesForFiltering.includes(q.type)
        ).map(q => ({ id: q.id, value: q.content })),
        [questionsForFiltering, questionIdx]
    )

    const selectedQuestionOptions = useMemo(() => {
        if (!selectedQuestionForFiltering) return []

        if (selectedQuestionForFiltering.type === "select")
            return selectedQuestionForFiltering.options

        return createSequentialArray(selectedQuestionForFiltering.min, selectedQuestionForFiltering.max).map(value => ({
            id: value.toString(),
            label: {
                [lang]: value.toString()
            }
        }) as ISelectOption)
    }, [selectedQuestionForFiltering, lang])
    // #endregion

    return (
        <div className="bar bordered" style={{ width: "100%" }}>
            <OptionsInput
                options={availableQuestionsForFilteringAsOptions}
                value={filter.id}
                setValue={qId => setFilter({ ...filter, id: qId })}
                single={true}
                objKey="value"
                initialOpen={false}
                lang={lang}
                disabled={disabled}
            />
            <OptionsInput
                options={selectedQuestionForFiltering ? allowedOperatorsForType[selectedQuestionForFiltering.type] : []}
                value={filter.operator}
                setValue={operator => setFilter({ ...filter, operator, value: [] })}
                single={true}
                objKey="label"
                initialOpen={false}
                lang={"en"}
                disabled={disabled}
                placeholder="Operator"
            />
            {(filter.operator === "contains" || filter.operator === "excludes") && (
                <OptionsInput
                    options={selectedQuestionOptions}
                    value={filter.value || []}
                    setValue={v => {
                        setFilter({
                            ...filter,
                            value: filter.value.includes(v)
                                ? filter.value.filter(fv => fv !== v)
                                : [...(filter.value || []), v]
                        })
                    }}
                    initialOpen={false}
                    objKey="label"
                    single={false}
                    lang={lang}
                    disabled={disabled}
                />
            )}
            {!(filter.operator === "contains" || filter.operator === "excludes") && (
                <OptionsInput
                    options={selectedQuestionOptions}
                    value={(typeof filter.value === "number" ? filter.value.toString() : filter.value) || null}
                    setValue={v => {
                        setFilter({
                            ...filter,
                            value: filter.value === v ? null : v
                        })
                    }}
                    initialOpen={false}
                    objKey="label"
                    single
                    lang={lang}
                    disabled={disabled}
                />
            )}
            <span
                style={{
                    borderLeft: "1px solid var(--border-color)",
                    borderRadius: 0,
                    paddingLeft: "1em"
                }}
            >
                <BiTrash
                    className={["clickable danger small-icon", disabled ? "disabled" : ""].join(" ")}
                    onClick={() => !disabled && setFilter(null)}
                />
            </span>
        </div>
    )
}

interface IQuestionFiltersProps {
    question: RecursivePartial<IQuestion>
    setQuestion: React.Dispatch<React.SetStateAction<RecursivePartial<IQuestion>>>
    lang: string
    disabled?: boolean
    filtersDomain?: IQuestionDomain | "all"
}

export const QuestionFilters = ({
    question,
    setQuestion,
    lang,
    disabled = false,
    filtersDomain = "interview"
}: Readonly<IQuestionFiltersProps>) => {
    // #region Contexts
    const { interviewQuestions, projectQuestions, screeningQuestions } = useProject()
    // #endregion

    // #region Memos
    const questionsForFiltering = useMemo(() => {
        if (filtersDomain === "all") return projectQuestions ?? []
        if (filtersDomain === "interview") return interviewQuestions ?? []
        if (filtersDomain === "screening") return screeningQuestions ?? []
        return []
    }, [filtersDomain, projectQuestions, interviewQuestions, screeningQuestions])

    const questionIdx = useMemo(() => {
        const idx = questionsForFiltering.findIndex(q => q.id === question.id)
        return idx === -1 ? (questionsForFiltering?.length ?? 0) : idx
    }, [questionsForFiltering, question.id])

    const isAddingFiltersAllowed = useMemo(
        () => questionsForFiltering.find(
            (iq, idx) => idx < questionIdx && (["select", "nps", "scale"].includes(iq.type))
        ) !== undefined,
        [questionsForFiltering, questionIdx]
    )
    // #endregion

    // #region Callbacks
    const handleAddEmptyFilter = useCallback(() => {
        setQuestion(q => ({ ...q, filters: [...(q.filters ?? []), { id: "", operator: "contains", value: [] }] }))
    }, [setQuestion])

    const setFilter = useCallback(
        (newFilter: IFilter | null, idx: number) =>
            newFilter
                ? setQuestion(q => ({
                    ...q,
                    filters: (q.filters ?? []).map((f, i) => (i === idx ? newFilter : f))
                }))
                : setQuestion(q => ({
                    ...q,
                    filters: (q.filters ?? []).filter((_, i) => i !== idx)
                })),
        [setQuestion]
    )
    // #endregion

    if (!isAddingFiltersAllowed) return <></>

    return (
        <div className="flex flex-col gap-[0.5rem] rounded-[0.5rem] py-[0.5rem] px-[0.75rem] bg-glaut-cards">
            <div className="flex justify-between">
                <p className="text-[11.11px] font-medium text-glaut-dark-grey">
                    {getCopy(questionFiltersCopy.branchLogics)}
                </p>
                {!disabled && (
                    <button
                        className="border-none rounded-none shadow-none text-base bg-transparent p-0 m-0"
                        onClick={handleAddEmptyFilter}
                    >
                        <p className="text-[11.11px] font-medium text-glaut-dark-grey underline">
                            {getCopy(questionFiltersCopy.addLogic)}
                        </p>
                    </button>
                )}
            </div>

            {question.filters?.filter(isFilter)?.map((f, idx) => (
                <QuestionFilter
                    key={idx}
                    filter={f}
                    setFilter={f => setFilter(f, idx)}
                    questionIdx={questionIdx}
                    lang={lang}
                    disabled={disabled}
                    questionsForFiltering={questionsForFiltering}
                />
            ))}
        </div>
    )
}
