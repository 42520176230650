import { useGlautAuthInfo } from "@hooks/useGlautAuthInfo"
import TopBar from "../../components/layouts/TopBar"
import Settings from "./Settings"
import Workspaces from "./Workspaces"

const tabs = ["Workspaces", "Organization members"]
const guestTabs = ["Workspaces"]

interface IOrganizationContentProps {
    currentTab: number
    setCurrentTab: (index: number) => void
}

export default function OrganizationContent({ currentTab, setCurrentTab }: Readonly<IOrganizationContentProps>) {
    const { currentOrgUsers } = useGlautAuthInfo()

    return (
        <div className="flex flex-col w-full h-screen">
            <TopBar
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
                tabs={currentOrgUsers !== undefined ? tabs : guestTabs}
            />

            <div className="flex justify-center overflow-auto">
                <div className="flex flex-col w-3/4">
                    {currentTab === 0 && <Workspaces />}
                    {currentTab === 1 && <Settings />}
                </div>
            </div>
        </div>
    )
}
