import { copy, getCopy } from "@utils/Copy"

export const probingTemplates: [string, string][] = [
    [
        getCopy(copy.outline.interviewQuestions.probingTemplates.askWhy.label) ?? "",
        getCopy(copy.outline.interviewQuestions.probingTemplates.askWhy.description) ?? ""
    ],
    [
        getCopy(copy.outline.interviewQuestions.probingTemplates.askWhat.label) ?? "",
        getCopy(copy.outline.interviewQuestions.probingTemplates.askWhat.description) ?? ""
    ],
    [
        getCopy(copy.outline.interviewQuestions.probingTemplates.askExample.label) ?? "",
        getCopy(copy.outline.interviewQuestions.probingTemplates.askExample.description) ?? ""
    ]
]