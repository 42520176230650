import ProjectMetricsBox from "@components/Project/ProjectMetricsBox"
import { useLegacyProject, useProjectDispatch } from "../../contexts/ProjectProvider"
import DataTabTable from "../DataTabTable"
import { useCallback } from "react"
import { useGlautAuthInfo } from "@hooks/useGlautAuthInfo"
import DataTabCsvContent from "../DataTabCsvContent"

export default function DataTabContent() {
    // #region Contexts
    const { project } = useLegacyProject()
    const projectDispatch = useProjectDispatch()
    const { isSuperuser } = useGlautAuthInfo()
    // #endregion

    // #region Callbacks
    const handleRefresh = useCallback(() => {
        projectDispatch({ type: "force-data-tab-interviews-refetch" })
    }, [projectDispatch])
    // #endregion

    if (!project?.source) return <></>
    if (project.source === "CSV") return <DataTabCsvContent />

    return (
        <div className="relative h-full overflow-hidden">
            <div className="container mx-auto h-full flex flex-col flex-1 gap-[1.25rem] overflow-hidden">
                <div className="mt-12 px-[21px]">
                    <ProjectMetricsBox
                        projectId={project._id}
                        projectStatus={project.status}
                        withHeader={isSuperuser}
                        onRefresh={handleRefresh}
                    />
                </div>
                <DataTabTable />
            </div>
        </div>
    )
}