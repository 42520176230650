interface IConversationTranscriptBoxCsvMessageProps {
    content: string
}

export default function ConversationTranscriptBoxCsvMessage({
    content
}: Readonly<IConversationTranscriptBoxCsvMessageProps>) {
    return (
        <p className="flex-1 py-[0.5rem] px-[0.75rem] text-[13.33px] text-glaut-text-midnight bg-glaut-dummie-color
            rounded-t-[0.3125rem] rounded-es-[0.3125rem] rounded-ee-none"
        >
            {content}
        </p>
    )
}