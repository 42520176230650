import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react"

import { useAuthInfo } from "@propelauth/react"
import { AccessHelper, OrgHelper, User, UserClass, createClient, IAuthClient } from "@propelauth/javascript"
import { useNavigate, useParams } from "react-router-dom"

import { useToast } from "@contexts/ToastProvider"

import Loading from "@components/loading/Loading"
import ErrorPage, { IErrorPageProps } from "@components/layouts/ErrorPage"
import { ErrorLevel } from "@utils/ErrorLevel"
import { copy, getCopy } from "@utils/Copy"
import { isSuperuser } from "@utils/auth"
import { settings } from "@utils/Variables"
import { IOrganization, IOrganizationWithWorkspaces } from "src/@types/organization"
import { IWorkspaceWithProjects } from "src/@types/workspace"
import { IOrganizationUser, IProjectGuest, IWorkspaceUser } from "src/@types/user"
import { useOrganizationService } from "@hooks/services/useOrganizationService"
import { useWorkspaceService } from "@hooks/services/useWorkspaceService"
import { useProjectService } from "@hooks/services/useProjectService"

// For each minimum org role required to execute an action
// return the list of org roles that can do that.
const orgRoleHierarchyMapping = {
    "owner": ["owner"],
    "member": ["owner", "member"]
}
// Map Glaut roles to PropelAuth roles
const wsRoleMapping = {
    "owner": "Owner",
    "editor": "Member",
    "viewer": "Viewer"
}
// For each minimum workspace role required to execute an action
// return the list of project roles that can do that.
const projectRoleHierarchyMapping = {
    "owner": [] as string[],
    "editor": ["editor"],
    "viewer": ["editor", "viewer"]
}


export declare type GlautUseAuthInfoProps = {
    loading: boolean;
    isLoggedIn: boolean | undefined;
    accessToken: string | undefined | null;
    user: User | undefined | null;
    userClass: UserClass | undefined | null;
    orgHelper: OrgHelper | undefined | null;
    accessHelper: AccessHelper | undefined | null;
    isImpersonating: boolean | undefined;
    impersonatorUserId?: string | undefined;
    refreshAuthInfo: () => Promise<void>;
    accessTokenExpiresAtSeconds: number | undefined;
    authClient: IAuthClient;

    isSuperuser: boolean;

    orgs: IOrganization[] | undefined;
    setCurrentOrgId: (id: string) => void;
    currentOrg: IOrganizationWithWorkspaces | undefined;
    currentOrgIsAtLeastRole: (role: keyof typeof orgRoleHierarchyMapping) => boolean;
    currentOrgUsers: IOrganizationUser[] | undefined;
    inviteOrgUser: (email: string, role: string) => Promise<void>;
    changeOrgUserRole: (email: string, role: string) => Promise<void>;
    removeOrgUser: (email: string) => Promise<void>;
    submitOrgLogo: (event) => Promise<void>;
    removeOrgLogo: () => Promise<void>;

    setCurrentWsId: (id: string) => void;
    currentWs: IWorkspaceWithProjects | undefined;
    currentWsIsAtLeastRole: (role: keyof typeof wsRoleMapping) => boolean;
    currentWsUsers: IWorkspaceUser[] | undefined;
    inviteWsUser: (email: string, role: string) => Promise<void>;
    changeWsUserRole: (email: string, role: string) => Promise<void>;
    removeWsUser: (email: string) => Promise<void>;

    currentProjectIsAtLeastRole: (role: keyof typeof projectRoleHierarchyMapping) => boolean;
    currentProjectGuests: IProjectGuest[] | undefined;
    inviteProjectGuest: (email: string, role: string) => Promise<void>;
    changeProjectGuestRole: (email: string, role: string) => Promise<void>;
    removeProjectGuest: (email: string) => Promise<void>;
}


export const UserContext = createContext({} as GlautUseAuthInfoProps)


export function useGlautAuthInfo(): GlautUseAuthInfoProps {
    const context = useContext(UserContext)
    if (!context)
        throw new Error("useGlautAuthInfo must be used within a UserContext")

    return context
}

export function UserProvider({ children }) {
    // #region Hooks
    const {
        loading,
        isLoggedIn,
        accessToken,
        user,
        userClass,
        orgHelper,
        accessHelper,
        isImpersonating,
        impersonatorUserId,
        refreshAuthInfo,
        accessTokenExpiresAtSeconds
    } = useAuthInfo()
    const navigate = useNavigate()
    const { addToast } = useToast()
    // #endregion

    // #region Params
    const { orgId: orgIdP, wsId: wsIdP, projectId } = useParams()
    // #endregion

    // #region State
    const [currentOrgId, setCurrentOrgId] = useState<string | undefined>(orgIdP)
    const [currentWsId, setCurrentWsId] = useState<string | undefined>(wsIdP)

    const [error, setError] = useState<IErrorPageProps>()
    const [orgs, setOrgs] = useState<IOrganization[]>()
    const [currentOrg, setCurrentOrg] = useState<IOrganizationWithWorkspaces>()
    const [currentWs, setCurrentWs] = useState<IWorkspaceWithProjects>()
    const [currentOrgUsers, setCurrentOrgUsers] = useState<IOrganizationUser[]>()
    const [currentWsUsers, setCurrentWsUsers] = useState<IWorkspaceUser[]>()
    const [currentProjectGuests, setCurrentProjectGuests] = useState<IProjectGuest[]>()
    // #endregion

    // #region Memo
    const authClient = useMemo(() => createClient({
        authUrl: settings.authUrl,
        enableBackgroundTokenRefresh: true
    }), [])
    // Redirect to the first workspace if there is only one org and no org or workspace is specified
    const redirectToWorkspace = useMemo(
        () => orgs?.length === 1 && !orgIdP && !wsIdP && orgs?.[0]?._id,
        [orgIdP, wsIdP, orgs]
    )
    // #endregion

    // #region Services
    const organizationService = useOrganizationService()
    const workspaceService = useWorkspaceService()
    const projectService = useProjectService()
    // #endregion

    // #region Effects
    // Set the current org and workspace id when the params change
    useEffect(() => {
        setCurrentOrgId(orgIdP)
        setCurrentWsId(wsIdP)
    }, [orgIdP, wsIdP])

    // Fetch the orgs when the user is logged in
    useEffect(() => {
        isLoggedIn && organizationService.getOrganizations()
            .then(res => setOrgs(res))
            .catch(e => {
                setError({
                    code: e?.response?.status
                })
            })
    }, [isLoggedIn, organizationService])


    // Fetch the current org and its users when the org id changes
    useEffect(() => {
        const organizationId = currentOrgId || currentWs?.org_id || redirectToWorkspace
        if (organizationId && isLoggedIn) {
            organizationService.getOrganization({
                organizationId
            }).then(res => setCurrentOrg(res))
                .catch(e => {
                    setError({
                        code: e?.response?.status
                    })
                })
            if (currentOrgId || currentWs?.org_id)
                organizationService.getUsers({
                    organizationId
                }).then(res => setCurrentOrgUsers(res))
        }
    }, [currentOrgId, currentWs, isLoggedIn, organizationService, redirectToWorkspace])

    // Fetch the current workspace and its users when the workspace id changes
    useEffect(() => {
        if (currentWsId && isLoggedIn) {
            workspaceService.getWorkspace({
                workspaceId: currentWsId
            }).then(res => setCurrentWs(res))
                .catch(e => {
                    setError({
                        code: e?.response?.status
                    })
                })
            workspaceService.getUsers({
                workspaceId: currentWsId
            }).then(res => setCurrentWsUsers(res))
        }
    }, [currentWsId, isLoggedIn, workspaceService])

    // Fetch the current project guests when the project id changes
    useEffect(() => {
        projectId && isLoggedIn && projectService.getGuests({
            projectId
        }).then(res => setCurrentProjectGuests(res))
    }, [projectId, isLoggedIn, projectService])

    // Redirect to the first org or workspace if the user is logged in and the orgs are loaded
    useEffect(() => {
        // TODO use browser language
        // Once loaded, if orgs is empty, show the error page
        if (orgs && orgs.length === 0)
            setError({
                message: getCopy(copy.errorNoOrg) ?? "",
                detail: getCopy(copy.errorNoOrgDetail) ?? "",
                errorLevel: ErrorLevel.Error,
                separatorExtraClass: "position2"
            })
        // Once loaded, if the org is not specified, navigate the first org
        else if (orgs?.length && !currentOrgId && !currentWsId && !projectId)
            if (orgs.length === 1 && currentOrg?.workspaces.length) {
                const lsWsId = localStorage.getItem("workspaceId")
                let newWsId: string | null = currentOrg.workspaces[currentOrg.workspaces.length - 1]._id
                if (lsWsId && currentOrg.workspaces.find(e => e._id === lsWsId))
                    newWsId = lsWsId
                navigate(`/w/${newWsId}`)
            } else if (orgs.length !== 1 || currentOrg?.workspaces.length === 0) {
                const lsOrgId = localStorage.getItem("organizationId")
                let newOrgId: string | null = orgs[orgs.length - 1]._id
                if (lsOrgId && orgs.find(e => e._id === lsOrgId))
                    newOrgId = lsOrgId
                navigate(`/o/${newOrgId}`)
            }
    }, [navigate, orgs, currentOrgId, currentWsId, projectId, currentOrg])

    // Save the current org and workspace id in local storage
    useEffect(() => {
        if (currentOrg) localStorage.setItem("organizationId", currentOrg._id)
        if (currentWs) localStorage.setItem("workspaceId", currentWs._id)
    }, [currentOrg, currentWs])
    // #endregion

    // #region Auth helpers
    const currentOrgIsAtLeastRole = useCallback(
        (role: keyof typeof orgRoleHierarchyMapping) => isSuperuser(user) || Boolean(
            currentOrg && user && currentOrgUsers && currentOrgUsers.filter(u =>
                u.email === user.email && orgRoleHierarchyMapping[role].includes(u.role)
            ).length > 0
        ), [currentOrg, currentOrgUsers, user])
    const currentWsIsAtLeastRole = useCallback(
        (role: keyof typeof wsRoleMapping) => isSuperuser(user) || currentOrgIsAtLeastRole("owner") || Boolean(
            currentWs && userClass?.getOrg(
                currentWs.propelauth_org_id
            )?.isAtLeastRole(wsRoleMapping[role])
        ), [currentOrgIsAtLeastRole, currentWs, user, userClass])
    const currentProjectIsAtLeastRole = useCallback(
        (role: keyof typeof projectRoleHierarchyMapping) => isSuperuser(user) || currentWsIsAtLeastRole(role) ||
            Boolean(
                projectId && user && currentProjectGuests && currentProjectGuests.filter(u =>
                    u.email === user.email && projectRoleHierarchyMapping[role].includes(u.role)
                ).length > 0
            ),
        [currentProjectGuests, currentWsIsAtLeastRole, projectId, user]
    )
    // #endregion

    // #region Org Actions
    const submitOrgLogo = useCallback(event => {
        const imageFile = event.target.files[0]

        // TODO validate the file

        if (imageFile && currentOrg)
            return organizationService.submitLogo({
                organizationId: currentOrg._id,
                imageFile
            }).then(response => {
                setCurrentOrg({ ...currentOrg, ...response })
            })
                .catch(() => {
                    addToast(getCopy(copy.errorGeneric) ?? "", "", ErrorLevel.Error)
                })

        return Promise.reject(new Error("No org or file"))
    }, [addToast, currentOrg, organizationService])

    const removeOrgLogo = useCallback(
        () => currentOrg
            ? organizationService.removeLogo({
                organizationId: currentOrg._id
            }).then(response => {
                setCurrentOrg({ ...currentOrg, ...response })
            }).catch(error => {
                console.error(error)
                addToast(getCopy(copy.errorGeneric) ?? "", "", ErrorLevel.Error)
            }) : Promise.reject(new Error("No org")),
        [addToast, currentOrg, organizationService]
    )

    const inviteOrgUser = useCallback((email, role) => currentOrg ? (
        currentOrgUsers?.filter(u => u.email === email).length === 0 ? organizationService.inviteUser({
            organizationId: currentOrg._id,
            email, role
        }).then(response => {
            setCurrentOrgUsers([...currentOrgUsers || [], response])
        }) : Promise.reject(new Error("Already invited"))
    ) : Promise.reject(new Error("No org")), [currentOrg, currentOrgUsers, organizationService])

    const changeOrgUserRole = useCallback((email, role) => currentOrg ? organizationService.changeUserRole({
        organizationId: currentOrg._id,
        email, role
    }).then(response => {
        setCurrentOrgUsers(currentOrgUsers?.map(e => e.email === email ? response : e))
    }) : Promise.reject(new Error("No org")), [currentOrg, currentOrgUsers, organizationService])

    const removeOrgUser = useCallback(email => currentOrg ? organizationService.removeUser({
        organizationId: currentOrg._id,
        email
    }).then(() => {
        setCurrentOrgUsers(currentOrgUsers?.filter(e => e.email !== email))
    }) : Promise.reject(new Error("No org")), [currentOrg, currentOrgUsers, organizationService])
    // #endregion

    // #region Workspace Actions
    const inviteWsUser = useCallback((email, role) => currentWs ? (
        currentWsUsers?.filter(u => u.email === email).length === 0 ? workspaceService.inviteUser({
            workspaceId: currentWs._id,
            email, role
        }).then(response => {
            setCurrentWsUsers([...currentWsUsers || [], response])
        }) : Promise.reject(new Error("Already invited"))
    ) : Promise.reject(new Error("No ws")), [currentWs, currentWsUsers, workspaceService])

    const changeWsUserRole = useCallback((email, role) => currentWs ? workspaceService.changeUserRole({
        workspaceId: currentWs._id,
        email, role
    }).then(response => {
        setCurrentWsUsers(currentWsUsers?.map(e => e.email === email ? response : e))
    }) : Promise.reject(new Error("No ws")), [currentWs, currentWsUsers, workspaceService])

    const removeWsUser = useCallback(email => {
        const propelauthUserId = currentWsUsers?.find(e => e.email === email)?.propelauth_id
        return currentWs && propelauthUserId ? workspaceService.removeUser({
            workspaceId: currentWs._id,
            propelauthUserId
        }).then(() => {
            setCurrentWsUsers(currentWsUsers?.filter(e => e.email !== email))
        }) : Promise.reject(new Error("No ws"))
    }, [currentWs, currentWsUsers, workspaceService])
    // #endregion

    // #region Project Actions
    const inviteProjectGuest = useCallback((email, role) => projectId ? (
        currentProjectGuests?.filter(u => u.email === email).length === 0 ? projectService.inviteGuest({
            projectId,
            email, role
        }).then(response => {
            setCurrentProjectGuests([...currentProjectGuests || [], response])
        }) : Promise.reject(new Error("Already invited"))
    ) : Promise.reject(new Error("No project")), [currentProjectGuests, projectId, projectService])
    const changeProjectGuestRole = useCallback((email, role) => projectId ? projectService.changeGuestRole({
        projectId,
        email, role
    }).then(response => {
        setCurrentProjectGuests(currentProjectGuests?.map(e => e.email === email ? response : e))
    }) : Promise.reject(new Error("No project")), [currentProjectGuests, projectId, projectService])
    const removeProjectGuest = useCallback(email => projectId ? projectService.removeGuest({
        projectId,
        email
    }).then(() => {
        setCurrentProjectGuests(currentProjectGuests?.filter(e => e.email !== email))
    }) : Promise.reject(new Error("No project")), [currentProjectGuests, projectId, projectService])
    // #endregion

    // #region Memo
    const context = useMemo(() => ({
        loading,
        isLoggedIn,
        accessToken,
        user,
        userClass,
        orgHelper,
        accessHelper,
        isImpersonating,
        impersonatorUserId,
        refreshAuthInfo,
        accessTokenExpiresAtSeconds,
        authClient,

        isSuperuser: isSuperuser(user),
        orgs,
        setCurrentOrgId,
        currentOrg,
        currentOrgIsAtLeastRole,
        currentOrgUsers,
        inviteOrgUser,
        changeOrgUserRole,
        removeOrgUser,
        submitOrgLogo,
        removeOrgLogo,

        setCurrentWsId,
        currentWs,
        currentWsIsAtLeastRole,
        currentWsUsers,
        inviteWsUser,
        changeWsUserRole,
        removeWsUser,

        currentProjectIsAtLeastRole,
        currentProjectGuests,
        inviteProjectGuest,
        changeProjectGuestRole,
        removeProjectGuest
    }), [
        loading,
        isLoggedIn,
        accessToken,
        user,
        userClass,
        orgHelper,
        accessHelper,
        isImpersonating,
        impersonatorUserId,
        refreshAuthInfo,
        accessTokenExpiresAtSeconds,
        authClient,
        orgs,
        currentOrg,
        currentOrgIsAtLeastRole,
        currentOrgUsers,
        inviteOrgUser,
        changeOrgUserRole,
        removeOrgUser,
        submitOrgLogo,
        removeOrgLogo,
        currentWs,
        currentWsIsAtLeastRole,
        currentWsUsers,
        inviteWsUser,
        changeWsUserRole,
        removeWsUser,
        currentProjectIsAtLeastRole,
        currentProjectGuests,
        inviteProjectGuest,
        changeProjectGuestRole,
        removeProjectGuest
    ])
    // #endregion

    // Error page
    if (error) return <ErrorPage
        code={error.code}
        message={error.message}
        detail={error.detail}
        errorLevel={error.errorLevel}
        separatorExtraClass={error.separatorExtraClass}
    />

    if ((!orgs || !currentOrg || (
        currentOrg._id !== currentOrgId && currentWs?._id && currentWs?._id !== currentWsId
    )) && !projectId)
        return <Loading />

    // Provide the project state and updater function to context consumers
    return (
        <UserContext.Provider value={context}>
            {children}
        </UserContext.Provider>
    )
}
