import React from "react"

interface ILoadingBarProps {
    /**
     * From 0 to 100.
     */
    percentage?: number
    showPercentage?: boolean
    precision?: number
    style?: React.CSSProperties
    loadingBarStyle?: React.CSSProperties
    loadingBarFullClassName?: string
    loadingBarEmptyClassName?: string
    loadingBarDotClassName?: string
}

export default function LoadingBar({
    percentage = 0,
    showPercentage = false,
    precision = 1,
    style,
    loadingBarStyle,
    loadingBarFullClassName,
    loadingBarEmptyClassName,
    loadingBarDotClassName
}: Readonly<ILoadingBarProps>) {
    return (
        <div className="loading-bar-wrapper w-full flex flex-row items-center m-2" style={style}>
            {showPercentage && (
                <span
                    className="loading-bar-percentage text-lg"
                    style={precision ? { width: `${(precision + 2) * 19}px` } : {}}
                >
                    {percentage.toFixed(precision)}%
                </span>
            )}
            <div className="loading-bar inline-block" style={loadingBarStyle ?? style}>
                <div
                    className={loadingBarFullClassName ?? "loading-bar-full color"}
                    style={{ width: `${percentage}%` }}></div>
                <div
                    className={loadingBarEmptyClassName ?? "loading-bar-empty color-light"}
                    style={{
                        width: `calc(${100 - percentage}% - 6px)`, // -6px from loading-bar-dot
                        border: `${100 - percentage > 0 ? 2 : 0}px solid transparent`
                    }}
                >
                    <div className={loadingBarDotClassName ?? "loading-bar-dot color"}></div>
                </div>
            </div>
        </div>
    )
}



