import csv from "@assets/csv.png"
import Button from "@components/layouts/Button"
import { getCopy, copy } from "@utils/Copy"
import React, { ChangeEventHandler, useCallback } from "react"
import { FaExclamationCircle } from "react-icons/fa"
import { MdUpload } from "react-icons/md"

const settingsCopy = copy.coding.settings

interface ICSVSettingsTabSelectCSVFileProps {
    setFile: React.Dispatch<React.SetStateAction<File | undefined>>
}

export default function CSVSettingsTabSelectCSVFile({ setFile }: Readonly<ICSVSettingsTabSelectCSVFileProps>) {
    // #region Callbacks
    const handleFileChange: ChangeEventHandler<HTMLInputElement> = useCallback(event => {
        const files = event.target.files
        if (!files) return

        setFile(files[0])
    }, [setFile])
    // #endregion

    return (
        <div className="import-wrapper">
            <div className="import">
                {csv && <img src={csv} alt="csv" />}
                <h1 className="title">{getCopy(settingsCopy.selectCsv.title)}</h1>
                <h2 className="subtitle">{getCopy(settingsCopy.selectCsv.subtitle)}</h2>
                <div className="instructions">
                    <div className="instructions-title">
                        <FaExclamationCircle />
                        {getCopy(settingsCopy.selectCsv.instructionsTitle)}
                    </div>
                    <div className="instructions-body">{getCopy(settingsCopy.selectCsv.instructions)}</div>
                    <div className="instructions-footer">{getCopy(settingsCopy.selectCsv.instructionsFooter)}</div>
                </div>
                <Button
                    label={getCopy(settingsCopy.selectCsv.uploadCsvFile)}
                    icon={<MdUpload />}
                    acceptFile=".csv"
                    handleFileChange={handleFileChange}
                />
            </div>
        </div>
    )
}