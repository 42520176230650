import GlautStar from "@components/Icons/GlautStar"
import { getCopy } from "@utils/Copy"
import { interviewTerminatedScreenoutCopy } from "./utils/copy"

interface IInterviewTerminatedScreenoutProps {
    lang: string
}

export default function InterviewTerminatedScreenout({ lang }: Readonly<IInterviewTerminatedScreenoutProps>) {
    return (
        <div className="h-dvh w-dvw flex flex-col items-start justify-center gap-[1.5em] mx-[4em] max-w-[300px] 
            pb-10">
            <GlautStar className="h-[2rem] w-[2rem]" />
            {getCopy(interviewTerminatedScreenoutCopy.message, lang)}
            <p className="text-[16px] text-glaut-text-midnight">
                {getCopy(interviewTerminatedScreenoutCopy.detail, lang)}
            </p>
        </div>
    )
}