export const conversationOverlayCopy = {
    status: {
        completed: {
            ar: "مكتمل",
            zh: "已完成",
            hr: "Završeno",
            cs: "Dokončeno",
            nl: "Voltooid",
            en: "Completed",
            en_GB: "Completed",
            en_AU: "Completed",
            en_ZA: "Completed",
            en_NG: "Completed",
            fi: "Valmis",
            fr: "Terminé",
            de: "Abgeschlossen",
            de_CH: "Abgeschlossen",
            el: "Ολοκληρώθηκε",
            hi_IN: "पूर्ण",
            it: "Completato",
            id: "Selesai",
            ja: "完了",
            no: "Fullført",
            pl: "Zakończone",
            pt: "Concluído",
            pt_BR: "Concluído",
            ru: "Завершено",
            es: "Completado",
            es_AR: "Completado",
            es_MX: "Completado",
            es_CL: "Completado",
            es_CO: "Completado",
            sv: "Slutförd",
            tr: "Tamamlandı",
            uk: "Завершено"
        },
        uncompleted: {
            ar: "غير مكتمل",
            zh: "未完成",
            hr: "Nezavršeno",
            cs: "Nedokončeno",
            nl: "Onvoltooid",
            en: "Uncompleted",
            en_GB: "Uncompleted",
            en_AU: "Uncompleted",
            en_ZA: "Uncompleted",
            en_NG: "Uncompleted",
            fi: "Keskeneräinen",
            fr: "Non terminé",
            de: "Unvollendet",
            de_CH: "Unvollendet",
            el: "Μη ολοκληρωμένο",
            hi_IN: "अपूर्ण",
            it: "Incompleto",
            id: "Belum selesai",
            ja: "未完了",
            no: "Ufullstendig",
            pl: "Nieukończone",
            pt: "Incompleto",
            pt_BR: "Incompleto",
            ru: "Не завершено",
            es: "Incompleto",
            es_AR: "Incompleto",
            es_MX: "Incompleto",
            es_CL: "Incompleto",
            es_CO: "Incompleto",
            sv: "Ej slutförd",
            tr: "Tamamlanmamış",
            uk: "Не завершено"
        },
        started: {
            ar: "بدأ",
            zh: "已开始",
            hr: "Započeto",
            cs: "Zahájeno",
            nl: "Gestart",
            en: "Started",
            en_GB: "Started",
            en_AU: "Started",
            en_ZA: "Started",
            en_NG: "Started",
            fi: "Aloitettu",
            fr: "Commencé",
            de: "Gestartet",
            de_CH: "Gestartet",
            el: "Ξεκίνησε",
            hi_IN: "शुरू किया",
            it: "Iniziato",
            id: "Dimulai",
            ja: "開始済み",
            no: "Startet",
            pl: "Rozpoczęte",
            pt: "Iniciado",
            pt_BR: "Iniciado",
            ru: "Начато",
            es: "Iniciado",
            es_AR: "Iniciado",
            es_MX: "Iniciado",
            es_CL: "Iniciado",
            es_CO: "Iniciado",
            sv: "Påbörjad",
            tr: "Başladı",
            uk: "Розпочато"
        }
    },
    fullTranscript: {
        ar: "النص الكامل",
        zh: "完整记录",
        hr: "Potpuni prijepis",
        cs: "Úplný přepis",
        nl: "Volledige transcriptie",
        en: "Full transcript",
        en_GB: "Full transcript",
        en_AU: "Full transcript",
        en_ZA: "Full transcript",
        en_NG: "Full transcript",
        fi: "Täydellinen litterointi",
        fr: "Transcription complète",
        de: "Vollständiges Transkript",
        de_CH: "Vollständiges Transkript",
        el: "Πλήρης απομαγνητοφώνηση",
        hi_IN: "पूर्ण प्रतिलेख",
        it: "Trascrizione completa",
        id: "Transkrip lengkap",
        ja: "完全な文字起こし",
        no: "Fullstendig transkripsjon",
        pl: "Pełna transkrypcja",
        pt: "Transcrição completa",
        pt_BR: "Transcrição completa",
        ru: "Полная расшифровка",
        es: "Transcripción completa",
        es_AR: "Transcripción completa",
        es_MX: "Transcripción completa",
        es_CL: "Transcripción completa",
        es_CO: "Transcripción completa",
        sv: "Fullständig transkription",
        tr: "Tam transkript",
        uk: "Повна транскрипція"
    },
    conversation: {
        ar: "محادثة",
        zh: "对话",
        hr: "Razgovor",
        cs: "Konverzace",
        nl: "Gesprek",
        en: "Conversation",
        en_GB: "Conversation",
        en_AU: "Conversation",
        en_ZA: "Conversation",
        en_NG: "Conversation",
        fi: "Keskustelu",
        fr: "Conversation",
        de: "Gespräch",
        de_CH: "Gespräch",
        el: "Συνομιλία",
        hi_IN: "वार्तालाप",
        it: "Conversazione",
        id: "Percakapan",
        ja: "会話",
        no: "Samtale",
        pl: "Rozmowa",
        pt: "Conversa",
        pt_BR: "Conversa",
        ru: "Разговор",
        es: "Conversación",
        es_AR: "Conversación",
        es_MX: "Conversación",
        es_CL: "Conversación",
        es_CO: "Conversación",
        sv: "Konversation",
        tr: "Konuşma",
        uk: "Розмова"
    },
    deleteEntry: {
        ar: "حذف المدخل",
        zh: "删除条目",
        hr: "Izbriši unos",
        cs: "Smazat záznam",
        nl: "Verwijder item",
        en: "Delete entry",
        en_GB: "Delete entry",
        en_AU: "Delete entry",
        en_ZA: "Delete entry",
        en_NG: "Delete entry",
        fi: "Poista merkintä",
        fr: "Supprimer l'entrée",
        de: "Eintrag löschen",
        de_CH: "Eintrag löschen",
        el: "Διαγραφή καταχώρησης",
        hi_IN: "प्रविष्टि हटाएं",
        it: "Elimina voce",
        id: "Hapus entri",
        ja: "エントリーを削除",
        no: "Slett oppføring",
        pl: "Usuń wpis",
        pt: "Eliminar entrada",
        pt_BR: "Excluir entrada",
        ru: "Удалить запись",
        es: "Eliminar entrada",
        es_AR: "Eliminar entrada",
        es_MX: "Eliminar entrada",
        es_CL: "Eliminar entrada",
        es_CO: "Eliminar entrada",
        sv: "Radera post",
        tr: "Girişi sil",
        uk: "Видалити запис"
    },
    areYouSureYouWantToDeleteThisEntry: {
        ar: "هل أنت متأكد أنك تريد حذف هذا المدخل؟",
        zh: "您确定要删除此条目吗？",
        hr: "Jeste li sigurni da želite izbrisati ovaj unos?",
        cs: "Opravdu chcete smazat tento záznam?",
        nl: "Weet u zeker dat u dit item wilt verwijderen?",
        en: "Are you sure you want to delete this entry?",
        en_GB: "Are you sure you want to delete this entry?",
        en_AU: "Are you sure you want to delete this entry?",
        en_ZA: "Are you sure you want to delete this entry?",
        en_NG: "Are you sure you want to delete this entry?",
        fi: "Haluatko varmasti poistaa tämän merkinnän?",
        fr: "Êtes-vous sûr de vouloir supprimer cette entrée ?",
        de: "Sind Sie sicher, dass Sie diesen Eintrag löschen möchten?",
        de_CH: "Sind Sie sicher, dass Sie diesen Eintrag löschen möchten?",
        el: "Είστε σίγουροι ότι θέλετε να διαγράψετε αυτήν την καταχώρηση;",
        hi_IN: "क्या आप वाकई इस प्रविष्टि को हटाना चाहते हैं?",
        it: "Sei sicuro di voler eliminare questa voce?",
        id: "Apakah Anda yakin ingin menghapus entri ini?",
        ja: "このエントリーを削除してもよろしいですか？",
        no: "Er du sikker på at du vil slette denne oppføringen?",
        pl: "Czy na pewno chcesz usunąć ten wpis?",
        pt: "Tem certeza que deseja eliminar esta entrada?",
        pt_BR: "Tem certeza que deseja excluir esta entrada?",
        ru: "Вы уверены, что хотите удалить эту запись?",
        es: "¿Está seguro de que desea eliminar esta entrada?",
        es_AR: "¿Está seguro de que desea eliminar esta entrada?",
        es_MX: "¿Está seguro de que desea eliminar esta entrada?",
        es_CL: "¿Está seguro de que desea eliminar esta entrada?",
        es_CO: "¿Está seguro de que desea eliminar esta entrada?",
        sv: "Är du säker på att du vill radera denna post?",
        tr: "Bu girişi silmek istediğinizden emin misiniz?",
        uk: "Ви впевнені, що хочете видалити цей запис?"
    },
    question: {
        ar: "سؤال",
        zh: "问题",
        hr: "Pitanje",
        cs: "Otázka",
        nl: "Vraag",
        en: "Question",
        en_GB: "Question",
        en_AU: "Question",
        en_ZA: "Question",
        en_NG: "Question",
        fi: "Kysymys",
        fr: "Question",
        de: "Frage",
        de_CH: "Frage",
        el: "Ερώτηση",
        hi_IN: "प्रश्न",
        it: "Domanda",
        id: "Pertanyaan",
        ja: "質問",
        no: "Spørsmål",
        pl: "Pytanie",
        pt: "Pergunta",
        pt_BR: "Pergunta",
        ru: "Вопрос",
        es: "Pregunta",
        es_AR: "Pregunta",
        es_MX: "Pregunta",
        es_CL: "Pregunta",
        es_CO: "Pregunta",
        sv: "Fråga",
        tr: "Soru",
        uk: "Питання"
    },
    panelScreening: {
        relatedData: {
            ar: "البيانات ذات الصلة",
            zh: "相关数据",
            hr: "Povezani podaci",
            cs: "Související údaje",
            nl: "Gerelateerde gegevens",
            en: "Related data",
            en_GB: "Related data",
            en_AU: "Related data",
            en_ZA: "Related data",
            en_NG: "Related data",
            fi: "Liittyvät tiedot",
            fr: "Données connexes",
            de: "Zugehörige Daten",
            de_CH: "Zugehörige Daten",
            el: "Σχετικά δεδομένα",
            hi_IN: "संबंधित डेटा",
            it: "Dati correlati",
            id: "Data terkait",
            ja: "関連データ",
            no: "Relaterte data",
            pl: "Powiązane dane",
            pt: "Dados relacionados",
            pt_BR: "Dados relacionados",
            ru: "Связанные данные",
            es: "Datos relacionados",
            es_AR: "Datos relacionados",
            es_MX: "Datos relacionados",
            es_CL: "Datos relacionados",
            es_CO: "Datos relacionados",
            sv: "Relaterad data",
            tr: "İlgili veriler",
            uk: "Пов'язані дані"
        },
        whatIsYourGender: {
            ar: "ما هو جنسك؟",
            zh: "您的性别是什么？",
            hr: "Koji je vaš spol?",
            cs: "Jaké je vaše pohlaví?",
            nl: "Wat is uw geslacht?",
            en: "What is your gender?",
            en_GB: "What is your gender?",
            en_AU: "What is your gender?",
            en_ZA: "What is your gender?",
            en_NG: "What is your gender?",
            fi: "Mikä on sukupuolesi?",
            fr: "Quel est votre genre ?",
            de: "Was ist Ihr Geschlecht?",
            de_CH: "Was ist Ihr Geschlecht?",
            el: "Ποιο είναι το φύλο σας;",
            hi_IN: "आपका लिंग क्या है?",
            it: "Qual è il tuo genere?",
            id: "Apa jenis kelamin Anda?",
            ja: "あなたの性別は何ですか？",
            no: "Hva er ditt kjønn?",
            pl: "Jaka jest Twoja płeć?",
            pt: "Qual é o seu género?",
            pt_BR: "Qual é o seu gênero?",
            ru: "Какой ваш пол?",
            es: "¿Cuál es su género?",
            es_AR: "¿Cuál es su género?",
            es_MX: "¿Cuál es su género?",
            es_CL: "¿Cuál es su género?",
            es_CO: "¿Cuál es su género?",
            sv: "Vilket är ditt kön?",
            tr: "Cinsiyetiniz nedir?",
            uk: "Яка ваша стать?"
        },
        howOldAreYou: {
            ar: "كم عمرك؟",
            zh: "您多大年龄？",
            hr: "Koliko imate godina?",
            cs: "Kolik je vám let?",
            nl: "Hoe oud bent u?",
            en: "How old are you?",
            en_GB: "How old are you?",
            en_AU: "How old are you?",
            en_ZA: "How old are you?",
            en_NG: "How old are you?",
            fi: "Kuinka vanha olet?",
            fr: "Quel âge avez-vous ?",
            de: "Wie alt sind Sie?",
            de_CH: "Wie alt sind Sie?",
            el: "Πόσο χρονών είστε;",
            hi_IN: "आपकी उम्र क्या है?",
            it: "Quanti anni hai?",
            id: "Berapa umur Anda?",
            ja: "年齢はおいくつですか？",
            no: "Hvor gammel er du?",
            pl: "Ile masz lat?",
            pt: "Que idade tem?",
            pt_BR: "Qual é a sua idade?",
            ru: "Сколько вам лет?",
            es: "¿Cuántos años tiene?",
            es_AR: "¿Cuántos años tiene?",
            es_MX: "¿Cuántos años tiene?",
            es_CL: "¿Cuántos años tiene?",
            es_CO: "¿Cuántos años tiene?",
            sv: "Hur gammal är du?",
            tr: "Kaç yaşındasınız?",
            uk: "Скільки вам років?"
        },
        whichStateDoYouLiveIn: {
            ar: "في أي ولاية تعيش؟",
            zh: "您住在哪个州？",
            hr: "U kojoj državi živite?",
            cs: "Ve kterém státě žijete?",
            nl: "In welke staat woont u?",
            en: "Which state do you live in?",
            en_GB: "Which state do you live in?",
            en_AU: "Which state do you live in?",
            en_ZA: "Which state do you live in?",
            en_NG: "Which state do you live in?",
            fi: "Missä osavaltiossa asut?",
            fr: "Dans quel état vivez-vous ?",
            de: "In welchem Bundesstaat leben Sie?",
            de_CH: "In welchem Kanton leben Sie?",
            el: "Σε ποια πολιτεία ζείτε;",
            hi_IN: "आप किस राज्य में रहते हैं?",
            it: "In quale stato vivi?",
            id: "Di negara bagian mana Anda tinggal?",
            ja: "どの州にお住まいですか？",
            no: "Hvilken stat bor du i?",
            pl: "W którym stanie mieszkasz?",
            pt: "Em que estado vive?",
            pt_BR: "Em qual estado você mora?",
            ru: "В каком штате вы живете?",
            es: "¿En qué estado vive?",
            es_AR: "¿En qué provincia vive?",
            es_MX: "¿En qué estado vive?",
            es_CL: "¿En qué región vive?",
            es_CO: "¿En qué departamento vive?",
            sv: "Vilken delstat bor du i?",
            tr: "Hangi eyalette yaşıyorsunuz?",
            uk: "В якому штаті ви живете?"
        }
    }
}