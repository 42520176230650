import { DetailedHTMLProps, SVGAttributes } from "react"

interface IBranchProps
    extends DetailedHTMLProps<SVGAttributes<SVGSVGElement>, SVGSVGElement> {

}

export default function Branch({
    fill,
    height = "12",
    viewBox = "0 0 8 12",
    width = "8",
    ...props
}: Readonly<IBranchProps>) {
    return (
        <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M8 3.375C8 2.33906 7.25417 1.5 6.33333 1.5C5.4125 1.5 4.66667 2.33906 4.66667 3.375C4.66667 4.22813 5.17292 4.94766 5.86458 5.175C5.85208 5.55234 5.77708 5.84297 5.63542 6.03984C5.31458 6.48984 4.60833 6.56484 3.86042 6.64219C3.27292 6.70312 2.66458 6.76875 2.16667 7.03828V3.66328C2.84375 3.42422 3.33333 2.71406 3.33333 1.875C3.33333 0.839062 2.5875 0 1.66667 0C0.745833 0 0 0.839062 0 1.875C0 2.71406 0.489583 3.42422 1.16667 3.66328V8.33438C0.489583 8.57578 0 9.28594 0 10.125C0 11.1609 0.745833 12 1.66667 12C2.5875 12 3.33333 11.1609 3.33333 10.125C3.33333 9.32812 2.89167 8.64609 2.26667 8.37656C2.33125 8.25469 2.42917 8.14688 2.57708 8.0625C2.91458 7.87031 3.41875 7.81875 3.95417 7.7625C4.83333 7.67109 5.82917 7.56562 6.41667 6.74531C6.70833 6.3375 6.85625 5.8125 6.86667 5.15391C7.525 4.90078 8 4.2 8 3.375ZM1.66667 1.5C1.85 1.5 2 1.66875 2 1.875C2 2.08125 1.85 2.25 1.66667 2.25C1.48333 2.25 1.33333 2.08125 1.33333 1.875C1.33333 1.66875 1.48333 1.5 1.66667 1.5ZM1.66667 10.5C1.48333 10.5 1.33333 10.3313 1.33333 10.125C1.33333 9.91875 1.48333 9.75 1.66667 9.75C1.85 9.75 2 9.91875 2 10.125C2 10.3313 1.85 10.5 1.66667 10.5ZM6.33333 3C6.51667 3 6.66667 3.16875 6.66667 3.375C6.66667 3.58125 6.51667 3.75 6.33333 3.75C6.15 3.75 6 3.58125 6 3.375C6 3.16875 6.15 3 6.33333 3Z"
                fill={fill} />
        </svg>
    )
}