import {
    IInterviewQuestion,
    IScaleQuestion
} from "@/@types/interview-question"
import { IProjectGatewayCreateInterviewQuestionRequest } from "@services/projects/IProjectGateway"

/**
 * Builds the payload to create a new interview question. Returns `null` in case there is missing information.
 */
export function buildIqPayload(
    iq: Partial<IInterviewQuestion>,
    lang: string,
    projectId: string,
    originalIq?: Partial<IInterviewQuestion>
): IProjectGatewayCreateInterviewQuestionRequest | null {
    if (!iq.content?.[lang]) return null
    const content = iq.content

    if (!iq.type) return null
    const type = iq.type

    const embedUrl = iq.embed_url?.length ? iq.embed_url : null
    const filters = iq.filters ?? []
    const header = iq.header?.length ? iq.header : ""
    const img = iq.img?.length ? iq.img : null
    const probingInstructions = iq.probing_instructions ?? null

    if (type === "nps")
        return {
            projectId,
            data: {
                content,
                domain: "interview",
                embed_url: embedUrl,
                filters,
                header,
                img,
                project_id: projectId,
                type,
                min: 1,
                max: 10,
                probing_instructions: probingInstructions
            }
        }

    if (type === "scale") {
        const { min, max } = iq as Partial<IScaleQuestion>
        if (min === undefined || max === undefined) return null
        if (min > max) return null

        return {
            projectId,
            data: {
                content,
                domain: "interview",
                embed_url: embedUrl,
                filters,
                header,
                img,
                project_id: projectId,
                type,
                min,
                max,
                probing_instructions: probingInstructions
            }
        }
    }

    if (type === "select") {
        const { options, max_choices: maxChoices } = iq
        if (!options) return null

        // @TODO improve this workaround

        if (originalIq?.type === "select")
            for (const option of options) {
                const originalOption = originalIq?.options?.find(o => o.id === option.id)
                if (!originalOption) option.id = ""
            }


        return {
            projectId,
            data: {
                content,
                domain: "interview",
                embed_url: embedUrl,
                filters,
                header,
                img,
                project_id: projectId,
                type,
                options,
                max_choices: !maxChoices ? 1 : maxChoices,
                probing_instructions: probingInstructions
            }
        }
    }

    if (type === "asset")
        return {
            projectId,
            data: {
                content,
                domain: "interview",
                embed_url: embedUrl,
                filters,
                header,
                img,
                project_id: projectId,
                type,
                probing_instructions: probingInstructions ?? undefined // intentional to avoid overwriting in BE
            }
        }

    return {
        projectId,
        data: {
            content,
            domain: "interview",
            embed_url: embedUrl,
            filters,
            header,
            img,
            project_id: projectId,
            type,
            probing_instructions: probingInstructions
        }
    }
}