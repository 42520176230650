import GlautInputLogo from "@components/inputs/GlautInputLogo"
import { ChangeEvent, useCallback, useMemo } from "react"
import { MdClose } from "react-icons/md"
import { useGlautAssetComponent, useGlautAssetComponentDispatch } from "../../contexts/GlautAssetComponentProvider"

export default function GlautAssetComponentSelectedOptionImage() {
    // #region Contexts
    const { disabled, disabledType, onChange, value } = useGlautAssetComponent()
    const glautAssetComponentDispatch = useGlautAssetComponentDispatch()
    // #endregion

    // #region Memos
    const isOptionDisabled = useMemo(() => disabled || disabledType.image, [disabled, disabledType])
    // #endregion

    // #region Callbacks
    const handleSelectLogo = useCallback(async (ev: ChangeEvent<HTMLInputElement>) => {
        const logoFile = ev.target.files?.[0]
        if (!logoFile) return

        onChange.image?.(logoFile)
    }, [onChange])

    const handleRemoveLogo = useCallback(() => {
        onChange.image?.(null)
    }, [onChange])

    const handleRemoveOption = useCallback(() => {
        onChange.image?.(undefined)
        glautAssetComponentDispatch({ type: "remove-selected-option", assetType: "image" })
    }, [glautAssetComponentDispatch, onChange])
    // #endregion

    return (
        <div className="flex gap-[0.5em] p-[0.75em] bg-glaut-cards rounded-[0.5em]">
            <div className="flex-1">
                <GlautInputLogo
                    src={value.image ?? undefined}
                    alt="question-img"
                    disabled={disabled || disabledType.image}
                    onSelect={handleSelectLogo}
                    onRemove={handleRemoveLogo}
                />
            </div>
            {!value.image && (
                <button
                    className="text-base p-0 m-0 rounded-none shadow-none border-none bg-transparent"
                    onClick={handleRemoveOption}
                    disabled={isOptionDisabled}
                >
                    <MdClose className="h-[1em] w-[1em] text-glaut-dummie-color2" />
                </button>
            )}
        </div>
    )
}