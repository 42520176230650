import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { ProjectContext } from "../../../AutoSaveProject"
import { IProject } from "src/@types/project"
import { MdAddCircle } from "react-icons/md"
import { getCopy, copy } from "@utils/Copy"
import ReportsTabEmpty from "../ReportsTabEmpty"
import { useReportService } from "@hooks/services/useReportService"
import { useReportsTab, useReportsTabDispatch } from "@contexts/ReportsTabProvider"
import Loading from "@components/loading/Loading"
import GlautButtonPrimary from "@components/Buttons/GlautButtonPrimary"
import ReportDetails from "@components/Reports/ReportDetails"
import { useAnalysisService } from "@hooks/services/useAnalysisService"
import { useProject } from "../../contexts/ProjectProvider"
import { IAnalysis } from "@/@types/analysis"

export default function ReportsTabContent() {
    // #region Contexts
    const { project } = useContext(ProjectContext) as { project: IProject | null }
    const { numberOfEntries, interviewQuestions } = useProject()
    const { currReportsPage, currOpenReport } = useReportsTab()
    const reportsTabDispatch = useReportsTabDispatch()
    // #endregion

    // #region Services
    const analysisService = useAnalysisService()
    const reportService = useReportService()
    // #endregion

    // #region States
    const [isFetchingReports, setIsFetchingReports] = useState(false)
    const [reportMetrics, setReportMetrics] = useState<
        Awaited<ReturnType<typeof reportService["getReportDetails"]>>["metrics"]
    >()
    const [analyses, setAnalyses] = useState<IAnalysis[]>()
    // #endregion

    // #region Memos
    const contentState = useMemo(() => {
        if (isFetchingReports) return "loading"
        if (!isFetchingReports && currReportsPage.length === 0) return "empty-state"

        return "normal"
    }, [currReportsPage.length, isFetchingReports])
    // #endregion

    // #region Callbacks
    const handleSelectReport = useCallback((reportId: string) => {
        if (!project || isFetchingReports) return

        setIsFetchingReports(true)

        reportService.getReportDetails({ reportId })
            .then(({ report, metrics, analyses }) => {
                reportsTabDispatch({ type: "set-curr-open-report", report })
                setReportMetrics(metrics)
                setAnalyses(analyses)
            })
            .finally(() => {
                setIsFetchingReports(false)
            })
    }, [isFetchingReports, project, reportService, reportsTabDispatch])
    // #endregion

    // #region Effects

    // onLoadFetchReportsList
    useEffect(() => {
        if (!project) return

        setIsFetchingReports(true)

        const projectId = project._id

        const reportPromise = reportService.getReportList({ projectId })

        Promise.all([reportPromise]).then(([{ reports }]) => {
            reportsTabDispatch({ type: "set-curr-reports-page", reports })
        }).finally(() => {
            setIsFetchingReports(false)
        })
    }, [analysisService, project, reportService, reportsTabDispatch])

    // #endregion

    if (currOpenReport && project && interviewQuestions && reportMetrics && analyses)
        return (
            <ReportDetails
                report={currOpenReport}
                project={project}
                analyses={analyses}
                interviewQuestions={interviewQuestions}
                numberOfEntries={numberOfEntries}
                metrics={reportMetrics}
                onBack={() => { reportsTabDispatch({ type: "close-curr-report" }) }}
            />
        )

    return (
        <div className="h-full overflow-auto bg-glaut-off-white">
            <div className="container flex flex-col my-20 mx-auto h-full gap-[0.75em] max-w-[50%]">
                <div className="flex gap-[0.3125em] justify-end">
                    <GlautButtonPrimary disabled>
                        <MdAddCircle className="h-[1.25em] w-[1.25em]" />
                        <p className="text-[13.33px] font-medium">
                            {getCopy(copy.reports.createReport)}
                        </p>
                    </GlautButtonPrimary>
                </div>
                <div className={`flex-1 flex flex-col items-center gap-[0.75em]
                    ${contentState !== "normal" ? "justify-center" : ""}
                `}>
                    {contentState === "loading" && (
                        <Loading />
                    )}
                    {contentState === "empty-state" && (
                        <ReportsTabEmpty />
                    )}
                    {contentState === "normal" && (
                        <div className="flex flex-col gap-[0.75em] w-full pb-32">
                            {currReportsPage.map(r => (
                                <button
                                    key={r.id}
                                    className={`shadow-none w-full py-[0.625em] px-[0.75em] rounded-[0.25em] border-1
                                        bg-transparent 
                                        border-glaut-stroke-glaut
                                        hover:bg-glaut-very-light-grey hover:border-glaut-grey
                                    `}
                                    onClick={() => { handleSelectReport(r.id) }}>
                                    <p className="text-[16px] text-glaut-text-midnight text-center">
                                        {r.title}
                                    </p>
                                </button>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}