import { useCallback, useState } from "react"

import Popup from "@components/Popups/Popup"

interface IDeletePopupProps {
    confirmString: string
    entityName: string
    close: () => void
    onConfirm: () => void
}

export default function DeletePopup({ confirmString, entityName, close, onConfirm }: Readonly<IDeletePopupProps>) {
    const [name, setName] = useState("")

    const deleteEntity = useCallback(e => {
        e.preventDefault()
        if (name !== confirmString) return

        onConfirm()
    }, [confirmString, name, onConfirm])

    return (
        <Popup close={close}>
            <h2>Delete {entityName}</h2>
            <form>
                To confirm, type &quot;{confirmString}&quot; in the box below
                <input
                    value={name}
                    placeholder={confirmString}
                    onChange={e => setName(e.target.value)}
                    style={{ width: "100%" }}
                />
                <button
                    onClick={deleteEntity}
                    disabled={name !== confirmString}
                    className="danger-button"
                    style={{ width: "100%" }}
                >
                    Delete this {entityName}
                </button>
            </form>
        </Popup>
    )
}
