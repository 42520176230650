import FloatingLayout from "@components/layouts/FloatingLayout"
import { useAnalysisService } from "@hooks/services/useAnalysisService"
import { copy, getCopy } from "@utils/Copy"
import { MutableRefObject, useCallback, useContext, useState } from "react"
import ReactDOM from "react-dom"
import { FaTags } from "react-icons/fa"
import { IoMdArrowUp } from "react-icons/io"
import { MdClose, MdEditNote, MdMergeType } from "react-icons/md"
import { IProject } from "src/@types/project"
import { ProjectContext } from "../../../AutoSaveProject"
import { useAnalysisTabAnalysisColDispatch } from "../../contexts/AnalysisTabAnalysisColProvider"
import { useAnalysisTab, useAnalysisTabDispatch } from "../../contexts/AnalysisTabProvider"
import MenuItem from "../AnalysisTabAnalysisColThemeAndCodeActionsMenuItem"
import { ICategoryStats } from "src/@types/processing/statistics"
import { IAnalysisCategory } from "src/@types/analysis"

interface IBaseAnalysisTabAnalysisColThemeAndCodeActionsMenuProps {
    isTheme?: boolean
    isSubTheme?: boolean
    parentCategoryId?: string
    categoryStats: ICategoryStats<IAnalysisCategory>
    anchorRef: MutableRefObject<HTMLElement | null>
    /**
     * Only for coloring purposes.
     */
    categoryIndex?: number
    isOpen: boolean
    onClose?: () => void
    openPlacement?: "up" | "down"
}

interface IAnalysisTabAnalysisColThemeActionsMenuProps extends IBaseAnalysisTabAnalysisColThemeAndCodeActionsMenuProps {
    isTheme: true
    isSubTheme?: false
}

interface IAnalysisTabAnalysisColCodeActionsMenuProps extends IBaseAnalysisTabAnalysisColThemeAndCodeActionsMenuProps {
    isTheme?: false
    isSubTheme?: boolean
    parentCategoryId: string
}

type IAnalysisTabAnalysisColThemeAndCodeActionsMenuProps =
    IAnalysisTabAnalysisColThemeActionsMenuProps | IAnalysisTabAnalysisColCodeActionsMenuProps

export default function AnalysisTabAnalysisColThemeAndCodeActionsMenu({
    anchorRef,
    parentCategoryId,
    categoryStats,
    isTheme = false,
    categoryIndex = 1,
    isOpen,
    onClose,
    openPlacement = "down"
}: Readonly<IAnalysisTabAnalysisColThemeAndCodeActionsMenuProps>) {
    // #region Contexts
    const { project } = useContext(ProjectContext) as { project: IProject | null }
    const { selectedAnalysis } = useAnalysisTab()
    const analysisTabDispatch = useAnalysisTabDispatch()
    const analysisTabAnalysisColDispatch = useAnalysisTabAnalysisColDispatch()
    // #endregion

    // #region Services
    const analysisService = useAnalysisService()
    // #endregion

    // #region States
    const [floatingLayoutHeight, setFloatingLayoutHeight] = useState(0)
    // #endregion

    // #region Callbacks
    const handlePromoteToTheme = useCallback((categoryId: string) => {
        if (!project || !selectedAnalysis || isTheme) return

        const projectId = project._id
        const analysisId = selectedAnalysis.id
        const category = selectedAnalysis.categories.find(c => c.id === categoryId)

        if (!category) return

        analysisService.promoteCategoryToTheme({ analysisId, category, projectId }).then(({ analysisStats }) => {
            analysisTabDispatch({ type: "promote-category-to-theme", analysisId, categoryId, analysisStats })
        })
    }, [analysisService, analysisTabDispatch, isTheme, project, selectedAnalysis])
    // #endregion

    if (!isOpen) return false

    return (
        ReactDOM.createPortal(
            <FloatingLayout
                className={`absolute rounded-[0.25em] border-1 py-[0.75em] min-w-40 flex flex-col z-[999999999999]
                    bg-glaut-off-white border-glaut-grey shadow-[0px_0px_8px_0px_rgb(0,0,0,0.15)]
                `}
                anchorRef={anchorRef}
                onClose={onClose}
                ref={element => {
                    if (!element) return
                    setFloatingLayoutHeight(element.scrollHeight - 25)
                }}
                verticalOffset={openPlacement === "up" ? -floatingLayoutHeight : 0}
            >
                {categoryStats.children.length === 0 && (
                    <MenuItem
                        icon={<MdMergeType className="h-[1.25em] w-[1.25em] text-glaut-text-midnight" />}
                        label={getCopy(copy.codes.mergeWith) ?? ""}
                        onClick={e => {
                            e.stopPropagation()

                            analysisTabAnalysisColDispatch({
                                type: "set-category-being-merged",
                                category: {
                                    ...categoryStats,
                                    themeId: isTheme ? categoryStats.category.id : parentCategoryId!,
                                    index: categoryIndex
                                }
                            })
                            onClose?.()
                        }}
                    />
                )}
                {isTheme && categoryStats.children.length > 0 && (
                    <MenuItem
                        icon={<FaTags className="text-glaut-text-midnight" />}
                        label={getCopy(copy.codes.createSubtheme) ?? ""}
                        onClick={e => {
                            e.stopPropagation()

                            analysisTabAnalysisColDispatch({
                                type: "set-category-being-used-to-create-sub-theme",
                                category: {
                                    themeId: categoryStats.category.id,
                                    codes: []
                                }
                            })
                            onClose?.()
                        }}
                    />
                )}
                {isTheme && selectedAnalysis?.type === "thematic" && (
                    <MenuItem
                        icon={<MdEditNote className="h-[1.25em] w-[1.25em] text-glaut-text-midnight" />}
                        label={getCopy(copy.codes.editTheme) ?? ""}
                        onClick={e => {
                            e.stopPropagation()

                            analysisTabAnalysisColDispatch({
                                type: "set-category-being-edited",
                                category: { id: categoryStats.category.id, attribute: "name", type: "theme" }
                            })
                            onClose?.()
                        }}
                    />
                )}
                {!isTheme && (
                    <MenuItem
                        icon={<IoMdArrowUp className="h-[1.25em] w-[1.25em] text-glaut-text-midnight" />}
                        label={getCopy(copy.codes.makeTheme) ?? ""}
                        onClick={e => {
                            e.stopPropagation()

                            handlePromoteToTheme(categoryStats.category.id)
                            onClose?.()
                        }}
                    />
                )}
                {(!isTheme || selectedAnalysis?.type === "entity") && (
                    <MenuItem
                        icon={<MdEditNote className="h-[1.25em] w-[1.25em] text-glaut-text-midnight" />}
                        label={getCopy(copy.codes.editName) ?? ""}
                        onClick={e => {
                            e.stopPropagation()

                            analysisTabAnalysisColDispatch({
                                type: "set-category-being-edited",
                                category: { id: categoryStats.category.id, type: isTheme ? "theme-inline" : "code" }
                            })
                            onClose?.()
                        }}
                    />
                )}
                {((isTheme && selectedAnalysis?.type === "entity") || !isTheme) && (
                    <MenuItem
                        icon={<MdClose className="h-[1.25em] w-[1.25em] text-glaut-text-midnight" />}
                        label={getCopy(isTheme ? copy.codes.deleteTheme : copy.codes.deleteSubcode) ?? ""}
                        onClick={e => {
                            e.stopPropagation()

                            analysisTabAnalysisColDispatch({
                                type: "set-category-being-deleted",
                                category: { id: categoryStats.category.id, type: isTheme ? "theme" : "code" }
                            })
                            onClose?.()
                        }}
                    />
                )}
            </FloatingLayout>,
            document.getElementById("portal")!
        )
    )
}